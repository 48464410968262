import * as React from 'react'
import { FieldGroup, renderFieldsSimple, textInputFieldComponent, textAreaInputFieldComponent } from '@shared/components/forms'
import { emptyValidator, isEmail } from '@shared/helpers'
import styled from 'styled-components'

interface Props {
  values?: any
}

const StyledRequestFeatureForm = styled.div``

function requestFormFields(): FieldGroup<any> {
  return [
    {
      name: 'request',
      label: 'Describe your requested feature',
      required: true,
      placeholder: 'Request',
      validate: emptyValidator,
      component: textAreaInputFieldComponent(),
    },
  ]
}

export const RequestAFeatureForm = (props: Props) => {
  return (
    <StyledRequestFeatureForm>
      {renderFieldsSimple(requestFormFields())}
    </StyledRequestFeatureForm>
  )
}
