import React from 'react'
import {
    FieldGroup,
    selectFieldComponent,
    renderFieldsSimple,
    textInputFieldComponent,
    textInputFieldComponentAdvanced,
    creditCardInputFieldComponent,
} from '@shared/components/forms'
import { gapSizes, LightDivider } from '@src/styles'
import { renderHeader } from '@components/forms'
import { emptyValidator, isCVC, isExpirationDate, isPostalCode, isCreditCardNumber, isStreet, isCountry, isProvince, isCity } from '@shared/helpers'
import styled from 'styled-components'
import { countries } from '@shared/helpers/countries'
import { formatExpirationDate } from '@modules/payments/utility'
import { getCard } from '@modules/payments/components/billing-history'
import { MultiBoxField } from '@shared/components/multibox'

interface Props {
    values?: any
    hasProfiles?: boolean
    edit?: boolean
    isDefault?: boolean
}

const StyledNewCardWrap = styled.div`
    .multi {
        margin: ${gapSizes.XL} 0 !important;
    }
`

const CardNumberWrap = styled.div`
    position: relative;
`

const CardWrap = styled.div`
    position: absolute;
    right: 14px;
    top: 34px;
    svg {
        height: 40px;
    }
`

const countriesFormatted = countries.map(country => ({
    label: country.label,
    value: country.alpha3,
}))

function addressFields(): FieldGroup<any> {
    return [
        {
            name: 'country',
            label: 'Country',
            required: true,
            placeholder: 'Select country',
            validate: isCountry,
            component: selectFieldComponent(countriesFormatted, 'Country'),
        },
        {
            name: 'street1',
            label: 'Street Address',
            required: true,
            placeholder: 'Enter street address',
            validate: isStreet,
            component: textInputFieldComponent,
        },
        {
            name: 'street2',
            label: 'Street Address 2',
            required: false,
            placeholder: 'Enter street address',
            validate: isStreet,
            component: textInputFieldComponent,
        },
        {
            name: 'city',
            label: 'City',
            required: true,
            placeholder: 'Enter city',
            validate: isCity,
            component: textInputFieldComponent,
        },
        {
            name: 'province',
            label: 'State',
            required: true,
            placeholder: 'Enter state / province',
            validate: isProvince,
            component: textInputFieldComponent,
        },
        {
            name: 'postalCode',
            label: 'Postal Code',
            required: true,
            placeholder: 'Enter postal code',
            validate: isPostalCode,
            component: textInputFieldComponent,
        },
    ]
}

function cardFields(): FieldGroup<any> {
    return [
        {
            name: 'firstName',
            label: 'First Name',
            required: true,
            placeholder: 'Enter name on card',
            validate: emptyValidator,
            component: textInputFieldComponent,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            required: true,
            placeholder: 'Enter name on card',
            validate: emptyValidator,
            component: textInputFieldComponent,
        },
    ]
}

export const NewCardForm = (props: Props) => {
    const { values, hasProfiles, edit, isDefault } = props
    return (
        <StyledNewCardWrap>
            {renderHeader({ label: 'Billing Address' })}
            {renderFieldsSimple(addressFields())}
            {renderHeader({ label: 'Card Details' })}
            {renderFieldsSimple(cardFields(), true)}
            <CardNumberWrap>
                {renderFieldsSimple([
                    {
                        name: 'fullNumber',
                        label: 'Card Number',
                        required: true,
                        placeholder: 'Enter card number',
                        validate: isCreditCardNumber,
                        component: creditCardInputFieldComponent,
                    }
                ], true)}
                {values.fullNumber?.length > 0 && <CardWrap>{getCard(values.fullNumber.substring(0, 2))}</CardWrap>}
            </CardNumberWrap>
            {renderFieldsSimple([
                {
                    name: 'expiration',
                    pattern: 'dd/dd',
                    label: 'Valid Thru (mm/yyyy)',
                    required: true,
                    placeholder: 'Enter date',
                    format: formatExpirationDate,
                    validate: isExpirationDate,
                    component: textInputFieldComponentAdvanced({ mask: [/\d/, /\d/, '\u2000', /\d/, /\d/, /\d/, /\d/], placeholderChar: '/' }),
                },
                {
                    name: 'cvv',
                    label: 'Security Code',
                    required: true,
                    placeholder: 'Enter security code',
                    validate: isCVC(values?.fullNumber!),
                    component: textInputFieldComponent,
                },
            ])}
            {(!!hasProfiles || edit) && !isDefault ?
                <MultiBoxField name="defaultPayment" text="Set this card as default payment method" type="checkbox" />
                : null
            }
        </StyledNewCardWrap>
    )
}
