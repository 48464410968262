import React, { ComponentType, useEffect, useState } from 'react'
import { withCachedLoading, withLoadingCachedMultiple } from '@components/loading'
import { CapTableTable } from './components/captable-table'
import {
  CapTableGrouping,
  captablePath,
  Features,
  FilterOption,
  FilterOptions,
  GetBlocksResponse,
  GetOrganizationFeaturesResponse,
  newShareholdingPath,
  OperatingAgreement,
  Organization,
  PageProps,
  selectYourPlanPath,
  ShareholdingsProps,
  transferShareholdingPath
} from '@logic'
import { loadBlocks, loadCaptableData, loadFeatures, loadSecurities, loadUserState } from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission, CompanyType } from '@helpers/constants'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { newBasicModalBundle } from '@components/basic-modal'
import { RainbowBar } from './components/rainbow-bar'
import { formatSecurityAbbreviation, formatSecurityName } from '@helpers/shareholdings'
import { GetCaptableDashboardResponse, GetSecuritiesResponse } from '@src/service'
import { FlexRow, gapSizes, screenSizes } from '@src/styles'
import styled from 'styled-components'
import { RowsState } from '@components/tables'
import { formatDateString, formatTime, getQueryParams, Hash } from '@shared/helpers'
import { actionIds } from '@modules/actions/definition'
import { GroupMultiSelect } from '@shared/components/select-multi/style'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { CSVLink } from "react-csv"
import { isHolding } from './utility'
import { SolidButton } from '@components/button/button'
interface StateProps {
  currentOrganization: Organization
}

interface BlockProps {
  block?: Hash
  setBlock: (block: Hash) => void
}

type BlockStateProps =
  PageProps
  & StateProps
  & GetSecuritiesResponse
  & GetBlocksResponse
  & GetCaptableDashboardResponse
  & GetOrganizationFeaturesResponse

type Props = BlockStateProps & BlockProps & ShareholdingsProps & PageProps & PermissionProps & StateProps

const withBlockData = withLoadingCachedMultiple<Props>(
  loadPermissions,
  loadSecurities('true'),
  loadBlocks,
  loadUserState,
  loadFeatures)
const withData = withCachedLoading<any, Props>(loadCaptableData('true'), ['block'])

function withBlockState(Child: ComponentType<any>) {
  return (props: BlockStateProps) => {
    const { params } = props
    const blockVal = params.block
    const [block, setBlock] = useState<Hash | undefined>(blockVal)
    return <Child {...props} block={block} setBlock={setBlock} />
  }
}

const colorsList = ['#F29D4A', '#EB5757', '#9B51E0', '#56CCF2', '#27AE60', '#F2C94C', '#2F80ED', '#FFFFFF']
const StyledGroups = styled<any>(FlexRow)`
  justify-content: flex-end;
  .right {
    margin-left: ${gapSizes.M};
  }
  @media(max-width: ${screenSizes.S}px) {
    flex-direction: column;
    .right {
      margin: ${gapSizes.M} 0 0 0;
    }
    .select {
      width: 100%;
    }
  }
`


function prepareBlocks(blocks: OperatingAgreement[]) {
  const shareholdingsHashes = Array.from(
    new Set(blocks.map(block => block.shareholdings))
  )
  return shareholdingsHashes
    .map(shareholdings => {
      const duplicates = blocks.filter(block => block.shareholdings == shareholdings)
      const first = duplicates.map(block => block.timestamp).sort()[0]
      return duplicates.find(block => block.timestamp == first)!
    })
    .sort((a, b) =>
      // Descending
      b.timestamp.localeCompare(a.timestamp)
    )
}

const CaptablePageComponent = withPermissions([BuiltInPermission.viewCapTable, BuiltInPermission.viewSelf])(
  withBlockData(withBlockState(withData((props: Props) => {
    const {
      permissions,
      params,
      currentOrganization,
      navigate,
      httpClient,
      organizationFeatures
    } = props
    const { organization } = params

    const canEditMembers = hasPermission(permissions)(BuiltInPermission.editMembers)
    const canEditBilling = hasPermission(permissions)(BuiltInPermission.editBilling)
    const hasTransferFeature = organizationFeatures && organizationFeatures.includes(Features.transfers)
    const showTransferButton = (canEditMembers && hasTransferFeature) || canEditBilling

    useEffect(() => {
      const actionId = getQueryParams()
      if (actionId!.action === actionIds.transfer) {
        if (!hasTransferFeature) {
          upgradeModal.setVisible(true)
        }
        else {
          navigate(transferShareholdingPath, { organization })
        }
      }
    }, [])

    const upgradeModal = newBasicModalBundle({
      title: 'Upgrade your organization to transfer shares.',
      desc:
        'Transferring shares is a premium feature. In order to transfer shares,' +
        ' purchase a subscription and upgrade to a premium account.',
      buttons: [
        {
          text: 'Confirm',
          componentType: SolidButton,
          onActivate: () => {
            navigate(selectYourPlanPath, { organization })
          },
        },
      ],
    })

    // captable section
    const [selectedRows, setSelectedRows] = useState<RowsState>({})
    const [expandedRows, setExpandedRows] = useState<RowsState>({})
    const [chartValues, setChartValues] = useState<any[]>([])
    const [groupBy, setGroupBy] = useState<CapTableGrouping>(0)
    const [groupSelected, setGroupSelected] = useState<FilterOption>()
    const [timeSelected, setTimeSelected] = useState<FilterOption>()
    const {
      securities,
      stats,
      blocks,
      block,
      setBlock,
    } = props
    const shareholders = props.members?.filter((m: any) => m.holdings?.length > 0 || m.voided.length > 0)
      .map(m => {
        if (m.voided) {
          const voided = m.voided.map(v => ({ ...v, voided: true }))
          return { ...m, holdings: m.holdings.concat(voided) }
        }
        else return { ...m, holdings: m.holdings.concat(m.voided) }
      })
    const userState = props?.userStateRecords
    const sortedShareholders = shareholders ? shareholders?.sort((a, b) => b.memberOutstanding - a.memberOutstanding) : []

    let allHoldings: any[] = []
    sortedShareholders.forEach(s => allHoldings = allHoldings.concat(s.holdings))
    const holdingsList = allHoldings.filter(h => isHolding(h.holdingType))
    const shareholdings = allHoldings.filter(h => !isHolding(h.holdingType))

    const securitiesList = securities ? securities.filter(s => !s.parent).map((arg): any => {
      const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
      return { ...arg, stats: statsValue }
    }).sort((a, b) => b.stats.outstanding - a.stats.outstanding) : []
    const blocksList = blocks && blocks.length > 0 ? prepareBlocks(blocks) : []

    const headers = [
      { label: 'Equa Holder Id', key: 'holderId' },
      { label: 'Holder Number', key: 'number' },
      { label: 'Holder Name', key: 'name' },
      { label: 'Email', key: 'email' },
      { label: 'Equa Holding Id', key: 'id' },
      { label: 'Security Type', key: 'equity' },
      { label: 'Certificate Number', key: 'holdingName' },
      { label: 'Issue Date', key: 'date' },
      { label: 'Amount', key: 'amount' },
    ]

    const csvData: any = allHoldings.sort((a, b) => (b.value || 0) - (a.value || 0))
      .map((h: any, i: number) => {
        const owner = sortedShareholders?.find((s: any) => s.id === h?.owner!)
        const security = securitiesList?.find(s => s.hash === h.equity)
        return {
          holderId: owner?.id || '',
          name: owner?.name || '',
          email: owner?.email || '',
          id: h.id,
          equity: security ? formatSecurityName(security) + ` (${formatSecurityAbbreviation(security)})` : '',
          number: i + 1,
          holdingName: h.name,
          date: h.issueDate ? formatDateString(h.issueDate) : '',
          amount: h.value
        }
      })

    const groupOptions: FilterOptions = [
      { label: 'Holder', value: CapTableGrouping.member },
      { label: 'Holdings', value: CapTableGrouping.securityType }
    ]
    const noSelection = [{ label: 'No selection', value: 'current' }]
    const blockOptions: FilterOptions = blocksList?.length > 0 ? noSelection.concat(
      blocksList.map(block => {
        return block && {
          label: formatDateString(block.timestamp) + ' ' + formatTime(block.timestamp),
          value: block.hash
        }
      })) : noSelection

    useEffect(() => {
      const timeOption = blockOptions.find(b => b.value === block)
      if (timeOption) setTimeSelected({ label: `Date: ${timeOption.label}`, value: timeOption.value })
      const option = groupOptions[0]
      setGroupSelected({ label: `Group By: ${option.label}`, value: option.value })
    }, [])

    useEffect(() => {
      const filteredHoldings = holdingsList.filter(el => (el.convertibleInstrument || el.plan) && securitiesList.some(s => s.hash === el.parent))
        .map(holding => {
          const notes = holdingsList.filter(s => s.parent === holding.id)
          const value = notes.reduce((a, b) => a + b.value, 0)
          return {
            value,
            selected: false
          }
        })
      setChartValues(!groupBy ? sortedShareholders?.map((shareholder: any, i: number) => {
        return { value: shareholder.memberOutstanding + shareholder.memberFullyDiluted, selected: false }
      }) : securitiesList.map((securityType, i: number) => {
        return {
          value: Math.max(securityType?.stats?.totalAuthorized!, securityType?.stats?.outstanding!),
          selected: false
        }
      }).concat(filteredHoldings)
      )
      return () => {
        setSelectedRows({})
        setExpandedRows({})
      }
    }, [groupBy])

    const toggleSelectedRows = (key: number) => {
      let select = {}
      if (selectedRows && selectedRows[key] === true) {
        select = { [key]: false }
      } else {
        select = { [key]: true }
      }
      setSelectedRows(select)
    }
    const toggleExpandedRows = (key: number) => {
      let expand = expandedRows
      if (expand && expand[key] === true) {
        expand = { ...expand, [key]: false }
      } else {
        expand = { ...expand, [key]: true }
      }
      setExpandedRows(expand)
    }

    const handleSelection = (key: number) => {
      const chartV = chartValues
      toggleSelectedRows(key)
      chartV.map((arg, i: number) => {
        if (i === key) {
          return arg.selected = !arg.selected
        }
        return arg.selected = false
      })
      setChartValues(chartV)
    }

    const navigateAndUpdateBlock = (block: Hash) => {
      setBlock(block)
      if (block.length > 0) {
        navigate(captablePath, { organization, block })
      }
    }

    const dropOptions = [
      ['Transfer', () => !hasTransferFeature ? upgradeModal.setVisible(true) : navigate(transferShareholdingPath, { organization })],
      ['Draft', () => navigate(newShareholdingPath, { organization })],
      [<CSVLink
        filename={`Cap Table - ${currentOrganization?.name} - ${formatDateString(new Date())}.csv`}
        data={csvData}
        headers={headers}
        className='withPadding'>
        Export
      </CSVLink>],
    ]

    return <PageContent>
      {upgradeModal.component}
      <PageContentHeader title="Cap Table">
        <FlexRow alignItems={'flex-end'}>
          {showTransferButton ? (
            <ActionsDrop options={dropOptions} />
          ) : null}
        </FlexRow>
      </PageContentHeader>
      <StyledGroups>
        <GroupMultiSelect
          onChange={(selection: any) => {
            selection && navigateAndUpdateBlock(selection.value)
          }}
          value={timeSelected}
          options={blockOptions}
          placeholder={`Date: ${blockOptions[0].label}`}
          className='select'
        />
        <GroupMultiSelect
          onChange={(selection: any) => {
            if (selection) {
              setGroupSelected({ label: `Group By: ${selection.label}`, value: selection.value })
              setGroupBy(selection.value)
            }
          }}
          value={groupSelected}
          placeholder={`Group By: ${groupOptions[0].label}`}
          options={groupOptions}
          className='select right' />
      </StyledGroups>
      <RainbowBar colors={colorsList} blocks={chartValues} handleSelection={handleSelection} />
      <CapTableTable
        companyType={currentOrganization.companyType || CompanyType.Other}
        shareholders={sortedShareholders}
        shareholdings={shareholdings}
        selectedRows={selectedRows}
        expandedRows={expandedRows}
        toggleExpandedRows={toggleExpandedRows}
        securities={securitiesList}
        groupBy={groupBy}
        colorsList={colorsList}
        holdings={holdingsList}
        organization={organization}
        userState={userState}
        httpClient={httpClient}
      />
    </PageContent>
  }))))

const mapStateToProps: StateTransform<any> = s => ({
  currentOrganization: s.organization!.currentOrganization,
})

export const CaptablePage = connect(mapStateToProps)(CaptablePageComponent)
