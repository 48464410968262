import styled from 'styled-components'
import { gapSizes, screenSizes } from '@src/styles/theme'
import { crossWithHoverCss, FlexRow, fullScreenOvershadow } from '@src/styles'
import { rgba } from 'polished'

export const StyledModal = styled.div<any>`
  display: ${props => (props.show ? 'block' : 'none')};
  ${fullScreenOvershadow}
  height: ${props => props.absolute === 'true' && 'calc(100% - 56px)'};
  section {
    width: 90%;
    max-width: ${props => !props.max ? '500px' : props.max};
    border-radius: ${props => !props.max ? props.theme.radius : '8px'};
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align ? props.align : 'flex-start'};
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80%;
    position: fixed;
    background: ${props => props.theme.modalBack};
    color: ${props => props.theme.white};
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    input {
      width: 100%;
    }
    select {
      border: 1px solid ${props => props.theme.secondary};
    }
    // #root {
    //   overflow: hidden;
    // }
    // ::-webkit-scrollbar {
    //   width: 0;
    //   background: transparent;
    // }
  }
  @media(max-width: ${screenSizes.M}px) {
    section {
      width: 100% !important;
    }
  }
`

export const ModalCloseButton = styled<any>(FlexRow)`
  position: absolute;
  top: 32px;
  right: 32px;
  margin: 0 !important;
  ${crossWithHoverCss}
`

export const ButtonLayout = styled<any>(FlexRow)`
  background-color: ${props => rgba(props.theme.secondary, .05)};
  justify-content: flex-end;
  width: 100%;
  padding: 24px 32px;
  border-top: 1px solid #dddddd;
  button {
    height: 40px;
    padding: 0 32px !important;
  }
  @media(max-width: ${screenSizes.M}px) {
      flex-direction: column;
      button {
        width: 100%;
        margin: 8px 0 0 0;
        &:first-child {
          margin: 0;
        }
      }
  }
`
