import * as React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { gapSizes, Themes } from '@src/styles/theme'
import { MultiBoxField } from '@shared/components/multibox'
import { allPermissions } from '../utility'
import SelectCheckbox from '@image/select-check.png'
import { Ellipses } from '@shared/components/tables'
import { createRolePath, editRolePath, permissionsPath, viewRolePath } from '@logic'
import { whiteColor } from '@src/styles'
import AdminShield from '@image/admin.svg'
import { deleteRoleModalBundle } from './delete-role-modal'
import { deleteRole } from '@src/service'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { overflowParams, PathLink } from '@shared/components/navigation'
import { CommonSubmissionFooter } from '@shared/components/forms/common-submission-footer'

interface Props {
  roles: any[]
  values?: any
  edit?: boolean
  reload?: any
  navigate: any
  httpClient: any
  organization: string
  members?: any[]
}

const rowHeight = '56px'
const StyledTable = styled.div<any>`
  transition: all 300ms ease-in;
  position: relative;
  margin: 32px 0 64px 0;
  ::before {
    content:'';
    position: absolute;
    z-index: 1;
    height: 16px;
    bottom: 0;
    left: 0;
    width: 216px;
    background: ${props => props.theme.secondary};
  }
  ::after {
    content:'';
    position: absolute;
    z-index: 1;
    height: calc(100% - ${props => props.thresh === 'true' ? '18px' : '5px'});
    top: 2px;
    right: 0;
   border-right: 1px solid ${props => props.theme.listDesc};
  }
  table {
    padding-bottom: 4px;
    display: block;
    overflow-x: auto;
    border-radius: 4px;
    -moz-border-radius: 5px;
    border-top: 1px solid ${props => props.theme.listDesc};
    border-left: 1px solid ${props => props.theme.listDesc};
    color: ${props => props.theme.white};
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    thead {
      tr:first-child {
        th {
          border-top: none;
        }
      }
    }
    td {
        font-size: 16px;
        line-height: 24px;
        background: ${props => props.theme.newNav};
    }
    th {
        font-weight: bold;
        font-family: 'NunitoSansBold';
        background-color: ${props => props.theme.tableHeader} !important;
    }
    tr {
      :last-child {
        td {
          border-bottom: 1px solid ${props => props.theme.listDesc};
        }
      }
    }
    tbody {
      background-color: ${props => props.theme.whiteGlove};
    }
    &::-webkit-scrollbar {
      height: 12px;
      background-color: ${rgba(81, 111, 119, 0.101961)};
      border: 4px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      border: 2px solid transparent;
      background-color: #9CA6AD;
      border-radius: 6px;
      background-clip: padding-box;
      height: 8px;
    }
    &::-webkit-scrollbar-track-piece:horizontal:start {
      background: transparent;
      margin-left: 216px;
    }
  }
`

export const TableRow = styled.tr<any>`
  height: ${rowHeight};
  padding: 0 ${gapSizes.S};
  img {
    width: 20px;
    height: 20px;
    border-radius: ${props => props.edit !== 'true' && '50%'};
    vertical-align: middle;
  }
  .permissionInfo, .allPerm {
      position: sticky;
      left: 0;
      z-index: 1;
  }
  .permissionInfo {
    background-color: ${props => props.theme.tableHeader} !important;
    span {
      display: block;
      line-height: 24px;
      font-weight: bold;
      font-family: 'NunitoSansBold';
      margin: 0;
      color: ${props => props.theme.listText};
      :last-child {
        font-size: 14px;
        font-weight: 600;
        font-family: 'NunitoSansSemiBold';
        color: ${props => props.theme.listDesc};
      }
    }
  }
  .multi {
    margin: 0 auto !important;
    width: 20px;
  }
  th, td {
    text-align: center;
    padding: 16px;
    border-right: 1px solid ${props => props.theme.listDesc};
    border-top: 1px solid ${props => props.theme.listDesc};
    min-width: 265px;
    :last-child {
      border-right: none;
    }
    :first-child {
      text-align: start;
      min-width: 216px;
    }
  }
`

const HeadWrap = styled.div<any>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${props => props.edit === 'false' ?
    'center'
    :
    props.isAdmin === 'true' ? 'space-between' : 'space-around'};
  .ellipse {
    position: ${props => props.isAdmin === 'false' && 'absolute'};
    right: 0;
    .optionsWrap {
      padding: 0 !important;
    }
  }
  .head {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 16px;
    max-width: 153px;
    span {
      word-break: break-word;
      ${whiteColor}
      margin: 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .shield {
    margin-right: 11px;
  }
  svg {
    path {
        fill: ${props => props.theme.selected === Themes.LIGHT && '#2B3B44'}
    }
  }
`

export const RolePermissionsTable = withRouter((props: Props & RouteComponentProps<any>) => {
  const [selectedRole, setSelectedRole] = React.useState<string>('')
  const [hasMembers, setHasMembers] = React.useState<boolean>(false)
  const { roles, edit, values, navigate, organization, httpClient, reload, members } = props

  const deleteModal = deleteRoleModalBundle({
    title: hasMembers ? 'Are you sure?' : 'Delete this role?',
    submitText: 'Confirm',
    onSubmit: () => deleteRoleFunction(),
    onCancel: () => deleteModal.setVisible(false),
    withMembers: hasMembers
  })

  const deleteRoleFunction = async () => {
    await deleteRole(httpClient)({ organization, role: selectedRole })
    deleteModal.setVisible(false)
    reload()
  }

  const handleSelectAll = (role: string) => {
    const roleValue = roles?.find(r => r.id === role)
    if (!values[`${roleValue?.id}`]!) {
      const permission: any = {}
      allPermissions.forEach(p => {
        permission[`${p.id}`] = true
      })
      const v = {
        ...values, roles: { ...values.roles, [`${roleValue?.id}`]: { ...roleValue, permission } }
      }
      Object.assign(values, v)
    }
    else {
      delete values[`${roleValue?.id}`]
      delete values.roles[`${roleValue?.id}`].permission
    }
  }

  const handleSelect = (role: string, permission: string) => {
    const roleValue = roles?.find(r => r.id === role)
    const roleKey = `${roleValue?.id}`
    const data = values?.roles[roleKey]
    const rolePermissions = data?.permission || {}
    const currentLength = Object.entries(rolePermissions)
      .filter(([key, value]) => value && key !== permission)
      .map(([key, _]) => key)
      .concat(rolePermissions[permission] ? [] : [permission])
      .length || 0

    if (currentLength == allPermissions.length) {
      const v = { ...values, [roleKey]: true }
      Object.assign(values, v)
    } else {
      delete values[roleKey]
    }
  }

  const headers = !edit ? roles?.map(r => ({ name: r.name, id: r.id, isShared: r.isShared }) as any)
    :
    [''].concat(roles?.map(r => ({ name: r.name, id: r.id, isShared: r.isShared }) as any))

  const navigateEdit = (column: any) => {
    navigate(overflowParams(editRolePath, {
      organization,
      role: column.id,
      matrix: true,
      parent: window.location.pathname
    }))
  }

  return (
    <>
      {deleteModal.component}
      <StyledTable thresh={roles?.length > 5 ? 'true' : 'false'}>
        <table>
          <thead>
            <TableRow>
              {!edit && <th className='allPerm'>ALL PERMISSIONS</th>}
              {headers.map((column, i: number) => {
                if (i === 0 && edit) {
                  return <th className='allPerm' />
                }
                else {
                  return (
                    <th key={i}>
                      <HeadWrap isAdmin={column.isShared ? 'true' : 'false'} edit={edit ? 'true' : 'false'}>
                        {column.isShared! && edit && <AdminShield className='shield' />}
                        <PathLink
                          path={viewRolePath}
                          args={{ organization, role: column.id! }}
                          className='head'
                        >
                          {column.isShared! && !edit && <AdminShield className='shield' />}
                          <span>{column.name! || column}</span>
                        </PathLink>
                        {edit && column?.id! && <Ellipses options={
                          !column.isShared! ?
                            [
                              ['Edit', () => navigateEdit(column)],
                              ['Clone', () => navigate(overflowParams(
                                createRolePath,
                                { organization, clone: column.id!, matrix: true, parent: window.location.pathname }
                              ))],
                              ['Delete', () => {
                                const roleMembers = members?.filter(m => m.roles.includes(column.id))
                                if (roleMembers && roleMembers.length > 0) setHasMembers(true)
                                setSelectedRole(column.id!)
                                deleteModal.setVisible(true)
                              }],
                            ]
                            :
                            [['Edit', () => navigateEdit(column)]]
                        }
                        />
                        }
                      </HeadWrap>
                    </th>
                  )
                }

              })}
            </TableRow>
            {edit && <TableRow edit={edit ? 'true' : 'false'}>
              <th className='allPerm'>ALL PERMISSIONS</th>
              {headers.map((column, i: number) => {
                if (column !== '')
                  return (
                    <th key={i}>
                      <MultiBoxField
                        name={`${column.id!}`}
                        type="checkbox"
                        noLabel={true}
                        handleClick={() => handleSelectAll(column.id!)}
                        disabled={column?.isShared!}
                      />
                    </th>
                  )
              })}
            </TableRow>
            }
          </thead>
          <tbody>
            {allPermissions.map((p, index: number) => {
              return (
                <TableRow key={index} edit={edit ? 'true' : 'false'}>
                  <td className="permissionInfo">
                    <span>{p.name}</span>
                    <span>{p.description}</span>
                  </td>
                  {roles?.map((cell, i) => {
                    const hasPermission = cell.permissions?.find(perm => perm.id === p.id)
                    const col = headers[i + 1]
                    return <td key={i}>
                      {edit ?
                        <MultiBoxField
                          name={`roles.${cell.id}.permission.${p.id}`}
                          type="checkbox"
                          noLabel={true}
                          onChange={() => handleSelect(col?.id!, p.id)}
                          disabled={cell.isShared}
                        /> :
                        hasPermission && <img src={SelectCheckbox} alt="check" />
                      }
                    </td>
                  })}
                </TableRow>
              )
            })}
          </tbody>
        </table>
      </StyledTable>
      {edit && <CommonSubmissionFooter
        submitText={'Save'}
        disabled={props?.invalid! || props?.submitting!}
        skipText={'Cancel'}
        skip={{ path: permissionsPath, args: { organization } }}
      />
      }
    </>
  )
})
