import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import {
    editPoolPath,
    newPoolPath,
    organizationViewSecurityPath,
    PageProps,
    planIncentivePath,
    poolPath,
    poolsPath
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission, getIncentiveTypes } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadOrganizationHoldings, loadPlan, loadSecurities } from '@helpers/loaders'
import { deleteHolding, ExpandedHoldingsResponse, GetPlanResponse, GetSecuritiesResponse } from '@src/service'
import { EmptyLanding } from '@shared/components/empty-landing'
import { ellipsesColumn, Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { formatSecurityName, optionalCommaNumber, toCommaNumber } from '@shared/helpers'
import { basicDeleteModal } from '@shared/components/modal'
import { filterEquities, filterIncentives, filterPools } from '../logic'
import { optionalShares } from '@modules/convertibles/components'

type Props = PageProps & ExpandedHoldingsResponse & GetSecuritiesResponse & GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganizationHoldings('all'), loadSecurities(), loadPlan)

export const PoolsList = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const [selectedPool, setSelectedPool] = React.useState<any>()
        const { params, navigate, securities, holdings, httpClient, plan, reload } = props
        const { organization } = params
        const securitiesList = filterEquities([], securities, true)
        const incentivesList = filterIncentives(securitiesList, plan)
        const pools = filterPools(holdings, incentivesList, plan?.hash).map(p => {
            const options = holdings?.filter(holding => (holding.parent! === p.id) || (holding.parent! === p.hash))
            const totalVested = options.reduce((a, b) => a + b?.vestedShares, 0)
            const totalExercised = options.reduce((a, b) => a + b?.exercisedShares, 0)
            const totalUnvested = (p.value || 0) - totalVested
            const incentive = incentivesList.find(i => i.hash === p.parent)
            return { ...p, incentive, totalVested, totalExercised, totalUnvested }
        })

        const allocatedTotal = pools?.reduce((a, b) => a + b.value, 0)
        const vestedTotal = pools?.reduce((a, b) => a + b.totalVested, 0)
        const exercisedTotal = pools?.reduce((a, b) => a + b.totalExercised, 0)
        const unVestedTotal = pools?.reduce((a, b) => a + b.totalUnvested, 0)

        const handleDeletePool = async () => {
            await deleteHolding(httpClient)({ holding: selectedPool?.hash })
            deleteModal.setVisible(false)
            reload()
        }
        const deleteModal = basicDeleteModal(
            handleDeletePool,
            [selectedPool?.name || ''],
        )
        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink path={poolPath} args={{ organization, plan: params.plan, pool: row.original.hash }}>
                            {row.original.name}
                        </PathLink>
                    )
                },
                Footer: ({ data }: any) => <div>Total</div>
            },
            {
                Header: 'Incentive Type',
                accessor: 'incentiveType',
                Cell: ({ row }: CellProps<any>) => <span>{getIncentiveTypes[row.original.incentive.type]}</span>,
                className: 'left'
            },
            {
                Header: 'Incentive Name',
                accessor: 'incetiveName',
                Cell: ({ row }: CellProps<any>) => {
                    const incentive = row.original.incentive
                    return <PathLink
                        path={planIncentivePath}
                        args={{ organization, plan: params.plan, incentive: row.original.incentive.hash }}
                        target={'_blank'}>
                        {incentive.name} ({incentive.shareClassPrefix})
                    </PathLink>
                }
                ,
                className: 'left'
            },
            {
                Header: 'Approved Equity',
                accessor: 'approvedEquity',
                Cell: ({ row }: CellProps<any>) => {
                    const incentive = row.original.incentive
                    const incentiveEquity = securitiesList.find(s => s.hash === incentive.parent)
                    return (
                        <PathLink
                            path={organizationViewSecurityPath}
                            args={{ organization, security: incentiveEquity?.hash }}
                            target={'_blank'}>
                            {incentiveEquity &&
                                formatSecurityName(incentiveEquity)} {incentiveEquity?.shareClassPrefix ? `(${incentiveEquity?.shareClassPrefix})` : ''}
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Allocated Amount',
                accessor: 'allocatedAmount',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.value))}</span>,
                className: 'right',
                Footer: ({ data }: any) => <div>{toCommaNumber(optionalShares(allocatedTotal))}</div>
            },
            {
                Header: 'Vested Amount',
                accessor: 'totalVested',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.totalVested))}</span>,
                className: 'right',
                Footer: ({ data }: any) => <div>{toCommaNumber(optionalShares(vestedTotal))}</div>
            },
            {
                Header: 'Exercised Amount',
                accessor: 'totalExercised',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.totalExercised))}</span>,
                className: 'right',
                Footer: ({ data }: any) => <div>{toCommaNumber(optionalShares(exercisedTotal))}</div>
            },
            {
                Header: 'Unvested Amount',
                accessor: 'totalUnvested',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.totalUnvested))}</span>,
                className: 'right',
                Footer: ({ data }: any) => <div>{toCommaNumber(optionalShares(unVestedTotal))}</div>
            },
            ellipsesColumn(
                [
                    ['View', ({ row }) => navigate(poolPath, { organization, plan: params.plan, pool: row.original.hash })],
                    ['Edit', ({ row }) => navigate(editPoolPath, { organization, plan: params.plan, pool: row.original.hash })],
                    ['Delete', ({ row }) => { setSelectedPool(row.original), deleteModal.setVisible(true) }]
                ]
            ),
        ]

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    {pools.length > 0 ?
                        <>
                            <PageContentHeader title="Pools">
                                <PathLinkButton path={newPoolPath} args={params}>
                                    + Add
                                </PathLinkButton>
                            </PageContentHeader>
                            <Table columns={columns} data={pools} scrollingTable={true} withAllBorders={true} />
                        </>
                        :
                        <EmptyLanding path={poolsPath} params={params} />
                    }
                </PageContent>
            </>
        )
    })
)
