import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, planIncentivesPath, plansPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { IncentiveForm } from '../components/incentive-form'
import {
    createAuthorization,
    createSecurity,
    FilesResponse,
    GetLegendsResponse,
    GetPlanResponse,
    GetSecuritiesResponse,
    NewAuthorizationRequest,
    postFile
} from '@src/service'
import { BaseLinkProps } from '@shared/components/navigation'
import { loadLegends, loadPlan, loadSecurities } from '@shared/helpers'
import { filterEquities } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & PermissionProps & GetSecuritiesResponse & GetPlanResponse & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities('true'), loadPlan, loadLegends(true))

export const CreateIncentive = withData(
    withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
        const { httpClient, navigate, params, securities, stats, plan, legends } = props
        const { organization } = params
        const initialValues = {}
        const approvedEquities = plan?.approvedEquities?.equity
        const securitiesList = filterEquities((stats || []), securities)
        const equitiesList = securitiesList.filter(s => approvedEquities?.some((a: any) => a === s.hash))

        const onUpload: UploadHandler = async files => {
            const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
            const file = response.files[0]
            return {
                hash: file.hash,
                name: file.filename,
                id: file.id,
            }
        }

        const onSubmit = async (values: any) => {
            const documents = values?.approvalDocument?.filter((d: any) =>
                (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
            const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
                const newDocument = { ...document }
                delete newDocument.key
                return newDocument
            }) : []

            const request = {
                type: values?.type,
                name: values?.name,
                parent: values?.approvedEquity,
                shareClassPrefix: values?.abbreviation,
                price: values?.pricePerUnit!,
                legend: values?.legend!,
            } as any

            const newIncentive = await createSecurity(httpClient)({ organization }, request)
            if (newIncentive?.security) {
                modifyAuthorizations({ approvalDocuments, target: newIncentive.security.hash!, httpClient, organization })
                navigate(planIncentivesPath, { organization, plan: params.plan })
            }
            else console.log('create incentive error') // TODO: add error message toast
        }

        const parent: BaseLinkProps = { path: plansPath, args: { organization } }

        return (
            <PageContent>
                <PageContentHeader title="Create New Incentive" parent={parent} />
                <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                    submitText="Save">
                    <IncentiveForm onUpload={onUpload} securities={equitiesList} params={params} legends={legends} />
                </CommonForm>
            </PageContent>
        )
    })
)
