import * as React from 'react'
import * as PIXI from 'pixi.js-legacy'
import _ from 'lodash'
import styled, { ThemeProps } from 'styled-components'
import { FlexRow, gapSizes, Themes } from '@src/styles'
import { formatDateString, safeDivide, toCommaFloat, toCommaNumber, validateUsername } from '@shared/helpers'
import { dateDiffInDays } from '@modules/convertibles/utility'

type DisplayChartProps = ThemeProps<any> & {
    app: PIXI.Application
    durationMonths: number
    data: ReadonlyArray<object>
    isDiscreet: boolean
    theme: any
    hasStartDate: boolean
    cliffMonths: number
    eventsRange: any
    emptyRange: any
    forView: boolean
    notify: boolean
    totalAmount?: number
    custom?: boolean
    vestingData?: any
    isAbsolute: boolean
    noMargin?: boolean
    noMarginBottom?: boolean
    customWidth?: number
    customHeight?: number
}

const ChartWrapper = styled.div<{ noMargin: string, noMarginBottom: string }>`
  max-height: 288px;
  width: 100%;
  max-width: 1280px;
  height: auto;
  margin: ${props => props.noMargin === 'true' ? 0 : `${gapSizes.M} 0 60px 0`};
  margin-bottom: ${props => props.noMarginBottom == 'true' ? 0 : ''};
`

const StyledChart = styled<any>(FlexRow)`
    width: 100%;
    background-color: ${({ theme }) => theme.chartBack};
    border-radius: 8px;
    overflow-x: auto;
    #useFont {
        font-family: NunitoSans;
    }
    ::-webkit-scrollbar-thumb {
        border: 5px solid transparent;
    }
`

const getBarWidth = (
    app: PIXI.Application,
    isDiscreet: boolean,
    isAbsolute: boolean,
    cliffLineWidth: number,
    vestingData: any,
    emptyRange: any,
    barsRange: any) => {
    if (isDiscreet) {
        if (isAbsolute) return safeDivide(app.screen.width - 117, vestingData.events.length)
        else return safeDivide(app.screen.width - (117 + cliffLineWidth), vestingData.events.length)
    }
    else {
        const emptyWidth = emptyRange.length * 24.25
        const spaceBetween = barsRange.length * 4
        const remainingWidth = app.screen.width - (117 + emptyWidth + spaceBetween)
        return safeDivide(remainingWidth, barsRange.length)
    }
}

export const RenderChartPixi = (props: DisplayChartProps) => {
    const {
        app,
        vestingData,
        data,
        customWidth,
        isAbsolute,
        customHeight,
        forView,
        notify,
        isDiscreet,
        hasStartDate,
        eventsRange,
        cliffMonths,
        emptyRange,
        theme,
        totalAmount,
    } = props
    const ref: any = React.useRef(null)

    const container = new PIXI.Container()
    const graphics = new PIXI.Graphics()
    const lines = new PIXI.Graphics()
    const axisText = new PIXI.Graphics()
    const emptyBars = new PIXI.Graphics()
    const bars = new PIXI.Graphics()
    const eventsBar = new PIXI.Graphics()
    const footer = new PIXI.Graphics()
    const selected = theme.selected

    const maxBarHeight = app.screen.height - 149
    const linesRange = _.range(0, 5)
    const barsRange = data as any
    const total = isAbsolute ? barsRange[barsRange.length - 1]?.value : totalAmount
    const withCliff = cliffMonths !== 0 ? true : false
    const cliffLineWidth = cliffMonths ? (cliffMonths == 1 ? 34.25 : 24.25) * (cliffMonths) : 0

    const calculateBarHeight = (value: number) => {
        if (isDiscreet) {
            if (!isAbsolute) {
                if (forView) return (value * maxBarHeight) / 1
                else return (value * maxBarHeight) / total
            }
            else return (value * maxBarHeight) / total
        }
        else return (value * maxBarHeight) / total
    }

    const mappedBars = barsRange?.map((bar: any, i: number) =>
    ({
        ...bar,
        barHeight: calculateBarHeight(bar.value),
        index: i,
        width: getBarWidth(app, isDiscreet, isAbsolute, cliffLineWidth, vestingData, emptyRange, barsRange)
    }))

    const totalRange = emptyRange.concat(mappedBars as any)
    const totalBarsWidth = totalRange.reduce((a: any, b: any) => a + b.width, 0) + 117 + (totalRange.length * 4)
    const barWidth = getBarWidth(app, isDiscreet, isAbsolute, cliffLineWidth, vestingData, emptyRange, barsRange)
    const frequency = vestingData.frequencyMonths
    const tickColor = selected === Themes.LIGHT ? 0x262626 : 0xFFFFFF
    const textColor = selected === Themes.LIGHT ? 0x262626 : 0x9CA6AD

    const calculateCanvasWidth = () => {
        if (isDiscreet) {
            if (!isAbsolute) {
                if (totalBarsWidth > app.screen.width) return totalBarsWidth
                else return app.screen.width
            }
            else {
                if (totalRange.length > 9) {
                    if (totalBarsWidth > app.screen.width) return totalBarsWidth
                }
                else return app.screen.width
            }
        }
        else {
            if (totalBarsWidth > app.screen.width) return totalBarsWidth
            else return app.screen.width
        }
    }

    if (isDiscreet && isAbsolute) {
        renderEvents(app, eventsRange, eventsBar, footer, barWidth, tickColor, textColor, cliffMonths, isDiscreet, withCliff, isAbsolute)
    } else if (!isAbsolute) {
        if (hasStartDate || forView) {
            renderEvents(app, eventsRange, eventsBar, footer, barWidth, tickColor, textColor, cliffMonths, isDiscreet, withCliff, isAbsolute)
        }
    }
    renderBars(bars, totalRange, mappedBars, isDiscreet, isAbsolute, forView, total, tickColor, notify, withCliff, frequency)
    container.addChild(graphics, axisText, lines, emptyBars, bars, eventsBar, footer)
    React.useEffect(() => {
        if (ref?.current && data) {
            ref.current.appendChild(app.view)
            app.screen.width = calculateCanvasWidth()
            app.renderer.resize(app.screen.width, app.screen.height)
            renderAxisLines(app, lines, linesRange, axisText, textColor)
            app.stage.removeChildren()
            app.stage.addChild(container)
            app.start()
        }
        return () => {
            app.renderer.resize(1280, 288)
            app.stop()
        }
    }, [data, vestingData, hasStartDate])

    return <ChartWrapper
        noMargin={props?.noMargin ? 'true' : 'false'}
        noMarginBottom={props?.noMarginBottom ? 'true' : 'false'}
    >
        <StyledChart ref={ref} />
    </ChartWrapper>
}

const renderEvents = (
    app: PIXI.Application,
    eventsRange: any,
    bars: PIXI.Graphics,
    footer: PIXI.Graphics,
    barWidth: number,
    tickColor: number,
    textColor: number,
    cliffMonths?: number,
    isDiscreet?: boolean,
    withCliff?: boolean,
    isAbsolute?: boolean) => {
    let eventStartX = 82
    let width = safeDivide(app.screen.width - 117, eventsRange.length - 1)
    let eventTotal = 0

    const renderBaseText = (index: number, y: number, eventStartX: number, noCliff?: boolean) => {
        const renderFirstYearText = () => {
            const firstYearText = new PIXI.Text(noCliff ? '(Vesting Calculation Date)' : '(Cliff Date: \n Vesting Begins)', {
                fill: textColor,
                fontSize: 12,
                breakWords: true,
                lineHeight: 20,
                fontWeight: '600'
            })
            firstYearText.x = eventStartX
            firstYearText.y = y + 12
            firstYearText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
            footer.addChild(firstYearText)
        }
        switch (index) {
            case 0:
                if (!withCliff) {
                    renderFirstYearText()
                }
            case 1:
                if (withCliff) {
                    renderFirstYearText()
                }
        }
    }

    const renderTicker = (y: number) => {
        bars.beginFill(tickColor)
        bars.drawRect(eventStartX, y - 20, 1.1, 7.5)
        bars.endFill()
    }

    eventsRange.forEach((l: any, i: number) => {
        const y = 220
        if (!isDiscreet) {
            let text = ''
            const cliffLineWidth = cliffMonths ? (cliffMonths == 1 ? 34.25 : 24.25) * (cliffMonths) : 0

            if (i !== 0) {
                if (withCliff) {
                    if (i == 1) eventStartX = eventStartX + cliffLineWidth
                    else {
                        width = safeDivide(app.screen.width - (117 + cliffLineWidth), eventsRange.length - 2)
                        eventStartX = eventStartX + width
                    }
                }
                else eventStartX = eventStartX + width
            }

            if (i == 0) {
                if (withCliff) {
                    text = 'Day 1'
                    bars.beginFill(tickColor)
                    bars.drawRect(eventStartX, y - 20, cliffLineWidth, 1)
                    bars.endFill()
                }
                else {
                    text = 'Day 1'
                    renderBaseText(i, y, eventStartX, true)
                }
                renderTicker(y)
            } else {
                if (i == 1) {
                    if (withCliff) {
                        if (cliffMonths && cliffMonths < 12) {
                            text = `${(cliffMonths)} ${cliffMonths == 1 ? 'month' : 'months'}`
                        }
                        else {
                            const cliffInYears = safeDivide((cliffMonths || 0), 12)
                            text = `${cliffInYears + ' years'}`
                        }
                    } else text = `${i + ' year'}`

                    renderBaseText(i, y, eventStartX)
                    renderTicker(y)
                }
                else if (i == (eventsRange.length - 1)) {
                    text = `${i} years`
                    const fullVestedText = new PIXI.Text('(Fully Vested)', {
                        fill: textColor,
                        fontSize: 12,
                        breakWords: true,
                        lineHeight: 20,
                        fontWeight: '600'
                    })
                    fullVestedText.x = eventStartX - 75
                    fullVestedText.y = y + 12
                    fullVestedText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
                    footer.addChild(fullVestedText)
                }
                else {
                    text = `${i} years`
                    renderTicker(y)
                }
            }

            const eventsText = new PIXI.Text(text, {
                fill: textColor,
                fontSize: 12,
                breakWords: true,
                lineHeight: 20,
                fontWeight: '600'
            })
            if (i == (eventsRange.length - 1)) eventsText.x = eventStartX - 40
            else eventsText.x = eventStartX
            eventsText.y = y - 8
            eventsText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
            footer.addChild(eventsText)
        }
        else {
            const shared: any = {
                fill: textColor,
                fontSize: 12,
                breakWords: true,
                lineHeight: 20,
                fontWeight: '600'
            }

            const currentValue = l.value
            eventTotal = eventTotal + currentValue

            if (isAbsolute) {
                const renderAbsoluteText = () => {
                    const value = i == 0 ? toCommaNumber(currentValue)
                        :
                        `+ ${toCommaNumber(currentValue)} = ${toCommaNumber(eventTotal)}`
                    const eventNumber = new PIXI.Text(`Event ${i + 1}`, shared)
                    const eventDate = new PIXI.Text(`${formatDateString(l.date)}`, shared)
                    const eventTotalText = new PIXI.Text(`${value} total`, shared)
                    eventNumber.x = eventStartX + (barWidth / 2)
                    eventNumber.y = y - 8
                    eventDate.x = eventStartX + (barWidth / 2) - 10
                    eventDate.y = y - 8 + 20
                    eventTotalText.x = eventStartX + (barWidth / 2) - (i !== 0 ? 25 : 8)
                    eventTotalText.y = y - 8 + 40
                    eventNumber.texture.baseTexture.scaleMode =
                        eventDate.texture.baseTexture.scaleMode =
                        eventTotalText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
                    footer.addChild(eventNumber, eventDate, eventTotalText)
                }

                if (i !== 0) eventStartX = eventStartX + barWidth

                if (eventsRange.length >= 8) {
                    if (!Boolean(i % 2)) renderAbsoluteText()
                }
                else renderAbsoluteText()
            }
            else {
                const renderRelativeText = (month: number, eventStartX: number) => {
                    const eventNumber = new PIXI.Text(`Event ${l.index + 1}`, shared)
                    const eventDate = new PIXI.Text(`Month ${month}`, shared)
                    eventNumber.x = eventStartX + (barWidth / 2)
                    eventNumber.y = y - 8
                    eventDate.x = eventStartX + (barWidth / 2)
                    eventDate.y = y - 8 + 20
                    eventNumber.texture.baseTexture.scaleMode =
                        eventDate.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
                    footer.addChild(eventNumber, eventDate)
                }

                let text = ''
                const month = l.month ? l.month : ''
                const cliffLineWidth = cliffMonths ? (cliffMonths == 1 ? 34.25 : 24.25) * (cliffMonths) : 0

                if (month) {
                    if (l.index == 0) eventStartX = eventStartX - 10
                    else eventStartX = eventStartX + barWidth
                    renderRelativeText(month, eventStartX)
                }
                else {
                    if (i !== 0) {
                        if (withCliff) {
                            if (i == 1) eventStartX = eventStartX + cliffLineWidth
                            else {
                                barWidth = safeDivide(app.screen.width - (117 + cliffLineWidth), eventsRange.length - 2)
                                eventStartX = eventStartX + barWidth
                            }
                        }
                        else eventStartX = eventStartX + barWidth
                    }

                    if (i == 0) {
                        if (withCliff) {
                            text = 'Day 1'
                            bars.beginFill(tickColor)
                            bars.drawRect(eventStartX, y - 20, cliffLineWidth, 1)
                            bars.endFill()
                        }
                        else {
                            text = 'Day 1'
                            renderBaseText(i, y, eventStartX, true)
                        }
                        renderTicker(y)
                    } else {
                        if (i == 1) {
                            if (withCliff) {
                                if (cliffMonths && cliffMonths < 12) {
                                    text = `${(cliffMonths)} ${cliffMonths == 1 ? 'month' : 'months'}`
                                }
                                else {
                                    const cliffInYears = safeDivide((cliffMonths || 0), 12)
                                    text = `${cliffInYears + ' years'}`
                                }
                            } else text = `${i + ' year'}`

                            renderBaseText(i, y, eventStartX)
                            renderTicker(y)
                        }
                    }

                    const eventsText = new PIXI.Text(text, {
                        fill: textColor,
                        fontSize: 12,
                        breakWords: true,
                        lineHeight: 20,
                        fontWeight: '600'
                    })
                    if (i == (eventsRange.length - 1)) eventsText.x = eventStartX - 40
                    else eventsText.x = eventStartX
                    eventsText.y = y - 8
                    eventsText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
                    footer.addChild(eventsText)
                }
            }
        }
    })
}

const renderAxisLines = (app: PIXI.Application, lines: PIXI.Graphics, linesRange: any[], axisText: PIXI.Graphics, textColor: number) => {
    linesRange.forEach((l, i: number) => {
        const textAmount = 100 - (i * 25)
        const basicText = new PIXI.Text(textAmount == 0 ? `${textAmount}` : `${textAmount}%`, {
            fill: textColor,
            fontSize: 14,
            lineHeight: 19,
        })
        const x = 82
        const y = i * 35 + 60
        lines.beginFill(0xCEDAE1)
        lines.alpha = 0.3
        lines.drawRect(x, y, app.screen.width - 114, 1.1)
        lines.endFill()
        basicText.x = textAmount == 100 ? 32 : textAmount == 0 ? 58 : 38
        basicText.y = y - 8
        basicText.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
        axisText.addChild(basicText)
    })
}

const renderHoverText = (
    current: any,
    total: number,
    hover: PIXI.Graphics,
    hoverX: number,
    hoverY: number,
    month: number,
    isDiscreet: boolean,
    isAbsolute: boolean,
    forView: boolean,
    totalAmount: number) => {
    const sharedStyle = {
        fill: 0x262626,
        fontSize: 10,
        lineHeight: 14,
    }
    const eventCount = new PIXI.Text(`Event: ${current.index + 1}`, { ...sharedStyle, fontWeight: 'bold' })
    const eventDate = new PIXI.Text(`Date: ${!isDiscreet ? (current.date || `Month ${month}`)
        :
        (isDiscreet && isAbsolute) ? current.date : 'Month ' + current.event.month}`, sharedStyle)
    const eventPerVested = new PIXI.Text(`Percentage Vested: ${current.percentage}%`, sharedStyle)
    const eventGranted = totalAmount ? new PIXI.Text(`Total Granted: ${toCommaFloat(totalAmount)}`, sharedStyle) : undefined
    const eventVested = current?.value ?
        new PIXI.Text(`Total Vested: ${toCommaFloat(current.value)}`, { ...sharedStyle, fill: 0x33AA40, fontWeight: 'bold' }) : undefined
    const eventUnvested = total && current?.value ?
        new PIXI.Text(`Unvested: ${toCommaFloat(total - current.value)}`, { ...sharedStyle, fill: 0xFF5656, fontWeight: 'bold' }) : undefined
    const mapText = _.concat([], [eventCount, eventDate, eventPerVested, eventGranted, eventVested, eventUnvested]).filter(a => a)
    mapText.map((m, i: number) => {
        if (m) {
            m.x = hoverX + 16
            m.y = hoverY + 10 + (i * 13)
        }
    })

    hover.addChild(eventCount, eventDate, eventPerVested)
    eventCount.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
    eventDate.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
    eventPerVested.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST

    if (eventGranted) {
        if (isAbsolute || !forView) {
            eventGranted.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
            hover.addChild(eventGranted)
        }
    }
    if (eventVested) {
        if (isAbsolute || !forView) {
            eventVested.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
            hover.addChild(eventVested)
        }
    }
    if (eventUnvested) {
        if (isAbsolute || !forView) {
            eventUnvested.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST
            hover.addChild(eventUnvested)
        }
    }
}

const renderHover = (
    bars: PIXI.Graphics,
    bar: PIXI.Graphics,
    info: PIXI.Graphics,
    current: any,
    hoverX: number,
    hoverY: number,
    total: number,
    i: number,
    isDiscreet: boolean,
    isAbsolute: boolean,
    forView: boolean,
    totalAmount: number,
    frequency?: number) => {
    const triangle = new PIXI.Graphics()
    let month = current.index + 1
    if (!isDiscreet) {
        if (frequency) month = (month * frequency)
    }

    bar.on('pointerover', () => {
        if (current.value !== 0) {
            const infoWidth = 150
            let infoHeight = isAbsolute ? 100 : forView ? 70 : 100
            let y = 0
            let x = 0
            if (isDiscreet) {
                bar.alpha = 0.9
                x = hoverX + ((current.width / 2) - (infoWidth / 2))
                y = hoverY - (infoHeight - (current.barHeight / 3))
            } else {
                x = hoverX + ((current.width / 2) - (infoWidth / 2))
                y = hoverY - (infoHeight - (current.barHeight / 3))
            }

            info.beginFill(0xFAFAFA)
            triangle.beginFill(0xFAFAFA)
            info.drawRoundedRect(x, y, infoWidth, infoHeight, 5)

            renderHoverText(current, total, info, x, y, month, isDiscreet, isAbsolute, forView, totalAmount)
            const triangleY = y + infoHeight
            const triangleX = (x - 3) + (infoWidth / 2)
            triangle.moveTo(triangleX, triangleY)
            triangle.lineTo(triangleX, triangleY)
            triangle.lineTo(triangleX + 3, (triangleY + 6))
            triangle.lineTo(triangleX + 7, triangleY)
            triangle.endFill()
            info.addChild(triangle)
            info.alpha = 1
            bars.addChild(info)
        }
    })
    bar.on('pointerout', () => {
        if (isDiscreet) bar.alpha = 1
        info.removeChildren()
        info.clear()
    })
}

const renderBars = (
    bars: PIXI.Graphics,
    totalRange: any[],
    mappedBars: any[],
    isDiscreet: boolean,
    isAbsolute: boolean,
    forView: boolean,
    totalAmount: number,
    tickColor: number,
    notify: boolean,
    withCliff: boolean,
    frequency: number) => {
    let barsStartY = 199.5
    let barsStartX = 82
    const totalValue = totalRange.reduce((a, b) => a + b.amount, 0)

    // const renderNotification = (bar: PIXI.Graphics, barsStartX: number, width: number, dateDiff: number) => {
    //     const xAxis = safeDivide(width, 30) * Math.abs(dateDiff)
    //     const x = barsStartX + xAxis
    //     bar.beginFill(tickColor)
    //     bar.drawRect(x, 50, 2.17, 150)
    //     bar.endFill()
    //     //circle on top
    //     bar.beginFill(tickColor)
    //     bar.drawCircle(x + 1, 50, 4)
    //     bar.endFill()
    //     //top text
    //     const todayText = new PIXI.Text('Today',
    //         {
    //             fill: tickColor,
    //             fontSize: 14,
    //             lineHeight: 19,
    //         })
    //     todayText.x = x - 18
    //     todayText.y = 27
    //     bar.addChild(todayText)
    // }

    totalRange.forEach((l: any, i: number) => {
        const bar = new PIXI.Graphics()
        const info = new PIXI.Graphics()
        const currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        const eventDate = new Date(l.date || '')
        const nextEvent = totalRange[i + 1]?.date
        const nextDate = nextEvent ? new Date(nextEvent) : undefined
        const eventIsVested = currentDate >= eventDate
        const smallerThanCurrent = eventIsVested && currentDate < (nextDate || 0)
        const plusMinus = smallerThanCurrent ? 1 : 0
        const currentVesting = (currentDate.getMonth() === eventDate.getMonth() ||
            (currentDate.getMonth() - plusMinus) === eventDate.getMonth()) &&
            currentDate.getFullYear() === eventDate.getFullYear()
        const dateDiff = dateDiffInDays(currentDate, eventDate)
        const showNotification = !notify && (currentVesting)

        if (!isDiscreet) {
            barsStartX = barsStartX + ((totalRange[i - 1]?.width || 0) + 4)
            barsStartY = barsStartY - ((l.barHeight || l.value) - (mappedBars[l.index - 1]?.barHeight || 0))
            bar.beginFill(eventIsVested && !notify ? 0x27AE60 : 0x56CCF2)
            bar.drawRoundedRect(barsStartX, barsStartY, l.width, (l.barHeight || l.value), 2)
            bar.interactive = true
            bar.buttonMode = true

            if (l.value !== 0) {
                renderHover(bars, bar, info, l, barsStartX, barsStartY, totalValue, i, isDiscreet, isAbsolute, forView, totalAmount, frequency)
            }
            bar.endFill()
            bars.addChild(bar)
            // if (showNotification) {
            //     if (withCliff) {
            //         if (l.index == 0) {
            //             if (eventDate > currentDate) renderNotification(bar, barsStartX, l.width, 0)
            //         } else {
            //             if (eventDate > currentDate) renderNotification(bar, barsStartX, l.width, 0)
            //             else if (smallerThanCurrent) renderNotification(bar, barsStartX, l.width, dateDiff)
            //         }
            //     }
            //     else if (smallerThanCurrent) renderNotification(bar, barsStartX, l.width, dateDiff)
            // }
        }
        else {
            barsStartX = barsStartX + ((totalRange[i - 1]?.width || 0) + 4)
            barsStartY = barsStartY - ((l.barHeight || 0) - (mappedBars[l.index - 1]?.barHeight || 0))
            bar.beginFill(eventIsVested && !notify ? 0x27AE60 : 0x56CCF2)
            bar.drawRect(barsStartX, barsStartY, l.width, l.barHeight)
            bar.interactive = true
            bar.buttonMode = true
            renderHover(bars, bar, info, l, barsStartX, barsStartY, totalValue, i, isDiscreet, isAbsolute, forView, totalAmount)
            bar.endFill()
            bars.addChild(bar)
            // if (showNotification && eventIsVested) renderNotification(bar, barsStartX, l.width, dateDiff)
        }
    })
}
