import { HttpClient, HttpError, WebResponse } from '@src/service'
import { clearDataCache, getDataCacheValue, setDataCacheValue } from '@shared/helpers'

enum FetchState {
  loading = 'loading'
}

const fetchStates: { [key: string]: FetchState } = {}

const pause = (duration: number) => new Promise(resolve => setTimeout(resolve, duration))

// This function will wait for a limited amount of time for a response from a concurrent GET request
async function waitForOtherRequest<T>(urlPath: string): Promise<WebResponse<T> | undefined> {
  const timeout = 2 * 1000
  const startTime = Date.now()

  while (Date.now() - startTime < timeout) {
    const value = getDataCacheValue<WebResponse<T>>(urlPath)
    if (value) return value

    if (!fetchStates[urlPath]) {
      console.log(`Cached response for duplicate fetch result was deleted early: ${urlPath}`)
      return undefined
    }
    await pause(10)
  }

  console.log(`Waiting for duplicate fetch timed out for ${urlPath}`)
  return undefined
}

async function httpGet<T>(httpClient: HttpClient, urlPath: string, withoutBaseUrl?: boolean): Promise<WebResponse<T>> {
  fetchStates[urlPath] = FetchState.loading
  const response = await httpClient.get<T>(urlPath, withoutBaseUrl)
  delete fetchStates[urlPath]
  return response
}

// This is a wrapper around HTTP GET fetching.  If a GET request is already being made with the same URL,
// this function will wait for the response from that request instead of spawning another request
async function httpGetWithoutDuplication<T>(httpClient: HttpClient, urlPath: string, withoutBaseUrl?: boolean): Promise<WebResponse<T>> {
  const state = fetchStates[urlPath]
  if (state == FetchState.loading) {
    const response = await waitForOtherRequest<T>(urlPath)
    return response
      ? response
      : httpGet(httpClient, urlPath, withoutBaseUrl) // Waiting for the other request timed out so just go ahead and make a new request
  } else {
    return httpGet(httpClient, urlPath, withoutBaseUrl)
  }
}

export async function httpGetWithCache<T = any | undefined>(httpClient: HttpClient, urlPath: string, withoutBaseUrl?: boolean): Promise<WebResponse<T>> {
  const value = getDataCacheValue<T>(urlPath)
  if (value)
    return value

  const response = await httpGetWithoutDuplication<T>(httpClient, urlPath, withoutBaseUrl)
  if (response instanceof HttpError) {
    return response
  } else {
    setDataCacheValue<T>(urlPath, response)
    return response
  }
}

export async function httpPut<T = any>(httpClient: HttpClient, urlPath: string, body: any): Promise<WebResponse<T>> {
  const response = await httpClient.put(urlPath, body)
  clearDataCache()
  return response
}

export async function httpPatch<T = any>(httpClient: HttpClient, urlPath: string, body: any): Promise<WebResponse<T>> {
  const response = await httpClient.patch(urlPath, body)
  clearDataCache()
  return response
}

export async function httpPost<T = any>(httpClient: HttpClient, urlPath: string, body: any): Promise<WebResponse<T>> {
  const response = await httpClient.post(urlPath, body)
  clearDataCache()
  return response
}

export async function httpDelete<T = any>(httpClient: HttpClient, urlPath: string): Promise<WebResponse<T>> {
  const response = await httpClient.delete(urlPath)
  clearDataCache()
  return response
}
