import * as React from 'react'
import {
  captableCertificatePath,
  conversionPath,
  convertibleInstrumentsPath,
  documentsAndNotesPath,
  editConvertibleInstrumentPath,
  editConvertibleNotePath,
  MembersProps,
  newConvertibleNotePath,
  organizationMemberPath,
  PageProps,
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { DetailsLabel, FlexCol, FlexRow, fontSizes, LightDivider, ListLabel, ListValue, screenSizes, whiteColor } from '@src/styles'
import { PathLinkButton, PathLinkButtonInverted } from '@components/button/button'
import { ProgressBar } from '@components/progressBar'
import styled from 'styled-components'
import { CellProps } from 'react-table'
import { ellipsesColumn, Table } from '@components/tables'
import {
  formatDateString,
  formatSecurityName,
  loadAuthorizations,
  loadHolding,
  loadMembers,
  loadOrganizationHoldings,
  loadSecurities,
  percentage,
  toCommaFloat,
  toCommaNumber,
  usdString
} from '@helpers/index'
import { renderHeader } from '@components/forms'
import {
  ExpandedHoldingResponse,
  ExpandedHoldingsResponse,
  GetAuthorizationsResponse,
  GetSecuritiesResponse
} from '@src/service'
import { ConvertibleInstrument, dateDiffInDays, getAccrualFrequency, optionalInterestFromConvertible } from '../utility'
import { BaseLinkProps, overflowParams, PathLink } from '@components/navigation'
import { optionalUsdString } from '@modules/convertibles/components'

type Props = PageProps &
  ExpandedHoldingResponse &
  GetAuthorizationsResponse &
  ExpandedHoldingsResponse &
  GetSecuritiesResponse &
  GetAuthorizationsResponse &
  MembersProps

const withData = withLoadingCachedMultiple<Props>(
  loadHolding,
  loadOrganizationHoldings('all'),
  loadSecurities(),
  loadAuthorizations('holding'),
  loadMembers
)

const Wrapper = styled.div`
  ${whiteColor}
  p {
    color: ${props => props.theme.label};
    font-size: ${fontSizes.XS};
    margin: 0;
  }
  h5 {
    font-size: ${fontSizes.S};
    line-height: 25px;
    font-weight: 300;
  }
`

const Panel = styled<any>(FlexCol)`
  justify-content: flex-start;
  width: 100%;
  align-items: normal;
  background: ${props => props.theme.panelBack};
  border-radius: 8px;
  padding: 0 30px 20px 30px;
  h2 {
    padding: 0 !important;
  }
`

const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 52px;
  @media(max-width: ${screenSizes.XS}px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`

const ProgressWrap = styled.div`
  margin-bottom: 24px;
  @media(max-width: ${screenSizes.XS}px) {
  }
`

const StyledDate = styled<any>(FlexRow)`
  padding-left: ${props => props.position ? `${props.position}px` : ''};
  @media(max-width: ${screenSizes.XS}px) {
  }
`

const NoNotes = styled.h1`
  font-size: ${fontSizes.XL};
  line-height: 49px;
`

const StyledDivider = styled<any>(LightDivider)`
  margin-top: 0;
`

const renderPanelInfo = (label: string, value: any, index: number) => {
  return (
    <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
      <ListLabel>{label}</ListLabel>
      <ListValue>
        {value}
      </ListValue>
    </FlexRow>
  )
}

export const ViewConvertibleInstrumentPage = withData((props: Props) => {
  const { params, navigate, holding, holdings, securities, members, authorizations } = props
  const { organization } = params
  const membersList = members && members.length > 0 ? members : []
  const authorizationsList = authorizations && authorizations.length > 0 ? authorizations : []
  const convertibleInstrument = holding.convertibleInstrument as ConvertibleInstrument
  const maturityDate = holding?.convertibleInstrument?.maturityDate && new Date(holding.convertibleInstrument.maturityDate.split('T')[0].replace(/\-/g, '/'))
  const issueDate = new Date(holding.issueDate?.split('T')[0].replace(/\-/g, '/'))
  const issueAndTodayDiff = dateDiffInDays(issueDate, new Date())
  const issueAndMaturityDiff = holding?.convertibleInstrument?.maturityDate && dateDiffInDays(issueDate, maturityDate!)
  const datePercentage = holding?.convertibleInstrument?.maturityDate && percentage(issueAndTodayDiff, issueAndMaturityDiff)
  const title = holding.name!
  const { authorizedRaiseAmount } = convertibleInstrument

  const outstanding = holdings?.filter(el => el.parent === holding.id)
    .reduce((a, b) => a + b.capitalContribution!, 0)
  const treasury = authorizedRaiseAmount - outstanding

  const tableData = [
    { ...holding, outstanding, treasury, documents: authorizationsList }
  ]

  const security = securities.filter(el => el.hash === holding.parent)[0]
  const parentName = security && formatSecurityName(security)
  const hasNoInterest = holding?.convertibleInstrument?.interestRate ?
    holding?.convertibleInstrument?.interestRate !== 0
    :
    holding?.convertibleInstrument?.interestRate!

  const notesList = holdings
    .filter(el => el.parent === holding.id)
    .map(el => {
      const currentInterest = optionalInterestFromConvertible(convertibleInstrument, el.issueDate, new Date(), el.capitalContribution, true) || 0
      const totalInterest = optionalInterestFromConvertible(convertibleInstrument, el.issueDate, new Date(), el.capitalContribution, false, true) || 0
      const holder = membersList.find(s => s.id === el.owner)?.fullName
      return {
        ...el,
        currentInterest,
        totalInterest,
        holder,
      }
    })
  const sortedNotesList = notesList.sort((a, b) => new Date(a?.issueDate!) as any - new Date(b?.issueDate!) as any)
  const initialIssuance = sortedNotesList[0]?.issueDate!
  const getApprovalDocuments = (documents: any, holding: string) => {
    if (documents && documents.length > 0) {
      return <a
        href=""
        onClick={() => navigate(
          overflowParams(documentsAndNotesPath, { shareholding: holding, organization, parent: window.location.pathname })
        )}
        style={{ cursor: 'pointer' }}>
        {documents.length} documents
      </a>
    } else return null
  }

  const columns = [
    {
      Header: 'Converts To',
      Cell: ({ row }: CellProps<any>) => <div>{parentName!}</div>,
      className: 'basic'
    },
    {
      Header: 'Auth Raised Amount',
      accessor: 'authorizedRaiseAmount',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(authorizedRaiseAmount)}</div>
    },
    {
      Header: 'Outstanding',
      accessor: 'outstanding',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.outstanding)}</div>
    },
    {
      Header: 'Treasury',
      accessor: 'treasury',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.treasury)}</div>
    },
    {
      Header: 'Maximum Issuance',
      accessor: 'value',
      Cell: ({ row }: CellProps<any>) => <div>{toCommaNumber(holding.value)}</div>
    },
    {
      Header: 'Approval Document(S)',
      Cell: ({ row }: CellProps<any>) => <>{getApprovalDocuments(row.original.documents!, holding.hash)}</>
    },
  ]
  const optionalInterest = hasNoInterest ? [
    {
      Header: 'Current Interest',
      accessor: 'currentInterest',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.currentInterest!, 2)}</div>
    },
    {
      Header: 'Total Interest',
      accessor: 'totalInterest',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.totalInterest!, 2)}</div>
    }
  ] : []
  const issuedColumns = [
    {
      Header: 'Cert ID',
      accessor: 'name',
      Cell: ({ row }: CellProps<any>) => <PathLink
        path={captableCertificatePath}
        args={{
          organization,
          shareholding: row.original.hash,
          parent: window.location.pathname
        }}>
        {row.original.name}
      </PathLink>
    },
    {
      Header: 'Issue Date',
      accessor: 'issueDate',
      Cell: ({ row }: CellProps<any>) => <div>{row.original.issueDate && formatDateString(row.original.issueDate)}</div>
    },
    {
      Header: 'Holder',
      accessor: 'holder',
      Cell: ({ row }: CellProps<any>) => <PathLink
        path={organizationMemberPath}
        args={{
          organization,
          member: row.original.owner,
          parent: window.location.pathname
        }}>
        {row.original.holder!}
      </PathLink>,
    },
    {
      Header: 'Principal',
      accessor: 'capitalContribution',
      Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.capitalContribution!)}</div>
    },
    ...optionalInterest,
    ellipsesColumn(
      [
        ['View', ({ row }) => navigate(overflowParams(captableCertificatePath, {
          organization,
          shareholding: row.original.hash,
          parent: window.location.pathname
        }))],
        ['Edit', ({ row }) => navigate(editConvertibleNotePath, { organization, holding: holding.hash, note: row.original.hash })]
        // add delete option here after modals are updated
      ]
    ),
  ]

  const conversionSettings = [
    {
      label: 'Minimum Valuation',
      value: optionalUsdString(convertibleInstrument.valuationMin)
    },
    {
      label: 'Maximum Valuation',
      value: optionalUsdString(convertibleInstrument.valuationMax)
    },
    {
      label: 'Discount Rate',
      value: `${toCommaFloat(convertibleInstrument.conversionDiscount)}%`
    },
    {
      label: 'Interest Rate',
      value: `${toCommaNumber(convertibleInstrument.interestRate || 0)}%`
    },
    {
      label: 'Accrual Frequency',
      value: getAccrualFrequency[convertibleInstrument.accrualFrequency]
    },
    {
      label: 'Actual Price Per Unit',
      value: optionalUsdString(holding.pricePerUnit!)
    },
  ]

  const otherSettings = [
    {
      label: 'Qualified Financing Amount',
      value: optionalUsdString(convertibleInstrument.qualifiedFinancingAmount)
    },
    {
      label: 'Early Exit Multiple',
      value: `${toCommaNumber(convertibleInstrument.earlyExitMultiple || 1)}x`
    },
    {
      label: 'Seniority Level',
      value: holding.seniority
    },
  ]
  const parent: BaseLinkProps = { path: convertibleInstrumentsPath, args: { organization } }

  return (
    <PageContent>
      <PageContentHeader title={title} parent={parent}>
        <div>
          <PathLinkButton path={editConvertibleInstrumentPath} args={{ organization, holding: params?.holding }}>
            Edit
          </PathLinkButton>
          <PathLinkButtonInverted path={conversionPath}
            args={{ organization, holding: params.holding }}>Convert</PathLinkButtonInverted>
        </div>
      </PageContentHeader>
      <Wrapper>
        {holding?.convertibleInstrument?.maturityDate &&
          <ProgressWrap>
            <StyledDate position={datePercentage}>
              <p>Today: {formatDateString((new Date()).toISOString())}</p>
            </StyledDate>
            <ProgressBar percentage={datePercentage} margin="0 0 10px 0" fullHeight={true} />
            <FlexRow justifyContent={'space-between'}>
              <p>Initial Issuance: {formatDateString(initialIssuance)}</p>
              <p>Maturity Date: {formatDateString(maturityDate)}</p>
            </FlexRow>
          </ProgressWrap>
        }
        <Table columns={columns} data={tableData} scrollingTable={true} />
        <PanelsWrapper>
          <Panel>
            {renderHeader({ label: 'Conversion Settings' })}
            {conversionSettings.map((el, index: number) => {
              return renderPanelInfo(el.label, el.value, index)
            })}
          </Panel>
          <Panel>
            {renderHeader({ label: 'Other Settings' })}
            {otherSettings.map((el, index: number) => {
              return renderPanelInfo(el.label, el.value, index)
            })}
          </Panel>
        </PanelsWrapper>
        <FlexRow justifyContent={'space-between'}>
          <h5>Convertible Notes</h5>
          <PathLinkButton path={newConvertibleNotePath} args={{ organization, holding: params?.holding! }}>
            + Add</PathLinkButton>
        </FlexRow>
        <StyledDivider stretch={true} />
        {notesList.length > 0 ? <Table columns={issuedColumns} data={notesList} scrollingTable={true} />
          :
          <FlexCol>
            <NoNotes>Issue a Convertible Instrument</NoNotes>
            <span>Click the “New” button above to issue an instrument</span>
          </FlexCol>
        }
      </Wrapper>
    </PageContent>
  )
})
