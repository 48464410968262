import * as React from "react";
import styled from "styled-components";
import { screenSizes, gapSizes, fontSizes } from "@src/styles/theme";
import UpArrowGreen from "@image/arrow-up-green.svg";
import DownArrowRed from "@image/arrow-down-red.svg";

export const panelRadius = "4px";

export const Panel = styled.div<any>`
  max-width: ${(props) => (props.max ? props.max : "100%")};
  width: 100%;
  padding: 16px;
  text-align: center;
  background: ${(props) => props.theme.panelBack};
  border-radius: ${panelRadius};
  white-space: ${(props) => (props.wrap === "true" ? "nowrap" : "")};
`;

export const PanelGrid = styled.div<any>`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: ${(props) => `repeat(${props.columns}, 1fr)`};
  color: ${(props) => props.theme.white};
  margin-bottom: 16px;
  @media (max-width: ${screenSizes.L}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${screenSizes.S}px) {
    grid-template-columns: 1fr;
  }
`;

export const SimplePanelValue = styled.div`
  font-size: ${fontSizes.XXL};
`;

export const SimplePanelDescription = styled.label<any>`
  margin-top: 9px;
  text-align: center;
  text-transform: capitalize;
  color: ${(props) => (props.descriptionColor ? props.descriptionColor : props.theme.white)};
`;

interface SimplePanelProps {
  value: any;
  description: string;
  wrap?: string;
  rawValue?: any;
  withArrow?: boolean;
  descriptionColor?: any;
}

export const SimplePanel = (props: SimplePanelProps) => {
  const { value, description, wrap, withArrow, rawValue, descriptionColor } =
    props;
  return (
    <Panel wrap={wrap}>
      <SimplePanelValue className="value">
        {withArrow && rawValue && parseFloat(rawValue) > 0 ? (
          <>
            <UpArrowGreen />
            &nbsp;
          </>
        ) : parseFloat(rawValue) < 0 ? (
          <>
            <DownArrowRed />
            &nbsp;
          </>
        ) : null}

        {value
          ? typeof value === "string"
            ? value.toUpperCase()
            : value
          : null}
      </SimplePanelValue>
      <SimplePanelDescription
        descriptionColor={descriptionColor}
        className="description"
      >
        {description}
      </SimplePanelDescription>
    </Panel>
  );
};
