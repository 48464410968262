import { index } from '../src'
import { Service } from '@src/service'

declare var API_URL: string
function main() {
  const service = new Service(API_URL)
  index(service)
  if (module.hot) {
    module.hot.accept()
  }
}

main()
