import * as React from 'react'
import { SubmitButton } from '@components/button/button'
import { FormButtonLayout } from '@components/styledForm'
import { FormApi, FormState, Mutator, SubmissionErrors } from 'final-form'
import { Form } from 'react-final-form'
import { fieldLabel, fieldSpan, FlexRow, whiteColor } from '@src/styles'
import styled from 'styled-components'
import { FormValidator } from '@src/shared/helpers'
import { gapSizes, screenSizes } from '@src/styles/theme'
import { CommonSubmissionFooter } from '@components/forms/common-submission-footer'
import { ReactElement } from 'react'
import { Prompt } from 'react-router-dom'
import { exitWithoutSaveModalBundle } from '@modules/roles/components/exit-without-save-modal'
import { NavigationProps, withNavigation } from '../navigation'
import _ from 'lodash'

export type OnSubmit<T = any> = (
  values: T,
  form: FormApi<T>,
  callback?: (errors?: SubmissionErrors) => void
) => SubmissionErrors | Promise<SubmissionErrors | undefined> | undefined | void

export type Sticky<T> = (props: FormState<T>) => ReactElement

interface Props extends NavigationProps {
  children?: any
  initialValues: any
  onSubmit: OnSubmit<any>
  submitText?: string
  skipText?: string
  validate?: FormValidator
  sticky?: Sticky<any>
  mutators?: { [key: string]: Mutator<any> }
  stretched?: boolean
  skip?: any
  noPrompt?: boolean
  stickyOnTop?: boolean
  leaveModal?: boolean
}

export const FormRow = styled<any>(FlexRow)`
  align-items: flex-start;
  justify-content: space-between;
  max-width: ${props => props.stretched && '100% !important'};
  @media (max-width: ${screenSizes.S}px) {
    flex-direction: column;
    flex: 1;
  }
`

export const StyledCommonForm = styled.form<any>`
  flex: 1;
  align-items: inherit;
  width: 100%;
  .formWrapper {
    justify-content: space-between;
    align-items: flex-start;
    .formLeft {
      width: 100%;
      max-width: ${props => props.stretched ? '100%' : '628px'};
    }
  }
  label {
    ${fieldLabel}
  }
  span {
    display: block;
    ${whiteColor}
  }
  @media (max-width: ${screenSizes.M}px) {
    width: 100%;
    button {
      bottom: 20px;
    }
  .formWrapper {
    flex-direction: column;
  }
  }
`

export const StickyComponent = styled.div<any>`
  position: sticky;
  flex: 1;
  top: 0;
  height: fit-content;
  margin-left: ${props => props.onTop == 'true' ? 0 : gapSizes.M};
  background-color: ${props => props.onTop == 'true' ? props.theme.secondary : ''} !important;
  z-index: 1;
  padding-bottom: ${props => props.onTop == 'true' ? '40px' : 0};
  @media (max-width: ${screenSizes.M}px) {
    width: 100%;
    margin-left: 0;
    margin-top: ${gapSizes.M};
    max-width: initial;
  }
`

export const CommonForm = withNavigation<Props>(props => {
  const [nextLocation, setNextLocation] = React.useState<string>('')
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false)
  const {
    children,
    initialValues,
    onSubmit,
    submitText,
    validate,
    sticky,
    mutators,
    stretched,
    skipText,
    skip,
    noPrompt,
    navigate,
    history,
    leaveModal,
    stickyOnTop, } = props
  const [initialValuesWithDefaults, setInitialValuesWithDefaults] = React.useState<object | undefined>(initialValues)
  const [originalFields, setOriginalFields] = React.useState<any>({})
  const [counter, setCounter] = React.useState<number>(0)
  return (
    <>
      <Form
        mutators={mutators}
        validate={validate}
        onSubmit={onSubmit}
        initialValues={initialValuesWithDefaults}
        render={formProps => {
          const {
            handleSubmit,
            invalid,
            submitting,
            values,
            errors,
            form: {
              mutators: { pop, push, remove },
            },
          } = formProps
          const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(typeof child === 'function' ? child() : child, formProps)
          )
          const stickyWithProps = sticky && sticky(formProps)

          const leaveWithoutSaveModal = exitWithoutSaveModalBundle({
            title: 'Leave without saving?',
            submitText: 'Stay on page',
            onCancel: () => leaveAnyway(),
          })

          const leaveAnyway = () => {
            leaveWithoutSaveModal.setVisible(false)
            // history.block is unblocking the history here so navigation continues
            history.block()()
            navigate(nextLocation)
          }

          const handleBlockedNavigation = (location: any) => {
            if (formProps?.dirty) {
              setNextLocation(location.pathname)
              leaveWithoutSaveModal.setVisible(true)
              return false
            }
            return true
          }

          const isDirty = !_.isEqual(_.values(originalFields), _.values(formProps.values))

          React.useEffect(() => {
            const fields = formProps?.form?.getState()?.values
            if (counter < 1) {
              setCounter(counter + 1)
              setOriginalFields(fields)
              setInitialValuesWithDefaults(formProps.values)
            }
          })

          React.useEffect(() => {
            if (submitting && !isSubmitting) {
              setIsSubmitting(true)
            }
          }, [submitting])

          return (
            <>
              {leaveWithoutSaveModal.component}
              {!noPrompt && !isSubmitting && !leaveModal && 
                <Prompt
                  when={isDirty}
                  message={(location: any) => handleBlockedNavigation(location)} />
              }
              <FormRow stretched={stretched}>
                <StyledCommonForm onSubmit={handleSubmit} stretched={stretched}>
                  {stickyOnTop && stickyWithProps ? <StickyComponent stretched={stretched} onTop={'true'}>{stickyWithProps}</StickyComponent> : null}
                  <FlexRow className='formWrapper'>
                    <div className='formLeft'>
                      {childrenWithProps}
                      {(submitText || skipText) && <CommonSubmissionFooter
                        submitText={submitText}
                        disabled={invalid || submitting}
                        skipText={skipText}
                        skip={skip}
                        submitting={submitting} />
                      }
                    </div>
                    {!stickyOnTop && stickyWithProps ? <StickyComponent stretched={stretched}>{stickyWithProps}</StickyComponent> : null}
                  </FlexRow>
                </StyledCommonForm>
              </FormRow>
            </>
          )
        }}
      />
    </>
  )
})
