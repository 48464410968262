import * as React from 'react'
import { PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAddresses, loadEmails, loadProfile } from '@helpers/loaders'
import { GetAddressesResponse, GetEmailsResponse } from '@src/service'
import { ViewProfileShared } from '../components/profile-details-shared'

type Props = PageProps & GetAddressesResponse & GetEmailsResponse

const withData = withLoadingCachedMultiple<Props>(loadProfile(), loadAddresses(''), loadEmails(''))

export const ViewProfile = withData((props: Props) => {
    return <ViewProfileShared {...props} />
})
