import * as React from 'react'
import { OrganizationsProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { loadOrganizations, loadProfile, loadTransactionsHistory } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { GetTransactionsHistoryResponse } from '@src/service/services/profile'
import { TransactionHistoryTable } from '../components'
import { withPermissions } from '@shared/hocs/with-permissions'
import { PermissionProps } from '@shared/components/permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetTransactionsHistoryResponse & OrganizationsProps & PermissionProps

const withData = withLoadingCachedMultiple<Props>(loadTransactionsHistory, loadOrganizations, loadProfile())

export const OrgTransferHistoryPage = withPermissions([BuiltInPermission.viewOrganization])(
    withData((props: Props) => {
        const { transactionRecords, organizations } = props
        const userProfile = props.profile!!
        return (
            <PageContent withMargin={true}>
                <PageContentHeader title={'Latest Transaction History'} hideCrumbs={true} />
                <TransactionHistoryTable
                    transactionRecords={transactionRecords}
                    organizations={organizations}
                    userProfile={userProfile} />
            </PageContent>
        )
    })
)
