import { UrlPath, Uuid } from '@helpers/util'
import { EntityProps } from '@logic'
export interface UserProps {
    user: Uuid
}

export interface ColorProps extends UserProps {
    target: Uuid
}

export const profileUrl: UrlPath<{ profile: Uuid }> = 'profile/:profile'
export const entityMembersUrl: UrlPath<{ entity: Uuid }> = `entity/:entity/member`
export const colorsUrl: UrlPath<UserProps> = `user/:user/color`
export const colorUrl: UrlPath<ColorProps> = `user/:user/color/:target`
export const accountUrl: UrlPath<UserProps> = `user/:user/account`
export const referralUrl: UrlPath<{}> = 'referral/:entity'
export const invitationUrl: UrlPath<{}> = 'invitation'
export const scratchCardUrl: UrlPath<{}> = 'card'
export const equaCashTransferUrl: UrlPath<{}> = 'equaCash/transfer'
export const transactionsHistoryUrl: UrlPath<EntityProps> = 'entity/:entity/history'
