import { ToastItems, ToastTypes } from './types/types'

export const toasts: ToastItems = {
  invalidPayment: {
    key: 'invalidPayment',
    label: 'There was an issue with your payment information',
    type: ToastTypes.error,
  },
  uploadedFile: {
    key: 'uploadedFile',
    label: 'Your upload was successful!',
    type: ToastTypes.success,
  },
  deletedFile: {
    key: 'deletedFile',
    label: 'Document Deleted!',
    type: ToastTypes.success,
  },
  renamedFile: {
    key: 'renamedFile',
    label: 'Document Renamed!',
    type: ToastTypes.success,
  },
  nameAlreadyExists: {
    key: 'nameAlreadyExists',
    label: 'File with that name already exists',
    type: ToastTypes.error,
  },
  invalidTwoFactorToken: {
    key: 'invalidTwoFactorToken',
    label: 'The two factor token is incorrect.  Please check and try again.',
    type: ToastTypes.error,
  },
  invalidCredentials: {
    key: 'invalidCredentials',
    label: 'Incorrect username or password.',
    type: ToastTypes.error,
  },
  emailUnavailable: {
    key: 'emailUnavailable',
    label: 'Email address is already taken.',
    type: ToastTypes.error,
  },
  login_progress_error: {
    key: 'login_progress_error',
    label: 'Login popup window is already open.  Please proceed through the new window.',
    type: ToastTypes.error,
  },
  unsupportedFileExtension: {
    key: 'unsupportedFileExtension',
    label: 'Unsupported file type.  Supported file types are: docx, txt.',
    type: ToastTypes.error,
  },
  invitedToOrganization: {
    key: 'invitedToOrganization',
    label: 'User was successfully invited!',
    type: ToastTypes.success,
  },
  default: {
    key: 'default.json',
    label: 'Something has gone wrong.',
    type: ToastTypes.error,
  },
  unsupportedCertificateExtension: {
    key: 'unsupportedFileExtension',
    label: 'Unsupported file type.  Supported file types are: pdf, png, jpeg.',
    type: ToastTypes.error,
  },
  invalidFileData: {
    key: 'invalidFileData',
    label: 'Invalid File data',
    type: ToastTypes.error,
  },
  verifyEmail: {
    key: 'verifyEmail',
    label: 'Your verification email was sent',
    type: ToastTypes.success,
  },
  checkEmail: {
    key: 'checkEmail',
    label: 'You successfully verified your email!',
    type: ToastTypes.success,
  },
  resetPassword: {
    key: 'resetPassword',
    label: 'Please check your email for a link to reset your password.',
    type: ToastTypes.success,
  },
  passwordUpdated: {
    key: 'passwordUpdated',
    label: 'Password Update Successful!',
    type: ToastTypes.success,
  },
}
