import * as React from 'react'
import { PageProps } from '@logic'
import { WalletAddressComponent } from '@modules/profile/components/wallet-address'
import { signUpModal } from '@shared/components/modal'

type Props = PageProps

export const GuestWalletAddressBook = (props: Props) => {
    const { navigate } = props

    const signUpModalBundle = signUpModal({ navigate })
    return <>
        {signUpModalBundle.component}
        <WalletAddressComponent {...props} forGuest={true} openSignUp={() => signUpModalBundle.setVisible(true)} />
    </>
}
