import * as React from 'react'
import { grayColor, SmallHeader } from '@src/styles'
import styled from 'styled-components'
import { PathLinkButtonInverted } from '@components/button'
import { organizationMembersPath } from '@logic'
import { Uuid } from '@helpers/util'
import { themeGray } from './invited-member-row'

interface Props {
  emails: string[]
  organization: Uuid
}

const EmailRow = styled.div`
  ${themeGray}
`

const Paragraph = styled.p`
  margin-top: 20px;
  margin-bottom: 30px;
`

export const InvitationSuccess = ({ emails, organization }: Props) => (
  <div>
    <SmallHeader>Success! You have sent invites to:</SmallHeader>
    <Paragraph>
      {
        emails.map(email => <EmailRow>{email}</EmailRow>)
      }
    </Paragraph>
    <PathLinkButtonInverted path={organizationMembersPath} args={{ organization }}>
      Go Back
    </PathLinkButtonInverted>
  </div>
)
