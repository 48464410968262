import * as React from 'react'
import {
  additionalLegendOptions,
  conditionalFields,
  dateField,
  FieldGroup,
  numericInputFieldWithCommas,
  renderFieldsSimple,
  renderHeader,
  selectFieldComponent,
  uploadFileField,
  UploadHandler,
} from '@shared/components/forms'
import { emptyValidator, nonZeroNumberWithOptionalCommas } from '@shared/helpers/field-validators'
import { ExpandedHolding, ExpandedLegend, ExpandedSecurity, FormOption, NewOption } from '@src/service'
import { ContinuousVestingSchedule, DiscreteVestingSchedule, Member, organizationCreateLegendPath } from '@logic'
import { LabelValue } from '@shared/components/select'
import { legendsToSelectOptions, optionsFromMembers } from '@helpers/util'
import { StyledHeaderRow } from './pool-form'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'
import { formatToNumber } from '@modules/captable/utility'
import { Params } from '@shared/components/navigation'

interface Props {
  values?: FormOption
  members: Member[]
  pools?: ExpandedHolding[]
  legends: ExpandedLegend[]
  onUpload: UploadHandler
  securities: ExpandedSecurity[]
  vestings: ContinuousVestingSchedule[] | DiscreteVestingSchedule[]
  edit?: boolean
  defaultLegend?: any
  params: Params
}

function optionFields(
  members: LabelValue[],
  legendOptions: ExpandedLegend[],
  showStartDate: boolean,
  totalAmount: number | undefined,
  params: Params,
  poolOptions?: any,
  vestingOptions?: any,
  legend?: string): FieldGroup<NewOption> {
  const startDatePicker = React.useRef()

  function startDateField(): FieldGroup<any> {
    const fields = [
      {
        name: 'startDate',
        label: 'Vesting Start Date',
        required: false,
        placeholder: 'MM/DD/YYYY',
        note: 'The start date of the vesting schedule or award commencement.',
        ...dateField(startDatePicker),
      },
    ]
    const fieldsArray = conditionalFields(fields, showStartDate)
    return fieldsArray
  }

  return [
    {
      name: 'member',
      label: 'Holder',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(members, 'Holder'),
    },
    {
      name: 'pool',
      label: 'Pool',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(poolOptions, 'Pool Name'),
    },
    {
      name: 'issueDate',
      label: 'Grant / Effective Date',
      required: true,
      placeholder: 'MM/DD/YYYY',
      note: 'The date the incentive is granted.',
      ...dateField(React.useRef()),
    },
    {
      name: 'vestingSchedule',
      label: 'Vesting Schedule',
      required: false,
      validate: emptyValidator,
      component: selectFieldComponent(vestingOptions, 'Vesting Schedule Name'),
    },
    ...startDateField(),
    {
      name: 'startingValue',
      label: 'Total Amount',
      required: true,
      format: formatToNumber,
      placeholder: 'Enter total amount',
      defaultValue: totalAmount,
      validate: nonZeroNumberWithOptionalCommas(),
      component: numericInputFieldWithCommas,
    },
    {
      name: 'legend',
      label: 'Legend',
      required: false,
      validate: emptyValidator,
      defaultValue: legend!,
      component: selectFieldComponent(
        legendsToSelectOptions(legendOptions),
        'Legend',
        { text: '+ Add New Legend', to: organizationCreateLegendPath, args: { organization: params.organization } }
      ),
    },
  ]
}

export const OptionForm = (props: Props) => {
  const { members, pools, values, legends, vestings, onUpload, defaultLegend, securities, params, edit } = props
  const memberOptions = optionsFromMembers(members)
  const vestingOptions = vestings?.map((m: any) => ({
    label: m.name,
    value: m.hash,
  }))?.sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))

  const poolOptions = pools?.map(pool => {
    return { label: pool.name, value: pool.id }
  })
  const selectedIncentive = pools?.find(s => s.id === values?.pool)?.parent
  const legend = defaultLegend ? defaultLegend
    :
    values?.pool ? securities?.find(s => s.hash === selectedIncentive)?.legend! : undefined

  const additionalLegends = additionalLegendOptions(legends, values?.legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  const selectedVesting = vestings?.find(v => v.hash === values?.vestingSchedule)
  const isCustom = selectedVesting && Object.keys(selectedVesting).includes('events')
  const isAbsolute = isCustom && selectedVesting && selectedVesting.isValueAbsolute
  const showStartDate = isAbsolute ? false : true
  const vestingEvents = isCustom && selectedVesting ? selectedVesting.events : []
  const totalAmount = isAbsolute ? vestingEvents.reduce((a, b) => a + b.value, 0) : undefined

  const docFields = renderFieldsSimple([
    {
      name: 'issuanceAgreement',
      label: 'Issuance Agreement',
      required: false,
      ...uploadFileField({ onUpload, placeholder: 'Upload document', accept: '.pdf' }),
    },
  ], true)

  return (
    <>
      <StyledHeaderRow>{renderHeader({ label: 'Holding Details' })}</StyledHeaderRow>
      {renderFieldsSimple(optionFields(
        memberOptions,
        legendOptions,
        showStartDate,
        totalAmount,
        params,
        poolOptions,
        vestingOptions,
        legend,
      ))}
      <DocumentsAndNotes
        onUpload={onUpload}
        values={values}
        noInitial={true}
        definedFields={docFields} />
    </>
  )
}
