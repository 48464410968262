import { Hash, Uuid } from '@helpers/util'
import { ShareType } from '@logic'

export interface Plan {
  hash: Hash
  name: string
  boardApprovalDate: Date
  boardApprovalDocument?: Hash
  incentivePlanDocument?: Hash
  organization: Uuid
  pools: Pool[]
  termYears: number
  approvedEquities: any
  createdBy?: Uuid
  owner?: string
}

export interface Security {
  hash: Hash
  organization: Uuid
  legend: Hash
  shareClass: string
  shares: number
  shareType: ShareType
}

export enum VestmentTrigger {
  anniversary = 1,
  firstDayOfMonth = 2,
  lastDayOfMonth = 3,
}

export enum AmountType {
  fixed = 1,
  percentage = 2,
}

export interface ContinuousVestingSchedule {
  durationMonths: number
  frequencyMonths: number
  vestsOn: VestmentTrigger
  cliffMonths?: number
  cliffAmount?: number
  cliffAmountType?: AmountType
}

export interface DiscreteVestingSchedule {
  events: VestingEvent[]
  isValueAbsolute: boolean
}

export interface VestingEvent {
  hash: Hash
  day: number
  month: number
  value: number
  year?: number
}

export interface VestingSample {
  date?: Date
  value: number
  cumulativeValue: number
  event?: any
}

export type VestingSamples = VestingSample[]

export type ContinuousVestingScheduleWithoutHash = Omit<ContinuousVestingSchedule, 'hash'>

export interface Pool {
  hash: Hash
  boardApprovalDate: Date
  options: Hash
  organization: Uuid
  name: string
  security: Hash
  pricePerShare: number
  vestingSchedule: Hash
  exercisedShares: Hash
  availableShares: number
}

export interface Option {
  hash: Hash
  note: string
  organization: Uuid
  security: Hash
  startDate: Date
  member: Uuid
  shares: number
  vestingSchedule: Hash
}
