import * as React from 'react'
import { OrganizationProps, organizationSecuritiesPath, organizationViewSecurityPath, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  loadAuthorizations,
  loadLegends,
  loadOrganizationHoldings,
  loadSecurities,
  loadSecurity
} from '@helpers/loaders'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { EditSecurityForm } from '../components/security-form'
import {
  createAuthorization,
  editSecurity,
  EditSecurityTypeRequest,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetAuthorizationsResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  GetSecurityTypeResponse,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { formatSecurityName } from '@shared/helpers'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps &
  OrganizationProps &
  GetSecuritiesResponse &
  GetLegendsResponse &
  GetSecurityTypeResponse &
  ExpandedHoldingsResponse &
  GetAuthorizationsResponse

const withData = withLoadingCachedMultiple<Props>(
  loadSecurities(),
  loadLegends(true),
  loadSecurity(),
  loadOrganizationHoldings('all'),
  loadAuthorizations('security'))

export const EditSecurityPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, legends, holdings, authorizations } = props
    const { organization, security } = params
    const {
      hash,
      shareType,
      shareClass,
      shareClassPrefix,
      certifiedShares,
      fractionalShares,
      votingShares,
      dividendConfig,
      sharePricing,
      seniority,
      legend
    } = props.security
    const { conversionPrice, conversionRate, multipler, originalIssuePrice, parValue, participatingPreferred, pricePerShare } = sharePricing
    const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })
    const initialValues = {
      shareType,
      shareClass,
      shareClassPrefix,
      approvalDocument: documents,
      certifiedShares,
      fractionalShares,
      votingShares,
      parValue,
      pricePerShare,
      originalIssuePrice,
      conversionPrice,
      conversionRatio: conversionRate,
      multiplier: multipler,
      participatingPreferred,
      seniority,
      issueDividends: dividendConfig?.category ? true : false,
      dividendType: dividendConfig?.category,
      dividendYield: dividendConfig?.yield,
      dividendCumulation: dividendConfig?.cumulation,
      dividendAccrual: dividendConfig?.accrual,
      compoundingInterest: dividendConfig?.interest,
      legend,
      value: props.security?.value! || 0 // 0 value temporary fix for legacy data
    }

    const securityName = formatSecurityName(props.security)
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const securitiesList = securities ? securities.filter(s => !s.parent) : []

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const sharePricing = {
        parValue: values.parValue || 0,
        pricePerShare: values.pricePerShare || 0,
        originalIssuePrice: values.originalIssuePrice || 0,
        conversionPrice: values.conversionPrice || 0,
        conversionRate: values.conversionRatio || 0,
        multipler: values.multiplier || 0,
        participatingPreferred: values.participatingPreferred
      }
      const dividendConfig = values.issueDividends ? {
        accrual: values.dividendAccrual,
        interest: values.compoundingInterest,
        cumulation: values.dividendCumulation,
        category: values.dividendType,
        yield: values.dividendYield
      } : undefined

      const editSecurityType = {
        security: hash,
        certifiedShares: values.certifiedShares,
        dividendConfig,
        filingDate: values.filingDate,
        fractionalShares: values.fractionalShares,
        incorporationDocument: values.incorporationDocument,
        votingShares: values.votingShares,
        seniority: values.seniority,
        shareClass: values.shareClass,
        shareClassPrefix: values.shareClassPrefix,
        sharePricing,
        shareType: values.shareType,
        legend: values.legend,
        value: values?.value!
      } as EditSecurityTypeRequest

      const edit = await editSecurity(httpClient)({ organization, security }, editSecurityType)
      if (edit?.security) {
        modifyAuthorizations({ approvalDocuments, target: hash, httpClient, organization })
        navigate(organizationSecuritiesPath, { organization })
      }
      else console.log('create Security error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationViewSecurityPath, args: { organization, security: params.security } }

    return (
      <PageContent>
        <PageContentHeader title={`Edit ${securityName} Equity Details`} parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <EditSecurityForm onUpload={onUpload} securities={securitiesList} legends={legends} security={props.security} holdings={holdings} />
        </CommonForm>
      </PageContent>
    )
  }
  ))
