import styled from 'styled-components'
import { Button } from '@components/button'
import { screenSizes } from '@src/styles/theme'
import { semiBoldStyle } from '@src/styles'

export const Heading = styled.div`
  ${semiBoldStyle}
  line-height: 32px;
  font-size: 24px;
  color: ${({ theme }) => theme.white};
  text-align: center;
  @media (max-width: ${screenSizes.M}px) {
    line-height: 28px;
    font-size: 20px;
  }
`

export const SubHeading = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.white};
  @media (max-width: ${screenSizes.M}px) {
    font-size: 14px;
    line-height: 19px;
  }
`

export const NoTicketCont = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0;
  img {
    margin-top: 35px;
  }
  @media (min-width: ${screenSizes.XL}px) {
    width: 897px;
    margin: 0 79px 0px;
  }
`

export const TicketsCont = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  @media (min-width: ${screenSizes.XL}px) {
    width: 897px;
    margin: 35px 79px 0 79px;
  }

  .single-ticket {
    position: relative;
  }

  .brown-scratch-box {
    position: absolute;
    top: 150px;
    left: 45px;
  }
`

export const BtnCont = styled.div`
  display: flex;
  justify-content: center;
  margin: 28px 0 54px 0;
`

export const InviteFriendsBtn = styled<any>(Button)`
  font-weight: bold;
  font-family: 'NunitoSansBold';
  font-size: 13px;
  text-transform: uppercase;
  color: ${props => props.theme.white};
  padding: 12px 24px;
  width: 248px;
`

export const NextBtn = styled<any>(InviteFriendsBtn)``

export const ScratchAllBtn = styled<any>(InviteFriendsBtn)``
