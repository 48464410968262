import React from 'react'
import { editRolePath, viewRolePath } from '@logic'
import styled from 'styled-components'
import { FlexCol, FlexRow, fontSizes, whiteColor } from '@src/styles'
import { Panel } from './panels'
import { overflowParams, PathLink } from '../navigation'
import { nameAbbreviation } from '../avatar'
import { StyledHead } from '@modules/roles/components/role-form'
import EditPen from '@image/edit-pen-new.svg'
import { formatImageUrl } from '@shared/helpers'

interface PanelProps {
    organization: string
    members?: any
    navigate: any
    isAdmin?: boolean
    isAdminRole?: boolean
    role?: any
}

const PanelWrapper = styled<any>(Panel)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: auto;
    padding: 16px;
`

const HeaderRow = styled<any>(FlexRow)`
    width: 100%;
    svg {
        cursor: pointer;
    }
`

const EditWrap = styled<any>(PathLink)`
`

const MemberWrap = styled<any>(FlexRow)`
    ${whiteColor}
    padding: 12px;
    max-width: 312px;
    width: 100%;
    span {
        line-height: 24px;
    }
    div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 2px solid ${props => props.theme.whiteGlove};
        background: #33AA40;
        z-index: ${props => props.zIndex};
        span {
            font-weight: 800;
            font-family: 'NunitoSansBold';
        }
   }
   .memberName {
     margin-left: 16px;
     text-align: start;
   }
`

const MembersWrap = styled<any>(FlexRow)`
    ${whiteColor}
    flex-wrap: wrap;
    width: 100%;
    img {
      height: 100% !important;
      border-radius: 50%;
    }
`

type Props = PanelProps

export const AssignedMembersPanel = (props: Props) => {
    const { members, organization, isAdmin, role, isAdminRole } = props

    return <PanelWrapper>
        <HeaderRow justifyContent={'space-between'} alignItems={'flex-start'}>
            <StyledHead style={{ margin: '0 0 24px 0' }}>
                {members?.length} {members?.length > 1 ? 'Assigned Members' : 'Assigned Member'}
                {isAdminRole && <span> (1 or more Required)</span>}
            </StyledHead>
            {isAdmin && <EditWrap path={editRolePath} args={{ organization, role: role.id }}>
                <EditPen />
            </EditWrap>
            }
        </HeaderRow>
        <MembersWrap>
            {members.map((member: any, i: number) => {
                return <MemberWrap key={i}>
                    <div>
                        {member.file ? <img src={formatImageUrl(member.file.hash)} alt="member" />
                            :
                            <span>{nameAbbreviation(member.fullName)}</span>
                        }
                    </div>
                    <span className="memberName">{member.fullName}</span>
                </MemberWrap>
            })}
        </MembersWrap>
    </PanelWrapper>
}
