import { HttpError, ResponseCode, WebResponse } from '@src/service'
import { displayToast, ToastTypes } from '@logic'

export function globalDisplayToast(type: ToastTypes, message: string) {
  (window as any).store.dispatch(displayToast(message, type, message))
}

export function displayErrorToast(message: string) {
  globalDisplayToast(ToastTypes.error, message)
}

export const accessDeniedWithFallback = <Response extends unknown>(accessDeniedResponse: Response) => <UnresolvedResponse extends unknown>(
  response: UnresolvedResponse
): Response => {
  if (response instanceof HttpError) {
    if (response.status == ResponseCode.accessDenied) {
      return accessDeniedResponse
    } else {
      displayErrorToast(response.message || 'Something Went Wrong')
      return {} as any // TODO: Make this stronger typed.  Will require a broader improvement of the error handling workflow.
    }
  } else {
    return response as Response
  }
}

export const whenAccessDenied = <T extends unknown>(response: WebResponse<T>): T | undefined => {
  if (response instanceof HttpError) {
    if (response.status == ResponseCode.accessDenied) {
      return undefined
    } else {
      const message = response.message || 'Something Went Wrong'
      const localWindow = window as any
      localWindow.store.dispatch(displayToast(message, ToastTypes.error, message))
      return undefined
    }
  } else {
    return response
  }
}
