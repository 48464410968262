import * as React from 'react'
import { actionPath } from '@logic'
import { Panel } from '@components/panels/panels'
import { PathLink } from '@components/navigation/path-link'
import { FlexCol } from '@src/styles'
import styled from 'styled-components'
import { OrganizationAction } from '../types'

const ActionPanelContent = styled<any>(FlexCol)`
  min-height: 125px;
  justify-content: space-between;
  color: ${props => props.theme.white};
  cursor: pointer;
  :first-child {
    padding: 20px 0 0 0;
  }
`

const StyledPanel = styled<any>(Panel)`
  height: 169px;
`

interface ActionProps {
  action: OrganizationAction
  organization: string
}

export const ActionCard = (props: ActionProps) => {
  const { action, organization } = props
  const id = action.id
  const title = action.title
  const Icon = action.icon

  return (
    <PathLink path={actionPath} args={{ organization, action: id }}>
      <StyledPanel>
        <ActionPanelContent>
          <Icon />
          <span>{title}</span>
        </ActionPanelContent>
      </StyledPanel>
    </PathLink>
  )
}
