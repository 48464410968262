import * as React from 'react'
import { PageProps } from '@logic'
import { AddCardShared } from '@modules/profile/components/add-card-shared'
import { signUpModal } from '@shared/components/modal'

type Props = PageProps

export const GuestAddCardPage = (props: Props) => {
    const { navigate } = props
    const signUpModalBundle = signUpModal({ navigate })

    return <>
        {signUpModalBundle.component}
        <AddCardShared {...props} forGuest={true} openSignUp={() => signUpModalBundle.setVisible(true)} />
    </>
}
