import { useEffect, useRef } from 'react'

export function useDidUpdateEffect(fn: () => any, dependencies: [any]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current)
      fn();
    else
      didMountRef.current = true;
  }, dependencies);
}
