import * as React from 'react'
import { MembersProps, PageProps, ShareholdingFormData, captableCertificatePath } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  InitialShareholdingFormValues,
  prepareCapitalContributionsForSubmission,
  EditShareholdingForm
} from '@modules/captable/components/shareholding-form'
import { loadAuthorizations, loadHolding, loadLegends, loadMembers, loadSecurities } from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions } from '@components/permissions'
import {
  CapitalChangeRequest,
  editHolding,
  ExpandedHoldingResponse,
  FilesResponse,
  GetAuthorizationsResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  patchCapitalChange,
  postFile
} from '@src/service'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps &
  MembersProps &
  GetSecuritiesResponse &
  GetLegendsResponse &
  GetAuthorizationsResponse &
  ExpandedHoldingResponse

const withData = withLoadingCachedMultiple<Props>(
  loadMembers,
  loadHolding,
  loadPermissions,
  loadSecurities(),
  loadLegends(true),
  loadAuthorizations('shareholding')
)

export const EditShareholdingPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { members, navigate, params, service, securities, legends, httpClient, authorizations } = props
    const shareholdingRecord = props.holding!!
    const { organization, shareholding } = params
    const { issueDate, owner, value } = shareholdingRecord
    const legendValue = shareholdingRecord.legend!
    const securitiesList = securities ? securities?.filter(s => !s.parent) : []
    const capitalContributionsRecord = shareholdingRecord?.capitalContributionTotals!
    const security = securitiesList?.find(s => s.hash === shareholdingRecord?.securityDetails!)
    const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })

    const initialValues: InitialShareholdingFormValues = {
      issueDate,
      member: owner,
      shares: value,
      securityType: shareholdingRecord?.securityDetails!,
      capitalContributions: capitalContributionsRecord!,
      approvalDocument: documents,
    }
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: ShareholdingFormData) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const equity = securitiesList?.find(sec => sec.hash == values?.securityType)

      const capitalContributions = prepareCapitalContributionsForSubmission(values).capitalContributions
      const request = {
        owner: values?.member,
        issueDate: values?.issueDate,
        parent: values?.securityType,
        value: values?.shares,
        legend: values?.legend,
        entity: organization,
        name: shareholdingRecord?.name!,
        internalType: equity ? equity.shareTypeUuid : '',
        class: equity ? equity.shareClassPrefix : '',
      }
      const response = await editHolding(service)({ holding: shareholding }, request)
      if (response?.id || response?.hash) {
        const holding = response.hash
        if (capitalContributions?.length > 0) {
          capitalContributions.forEach(async (c: any) => {
            const currentValue = capitalContributionsRecord[c.type]!
            if (c.amount !== currentValue!) {
              const capitalChangeRequest = {
                mod: c.amount,
                timestamp: new Date(),
                type: c.type,
                source: holding,
              } as CapitalChangeRequest
              await patchCapitalChange(httpClient)(
                { entity: organization }, capitalChangeRequest
              )
            }
          })
        }
        modifyAuthorizations({ approvalDocuments, target: holding, httpClient, organization })
        navigate(captableCertificatePath, { organization, shareholding: response?.hash })
      }
    }

    return (
      <PageContent>
        <PageContentHeader title={`Edit Shareholding ${shareholdingRecord?.name}`} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <EditShareholdingForm
            members={members}
            securities={securitiesList}
            legends={legends}
            onUpload={onUpload}
            securityType={security}
            legend={legendValue} />
        </CommonForm>
      </PageContent>
    )
  })
)
