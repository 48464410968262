import React, { useState, ReactElement } from 'react'
import { CommonInput, FlexCol, FlexRow, screenSizes } from '@src/styles'
import styled from 'styled-components'
import { SolidButton } from './button'
import { ErrorLine } from './forms/field-errors'

const AddFieldWrapper = styled<any>(FlexRow)`
  width: 100%;
  height: 50px;
  div, input {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  @media(max-width: ${screenSizes.S}px) {
    width: 100%;
  }
`

interface DynamicAddFieldProps {
  onClick: () => void
  placeholder: string,
}

export interface DynamicAddFieldBundle {
  component: ReactElement
  inputValue: string,
}

export function dynamicAddField(props: DynamicAddFieldProps): DynamicAddFieldBundle {
  const [inputValue, setInputValue] = useState<string>('')
  const { placeholder, onClick } = props
  const isAddress = inputValue !== '' ? !inputValue.match(/^0x[a-fA-F0-9]{40}$/) : false
  const disabled = inputValue == '' || isAddress
  const component = (
    <FlexCol style={{ width: '100%' }} alignItems={'flex-start'}>
      <AddFieldWrapper isAddress={isAddress ? 'true' : 'false'}>
        <CommonInput
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={(e: any) => setInputValue(e.target.value)}
          notEmpty={inputValue ? 'true' : 'false'}
          hasErrors={isAddress} />
        <SolidButton onClick={onClick} disabled={disabled}>
          Add
        </SolidButton>
      </AddFieldWrapper>
      {isAddress ? <ErrorLine>
        Enter a valid ETH address
      </ErrorLine> : null}
    </FlexCol>
  )
  return {
    component,
    inputValue,
  }
}
