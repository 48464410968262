import '@babel/polyfill'
import * as React from 'react'
import { newStore, RootState } from '@logic'
import { createBrowserHistory } from 'history'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import 'react-datepicker/dist/react-datepicker.css'
import { Service } from '@src/service'
import { App } from '@src/app'

export interface AppConfig {
  api: {
    url: string
  }
  appName: string
  clientId: string
  tenantId: string
  mode: WebpackMode
  stubWebservice: boolean
}

export enum WebpackMode {
  development = 'development',
  production = 'production',
}

export type StateTransform<T> = (r: RootState) => T

function newApp(service: Service) {
  const history = createBrowserHistory()

  // TODO: Refactor the initialization of the redux to not require "addDispatch"

  // Hack to replace react-hot-loader with HMR (HMR will delete redux store without this)
  if (!(window as any).store) {
    ;(window as any).store = newStore(history, service)
  }

  service.addDispatch((window as any).store.dispatch)

  return (
    <Provider store={(window as any).store}>
      <App history={history} service={service} />
    </Provider>
  )
}

declare var DISABLE_FULLSTORY: string

export function index(service: Service) {
  if (!DISABLE_FULLSTORY) (window as any).startFullStory()
  const app = newApp(service)
  const appContainer = document.getElementById('root')
  ReactDOM.render(app as any, appContainer)
}
