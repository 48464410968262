import * as React from 'react'
import styled from 'styled-components'
import { FlexCol, FlexRow, semiBoldStyle } from '@src/styles'
import { SubmitButton } from '@shared/components/button'
import CarrotUp from '@image/carrot-up.svg'
import CarrotDown from '@image/carrot-down.svg'
import { BillingManifest } from '@src/service/services/billing'
import { dollarStringFromCents, optionalCommaAmount } from '@shared/helpers'

interface Props {
  manifest: BillingManifest
  remove?: boolean
  disableSubmit?: boolean
  values?: any
  balance: number
  couponValue: number
}

const SummaryWrap = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  background: ${props => props.theme.modalBack};
  .body {
    width: 100%;
    padding: 12px 0;
    align-items: flex-start;
  }
  .title {
    ${semiBoldStyle}
    line-height: 24px;
    padding: 12px 24px;
  }
  .withMargin {
    margin-top: 24px;
  }
  .foot {
    justify-content: space-between;
    padding: 24px 24px 12px 24px;
    border-top: 1px solid rgba(206, 218, 225, 0.3);
    span {
        ${semiBoldStyle}
        :last-child {
            font-size: 24px;
            line-height: 32px;
        }
    }
  }
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.08),
                0px 9px 46px rgba(0, 0, 0, 0.05),
                0px 11px 15px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
`
const Footer = styled.div`
  width: 100%;
  padding: 4px 24px 24px 24px;
  button {
    width: 100%;
  }
`

const StyledRow = styled<any>(FlexRow)`
  padding: 12px 24px;
  div {
    width: 100%;
  }
  p {
    color: #9CA6AD;
    margin: 0;
  }
  span, p {
    font-size: 14px;
    line-height: 19px;
  }
  span {
      :last-child {
        font-size: 20px;
        line-height: 28px;
      }
  }
`

const FixedHeader = styled<any>(FlexRow)`
  padding: ${props => props.open === 'true' ? '12px' : '12px 24px'};
  width: 100%;
  border-bottom: ${props => props.open === 'true' ? '1px solid rgba(206, 218, 225, 0.3)' : 'none'};
  div {
    width: 100%;
  }
  span {
    ${semiBoldStyle}
    font-size: 20px;
    line-height: 28px;
  }
  .amount {
    font-size: 24px;
    line-height: 32px;
  }
  svg {
    cursor: pointer;
    margin-right: 10px;
  }
`

const DiscountPanel = styled<any>(FlexRow)`
  justify-content: space-between;
  padding: 12px 24px;
`

export function CartSummaryModal(props: Props) {
  const { manifest, remove, balance, values, couponValue, disableSubmit } = props
  const [isOpen, setIsOpen] = React.useState<boolean>(true)
  const showEquaCash = values?.equaCash && values.equaCash <= balance && values.equaCash !== 0 ? true : false
  const getDue = () => {
    if (values?.equaCash || couponValue) {
      if (showEquaCash) {
        return manifest?.total_in_cents / 100 - values.equaCash - couponValue
      } else return manifest?.total_in_cents / 100 - couponValue
    }
    else return dollarStringFromCents(manifest?.total_in_cents)
  }

  return (
    <SummaryWrap>
      <FixedHeader open={isOpen ? 'true' : 'false'}>
        {!isOpen ? <CarrotUp onClick={() => setIsOpen(true)} /> : <CarrotDown onClick={() => setIsOpen(false)} />}
        <FlexRow justifyContent={'space-between'}>
          <span>Cart Summary</span>
          {!isOpen && <span className='amount'>{typeof getDue() !== 'string' ? optionalCommaAmount(getDue()) : optionalCommaAmount(getDue())}</span>}
        </FlexRow>
      </FixedHeader>
      {isOpen && <div className='body'>
        <span className='title'>Payment</span>
        {manifest?.line_items?.map(item => {
          return <StyledRow justifyContent={'space-between'}>
            <FlexCol alignItems={'start'}>
              <span>{item.memo!}</span>
              <p>Annual Billing</p>
            </FlexCol>
            <span>{dollarStringFromCents(item.amount_in_cents)}</span>
          </StyledRow>
        })}
        {showEquaCash &&
          <DiscountPanel>
            <span>Equa Cash</span>
            <span>- {optionalCommaAmount(values.equaCash)}</span>
          </DiscountPanel>
        }
        {remove &&
          <DiscountPanel>
            <span>Promo Code</span>
            <span>- {optionalCommaAmount(couponValue)}</span>
          </DiscountPanel>
        }
        <FlexRow className='foot'>
          <span>Due Today:</span>
          <span>{typeof getDue() !== 'string' ? optionalCommaAmount(getDue()) : getDue()}</span>
        </FlexRow>
      </div>}
      <Footer>
        <SubmitButton type='text' disabled={disableSubmit}>Pay Now</SubmitButton>
      </Footer>
    </SummaryWrap>
  )
}
