import * as React from 'react'
import { editPermissionsPath, MembersProps, PageProps, rolesPath, viewRolePath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { createNewRole, editMemberRole, GetRolesResponse, NewMemberRoleRequest, NewRoleRequest } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { preparePermissionsForSubmission, RoleForm } from '../components/role-form'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { getQueryParams, loadMembers, loadRoles } from '@shared/helpers'
import { colors, Themes, whiteColor } from '@src/styles'
import { allPermissions, getRolesFooterBanner } from '../utility'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

export const RolesWrapper = styled.div<any>`
  ${whiteColor}
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  img {
      width: 100%;
      height: auto;
      z-index: 1;
      margin-top: auto;
  }
  svg {
    path{
      fill: ${props => props.theme.selected === Themes.LIGHT && '#2B3B44'}
    }
  }
`

export const SubHeaderText = styled.span<any>`
    font-size: ${props => props.view !== 'true' && '20px'};
    line-height: ${props => props.view !== 'true' ? '28px' : '24px'};
    font-weight: ${props => props.view !== 'true' ? 600 : 'normal'};
    font-family: ${props => props.view !== 'true' && 'NunitoSansSemiBold'};
    color: ${props => props.theme.white};
    max-width: 828px;
    svg {
        margin-right: 10px;
        path {
            fill: ${props => props.theme.selected === Themes.LIGHT && '#2B3B44'}
        }
    }
`

const FormWrapper = styled.div<any>`
`

type Props = PageProps & MembersProps & ThemeProps<any> & GetRolesResponse

const withData = withLoadingCachedMultiple<Props>(loadMembers, loadRoles)

export const CreateRolePage = withData(withTheme(withPermissions([BuiltInPermission.editMembers])((props: Props) => {
    const { httpClient, navigate, params, members, theme, roles } = props
    const { organization } = params
    const clone = getQueryParams().clone!
    const fromPermissions = getQueryParams().matrix!
    const currentRole = roles?.find(r => r.id === clone!)
    const sortedMembers = members?.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName)
    })
    const permission: any = {}
    currentRole?.permissions?.forEach(p => {
        permission[`${p.id}`] = true
    })
    const initialValues = {
        role: currentRole?.name ? currentRole.name + ' Clone' : '',
        description: currentRole?.description!,
        permission,
        all: currentRole?.permissions?.length === allPermissions?.length
    }

    const onSubmit = async (values: any) => {
        const data = preparePermissionsForSubmission(values)
        const request = {
            role: data.role,
            description: data.description,
            permission: data.permission!,
        } as NewRoleRequest
        const response = await createNewRole(httpClient)({ organization }, request)
        if (response?.id) {
            const roleId = response.id!
            if (values?.members?.length > 0) {
                const memberRoleRequest = {
                    member: values?.members!,
                } as NewMemberRoleRequest
                await editMemberRole(httpClient)({ organization, role: roleId }, memberRoleRequest)
            }
            if (!fromPermissions) navigate(viewRolePath, { organization, role: roleId })
            else navigate(editPermissionsPath, { organization })
        }
        else console.log('create role error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: rolesPath, args: { organization } }

    return (
        <RolesWrapper>
            <PageContent>
                <PageContentHeader title={!clone ? 'Create a New Role' : 'Clone an Exisiting Role'} parent={parent} />
                <SubHeaderText style={{ marginTop: '11px' }}>{!clone ?
                    `You’ve created a brand new role from scratch. Give your new role a unique name and description, then add your custom permissions.
                 You can also assign your members to this role at the bottom of this page.`
                    :
                    `You’ve cloned an existing role.
                 Now go ahead and give it a unique name and description, then review and adjust the permissions.
                 You can also assign your members to this role at the bottom of this page.`
                }</SubHeaderText>
                <FormWrapper clone={clone ? 'true' : 'false'}>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={clone ? initialValues : {}}
                        stretched={true}>
                        <RoleForm
                            members={sortedMembers}
                            roles={roles}
                            skip={{ path: rolesPath, args: { organization } }}
                            clone={clone}
                        />
                    </CommonForm>
                </FormWrapper>
            </PageContent>
            <img src={getRolesFooterBanner(theme?.selected!)} alt="rolesFooter" />
        </RolesWrapper>
    )
})))
