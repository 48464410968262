import * as React from 'react'
import { boldStyle, FlexCol, FlexRow, semiBoldStyle, whiteColor } from '@src/styles'
import styled from 'styled-components'
import Wallet from '@image/wallet.svg'
import Debt from '@image/debt.svg'
import Claim from '@image/claim.svg'
import Savings from '@image/savings.svg'
import Networks from '@image/networks.svg'
import Protocols from '@image/protocols.svg'
import Ethereum from '@image/ethereum.svg'
import Bsc from '@image/bsc.svg'
import Polygon from '@image/polygon.svg'
import { formatCurrency, toCommaFloat } from '@shared/helpers'

interface InfoProps {
    walletData: any
    savingsData: any
    claimData: any
    debtData: any
    vaultData: any
    networksData: any
    protocolsData: any
    supportedProtocols?: any
}

const CategoryWrap = styled<any>(FlexCol)`
    padding: 16px;
    background: ${({ theme }) => theme.walletCat};
    border-radius: 4px;
    align-items: flex-start;
    width: 100%;
`

const WalletInfoWrap = styled<any>(FlexCol)`
    background: ${({ theme }) => theme.walletBack};
    ${whiteColor}
    padding: 32px;
    span {
        display: block;
    }
    align-items: flex-start;
    max-width: 845px;
    border-radius: 4px;
    margin: 24px 0px;
    .wrap {
        margin-bottom: 24px;
    }
    .wrap:last-child {
        margin-bottom: 0;
    }
`

const NetWorthText = styled.span<any>`
    font-size: 20px;
    line-height: 28px;    
    color: #9CA6AD;
`

const NetWorthValue = styled.span<any>`
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
`

const CategoryRow = styled<any>(FlexRow)`
    border-bottom: 1px solid rgba(206, 218, 225, 0.3);
    padding-bottom: 24px;
    width: 100%;
    .cat {
        ${boldStyle}
        font-size: 20px;
        line-height: 28px;
    }
    .total {
        font-size: 18px;
        line-height: 26px;
        color: #9CA6AD;
    }
    svg {
        margin-right: 12px;
    }
`

const ValueRow = styled<any>(FlexRow)`
    border-bottom: 1px solid rgba(206, 218, 225, 0.3);
    padding: 24px 0 12px 0;
    width: 100%;
    .token {
        ${boldStyle}
        line-height: 24px;
    }
    .value {
        font-size: 14px;
        line-height: 19px;
    }
    svg, img {
        margin: 0 20px 0 5px;
    }
    img {
        width: 31px;
        height: 31px;
        border-radius: 50%;
    }
`

const getCategoryIcon = (cat: string) => {
    switch (cat) {
        case 'Wallet':
            return <Wallet />
        case 'Savings':
            return <Savings />
        case 'Claimable':
            return <Claim />
        case 'Debt':
            return <Debt />
        case 'Networks':
            return <Networks />
        case 'Protocols':
            return <Protocols />
    }
}

const getNetworkIcon = (network: string) => {
    switch (network) {
        case 'Ethereum':
            return <Ethereum />
        case 'Polygon':
            return <Polygon />
        case 'BSC':
            return <Bsc />
    }
}

const renderCategory = (
    category: any,
    data: any,
    allIcons: any[],
    total?: number,
    forNetwork?: boolean) => {
    const sortedData = data.sort((a, b) => b.value - a.value)
    return <CategoryWrap className='wrap'>
        <CategoryRow>
            {getCategoryIcon(category)}
            <div>
                <span className='cat'>{category}</span>
                {total ? <span className='total'>{formatCurrency(total)}</span> : null}
            </div>
        </CategoryRow>
        {sortedData.map((d: any) => {
            const address = d.tokens ? d.tokens[0].address : undefined
            const icon = allIcons.find(a => a.name == d.name)
            const imgUrl = !forNetwork ? icon ? icon.image : address ?
                `https://storage.googleapis.com/zapper-fi-assets/tokens/${d.network}/${address}.png` : undefined : undefined

            return <ValueRow>
                {forNetwork ? getNetworkIcon(d.label) : imgUrl ?
                    <img src={imgUrl} alt='token' /> : null}
                <FlexRow justifyContent={'space-between'} style={{ width: '100%' }}>
                    <div>
                        <span className='token'>{d.name}</span>
                        {d.price ? <span className='value'>{formatCurrency(d.price)}</span> : null}
                    </div>
                    <FlexCol alignItems={'flex-end'}>
                        {d.balance ? <span className='token'>{toCommaFloat(d.balance, 4)}</span> : null}
                        <span className='value'>{formatCurrency(d.value)}</span>
                    </FlexCol>
                </FlexRow>
            </ValueRow>
        })}
    </CategoryWrap>
}

export const WalletInfo = (props: InfoProps) => {
    const {
        walletData,
        savingsData,
        claimData,
        debtData,
        vaultData,
        networksData,
        protocolsData,
        supportedProtocols
    } = props
    const walletTotal = walletData.reduce((a, b) => a + b.value, 0)
    const savingsTotal = savingsData.reduce((a, b) => a + b.value, 0)
    const claimTotal = claimData.reduce((a, b) => a + b.value, 0)
    const debtTotal = debtData.reduce((a, b) => a + b.value, 0)
    const vaultTotal = vaultData.reduce((a, b) => a + b.value, 0)
    const totalNetWorth = walletTotal + savingsTotal + claimTotal + vaultTotal - debtTotal
    let protocolApps: any = []
    supportedProtocols.forEach(s => {
        s.apps.filter(a => a.appId !== 'nft' && a.appId !== 'tokens')?.forEach(app => {
            return protocolApps = protocolApps.concat({ name: app.meta.label, image: app.meta.img })
        })
    })
    let allIconsForTokens: any = []
    walletData.concat(savingsData, claimData, debtData, vaultData, protocolApps).forEach((data: any) => {
        if (data.tokens && data.tokens[0].img) {
            allIconsForTokens = allIconsForTokens.concat({ name: data.name, image: data.tokens[0].img })
        }
        if (data.image) allIconsForTokens = allIconsForTokens.concat({ name: data.name, image: data.image })
    })

    return (
        <>
            <WalletInfoWrap>
                <NetWorthText>Net Worth</NetWorthText>
                <NetWorthValue>{formatCurrency(totalNetWorth)}</NetWorthValue>
                {walletData.length > 0 ? renderCategory('Wallet', walletData, allIconsForTokens, walletTotal) : null}
                {savingsData.length > 0 ? renderCategory('Savings', savingsData, allIconsForTokens, savingsTotal) : null}
                {claimData.length > 0 ? renderCategory('Claimable', claimData, allIconsForTokens, claimTotal) : null}
                {debtData.length > 0 ? renderCategory('Debt', debtData, allIconsForTokens, debtTotal) : null}
            </WalletInfoWrap>
            <WalletInfoWrap>
                <NetWorthValue>Networks & Protocols</NetWorthValue>
                {renderCategory('Networks', networksData, [], undefined, true)}
                {protocolsData.length > 0 ? renderCategory('Protocols', protocolsData, allIconsForTokens) : null}
            </WalletInfoWrap>
        </>
    )
}
