import { RouterState } from 'connected-react-router'
import { Action } from 'redux'
import { NavigationProps } from '@components/navigation/navigation'
import { ServiceProps } from '@shared/hocs/with-service'
import { Permission } from '@components/permissions'
import { Hash, StringMap, Uuid } from '@helpers/util'
import { Member, ShareType, ShareTypeUuid, TaxId } from '@src/logic/organized-types'
import { SelectOption } from '@components/forms'
import { NewAddressRequest } from '@logic'
import { IncentiveTypes } from '@shared/helpers/constants'

export const supportedMaxShares = 1000000000000

export interface User {
  id: string
  username: string
  email: string
  profile: Partial<Profile>
  acceptedTerms?: boolean
  emailVerified?: boolean
  isAdmin?: boolean
  accessToken?: string
  password?: string
  status: boolean
}

export interface Profile {
  fullName: string
}

export enum OrganizationError {
  GetDriveFailed,
}

export interface OrganizationState {
  organizationList?: Organization[]
  currentOrganization?: Organization
  organizationError?: OrganizationError
  certificateList?: any
}

export interface Manager {
  user?: string
  email?: string
  fullName?: string
  role?: any
}

export enum Features {
  legends = 'legends',
  transfers = 'transfers',
}

export interface Organization {
  id: string
  name: string
  email: string
  businessAddress: Address
  mailingAddress: Address
  regAgentAddress: Address
  bankAccAddress: Address
  creator?: Uuid
  logo?: any
  driveId?: any
  operatingAgreement: any
  primaryActivity: string
  type: string
  isAddressSame: boolean
  editId: string
  companyType: number
  otherCompanyType: string
  members: Member[]
  agent: any
  createBankAcc: string
  features: Features[]
  website: string
  taxIds: TaxId[]
}

export interface Address {
  street1: string
  street2: string
  street3: string
  country: string
  city: string
  postalCode: string
  province?: string
  provinceAbbreviation?: string
}

export enum ToastTypes {
  error,
  success,
}

export interface ToastItem {
  key: any
  label: string
  type: ToastTypes
}

export interface SuccessItem {
  key: any
  label: string
}

export type ToastItems = { [index: string]: ToastItem }

export interface RootState {
  router?: RouterState
  user?: User
  organization: OrganizationState
  toasts?: ToastItems
  access?: Access
  checklist?: any
}

export interface Access {
  needsTwoFactor?: boolean
}

export interface LoginRequest {
  usernameOrEmail: string
  password: string
  twoFactorToken?: string
}

export interface NewUser {
  username?: string
  email: string
  password: string
  twoFactorSecret?: string
  acceptedTerms?: boolean
}

export interface NewProfileRequest {
  fullName: string
  homeAddress?: NewAddressRequest
  website?: string
  email?: string
  phone?: string
  dateOfBirth?: Date
  file?: Hash
}

export interface ReferralRequest {
  referralLink?: string
  referralId?: string
  recipientByEmail?: string
  recipientByLink?: string
}

export interface NewUserRequest {
  user?: NewUser
  profile: NewProfileRequest
  referral?: ReferralRequest
  coupon?: string
  metaData?: any
}

export interface UpdateUserProfileRequest {
  profile: string
  fullName: string
  website?: string
  email?: string
  phone?: string
  dateOfBirth?: Date
  file?: Hash
  links?: StringMap
}

export interface PasswordResetRequest {
  usernameOrEmail?: string
}

export interface VerifyTwoFactorRequest {
  token: string
  secret: string
}

export interface EmailAvailableRequest {
  email: string
}

export interface UsernameAvailableRequest {
  username: string
}

export interface LoginResponse {
  user?: User
  profile?: Profile
  needsTwoFactor?: boolean
  status?: boolean
}

export interface GetCurrentUserResponse {
  user?: User
  callback?: any
}

export interface GetUsersResponse {
  users: User[]
}

export interface GetCurrentOrganizationResponse {
  organization?: Organization
}

export interface GetOrganizationFeaturesResponse {
  organizationFeatures: Features
}

export interface GetOrganizationPermissions {
  permissions: Permission[]
}

export interface AddressResponse {
  address: Address
}

export interface OrganizationResponse {
  id: string
  name: string
  creator?: Uuid
}

export interface GetOrganizationListResponse {
  organizations: Organization[]
}

export interface OrganizationsProps {
  organizations: Organization[]
}

export interface TwoFactorSecretResponse {
  secret?: string
}

export interface ChecklistState {
  capTableData?: any
  addMemberResponse?: any
  expanded: boolean
  isError?: boolean
}

export interface CapTableState {
  capTableData?: any
  addMemberResponse?: any
  isLoading?: boolean
  isError: boolean
}

export interface ReferralUserDetails {
  position_ahead?: number
  position_behind?: number
}

export enum OrganizationType {
  public = 1,
  private = 2,
  investor = 3,
}

export enum ToolType {
  zoom = 1,
  skype = 2,
  meet = 3,
}

export interface CompanyInfo {
  id: string
  email: string
  name: string
  phoneNumber: string
  organizationType: OrganizationType
  scheduleTool: ToolType
  numberSecurityHolder: number
  user?: string
}

export interface ReferralData {
  userDetails: ReferralUserDetails
  companyInfo?: CompanyInfo
}

export interface MyReferralState {
  refData?: any[]
  googleContacts?: any[]
  scratchCardList?: any[]
  referralData?: ReferralData
  invitedFriendList?: any
  scratchCardsResponse?: any
  inviteResponse?: any
  submitInfoResponse?: any
  isLoadingGoogleContacts: boolean
}

export interface ReferralUserDetailsResponse {
  email: string
  equaCash: number
  noofref: number
  position_ahead: number
  position_behind: string
  referralLink: string
  status: number
  username: string
}

export interface ReferralOrganizationResponse {
  orgTotalReward: number
  orgDailyReward: number
  orgMonthlyReward: number
}

export interface ReferralUserResponse {
  totalReward: number
  dailyReward: number
  monthlyReward: number
}

export interface GetReferralDataResponse {
  userDetails: ReferralUserDetailsResponse
}

export interface GetOrgReferralDataResponse {
  orgRewardDetails: ReferralOrganizationResponse
  userRewardDetails: ReferralUserResponse
  referralLink: string
  userReferralLink: string
}

export interface GetGoogleContactResponse {
  googleContact?: MyReferralState
}

export enum RewardType {
  referral = 1,
  signup = 2,
  organizationInfo = 3,
  obcl = 4
}

export interface ExpandedReward {
  id: string
  user?: Uuid
  reward: number
  type: RewardType
  scratched: boolean
  scratchedDate?: Date
  organization?: Uuid
}

export interface ScratchCardResponse {
  dailyReward: number
  monthlyReward: number
  dailyTicket: number
  monthlyTicket: number
  rewards: ExpandedReward[]
  totalReward: number
}

export interface GetScratchCardResponse {
  rewardDetails: ScratchCardResponse
  orgRewards: { orgTickets: ExpandedReward[] }
}

interface ExpandedInvitation {
  created: string
  email: string
  modified: string
  status: number
}

export interface InvitationDetails {
  invitations: ExpandedInvitation[]
  dailyInvitation: number
  monthlyInvitation: number
  totalCompletedInvitation: number
  totalInvitations: number
  totalPendingInvitation: number
}

export interface InvitedFriendsResponse {
  invitationDetails: InvitationDetails
}

export interface ScratchCardsResponse {
  scratchCardsResponse?: MyReferralState
}

export interface SendInvitationResponse {
  inviteResponse?: MyReferralState
}

export interface SubmitInformationResponse {
  submitInfoResponse?: MyReferralState
}

export interface IsAvailableResponse {
  isAvailable: boolean
}

export type EmptyResponse = {}

export type CreateOrganizationRequest = Partial<Organization>

export interface OrganizationResponse {
  organization: Organization
}

export interface TeamMemberState {
  teamMemberData?: any
  teamMemberList?: any
  memberProfileData?: any
  uploadCsvResponse?: any
  inviteTeamMemberRes?: any
  recentActionsList?: any
  exerciseStockOptionRes?: any
  isLoading: boolean
  isError: boolean
  profileLoading: boolean
}

export interface UploadTeamMemberCsvResponse {
  uploadCsvResponse?: any
}

export interface InviteTeamMemberResponse {
  inviteTeamMembeRes?: any
}

export interface GetRecentActionsResponse {
  recentActionsList?: any
}

export interface ExerciseStockOptionResponse {
  exerciseStockOptionRes?: any
}

export enum AsyncSuffix {
  start = '_START',
  success = '_SUCCESS',
  failure = '_FAILURE',
}

export interface RequestAction extends Action {
  data: any
  callback?: any
}

export interface DocumentRecord {
  id: string
  name: string
  folder?: any
  type?: number
  '@microsoft.graph.downloadUrl'?: string
}

export interface PageProps extends NavigationProps, ServiceProps {
  user?: User
  reload: () => void
}

export interface OrganizationPropsOld {
  organization: Uuid
}

export type FilterOption = SelectOption<Hash | number | undefined>
export type FilterOptions = Array<SelectOption<Hash | number | undefined>>

export enum ExpansionMethod {
  nested = 'nested', // Expanded objects are embedded inside their parent object
  normalized = 'normalized', // Expanded objects are returned in a root array
}

export enum CurrencyType {
  shares = '8b4bfe5c-96be-11ea-bb37-0242ac130002',
  usd = 'e2d7d4b3-05db-43eb-95c1-9e518bb7f750'
}

export enum AddressType {
  member = '1acf39d8-96cc-11ea-bb37-0242ac130002',
  shareholding = '97003b20-500a-4880-805f-e37af48e0419',
  option = 'e85fabb6-e529-4831-9051-421bce7ebf27',
  user = '2454ea6e-8149-43ed-99fc-eaa357cc5525',
  organization = 'd074322e-1451-460d-b138-52584e423f05'
}

export type Money = number

export enum EquaCashTransferType {
  transferProfile = 1,
  transferOrganization = 2,
  earned = 3,
  spend = 4
}

export const getTransferType = {
  [EquaCashTransferType.transferProfile]: 'Transfer Profile',
  [EquaCashTransferType.transferOrganization]: 'Transfer Org',
  [EquaCashTransferType.earned]: 'Earned',
  [EquaCashTransferType.spend]: 'Spend',
} as any

export interface FileRecord {
  id: Uuid
  hash: Hash
  filename: string
  url: string
  extension: string
  contentType: string
  size: number
  owner: Uuid
}
export interface SecurityType {
  id?: Uuid
  hash: Hash
  certifiedShares: boolean
  dividendConfig?: Hash
  issueDate?: Date
  fractionalShares: boolean
  incorporationDocument?: Hash
  legend?: Hash
  organization: Uuid
  shareClass: string
  shareClassPrefix?: string
  sharePricing: Hash
  shareType: ShareType
  shareTypeUuid?: ShareTypeUuid
  value?: number
  votingShares: boolean
  name?: string
  parent?: Hash
  price?: number
  type?: IncentiveTypes
  deleted?: boolean
}