import styled from 'styled-components'
import { FlexRow } from '@src/styles'

export const LabelGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -20px;
`
export const LabelButton = styled.label`
  cursor: pointer;
  margin-right: 20px;
  color: ${props => props.theme.white};
  opacity: 1;
  svg {
    fill: ${props => props.theme.white};
    height: 16px;
  }
`
export const DropDiv = styled<any>(FlexRow)`
  cursor: pointer;
  color: ${props => props.theme.white};
  opacity: 1;
`

export const Paragraph = styled.p`
  color: ${props => props.theme.white};
  text-align: left;
  font-size: 0.9em;
  border-bottom: ${props => props.theme.gray};
  span, a {
    font-weight: bold;
    font-family: NunitoSansBold;
  }
`

export const BtnCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const IntroModal = styled.div`
  p {
    margin-top: 0;
  }
  ul {
    padding: 0;
  }
  li {
    list-style-type: none;
    text-align: left;
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: 0.9em;
    svg {
      margin-right: 5px;
    }
  }
`
