import * as React from 'react'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadCaptableData } from '@helpers/loaders'
import { whiteColor, gapSizes, FlexRow } from '@src/styles'
import { PathLink } from '@components/navigation'
import { CellProps } from 'react-table'
import { Table } from '@components/tables/table'
import { Avatar } from '@components/avatar'
import styled from 'styled-components'
import { organizationMemberPath, PageProps } from '@logic'
import { optionalUsdString } from '@modules/convertibles/components'
import { toCommaFloat } from '@shared/helpers'
import { GetCaptableDashboardResponse } from '@src/service'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetCaptableDashboardResponse

const withData = withLoadingCachedMultiple<Props>(loadCaptableData('false'))

const HoldersListWrapper = styled.div`
  ${whiteColor}
  h4 {
    padding-bottom: ${gapSizes.S};
  }
  a {
    ${whiteColor}
  }
`

const HolderRow = styled<any>(FlexRow)`
  align-items: center;
  width: min-content;
  .avatar {
    cursor: pointer;
  }
`

export const HolderReportsPage = withPermissions([BuiltInPermission.viewCapTable, BuiltInPermission.viewSelf])(
  withData((props: Props) => {
    const { params } = props
    const { organization } = params
    const avatarSize = '36px'
    const pageSize = 100000
    const orgMembers = props.members!!
    const memberList = orgMembers ? orgMembers.filter(m => m.holdings?.length > 0 || m.voided?.length > 0)?.map(o => {
      return { ...o, hidden: !o.inTable }
    }) : []

    const columns = [
      {
        Header: 'Holder',
        accessor: 'name',
        Cell: ({ row }: CellProps<any>) => {
          return (
            <HolderRow>
              <Avatar
                size={avatarSize}
                image={row.original.file?.hash}
                fullName={row.original.name}
                className='avatar'
                onClick={() => window.open(`/organization/${organization}/member/${row.original.id}`)} />
              <PathLink path={organizationMemberPath} args={{ organization, member: row.original.id }} target='_blank'>
                <span>{row.original.name}</span>
              </PathLink>
            </HolderRow>
          )
        },
        Footer: () => <div>TOTAL</div>,
      },
      {
        Header: 'Investment',
        accessor: 'memberInvestment',
        Cell: ({ row }: CellProps<any>) => optionalUsdString(row.original.memberInvestment || 0),
        Footer: (info: any) => {
          const investmentTotal = info.data.reduce((a: any, b: any) => a + (b.memberInvestment || 0), 0)
          return <span>{optionalUsdString(investmentTotal)}</span>
        },
      },
      {
        Header: 'Outstanding',
        accessor: 'memberOutstanding',
        Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.memberOutstanding || 0),
        Footer: (info: any) => {
          const outstandingTotal = info.data.reduce((a: any, b: any) => a + (b.memberOutstanding || 0), 0)
          return <span>{toCommaFloat(outstandingTotal)}</span>
        }
      },
      {
        Header: 'Ownership',
        accessor: 'memberOutstandingPer',
        Cell: ({ row }: CellProps<any>) => {
          const memberOutstandingPer = row.original.memberOutstandingPer || 0
          return <span>{memberOutstandingPer.toFixed(2)}%</span>
        },
        Footer: (info: any) => {
          const memberOutstandingPerTotal = info.data.reduce((a: any, b: any) => a + (b.memberOutstandingPer || 0), 0)
          return <span>{memberOutstandingPerTotal.toFixed(2)}%</span>
        }
      },
      {
        Header: 'Fully Diluted',
        accessor: 'memberFullyDiluted',
        Cell: ({ row }: CellProps<any>) => {
          const memberFullyDiluted = row.original.memberFullyDiluted || 0
          return <span>{toCommaFloat(memberFullyDiluted)}</span>
        },
        Footer: (info: any) => {
          const memberFullyDilutedTotal = info.data.reduce((a: any, b: any) => a + (b.memberFullyDiluted || 0), 0)
          return <span>{toCommaFloat(memberFullyDilutedTotal)}</span>
        }
      },

      {
        Header: 'Ownership',
        accessor: 'memberFullyDilutedPer',
        Cell: ({ row }: CellProps<any>) => {
          const memberFullyDilutedPer = row.original.memberFullyDilutedPer || 0
          return <span>{memberFullyDilutedPer.toFixed(2)}%</span>
        },
        Footer: (info: any) => {
          const memberFullyDilutedPerTotal = info.data.reduce((a: any, b: any) => a + (b.memberFullyDilutedPer || 0), 0)
          return <span>{memberFullyDilutedPerTotal.toFixed(2)}%</span>
        }
      },
    ]

    return (
      <PageContent>
        <PageContentHeader title='Holders' hideCrumbs={true} />
        <HoldersListWrapper>
          <Table
            columns={columns}
            data={memberList}
            scrollingTable={true}
            withAllBorders={true}
            pageSize={pageSize}
          />
        </HoldersListWrapper>
      </PageContent>
    )
  }))
