import { reduceServices, ServiceHandler } from '../lib/async-reduction'

/*
 This file initializes all HTTP related Redux actions.
 For each item in `serviceHandlers`, a reducer is created.  Where the type string of the item is 'FOO',
 the generated reducer will respond to actions of type 'FOO_START' and then
 dispatch an action of either type 'FOO_SUCCESS' or 'FOO_FAILURE'.
  */

export enum AsyncBaseActionType {
  GET_CURRENT_USER = 'GET_CURRENT_USER',
  PATCH_USER = 'PATCH_USER',
  REGISTER = 'REGISTER',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  PASSWORD_RESET = 'PASSWORD_RESET',
  CHECK_VERIFICATION = 'CHECK_VERIFICATION',
  UPDATE_USER = 'UPDATE_USER',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  ORGANIZATION_LIST = 'ORGANIZATION_LIST',
  CURRENT_ORGANIZATION = 'CURRENT_ORGANIZATION',
  UPLOAD_TEAM_MEMBER_CSV = 'UPLOAD_TEAM_MEMBER_CSV',
  INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER',
  GET_RECENT_ACTIONS = 'GET_RECENT_ACTIONS',
  EXERCISE_STOCK_OPTION = 'EXERCISE_STOCK_OPTION',
  GET_REFERRAL_DATA = 'GET_REFERRAL_DATA',
  GET_GOOGLE_CONTACT = 'GET_GOOGLE_CONTACT',
  GET_ALL_SCRATCH_CARDS = 'GET_ALL_SCRATCH_CARDS',
  INVITED_FRIENDS = 'INVITED_FRIENDS',
  SCRATCH_CARDS = 'SCRATCH_CARDS',
  SEND_INVITATION = 'SEND_INVITATION',
  SUBMIT_INFORMATION = 'SUBMIT_INFORMATION',
  UPLOAD_CERTIFICATE_CSV = 'UPLOAD_CERTIFICATE_CSV',
  GET_ONBOARDING_STATUS = 'GET_ONBOARDING_STATUS',
  SCHEDULE_MEETING = 'SCHEDULE_MEETING',
  SETUP_CAP_TABLE = 'SETUP_CAP_TABLE',
}

const serviceHandlers: ServiceHandler[] = [
  {
    type: AsyncBaseActionType.GET_CURRENT_USER,
    asyncFunction: service => service.getCurrentUser,
  },
  {
    type: AsyncBaseActionType.PATCH_USER,
    asyncFunction: service => service.updateUser,
  },
  {
    type: AsyncBaseActionType.REGISTER,
    asyncFunction: service => service.register,
  },
  {
    type: AsyncBaseActionType.LOGIN,
    asyncFunction: service => service.login,
  },
  {
    type: AsyncBaseActionType.LOGOUT,
    asyncFunction: service => service.logout,
  },
  {
    type: AsyncBaseActionType.PASSWORD_RESET,
    asyncFunction: service => service.passwordReset,
  },
  {
    type: AsyncBaseActionType.UPDATE_USER,
    asyncFunction: service => service.updateUserProfile,
  },
  {
    type: AsyncBaseActionType.CREATE_ORGANIZATION,
    asyncFunction: service => service.createOrganization,
  },
  {
    type: AsyncBaseActionType.ORGANIZATION_LIST,
    asyncFunction: service => service.getOrganizationList,
  },
  {
    type: AsyncBaseActionType.CHECK_VERIFICATION,
    asyncFunction: service => service.checkVerification,
  },
  {
    type: AsyncBaseActionType.CURRENT_ORGANIZATION,
    asyncFunction: service => service.getCurrentOrganization,
  },
  {
    type: AsyncBaseActionType.UPLOAD_TEAM_MEMBER_CSV,
    asyncFunction: service => service.uploadTeamMemberCsv,
  },
  {
    type: AsyncBaseActionType.INVITE_TEAM_MEMBER,
    asyncFunction: service => service.inviteTeamMember,
  },
  {
    type: AsyncBaseActionType.GET_RECENT_ACTIONS,
    asyncFunction: service => service.getRecentActions,
  },
  {
    type: AsyncBaseActionType.EXERCISE_STOCK_OPTION,
    asyncFunction: service => service.exerciseStockOption,
  },
  {
    type: AsyncBaseActionType.GET_REFERRAL_DATA,
    asyncFunction: service => service.getReferralData,
  },
  {
    type: AsyncBaseActionType.GET_GOOGLE_CONTACT,
    asyncFunction: service => service.getGoogleContact,
  },
  {
    type: AsyncBaseActionType.GET_ALL_SCRATCH_CARDS,
    asyncFunction: service => service.getAllScratchCards,
  },
  {
    type: AsyncBaseActionType.INVITED_FRIENDS,
    asyncFunction: service => service.invitedFriends,
  },
  {
    type: AsyncBaseActionType.SCRATCH_CARDS,
    asyncFunction: service => service.scratchCards,
  },
  {
    type: AsyncBaseActionType.SEND_INVITATION,
    asyncFunction: service => service.sendInvitation,
  },
  {
    type: AsyncBaseActionType.SUBMIT_INFORMATION,
    asyncFunction: service => service.submitInformation,
  },
  {
    type: AsyncBaseActionType.UPLOAD_CERTIFICATE_CSV,
    asyncFunction: service => service.uploadCertificateCsv,
  },
  {
    type: AsyncBaseActionType.GET_ONBOARDING_STATUS,
    asyncFunction: service => service.getOnBoardingStatus,
  },
  {
    type: AsyncBaseActionType.SCHEDULE_MEETING,
    asyncFunction: service => service.scheduleMeeting,
  },
  {
    type: AsyncBaseActionType.SETUP_CAP_TABLE,
    asyncFunction: service => service.setupCapTable,
  },
]

export const serviceReducer = reduceServices(serviceHandlers)
