import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors, DetailsLabel, Divider, FlexRow, fontSizes, Header2, ListLabel, ListValue } from '@src/styles'
import { Panel } from '@components/panels/panels'
import {
  convertToSharesOrUndefined,
  getAccrualFrequency,
  getInstrumentType, getOrganizationTotalUnits,
  getSharePriceOrUndefined, optionalSecurityTreasury,
} from '../utility'
import {
  formatDate,
  formatDateString,
  formatSecurityName,
  optionalCommaNumber,
  parseOptionalFloat,
  usdString
} from '@helpers/index'
import { ExpandedSecurity } from '@src/service'
import { ConvertibleInstrumentFormFields } from '@modules/convertibles'

interface Props {
  values: ConvertibleInstrumentFormFields
  securities: ExpandedSecurity[],
  forEdit: boolean
}

const Info = css`
  font-size: 18px;
  line-height: 25px;
`

const StyledDetails = styled<any>(Panel)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
  color: ${props => props.theme.white};
  padding: 26px 35px 30px 45px;
  border-radius: 8px;
  div {
    justify-content: space-between;
  }
  p {
    ${Info}
    color: ${props => props.theme.gray};
    margin: 0 0 0 20px;
  }
  span {
    ${Info}
  }
`
const StyledHeader = styled<any>(Header2)`
  line-height: 49px;
`

const Divide = styled.div<any>`
  ${Divider}
  margin: ${props => props.stretch && '20px 0'};
  border-bottom: 1px solid ${colors.slateGrey};
`

const StyledError = styled<any>(FlexRow)`
  padding: 20px 43px;
  background: ${colors.errorBack};
  border: 2px solid ${colors.errorRed};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: 34px;
  span {
    font-size: ${fontSizes.XS};
    line-height: 22px;
    font-weight: bold;
    font-family: 'NunitoSansBold';
    color: ${colors.errorRed};
  }
`

const renderInstrumentDetails = (label: string, value: any, index: number, error?: string) => {
  return (
    <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
      <ListLabel error={error}>{label}</ListLabel>
      <ListValue error={error}>
        {value}
      </ListValue>
    </FlexRow>
  )
}

export function optionalPercentage(value?: number | string): string {
  return typeof value == 'number' || typeof value == 'string'
    ? `${value}%`
    : ''
}

export function optionalUsdString(value?: number, decimalPlaces?: number): string | undefined {
  return typeof value == 'number'
    ? usdString(value, decimalPlaces)
    : undefined
}

export function optionalShares(value?: number): number {
  return typeof value == 'number'
    ? parseFloat(value.toFixed(8))
    : 0
}

export function optionalSharesRoundedUp(value?: number): number | undefined {
  return typeof value == 'number'
    ? Math.ceil(value)
    : undefined
}

export const InstrumentDetails = (props: Props) => {
  const { values, securities, forEdit } = props

  const { accrualFrequency, convertsTo, maturityDate, name, instrumentType } = values
  const authorizedRaiseAmount = parseOptionalFloat(values.authorizedRaiseAmount)
  const valuationMin = parseOptionalFloat(values.valuationMin)
  const valuationMax = parseOptionalFloat(values.valuationMax)
  const pricePerUnit = parseOptionalFloat(values.pricePerUnit)
  const discount = parseOptionalFloat(values.conversionDiscount)
  const interestRate = parseOptionalFloat(values.interestRate)

  const security = securities.filter(security => security.hash === convertsTo)[0]
  const treasury = optionalSecurityTreasury(security)
  const organizationUnits = getOrganizationTotalUnits(securities)

  const minPricePerShare = getSharePriceOrUndefined(valuationMin, organizationUnits, discount)
  const maxPricePerShare = getSharePriceOrUndefined(valuationMax, organizationUnits, discount)

  const minShares = convertToSharesOrUndefined(authorizedRaiseAmount, maxPricePerShare)
  const maxShares = convertToSharesOrUndefined(authorizedRaiseAmount, minPricePerShare)
  const maturityDateValue = maturityDate && formatDateString(maturityDate)
  const error = maxShares! > treasury! ? true : false
  const details = [
    { label: 'Instrument Type', value: getInstrumentType[instrumentType!] },
    { label: 'Instrument Name', value: name },
    { label: 'Maturity Date', value: maturityDateValue! },
    { label: 'Converts To', value: security && formatSecurityName(security) },
  ]
  const amounts = [
    { label: 'Authorized Max Raise Amount', value: optionalUsdString(authorizedRaiseAmount) },
    { label: 'Minimum Valuation', value: optionalUsdString(valuationMin) },
    { label: 'Maximum Valuation', value: optionalUsdString(valuationMax) },
    { label: 'Discount Rate', value: optionalPercentage(discount) },
    { label: 'Interest Rate', value: optionalPercentage(interestRate) },
    { label: 'Accrual Frequency', value: getAccrualFrequency[accrualFrequency] },
  ]
  const shares = [
    { label: 'Actual Price Per Unit', value: optionalUsdString(pricePerUnit) },
    { label: 'Minimum Price Per Unit', value: optionalUsdString(minPricePerShare) },
    { label: 'Maximum Price Per Unit', value: optionalUsdString(maxPricePerShare) },
    { label: 'Minimum Issuance', value: optionalCommaNumber(minShares) },
  ]
  const withErrors = [
    { label: 'Maximum Issuance', value: optionalCommaNumber(maxShares) },
    { label: 'Total Units Available in Treasury', value: optionalCommaNumber(treasury) },
  ]

  return (
    <>
      <StyledDetails>
        <StyledHeader>{forEdit ? 'Edit' : 'New'} Convertible Instrument</StyledHeader>
        {details.map((detail, i: number) => renderInstrumentDetails(detail.label, detail.value, i))}
        <Divide />
        {amounts.map((amount, i: number) => renderInstrumentDetails(amount.label, amount.value, i))}
        <Divide stretch={true} />
        {shares.map((share, i: number) => renderInstrumentDetails(share.label, share.value, i))}
        {withErrors.map((share, i: number) => renderInstrumentDetails(share.label, share.value, i, error?.toString()))}
      </StyledDetails>
      {security && error &&
        <StyledError>
          <span>
            This configuration may require you to issue more shares of {formatSecurityName(security)} than are currently available in your treasury.
            Add more shares of {formatSecurityName(security)} or change your conversion details.
          </span>
        </StyledError>
      }
    </>
  )
}
