import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, planDocumentsPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadIncentiveDocuments } from '@helpers/loaders'
import { GetIncentiveDocumentsResponse } from '@src/service'
import { SlimButton } from '@shared/components/button'
import CarrotUp from '@image/carrot-up.svg'
import CarrotDown from '@image/carrot-down.svg'
import DoubleCarrotUp from '@image/double-carrot-up.svg'
import DoubleCarrotDown from '@image/double-carrot-down.svg'
import { DetailsLabel, FlexCol, FlexRow, screenSizes, semiBoldStyle, Themes } from '@src/styles'
import styled from 'styled-components'
import { Uuid } from '@shared/helpers'
import { EmptyLanding } from '@shared/components/empty-landing'
import { StyledLink } from '@modules/captable/pages'
import _ from 'lodash'

type Props = PageProps & GetIncentiveDocumentsResponse

const withData = withLoadingCachedMultiple<Props>(loadIncentiveDocuments)

const StyledButtons = styled<any>(FlexRow)`
    max-width: 394px;
    button:first-child {
        margin-right: 10px;
        max-width: 184px;
    }
    button {
        margin-bottom: 0;
    }
    margin-bottom: 40px;
    @media (max-width: ${screenSizes.S}px) {
        flex-direction: column;
        max-width: 100%;
        button:first-child {
            margin-right: 0;
            max-width: 100%;
        }
        button {
            width: 100%;
            margin-bottom: 10px
        }
    }
`

const StyledDocuments = styled<any>(FlexCol)`
    color: ${({ theme }) => theme.white};
    h5 {
        font-size: 24px;
        line-height: 32px;
        ${semiBoldStyle}
        margin: 0;
    }
    .down {
        path {
            fill: ${({ theme }) => theme.accent};
        }
    }
    border-bottom: 1px solid rgba(206, 218, 225, 0.3);
    margin-bottom: 24px;
    max-width: 633px;
    div {
        width: 100%;
    }
    svg {
        margin-right: 8px;
        cursor: pointer;
    }
    .title {
        margin-bottom: 24px;
    }
`

const StyledDoc = styled<any>(FlexCol)`
    padding: 0 32px;
    margin-bottom: 24px;
    align-items: flex-start;
    .name {
        margin-bottom: 8px;
        line-height: 22px;
    }
    .note {
        margin-top: 4px;
        color: #9CA6AD;
        line-height: 22px;
    }
`

const StyledNotes = styled<any>(FlexCol)`
    align-items: flex-start;
    div, span {
        color: ${({ theme }) => theme.selected == Themes.LIGHT ? '#262626' : '#9CA6AD'};
    }
`

export const AllPlanDocuments = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const { planDocuments, incentiveDocuments, poolDocuments, vestingDocuments, optionDocuments, params } = props
        const planDocs = planDocuments?.length > 0 ? planDocuments : []

        const defaultState: Set<string> = new Set(planDocs.length > 0 ? ['Plans'] : [])
        const [expandAll, setExpandAll] = React.useState<Set<string>>(defaultState)

        const incentiveDocs = incentiveDocuments?.length > 0 ? incentiveDocuments : []
        const poolDocs = poolDocuments?.length > 0 ? poolDocuments : []
        const vestingDocs = vestingDocuments?.length > 0 ? vestingDocuments : []
        const optionDocs = optionDocuments?.length > 0 ? optionDocuments : []
        const empty = (
            planDocs.length == 0 &&
            incentiveDocs.length == 0 &&
            poolDocs.length == 0 &&
            vestingDocs.length == 0 &&
            optionDocs.length == 0
        )

        const renderExpandAll = () => {
            return <StyledButtons>
                <SlimButton
                    onClick={() => {
                        if (!expandAll.has('all')) {
                            const all = ['Plans', 'Incentives', 'Pools', 'Vesting Schedules', 'Holdings']
                            setExpandAll(new Set(all))
                        }
                        else { }
                    }}
                    disabled={expandAll.has('all')}
                >
                    <DoubleCarrotDown />
                    Expand All
                </SlimButton>
                <SlimButton onClick={() => setExpandAll(new Set())}>
                    <DoubleCarrotUp />
                    Collapse All
                </SlimButton>
            </StyledButtons >
        }

        const openDocument = (doc?: Uuid, name?: string) => {
            if (doc && name) {
                const n = encodeURIComponent(name)
                window.open(`/api/v1/file/${doc}/content/${n}`)
            }
        }

        const renderDocuments = (title: string, docs: any) => {
            return <StyledDocuments>
                <FlexRow className='title'>
                    {expandAll.has(title) && docs.length > 0 ?
                        <CarrotDown
                            className='down'
                            onClick={() => {
                                if (docs.length > 0) {
                                    expandAll.delete(title)
                                    setExpandAll(new Set(expandAll))
                                } else { }
                            }}
                        />
                        :
                        <CarrotUp onClick={() => docs.length > 0 ? setExpandAll(new Set(expandAll.add(title))) : {}} />
                    }
                    <h5>{title}</h5>
                </FlexRow>
                {expandAll.has(title) ? docs.map((d: any) => {
                    return <StyledDoc>
                        <span className='name'>{d.documentTypeName || d.document?.filename!}</span>
                        <StyledLink onClick={() => openDocument(d.document?.hash!, d.document?.filename!)}>
                            {d.document?.filename!}
                        </StyledLink>
                        {d.note ? <StyledNotes>
                            <DetailsLabel>Notes:</DetailsLabel>
                            <span style={{ wordBreak: 'break-all' }} className='note'>{d.note!}</span>
                        </StyledNotes>
                            : null}
                    </StyledDoc>
                })
                    : null
                }
            </StyledDocuments>
        }

        return (
            <PageContent>
                {!empty ? <>
                    <PageContentHeader title='Documents & Notes' hideCrumbs={true} />
                    {renderExpandAll()}
                    {renderDocuments('Plans', planDocs)}
                    {renderDocuments('Incentives', incentiveDocs)}
                    {renderDocuments('Pools', poolDocs)}
                    {renderDocuments('Vesting Schedules', vestingDocs)}
                    {renderDocuments('Holdings', optionDocs)}
                </>
                    :
                    <EmptyLanding path={planDocumentsPath} params={params} />
                }
            </PageContent>
        )
    })
)
