import { AnyAction, Reducer } from 'redux'
import { ActionType } from '../actions'

export const accessReducer: Reducer<boolean, AnyAction> = (state: any, action) => {
  switch (action.type) {
    case ActionType.LOGIN_SUCCESS:
      return { ...state, needsTwoFactor: action.data.needsTwoFactor }
  }
  return state
}
