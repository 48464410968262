import { OrganizationAction } from './types'
import { captablePath, newShareholdingPath, Paths } from '@logic'
import { actionIds } from './definition'
import TransferShares from '@image/transfer-shares.svg'
import Draft from '@image/draft.svg'
import CapitalCall from '@image/capital-call.svg'
import BoardMeeting from '@image/board-meeting.svg'
import UpdateGoverningDocuments from '@image/update-governing-documents.svg'
import SignAgreement from '@image/sign-agreement.svg'
import Collaborate from '@image/collaborate.svg'
import NewDataRoom from '@image/new-data-room.svg'
import NewOrganization from '@image/new-organization.svg'
import IncorporateNewOrganization from '@image/import-existing-organization.svg'
import UpgradeTransferAgent from '@image/upgrade-transfer-agent.svg'
import ViewTransferShares from '@image/view-transfer-shares.svg'
import ViewDraft from '@image/view-draft.svg'
import ViewCapitalCall from '@image/view-capital-call.svg'
import ViewBoardMeeting from '@image/view-board-meeting.svg'
import ViewGoverningDocuments from '@image/view-governing-documents.svg'
import ViewSignAgreement from '@image/view-sign-agreement.svg'
import ViewCollaborate from '@image/view-collaborate.svg'
import ViewNewDataRoom from '@image/view-new-data-room.svg'
import ViewNewOrganization from '@image/view-new-organization.svg'
import ViewIncorporateOrganization from '@image/view-incorporate-organization.svg'
import ViewTransferAgent from '@image/view-transfer-agent.svg'

export const organizationActions: OrganizationAction[] = [
  {
    title: 'Transfer',
    id: actionIds.transfer,
    icon: TransferShares,
    description1: 'Your holder may have the right to sell or transfer their shares.',
    description2: 'These transactions impact your cap table and should be represented in real-time so that you can maintain accurate records of your company’s ownership.',
    description3: 'Use Equa’s transfer tool to log this activity. ',
    diagram: ViewTransferShares,
    destination: captablePath,
  },
  {
    title: 'Draft',
    id: actionIds.draft,
    icon: Draft,
    description1: 'Whether to a founder, employee, investor or advisor, keep your cap table up to date as shares are drafted and issued.',
    description2: 'When shares are allocated, the holder will have access to their digitized stock certificates and other relevant information in their personal dashboard.',
    description3: 'Furthermore, your company has a real-time view of its ownership records.',
    diagram: ViewDraft,
    destination: newShareholdingPath,
  },
  {
    title: 'Capital Call',
    id: actionIds.capitalCall,
    icon: CapitalCall,
    description1: 'A capital call is the act of collecting funds from individuals whenever the need arises.',
    description2: 'Time is usually of the essence.',
    description3: 'Use Equa to facilitate the communication and execution of this key activity, reducing the period between initiation and close.',
    diagram: ViewCapitalCall,
  },
  {
    title: 'Board Meeting',
    id: actionIds.boardMeeting,
    icon: BoardMeeting,
    description1: 'A healthy organization maintains pristine records of all board meetings, votes, and other activities that are essential to growing your company.',
    description2: 'Use Equa to communicate, store, and keep up to date all board related documents and actions.',
    description3: '',
    diagram: ViewBoardMeeting,
  },
  {
    title: 'Updating Agreements',
    id: actionIds.updateGoverningDocuments,
    icon: UpdateGoverningDocuments,
    description1: 'Your company’s agreements can be easily updated over time through board approved amendments.',
    description2: 'Whether updating your Company Bylaws, your Operating Agreement, or any additional key information or operational articles, accelerate these changes using Equa.',
    description3: 'Loop in all relevant parties to collect feedback and organize required voting processes.',
    diagram: ViewGoverningDocuments,
  },
  {
    title: 'Sign an Agreement',
    id: actionIds.signAnAgreement,
    icon: SignAgreement,
    description1: 'A company is a set of agreements: between founders, between the company and employees, or between partners, advisors, investors, etc.',
    description2: 'Equa’s signing functionality allows you to collaborate on and execute documents effortlessly.',
    description3: 'Once a document has been signed, select where to store it in your data room so that all relevant parties have access.',
    diagram: ViewSignAgreement,
  },
  {
    title: 'Collaborate',
    id: actionIds.collaborate,
    icon: Collaborate,
    description1: 'Stakeholder communication and collaboration is key to any company’s success.',
    description2: 'Whether working on contract language, providing updates to investors or holders, or soliciting advice from your legal or accounting representatives, Equa streamlines the interaction to execution process.',
    description3: '',
    diagram: ViewCollaborate,
  },
  {
    title: 'Create a New Data Room',
    id: actionIds.newDataRoom,
    icon: NewDataRoom,
    description1: 'Facilitate the sharing of documentation and set appropriate permission levels to those stakeholders who need access.',
    description2: 'Accelerate investor due diligence processes, provide secure access to relevant holder documents, or simply be proactive in managing and centralizing key information so that it can be easily referenced as your company grows.',
    description3: '',
    diagram: ViewNewDataRoom,
    destination: Paths.organizationDataRoom,
  },
  {
    title: 'Create New Organization',
    id: actionIds.newOrganization,
    icon: NewOrganization,
    description1: 'Create a new organization following necessary entity formation procedures, and gain access in Equa to a data room, a cap table, and other features that allow you build a healthy company from the start.',
    description2: '',
    description3: '',
    diagram: ViewNewOrganization,
    destination: Paths.newOrganization,
  },
  {
    title: 'Incorporate New Organization',
    id: actionIds.incorporateNewOrganization,
    icon: IncorporateNewOrganization,
    description1: 'Equa is a safe, powerful, and easy-to-use platform for incorporating a company.',
    description2: 'By removing legal complexity, tedious paperwork, and costly fees, Equa helps you legally incorporate your organization from anywhere in the world.',
    description3: '',
    diagram: ViewIncorporateOrganization,
  },
  {
    title: 'Upgrade your Transfer Agent',
    id: actionIds.upgradeTransferAgent,
    icon: UpgradeTransferAgent,
    description1: 'Did you know...',
    description2: 'Equa Transfer is an SEC Registered Transfer Agency who can help facilitate compliant transactions between your company and holders including issuing, transferring, or cancelling shares? ',
    description3: 'Reach out to learn more about how this can help your company.',
    diagram: ViewTransferAgent,
    destination: 'http://equatransfer.com',
  },
]
