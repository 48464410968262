import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, vestingsPath, viewVestingPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import {
    FilesResponse,
    FormVestingSchedule,
    postFile,
    GetVestingResponse,
    GetAuthorizationsResponse,
    editVesting
} from '@src/service'
import { BaseLinkProps } from '@shared/components/navigation'
import { VestingScheduleForm } from '../components/vesting-schedule-form'
import { getQueryParams, loadAuthorizations, loadVesting, nextInternalId, normalizeDate } from '@shared/helpers'
import { HorizontalVestingChart } from '../components/vesting-chart'
import { eventsWithData } from '../utility'
import { QuestionSpan, NoteSpan } from '../components/plan-form'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & GetVestingResponse & GetAuthorizationsResponse

const withData = withLoadingCachedMultiple<Props>(loadVesting, loadAuthorizations('vesting'))

export const EditVestingSchedule = withData(
    withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
        const { httpClient, navigate, params, authorizations, vestingSchedule } = props
        const { organization, plan } = params
        const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a =>
            a.authorizationDate || a.documentTypeName || a.document || a.note) : []
        const documents = documentsAndNotes?.map((auth, index: number) => {
            return {
                documentTypeName: auth.documentTypeName!,
                approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
                approvalDate: auth.authorizationDate!,
                note: auth.note!,
                hash: auth.hash!
            }
        })

        const isDiscreet = vestingSchedule ? Object.keys(vestingSchedule)?.includes('events') : false
        const events = isDiscreet ?
            vestingSchedule.isValueAbsolute ?
                vestingSchedule?.events?.map(event => {
                    const date = new Date(`${event.month}/${event.day}/${event.year}`)
                    return { ...event, date, key: nextInternalId() }
                })?.sort((a, b) => a.date && b.date && a.date.getTime() - b.date.getTime())
                :
                vestingSchedule?.events?.map(event => ({ ...event, rValue: event.value * 100, key: nextInternalId() }))
                    ?.sort((a, b) => a.month - b.month)
            : []

        const initialValues: any = {
            vestingSchedule: vestingSchedule ? vestingSchedule : {} as any,
            cliff: vestingSchedule?.cliffMonths ? true : false,
            amount: vestingSchedule?.cliffAmount ? true : false,
            vestingOptions: {
                procedural: !isDiscreet ? true : false,
                absolute: isDiscreet ? vestingSchedule?.isValueAbsolute ? true : false : false,
                relative: isDiscreet ? !vestingSchedule?.isValueAbsolute ? true : false : false
            },
            events,
            name: vestingSchedule?.name!,
            approvalDocument: documents,
            startDate: vestingSchedule?.startDate || undefined,
        }

        const onUpload: UploadHandler = async files => {
            const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
            const file = response.files[0]
            return {
                hash: file.hash,
                name: file.filename,
                id: file.id,
            }
        }

        const onSubmit = async (values: FormVestingSchedule) => {
            const documents = values?.approvalDocument?.filter((d: any) =>
                (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
            const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
                const newDocument = { ...document }
                delete newDocument.key
                return newDocument
            }) : []

            const isProcedural = values?.vestingOptions.procedural
            const events = !isProcedural ?
                values?.events?.filter(f => !f.isRemoved).map(event => {
                    delete event.key
                    if (values?.vestingOptions.absolute) {
                        const date = event.date
                        const month = date?.getMonth() + 1
                        const day = date?.getDate()
                        const year = date.getFullYear()
                        return {
                            month: parseInt(month),
                            day: parseInt(day),
                            year: parseInt(year),
                            value: event.value,
                        }
                    } else {
                        const value = event.rValue / 100
                        delete event.rValue
                        return { ...event, value }
                    }
                }) : {}

            const cliffMonths = isProcedural && values?.cliff ? {
                cliffMonths: values?.vestingSchedule?.cliffMonths
            } : undefined

            const cliffAmountAndType = isProcedural && values?.cliff && values?.amount ?
                {
                    cliffAmount: parseFloat(values!.vestingSchedule!.cliffAmount!.toString()),
                    cliffAmountType: values?.vestingSchedule?.cliffAmountType
                }
                : undefined


            const vesting = isProcedural ?
                {
                    durationMonths: values?.vestingSchedule?.durationMonths,
                    frequencyMonths: values?.vestingSchedule?.frequencyMonths,
                    vestsOn: values?.vestingSchedule?.vestsOn,
                    ...cliffMonths,
                    ...cliffAmountAndType,
                    name: values?.name,
                    startDate: values?.startDate!,
                }
                :
                {
                    events,
                    isValueAbsolute: values?.vestingOptions.absolute,
                    name: values?.name,
                }

            const request = {
                vestingSchedule: vesting,
                plan,
            } as any
            const response: any = await editVesting(httpClient)({ vestingScheduleOld: vestingSchedule.hash }, request)

            if (response?.hash) {
                modifyAuthorizations({ approvalDocuments, target: response.hash, httpClient, organization })
                const parent = getQueryParams().parent
                if (parent) {
                    navigate(viewVestingPath, { organization, plan, vesting: response.hash })
                } else {
                    navigate(vestingsPath, { organization, plan })
                }
            }
            else console.log('edit vesting schedule error') // TODO: add error message toast
        }

        const parent: BaseLinkProps = { path: vestingsPath, args: { organization, plan } }

        const VestingChartWrapper: Sticky<any> = props => {
            const { values } = props
            const schedule = eventsWithData(values)
            const custom = Object.keys(schedule).includes('events')
            const events = custom ?
                schedule.isValueAbsolute ?
                    schedule?.events?.map(event => {
                        const date = new Date(`${event.month}/${event.day}/${event.year}`)
                        return { ...event, date }
                    })?.sort((a, b) => a.date && b.date && a.date.getTime() - b.date.getTime())
                    :
                    schedule?.events?.map(event => ({ ...event, rValue: event.value * 100 }))
                        ?.sort((a, b) => a.month - b.month)
                : []
            const isAbsolute = custom ? schedule.isValueAbsolute : false
            return <>
                <PageContentHeader title={`Edit ${vestingSchedule?.name}`} parent={parent} />
                <QuestionSpan>What is a Vesting Schedule?</QuestionSpan>
                <NoteSpan>Vesting Schedules determine the schedule of when holders acquire full ownership of the asset.
                    Create all of your different vesting schedules here, then you’ll be able to assign them to your incentives later on.
                </NoteSpan>
                {schedule ? <HorizontalVestingChart
                    vestingSchedule={custom ? { ...schedule, events } : schedule}
                    value={values?.startingValue}
                    custom={custom}
                    startDate={values?.startDate && !isAbsolute ? normalizeDate(values.startDate) : undefined}
                    dontNotify={true}
                    noMarginBottom={true}
                /> : <div />}
            </>
        }

        return (
            <PageContent>
                <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                    submitText="Save" sticky={VestingChartWrapper} stickyOnTop={true}>
                    <VestingScheduleForm onUpload={onUpload} />
                </CommonForm>
            </PageContent>
        )
    })
)
