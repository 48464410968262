import * as React from 'react'
import styled from 'styled-components'
import { FlexCol, FlexRow, fontSizes } from '@src/styles'

export interface BlockInfo {
  value: number,
  selected: boolean,
  color?: string,
  name?: string,
  abb: string,
}

interface Props {
  colors?: string[]
  blocks: BlockInfo[]
  handleSelection?: any
  PopUp?: React.ReactElement
  customMargin?: string
}

const BarWrapper = styled.div<any>`
  display: flex;
  max-width: 1280px;
  margin: ${props => props.customMargin ? props.customMargin : '50px 0px 20px'};
  height: 20px;
  @keyframes expand {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  .blocks {
    width: 100%;
    animation: expand 1.5s forwards;
    display: flex;
  }
`

const Block = styled.div<any>`
  background-color: ${(props: any) => props.color && props.color};
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  cursor: pointer;
  width: ${(props: any) => props.width && props.width};
  transform: ${(props: any) => (props.selected ? 'scaleY(1.2)' : 'scaleY(1)')};
  margin-right: 2px;
  position: relative;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-right: 0;
  }
`

const Pop = styled.div`
position: absolute;
width 195px;
height: 131px;
background: ${props => props.theme.action};
.triangle-down {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-top: 50px solid #555;
}
`

export const RainbowBar = (props: Props) => {
  const { colors, blocks, handleSelection, customMargin } = props
  const total = blocks?.reduce((a, b) => a + b.value, 0)
  const mappedBlocks = blocks?.map(block => ({ ...block, value: block.value * 100 / total }))

  return (
    <BarWrapper customMargin={customMargin}>
      <div className="blocks">
        {mappedBlocks?.map((el, i: number) => {
          return el.value > 0 && <Block
            key={i}
            color={el.color ? el.color : colors![i % colors!.length]}
            width={`${el.value}%`}
            selected={el.selected}
            onClick={() => handleSelection(i)}
          />
        })}
      </div>
    </BarWrapper>
  )
}