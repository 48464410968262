import * as React from 'react'
import { Field } from 'react-final-form'
import { RadioContainer, CheckMark, InfoBox, InfoIcon, RadioCheckMark } from './style'
import styled, { CSSObject } from 'styled-components'
import { ReactElement } from 'react'
import { CommonInput } from '@src/styles'
import SelectCheckbox from '@image/select-check.png'
import { requiredOrOptional } from '../forms'

interface BoxProps {
  name?: string
  id?: string
  text?: string | ReactElement
  type: string
  styleType?: string
  handleClick?: (e: any) => void
  defaultChecked?: boolean
  checked?: boolean
  info?: string
  onChange?: any
  input?: any
  validate?: any
}

interface Props extends BoxProps {
  name: string
  type: string
  inverted?: boolean
  noLabel?: boolean
  disabled?: boolean
}

const StyledMulti = styled.span<any>`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.noLabel === 'true' && 'center'};
  margin: ${props => props.noLabel === 'false' ? '10px 0' : '0'} !important;
  > div:first-child {
    font-size: 0.9em;
  }
  > div {
    color: ${props => props.theme.white};
  }
`

export const MultiBox = (props: Props) => {
  const { name, text, handleClick, type, checked, id, info, input, onChange, noLabel, disabled, styleType } = props
  const onChangeWrapper = onChange
    ? function () {
      onChange?.apply(undefined, arguments)
      input?.onChange?.apply(undefined, arguments)
    }
    : input.onChange

  return (
    <StyledMulti className="multi" noLabel={noLabel ? 'true' : 'false'}>
      <RadioContainer noLabel={noLabel ? 'true' : 'false'} disabled={disabled}>
        {text && <div>{text}</div>} {!!info && <InfoIcon>&#9432;</InfoIcon>}
        <CommonInput id={id} type={type} name={name} onClick={handleClick} checked={checked} disabled={disabled} {...input} onChange={onChangeWrapper} />
        {info && (
          <InfoBox>
            <span>&#9432;</span>
            {info}
          </InfoBox>
        )}
        {styleType === 'radio' ?
          <RadioCheckMark
            type={styleType}
            inverted={props.inverted}
            noLabel={noLabel ? 'true' : 'false'}
            checked={(input?.checked || checked) ? 'true' : 'false'}>
            <span />
          </RadioCheckMark>
          :
          input?.checked || checked ?
            <img src={SelectCheckbox} alt="check" />
            :
            <CheckMark type={styleType} inverted={props.inverted} noLabel={noLabel ? 'true' : 'false'} />}
      </RadioContainer>
    </StyledMulti>
  )
}

export const MultiBoxField = (props: Props) => {
  const { name, type, validate } = props
  return (
    <>
      <Field
        name={name}
        type={type}
        validate={validate}
        render={({ input, meta }) => <MultiBox {...props} input={input} />}
      />
    </>
  )
}
