export function validateCardNumber(num: string) {
  const regex = new RegExp('^[0-9]{15,16}$');
  if (!regex.test(num)) return false;

  return luhnCheck(num);
}

function luhnCheck(val: string) {
  let nCheck = 0;
  let bEven = false;
  val = val.replace(/\D/g, '');

  for (let n = val.length - 1; n >= 0; n--) {
    const cDigit = val.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 == 0;
}
