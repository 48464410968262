import {
  organizationPath,
  captablePath,
  billingPath,
  selectYourPlanPath,
  organizationDetailsPath,
  organizationSettingsPath,
  Paths,
  actionsPath,
  organizationSecuritiesPath,
  organizationLegendsPath,
  plansPath,
  checkoutPath,
  newPaymentProfilePath,
  organizationDetailsEditPath,
  organizationNewSecurityPath,
  organizationCreateLegendPath,
  organizationEditSecurityPath,
  organizationEditLegendPath,
  requestAFeaturePath,
  captableCertificatePath,
  certificateDetailsPath,
  documentsAndNotesPath,
  certificateLegendPath,
  editShareholdingPath,
  profilePath,
  portfolioPath,
  referralsPath,
  themesPath,
  convertibleInstrumentsPath,
  viewConvertibleInstrumentPath,
  newConvertibleInstrumentPath,
  editConvertibleInstrumentPath,
  viewConvertibleNotePath,
  newConvertibleNotePath,
  editConvertibleNotePath,
  conversionPath,
  capitalizationPath,
  newShareholdingPath,
  transferShareholdingPath,
  organizationMembersPath,
  newMemberPath,
  editMemberPath,
  editProfilePath,
  newHoldingPath,
  editHoldingPath,
  accountPath,
  addCardPath,
  editCardPath,
  viewCardPath,
  dashboardPath,
  viewRolePath,
  rolesPath,
  editRolePath,
  createRolePath,
  permissionsPath,
  editPermissionsPath,
  organizationCashTransferPath,
  equaCashTransferPath,
  planPath,
  newPlanPath,
  editPlanPath,
  planIncentivesPath,
  newPlanIncentivePath,
  planIncentivePath,
  editPlanIncentivePath,
  poolsPath,
  newPoolPath,
  poolPath,
  editPoolPath,
  vestingsPath,
  newVestingPath,
  viewVestingPath,
  editVestingPath,
  reportsPath,
  guestPortfolioPath
} from '@logic'
import { loadHolding, loadOrganization, loadPlan, loadProfile, loadSecurity, loadVesting } from './loaders'

export interface PathTitleBundle {
  path: string
  crumb?: (props?: any) => string
  loaders?: any
  target?: string
  title?: (props?: any) => string
  disabled?: boolean
}

type PathTitlesType = PathTitleBundle[]
const repeatingItems = [
  {
    path: profilePath,
    crumb: (props: any) => `${props?.profile?.fullName!}`,
    title: () => 'Profile',
    loaders: loadProfile(),
    target: dashboardPath
  },
  {
    path: accountPath,
    crumb: () => '/ Account',
    title: () => 'Account',
  },
]

export const PageBreadCrumbs = [
  {
    path: viewCardPath,
    items: repeatingItems
  },
  {
    path: addCardPath,
    items: repeatingItems,
  },
  {
    path: editCardPath,
    items: repeatingItems,
  },
]

const ProfilePathTitles: PathTitlesType = [
  {
    path: portfolioPath,
    crumb: () => 'Portfolio / ',
    title: () => 'Portfolio',
  },
  {
    path: guestPortfolioPath,
    crumb: () => 'Portfolio / ',
    title: () => 'Portfolio',
  },
  {
    path: profilePath,
    title: () => 'Profile',
  },
  {
    path: editProfilePath,
    title: () => 'Edit',
  },
  {
    path: themesPath,
    title: () => 'Themes',
  },
  {
    path: newHoldingPath,
    crumb: () => 'New / ',
    title: () => 'New',
  },
  {
    path: editHoldingPath,
    crumb: () => 'Edit / ',
    title: () => 'Edit',
  },
  {
    path: accountPath,
    title: () => 'Account',
  },
  {
    path: viewCardPath,
    title: () => 'View',
  },
  {
    path: addCardPath,
    title: () => 'Add',
  },
  {
    path: editCardPath,
    title: () => 'Edit',
  },
]

export const PathTitles: PathTitlesType = [
  ...ProfilePathTitles,
  {
    path: Paths.organizationList,
    title: () => 'My Organizations',
  },
  {
    path: organizationPath,
    crumb: (props: any) => `${props.organization.name}`,
    loaders: loadOrganization,
    title: () => 'Organization',
  },
  {
    path: organizationMembersPath,
    crumb: () => '/ Team Members',
    title: () => 'Team Members',
  },
  {
    path: newMemberPath,
    crumb: () => '/ New Member',
    title: () => 'New Member',
  },
  {
    path: editMemberPath,
    crumb: () => '/ Edit Member',
    title: () => 'Edit Member',
  },
  {
    path: capitalizationPath,
    crumb: () => '/ Capitalization',
    target: captablePath,
    title: () => 'Cap Table',
  },
  {
    path: captablePath,
    crumb: () => ' / Cap Table',
    title: () => 'Cap Table',
  },
  {
    path: newShareholdingPath,
    crumb: () => '/ Draft',
    title: () => 'Draft',
  },
  {
    path: Paths.editShareholding,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: transferShareholdingPath,
    crumb: () => '/ Transfer',
    title: () => 'Transfer',
  },
  {
    path: plansPath,
    crumb: () => '/ Incentive Plans',
    title: () => 'Incentive Plans',
  },
  {
    path: newPlanPath,
    crumb: () => '/ Create New',
    title: () => 'Create New',
  },
  {
    path: planPath,
    crumb: (props: any) => `/ ${props.plan?.name!}`,
    title: () => 'Incentive Plans',
    loaders: loadPlan,
  },
  {
    path: editPlanPath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: planIncentivesPath,
    crumb: () => '/ Incentives',
    title: () => 'Incentives',
  },
  {
    path: newPlanIncentivePath,
    crumb: () => '/ Create New',
    title: () => 'Create New',
  },
  {
    path: planIncentivePath,
    crumb: (props: any) => `/ ${props.security?.name!}`,
    title: () => 'Incentive Plans',
    loaders: loadSecurity('incentive'),
  },
  {
    path: editPlanIncentivePath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: poolsPath,
    crumb: () => '/ Pools',
    title: () => 'Pools',
  },
  {
    path: newPoolPath,
    crumb: () => '/ Create New',
    title: () => 'Create New',
  },
  {
    path: poolPath,
    crumb: (props: any) => `/ ${props.holding?.name!}`,
    title: () => 'Incentive Plans',
    loaders: loadHolding,
  },
  {
    path: editPoolPath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: vestingsPath,
    crumb: () => '/ Vesting Schedules',
    title: () => 'Vesting Schedules',
  },
  {
    path: newVestingPath,
    crumb: () => '/ Create New',
    title: () => 'Create New',
  },
  {
    path: viewVestingPath,
    crumb: (props: any) => `/ ${props.vestingSchedule?.name!}`,
    title: () => 'Vesting Schedule',
    loaders: loadVesting,
  },
  {
    path: editVestingPath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: Paths.organizationDataRoom,
    crumb: () => '/ Data Room',
    title: () => 'Data Room',
  },
  {
    path: actionsPath,
    crumb: () => '/ Actions',
    title: () => 'Actions',
  },
  {
    path: requestAFeaturePath,
    crumb: () => '/ Request A Feature',
    title: () => 'Request A Feature',
  },
  {
    path: organizationSettingsPath,
    crumb: () => '/ Settings',
    target: organizationDetailsPath,
    title: () => 'More | Org Details',
  },
  {
    path: organizationDetailsEditPath,
    crumb: () => '/ Edit',
    target: organizationDetailsPath,
    title: () => 'Edit Org Details',
  },
  {
    path: organizationSecuritiesPath,
    crumb: () => '/ Equities',
    title: () => 'Equities',
  },
  {
    path: organizationNewSecurityPath,
    crumb: () => '/ New',
    title: () => 'New Equity',
  },
  {
    path: organizationEditSecurityPath,
    crumb: () => '/ Edit',
    title: () => 'Edit Equity',
  },
  {
    path: organizationLegendsPath,
    crumb: () => '/ Legends',
    title: () => 'More | Legends',
  },
  {
    path: organizationCreateLegendPath,
    crumb: () => '/ New',
    title: () => 'Create Legend',
  },
  {
    path: organizationEditLegendPath,
    crumb: () => '/ Edit',
    title: () => 'Edit Legend',
  },
  {
    path: billingPath,
    crumb: () => '/ Billing',
    title: () => 'More | Billing',
  },
  {
    path: newPaymentProfilePath,
    crumb: () => '/ New Payment Method',
    title: () => 'New Payment Method',
  },
  {
    path: selectYourPlanPath,
    crumb: () => '/ Upgrade',
    title: () => 'Upgrade',
  },
  {
    path: checkoutPath,
    crumb: () => '/ Check Out',
    title: () => 'Check Out',
  },
  {
    path: captableCertificatePath,
    crumb: (props: any) => `/ ${props.holding?.name!}`,
    loaders: loadHolding,
    title: () => `Certificate`,
  },
  {
    path: certificateDetailsPath,
    crumb: () => '/ Details',
    title: () => 'Certificate Details',
  },
  {
    path: certificateLegendPath,
    crumb: () => '/ Legend',
    title: () => 'Certificate Legend',
  },
  {
    path: documentsAndNotesPath,
    crumb: () => '/ Documents & Notes',
    title: () => 'Docs & Notes',
  },
  {
    path: editShareholdingPath,
    crumb: () => '/ Edit',
    title: () => 'Certificate Edit',
  },
  {
    path: convertibleInstrumentsPath,
    crumb: () => '/ Convertible Instruments',
    title: () => 'Convertible Instruments',
  },
  {
    path: newConvertibleInstrumentPath,
    crumb: () => '/ New Instrument',
    title: () => 'New',
  },
  {
    path: viewConvertibleInstrumentPath,
    crumb: (props: any) => `/ ${props.holding.name}`,
    loaders: loadHolding,
    title: () => 'Instrument',
  },
  {
    path: editConvertibleInstrumentPath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  // {
  //   path: viewConvertibleNotePath,
  //   crumb: () => '/ Edit',
  //   loaders: {},
  //   title: () => 'Edit',
  // },
  {
    path: newConvertibleNotePath,
    crumb: () => '/ New',
    title: () => 'New',
  },
  {
    path: editConvertibleNotePath,
    crumb: () => '/ Edit',
    title: () => 'Edit',
  },
  {
    path: conversionPath,
    crumb: () => '/ Conversion',
    title: () => 'Conversion',
  },
  {
    path: rolesPath,
    crumb: () => '/ Roles & Permissions',
    title: () => 'Roles & Permissions',
  },
  {
    path: viewRolePath || createRolePath || editRolePath,
    crumb: () => '/ Roles',
    title: () => 'Roles',
    target: rolesPath,
  },
  {
    path: editPermissionsPath,
    crumb: () => '/ Permissions Matrix',
    title: () => 'Permissions Matrix',
  },
  {
    path: organizationCashTransferPath,
    crumb: () => '/ E$ Transfer',
    title: () => 'E$ Transfer',
  },

  {
    path: reportsPath,
    crumb: () => '/ Reports',
    title: () => 'Holder',
  }
]
