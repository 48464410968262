import { Uuid } from '@helpers/util'
import { HttpClient, membersAccessDeniedResponse, newGetFunction } from '@src/service'
import { accessDeniedWithFallback } from '@src/service/lib/utility'
import { EntityProps, GetOrganizationsResponse, GetOrgReferralDataResponse, GetReferralDataResponse, MembersProps, OrganizationUrlProps } from '@logic'
import {
  addressesUrl,
  AddressUrlProps,
  addressUrl,
  fileContentUrl,
  FileContentUrlProps,
  organizationStatsUrl,
  organizationUrl,
  taskUrl,
  userStateUrl,
  emailsUrl,
  orgReferralUrl
} from './urls'
import {
  FileContentResponse,
  GetAddressesResponse,
  GetAddressResponse,
  GetEmailsResponse,
  GetOrganizationResponse,
  GetOrganizationStatsResponse
} from './responses'
import { GetTasksResponse } from '@shared/helpers'
import { PermissionProps } from '@shared/components/permissions'

export const getMembers = (httpClient: HttpClient) => async (organization: Uuid): Promise<MembersProps> => {
  return accessDeniedWithFallback(membersAccessDeniedResponse)(await httpClient.get(`organization/${organization}/member`))
}

export const getFileContent = newGetFunction<FileContentUrlProps, FileContentResponse>(fileContentUrl)
export const getOrganizations = newGetFunction<{}, GetOrganizationsResponse>('organization')
export const getOrganizationPermissions =
  newGetFunction<OrganizationUrlProps & { user: Uuid }, PermissionProps>(`organization/:organization/user/:user`)
export const getOrganization = newGetFunction<OrganizationUrlProps, GetOrganizationResponse>(organizationUrl)
export const getOrganizationStats = newGetFunction<OrganizationUrlProps, GetOrganizationStatsResponse>(organizationStatsUrl)
export const getTasks = newGetFunction<{ entity: Uuid }, GetTasksResponse>(taskUrl)
export const getUserState = newGetFunction<{}, GetTasksResponse>(userStateUrl)
export const getAddresses = newGetFunction<EntityProps, GetAddressesResponse>(addressesUrl)
export const getAddress = newGetFunction<AddressUrlProps, GetAddressResponse>(addressUrl)
export const getEmails = newGetFunction<EntityProps, GetEmailsResponse>(emailsUrl)
export const getOrgRewardsData = (user?: Uuid) => newGetFunction<{}, GetOrgReferralDataResponse>(`${orgReferralUrl}?user=${user}`)
