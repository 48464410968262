import * as React from 'react'
import { requestAFeaturePath } from '@logic'
import { Panel, PanelGrid } from '@components/panels/panels'
import { PathLink } from '@components/navigation/path-link'
import { FlexCol } from '@src/styles'
import styled from 'styled-components'
import { OrganizationAction } from '../types'
import Plus from '@image/plus.svg'
import { ActionCard } from './action-card'

const ActionsFlexRow = styled<any>(FlexCol)`
  justify-content: space-between;
  min-height: 119px;
  color: ${props => props.theme.white};
  :first-child {
    padding: 20px 0 0 0;
  }
`

const PanelRequest = styled<any>(Panel)`
  cursor: pointer;
  margin: 0;
  border: 3px dashed rgba(255, 255, 255, 0.4);
`

interface ActionProps {
  actions: OrganizationAction[]
  organization: string
}

type Props = ActionProps

export const ActionList = (props: Props) => {
  const { actions, organization } = props

  return (
    <PanelGrid columns={4}>
      {actions && actions.map((el: any, index: number) => {
        return <ActionCard action={el} key={index} organization={organization} />
      })}
      <PathLink path={requestAFeaturePath} args={{ organization }}>
        <PanelRequest>
          <ActionsFlexRow>
            <Plus />
            <span>Request a feature</span>
          </ActionsFlexRow>
        </PanelRequest>
      </PathLink>
    </PanelGrid>
  )
}
