import * as React from 'react'
import { Member, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAuthorizations, loadHolding, loadOrganizationHoldings, loadRoles } from '@helpers/loaders'
import { DetailsLabel, DetailsMaxWidth, FlexRow, LightDivider, whiteColor } from '@src/styles'
import { legacyRoles, renderHeader } from '@components/forms'
import styled from 'styled-components'
import {
    ExpandedHoldingResponse,
    ExpandedHoldingsResponse,
    ExpandedSecurity,
    GetAuthorizationsResponse,
    getMember,
    GetRolesResponse,
    getSecurity
} from '@src/service'
import { formatExtendedDateString, formatSecurityName, roundFloat, safeDivide } from '@shared/helpers'
import { ViewOption } from '@modules/esop/components/view-option'
import { HoldingRecordType } from '@shared/helpers/holdings'

type Props = PageProps & GetAuthorizationsResponse & ExpandedHoldingResponse & ExpandedHoldingsResponse & GetRolesResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadAuthorizations('shareholding'), loadOrganizationHoldings('all'), loadRoles)

const ViewCertificateWrapper = styled.div`
  ${whiteColor}
  ${DetailsMaxWidth}
`

const renderInfo = (label: string, value: any, index: number) => {
    return (
        <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
            <DetailsLabel>{label}</DetailsLabel>
            <span>
                {value}
            </span>
        </FlexRow>
    )
}

export const ViewCertificateDetailsPage = withData(
    (props: Props) => {
        const [member, setMember] = React.useState<Member | undefined>()
        const [securityType, setSecurityType] = React.useState<ExpandedSecurity | undefined>()
        const { params, httpClient, authorizations, holding, holdings, roles } = props
        const { organization } = params

        const isShareholding = holding?.securityDetails && !holding?.convertibleInstrument && !holding?.plan
        const holdingSecurityType = !isShareholding ? holdings?.find(el => el.id === holding?.parent)?.parent : ''
        const getMemberAndSecurity = async () => {
            const member = await getMember(httpClient)({ organization, member: holding.owner! })
            if (member?.member) setMember(member!.member)
            const security = isShareholding ? holding.securityDetails : holdingSecurityType
            const securityType = await getSecurity(httpClient)({
                organization,
                security
            })
            if (securityType?.security) setSecurityType(securityType.security)
        }
        const selectableRoles = roles!!

        React.useEffect(() => {
            if (holding) getMemberAndSecurity()
        }, [holding])

        const ownerDetails = [
            { label: 'Name', value: member?.fullName },
            { label: 'Email Address', value: member?.email },
            {
                label: 'Role(s)',
                value: member?.roles?.map((el: any) => {
                    const role = legacyRoles?.find(({ value }) => value === el) || selectableRoles?.find((role) => role?.id! === el)
                    return role?.label || role?.name
                }).join(', ')
            },
            { label: 'Address', value: '' },
            { label: 'City', value: '' },
            { label: 'State', value: '' },
            { label: 'Zip Code', value: '' },
            { label: 'Country', value: '' },
            { label: 'Date of Birth', value: member?.dateOfBirth ? formatExtendedDateString(member.dateOfBirth) : 'Not Entered' },
            { label: 'Tax ID', value: 'Not Entered' },
            { label: 'Employee ID', value: 'Not Entered' },
            { label: 'Payroll ID', value: 'Not Entered' },
        ]

        const certificateDetails = [
            { label: 'Share Class', value: securityType && formatSecurityName(securityType) },
            { label: 'Quantity', value: holding?.value },
            { label: 'Price Per Share', value: holding?.capitalContribution && holding?.value && `$${roundFloat(safeDivide(holding.capitalContribution, holding.value))}` },
            { label: 'Status', value: '' },
        ]

        const issuerDetails = [
            { label: 'Issue Date', value: formatExtendedDateString(holding?.issueDate!) },
            {
                label: 'Board Approval Date',
                value: authorizations && authorizations.length > 0 && formatExtendedDateString(authorizations[0].authorizationDate!)
            },
        ]
        const args: any = { ...params, option: holding?.holdingType == HoldingRecordType.option ? holding?.hash : undefined, plan: holding?.plan || undefined }

        return (
            <PageContent>
                <PageContentHeader title="Details" />
                {holding.holdingType === HoldingRecordType.option ?
                    <ViewOption {...props} params={args} />
                    :
                    <ViewCertificateWrapper>
                        {renderHeader({ label: 'Owner' })}
                        {ownerDetails.map((el, index: number) => {
                            return renderInfo(el.label, el.value, index)
                        })}
                        {renderHeader({ label: 'Certificate' })}
                        {certificateDetails.map((el, index: number) => {
                            return renderInfo(el.label, el.value, index)
                        })}
                        {renderHeader({ label: 'Issuer' })}
                        {issuerDetails.map((el, index: number) => {
                            return renderInfo(el.label, el.value, index)
                        })}
                        <LightDivider />
                    </ViewCertificateWrapper>}
            </PageContent>
        )
    }
)
