import * as React from "react"
import { countries } from "@helpers/countries"
import { colors, FlexRow, LightDivider, Themes } from "@src/styles"
import {
  isCity,
  isCountry,
  isPostalCode,
  isProvince,
  isStreet,
} from "@helpers/field-validators"
import {
  FieldGroup,
  multiSelectFieldComponent,
  renderFieldsSimple,
  renderHeader,
  selectFieldComponent,
  textInputFieldComponent,
} from "@components/forms"
import Cross from "@image/close-default.svg"
import { emptyValidator, nextInternalId } from "@shared/helpers"
import { FieldArray } from "react-final-form-arrays"
import styled from "styled-components"
import { CancelButton } from "./button"
import { addressGroupOptions } from "@shared/helpers/constants"

interface StateProps {
  name: string
}

function addressFields(
  name: string,
  countriesFormatted: any,
  addressGroupOptions: any
): FieldGroup<any> {
  return [
    {
      name: `${name}.groups`,
      label: "Address Type(s)",
      required: false,
      validate: emptyValidator,
      placeholder: "Select address type",
      component: multiSelectFieldComponent(addressGroupOptions, 'Address Type(s)'),
    },
    {
      name: `${name}.country`,
      label: "Country",
      required: true,
      validate: isCountry,
      placeholder: "Select country",
      component: selectFieldComponent(countriesFormatted, 'Country'),
    },
    {
      name: `${name}.street1`,
      label: "Street Address",
      required: true,
      placeholder: "Enter street address",
      validate: isStreet,
      component: textInputFieldComponent,
    },
    {
      name: `${name}.street2`,
      label: "Street Address 2",
      required: false,
      placeholder: "Enter street address 2",
      validate: isStreet,
      component: textInputFieldComponent,
    },
    {
      name: `${name}.street3`,
      label: "Street Address 3",
      required: false,
      placeholder: "Enter street address 3",
      validate: isStreet,
      component: textInputFieldComponent,
    },
    {
      name: `${name}.city`,
      label: "City",
      required: true,
      validate: isCity,
      placeholder: "Enter city",
      component: textInputFieldComponent,
    },
    {
      name: `${name}.province`,
      label: "State/Province",
      required: false,
      placeholder: "Enter state / province",
      validate: isProvince,
      component: textInputFieldComponent,
    },
    {
      name: `${name}.postalCode`,
      validate: isPostalCode,
      label: "Postal Code",
      required: true,
      placeholder: "Enter postal code",
      component: textInputFieldComponent,
    },
  ]
}

export const StyledAddress = styled.div<any>`
  width: 100%;
  background-color: ${(props) => props.theme.selectorBack};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: ${(props) => (!!props.isLast ? "" : "25px")};
  .cross {
    cursor: pointer;
    path {
      fill: ${(props) => props.theme.whiteIcon};
      fill-opacity: 1;
    }
  }
`

const AddAddressBtn = styled<any>(CancelButton)`
  margin-top: 24px;
  color: ${colors.green} !important;
  height: 48px;
  line-height: 24px;
  border: none;
  background: transparent !important;
  &:hover {
    color: ${(props) => props.theme.white} !important;
    text-decoration: underline;
  }
`

const AddressHeader = styled<any>(FlexRow)`
  margin-bottom: 24px;
  span {
    margin: 0 !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-family: "NunitoSansSemiBold";
  }
`

const CrossWrap = styled<any>(FlexRow)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.buttonHover};
    path {
      fill: ${(props) =>
    props.theme.selected !== Themes.LIGHT
      ? props.theme.white
      : colors.white};
    }
  }
  &:active {
    background: ${(props) => props.theme.buttonActive};
  }
`

type Props = StateProps

export const Address = (props: Props) => {
  const { name } = props
  const countriesFormatted = countries.map((country) => ({
    label: country.label,
    value: country.alpha3,
  }))

  const deleteField = (fields: any, index: number) => {
    const value = fields.value?.filter((v: any, i: number) => index === i)?.map(s => ({ ...s, isRemoved: true }))[0]
    fields.update(index, value)
  }

  const Fields = (
    name: string,
    fields: any,
    key: number,
    isLast: boolean,
    i: number,
    fieldNumber: number
  ) => {
    return (
      <StyledAddress isLast={isLast} key={key}>
        <AddressHeader justifyContent={"space-between"}>
          <span>Address {fieldNumber}</span>
          <CrossWrap onClick={() => deleteField(fields, i)}>
            <Cross className="cross" />
          </CrossWrap>
        </AddressHeader>
        {renderFieldsSimple(
          addressFields(name, countriesFormatted, addressGroupOptions), true
        )}
      </StyledAddress>
    )
  }

  return (
    <>
      <FieldArray name={name || "address"}>
        {({ fields, meta }) => {
          const addToField = () => fields.push({ key: nextInternalId() })
          let fieldNumber: number = 0
          const addressArray =
            fields?.length! > 0
              ? fields.map((name: string, i: number) => {
                const key = fields?.value[i]?.key ? fields.value[i].key : i
                const isLast = i === fields?.length! - 1
                const removed = fields?.value[i]?.isRemoved
                if (!removed) fieldNumber++
                return !removed ? Fields(name, fields, key, isLast, i, fieldNumber) : null
              })
              : []
          const currentFields = addressArray.filter(e => e)
          return (
            <div style={{ textAlign: "start" }}>
              {renderHeader({
                label: 'Address Information',
                index: 0,
                buttonText: 'Add',
                buttonOnClick: () => addToField()
              })}
              {addressArray}
              {currentFields?.length > 0 && (
                <AddAddressBtn onClick={(e) => { e.preventDefault(), addToField() }} name='addAddress'>
                  + Add Address
                </AddAddressBtn>
              )}
              <LightDivider stretch={true} />
            </div>
          )
        }}
      </FieldArray>
    </>
  )
}
