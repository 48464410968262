import * as React from 'react'
import { logout, LogoutDispatch, PageProps, Paths, User } from '@logic'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

interface StateProps {
    user?: User
}

interface LogoutProps {
    logout: LogoutDispatch
}

type Props = StateProps & LogoutProps & PageProps

const LogoutComponent = (props: Props) => {
    const { user, navigate, logout } = props
    React.useEffect(() => {
        if (user) {
            logout()
            navigate(Paths.userLogin)
        }
    }, [])
    return (
        <div />
    )
}

const mapStateToProps: StateTransform<StateProps> = s => ({
    user: s.user,
})

export const Logout = withRouter(connect(mapStateToProps, { logout })(LogoutComponent))
