import React, { useState, ReactElement } from 'react'
import { colors, CommonInput, FlexCol, FlexRow, screenSizes } from '@src/styles'
import styled from 'styled-components'
import SearchIcon from '@image/search.svg'
import { ErrorLine } from './forms/field-errors'
import { Navigate, overflowParams } from './navigation'
import { guestPortfolioPath, portfolioPath } from '@logic'

const SearchWrapper = styled<any>(FlexRow)`
  width: ${props => props.joined == 'true' ? '411px' : '372px'};
  height: 50px;
  div, input {
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  input {
    border-top-right-radius: ${props => props.joined == 'true' && '0 !important'};
    border-bottom-right-radius: ${props => props.joined == 'true' && '0 !important'};
  }
  @media(max-width: ${screenSizes.S}px) {
    width: 100%;
  }
`

const SearchIconWrap = styled.div<any>`
  background: ${props => props.theme.accent};
  padding: 14px;
  cursor: pointer;
  margin-left: ${props => props.joined == 'true' ? '0' : '4px'};
  border-top-left-radius: ${props => props.joined == 'true' && '0 !important'};
  border-bottom-left-radius: ${props => props.joined == 'true' && '0 !important'};
  svg {
    path {
      fill: ${colors.white};
    }
  }
`

export interface SearchBundle {
    component: ReactElement
    inputValue: string
}

export function customPortfolioSearch(placeholder: string, navigate: Navigate, joined?: boolean, forGuest?: boolean): SearchBundle {
    const [inputValue, setInputValue] = useState<string>('')
    const isAddress = inputValue?.match(/^0x[a-fA-F0-9]{40}$/)
    const validate = inputValue && isAddress ? !inputValue.match(/^0x[a-fA-F0-9]{40}$/) : false
    const error = 'Enter a valid ETH address'
    const component = (
        <FlexCol alignItems={'flex-start'}>
            <SearchWrapper joined={joined ? 'true' : 'false'}>
                <CommonInput
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e: any) => setInputValue(e.target.value)}
                    notEmpty={inputValue ? 'true' : 'false'}
                    hasErrors={validate} />
                <SearchIconWrap
                    joined={joined ? 'true' : 'false'}
                    onClick={isAddress ? () => navigate(overflowParams(forGuest ?
                        guestPortfolioPath : portfolioPath, { address: inputValue })) : () => { }}>
                    <SearchIcon />
                </SearchIconWrap>
            </SearchWrapper>
            {validate ? <ErrorLine>
                {error}
            </ErrorLine> : null}
        </FlexCol>
    )
    return {
        component,
        inputValue,
    }
}
