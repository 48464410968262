import * as React from 'react'
import { ComponentType } from 'react'
import { Omit, RouteComponentProps, withRouter } from 'react-router'
import { History, LocationDescriptorObject } from 'history'
import { Paths } from '@logic'
import { UrlPath, getQueryParams } from '@helpers/util'
import * as H from 'history'

export interface BaseLinkProps<T = any> {
  path?: string | UrlPath<T>
  args?: T
}

export type Navigate = <T extends unknown = any>(url: UrlPath<T> | string | LocationDescriptorObject, args?: T, state?: any) => void
export type Params = { [key: string]: string }
export interface NavigationProps extends RouteComponentProps {
  params: { [key: string]: string }
  queryParams: { [key: string]: string }
  navigate: Navigate
}

export function formatQueryArgumentValue(value: any): string {
  if (Array.isArray(value)) {
    return value.join(',')
  } else {
    return value
  }
}

export function applyPathArgs(template: string, args: any) {
  const args2 = { block: 'current', ...args }
  const keys = Object.keys(args2)
  return keys
    .reduce((accumulator, key) =>
      accumulator.replace(':' + key, args2[key]), template
    )
}

export function formatParams(args: any, keys: string[] = Object.keys(args)) {
  return keys.length
    ? '?' + keys.map(key => `${key}=${formatQueryArgumentValue(args[key])}`).join('&')
    : ''
}

export function getOverflowParamsFilteringTemplate(template: string, args: any) {
  const keys = Object.keys(args)
  const remaining = keys.filter(key => template.indexOf(`:${key}`) === -1)
  return formatParams(args, remaining)
}

export function overflowParams(template: string, args: any): LocationDescriptorObject {
  const search = getOverflowParamsFilteringTemplate(template, args)
  return {
    pathname: applyPathArgs(template, args),
    search
  }
}

// Strongly typed version of applyPathArgs
export function resolveUrlPath<UrlProps>(urlPath: UrlPath<UrlProps>, props: UrlProps) {
  return Object.keys(props).reduce((a, b) => a.replace(':' + b, (props as any)[b]), urlPath)
}

export function matchesPath(path: string, value: string, exact: boolean): boolean {
  const generalized = path.replace(/:\w+/g, '[^/]*?')
  const pattern = `^${generalized}${exact ? '$' : ''}`
  const regex = new RegExp(pattern)
  return !!value.match(regex)
}

const navigate = (history: History): Navigate => (url, args, state) => {
  if (typeof url === 'string') {
    const pathname = args ? applyPathArgs(url, args) : url
    history.push({ pathname, state })
  } else {
    const locationDescriptor = url as LocationDescriptorObject
    const pathname = args ? applyPathArgs(locationDescriptor.pathname!, args) : locationDescriptor.pathname
    history.push({ ...locationDescriptor, pathname })
  }
}

export function withNavigation<P extends NavigationProps & RouteComponentProps<any>>(
  WrappedComponent: ComponentType<P>
): ComponentType<Omit<P, keyof (RouteComponentProps<any> & NavigationProps)>> {
  return withRouter((props: P) => <WrappedComponent {...props} params={{ ...props.match.params }}
    queryParams={{ ...getQueryParams() }}
    navigate={navigate(props.history)} />) as any
}
