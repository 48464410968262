import * as React from 'react'
import { useMemo, useState } from 'react'
import { currencyStringToNumber, Uuid } from '@helpers/util'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { CommonForm } from '@shared/components/forms'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { loadLegends, loadMembers, loadOrganizationHoldings, loadPlans } from '@shared/helpers/loaders'
import { NavigationProps } from 'shared/components/navigation'
import {
  exerciseOption,
  ExpandedHolding,
  ExpandedHoldingsResponse,
  GetLegendsResponse,
  GetPlansResponse,
  HttpError
} from '../../../service'
import {
  displayToast,
  DisplayToastDispatch,
  MembersProps,
  Organization,
  organizationMemberPath,
  PageProps
} from '@logic'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import arrayMutators from 'final-form-arrays'
import { PurchaseForm } from '../components/purchase-form'

interface Props extends NavigationProps, PageProps, MembersProps, GetLegendsResponse, ExpandedHoldingsResponse, GetPlansResponse {
  organization?: Uuid
  displayToast: DisplayToastDispatch
  currentOrganization: Organization
}
const withData = withLoadingCachedMultiple<Props>(loadMembers, loadOrganizationHoldings('all'), loadLegends(true), loadPlans)

export const PurchaseSharesComponent = withData((props: Props) => {
  const {
    params: { option },
    plans,
    httpClient,
    members,
    displayToast,
    navigate,
    currentOrganization,
    legends,
    holdings,
  } = props
  // const { features } = currentOrganization
  const [currentOption, setCurrentOption] = useState<ExpandedHolding | undefined>()
  const plan = plans[0]

  const currentPool = useMemo(() => {
    const poolOption = holdings.find(el => el.hash === option)
    const pool = holdings.find(el => el.id === poolOption?.parent)
    setCurrentOption(poolOption)
    return pool
  }, [plan])

  const currentShareholder = members.find(member => {
    if (member.id === currentOption?.owner) {
      return member
    }
  })

  const submitPurchase = async (values: any) => {
    if (currentPool) {
      const capitalContributions = values.capitalContributions
        ? Object.keys(values.capitalContributions).map((key: string) => {
          return { type: key, amount: currencyStringToNumber(values.capitalContributions![key]) }
        })
        : null
      const exerciseOptionsRequest = {
        ...values,
        shares: parseFloat(values.shares),
        capitalContributions,
      }
      const exerciseOptionsResponse = await exerciseOption(httpClient)(
        { option, plan: plan.hash, pool: currentPool.hash },
        exerciseOptionsRequest
      )
      if (exerciseOptionsResponse instanceof HttpError) {
        displayToast(exerciseOptionsResponse?.key!)
      } else {
        navigate(organizationMemberPath, { organization: plan.organization, member: currentShareholder?.id })
      }
    }
  }
  return (
    <PageContent>
      <PageContentHeader title="Purchase Shares" />
      <CommonForm
        mutators={{ ...arrayMutators }}
        onSubmit={submitPurchase}
        initialValues={{}}
        submitText="Purchase"
      >
        <PurchaseForm legends={legends} currentShareholder={currentShareholder} />
      </CommonForm>
    </PageContent>
  )
})

export const PurchaseShares = connect(null, { displayToast })(withRouter(PurchaseSharesComponent as any))
