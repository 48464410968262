import * as React from 'react'
import { FlexCol, FlexRow, semiBoldStyle } from '@src/styles'
import { editPlanPath, organizationViewSecurityPath } from '@logic'
import { formatDateString, formatSecurityName, optionalCommaNumber, safeDivide, Uuid } from '@shared/helpers'
import { HttpClient } from '@src/service'
import { Navigate, Params, PathLink } from '@shared/components/navigation'
import styled from 'styled-components'
import { Panel, PanelGrid } from '@shared/components/panels/panels'
import { ShareChart } from '@modules/team-members/components/team-members-profile/components'
import { StyledLink } from '@modules/captable/pages'
import EmptyDocuments from '@image/empty-documents-118x118.svg'
import { PathLinkButtonInverted } from '@shared/components/button'
import { dateDiffInDays } from '@modules/convertibles/utility'

interface Props {
  organization: Uuid
  plan?: any
  httpClient: HttpClient
  navigate: Navigate
  securities?: any[]
  params: Params
  totalExercised: number
  documents?: any
}

const AmountSpan = styled.span<any>`
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 4px;
`

const TitleRow = styled<any>(FlexRow)`
  padding: 8px;
  margin-bottom: ${props => props.extend === 'true' ? '16px' : '8px'};
  span {
    ${semiBoldStyle}
    font-size: 24px;
    line-height: 32px;
  }
`

const FooterContent = styled<any>(FlexRow)`
  padding: 8px;
  width: 100%;
  span {
    line-height: 24px;
  }
  label {
    color: #9CA6AD;
    font-size: 14px;
    line-height: 19px;
  }
`

const StyledData = styled<any>(FlexRow)`
  padding: 12px;
  width: 100%;
  a {
    :hover {
      text-decoration: underline;
    }
  }
`

const StyledEmpty = styled<any>(FlexCol)`
  width: 100%;
  span {
    margin: 16px 0;
    line-height: 24px;
  }
`

const CustomLabel = styled.label<any>`
  color: #9CA6AD;
  font-size: 16px;
  line-height: 24px;
`

export const ViewPlan = (props: Props) => {
  const { organization, plan, securities, documents, totalExercised } = props
  const openDocument = (doc?: Uuid, name?: string) => {
    if (doc && name) {
      const n = encodeURIComponent(name)
      window.open(`/api/v1/file/${doc}/content/${n}`)
    }
  }
  const contentInfo: any = [
    {
      label: 'Total Incentives',
      value: optionalCommaNumber(plan?.totalIncentives)
    },
    {
      label: 'Total Pools',
      value: optionalCommaNumber(plan?.totalPools)
    },
    {
      label: 'Total Vesting Schedules',
      value: optionalCommaNumber(plan?.totalVestingSchedules)
    },
    {
      label: 'Total Holdings',
      value: optionalCommaNumber(plan?.totalOptions)
    },
  ]

  const amountInfo: any = [
    {
      label: 'Total Allocated Amount',
      value: optionalCommaNumber(plan?.totalAllocatedAmount)
    },
    {
      label: 'Total Exercised Amount',
      value: optionalCommaNumber(totalExercised)
    },
  ]

  const equitiesAndDocuments: any = [
    {
      title: 'Approved Equities',
      data: securities,
      forEquities: true,
      link: organizationViewSecurityPath,
      args: {}
    },
    {
      title: 'Documents',
      data: documents,
    },
  ]

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)
  const approvalDate = new Date(plan?.boardApprovalDate)
  const dateDiff = dateDiffInDays(approvalDate, currentDate)
  const yearOfApproval = approvalDate.getFullYear()
  const endDate = new Date(yearOfApproval + plan?.termYears, approvalDate.getMonth(), approvalDate.getDate())
  const daysBetweenTerm = dateDiffInDays(approvalDate, endDate)
  const termYearsCount = dateDiff > 0 ? safeDivide(dateDiff, daysBetweenTerm) * 100 : 0

  return (
    <>
      <PanelGrid columns={4}>
        {contentInfo.map((info: any) => (
          <Panel>
            <FlexCol alignItems={'flex-start'}>
              <AmountSpan>{info.value}</AmountSpan>
              <CustomLabel>{info.label}</CustomLabel>
            </FlexCol>
          </Panel>
        ))}
      </PanelGrid>
      <PanelGrid columns={2}>
        {amountInfo.map((info: any) => (
          <Panel>
            <FlexCol alignItems={'flex-start'}>
              <AmountSpan>{info.value}</AmountSpan>
              <CustomLabel>{info.label}</CustomLabel>
            </FlexCol>
          </Panel>
        ))}
      </PanelGrid>
      <PanelGrid columns={3} style={{ marginTop: '4px' }}>
        <Panel>
          <div>
            <TitleRow>
              <span>Plan Term</span>
            </TitleRow>
            <ShareChart termYears={plan?.termYears || 0} chartValue={termYearsCount} />
            <FooterContent justifyContent={'space-between'}>
              <FlexCol alignItems={'flex-start'}>
                <label>Started on</label>
                <span>{approvalDate ? formatDateString(approvalDate) : null}</span>
              </ FlexCol>
              <FlexCol alignItems={'flex-end'}>
                <label>Ends on</label>
                {plan?.termYears !== 0 ?
                  <span>{formatDateString(endDate)}</span>
                  :
                  <span>No Expiration</span>
                }
              </FlexCol>
            </FooterContent>
          </div>
        </Panel>
        {equitiesAndDocuments.map((v: any) => (
          <Panel>
            <div>
              <TitleRow extend={'true'}>
                <span>{v.title}</span>
              </TitleRow>
              {v.forEquities ?
                v.data.map((d: any) => (
                  <StyledData>
                    <PathLink
                      path={organizationViewSecurityPath}
                      args={{ organization, security: d.hash }}
                      target={'_blank'}>
                      {formatSecurityName(d)} {d.shareClassPrefix ? `(${d.shareClassPrefix})` : ''}
                    </PathLink>


                  </StyledData>
                ))
                :
                v.data.length > 0 ? v.data.map((d: any) => (
                  <StyledData>
                    <StyledLink
                      style={{ textAlign: 'left' }}
                      onClick={() => openDocument(d.document?.hash!, d.document?.filename!)}>
                      {d.document?.filename!}
                    </StyledLink>
                  </StyledData>
                ))
                  :
                  <StyledEmpty>
                    <EmptyDocuments />
                    <span>Add New Document</span>
                    <PathLinkButtonInverted
                      path={editPlanPath}
                      args={{ organization, plan: plan?.hash, parent: location.pathname }}
                      slim>
                      + Add
                    </PathLinkButtonInverted>
                  </StyledEmpty>
              }
            </div>
          </Panel>
        ))}
      </PanelGrid>
    </>
  )
}
