import * as React from 'react'
import { newBasicModalBundle } from "../basic-modal"
import { LoginSignButton } from '../button'
import styled from 'styled-components'
import { colors, FlexCol, FlexRow } from '@src/styles'
import SignupModal from '@image/signup-modal.png'
import { Paths } from '@logic'
import { Navigate } from '../navigation'

const TitleWrap = styled<any>(FlexRow)`
  align-items: center;
`
const DescWrap = styled.span<any>`
padding: 32px 32px 0px !important;
`
const HeaderLink = styled.span<any>`
  cursor: pointer;
  color: ${props => `${props.theme.accent}`};
  margin-left: 4px;
`
const Wrap = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  overflow-y: auto;
  background: ${props => props.theme.modalBack};
  align-items: flex-start;
  div {
      width: 100%;
      @media only screen and (max-width: 500px) {
    width: max-content;
  }
  }
  .body {
    margin: 0;
    padding-inline-start: 22px;
    line-height: 24px;
  }
  .info {
    display: flex;
    padding: 8px 12px;
    border: 1px solid #CEDAE1;
    background-color: ${({ theme }) => theme.input.default};
    border-radius: 4px;
  }
  .haveAccount{
    width: 100%;
      text-align: left;
      margin-top: 8px;
  }
`
const Subtext = styled.span`
    margin: auto 0;
    margin-left: 15px;
`

interface ModalProps {
  navigate: Navigate
}

export interface signUpModalBundle {
  component: React.ReactElement
  isVisible: boolean
  setVisible: (value: boolean) => void
}
export function signUpModal(props: ModalProps): signUpModalBundle {
  const { navigate } = props
  return newBasicModalBundle({

    title: <TitleWrap>{'Sign up to complete action'}</TitleWrap>,
    desc: 'You need an Equa account to complete this action.',
    body: <Wrap>
      <div className='info'>
        <img src={SignupModal} />
        <Subtext> Earn a FREE Equa Cash scratch-off ticket simply<br /> by signing up!</Subtext>
      </div>
      <span className='haveAccount'>Already have an account?
        <HeaderLink onClick={() => navigate(Paths.userLogin)}>
          Log In
        </HeaderLink>
      </span>
    </Wrap>,
    noPadding: true,
    buttons: [
      {
        text: 'Sign Up',
        onActivate: () => {
          navigate(Paths.userRegister)
        },
        componentType: LoginSignButton,
      },
    ],
    max: '550px'
  })
}