import * as React from 'react'
import { FlexCol, FlexRow, Themes } from '@src/styles'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { CardHeader, InviteDescription, InviteSection, InviteWrap } from '@modules/referrals/style'
import { SlimButton } from '../../../shared/components/button'
import CopySmall from '@image/copy-16x16.svg'
import Share from '@image/share.svg'
import ReferralBack from '@image/referral-back.svg'
import ReferralBackLight from '@image/referral-back-light.svg'
import { CommonForm } from '../../../shared/components/forms'
import { InviteForm, learnMoreModal } from '@modules/referrals/components'
import arrayMutators from 'final-form-arrays'
import { CopyHover } from '@modules/profile'

interface Props {
  onSubmit: any
  socialMediaModalOpen: () => void
  copyReferralLink: () => void
  forOrg?: boolean
  copy?: boolean
  mobileView?: boolean
  forGuest?: boolean
  openSignUp?: () => void
}

const InfoBox = styled<any>(FlexCol)`
  background: #435763;
  border-radius: 8px;
`

const TopInfo = styled<any>(FlexCol)`
  padding: 24px 24px 0px;
  border-bottom: 1px solid rgba(206, 218, 225, 0.3);
  svg {
    margin-right: 10px;
  }
`

const BottomInfo = styled<any>(FlexRow)`
  padding: 12px 0;
  width: 100%;
  .values {
    display: flex;
    flex-direction: column;
    :last-child {
      align-items: flex-end;
    }
    svg {
        margin-right: 4px;
    }
  }
`
const CopyWrap = styled<any>(FlexRow)`
    cursor: pointer;
    position: relative;
    width: 100%;
    div {
        display: none;
    }
    &:hover {
        div {
            display: flex;
        }
    }
    svg {
        path {
            fill: ${props => props.theme.whiteIcon};
        }
    }
    .arrow-up {
        width: 0; 
        height: 0; 
        left: 50%;
        position: absolute;
        bottom: 5px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;    
        border-bottom: 7px solid ${props => !props.copy ? '#FAFAFA' : '#33AA40'};
    }
`

const HoverElement = styled<any>(CopyHover)`
  max-width: 50px;
  left: 42%;
  bottom: -19px;
`

export const ReferralInvitePanel = withTheme((props: Props & ThemeProps<any>) => {
  const { onSubmit, copyReferralLink, forOrg, copy, mobileView, theme, socialMediaModalOpen, forGuest, openSignUp } = props
  const handleLearnCancel = () => {
    learnModal.setVisible(false)
  }
  const learnModal = learnMoreModal({ onCancel: handleLearnCancel })

  return (
    <>
      {learnModal.component}
      <InviteWrap>
        <InviteSection>
          <CardHeader>Your {forOrg ? 'Org' : 'Profile'} referral link</CardHeader>
          <InviteDescription>
            Earn Equa Cash scratch-off tickets by inviting others to the Equa platform using your custom
            {' '}{forOrg ? 'org' : 'profile'} referral link below.
            {' '}<span onClick={() => learnModal.setVisible(true)} className='openModal'>Learn more</span>
          </InviteDescription>
          <SlimButton onClick={forGuest ? openSignUp : socialMediaModalOpen}>
            <Share />
            share
          </SlimButton>
          <CopyWrap copy={copy}>
            <SlimButton onClick={forGuest ? openSignUp : () => copyReferralLink()}>
              <CopySmall />
              copy link
            </SlimButton>
            <div className='arrow-up' />
            <HoverElement copy={copy}>
              <span>{copy ? 'Copied!' : 'Copy'}</span>
            </HoverElement>
          </CopyWrap>
          <CommonForm mutators={{ ...arrayMutators }} onSubmit={forGuest ? openSignUp : onSubmit} initialValues={{}}
            stretched={true}>
            <InviteForm />
          </CommonForm>
        </InviteSection>
        {!mobileView ? theme.selected === Themes.LIGHT ? <ReferralBackLight /> : <ReferralBack /> : null}
      </InviteWrap>
    </>
  )
})
