import * as React from 'react'
import { OrganizationProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAddresses, loadEmails, loadOrganization } from '@helpers/loaders'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { OrganizationDetailsForm } from '../components/organization-details-form'
import { FilesResponse, postFile, editOrganizationDetails, GetAddressesResponse, createAddress, editAddress, deleteAddress, addEmails, GetEmailsResponse } from '@src/service'
import { organizationDetailsPath, setExpanded } from '@logic'
import { BaseLinkProps, applyPathArgs } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { NewAddressRequest1, NewEmailDiffRequest, PatchOrganizationRequest, UpdateAddressRequest1 } from '@src/service/services/organizations/requests'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & OrganizationProps & StoreProps & GetAddressesResponse & GetEmailsResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganization, loadAddresses('organization'), loadEmails('organization'))

const EditOrganizationDetailsComponent = withData(
  withPermissions([BuiltInPermission.editOrganizationDetails])((props: Props) => {
    const { httpClient, navigate, params, setExpanded, addresses } = props
    const organizationDetails = props.organization
    const {
      name,
      businessAddress,
      mailingAddress,
      website,
      taxIds,
      logo,
      phoneNumber,
      companyType,
      registrationNumber,
      startDate,
    } = organizationDetails
    const { organization } = params
    const mapAddresses = addresses?.map(a => {
      const groups = a.groups?.map(g => g.id!)
      return { ...a, groups }
    })
    const emails = props.emails!!
    const mapEmails = emails && emails.length > 0 ? emails.reverse().map(e => {
      return { email: e.email }
    }) : []

    const initialValues = {
      name,
      address: mapAddresses,
      website,
      taxIds,
      logo,
      phoneNumber,
      companyType,
      registrationNumber,
      startDate,
      emails: mapEmails
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        id: file.id,
        hash: file.hash,
        name: file.filename,
      }
    }

    const onSubmit = async (values: any) => {
      const taxIds = values?.taxIds?.length > 0 ?
        values.taxIds.filter(f => !f.isRemoved)?.map((taxId: any) => {
          const newTaxId = { ...taxId }
          delete newTaxId.key
          return newTaxId
        }) : []

      const addressesValues = values?.address?.map((address: any) => {
        const newAddress = { ...address }
        delete newAddress.key
        delete newAddress.format
        delete newAddress.created
        delete newAddress.content
        delete newAddress.context
        return newAddress
      })

      const emailValues = values.emails?.length > 0 ?
        values.emails.filter((e: any) => !e.isRemoved)?.map((email: any) => {
          const newEmail = { ...email }
          delete newEmail.key
          return newEmail.email
        }) : []

      const Organization = {
        name: values?.name!,
        phoneNumber: values?.phoneNumber!,
        companyType: values?.companyType!,
        startDate: values?.startDate!,
        registrationNumber: values?.registrationNumber!,
        logo: values.logo?.id,
        taxIds,
        website: values?.website ? values.website : null,
      } as PatchOrganizationRequest
      const editOrganization = await editOrganizationDetails(httpClient)({ organization }, Organization)
      if (editOrganization?.organization) {
        if (addressesValues && addressesValues.length > 0) {
          addressesValues.forEach(async (address: any) => {
            if (address.isRemoved && address.id) {
              await deleteAddress(httpClient)({ entity: organization, address: address.id })
            }
            if (!address.isRemoved) {
              if (address.id) {
                const editAddRequest = {
                  ...address,
                  groups: address?.groups ? address.groups : []
                } as UpdateAddressRequest1
                await editAddress(httpClient)({ entity: organization, address: address.id }, editAddRequest)
              } else {
                const addRequest = {
                  ...address,
                  groups: address?.groups ? address.groups : []
                } as NewAddressRequest1
                await createAddress(httpClient)({ entity: organization }, addRequest)
              }
            }
          })
        }
        if (emailValues) {
          const emailsRequest: NewEmailDiffRequest = {
            groups: [],
            emails: emailValues,
            entity: organization
          }
          await addEmails(httpClient)({ entity: organization }, emailsRequest)
        }
        setExpanded()
        navigate(organizationDetailsPath, { organization })
      }
      else console.log('edit Organization error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationDetailsPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Edit Organization Details" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <OrganizationDetailsForm onUpload={onUpload} />
        </CommonForm>
      </PageContent>
    )
  }))

export const EditOrganizationDetailsPage = connect(null, { setExpanded })(EditOrganizationDetailsComponent)
