import * as React from 'react'
import { PageProps, editCardPath, accountPath, PaymentProfilesResponse } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadPaymentProfiles } from '@helpers/loaders'
import { FlexRow, fontSizes, LightDivider, OrgDetailsWrapper, screenSizes } from '@src/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { renderHeader } from '@shared/components/forms'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { newDeleteCardModal } from '@modules/documents/components/modals'
import { BaseLinkProps, overflowParams } from '@shared/components/navigation'
import { defaultPaymentProfile, deletePaymentProfile } from '@src/service'

type Props = PageProps & PaymentProfilesResponse

const withData = withLoadingCachedMultiple<Props>(loadPaymentProfiles)

const InfoStyledRow = styled<any>(FlexRow)`
    width: 100%;
    justify-content: space-between;
    padding-bottom: ${props => !props.isLast && '8px'};
    align-items: start;
    p, span {
        font-size: ${fontSizes.XS} !important;
        line-height: 22px;
    }
    p {
        width: 50%;
        margin: 0 10px 0 0;
        color: ${props => props.theme.label};
    }
    span {
        width: 50%;
        word-break: break-all;
        text-align: right;
    }
`

const ViewProfileWrap = styled<any>(OrgDetailsWrapper)`
  width: 100%;
  max-width: 600px;
  a {
      text-decoration: underline;
  }
`

const ProfileWrapper = styled.div<any>`
  h1, h4 {
      font-weight: normal;
  }
  h4 {
    padding-top: 0;
  }

  .first {
    margin-top: 20px;
    @media (min-width: ${screenSizes.XL}px) {
        margin-top: 40px;
    }
  }
`

const renderCardInfo = (info: any | undefined, links?: boolean) => {
    return info?.map((s: any, i: number) => {
        const isLast = i === info.length - 1
        return <InfoStyledRow isLast={isLast} key={i}>
            <p>{s.title}</p>
            {links ?
                s.value && <Link to={{ pathname: s.value!.includes('http') ? s.value! : `http://${s.value!}` }}
                    target="_blank">{s.value!}</Link>
                :
                <span>
                    {s.value!}
                </span>
            }
        </InfoStyledRow>
    })
}

export const ViewCardPage = withData((props: Props) => {
    const [profile, setProfile] = React.useState<string>('')
    const { navigate, reload, paymentProfiles, params, httpClient, user, location } = props
    const filteredProfile = paymentProfiles && paymentProfiles.length > 0 ? paymentProfiles.find(p => p.payment_profile?.id == params?.card!) : undefined
    const profileRecord = filteredProfile?.payment_profile!
    const cardLastDigits = profileRecord?.masked_card_number?.split('-')[3]

    const billingAddress = [
        { title: 'Country', value: profileRecord?.billing_country! },
        { title: 'Street Address', value: profileRecord?.billing_address! },
        { title: 'Street Address 2', value: profileRecord?.billing_address_2! },
        { title: 'City', value: profileRecord?.billing_city! },
        { title: 'State / Province', value: profileRecord?.billing_state! },
        { title: 'Postal Code', value: profileRecord?.billing_zip! },
    ]
    const cardDetails = [
        { title: 'First Name', value: profileRecord?.first_name! },
        { title: 'Last Name', value: profileRecord?.last_name! },
        { title: 'Card Number', value: `...${cardLastDigits}` },
        { title: 'Valid Thru (mm/yyyy)', value: `${profileRecord?.expiration_month!}/${profileRecord?.expiration_year!}` },
        { title: 'Default Status', value: filteredProfile?.isDefault ? 'Default' : 'Not Default' },
    ]
    const deleteProfile = async () => {
        await deletePaymentProfile(httpClient)({ entity: user?.id, profile })
        deleteModal.setVisible(false)
        navigate(accountPath)
    }

    const deleteModal = newDeleteCardModal(deleteProfile)
    const openDeleteModal = (card: string) => {
        setProfile(card)
        deleteModal.setVisible(true)
    }

    const setProfileAsDefault = async (card: string) => {
        await defaultPaymentProfile(httpClient)({ entity: user?.id!, profile: card }, {})
        reload()
    }

    const dropOptions = [
        ['Edit', () => navigate(overflowParams(editCardPath, { card: params?.card!, parent: location?.pathname! }))],
        !filteredProfile?.isDefault && ['Set as Default', () => setProfileAsDefault(params?.card!)],
        ['Delete', () => openDeleteModal(params?.card!)],
    ]

    const parent: BaseLinkProps = { path: accountPath, args: {} }

    return (
        <>
            {deleteModal.component}
            <PageContent>
                <ProfileWrapper>
                    <PageContentHeader title={`View Card ending in ...${cardLastDigits!}`} parent={parent} direction>
                        <ActionsDrop options={dropOptions} />
                    </PageContentHeader>
                    <ViewProfileWrap>
                        {renderHeader({ label: 'Billing Address' })}
                        {renderCardInfo(billingAddress)}
                        {renderHeader({ label: 'Card Details' })}
                        {renderCardInfo(cardDetails)}
                    </ViewProfileWrap>
                </ProfileWrapper>
            </PageContent>
        </>
    )
}
)
