import * as React from 'react'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { Organization, User, setExpanded } from '@logic'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'
import { SubHeader, SubTitle } from '@src/app/components/header/sub-header'
import { ComponentType } from 'react'
import { ServiceProps, withService } from '@shared/hocs/with-service'
import { editUserState, GetAddressesResponse } from '@src/service'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { getViewAddress, loadAddresses, loadUserState, nextStateCount } from '@shared/helpers'
import { withTracker } from '@shared/hocs/with-tracker'
import { NavigationProps, withNavigation } from '@shared/components/navigation'
import { AddressGroups } from '@shared/helpers/constants'

interface StateProps {
  user?: User
  currentOrganization: Organization
}

interface DispatchProps {
  setExpanded: any
}

interface UserStateProps {
  userStateRecords?: any
}

interface HistoryProps extends RouteComponentProps<any> {
  history: History
}

type Props = StateProps & HistoryProps & NavigationProps & ServiceProps & DispatchProps & UserStateProps

const withData = withLoadingCachedMultiple<Props>(loadUserState)

const OrganizationHeaderComponent = withNavigation(withTracker(withService(withData((props: Props) => {
  const { currentOrganization, httpClient, params, setExpanded } = props
  const userState = props.userStateRecords!!
  React.useEffect(() => {
    ; (async () => {
      if (params.organization) {
        if (userState?.recentOrganizations) {
          const recent = userState.recentOrganizations.filter((org: any) => org.id !== params.organization)
          const recentOrganizations = recent.concat([{ id: params.organization, visited: new Date() }])
          await editUserState(httpClient)({}, { userState: { ...userState, recentOrganizations } })
        }
        else {
          await editUserState(httpClient)({}, { userState: { ...userState, recentOrganizations: [{ id: params.organization, visited: new Date() }] } })
        }
        nextStateCount()
      }
    })()
  }, [params.organization])

  const OrganizationTitle = () => (
    <SubTitle
      logo={undefined}
      name={undefined}
    />
  )
  return (
    <>{<SubHeader title={<OrganizationTitle />} hide={true} />}</>
  )
}))))

const mapStateToProps: StateTransform<StateProps> = s => ({
  user: s.user,
  currentOrganization: s.organization!.currentOrganization!,
})

export const OrganizationHeader = withRouter(connect(mapStateToProps, { setExpanded })(OrganizationHeaderComponent) as any)

export function withOrganizationHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
  return (props: any) => (
    <React.Fragment>
      <OrganizationHeader />
      <InnerComponent {...props} />
    </React.Fragment>
  )
}
