import * as React from "react";
import {
  guestWalletAddressPortfolioPath,
  PageProps,
  walletAddressPortfolioPath,
} from "@logic";
import { PageContent } from "@components/pages";
import { FlexRow } from "@src/styles";
import {
  capitalize,
  getAddressData,
  guestWalletTabs,
  walletNetworks,
  walletTabs,
} from "../utility";
import { Tabs } from "@shared/components/tabs/tabs";
import { search } from "@shared/components/search";
import EmptyWallet from "@image/empty-wallet.svg";
import SaveButton from "@image/save-button.svg";
import SavedButton from "@image/saved-button.svg";
import { WalletInfo } from "../components/wallet-info";
import {
  deleteWalletAddress,
  getENS,
  newWalletAddress,
  NewWalletAddressRequest,
} from "@src/service";
import { AddressBookDrop } from "../components/address-book-drop";
import { CopyHover, CopyWrap } from "../pages/user-dashboard";
import Copy from "@image/copy-16x16.svg";
import { Loading } from "@components/loading";
import {
  AddressValue,
  BetaNotify,
  Description,
  EmptyWalletWrap,
  EnsName,
  PortofolioWrap,
  SaveButtonWrap,
} from "../pages";

type Props = PageProps & { forGuest?: boolean, openSignUp?: any };

export const WalletPortfolioComponent = (props: Props) => {
  const { params, navigate, user, httpClient, reload, forGuest, openSignUp } = props;
  const { address } = params;
  const records = props?.walletAddressRecords || [];
  const selectedAddress = records?.find((r) => r.address == address);
  const isAddress = address?.match(/^0x[a-fA-F0-9]{40}$/);
  const [ensValue, setEnsValue] = React.useState<any>();
  const [copy, setCopy] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [addressData, setAddressData] = React.useState<any>([]);
  const searchBundle = search(
    "Search address...",
    true,
    () =>
      navigate(
        forGuest ? guestWalletAddressPortfolioPath : walletAddressPortfolioPath,
        { address: inputValue }
      ),
    "address",
    "Enter a valid ETH address"
  );
  const Search = searchBundle.component;
  const inputValue = searchBundle.inputValue;
  const {
    data,
    walletData,
    savingsData,
    claimData,
    debtData,
    vaultData,
    networksData,
    protocolsData,
  } = getAddressData(addressData, address);

  const copyCodeToClipboard = (label: string) => {
    const el = document.getElementById(label);
    if (el) {
      navigator.clipboard.writeText(el.innerText!);
      setCopy(true);
    }
  };

  React.useEffect(() => {
    if (address && isAddress) {
      var data: any = [];
      const eventSource = new EventSource(
        `//api.zapper.fi/v1/balances?addresses%5B0%5D=${address}&nonNilOnly=true&networks%5B0%5D=ethereum&networks%5B1%5D=polygon&networks%5B2%5D=binance-smart-chain&api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241`,
        { withCredentials: true }
      );
      eventSource.addEventListener("start", (event: any) => {
        setLoading(true);
      });
      eventSource.addEventListener("balance", (event: any) => {
        data.push(JSON.parse(event.data));
      });
      eventSource.addEventListener("end", () => {
        setAddressData(data);
        setLoading(false);
        eventSource.close();
      });
    }
    return () => {
      setAddressData([]);
    };
  }, [address]);

  React.useEffect(() => {
    (async () => {
      if (address && isAddress) {
        const response: any = await getENS(httpClient)({ address: address });
        if (response && response[0] !== null) setEnsValue(response[0]);
        else {
          setEnsValue(address);
        }
      }
    })();
  }, [address]);

  const addAddress = async () => {
    const addressRequest: NewWalletAddressRequest = {
      entity: user?.id || "",
      address: address,
      name: address,
      globalName: ensValue,
    };
    const res = await newWalletAddress(httpClient)({}, addressRequest);
    if (res) reload();
  };

  const deleteAddress = async () => {
    const res = await deleteWalletAddress(httpClient)({
      entity: selectedAddress?.entity,
      address: selectedAddress?.address,
    });
    if (res) reload();
  };

  return (
    <>
      <Tabs
        tabs={forGuest ? guestWalletTabs : walletTabs}
        title={
          <>
            Wallets
            <BetaNotify>Beta</BetaNotify>
          </>
        }
        params={params}
      />
      <PageContent withMargin={true}>
        <PortofolioWrap>
          <FlexRow
            style={{ width: "100%", maxWidth: "483px" }}
            alignItems={"flex-start"}
          >
            {Search}
            <AddressBookDrop addresses={records} />
          </FlexRow>
          {data.length > 0 ? (
            <div>
              <FlexRow style={{ marginTop: "24px" }} alignItems={"center"}>
                <EnsName>
                  {address === ensValue
                    ? ensValue.slice(0, 6) +
                    "..." +
                    ensValue.slice(ensValue.length - 4, ensValue.length)
                    : ensValue}
                </EnsName>
                {forGuest ?
                  <SaveButtonWrap onClick={openSignUp}>
                    <SaveButton />
                    <CopyHover copy={false}>
                      <span>Save to address book</span>
                    </CopyHover>
                  </SaveButtonWrap>
                  : (
                    <SaveButtonWrap
                      onClick={() =>
                        selectedAddress ? deleteAddress() : addAddress()
                      }
                    >
                      {selectedAddress ? <SavedButton /> : <SaveButton />}
                      <CopyHover copy={false}>
                        <span>
                          {!selectedAddress
                            ? "Save to address book"
                            : "Saved to address book"}
                        </span>
                      </CopyHover>
                    </SaveButtonWrap>
                  )}
              </FlexRow>
              <FlexRow>
                <AddressValue id={"address"}>{address}</AddressValue>
                <CopyWrap
                  copy={copy}
                  onClick={() => copyCodeToClipboard("address")}
                  onMouseLeave={() => setCopy(false)}
                  style={{
                    minWidth: "20px",
                    height: "20px",
                    width: "20px",
                    marginLeft: "10px",
                  }}
                >
                  <div className="arrow-up" />
                  <CopyHover copy={copy}>
                    <span>{copy ? "Copied!" : "Copy"}</span>
                  </CopyHover>
                  <Copy style={{ fill: "#9CA6AD" }} />
                </CopyWrap>
              </FlexRow>
              <WalletInfo
                walletData={walletData}
                savingsData={savingsData}
                claimData={claimData}
                debtData={debtData}
                vaultData={vaultData}
                networksData={networksData}
                protocolsData={protocolsData}
                supportedProtocols={props?.supportedProtocols!!}
              />
            </div>
          ) : loading ? (
            <Loading />
          ) : (
            <EmptyWalletWrap>
              <EmptyWallet />
              <Description style={{ marginTop: "24px" }}>
                Use the search bar to find public addresses,
              </Description>
              <Description>or choose one from your address book.</Description>
            </EmptyWalletWrap>
          )}
        </PortofolioWrap>
      </PageContent>
    </>
  );
};
