import Modal from '@components/modal'
import Close from '@image/close-default.svg'
import { CrossWithHover, FlexRow, LightDivider, screenSizes } from '@src/styles'
import { CancelButton, InvertedWarningButton, SubmitButton, WarningButton } from '@components/button'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import Warning from '@image/warning.svg'

interface FormProps {
    title: string
    onCancel?: () => void
    submitText?: string
}

const TitleView = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.white};
  line-height: 32px;
  font-weight: bold;
  font-family: 'NunitoSansBold';
`

const WelcomeWrap = styled.div`
  color: ${props => props.theme.white} !important;
  width: 100%;
  padding: 32px 32px 24px 32px !important;
  overflow-y: auto;
  background: ${props => props.theme.welcomeBack};
  .warning {
      margin-right: 16px;
      path {
        fill: ${props => props.theme.warning};
      }
  }
  .body {
      padding: 24px 0;
      align-items: flex-start;
  }
`
const DescView = styled.div`
  color: ${props => props.theme.white} !important;
  span {
      line-height: 24px;
      display: block;
      margin: 0;
  }
  a {
      line-height: 24px;
      :hover {
        text-decoration: underline;
      }
  }
`

const FormButtonLayout = styled<any>(FlexRow)`
  padding-top: 24px;
  justify-content: flex-end;
  button {
    height: 40px;
    width: 100%;
    padding: 0 32px !important;
  }
  @media(max-width: ${screenSizes.M}px) {
      flex-direction: column;
      button: last-child {
          margin: 8px 0 0 0;
      }
  }
`


const StyledDivider = styled<any>(LightDivider)`
    margin: ${props => props.stretch && '0 -6.7%'};
    border-bottom: 1px solid rgba(206, 218, 225, 0.3);
`

export function ExitWithoutSaveComponent(props: FormProps) {
    const { title, submitText } = props
    const onCancel = props.onCancel || function () { }
    return (
        <Modal max={'544px'}>
            <WelcomeWrap>
                <FlexRow justifyContent={'space-between'}>
                    <FlexRow>
                        <Warning className='warning' />
                        <TitleView>{title}</TitleView>
                    </FlexRow>
                    <CrossWithHover onClick={() => props?.setVisible(false)}>
                        <Close />
                    </CrossWithHover>
                </FlexRow>
                <FlexRow className="body">
                    <DescView>
                        <span>You have made some changes on this page that have not been saved yet. </span>
                    </DescView>
                </FlexRow>
                <StyledDivider stretch={true} />
                <FormButtonLayout>
                    <InvertedWarningButton onClick={onCancel}>
                        Leave Anyway
                    </InvertedWarningButton>
                    <WarningButton onClick={() => props?.setVisible(false)}>
                        {submitText}
                    </WarningButton>
                </FormButtonLayout>
            </WelcomeWrap>
        </Modal>
    )
}

export interface exitWithoutSaveModal {
    component: ReactElement
    isVisible: boolean
    setVisible: (value: boolean) => void
}

export function exitWithoutSaveModalBundle(props: FormProps): exitWithoutSaveModal {
    const [isVisible, setVisible] = useState<boolean>(false)
    const propsWithState = { ...props, setVisible }
    const component = (
        <>
            {isVisible ? (
                <ExitWithoutSaveComponent {...propsWithState} />
            ) : null}
        </>
    )
    return {
        component,
        isVisible,
        setVisible,
    }
}
