import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  MembersProps,
  optionsPath,
  PageProps,
  planPath,
} from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions, PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import {
  createAuthorization,
  createHolding,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetLegendsResponse,
  GetPlanResponse,
  GetSecuritiesResponse,
  GetVestingsResponse,
  NewAuthorizationRequest,
  NewHoldingRequest,
  postFile
} from '@src/service'
import {
  loadLegends,
  loadMembers,
  loadOrganizationHoldings,
  loadPlan,
  loadSecurities,
  loadVestings
} from '@helpers/loaders'
import { HorizontalVestingChart } from '../components/vesting-chart'
import { OptionForm } from '../components/option-form'
import { BaseLinkProps } from '@shared/components/navigation'
import _ from 'lodash'
import { normalizeDate } from '@shared/helpers'
import { filterEquities, filterIncentives, filterPools } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { QuestionSpan, NoteSpan } from '../components/plan-form'
import { HoldingRecordType } from '@shared/helpers/holdings'

type Props = PageProps &
  PermissionProps &
  MembersProps &
  GetLegendsResponse &
  ExpandedHoldingsResponse &
  GetSecuritiesResponse &
  GetVestingsResponse &
  GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(
  loadPermissions,
  loadMembers,
  loadLegends(true),
  loadOrganizationHoldings('all'),
  loadSecurities(),
  loadVestings,
  loadPlan
)

export const CreateOption = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const {
      httpClient,
      navigate,
      params,
      members,
      legends,
      holdings,
      securities,
      vestingSchedule,
      plan } = props
    const { organization } = params

    const securitiesList = filterEquities([], securities, true)
    const incentivesList = filterIncentives(securitiesList, plan)
    const planPools = filterPools(holdings, incentivesList, params?.plan)
    const vestings = vestingSchedule && vestingSchedule.length > 0 ? vestingSchedule : []

    const initialValues: any = {}
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const createNewOption = async (values: any) => {
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const currentPool = planPools?.find(pool => pool.id == values?.pool)
      const currentIncentive = incentivesList?.find(incentive => (currentPool?.parent == incentive.id) ||
        (currentPool?.parent == incentive.hash))

      const request = {
        parent: values?.pool!,
        issueDate: values?.issueDate!,
        startDate: values?.startDate!,
        owner: values?.member!,
        value: values?.startingValue!,
        legend: values?.legend!,
        startingValue: values?.startingValue!,
        entity: organization,
        vestingSchedule: values?.vestingSchedule!,
        issuanceAgreement: values?.issuanceAgreement ? values?.issuanceAgreement.id : undefined,
        holdingType: HoldingRecordType.option,
        internalType: currentIncentive ? currentIncentive.type : '',
        class: currentIncentive ? currentIncentive.shareClassPrefix : '',
      } as NewHoldingRequest

      const newOption = await createHolding(httpClient)({}, request)
      if (newOption) {
        const option = newOption.hash!
        modifyAuthorizations({ approvalDocuments, target: option, httpClient, organization })
        navigate(optionsPath, { organization, plan: params.plan })
      } else console.log('Create Option error')
    }

    const VestingChartWrapper: Sticky<any> = props => {
      const { values } = props
      const vestingSchedule = vestings?.find(v => v.hash === values?.vestingSchedule)
      const custom = vestingSchedule ? Object.keys(vestingSchedule).includes('events') : false
      const events = custom ?
        vestingSchedule.isValueAbsolute ?
          vestingSchedule?.events?.map(event => {
            const date = new Date(`${event.month}/${event.day}/${event.year}`)
            return { ...event, date }
          })?.sort((a, b) => a.date && b.date && a.date.getTime() - b.date.getTime())
          :
          vestingSchedule?.events?.map(event => ({ ...event, rValue: event.value * 100 }))
            ?.sort((a, b) => a.month - b.month)
        : []
      const isAbsolute = custom ? vestingSchedule.isValueAbsolute : false
      return <>
        <PageContentHeader title='Create New Holding' parent={parent} />
        <QuestionSpan>What is a holding?</QuestionSpan>
        <NoteSpan>A holding is the issuance of the incentive to the holder.
          This is where it all comes together.
          Enter all of your specific Holding’s details here.
        </NoteSpan>
        {vestingSchedule ? <HorizontalVestingChart
          vestingSchedule={custom ? { ...vestingSchedule, events } : vestingSchedule}
          value={values?.startingValue}
          custom={custom}
          startDate={values?.startDate && !isAbsolute ? normalizeDate(values.startDate) : undefined}
          dontNotify={true}
          noMarginBottom={true}
        /> : <div />}
      </>
    }
    const parent: BaseLinkProps = { path: planPath, args: { organization, plan: params?.plan } }

    return (
      <PageContent>
        <CommonForm
          mutators={{ ...arrayMutators }}
          onSubmit={createNewOption}
          initialValues={initialValues}
          sticky={VestingChartWrapper}
          stickyOnTop={true}
          submitText='Save'
        >
          <OptionForm
            members={members}
            pools={planPools}
            legends={legends}
            onUpload={onUpload}
            securities={securities!}
            vestings={vestings}
            params={params} />
        </CommonForm>
      </PageContent>
    )
  }))
