import { LoadData } from '@components/loading'
import {
  MemberLimitProps,
  MemberProps,
  MembersProps,
  OrganizationsProps,
  PageProps,
  ShareholdingFormInfoProps,
  ShareholdingProps,
  ShareholdingsProps,
  SiteStatsResponse,
  GetBlocksResponse,
  PaymentProfilesResponse,
  GetSubscriptionsResponse,
  TransactionsResponse,
  GetOrganizationFeaturesResponse,
  EntityProps,
  GetReferralDataResponse,
  InvitedFriendsResponse,
  GetScratchCardResponse,
  ExpansionMethod,
  GetOrgReferralDataResponse,
  Organization,
  GetOrganizationsResponse,
} from '@logic'
import {
  getMembers, getOrganization,
  GetPlansResponse,
  projectSchedule,
  ProjectVestingScheduleProps,
  ProjectVestingScheduleRequest,
  WebResponse,
  getPaymentProfiles,
  getSubscriptions,
  getBillingHistory,
  getPricePoints,
  getHoldings,
  getHolding,
  ExpandedHoldingsResponse,
  ExpandedHoldingResponse,
  newGetFunction,
  getTasks,
  getUserState,
  getRoles,
  getRole,
  GetRolesResponse,
  getOrganizationStats,
  GetOrganizationStatsResponse,
  AddressUrlProps,
  getAddresses,
  getAddress,
  GetAddressesResponse,
  GetAddressResponse,
  getEmails,
  GetEmailsResponse,
  getOrgRewardsData,
  getOrganizations,
  getOrganizationPermissions
} from '@src/service'
import {
  getShareholding,
  getShareholdings,
  GetPoolsResponse,
  GetMemberOptionsResponse,
  GetSecuritiesResponse,
  getSecurities,
  GetLegendResponse,
  getLegends,
  GetLegendsResponse,
  getLegend,
  getBlocks,
  GetSecurityTypeResponse,
  getSecurityType,
  GetOptionsResponse,
  GetAuthorizationsResponse,
  getQueryData,
  QueryResponse,
  MultiQueryRequest,
  GetPlanResponse,
  GetVestingsResponse,
  GetVestingResponse,
  GetIncentiveDocumentsResponse,
  getCaptableData,
  getCaptablePools,
  GetPoolsReportResponse,
  GetCaptableDashboardResponse,
} from '@src/service/services/captable'
import {
  getUserCoupon,
  previewSubscriptionPurchase,
  PurchasePreviewProps,
  PurchaseRequest,
  UserCouponProps,
} from '@src/service/services/billing'
import {
  getPlans,
  getPools,
  getMemberOptions,
  getOrganizationOptions,
  getAuthorizations,
  getPlan,
  getVestings,
  getVesting,
  getIncentiveDocuments,
  getPlanDashboard
} from '@src/service/services/captable/options/reading'
import { MinimalServiceProps } from '@shared/hocs/with-service'
import {
  getAccount,
  getAllScratchCards,
  getColor,
  GetColorResponse,
  getColors,
  GetColorsResponse,
  getInvitedFriends,
  getProfile,
  getReferralsData,
  GetTransactionsHistoryResponse,
  getTransactionsHistory,
  getPortfolioData
} from '@src/service/services/profile'
import { Hash } from './util'
import { holdingsUrl } from '@src/service/services/captable/urls'
import { PermissionProps } from '@shared/components/permissions'
import { getENS, getSupportedProtocols, getWalletAddress, getWalletAddresses } from '@src/service/services/wallet'

export const loadOrganizations: LoadData<GetOrganizationsResponse | undefined> = ({ httpClient }) => {
  return getOrganizations(httpClient)({})
}

export const loadSiteStats: LoadData<SiteStatsResponse> = ({ serviceOld }) => {
  return serviceOld.getSiteStats()
}

export const loadShareholdings: LoadData<ShareholdingsProps> = ({ service, params }) => {
  return getShareholdings(service)(params.organization, params.block === "current" ? undefined : params.block)
}

export const loadShareholding: LoadData<ShareholdingProps> = ({ service, params }) => {
  return getShareholding(service)(params.shareholding)
}

export const loadMembers: LoadData<MembersProps> = ({ service, params }) => {
  return getMembers(service)(params.organization)
}

export const loadMember: LoadData<MemberProps> = ({ serviceOld, params }) => {
  return serviceOld.getOrganizationMember(params.organization, params.member)
}

export const loadShareholdingFormConfig: LoadData<ShareholdingFormInfoProps> = async ({ serviceOld, params }) => {
  const shareholdingFormInfo = await serviceOld.getShareholdingFormConfig(params.organization)
  return {
    shareholdingFormInfo,
  }
}

export const loadOrganizationOld: LoadData<any> = async ({ serviceOld, params }) => {
  const response = await serviceOld.getOrganization(params.organization)
  const organization = response
  return { organization }
}

export const loadOrganization: LoadData<any> = async ({ httpClient, params }) => {
  const { organization } = params
  return params.organization ? await getOrganization(httpClient)({ organization }) : undefined
}

export const loadOrganizationStats: LoadData<GetOrganizationStatsResponse | undefined> = async ({ httpClient, params }) => {
  const { organization } = params
  return await getOrganizationStats(httpClient)({ organization })
}

export const loadPaymentInfo: LoadData<any> = async ({ serviceOld, params }) => {
  const response = await serviceOld.getPaymentInfo(params.organization)
  const subscriptions = response.subscriptions && response.subscriptions.length ? response.subscriptions : null // TODO fix hack to push to prod and get around auto toast error
  return { subscriptions }
}

export const loadMemberLimit: LoadData<MemberLimitProps> = ({ serviceOld, params }) => {
  return serviceOld.getOrganizationMemberLimit(params.organization)
}

export type LoadSubscriptionPreviewProps = PageProps & { previewRequest?: PurchaseRequest }
export const loadSubscriptionPreview: LoadData<WebResponse<PurchasePreviewProps>, LoadSubscriptionPreviewProps> = ({
  service,
  previewRequest,
  params,
}) => {
  return previewSubscriptionPurchase(service)(previewRequest!, params.organization)
}

export const loadUserCoupon: LoadData<WebResponse<UserCouponProps>> = ({ service }) => {
  return getUserCoupon(service)
}

export const loadPlans: LoadData<WebResponse<GetPlansResponse | undefined>> = ({ service, params }) => {
  const { organization } = params
  return getPlans(service)({ organization })
}

export const loadPlan: LoadData<GetPlanResponse | undefined> = ({ service, params }) => {
  const { plan } = params
  return getPlan(service)({ plan })
}

export const loadPlanDashboard: LoadData<{} | undefined> = ({ httpClient, params }) => {
  const { plan } = params
  return getPlanDashboard(httpClient)({ plan })
}

export const loadVestings: LoadData<GetVestingsResponse | undefined> = ({ httpClient, params }) => {
  return getVestings(params.plan)(httpClient)({})
}

export const loadVesting: LoadData<GetVestingResponse | undefined> = ({ httpClient, params }) => {
  const { vesting, plan } = params
  return getVesting(plan)(httpClient)({ vestingSchedule: vesting })
}

export const loadIncentiveDocuments: LoadData<GetIncentiveDocumentsResponse | undefined> = ({ httpClient, params }) => {
  const { plan } = params
  return getIncentiveDocuments(plan)(httpClient)({})
}

export const loadPools: LoadData<WebResponse<GetPoolsResponse | undefined>> = ({ service, params }) => {
  const { plan } = params
  return getPools(service)({ plan })
}

export const loadPool: LoadData<ExpandedHoldingResponse | undefined> = ({ httpClient, params }) => {
  const { pool } = params
  return getHolding(httpClient)({ holding: pool })
}

export const loadMemberOptions: LoadData<GetMemberOptionsResponse | undefined> = ({ service, params }) => {
  const { member } = params
  return getMemberOptions(service)({ member })
}

export type LoadVestingScheduleProjection = LoadData<ProjectVestingScheduleProps, MinimalServiceProps & ProjectVestingScheduleRequest>

export const loadVestingScheduleProjection: LoadVestingScheduleProjection = async props => {
  const { service, vestingSchedule, shares } = props
  return (await projectSchedule(service)({}, { vestingSchedule, shares })) || { samples: new Array<number>() }
}

export const loadSecurities: (stats?: string) => LoadData<GetSecuritiesResponse | undefined> = stats =>
  ({ httpClient, params }) => {
    const { organization } = params
    return getSecurities(stats)(httpClient)({ organization })
  }

export const loadSecurity: (key?: string) => LoadData<GetSecurityTypeResponse | undefined> = key => ({ httpClient, params }) => {
  const { organization } = params
  const security = key ? params[key] : params.security
  return getSecurityType(httpClient)({ organization, security })
}

export const loadLegends: (deleted: boolean) => LoadData<GetLegendsResponse | undefined> = deleted => ({ httpClient, params }) => {
  const { organization } = params
  return getLegends(deleted)(httpClient)({ organization })
}

export const loadLegend: LoadData<GetLegendResponse | undefined> = ({ httpClient, params }) => {
  const { organization, legend } = params
  return getLegend(httpClient)({ organization, legend })
}

export const loadBlocks: LoadData<GetBlocksResponse | undefined> = ({ httpClient, params }) => {
  const { organization } = params
  return getBlocks(httpClient)({ organization })
}

export const loadPaymentProfiles: LoadData<PaymentProfilesResponse | undefined> = async ({ httpClient, params, user }) => {
  const { organization } = params
  const entity = organization ? organization : user?.id
  const response = await getPaymentProfiles(httpClient)({ entity })
  if (response && response!.paymentProfiles!.length > 0) {
    const profiles: any = response.paymentProfiles.reverse()
    return { paymentProfiles: profiles }
  }
  else return { paymentProfiles: [] }
}

export const loadSubscriptions: LoadData<GetSubscriptionsResponse | undefined> = ({ httpClient, params }) => {
  const { organization } = params
  return getSubscriptions(httpClient)({ organization })
}

export const loadRoles: LoadData<GetRolesResponse | undefined> = ({ httpClient, params }) => {
  const { organization } = params
  return getRoles(httpClient)({ organization })
}

export const loadRole: LoadData<GetRolesResponse | undefined> = ({ httpClient, params, queryParams }) => {
  const { organization } = params
  const roleId = params?.role || queryParams?.clone
  return roleId ? getRole(httpClient)({ organization, role: roleId }) : undefined
}

export const loadBillingHistory: LoadData<TransactionsResponse | undefined> = ({ httpClient, params }) => {
  const { organization } = params
  return getBillingHistory(httpClient)({ organization })
}

export const loadPricePoints: LoadData<TransactionsResponse | undefined> = ({ httpClient, params }) => {
  const { organization } = params
  return getPricePoints(httpClient)({ organization })
}

export const loadOption: LoadData<ExpandedHoldingResponse | undefined> = ({ httpClient, params }) => {
  const { option } = params
  return getHolding(httpClient)({ holding: option })
}

export const loadOrganizationOptions: LoadData<GetOptionsResponse | undefined> = ({ httpClient, params, queryParams }) => {
  const { organization } = params
  const { block } = queryParams
  return getOrganizationOptions(block)(httpClient)({ organization })
}

export const loadPortfolioData: (key: string) => LoadData<{} | undefined> = key =>
  async ({ httpClient, user, params }) => {
    const forUser = key == 'user'
    const id = forUser ? user?.id : params[key]

    if (id) {
      let addHoldings = [] as any
      const getUserHoldings = (user: Hash) =>
        newGetFunction<{}, ExpandedHoldingsResponse>(`${holdingsUrl}?owner=${user}&expansions=all&voided=false`)
      const userHoldings = await getUserHoldings(id)(httpClient)({}) // bottom table
      if (userHoldings?.holdings!) {
        const onlyAssets = userHoldings.holdings.filter(h => !h.plan && !h.convertibleInstrument)
        addHoldings = addHoldings.concat(onlyAssets)
      }

      const membersResponse = await getPortfolioData(httpClient)({ entity: id })
      if (membersResponse?.memberRecords && membersResponse?.memberRecords?.length > 0) {
        const members = membersResponse.memberRecords.map((member: any) => {
          let holdings: any[] = []

          const filteredHoldings = member.holdings?.filter(h => h.owner === member.id)
          const instrumentsAndPools = member.holdings?.filter(h =>
            h.owner === member.organization)?.map(v => ({ ...v, notInRow: true }))
          holdings = holdings.concat(filteredHoldings)
          holdings = holdings.concat(instrumentsAndPools)
          return { ...member, holdings }
        })
        return { members, holdings: addHoldings, securities: membersResponse.securities }
      } else return { holdings: addHoldings }
    }
    else return {}
  }

export const loadAuthorizations: (key: string) => LoadData<GetAuthorizationsResponse | undefined> = key => ({ httpClient, params }) => {
  return getAuthorizations(params[key])(httpClient)({})
}

export const loadHoldings: (key: string) => (expansions: string) => LoadData<ExpandedHoldingsResponse | undefined> =
  key => expansions => ({ httpClient, params }) => {
    const block = params.block === 'current' ? undefined : params.block
    return getHoldings(params[key], expansions, block)(httpClient)({})
  }

export const loadOrganizationHoldings = loadHoldings('organization')

export const loadHolding: LoadData<ExpandedHoldingResponse | undefined> = ({ httpClient, params }) => {
  const key = params?.shareholding! || params?.option! || params?.pool! || params?.note! || params?.holding!
  return getHolding(key)(httpClient)({})
}

export const loadColors: (key: string) => LoadData<GetColorsResponse | undefined> = key => ({ httpClient, user, params }) => {
  const userId = key == 'user' ? user?.id! : params.organization
  return getColors(httpClient)({ user: userId })
}

export const loadColor: (key: string) => LoadData<GetColorResponse | undefined> = key => ({ httpClient, params, user }) => {
  const userId = user?.id!
  return getColor(httpClient)({ user: userId, target: params[key] })
}

export const loadFeatures: LoadData<GetOrganizationFeaturesResponse | undefined> = ({ serviceOld, params }) => {
  const { organization } = params
  return serviceOld.getFeatures(organization)
}

export const loadProfile: (id?: string) => LoadData<{} | undefined> = id => ({ httpClient, user }) => {
  const userId = id ? id : user?.id!
  return getProfile(httpClient)({ profile: userId })
}

export const loadAccount: LoadData<{} | undefined> = ({ httpClient, user }) => {
  const userId = user?.id!
  return getAccount(httpClient)({ user: userId })
}

export const loadUserState: LoadData<{} | undefined> = ({ httpClient }) => {
  return getUserState(httpClient)({})
}

export const loadChecklistPermissions: (user: any) => LoadData<PermissionProps | undefined> = user => ({ httpClient }) => {
  const org = window.location?.pathname?.split('/')[2]!
  return org?.includes('-') ? getOrganizationPermissions(httpClient)({
    organization: org,
    user: user.id
  }) : undefined
}

export const loadAddresses: (key: string) => LoadData<GetAddressesResponse | undefined> = key => ({ httpClient, params, user }) => {
  const entity = params[key] || user?.id
  return getAddresses(httpClient)({ entity })
}

// export const loadAddress: (address: any) => LoadData<GetAddressResponse | undefined> = address => ({ httpClient }) => {
//   const entity = params?.organization || user?.id
//   return getAddress(httpClient)({ entity, address })
// }

export const loadEmails: (key: string) => LoadData<GetEmailsResponse | undefined> = key => ({ httpClient, params, user }) => {
  const entity = params[key] || user?.id
  return getEmails(httpClient)({ entity })
}

export const loadReferralsData: LoadData<GetReferralDataResponse | undefined> = ({ httpClient, params, user }) => {
  const entity = params?.organization || user?.id
  return getReferralsData(httpClient)({ entity })
}

export const loadInvitedFirends: LoadData<InvitedFriendsResponse | undefined> = ({ httpClient, params, user }) => {
  return getInvitedFriends(params.organization)(httpClient)({})
}

export const loadAllScratchCards: (key?: string) => LoadData<GetScratchCardResponse | undefined> = key => ({ httpClient, params }) => {
  const id = key ? params[key] : undefined
  return getAllScratchCards(id)(httpClient)({})
}

export const loadOrgRewardsData: LoadData<GetOrgReferralDataResponse | undefined> = ({ httpClient, params, user }) => {
  return getOrgRewardsData(user?.id)(httpClient)({ entity: params.organization })
}

export const loadQueryData: LoadData<QueryResponse | undefined> =
  ({ httpClient, params }) => {
    const block = params.block === 'current' ? undefined : params.block
    const queryRequest: MultiQueryRequest = {
      queries: [{
        type: 'holdingsReport',
        expansions: [],
        filters: [{ path: 'entity', operator: '=', value: params?.organization }],
        voided: 'false'
      }]
    }
    return getQueryData(httpClient)({}, queryRequest)
  }

export const loadCaptableData: (voided: string, expansions?: string) => LoadData<GetCaptableDashboardResponse | undefined> = (voided, expansions) =>
  ({ httpClient, params }) => {
    const organization = params?.organization
    const block = params.block == 'current' ? undefined : params.block
    return getCaptableData(voided, expansions, block)(httpClient)({ organization })
  }

export const loadTransactionsHistory: LoadData<GetTransactionsHistoryResponse | undefined> = ({ httpClient, params, user }) => {
  const entity = params.organization || user?.id
  return getTransactionsHistory(httpClient)({ entity })
}

export const loadWalletAddresses: LoadData<{} | undefined> = ({ httpClient, user }) => {
  const entity = user?.id || ''
  return getWalletAddresses(httpClient)({ entity })
}

export const loadWalletAddress: LoadData<{} | undefined> = ({ httpClient, params, user }) => {
  const entity = params.organization || user?.id
  return getWalletAddress(httpClient)({ entity })
}

export const loadCaptablePools: LoadData<GetPoolsReportResponse | undefined> = ({ httpClient, params }) => {
  const organization = params.organization
  return getCaptablePools(httpClient)({ organization })
}

export const loadSupportedProtocols: LoadData<{} | undefined> = async ({ httpClient, params }) => {
  const address = params.address
  const res = address ? await getSupportedProtocols(httpClient)({ address }) : undefined
  return { supportedProtocols: res }
}

export const loadENS: LoadData<{} | undefined> = async ({ httpClient, params }) => {
  const address = params.address
  const res = address ? await getENS(httpClient)({ address }) : undefined
  return { ens: res }
}
