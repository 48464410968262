import * as React from 'react'
import { PageContent } from '@components/pages'
import { organizationMemberPath, PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding } from '@helpers/loaders'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import { CertificateView } from '@modules/captable/components/certificate-view'
import { getHoldingHashOrId } from '@helpers/util'
import { ExpandedHoldingResponse, GetSecuritiesResponse } from '@src/service'

type Props = PageProps & ExpandedHoldingResponse & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding)

export const MemberCertificatePage = withData((props: Props) => {
  const { holding } = props
  const { member, organization } = props.params

  const certificateNumber = holding?.name
  const parent: BaseLinkProps = { path: organizationMemberPath, args: { member, organization } }
  return (
    <PageContent>
      <PageContentHeader title={`Share Certificate ${certificateNumber}`} parent={parent} />
      <CertificateView shareholding={getHoldingHashOrId(holding!)} />
    </PageContent>
  )
})
