import * as React from 'react'
import {
    User,
    newHoldingPath,
    editHoldingPath,
    captablePath,
    captableCertificatePath,
    portfolioPath,
    transferShareholdingPath,
    Member,
    newOrgHoldingPath,
    editOrgHoldingPath,
    guestPortfolioPath,
    guestWalletPortfolioPath,
    walletPortfolioPath,
    walletAddressBookPath,
    guestWalletAddressBookPath,
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { PathLinkButton, SolidButton } from '@components/button/button'
import {
    FlexRow,
    whiteColor,
    SmallHeader,
    StyledCertificateImage,
    FlexCol,
    fontSizes,
    screenSizes,
    semiBoldStyle
} from '@src/styles'
import { Navigate, overflowParams, Params, PathLink } from '@components/navigation'
import { CellProps } from 'react-table'
import { RowsState, SortRowsState, Table, TableRowProps } from '@components/tables/table'
import { ellipsesColumn } from '@components/tables/ellipses'
import {
    formatCurrency,
    formatDateString,
    formatSecurityAbbreviation,
    getHoldingHashOrId,
    getQueryParams,
    getTotals,
    percentageString,
    safeDivide,
    toCommaFloat
} from '@shared/helpers'
import styled from 'styled-components'
import { search } from '@shared/components/search'
import { PanelGrid, SimplePanel } from '@shared/components/panels/panels'
import { RainbowBar } from '@modules/captable/components/rainbow-bar'
import { deleteHolding, ExpandedHolding, ExpandedSecurity, getENS, Holding, HttpClient } from '@src/service'
import { newColor, NewColorRequest } from '@src/service/services/profile'
import { CertificateRow, RainbowChips } from '@modules/captable/components'
import { getHoldingsTotals } from '@shared/helpers/holdings'
import { advancedTableRow, expanderColumn } from '@shared/components/tables'
// @ts-ignore
import CertificateImage from '@image/certificate-image.jpg'
import { colorPicker } from '@shared/components/color-picker'
import { deletePlanModal } from '@modules/documents/components/modals'
import { optionalUsdString } from '@modules/convertibles/components'
import { optionalInterestFromConvertible } from '@modules/convertibles/utility'
import { colorPickerModal } from '@modules/profile/components/profile-modals'
import { isHolding } from '@modules/captable/utility'
import MoneyBag from '@image/money-bag.svg'
import { getAddressData } from '@modules/profile/utility'
import { customPortfolioSearch } from './custom-portfolio-search'

interface Props {
    navigate: Navigate
    httpClient: HttpClient
    user?: User
    members?: Member[]
    holdings?: ExpandedHolding[]
    securities?: ExpandedSecurity[]
    colors?: { [key: string]: string }
    reload?: any
    forOrganization?: boolean
    forGuest?: boolean
    openSignUp?: () => void
    title: string
    hideCrumbs?: boolean
    params: Params
}

const TableDescription = styled.span<any>`
    ${semiBoldStyle}
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
`

const TableInfo = styled.span<any>`
    font-size: 14px;
    line-height: 19px;
`

const StyledSpan = styled.span<any>`
    color: ${props => props.theme.white};
`

const CertificateSpan = styled.span`
    color: ${props => props.theme.label};
    font-size: ${fontSizes.XXS};
`
const AddressSpan = styled.span<any>`
    cursor: pointer;
`

const PortfolioWrapper = styled.div`
  ${whiteColor}
  .value {
    font-size: 38px;
    line-height: 52px;
  }

`

const StyledWorth = styled<any>(FlexRow)`
    margin: 64px 0 24px 0;
    align-items: flex-end;
    .worth {
        ${semiBoldStyle}
        font-size: 48px;
        line-height: 54px;
        margin-left: 24px;
    }
    .netWorth {
        font-size: 20px;
        line-height: 28px;
        margin-left: 12px;
        color: #9CA6AD;
    }

    svg {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }
`

const StyledTitle = styled<any>(SmallHeader)`
  margin-bottom: 24px;
  line-height: 32px;
  ${semiBoldStyle}
`

const StyledHeader = styled<any>(FlexRow)`
    div:last-child {
       margin-right:  ${props => props.forGuest ? '0' : '15px'}
    }
    @media(max-width: ${screenSizes.XS}px) {
        width:100%;
        flex-direction: column;
        align-items: start;
        div:last-child {
            margin-right: 0;
        }
    }
`
const colorsList = ['#F29D4A', '#EB5757', '#9B51E0', '#56CCF2', '#27AE60', '#F2C94C', '#2F80ED', '#FFFFFF']

export const CommonPortfolio = (props: Props) => {
    const [chartValues, setChartValues] = React.useState<any[]>([])
    const [sorts, setSorts] = React.useState<SortRowsState>([{ id: 'totals.current', desc: true }])
    const [lowerSorts, setLowerSorts] = React.useState<SortRowsState>([{ id: 'totals.current', desc: true }])
    const [selected, setSelected] = React.useState<string>('')
    const [defaultColor, setDefaultColor] = React.useState<string>('')
    const [selectedHolding, setSelectedHolding] = React.useState<string>('')
    const [selectedRows, setSelectedRows] = React.useState<RowsState>({})
    const [selectedAssetRows, setSelectedAssetRows] = React.useState<RowsState>({})
    const [addressData, setAddressData] = React.useState<any>([])

    const [address, setAddress] = React.useState<string>('')
    const [ensValue, setEnsValue] = React.useState<any>('')

    const { ethereum } = window as any

    const {
        navigate,
        httpClient,
        user,
        members,
        holdings,
        securities,
        colors,
        reload,
        forOrganization,
        title,
        hideCrumbs,
        params,
        forGuest,
        openSignUp } = props
    const {
        walletData,
        savingsData,
        claimData,
        debtData,
        vaultData } = getAddressData(addressData, address)

    const queryParams = getQueryParams()
    const isAddress = address?.match(/^0x[a-fA-F0-9]{40}$/)
    const name = address === ensValue ?
        ensValue.slice(0, 6) + '...' + ensValue.slice(ensValue.length - 4, ensValue.length) : ensValue

    const colorsData = colors ? colors : {}

    const holdingsList = holdings && holdings.length > 0 ? holdings.map(holding => {
        const investment = holding.value! * holding.originalPricePerUnit!
        const current = holding.value! * holding.pricePerUnit!
        return {
            ...holding,
            color: `#${colorsData[holding?.id!]!}`,
            investment,
            current,
            return: current - investment,
        }
    }) : []
    const [tableAssets, setTableAssets] = React.useState<any>(holdingsList)

    const shareholdingsList = members && members.length > 0 ? members : []
    const totals = getHoldingsTotals(tableAssets) as any
    const investmentTotal = (holdings: any) => holdings?.reduce((a, b) => a + b.totals.investment, 0)
    const valueTotal = (holdings: any) => holdings?.reduce((a, b) => a + b.totals.current, 0)
    const quantityTotal = (holdings: any) => holdings?.reduce((a, b) => a + b.totals.quantity, 0)
    const priceTotal = (holdings: any) => holdings?.reduce((a, b) => a + b.totals.price, 0)
    const returnTotal = (holdings: any) => holdings?.reduce((a, b) => a + b.totals.return, 0)

    const walletTotal = walletData.reduce((a, b) => a + b.value, 0)
    const savingsTotal = savingsData.reduce((a, b) => a + b.value, 0)
    const claimTotal = claimData.reduce((a, b) => a + b.value, 0)
    const debtTotal = debtData.reduce((a, b) => a + b.value, 0)
    const vaultTotal = vaultData.reduce((a, b) => a + b.value, 0)
    const netWorth = walletTotal + savingsTotal + claimTotal + vaultTotal - debtTotal

    const walletAssetsData = address ? [
        { name: name || '', address: address || '', networks: ['Ethereum', 'Polygon', 'BSC'], netWorth: netWorth || 0 }
    ] : []

    const tableData = () => {
        let list = [] as any
        securities && securities.length > 0 && securities.forEach(security => {
            return shareholdingsList.length > 0 ? shareholdingsList?.map(holding => {
                let data: any[] = []
                const shareholdings = holding?.holdings?.length > 0 ?
                    holding.holdings.filter(el => el.equity === security.hash && !el.notInRow && !isHolding(el.holdingType)) : []

                if (shareholdings?.length > 0) {
                    const shareholdingWithValues = shareholdings.map(el => {
                        const price = safeDivide(el.capitalContribution, el.value)
                        const originalValue = el.value / 1
                        const value = originalValue - el.value!
                        return { ...el, current: el.capitalContribution, price, return: value, shares: el.value }
                    })
                    data = data.concat(shareholdingWithValues)
                }
                const allHoldings = holding?.holdings!
                let optionsAndNotes: Holding[] = []
                const parentHoldings = allHoldings?.filter(h => (h.convertibleInstrument || h.plan) && h.parent === security.hash)

                if (parentHoldings?.length > 0) {
                    parentHoldings.forEach(parent => {
                        if (parent.convertibleInstrument) {
                            const child = allHoldings?.filter(h => h.parent === parent.id)?.map(h => ({ ...h, isNote: true }))
                            if (child.length > 0) optionsAndNotes = optionsAndNotes.concat(child)
                        }
                        else {
                            const child = allHoldings?.filter(h => h.parent === parent.id)?.map(h => ({ ...h, isNote: false }))
                            if (child.length > 0) optionsAndNotes = optionsAndNotes.concat(child)
                        }
                    })
                }
                if (optionsAndNotes.length > 0) {
                    const withValues = optionsAndNotes.map(el => {
                        if (el.isNote) {
                            const parent = allHoldings.find(h => el.parent === h.id)
                            const convertibleInstrument = parent?.convertibleInstrument!
                            const currentInterest = optionalInterestFromConvertible(convertibleInstrument, el.issueDate, new Date(), el.capitalContribution, true) || 0
                            const current = el.capitalContribution + currentInterest
                            const price = (current) / 1
                            const originalValue = current * 1
                            const returnValue = originalValue - el.capitalContribution!
                            return { ...el, current, price, return: returnValue, shares: 1 }
                        }
                        else {

                            const price = safeDivide(el.capitalContribution, el.value)
                            const originalValue = el.value / 1
                            const value = originalValue - el.value!
                            return { ...el, current: el.capitalContribution, price, return: value, shares: 1 }
                        }
                    })
                    data = data.concat(withValues)
                }

                if (data?.length > 0) {
                    const total = getTotals(data)
                    const price = data.reduce((a, b) => a + b.price, 0)
                    const value = data.reduce((a, b) => a + b.return, 0)
                    const quantity = data.reduce((a, b) => a + b.shares, 0)
                    const investmentTotal = data.reduce((a, b) => a + b.current, 0)
                    const totals = {
                        value: total.all,
                        investment: total.capitalContribution,
                        current: investmentTotal,
                        return: value,
                        price,
                        quantity,
                    }
                    const color = security.id ? colorsData[security.id] ? `#${colorsData[security.id]}` : ''
                        : colorsData[security.hash] ? `#${colorsData[security.hash]}` : ''
                    list = list.concat({ ...holding, shareholdings: data, security, totals, color })
                }
            })
                : security
        })
        const data = list.length > 0 ? list.map((el, i: number) => {
            if (el.color?.length == 0) {
                return { ...el, subRows: [...el.shareholdings], color: colorsList[i % colorsList.length] }
            } else {
                return { ...el, subRows: [...el.shareholdings] }
            }
        })
            .sort((a, b) => getTotals(b.shareholdings).capitalContribution - getTotals(a.shareholdings).capitalContribution)
            : list
        return data
    }

    React.useEffect(() => {
        if (address && isAddress) {
            var data: any = []
            const eventSource = new EventSource(
                `//api.zapper.fi/v1/balances?addresses%5B0%5D=${address}&nonNilOnly=true&networks%5B0%5D=ethereum&networks%5B1%5D=polygon&networks%5B2%5D=binance-smart-chain&api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241`
                , { withCredentials: true }
            )
            eventSource.addEventListener("start", (event: any) => {
            })
            eventSource.addEventListener("balance", (event: any) => {
                data.push(JSON.parse(event.data))
            })
            eventSource.addEventListener("end", () => {
                setAddressData(data)
                eventSource.close()
            })
        }
        return () => {
            setAddressData([])
        }
    }, [address])


    React.useEffect(() => {
        ; (async () => {
            if (address && isAddress) {
                const response: any = await getENS(httpClient)({ address: address })
                if (response && response[0] !== null) setEnsValue(response[0])
                else {
                    setEnsValue(address)
                }
            }
        })()
    }, [address])

    React.useEffect(() => {
        ; (async () => {
            const accounts = await ethereum.request({ method: 'eth_accounts' })
            if (accounts[0]) {
                setAddress(accounts[0])
            }
        })()
    }, [ethereum])

    React.useEffect(() => {
        if (queryParams.address) setAddress(queryParams.address)
    }, [queryParams])

    const [tableHoldings, setTableHoldings] = React.useState<any>(tableData())
    const totalInvestment = totals.investment! + tableHoldings?.reduce((a, b) => a + b.totals.investment, 0)!
    const totalInvestmentInUsd = optionalUsdString(totalInvestment, 2)
    const totalCurrentValue = totals.currentValue! + tableHoldings?.reduce((a, b) => a + b.totals.current, 0)!
    const totalCurrentValueInUsd = optionalUsdString(totalCurrentValue, 2)
    const lifetimeGrowth = totalCurrentValue - totalInvestment
    const lifetimeGrowthInUsd = optionalUsdString(lifetimeGrowth, 2)
    const lifetimePercentage = percentageString(totalCurrentValue, totalInvestment)
    const columns = (totalInvestmentInUsd!.length > 11 || totalCurrentValueInUsd!.length > 11 || lifetimeGrowthInUsd!.length > 11) ? 2 : 4
    const totalNetWorth = totalCurrentValue + netWorth

    const getHoldingsList = (value: any) => {
        if (value) {
            const upperData = tableHoldings?.filter((arg: any) => arg.org.name.toLowerCase().includes(value.toLowerCase()))
            const upper = upperData.map(shareholding => ({
                ...shareholding,
                footer: {
                    quantity: quantityTotal(upperData)!,
                    investment: investmentTotal(upperData)!,
                    value: valueTotal(upperData)!,
                    price: priceTotal(upperData)!,
                    return: returnTotal(upperData)!,
                }
            }))
            const data = tableAssets?.filter(arg => {
                return arg.name.toLowerCase().includes(value.toLowerCase()) || arg.tickerSymbol?.toLowerCase().includes(value.toLowerCase())
            }
            )
            const holdingsTotals = getHoldingsTotals(data) as any
            const lower = data.map(holding => ({
                ...holding,
                totals: {
                    value: holdingsTotals.quantity,
                    investment: holdingsTotals.investment,
                    current: holdingsTotals.currentValue,
                    price: holdingsTotals.price,
                    return: holdingsTotals.return,
                }
            }))
            return { upper, lower }
        }
        else {
            const holdingsTotals = getHoldingsTotals(tableAssets) as any
            const upperData = tableHoldings
            const upper = upperData.map(shareholding => ({
                ...shareholding,
                footer: {
                    quantity: quantityTotal(upperData)!,
                    investment: investmentTotal(upperData)!,
                    value: valueTotal(upperData)!,
                    price: priceTotal(upperData)!,
                    return: returnTotal(upperData)!,
                }
            }))
            const lower = tableAssets.map(holding => ({
                ...holding,
                totals: {
                    value: holdingsTotals.quantity,
                    investment: holdingsTotals.investment,
                    current: holdingsTotals.currentValue,
                    price: holdingsTotals.price,
                    return: holdingsTotals.return,
                }
            }))
            return { upper, lower }
        }
    }

    const searchBundle = customPortfolioSearch('Search Organizations and Assets...', navigate, true, forGuest)
    const Search = searchBundle.component
    const inputValue = searchBundle.inputValue
    const isInputAddress = inputValue?.match(/^0x[a-fA-F0-9]{40}$/)
    const data = getHoldingsList(isInputAddress ? '' : inputValue)

    const colorPickerBundle = colorPicker(defaultColor)
    const ColorPicker = colorPickerBundle.component
    const selectedColor = colorPickerBundle.color

    const pickColor = async () => {
        const colorRequest = {
            value: selectedColor.replace('#', '')
        } as NewColorRequest
        const colorResponse = await newColor(httpClient)(
            { user: user?.id!, target: selected }, colorRequest
        )
        if (colorResponse?.value!) {
            const assetSelected = tableAssets.filter(asset => asset.id === colorResponse?.target!)?.length > 0
            const holdingSelected = tableHoldings.filter(holding => holding.security.id === colorResponse?.target!)?.length > 0
            if (assetSelected) {
                setTableAssets(tableAssets.map(el => {
                    if (el.id === colorResponse?.target!) return { ...el, color: `#${colorResponse?.value!}` }
                    else return el
                }))
            }
            if (holdingSelected) {
                setTableHoldings(tableHoldings.map(el => {
                    if (el.security.id === colorResponse?.target!) return { ...el, color: `#${colorResponse?.value!}` }
                    else return el
                }))
            }
            pickColorModal.setVisible(false)
        }
    }

    const pickColorModal = colorPickerModal(pickColor, ColorPicker)
    const openPickColor = (value: string) => {
        setSelected(value)
        pickColorModal.setVisible(true)
    }

    const handleUpperSort = (id: string) => {
        const existing = sorts.filter(sort => sort.id == id)[0]
        const desc = existing ? !existing.desc : false
        setSorts([{ id, desc }])
    }

    const handleLowerSort = (id: string) => {
        const existing = lowerSorts.filter(sort => sort.id == id)[0]
        const desc = existing ? !existing.desc : false
        setLowerSorts([{ id, desc }])
    }

    React.useEffect(() => {
        const data = tableHoldings && tableHoldings.length > 0 ? tableHoldings : []
        const assets = tableAssets
        const chartData = data.concat(assets)
        setChartValues(chartData?.map((el: any) => {
            const value = el.shareholdings ? el.shareholdings.reduce((a, b) => a + b.capitalContribution, 0) : el.capitalContribution
            return {
                value,
                selected: false,
                color: el.color,
                name: el.org?.name || el.name!,
                abb: el.security ? formatSecurityAbbreviation(el.security) : el.abbreviation
            }
        }))
    }, [tableHoldings, tableAssets])

    const handleDeleteHolding = async () => {
        await deleteHolding(httpClient)({ holding: selectedHolding })
        deleteModal.setVisible(false)
        reload()
    }

    const deleteModal = deletePlanModal(
        handleDeleteHolding,
        'Delete Holding?',
        true,
        'Are you sure you want to delete this holding? This action cannot be undone.',
        'Confirm'
    )

    const toggleSelectedRows = (key: number) => {
        let select = {}
        if (selectedRows && selectedRows[key] === true) {
            select = { [key]: false }
        } else {
            select = { [key]: true }
        }
        setSelectedRows(select)
    }

    const toggleAssetSelectedRows = (key: number) => {
        let select = {}
        if (selectedAssetRows && selectedAssetRows[key] === true) {
            select = { [key]: false }
        } else {
            select = { [key]: true }
        }
        setSelectedAssetRows(select)
    }

    const handleSelection = (key: number) => {
        const chartV = chartValues
        const data = tableHoldings && tableHoldings.length > 0 ? tableHoldings : []
        const assets = tableAssets
        const isHolding = chartV && chartV.length > 0 ? assets?.filter(a => a.name === chartV[key].name!) : undefined
        if (isHolding && isHolding.length > 0) {
            const k = key - data?.length!
            toggleAssetSelectedRows(k)
        } else {
            toggleSelectedRows(key)
        }
        chartV.map((arg, i: number) => {
            if (i === key) {
                return arg.selected = !arg.selected
            }
            return arg.selected = false
        })
        setChartValues(chartV)
    }

    function conditionalEllipses() {
        const fields = [
            ellipsesColumn(
                [
                    ['Transfer', ({ row }) =>
                        navigate(overflowParams(transferShareholdingPath,
                            {
                                organization: forOrganization ? row.original.id! : row.original.organization!,
                                parent: window.location.pathname
                            }
                        ))
                    ],
                    ['Change Color', ({ row }) => {
                        setDefaultColor(row.original.security?.color!), openPickColor(row.original.security?.id || row.original.security?.hash)
                    }]
                ]
            )
        ]
        return !forGuest ? fields : []
    }
    const shareholdingsColumns = [
        // expanderColumn,
        {
            Header: 'Holdings',
            accessor: 'org.name',
            Cell: ({ row }: CellProps<any>) => {
                const certificates = row.original?.shareholdings?.length
                return (
                    <FlexRow>
                        <RainbowChips color={row.original?.color!} />
                        <FlexCol alignItems={'start'}>
                            <PathLink path={captablePath} args={{ organization: row.original.organization! }}>
                                <StyledSpan>
                                    {row.original.org?.name}-{' '}
                                    {formatSecurityAbbreviation(row.original.security)}{' '}
                                </StyledSpan>
                            </PathLink>
                            <CertificateSpan>{`${certificates} ${certificates > 1 ? 'Certificates' : 'Certificate'}`}</CertificateSpan>
                        </FlexCol>
                    </FlexRow>
                )
            },
            className: "left-basic",
            Footer: () => <span>TOTALS</span>,

        },
        {
            Header: 'Quantity',
            className: "right",
            accessor: 'totals.value',
            Cell: ({ row }: CellProps<any>) => <span>{row.original.totals?.quantity}</span>,
            Footer: ({ data }: any) => <span>{data.length > 0 && toCommaFloat(data[0].footer?.quantity)}</span>,

        },
        {
            Header: 'Investment',
            accessor: 'total.investment',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.totals?.investment, 2)}</span>,
            Footer: ({ data }: any) => <span>{data.length > 0 && optionalUsdString(data[0].footer?.investment, 2)}</span>

        },
        {
            Header: 'Current Value',
            accessor: 'totals.current',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.totals?.current, 2)}</span>,
            Footer: ({ data }: any) => <span>{data.length > 0 && optionalUsdString(data[0].footer?.value, 2)}</span>

        },
        {
            Header: 'Price',
            accessor: 'totals.price',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.totals?.price, 2)}</span>,
            Footer: ({ data }: any) => <span>{data.length > 0 && optionalUsdString(data[0].footer?.price, 2)}</span>

        },
        {
            Header: 'Return',
            accessor: 'totals.return',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.totals?.return, 2)}</span>,
            Footer: ({ data }: any) => <div>{data.length > 0 && optionalUsdString(data[0].footer?.return, 2)}</div>

        },
        ...conditionalEllipses()
    ]

    const holdingsColumns = [
        {
            Header: 'Holdings',
            accessor: 'name',
            Cell: ({ row }: CellProps<any>) => {
                return (
                    <FlexRow>
                        <RainbowChips color={row.original.color!} />
                        <span>{row.original.name!} {row.original.tickerSymbol ? `(${row.original.tickerSymbol})` : ''}</span>
                    </FlexRow>
                )
            },
            Footer: () => <span>TOTALS</span>,
            className: 'left-basic'
        },
        {
            Header: 'Quantity',
            accessor: 'value',
            Footer: ({ data }: any) => <div>{data.length > 0 && data[0].totals?.value!}</div>,
        },
        {
            Header: 'Investment',
            accessor: 'totals.investment',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.investment, 2)}</span>,
            Footer: ({ data }: any) => <div>{data.length > 0 && optionalUsdString(data[0].totals?.investment!, 2)}</div>,
        },
        {
            Header: 'Current Value',
            accessor: 'totals.current',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.current, 2)}</span>,
            Footer: ({ data }: any) => <div>{data.length > 0 && optionalUsdString(data[0].totals?.current!, 2)}</div>,
        },
        {
            Header: 'Price',
            accessor: 'pricePerUnit',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.pricePerUnit, 2)}</span>,
            Footer: ({ data }: any) => <div>{data.length > 0 && optionalUsdString(data[0].totals?.price!, 2)}</div>,
        },
        {
            Header: 'Return',
            accessor: 'totals.return',
            Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.return, 2)}</span>,
            Footer: ({ data }: any) => <div>{data.length > 0 && optionalUsdString(data[0].totals?.return!, 2)}</div>,
        },
        ellipsesColumn(
            [
                ['Edit Holding', ({ row }) => navigate(forOrganization ? editOrgHoldingPath :
                    editHoldingPath, forOrganization ? { organization: params?.organization, holding: row.original.hash }
                    : { holding: row.original.hash }
                )],
                ['Delete Holding', ({ row }) => { setSelectedHolding(row.original.hash), deleteModal.setVisible(true) }],
                ['Change Color', ({ row }) => { setDefaultColor(row.original.color!), openPickColor(row.original.id!) }],
            ]
        )
    ]

    const walletAssetColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ row }: CellProps<any>) => <span>{row.original.name}</span>,
            className: 'basic'
        },
        {
            Header: 'Address',
            accessor: 'address',
            Cell: ({ row }: CellProps<any>) => {
                const path = forGuest ? guestWalletPortfolioPath : walletPortfolioPath
                return <AddressSpan onClick={() =>
                    navigate(`${path}/${row.original.address}`)}>{row.original.address}</AddressSpan>
            },
            className: 'left',
        },
        {
            Header: 'Networks',
            accessor: 'Networks',
            Cell: ({ row }: CellProps<any>) => {
                const networks = row.original.networks
                return networks.map((n: string, i: number) => (
                    <span>{n}{i == networks.length - 1 ? '' : ', '}</span>
                ))
            },
            className: 'left',
        },
        {
            Header: 'Net worth',
            accessor: 'netWorth',
            Cell: ({ row }: CellProps<any>) => <span>{formatCurrency(row.original.netWorth)}</span>,
        },
    ]

    const subComponent = ({ row, selectedRows }: TableRowProps<any>) => {
        const shareholdings = row.original.shareholdings
        const renderCertificateRow = (voided?: boolean) => (subRow: any, i: number) => {
            const originalValue = subRow.shares! * (subRow.pricePerUnit! || 1)
            const args = {
                organization: subRow.organization || subRow.entity,
                shareholding: getHoldingHashOrId(subRow),
                member: subRow.member || subRow.owner,
                parent: portfolioPath
            }
            return <CertificateRow voided={voided} key={i}>
                <td />
                <td>
                    <FlexRow>
                        <StyledCertificateImage src={CertificateImage} />
                        <FlexCol alignItems="flex-start" style={{ marginLeft: '10px' }}>
                            <PathLink path={captableCertificatePath} args={args}>
                                {subRow.name!}
                            </PathLink>
                            <div>Issued {formatDateString(subRow.issueDate)}</div>
                        </FlexCol>
                    </FlexRow>
                </td>
                <td>{subRow.shares}</td>
                <td>{optionalUsdString(subRow?.capitalContribution!, 2)}</td>
                <td >{optionalUsdString(subRow.current, 2)}</td>
                <td>{optionalUsdString(subRow.price, 2)}</td>
                <td>{optionalUsdString(subRow.return, 2)}</td>
                <td />
            </CertificateRow>
        }
        return (
            <>
                {shareholdings.map(renderCertificateRow())}
            </>
        )
    }

    return (
        <>
            <PageContent withMargin={true}>
                {pickColorModal.component}
                {deleteModal.component}
                <PortfolioWrapper>
                    <PageContentHeader title={title} hideCrumbs={hideCrumbs}>
                        <StyledHeader justifyContent={'space-between'} forGuest={forGuest}>
                            {Search}
                            {!forGuest ? <PathLinkButton
                                path={forOrganization ? newOrgHoldingPath : newHoldingPath}
                                args={forOrganization ? { organization: params.organization } : {}}>
                                {forOrganization ? 'Add Holding' : 'Add Asset'}
                            </PathLinkButton> : null}
                        </StyledHeader>
                    </PageContentHeader>
                    <StyledWorth>
                        <MoneyBag />
                        <span className='worth'>
                            {formatCurrency(totalNetWorth || 0)}
                        </span>
                        <span className='netWorth'>Net Worth</span>
                    </StyledWorth>
                    <PanelGrid columns={columns}>
                        <SimplePanel
                            value={totalInvestmentInUsd}
                            description="Total Investment"
                            wrap={'true'}
                            descriptionColor={"#9CA6AD"} />
                        <SimplePanel
                            value={totalCurrentValueInUsd}
                            description="Total Current Value"
                            wrap={'true'}
                            descriptionColor={"#9CA6AD"} />
                        <SimplePanel
                            value={lifetimeGrowthInUsd}
                            description="Lifetime Growth (USD)"
                            wrap={'true'}
                            descriptionColor={"#9CA6AD"}
                            rawValue={lifetimeGrowth}
                            withArrow={true} />
                        <SimplePanel value={lifetimePercentage} description="Lifetime Growth (%)" wrap={'true'} rawValue={lifetimePercentage} withArrow={true} descriptionColor={"#9CA6AD"} />
                    </PanelGrid>
                    {!forGuest ?
                        <RainbowBar blocks={chartValues} handleSelection={handleSelection} customMargin='64px 0' />
                        : null}
                    {forGuest ? <>
                        <StyledTitle style={{ paddingTop: '48px' }}>Equa Assets</StyledTitle>
                        <div style={{ paddingBottom: '44px' }}>
                            <Table
                                columns={shareholdingsColumns}
                                data={[]}
                                isExpandable={true}
                                fixed={false}
                                scrollingTable={true}
                                description={<>
                                    <TableDescription>No assets to display.</TableDescription>
                                    <TableInfo>Assets issued by organizations will appear here.</TableInfo>
                                </>}
                                noFooter={true}
                            />
                        </div>
                    </>
                        :
                        <>
                            <StyledTitle>{`Equa ${forOrganization ? 'Holdings' : 'Assets'}`}</StyledTitle>
                            <Table
                                columns={shareholdingsColumns}
                                data={data.upper}
                                rowComponent={advancedTableRow(subComponent)}
                                isExpandable={true}
                                fixed={false}
                                selectedRows={selectedRows}
                                sortById={sorts}
                                handleSort={handleUpperSort}
                                scrollingTable={true}
                                description={data.upper.length > 0 ? undefined : <>
                                    <TableDescription>No assets to display.</TableDescription>
                                    <TableInfo>Assets issued by organizations will appear here.</TableInfo>
                                </>}
                                noFooter={data.upper.length > 0 ? false : true}
                            />
                        </>
                    }
                    <StyledTitle style={{ paddingTop: '48px' }}>Wallet Assets</StyledTitle>
                    <div style={{ paddingBottom: '44px' }}>
                        <Table
                            columns={walletAssetColumns}
                            data={walletAssetsData}
                            fixed={false}
                            scrollingTable={true}
                            description={walletAssetsData.length > 0 ? undefined : <>
                                <TableDescription>No assets to display.</TableDescription>
                                <TableInfo>
                                    Search an Ethereum address in search bar above or {' '}
                                    <PathLink path={forGuest ? guestWalletAddressBookPath : walletAddressBookPath}>
                                        connect a wallet.
                                    </PathLink>
                                </TableInfo>
                            </>}
                            noFooter={true}
                        />
                    </div>
                    <StyledTitle>Additional {forOrganization ? 'Holdings' : 'Assets'}</StyledTitle>
                    {forGuest ?
                        <Table
                            columns={shareholdingsColumns}
                            data={[]}
                            noFooter={true}
                            description={data.lower.length > 0 ? undefined : <>
                                <TableDescription>No assets to display.</TableDescription>
                                <TableInfo style={{ marginBottom: '16px' }}>Add assets manually to see them here.</TableInfo>
                                <SolidButton onClick={openSignUp}>Add Asset</SolidButton>

                            </>
                            }
                        />
                        : <Table
                            columns={holdingsColumns}
                            data={data.lower}
                            isExpandable={true}
                            fixed={false}
                            selectedRows={selectedAssetRows}
                            sortById={lowerSorts}
                            handleSort={handleLowerSort}
                            scrollingTable={true}
                            description={data.lower.length > 0 ? undefined : <>
                                <TableDescription>No assets to display.</TableDescription>
                                <TableInfo style={{ marginBottom: '16px' }}>Add assets manually to see them here.</TableInfo>
                                {forGuest ?
                                    <SolidButton onClick={openSignUp}>Add Asset</SolidButton>
                                    :
                                    <PathLinkButton
                                        path={forOrganization ? newOrgHoldingPath : newHoldingPath}
                                        args={forOrganization ? { organization: params.organization } : {}}>
                                        {forOrganization ? 'Add Holding' : 'Add Asset'}
                                    </PathLinkButton>}
                            </>}
                            noFooter={data.lower.length > 0 ? false : true}
                        />}
                </PortfolioWrapper>
            </PageContent>
        </>
    )
}
