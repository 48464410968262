import * as React from 'react'
import { FlexRow, colors, screenSizes, FlexCol, fontSizes, gapSizes } from '@src/styles'
import styled from 'styled-components'

interface Props {
    current: string
    completed?: string
}

const ProgressBar = styled<any>(FlexRow)`
    width: 404px;
    height: 8px;
    margin-bottom: ${gapSizes.S};
    border: ${props => props.current ? `1px solid ${props.theme.accent}` : ''};
    background: ${props => props.completed ? props.theme.accent : props.theme.progressBack};
    @media(max-width: ${screenSizes.M}px) {
        width: 100%;
    }
`
const ProgressWrap = styled<any>(FlexRow)`
    height: 123px;
    background: ${props => props.theme.action};
`

const ContentWrap = styled<any>(FlexRow)`
    width: ${screenSizes.XL}px;
    margin: 0 ${gapSizes.XL};
    span {
        font-size: ${fontSizes.S};
        line-height: 25px;
    }
    div:nth-child(1) {
        color: ${props => props.current === 'plan' ? props.theme.white : props.theme.afterSelected};
    }
    div:nth-child(2) {
        color: ${props => props.current === 'check-out' ? props.theme.white : props.theme.afterSelected};
        margin-left: 4px;
    }
    @media(max-width: ${screenSizes.M}px) {
        display: grid;
        margin: 0 ${gapSizes.M};
        grid-template-columns: 1fr 1fr;
    }
`

export const ProgressBarHeader = (props: Props) => {
    const { completed, current } = props
    return (
        <ProgressWrap justifyContent={'center'}>
            <ContentWrap current={current} completed={completed}>
                <FlexCol alignItems={'start'}>
                    <ProgressBar current={current === 'plan'} completed={completed === 'plan'} />
                    <span>Step 1:</span>
                    <span>Select Your Plan</span>
                </FlexCol>
                <FlexCol alignItems={'start'}>
                    <ProgressBar current={current === 'check-out'} completed={completed === 'check-out'} />
                    <span>Step 2:</span>
                    <span>Check Out</span>
                </FlexCol>
            </ContentWrap>
        </ProgressWrap>
    )
}