import * as React from 'react'
import { emptyValidator } from '@helpers/field-validators'
import {
  dateField,
  disabledInputFieldComponent,
  FieldGroup,
  phoneNumberField,
  renderFieldsSimple,
  renderHeader,
  textInputFieldComponent,
  uploadFileField,
  UploadHandler
} from '@shared/components/forms'
import { isEmail, isUrl } from '@shared/helpers'
import styled from 'styled-components'
import { Address } from '@shared/components/address'
import { fontSizes, LightDivider } from '@src/styles'
import { EmailSection } from '@shared/components/email-section'

interface Props {
  onUpload: UploadHandler,
  noEmail?: boolean
}

const ProfileFormWrapper = styled.div<any>``

function nameAndProfileFields(onUpload: UploadHandler): FieldGroup<any> {
  return [
    {
      name: 'firstName',
      label: 'First Name',
      required: true,
      validate: emptyValidator,
      placeholder: 'Enter your first name',
      component: textInputFieldComponent
    },
    {
      name: 'lastName',
      label: 'Last Name',
      required: true,
      validate: emptyValidator,
      placeholder: 'Enter your last name',
      component: textInputFieldComponent
    },
    {
      name: 'photo',
      label: 'Profile Image',
      required: false,
      note: 'Square images work best. Accepted file types: PNG, JPG, JPEG',
      ...uploadFileField({
        onUpload,
        placeholder: 'Upload your profile photo',
        accept: ['.jpg', '.png', '.jpeg']
      }),
    },
  ]
}

function personalInformationFields(): FieldGroup<any> {
  return [
    {
      name: 'phone',
      label: 'Phone Number',
      required: false,
      validate: emptyValidator,
      component: phoneNumberField,
    },
    {
      name: 'dateOfBirth',
      label: 'Date of Birth',
      required: false,
      placeholder: 'MM/DD/YYYY',
      ...dateField(React.useRef()),
    }
  ]
}

function profileLinksFields(): FieldGroup<any> {
  return [
    {
      name: 'links.linkedIn',
      label: 'LinkedIn',
      required: false,
      placeholder: 'Enter LinkedIn link',
      validate: isUrl,
      component: textInputFieldComponent,
    },
    {
      name: 'links.facebook',
      label: 'Facebook',
      required: false,
      placeholder: 'Enter Facebook link',
      validate: isUrl,
      component: textInputFieldComponent,
    },
    {
      name: 'links.twitter',
      label: 'Twitter',
      required: false,
      placeholder: 'Enter Twitter link',
      validate: isUrl,
      component: textInputFieldComponent,
    },
    {
      name: 'links.instagram',
      label: 'Instagram',
      required: false,
      placeholder: 'Enter Instagram link',
      validate: isUrl,
      component: textInputFieldComponent,
    },
  ]
}

export const ProfileForm = (props: Props) => {
  const { onUpload, noEmail } = props

  return (
    <ProfileFormWrapper>
      {renderHeader({ label: 'Name & Profile Image' })}
      {renderFieldsSimple(nameAndProfileFields(onUpload))}
      {renderHeader({ label: 'Personal Information' })}
      {renderFieldsSimple(personalInformationFields())}
      <Address name={'address'} />
      {!noEmail && <EmailSection name='emails' />}
      {renderHeader({ label: 'Profile Links' })}
      {renderFieldsSimple(profileLinksFields())}
      {renderHeader({ label: 'Website' })}
      {renderFieldsSimple([
        {
          name: 'website',
          label: 'Website',
          required: false,
          placeholder: 'Enter a website link',
          validate: isUrl,
          component: textInputFieldComponent,
        }
      ])}
    </ProfileFormWrapper>
  )
}
