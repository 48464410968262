import * as React from 'react'
import { PageProps, User, MembersProps, OrganizationsProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAddresses, loadColors, loadPortfolioData, loadOrganizations, loadProfile } from '@helpers/loaders'
import { NavigationProps } from '@components/navigation'
import { ExpandedHoldingsResponse, GetAddressesResponse, GetSecuritiesResponse } from '@src/service'
import { StateTransform } from 'index'
import { connect } from 'react-redux'
import { GetColorsResponse } from '@src/service/services/profile'
import { DashboardComponent } from '../components/dashboard-component'
import styled from 'styled-components'
import { colors, FlexRow } from '@src/styles'
interface StateProps {
    user?: User
}

type Props = PageProps &
    StateProps &
    OrganizationsProps &
    NavigationProps &
    GetColorsResponse &
    MembersProps &
    ExpandedHoldingsResponse &
    GetSecuritiesResponse &
    GetAddressesResponse


export const CopyWrap = styled<any>(FlexRow)`
    min-width: 48px;
    height: 48px;
    margin: 0 0 0 45px;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
    position: relative;
    align-items: center !important;
    div {
        display: none;
    }
    &:hover {
        background: ${props => props.theme.boxHover};
        div {
            display: flex;
        }
    }
    &:active {
        background: #30424D;
        opacity: 0.6;
    }
    svg {
        path {
            fill: ${props => props.theme.whiteIcon};
        }
    }
    .arrow-up {
        width: 0; 
        height: 0; 
        position: absolute;
        bottom: -8px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;    
        border-bottom: 7px solid ${props => !props.copy ? '#FAFAFA' : '#33AA40'};
    }
`

export const CopyHover = styled.div<any>`
    min-width: 45px;
    height: 24px;
    position: absolute;
    border-radius: 4px;
    bottom: -32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => !props.copy ? '#FAFAFA' : '#33AA40'};
    opacity: 1 !important;
    z-index: 999;
    span {
        font-weight: 600;
        font-size: 12px !important;
        font-family: 'NunitoSansSemiBold';
        line-height: 20px !important;
        margin: 0 8px;
        color: ${props => props.copy ? colors.white : '#262626'} !important;
        white-space: nowrap;
    }
`

const withData = withLoadingCachedMultiple<Props>(
    loadOrganizations,
    loadProfile(),
    loadColors('user'),
    loadAddresses(''),
    loadPortfolioData('user'),
)


export const UserDashboardComponent = withData((props: Props) => {
    return <DashboardComponent {...props} />
})

const mapStateToProps: StateTransform<StateProps> = s => ({
    user: s.user,
})

export const UserDashboardPage = connect(mapStateToProps, {})(UserDashboardComponent)
