import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useRef } from 'react'
import { Column } from 'react-table'
import { rgba } from 'polished'
import { colors, fontSizes, gapSizes, Themes } from '@src/styles/theme'
import EditPen from '@image/edit-pen-new.svg'
import Clone from '@image/clone.svg'
import Bin from '@image/bin-16x16.svg'
import Eye from '@image/protected-grey-16x16.svg'
import Plus from '@image/plus-16x16.svg'

export type EllipsesOption<D = any> = [string, () => void]

interface Props<D = any> {
  options: Array<EllipsesOption<D> | undefined | false>
  table?: boolean
}

export const StyledEllipses = styled.div<any>`
  width: ${props => props.table === 'true' ? '100%' : 'auto'};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
  float: right;
  > div:first-child {
    font-size: 22px;
    padding: ${props => props.table === 'false' ? '0 10px' : 0};
    overflow: hidden;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
`

const OptionWrap = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 30px;
  text-align: start;
  font-size: ${fontSizes.XS};
  white-space: nowrap;
  z-index: 4;
  opacity: 1;
`

const StyledOption = styled.div<any>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 16px;
  background-color: ${props => props.theme.ellipseOption};
  width: 100%;
  border-radius: ${props => props.isFirst && props.isLast ? '4px 4px 4px 4px' : props.isFirst ? '4px 4px 0 0' : props.isLast ? '0 0 4px 4px' : ''};
  svg {
    margin-right: 10px;
  }
  &:hover {
    background-color: ${props => props.isDelete ? colors.errorRed : props.theme.accent} !important;
    span {
      color: ${props => props.isDelete ? colors.white :
    props.theme.selected === Themes.LIGHT ? colors.white : props.theme.white} !important;
    }
    svg {
      path {
        fill: ${props => colors.white} !important;
      }
    }
  }
  svg {
    path {
      fill: ${props => props.isDelete ? colors.errorRed : props.theme.listText} !important;
    }
  }
`

const StyledSpan = styled.span<any>`
  margin: 0 !important;
  color: ${props => props.isDelete && colors.errorRed} !important;
  font-size: 16px !important;
  line-height: 24px !important;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const getIcon = (name: string) => {
  switch (name) {
    case 'View':
      return <Eye />
    case 'Create':
    case 'Edit':
      return <EditPen />
    case 'Clone':
      return <Clone />
    case 'Delete':
      return <Bin />
    case 'Add':
      return <Plus />
  }
}

export function Ellipses<D = any>(props: Props<D>) {
  const { options, table } = props
  const [expanded, setExpanded] = useState(false)
  const node = useRef(null)
  const handleClose = () => {
    setExpanded(false)
  }
  useClickOutside({ node, handleClose })
  return (
    <StyledEllipses ref={node} onClick={() => setExpanded(!expanded)} className="ellipse" table={table ? 'true' : 'false'}>
      <div className='optionsWrap'>
        <span name={"wrapper"}>&#8942;</span>
        {expanded ? (
          <OptionWrap>
            {options.map((option, i: number) => {
              const isFirst = i === 0
              const isLast = i === options.length - 1
              return (
                option && (
                  <StyledOption key={option[0]} onClick={option[1]} isDelete={option[0] == 'Delete'} isFirst={isFirst} isLast={isLast}>
                    {getIcon(option[0])}
                    <StyledSpan isDelete={option[0] == 'Delete'} name={option[0]}>{option[0]}</StyledSpan>
                  </StyledOption>
                )
              )
            })}
          </OptionWrap>
        ) : null}
      </div>
    </StyledEllipses>
  )
}

export type CellOption<D = any> = [string, (cell: any) => void]

export function ellipsesColumn<D = any>(options: Array<CellOption<D>>, hasPermissions: boolean = true): Column<any> {
  return {
    id: 'ellipses',
    Header: '',
    Cell: (cell: CellOption) => {
      return <>{hasPermissions && <Ellipses options={options.map(o => [o[0], () => o[1](cell)])} table={true} />}</>
    },
    className: 'ellipses'
  } as Column<any>
}
