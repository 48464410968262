import * as React from 'react'
import { SubHeader, SubTitle } from '@src/app/components/header/sub-header'
import { ComponentType } from 'react'

export const UpgradeHeader = (props: any) => {
    const UpgradeTitle = () => (
        <SubTitle hideLogo={true} name={'Upgrade'} />
    )
    return (
        <SubHeader title={<UpgradeTitle />} />
    )
}

export function withUpgradeHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
    return (props: any) => (
        <React.Fragment>
            <UpgradeHeader />
            <InnerComponent {...props} />
        </React.Fragment>
    )
}
