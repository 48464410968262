import { css, ThemeProps } from 'styled-components'
import { colors, fontSizes, gapSizes, Theme, Themes } from '@src/styles/theme'
import styled from 'styled-components'
import { PathLink } from '@components/navigation/path-link'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'
import { whiteColor } from '@src/styles'

export interface ButtonProps {
  margin?: string
  theme: ThemeProps<Theme>
}

export const ButtonGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const baseButtonStyle = css<ThemeProps<Theme> & { disabled?: boolean, slim?: boolean }>`
  font-family: 'NunitoSansExtraBold';
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.slim ? '32px' : '48px'};
  padding: 14px 32px;
  text-transform: uppercase;
  outline: 0;
  font-size: ${fontSizes.XXS};
  text-align: center;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  transition: all 0.3s;
  opacity: ${props => props.disabled ? 0.5 : 1.0};
  + a,
  + button {
    margin-left: 8px;
  }
  border-radius: ${props => props.theme.radius};
`

export const solidButtonStyle = css<any>`
  color: #FFF !important;
  border: 0;
  background-color: ${props => props.submitting ?
    props.theme.button.primary.submitting
    :
    props.theme.button.primary.default};
  &:hover {
    background-color: ${props => props.theme.button.primary.hover};
  }
  &:disabled {
    background-color: ${props => props.theme.button.primary.disabled} !important;
    border: none;
  }
`

export const invertedButtonStyle = css<any>`
  color: ${props => props.theme.accent};
  border: ${props => `1px solid ${props.theme.accent}`};
  background-color: ${props => props.submitting ?
    props.theme.button.inverted.submitting
    :
    props.theme.button.inverted.default};
    opacity: ${props => props.submitting && '0.6'};
  &:hover {
    background-color: ${props => props.theme.button.inverted.hover};
    border: ${props => `1px solid ${props.theme.button.inverted.hoverColor}`};
    color: ${props => props.theme.button.inverted.hoverColor};
  }
  &:disabled {
    background-color: ${props => props.theme.button.inverted.disabled} !important;
    color: rgba(191, 191, 191, 0.4);
    opacity: 0.8;
    border: 1px solid rgba(191, 191, 191, 0.4);
  }
`

export const errorButtonStyle = css<ThemeProps<Theme>>`
  color: ${colors.white};
  border: 0;
  background-color: ${props => props.theme.error};
  &:hover {
    background-color: ${props => rgba(props.theme.accent, 0.75)};
  }
`

export const Button = styled.button<ButtonProps>`
  ${baseButtonStyle}
  ${invertedButtonStyle}
`

export const ErrorButton = styled.button<ButtonProps>`
  ${baseButtonStyle}
  color: ${colors.white};
  border: 0;
  background-color: ${props => props.theme.button.error.default};
  &:hover {
    background-color: ${props => props.theme.button.error.hover};
  }
  &:disabled {
    background-color: ${props => props.theme.button.error.disabled};
  }
`

export const SolidButton = styled.button<ButtonProps>`
  ${baseButtonStyle}
  ${solidButtonStyle}
  white-space: nowrap;
`

export const InputButton = styled.div<any>`
  ${baseButtonStyle}
  ${solidButtonStyle}
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 12px 32px;
  background: ${props => props.hasErrors && colors.errorRed};
  &:hover {
    background-color: ${props => props.hasErrors && props.theme.button.error.hover};
  }
`

export const PathLinkButton = styled<any>(PathLink)`
  ${baseButtonStyle}
  ${solidButtonStyle}
`

export const LoginSignButton = styled.button<ButtonProps>`
  ${baseButtonStyle}
  ${solidButtonStyle}
`
export const PathLinkInvertedButton = styled<any>(PathLink)`
  ${baseButtonStyle}
  ${invertedButtonStyle}
`

export const LinkButton = styled(Link)`
  ${baseButtonStyle}
  ${solidButtonStyle}
`

export const PathLinkButtonInverted = styled<any>(PathLink)`
  ${baseButtonStyle}
  ${invertedButtonStyle}
`

export const SubmitButton = styled<any>(Button)`
  ${baseButtonStyle}
  ${solidButtonStyle}
`

export const InvertedButton = styled.div<any>`
  ${baseButtonStyle}
  ${invertedButtonStyle}
`

export const transparentButtonStyle = css`
  color: ${props => props.theme.listIcon} !important;
  background-color: transparent;
  &:disabled {
    opacity: 0.6;
  }
`

export const SkipLinkButton = styled<any>(PathLink)`
  ${baseButtonStyle}
  ${transparentButtonStyle}
  &:hover {
    text-decoration: underline;
  }
`

export const ClearButton = styled.button<any>`
  ${baseButtonStyle}
  ${transparentButtonStyle}
  border: none;
  &:hover {
    background: ${props => props.theme.button.cancel.hover};
    color: ${props => props.theme.selected === Themes.LIGHT && colors.white} !important;
  }
  &:disabled  {
    color: #BFBFBF !important;
    background: transparent;
  }
`

export const CancelButton = styled<any>(Button)`
  ${baseButtonStyle}
  color: ${props => props.theme.listIcon} !important;
  height: 40px;
  border: none !important;
  background: ${props => props.submitting ?
    props.theme.button.cancel.submitting
    :
    props.theme.button.cancel.default};
  &:hover {
    background: ${props => props.theme.button.cancel.hover};
    color: ${props => props.theme.selected === Themes.LIGHT && colors.white} !important;
  }
  &:disabled  {
    color: #BFBFBF !important;
    background: ${props => props.theme.button.cancel.disabled};
  }
`

export const WarningButton = styled<any>(Button)`
  ${baseButtonStyle}
  color: ${props => props.theme.button.warning.text};
  background-color: ${props => props.submitting ?
    props.theme.button.warning.submitting
    :
    props.theme.button.warning.default};
  border: none !important;
  height: 40px;
  &:hover {
    background: ${props => props.theme.button.warning.hover};
    color: ${props => props.theme.button.warning.text};
  }
  &:disabled  {
    color: #BFBFBF !important;
    background: ${props => props.theme.button.warning.disabled};
  }
`

export const InvertedWarningButton = styled<any>(Button)`
  ${baseButtonStyle}
  border: 1px solid ${props => props.theme.button.warning.default};
  color: ${props => props.theme.button.warning.default} !important;
  background-color: ${props => props.submitting ?
    props.theme.button.warningI.submitting
    :
    props.theme.button.warningI.default} !important;
  height: 40px;
  &:hover {
    border: 1px solid ${props => props.theme.button.warning.hover};
    background: ${props => props.theme.button.warningI.hover};
    color: ${props => props.theme.button.warning.hover} !important;
  }
  &:disabled  {
    color: #BFBFBF !important;
    border: 1px solid #BFBFBF !important;
    background: ${props => props.theme.button.warningI.disabled};
    opacity: 0.8;
  }
`
export const SlimButton = styled<any>(CancelButton)`
  padding: 12px 32px;
  margin-bottom: 16px;
  margin-left: 0 !important;
  width: 100%;
  height: 32px;
  background: ${props => props.theme.selected === Themes.LIGHT && '#92AABA'};
  color: ${colors.white} !important;
  svg {
    margin-right: 8px;
    path {
      fill: ${colors.white};
    }
  }
`