import * as React from 'react'
import { PageContent } from '@components/pages'
import { PageProps, ShareholdingProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding } from '@helpers/loaders'
import { PageContentHeader } from '@components/pages/page-content-header'
import { CertificateView } from '@modules/captable/components/certificate-view'
import { WarningButton } from '@components/button/button'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { BuiltInPermission } from '@helpers/constants'
import { newBasicModalBundle } from '@components/basic-modal'
import { deleteHolding, ExpandedHoldingResponse } from '@src/service/services/captable'
import { Form } from 'react-final-form'
import { renderFieldsSimple, selectFieldComponent, textAreaInputFieldComponent } from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers'
import styled from 'styled-components'
import { FlexRow } from '@src/styles'
import { createAuthorization } from '@src/service'
import { NewAuthorizationRequest } from '@src/service/services/organizations/requests'
import { CancelReasonTypes } from '../utility'
import { ActionsDrop } from '@shared/components/actionsDrop'

type Props = PageProps & ShareholdingProps & PermissionProps & ExpandedHoldingResponse

declare var API_URL: string

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadPermissions)

const StyledForm = styled<any>(FlexRow)`
  height: 235px;
  justify-content: center;
  align-items: start;
  form {
    width: 79.55%;
  }
`

export const CaptableCertificatePage = withData((props: Props) => {
  const { permissions, service, reload, httpClient } = props
  const holdingRecord = props.holding!!
  const { organization, shareholding } = props.params
  const certificateNumber = holdingRecord?.name!

  const cancelOptions = [
    { label: 'Error', value: CancelReasonTypes.error },
    { label: 'Transfer', value: CancelReasonTypes.transfer },
    { label: 'Buy Back', value: CancelReasonTypes.buyBack },
    { label: 'Other', value: CancelReasonTypes.other }
  ]
  const submit = async (values: any) => {
    const request = {
      note: values?.note!,
      reason: values?.reason!,
      target: shareholding,
    } as NewAuthorizationRequest
    await createAuthorization(httpClient)({ organization }, request)
    deleteHolding(httpClient)({ holding: shareholding })
    reload()
  }

  const cancelModal = newBasicModalBundle({
    title: 'Confirm Cancel',
    warningIcon: true,
    desc: `Are you sure you want to cancel the certificate ${certificateNumber}? This action cannot be undone`,
    body: (
      <StyledForm>
        <Form
          onSubmit={submit}
          render={({ handleSubmit }) => (
            <form id="new-link" onSubmit={handleSubmit}>
              {renderFieldsSimple([
                {
                  name: 'reason',
                  label: 'Reason',
                  placeholder: 'Select a reason',
                  required: true,
                  validate: emptyValidator,
                  component: selectFieldComponent(cancelOptions, 'Reason'),
                },
                {
                  name: 'note',
                  label: 'Notes',
                  placeholder: 'Notes',
                  required: false,
                  validate: emptyValidator,
                  component: textAreaInputFieldComponent(),
                },
              ], true)}
            </form>
          )}
        />
      </StyledForm>
    ),
    buttons: [
      {
        text: 'Confirm',
        type: 'submit',
        form: 'new-link',
        componentType: WarningButton,
      },
    ],
  })

  const dropOptions = [
    ['Download PDF', () => window.open(API_URL + `/shareholding/${shareholding}/certificate/copy`, '_blank')],
    ['Cancel', () => cancelModal.setVisible(true)],
  ]

  const headerLinks = hasPermission(permissions)(BuiltInPermission.editCapTable) && !holdingRecord?.isCancelled!
    ? <ActionsDrop options={dropOptions} /> : null

  return (
    <PageContent>
      {cancelModal.component}
      <PageContentHeader title={`View Certificate ${certificateNumber}`}>
        {headerLinks}
      </PageContentHeader>
      <CertificateView shareholding={shareholding} />
    </PageContent>
  )
})
