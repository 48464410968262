import * as React from 'react'
import { BaseLinkProps } from '@components/navigation/navigation'
import Arrow from '@image/arrow-left.svg'
// import ArrowDark from '@image/arrow-dark.png'
import { getQueryParams } from '@helpers/util'
import { ArrowPathLink, ContentHeaderStyle, TitleGrid } from '@components/pages/styles'
import { useEffect } from 'react'
import { Breadcrumbs } from '../breadcrumbs'
import { ThemeProps, withTheme } from 'styled-components'
import { Themes } from '@src/styles'

interface Props {
  parent?: BaseLinkProps
  onClick?: () => void
  children?: any
  subTitle?: string
  title: string | any
  hideCrumbs?: boolean
  noSpace?: boolean
  direction?: boolean
  withSticky?: boolean
}

const BackTitleButton = withTheme((props: BaseLinkProps & ThemeProps<any>) => {
  // const arrow = props.theme?.selected === Themes.LIGHT ? ArrowDark : Arrow
  return <ArrowPathLink {...props}>
    <Arrow alt="back arrow" />
  </ArrowPathLink>
})

function prepareParentLink(parent?: BaseLinkProps): BaseLinkProps | undefined {
  const path = getQueryParams().parent
  return path ? { path } : parent
}

export const PageContentHeader = (props: Props) => {
  const {
    parent,
    children,
    subTitle,
    title,
    onClick,
    hideCrumbs,
    noSpace,
    direction,
    withSticky
  } = props
  const parentLink = prepareParentLink(parent)
  // useEffect(() => {
  //   document.title = [title, subTitle].filter(i => i).join(' · ')
  // }, [])

  return (
    <>
      {!hideCrumbs && <Breadcrumbs withSticky={withSticky} />}
      <ContentHeaderStyle
        onClick={onClick}
        noSpace={noSpace ? 'true' : 'false'}
        direction={direction ? 'true' : 'false'}
        hideCrumbs={hideCrumbs ? 'true' : 'false'}
        withSticky={withSticky ? 'true' : 'false'}
      >
        <TitleGrid>
          {parentLink ? <BackTitleButton {...parentLink} /> : undefined}
          <h1>{title}</h1>
          {subTitle && <p>{subTitle} </p>}
        </TitleGrid>
        {children}
      </ContentHeaderStyle>
    </>
  )
}
