import * as React from 'react'
import { editMemberPath, MemberProps, organizationMembersPath, PageProps } from '@logic'
import { RecentActions, ShareBlock } from './components/index'
import { PageContent } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadCaptableData, loadMember, loadPlans } from '@helpers/loaders'
import { toCommaFloat } from '@helpers/util'
import { BaseLinkProps } from '@components/navigation/navigation'
import { PageContentHeader } from '@components/pages/page-content-header'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { BuiltInPermission } from '@helpers/constants'
import { PathLinkButtonInverted } from '@components/button/button'
import { withPermissions } from '@src/shared/hocs/with-permissions'
import { PanelGrid, SimplePanel } from '@components/panels/panels'
import { EsopPreview } from './components/esop-preview'
import { GetCaptableDashboardResponse, PlansProps } from '../../../../service'
import { optionalUsdString } from '@modules/convertibles/components'
import { HoldingRecordType } from '@shared/helpers/holdings'
import { isHolding } from '@modules/captable/utility'

type Props = PageProps & MemberProps
  & PermissionProps
  & PlansProps
  & GetCaptableDashboardResponse

const withData = withLoadingCachedMultiple<Props>(
  loadMember,
  loadPermissions,
  loadPlans,
  loadCaptableData('false', 'vesting_schedule'))

export const MemberPage = withData(
  withPermissions([BuiltInPermission.viewMembers, BuiltInPermission.viewSelf])((props: Props) => {
    const { memberRecord, permissions, params, plans, members } = props
    const { member, organization } = params

    const currentMember = members?.find(m => m.id == member)
    const memberHoldings = currentMember?.holdings
    const optionsList = memberHoldings?.filter(h => h.holdingType == HoldingRecordType.option) || []
    const userHoldings = memberHoldings?.filter(h => isHolding(h.holdingType))?.map(h => ({ ...h, isHolding: true })) || []
    const userShareholdings = memberHoldings?.filter(h => !isHolding(h.holdingType)) || []
    const exercisedShareholdings = userHoldings ? userShareholdings?.concat(userHoldings) : userShareholdings
    const canEdit = hasPermission(permissions)(BuiltInPermission.editMembers)
    const isAdmin = hasPermission(permissions)(BuiltInPermission.editCapTable)
    const fullyDilutedPercentage = currentMember?.memberFullyDilutedPer ?
      `${currentMember.memberFullyDilutedPer.toFixed(2)}%` : '0.00%'
    const outstandingPercentage = currentMember?.memberOutstandingPer ?
      `${currentMember.memberOutstandingPer.toFixed(2)}%` : '0.00%'
    const outstandingTotal = toCommaFloat(currentMember?.memberOutstanding || 0)
    const capitalTotal = optionalUsdString(currentMember?.memberInvestment || 0)
    const columns = (outstandingTotal!.length > 9 || capitalTotal!.length > 11) ? 2 : 4

    const parent: BaseLinkProps = { path: organizationMembersPath, args: { organization } }
    return (
      <PageContent>
        <PageContentHeader title={memberRecord.fullName} parent={parent}>
          {canEdit && (
            <>
              <PathLinkButtonInverted path={editMemberPath} args={{ organization, member }}>
                Edit
              </PathLinkButtonInverted>
            </>
          )}
        </PageContentHeader>
        <PanelGrid columns={columns}>
          <SimplePanel description={'Outstanding Shares'} value={outstandingTotal} />
          <SimplePanel description={'Capital Contribution'} value={capitalTotal} />
          <SimplePanel description={'Outstanding %'} value={outstandingPercentage} />
          <SimplePanel
            description={'Fully Diluted %'}
            value={fullyDilutedPercentage}
          />
        </PanelGrid>
        <PanelGrid columns={2}>
          <ShareBlock outstandingShares={exercisedShareholdings} />
          <RecentActions idList={props.location.state} />
        </PanelGrid>
        {optionsList?.length > 0 ?
          <EsopPreview
            options={optionsList}
            plans={plans}
            isAdmin={isAdmin}
            organization={organization} />
          : null
        }
      </PageContent>
    )
  })
)
