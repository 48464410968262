import {
    dashboardPath,
    portfolioPath,
    referralsPath,
    profileDetailsPath,
    themesPath,
    accountPath,
    logoutPath,
    equaCashTransferPath,
    walletsPath,
    walletPortfolioPath,
    guestDashboardPath,
    guestPortfolioPath,
    guestWalletsPath,
    guestWalletPortfolioPath,
    guestReferralsPath,
    guestEquaCashTransferPath,
    guestProfileDetailsPath,
    guestThemesPath,
    guestAccountPath
} from '@logic'
import { HeaderLinkProps, HeaderLinks } from '@src/app/components/header/header-links'
import React from 'react'
import { Permission } from '@components/permissions'
import { HamburgerMenu } from '../hamburger'

interface Props {
    permissions: Permission[]
    mobileView: boolean
    navigate: any
    forGuest?: boolean
}

export const ProfileLinks = (props: Props) => {
    const { permissions, mobileView, navigate, forGuest } = props
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
    const links: HeaderLinkProps[] = forGuest ?
        [
            {
                path: guestDashboardPath,
                title: 'Dashboard',
            },
            {
                path: guestPortfolioPath,
                title: 'Portfolio',
            },
            {
                path: guestWalletsPath,
                title: 'Wallets',
                defaultUrl: guestWalletPortfolioPath,
                beta: true
            },
            {
                path: guestReferralsPath,
                title: 'Referrals',
            },
            {
                path: guestEquaCashTransferPath,
                title: 'E$ Transfer',
            },
            {
                path: guestProfileDetailsPath,
                title: 'Profile',
            },
            {
                path: guestThemesPath,
                title: 'Themes',
            },
            {
                path: guestAccountPath,
                title: 'Account',
            },
        ]
        :
        [
            {
                path: dashboardPath,
                title: 'Dashboard',
            },
            {
                path: portfolioPath,
                title: 'Portfolio',
            },
            {
                path: walletsPath,
                title: 'Wallets',
                defaultUrl: walletPortfolioPath,
                beta: true
            },
            {
                path: referralsPath,
                title: 'Referrals',
            },
            {
                path: equaCashTransferPath,
                title: 'E$ Transfer',
            },
            {
                path: profileDetailsPath,
                title: 'Profile',
            },
            {
                path: themesPath,
                title: 'Themes',
            },
            {
                path: accountPath,
                title: 'Account',
            },
        ]
    return !mobileView ? <HeaderLinks links={links} />
        :
        <HamburgerMenu noPath={() => navigate(logoutPath)} setOpen={setMobileMenuOpen} isOpen={mobileMenuOpen} links={links} />

}
