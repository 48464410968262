import * as React from 'react'
import { Paths, SiteStatsResponse } from '@logic'
import { withCachedLoading } from '@components/loading'
import { PageContentHeader } from '@components/pages/page-content-header'
import { PageContent } from '@components/pages'
import { loadSiteStats } from '@helpers/loaders'
import { Panel, PanelGrid, SimplePanel } from '@components/panels/panels'
import { BaseLinkProps } from '@components/navigation/navigation'

const withSiteStats = withCachedLoading<SiteStatsResponse, any>(loadSiteStats)

export const SiteStats = withSiteStats((props: any) => {
  const { siteStats } = props

  const parent: BaseLinkProps = { path: Paths.admin }

  return (
    <PageContent>
      <PageContentHeader title="Site Stats" parent={parent} />
      <PanelGrid>
        <SimplePanel value={siteStats.organizationCount} description="Organizations" />
        <SimplePanel value={siteStats.memberCount} description="Members" />
        <SimplePanel value={siteStats.memberAverage.toFixed(2)} description="Average Members Per Organization" />
        <SimplePanel value={siteStats.waitlistCount} description="Waitlist Users" />
      </PanelGrid>
    </PageContent>
  )
})
