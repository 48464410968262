interface CacheEntry {
  created: number
  value: any
}

let dataCache: { [key: string]: CacheEntry } = {}

export function clearDataCache() {
  dataCache = {}
}

export function removeDataCacheValue(key: string) {
  delete dataCache[key]
}

export function setDataCacheValue<T>(key: string, value: T) {
  dataCache[key] = {
    created: Date.now(),
    value,
  }
}

export function getDataCacheValue<T>(key: string): T | undefined {
  const entry = dataCache[key]
  if (!entry) return undefined

  const age = Date.now() - entry.created
  if (age > 30 * 1000) {
    removeDataCacheValue(key)
    console.log(`Cache ${key}: Removing old value (age = ${age})`)
    return undefined
  } else {
    console.log(`Cache ${key}: Returning cached value`)
    return entry.value
  }
}
