import * as React from 'react'
import { organizationPath, OrganizationsProps, PageProps } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { CommonForm } from '@components/forms'
import SectionOrganizationForm from './components/section-organization'
import arrayMutators from 'final-form-arrays'
import { createAddress, NewAddressRequest1 } from '@src/service'

type Props = PageProps & OrganizationsProps

export const NewOrganizationPage = (props: Props) => {
  const { serviceOld, navigate, httpClient } = props

  const onSubmit = async (values: any) => {
    const addresses = values?.address?.filter((a: any) => !a.isRemoved).map((address: any) => {
      const newAddress = { ...address }
      delete newAddress.key
      return newAddress
    })

    const organizationResponse = await serviceOld.createOrganization(values.organization)
    if (organizationResponse) {
      const orgId = organizationResponse.organization.id
      if (addresses && addresses.length > 0) {
        addresses.forEach(async (address: any) => {
          const addRequest = {
            ...address,
            groups: address?.groups! ? address.groups : []
          } as NewAddressRequest1
          await createAddress(httpClient)({ entity: orgId }, addRequest)
        })
      }
      navigate(organizationPath, { organization: orgId })
    }
  }
  const title = 'Enter Your Organization Details'

  return (
    <PageContent>
      <PageContentHeader title={title} hideCrumbs={true} />
      <CommonForm
        mutators={{ ...arrayMutators }}
        onSubmit={onSubmit}
        initialValues={{}}
        submitText="Create Organization" >
        <SectionOrganizationForm path="organization" />
      </CommonForm>
    </PageContent>
  )
}
