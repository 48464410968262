import * as React from 'react'
import { loadPlan } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { ComponentType } from 'react'
import { NavigationProps, withNavigation } from '@shared/components/navigation'
import { withService } from '@shared/hocs/with-service'
import { GetPlanResponse, } from 'service'
import { Tab, Tabs } from '@shared/components/tabs/tabs'
import {
    planPath,
    planDocumentsPath,
    planIncentivesPath,
    poolsPath,
    vestingsPath,
    optionsPath
} from '@logic'

type Props = NavigationProps & GetPlanResponse
// TODO make subscription prop
const withData = withLoadingCachedMultiple<Props>(loadPlan)

const PlanHeader = withNavigation(withService(withData((props: Props) => {
    const { plan, params } = props
    const certificateNumber = plan?.name

    const links: Tab[] = [
        {
            destination: planPath,
            name: 'Dashboard',
            exact: true,
        },
        {
            destination: planIncentivesPath,
            name: 'Incentives',
        },
        {
            destination: poolsPath,
            name: 'Pools',
        },
        {
            destination: vestingsPath,
            name: 'Vesting Schedule',
        },
        {
            destination: optionsPath,
            name: 'Holdings',
        },
        {
            destination: planDocumentsPath,
            name: 'Documents & Notes',
        },
    ]

    return <Tabs tabs={links} title={certificateNumber} params={params} />
})))

export function withPlanHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
    return (props: any) => (
        <React.Fragment>
            <PlanHeader {...props} />
            <InnerComponent {...props} />
        </React.Fragment>
    )
}
