import * as React from 'react'
import { Loading } from '@components/loading'
import styled from 'styled-components'
import { Uuid } from '@helpers/util'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'

interface Props {
  shareholding: Uuid
}

declare var API_URL: string

export const ImageFooter = styled.div<any>`
  color: ${props => props.theme.white};
  margin-top: 16px;
`
export const Pdf = styled(Page)`
  max-width: 100%;
  user-select: none;
`

export const PdfDocument = styled(Document)`
  .react-pdf__Page__textContent{
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    cursor: inherit;
  }
`

export const getPageWidth = () => {
  const screenWidth: any = window.screen.width
  if (screenWidth <= 800) return screenWidth - (2 * 20)
  else if (screenWidth <= 1024) return screenWidth - (2 * 40)
  else if (screenWidth <= 1440) return screenWidth - (2 * 80)
  else return 1280
}

export const CertificateView = (props: Props) => {
  const { shareholding } = props
  const [numPages, setNumPages] = React.useState(null);
  const [width, setWidth] = React.useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }
  const setPageWidth = () => {
    const pageWidth = getPageWidth()
    setWidth(pageWidth)
  }
  const fileUrl = API_URL + `/shareholding/${shareholding}/certificate/?page=1`
  window.onresize = function () {
    setPageWidth()
  }

  React.useEffect(() => {
    setPageWidth()
  }, [])

  return (
    <>
      <PdfDocument file={{
        url: fileUrl,
        withCredentials: true
      }}
        loading={<Loading />}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode='canvas'>
        {numPages !== null && Array.from(
          new Array(numPages),
          (el, index) => (
            <Pdf
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading={'Loading document'}
              width={width}
            />
          ),
        )}
      </PdfDocument>
      <ImageFooter>This is a digital representation of your share certificate.</ImageFooter>
    </>
  )
}
