import * as React from 'react'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { PathLink } from '@shared/components/navigation'
import {
  allSetPath,
  buildProfilePath,
  organizationListPath,
  profilePath,
  profilePINPath,
  User,
  welcomePath,
  logout,
  LogoutDispatch,
  OrganizationsProps,
  Paths,
  logoutPath,
  guestProfileDetailsPath,
  guestDashboardPath,
  guestPortfolioPath,
  guestWalletsPath,
  guestWalletPortfolioPath,
  guestWalletAddressPortfolioPath,
  guestWalletAddressBookPath,
  guestReferralsPath,
  guestEquaCashTransferPath,
  guestTransactionsHistoryPath,
  guestThemesPath,
  guestAccountPath,
  guestAddCardPath,
  guestAddPINPath,
  guestOrganizationsPath,
} from '@logic'
import { StyledHeader } from './style'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'
import { userHasProfile, userIsEmailVerified, userIsLoggedIn } from '@helpers/util'
import { FlexRow } from '@src/styles'
import styled from 'styled-components'
import { ComponentType, ReactComponentElement, useState } from 'react'
import { ServiceProps } from '@shared/hocs/with-service'
import { matchesPath, NavigationProps } from '@shared/components/navigation'
import { HeaderLinks } from './header-links'
import { NavigationDock } from './shared/navigation-dock'
import { GuestNavigationDock } from './shared/guest-navigation-dock'
import { rgba } from 'polished'
import { ProfileDrop } from './shared/profile-drop'
import { OrganizationLinks } from './shared/organization-links'
import { PermissionProps } from '@shared/components/permissions'
import { getProfile } from '@src/service/services/profile'
import { ProfileLinks } from './shared/profile-links'
import Logout from '@image/log-out-16x16.svg'
import EquaLogo from '@image/equa-logo-text.svg'
import { LoginSignButton } from '@shared/components/button'
import { colors, FlexCol } from '@src/styles'

interface StateProps {
  user?: User
}

interface Props extends RouteComponentProps<any> {
  history: History
  logout: LogoutDispatch
}

export interface HeaderLinks {
  path?: string
  defaultUrl?: string
  title: string
  image: ReactComponentElement<any>
}

const FixedHeader = styled<any>(StyledHeader)`
  position:fixed;
  z-index: 999;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
`

const LeftVerticalDivider = styled.div<any>`
  height: 24px;
  width: 1px;
  background: ${rgba(206, 218, 225, 0.3)};
  margin: ${props => props.styleChange === 'true' ? '0 16px' : '0 24px'};
`
const HeaderLogo = styled.span<any>``

const LogOutNav = styled.span<any>`
    width: 100%;
    padding: 8px 16px;
    /* :hover {
    } */
    span {
        color: #9CA6AD;
        margin-right: 2px;
        vertical-align: middle;
    }
    svg{
      vertical-align: middle;
    }
`

const RightVerticalDivider = styled.div<any>`
  height: 24px;
  width: 1px;
  background: ${rgba(206, 218, 225, 0.3)};
  margin: ${props => props.styleChange === 'true' ?
    props.withMargin === 'false' ? '0 8px' : '0 70px 0 8px'
    : props.withMargin === 'false' ? '0 24px' : '0 80px 0 24px'};
`
const SignupButton = styled.span<any>`
    color: lightgray;
    font-weight: 800;
    font-size: 14px;
    cursor: pointer;
    :hover{
      color:${props => props.theme.button.primary.hover};
    }
`
const LoginButton = styled.button<any>`
  font-family: 'NunitoSansExtraBold';
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 112px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  transition: all 0.3s;
  opacity: ${props => props.disabled ? 0.5 : 1.0};
  + a,
  + button {
    margin-left: 8px;
  }
  border-radius: ${props => props.theme.radius};
  color: #FFF !important;
  border: 0;
  background-color: ${props => props.submitting ?
    props.theme.button.primary.submitting
    :
    props.theme.button.primary.default};
  &:hover {
    background-color: ${props => props.theme.button.primary.hover};
  }
  &:disabled {
    background-color: ${props => props.theme.button.primary.disabled} !important;
    border: none;
  }
  margin-left: 20px;
`

type HeaderProps = StateProps & Props & NavigationProps & ServiceProps & OrganizationsProps & PermissionProps

export const HeaderComponent = (props: HeaderProps) => {
  const { user, location, navigate, httpClient, permissions, history } = props
  const [profileData, setProfileData] = useState<any>({})
  const mobileView = window.innerWidth < 1118
  const styleChange = window.innerWidth < 1290
  const permissionsData = permissions || []

  const userExists = userIsLoggedIn(user) && userHasProfile(user) && userIsEmailVerified(user)

  React.useEffect(() => {
    ; (async () => {
      if (user?.id) {
        const profileResponse: any = await getProfile(httpClient)({ profile: user.id })
        if (profileResponse?.profile) setProfileData(profileResponse.profile)
      }
    })()
  }, [])

  const matchUrls = (urls: string[]) => {
    let value: boolean = false
    urls.forEach(url => {
      const matched = matchesPath(url, location.pathname, true)
      if (matched) value = true
    })
    return value
  }
  const noLogin = matchUrls([
    welcomePath,
    guestProfileDetailsPath,
    guestDashboardPath,
    guestPortfolioPath,
    guestWalletsPath,
    guestWalletPortfolioPath,
    guestWalletAddressPortfolioPath,
    guestWalletAddressBookPath,
    guestReferralsPath,
    guestEquaCashTransferPath,
    guestTransactionsHistoryPath,
    guestThemesPath,
    guestAccountPath,
    guestAddCardPath,
    guestAddPINPath,
    guestOrganizationsPath,
  ])
  const welcomeHeader = matchUrls([welcomePath, buildProfilePath, profilePINPath, allSetPath])
  const noNavigation = matchUrls([
    welcomePath,
    buildProfilePath,
    profilePINPath,
    allSetPath,
    organizationListPath,
    Paths.newOrganization
  ])
  const noHeader = matchUrls([Paths.userThanks, Paths.userLogin])
  const inProfile = matchesPath(profilePath, location.pathname, false)
  const inOrgsList = matchesPath(organizationListPath, location.pathname, true)
  const inNewOrg = matchesPath(Paths.newOrganization, location.pathname, true)

  const AllLinks = () => {
    return !noNavigation ? inProfile ?
      <ProfileLinks permissions={permissionsData} mobileView={mobileView} navigate={navigate} />
      :
      <OrganizationLinks permissions={permissionsData} mobileView={mobileView} navigate={navigate} /> : null
  }
  return noLogin && !welcomeHeader ?
    (
      <FixedHeader showBorder={!noNavigation}>
        <GuestNavigationDock  styleChange={styleChange}/>
        <FlexRow>
          <LeftVerticalDivider styleChange={styleChange ? 'true' : 'false'} />
          <ProfileLinks permissions={permissionsData} mobileView={mobileView} navigate={navigate} forGuest={true} />
        </FlexRow>
        <FlexRow>
          {!styleChange ?<> <SignupButton onClick={() => navigate(Paths.userRegister)}>
            SIGN UP
          </SignupButton>
          <LoginButton onClick={() => navigate(Paths.userLogin)}>
            LOG IN
          </LoginButton> <LeftVerticalDivider styleChange={styleChange ? 'true' : 'false'} /> </>: null}
          <ProfileDrop profileData={profileData} forGuest={true} />
        </FlexRow>
      </FixedHeader>
    )
    : userExists && !noHeader ?
      !welcomeHeader ? (
        <FixedHeader showBorder={!noNavigation}>
          <FlexRow>
            <NavigationDock
              inOrgsList={inOrgsList}
              inProfile={inProfile}
              user={user}
              styleChange={styleChange}
              {...props} />
            <LeftVerticalDivider styleChange={styleChange ? 'true' : 'false'} />
            {!mobileView && <AllLinks />}
          </FlexRow>
          <FlexRow>
            {mobileView && <AllLinks />}
            <RightVerticalDivider
              withMargin={!inProfile && !inOrgsList && !mobileView && !inNewOrg ? 'true' : 'false'}
              styleChange={styleChange ? 'true' : 'false'} />
            <ProfileDrop profileData={profileData} />
          </FlexRow>
        </FixedHeader>
      ) : (
        <FixedHeader showBorder={!noNavigation}>
          <FlexRow>
            <EquaLogo />
          </FlexRow>
          <PathLink path={logoutPath} args={{}}>
            <FlexRow>
              <LogOutNav> <span>LOG OUT</span> <Logout /></LogOutNav>
            </FlexRow>
          </PathLink>
        </FixedHeader>
      ) : null
}

const mapStateToProps: StateTransform<StateProps> = s => ({
  user: s.user,
})

export const Header = withRouter(connect(mapStateToProps, { logout })(HeaderComponent))

export function withHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
  return (props: any) => (
    <React.Fragment>
      <Header {...props} />
      <InnerComponent {...props} />
    </React.Fragment>
  )
}