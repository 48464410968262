import * as React from 'react'
import { colors, FlexCol, FlexRow, semiBoldStyle } from '@src/styles'
import { Panel } from '@shared/components/panels/panels'
import styled from 'styled-components'
import { optionalCommaNumber } from '@shared/helpers'

interface Props {
    current: number
    allocated: number
}

const TitleRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px;
    color: ${({ theme }) => theme.white};
    margin-bottom: 16px;
    h5 {
        margin: 0;
        ${semiBoldStyle}
        font-size: 24px;
        line-height: 32px;
    }
`

const StyledInfo = styled<any>(FlexRow)`
    width: 100%;
    padding: 12px;
    justify-content: space-between;
    color: ${({ theme }) => theme.white};
    span {
        line-height: 24px;
    }
`

const DataWrap = styled<any>(FlexCol)`
    overflow-y: auto;
    height: ${props => props.overflow === 'true' ? '236px' : 'auto'};
    display: ${props => props.overflow === 'true' ? 'block' : 'flex'};
    padding: ${props => props.overflow === 'true' && '8px'};
`

const WarningSpan = styled.span<any>`
    color: ${props => props.warning === 'true' && colors.errorRed} !important;
`

export const PoolTreasuryCalculator = (props: Props) => {
    const { current, allocated } = props
    const balance = current - allocated
    return (
        <Panel max={'628px'}>
            <TitleRow>
                <h5>Pool Treasury Calculator</h5>
            </TitleRow>
            <DataWrap>
                <StyledInfo>
                    <span>Current Equity Treasury</span>
                    <WarningSpan warning={Math.sign(current) === -1 ? 'true' : 'false'}>{optionalCommaNumber(current)}</WarningSpan>
                </StyledInfo>
                <StyledInfo>
                    <span>Allocated Amount</span>
                    <span>{optionalCommaNumber(allocated)}</span>
                </StyledInfo>
                <StyledInfo>
                    <span>Balance Remaining</span>
                    <WarningSpan warning={Math.sign(balance) === -1 ? 'true' : 'false'}>{optionalCommaNumber(balance)}</WarningSpan>
                </StyledInfo>
            </DataWrap>
        </Panel>
    )
}
