import * as React from 'react'
import { organizationNewSecurityPath } from '@logic'
import { CenteringContainer, StyledP } from '@src/styles'
import { PathLinkButton } from '@components/button/button'
import { Uuid } from '@helpers/util'

interface Props {
  organization: Uuid
  organizationName: string
}

export function NeedsSecurityTypes(props: Props) {
  const { organization, organizationName } = props
  return (
    <CenteringContainer>
      <StyledP>{organizationName} does not have any equities yet, which are needed for drafting.</StyledP>
      <StyledP>You can create equities in your organization settings. </StyledP>
      <PathLinkButton path={organizationNewSecurityPath} args={{ organization }}>Add New Equity</PathLinkButton>
    </CenteringContainer>
  )
}
