import { Action, AnyAction, Reducer } from 'redux'

export type DefinedReducer<S = any, A extends Action = AnyAction> = (state: S, action: A) => S

export type DefinedReducersMapObject<S = any, A extends Action = Action> = Partial<{ [K in keyof S]: Reducer<S[K], A> }>

export function pipeReducers<S, A extends Action = AnyAction>(reducers: Array<Reducer<S, A>>): Reducer<S, A> {
  return function(initialState: S | undefined, action: A) {
    return reducers.reduce((previousState, r) => r(previousState, action), initialState) as S
  }
}

export function pipeDefinedReducers<S, A extends Action = AnyAction>(reducers: Array<DefinedReducer<S, A>>): DefinedReducer<S, A> {
  return function(initialState: S, action: A) {
    return reducers.reduce((previousState, r) => r(previousState, action), initialState)
  }
}

export function combineReducers<S, A extends Action = AnyAction>(reducers: any): Reducer<S, A> {
  return (state: any, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
      const { router } = state
      state = { router }
    }
    const newState: any = { ...state }
    for (const key of Object.keys(reducers)) {
      const reducer = reducers[key] as DefinedReducer<any, A>
      const substate = newState[key]
      newState[key] = reducer(substate, action)
    }

    return newState
  }
}

export function combineDefinedReducers<S, A extends Action = AnyAction>(reducers: DefinedReducersMapObject<any, A>): DefinedReducer<S, A> {
  return combineReducers(reducers as any)
}

export function wrapDefinedReducer<S, A extends Action = AnyAction>(reducer: DefinedReducer<S, A>): Reducer<S, A> {
  return (state, action) => {
    if (!state) throw new Error('Redux state not inititalized')

    return reducer(state, action)
  }
}
