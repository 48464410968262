import * as React from 'react'
import { useState } from 'react'
import { editMemberPath, ExistingMember, inviteMembersPath, MembersProps, newMemberPath, organizationMemberPath, PageProps } from '@logic'
import { ellipsesColumn } from '@components/tables/ellipses'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers, loadRoles } from '@helpers/loaders'
import { Avatar } from '@components/avatar'
import { newDeleteConfirmationModal } from '@modules/documents/components/modals'
import { daysSince, formatDateString, Uuid } from '@helpers/util'
import { PageContent } from '@components/pages'
import { Button, PathLinkButton, PathLinkButtonInverted } from '@components/button/button'
import { PageContentHeader } from '@components/pages/page-content-header'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { SortRowsState, Table } from '@components/tables/table'
import { FlexRow } from '@src/styles'
import { PathLink } from '@components/navigation/path-link'
import { CellProps } from 'react-table'
import { Checkbox } from '@components/checkbox'
import { Set } from 'immutable'
import { overflowParams } from '@shared/components/navigation'
import { GetRolesResponse } from 'service'
import { legacyRoles } from '@shared/components/forms'

type Props = PageProps & MembersProps & PermissionProps & GetRolesResponse

const withData = withLoadingCachedMultiple<Props>(loadMembers, loadPermissions, loadRoles)

export function getInvitationStatus(member: ExistingMember): string {
  if (member.user)
    return 'Joined'

  if (!member.email)
    return 'No Email Address'

  if (!member.lastInvited)
    return 'Not Invited'

  const lastInvited = new Date(member.lastInvited)
  const days = daysSince(lastInvited.getTime())

  if (days < 1)
    return 'Invited Today'

  if (days == 1)
    return 'Invited Yesterday'

  return `Invited ${days} Days Ago`
}

export const MembersPage = withData(
  withPermissions([BuiltInPermission.viewMembers, BuiltInPermission.viewSelf])((props: Props) => {
    const [selectedMember, setSelectedMember] = useState<Uuid | undefined>(undefined)
    const [selection, setSelection] = useState<Set<Uuid>>(Set.of())
    const [sorts, setSorts] = React.useState<SortRowsState>([])
    const { organization } = props.params
    const { members, permissions, navigate, reload, user, roles } = props
    const selectableRoles = roles!!
    const deleteModal = newDeleteConfirmationModal(
      async () => {
        const deletes = await props.serviceOld.deleteMember(organization, selectedMember!)
        setSelectedMember(undefined)
        deleteModal.setVisible(false)
        reload()
      },
      true,
    )

    const isSelected = (member: Uuid) => {
      return selection.includes(member)
    }

    const toggleSelected = (member: Uuid) => {
      const modifiedSelection = isSelected(member)
        ? selection.remove(member)
        : selection.add(member)

      setSelection(modifiedSelection)
    }

    const confirmDeletion = (member: Uuid) => {
      setSelectedMember(member)
      deleteModal.setVisible(true)
    }

    const editMember = (member: Uuid) => {
      navigate({ pathname: editMemberPath, search: `parent=${window.location.pathname}` }, { organization, member })
    }

    const canEdit = hasPermission(permissions)(BuiltInPermission.editMembers)
    const shouldViewMembers = hasPermission(permissions)(BuiltInPermission.viewMembers)
    const shouldViewSelf = hasPermission(permissions)(BuiltInPermission.viewSelf)
    const visibleMembers = !shouldViewMembers && shouldViewSelf
      ? members.filter(member => member.user === user!.id)
      : members
    const membersWithStatus = visibleMembers!.map(el => {
      const allRoles = el.roles && el.roles.length > 0 ? el.roles.map(r => {
        const role = legacyRoles?.find(({ value }) => value === r) || selectableRoles?.find((role) => role?.id! === r)
        return role?.label || role?.name
      })?.filter(r => r) : []
      const rolesSort = allRoles && allRoles.length > 0 && allRoles[0]
      return { ...el, status: getInvitationStatus(el), rolesSort, allRoles }
    })

    const columns = [
      {
        Header: 'Name',
        accessor: 'fullName',
        sortType: 'caseInsensitive',
        Cell: ({ row }: CellProps<any>) => {
          const name = row.original.fullName
          return (
            <PathLink path={organizationMemberPath} args={{ organization, member: row.original.id }}>
              <FlexRow>
                <Avatar fullName={name} />
                <span>{name}</span>
              </FlexRow>
            </PathLink>
          )
        },
      },
      {
        Header: 'Roles',
        accessor: 'rolesSort',
        sortType: 'caseInsensitive',
        Cell: ({ row }: CellProps<any>) => {
          return row?.original?.allRoles?.map((role: string, i: number) => {
            const showComma = i < row.original.allRoles.length - 1
            return (
              <span key={i}>
                {role}
                {showComma && ', '}
              </span>
            )
          })
        },
      },
      { Header: 'Title', accessor: 'title', sortType: 'caseInsensitive' },
      {
        Header: 'Member Since',
        accessor: 'created',
        Cell: ({ row }: CellProps<any>) => formatDateString(row.original.created),
      },
      {
        Header: 'Invite Status',
        accessor: 'status',
        Cell: ({ row }: CellProps<any>) => <span>{row.original.status}</span>,
      },
      {
        id: 'select',
        Header: 'Select',
        Cell: ({ row }: CellProps<any>) => <Checkbox checked={isSelected(row.original.id)}
          onChange={() => toggleSelected(row.original.id)} noLabel={true} />,
        className: 'center'
      },
      ellipsesColumn(canEdit ?
        [
          ['View', ({ row }) => navigate(overflowParams(organizationMemberPath, {
            organization,
            member: row.original.id,
            parent: window.location.pathname
          }))],
          ['Edit', ({ row }) => editMember(row.original.id)],
          ['Delete', ({ row }) => confirmDeletion(row.original.id)],
        ] :
        [
          ['View', ({ row }) => navigate(overflowParams(organizationMemberPath, {
            organization,
            member: row.original.id,
            parent: window.location.pathname
          }))]
        ]
      ),
    ]

    const handleSort = (id: string) => {
      const existing = sorts.filter(sort => sort.id == id)[0]
      const desc = existing ? !existing.desc : false
      setSorts([{ id, desc }])
    }

    return (
      <>
        {deleteModal.component}
        <PageContent>
          <PageContentHeader title="Team Members">
            {canEdit && (
              <FlexRow alignItems={'baseline'}>
                <Button onClick={() => navigate(overflowParams(inviteMembersPath, { organization, members: selection.toArray() }))}
                  disabled={selection.isEmpty()}>
                  Invite Selected
                </Button>
                <PathLinkButton path={newMemberPath} args={{ organization }}>
                  Add Member
                </PathLinkButton>
              </FlexRow>
            )}
          </PageContentHeader>
          <Table columns={columns} data={membersWithStatus} sortById={sorts} handleSort={handleSort} scrollingTable={true} pageSize={50} />
        </PageContent>
      </>
    )
  })
)
