import * as React from 'react'
import styled, { css } from 'styled-components'
import { Divider, FlexRow, ListLabel, ListValue, semiBoldStyle } from '@src/styles'
import { Panel } from '@components/panels/panels'
import { CashTransferFormFields } from '../pages/transfer-org-equa-cash'
import { optionalCommaAmount } from '@shared/helpers'

interface Props {
  values: CashTransferFormFields
  balance: number,
}

const Info = css`
  font-size: 16px;
  line-height: 24px;
`

const StyledDetails = styled<any>(Panel)`
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  color: ${props => props.theme.white};
  padding: 16px;
  border-radius: 4px;
  ${Info}
  color: ${props => props.theme.gray};
  margin: 0;
  span {
    ${Info}
  }
`
const StyledHeader = styled.h5<any>`
  font-size: 24px;
  line-height: 32px;
  padding: 8px;
  margin: 0 0 16px 0;
  color: ${({ theme }) => theme.white};
  ${semiBoldStyle}
`

const Divide = styled.div<any>`
  ${Divider}
  width: 100%;
  margin: ${props => props.stretch && '16px 0'};
  border-bottom: 1px solid rgba(206, 218, 225, 0.3);
`

const InfoRow = styled<any>(FlexRow)`
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  width: 100%;
`

const sharedListLabel = {
  lineHeight: '24px',
  fontWeight: 'normal',
  fontFamily: 'NunitoSans'
}

const renderInfo = (label: string, value: string | undefined, index: number, error?: string) => {
  return (
    <InfoRow key={index}>
      <ListLabel error={error} style={sharedListLabel}>{label}</ListLabel>
      <ListValue error={error}>
        {value}{' '}E$
      </ListValue>
    </InfoRow>
  )
}

export const TransferInfo = (props: Props) => {
  const { values, balance } = props
  const newBalance = balance - (parseFloat(values?.value?.toFixed(2)) || 0)
  const details = [
    { label: 'Current Balance', value: optionalCommaAmount(balance || 0) },
    { label: 'Transaction Amount', value: optionalCommaAmount(values?.value || 0) },
  ]

  return (
    <StyledDetails>
      <StyledHeader>Transfer Ledger</StyledHeader>
      {details.map((detail, i: number) => renderInfo(detail.label, detail.value, i))}
      <Divide stretch={true} />
      <InfoRow>
        <ListLabel style={sharedListLabel}>New Balance</ListLabel>
        <ListValue>
          {optionalCommaAmount(newBalance.toFixed(2))}{' '}E$
        </ListValue>
      </InfoRow>
    </StyledDetails>
  )
}
