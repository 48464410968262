import * as React from 'react'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import styled from 'styled-components'
import { FlexCol, FlexRow, screenSizes } from '@src/styles'
import { SolidButton } from '@shared/components/button'
import Ticket from '@image/ticket-modal.svg'
import { Link } from 'react-router-dom'
import { PathLink } from '@shared/components/navigation'
import { billingPath, organizationReferralsPath, referralsPath } from '@logic'

interface Props {
  onCancel: () => void
  organization?: string
}

const WelcomeWrap = styled<any>(FlexRow)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  overflow-y: auto;
  background: ${props => props.theme.modalBack};
  img {
    width: 144px;
    height: 216px;
  }
  .body {
    padding: 8px;
    align-items: flex-start;
    margin-left: 16px;
  }
  .title {
    margin-bottom: 8px;
  }
  .withMargin {
    margin-top: 24px;
  }
  @media(max-width: ${screenSizes.M}px) {
    flex-direction: column;
    img {
      width: 94px;
      height: 140px;
    }
  }
`
const DescView = styled.div`
  color: ${props => props.theme.white} !important;
  span {
    display: block;
  }
`

const EarnView = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  margin-bottom: 8px;
  align-items: flex-start;
  span {
    display: block;
  }
`

export function learnMoreModal(props: Props) {
  const { onCancel, organization } = props
  const LearnMoreModalComponent = () => {
    return (
      <WelcomeWrap>
        <Ticket />
        <div className='body'>
          <EarnView>
            <span className='title'>How to earn:</span>
            <span>Share your custom referral link and invite colleagues and friends to Equa. When they join, you both earn 1 scratch-off ticket!</span>
            <span className='withMargin'>Earn even more E$ scratch-off tickets as you complete tasks when onboarding your organization. So easy!</span>
          </EarnView>
          <DescView>
            <span className='title'>How to spend:</span>
            <span>Spend your Equa Cash on your Equa subscription dues when you upgrade your plan on the billing page.</span>
            <span className='withMargin'>Transfer E$ between your Profile and Orgs to spend it on your preferred organization too!</span>
          </DescView>
        </div>
      </WelcomeWrap>
    )
  }

  return newBasicModalBundle({
    title: 'How to Earn & Spend Equa Cash',
    body: <LearnMoreModalComponent />,
    buttons: [{
      text: 'Great!',
      onActivate: onCancel,
      componentType: SolidButton,
    }],
    max: '606px',
    noCancel: true,
    align: 'center',
  })
}
