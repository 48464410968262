import {
  ShareholdingProps,
  ShareholdingsProps,
  TransferSharesResponse,
  Legend,
  DividendConfig,
  SharePricing,
  ShareType,
  FileRecord,
  SecurityType
} from '@logic'
import { Hash, Money, Uuid } from '@shared/helpers'
import { ConvertibleInstrument } from '@modules/convertibles/utility'
import { IncentiveTypes } from '@shared/helpers/constants'
import { HoldingRecordType } from '@shared/helpers/holdings'
import { NewVestingSchedule } from './options'

export const shareholdingsAccessDeniedResponse: ShareholdingsProps = {
  shareholdings: [],
  shareholdingsAccessDenied: true,
}

export const shareholdingAccessDeniedResponse: ShareholdingProps = {
  shareholding: undefined,
  shareholdingAccessDenied: true,
}

export const transferAccessDeniedResponse: TransferSharesResponse = {
  newShareholdings: undefined,
}

export interface ExpandedSecurity {
  hash: Hash
  certifiedShares: boolean
  dividendConfig?: DividendConfig
  filingDate?: Date
  fractionalShares: boolean
  votingShares: boolean
  incorporationDocument?: Hash
  organization: Uuid
  legend: Legend
  seniority: number
  shareClass: string
  shareClassPrefix?: string
  sharePricing: SharePricing
  shares: number
  shareType: ShareType
  name?: string
  parent?: string
  deleted?: boolean
  stats: {
    treasury: number
  }
}

export interface SecurityTypeStats {
  hash: string
  totalAuthorized: number
  outstanding: number
  fullyDiluted: number
  treasury: number
  exercised: number
  totalOptions: number
  reserved: number
}

export interface GetSecuritiesResponse {
  securities: ExpandedSecurity[]
  stats: SecurityTypeStats[]
}

export interface NewSecurityResponse {
  operatingAgreement: Hash,
  security: ExpandedSecurity
}

export interface EditSecurityTypeResponse {
  security: ExpandedSecurity
}

export interface GetSecurityTypeResponse {
  security: ExpandedSecurity
}

export interface MemberAuthor {
  member?: Uuid
  user: Uuid
  name: string
}

export interface ExpandedLegend {
  hash: Hash
  content: string
  name: string
  organization: Uuid
  author: MemberAuthor
  created: Date
  deleted?: boolean
}

export interface GetLegendResponse {
  legend: ExpandedLegend
}

export interface GetLegendsResponse {
  legends: ExpandedLegend[]
}

export interface NewLegendResponse {
  legend: {
    hash: Hash
  }
}

export interface CapitalChange {
  hash: Hash
  mod: Money
  source?: string // Can be a hash, UUID, or some other external form of unique id
  timestamp: Date
  type: Uuid
  organization: Uuid
}

export interface Holding {
  created?: Date
  id?: Uuid
  hash: Hash
  abbreviation?: string
  convertibleInstrument?: Hash
  entity: Uuid
  groups?: Hash
  issueDate?: Date
  legend?: Hash
  name?: string
  owner: string
  parent?: Hash
  plan?: Hash
  previous?: Hash
  pricePerUnit?: number
  securityDetails?: Hash
  serialNumber?: number
  startDate?: Date
  startingValue?: number
  tickerSymbol?: string
  value: number
  vestingSchedule?: Hash
  issuanceAgreement?: Hash
  strikePrice?: number
  type?: IncentiveTypes
  authorized?: number
  outstanding?: number
  fullyDiluted?: number
  holdingType?: HoldingRecordType
  internalType?: string
  class?: string
}

export interface GetHoldingsResponse {
  holdings: Holding[]
}

export interface NewHoldingResponse {
  hash: string
  abbreviation?: string
  issueDate?: Date
  legend?: Hash
  name?: string
  entity: Uuid
  owner: string
  parent?: Hash
  plan?: Hash
  previous?: Hash
  pricePerUnit?: number
  seniority?: number
  value: number,
  tickerSymbol?: string,
  vestingSchedule?: Hash,
  convertibleInstrument?: Hash
}

export interface Repayment {
  hash: Hash
  convertibleInstrument: Hash
  issueDate: Date
}

export interface ExpandedHolding extends Omit<Omit<Omit<Omit<Holding, 'convertibleInstrument'>, 'vestingSchedule'>, 'groups'>, 'issuanceAgreement'> {
  convertibleInstrument?: ConvertibleInstrument | Hash
  seniority?: number
  repayment?: Repayment | Hash
  vestingSchedule?: NewVestingSchedule | Hash
  availableShares?: number
  exercisedShares?: number
  nextVestingEvent?: Date
  vestedShares?: number
  originalPricePerUnit?: number
  capitalContribution?: number
  capitalContributionTotals?: any
  isCancelled?: boolean
  securityType?: SecurityType
  // holdingType?: HoldingType
  incentiveTypes?: Uuid
  groups?: Uuid[] | Hash
  equity?: Uuid | Hash
  issuanceAgreement?: FileRecord | Hash
  fullyDilutedPercentage?: number
  outstandingPercentage?: number
  class?: string
  internalType?: string
  security?: Uuid | Hash
}

export interface ExpandedHoldingResponse {
  holding: ExpandedHolding
}

export interface ExpandedHoldingsResponse {
  holdings: ExpandedHolding[]
  voidedHoldings?: ExpandedHolding[]
}

// Root field names are singular even though they are arrays to better support dynamic handling.
// Plural tense would make that more complicated
export interface QueryResponse {
  holding?: ExpandedHolding[]
  voidedHoldings?: ExpandedHolding[]
  equity?: SecurityType[]
}

export interface ExpandedPoolDashboard extends Holding {
  internalType?: string
  treasury?: number
  investment?: number
  ownership?: number
  equityName?: string
}

export interface GetPoolsReportResponse {
  pools: ExpandedPoolDashboard[]
}

export interface ExpandedCaptableDashboard {
  id: string
  name: string
  email: string
  user: string
  memberOutstanding?: number
  memberFullyDiluted?: number
  memberOutstandingPer?: number
  memberFullyDilutedPer?: number
  memberInvestment?: number
  holdings: ExpandedHolding[]
  voided: ExpandedHolding[]
}

export interface GetCaptableDashboardResponse {
  members: ExpandedCaptableDashboard[]
}