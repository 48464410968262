import Modal from '@components/modal'
import { ButtonLayout } from '@components/modal/style'
import Close from '@image/close-default.svg'
import { FlexRow, LightDivider, whiteColor } from '@src/styles'
import { Button, SubmitButton } from '@components/button'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { gapSizes } from '@src/styles/theme'
import arrayMutators from 'final-form-arrays'
import Ticket from '@image/ticket-modal.svg'
import { PathLink } from '@shared/components/navigation'
import { referralsPath } from '@logic'
import { rgba } from 'polished'
import { Link } from 'react-router-dom'
import RocketBig from '@image/rocket-big.svg'

interface FormProps {
    title: string
    onCancel?: () => void
    onSubmit: (props: any) => void
    submitText: string
    scratch?: boolean
}

const TitleView = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.white};
  line-height: 32px;
  font-weight: bold;
  font-family: 'NunitoSansBold';
`

const ModalCloseButton = styled.div`
  position: absolute;
  top: 40px;
  right: 36px;
  width: auto !important;
  margin: 0 !important;
  cursor: pointer;
  svg {
    fill: #9CA6AD;
  }
`

const WelcomeWrap = styled.div`
  color: ${props => props.theme.white} !important;
  width: 100%;
  padding: 32px 32px 24px 32px !important;
  overflow-y: auto;
  background: ${props => props.theme.welcomeBack};
  img {
      width: 128px;
      height: 192px;
  }
  .body {
      padding: 24px 0;
      align-items: flex-start;
  }
`
const DescView = styled.div`
  color: ${props => props.theme.white} !important;
  margin-left: 16px;
  span {
      line-height: 24px;
      display: block;
  }
  a {
      line-height: 24px;
      :hover {
        text-decoration: underline;
      }
  }
`

const FormButtonLayout = styled<any>(FlexRow)`
  padding-top: 24px;
  justify-content: flex-end;
  button {
    height: 40px;
    padding: 0 32px !important;
  }
`


const StyledDivider = styled<any>(LightDivider)`
    margin: ${props => props.stretch && '0 -5.5%'};
    border-bottom: 1px solid #CEDAE1;
`

export function ChecklistModalComponent(props: FormProps) {
    const { title, onSubmit, submitText, scratch } = props
    const onCancel = props.onCancel || function () { }
    return (
        <Modal max={'590px'}>
            <ModalCloseButton onClick={onCancel}>
                <Close />
            </ModalCloseButton>
            <WelcomeWrap>
                <TitleView>{title}</TitleView>
                <FlexRow className="body">
                    {!scratch ?
                        <>
                            <RocketBig />
                            <DescView>
                                <span>We’ve launched our new Onboarding Checklist to guide you through the Equa app and lead you to specific pages to build your organization.
                                    Thank you for using Equa!</span>
                            </DescView>
                        </>
                        :
                        <>
                            <Ticket />
                            <DescView>
                                <span>Equa Cash Scratch-Off Tickets are awarded throughout the
                                    app as you complete certain tasks and by inviting others using your referral link to Equa.</span>
                                <span>As you scratch-off each one, you earn a random value amount which is collected in your Equa Cash bank on your
                                    {' '}<Link to={{ pathname: referralsPath }} target="_blank">Profile Referrals</Link> page.
                                    Use your Equa Cash towards your Equa subscription fees.</span>
                            </DescView>
                        </>
                    }
                </FlexRow>
                <StyledDivider stretch={true} />
                <FormButtonLayout>
                    <SubmitButton onClick={onCancel}>
                        {props.submitText}
                    </SubmitButton>
                </FormButtonLayout>
            </WelcomeWrap>
        </Modal>
    )
}

export interface BasicModalBundle {
    component: ReactElement
    isVisible: boolean
    setVisible: (value: boolean) => void
}

export function checklistModalBundle(props: FormProps): BasicModalBundle {
    const [isVisible, setVisible] = useState<boolean>(false)
    const component = (
        <>
            {isVisible ? (
                <ChecklistModalComponent {...props} />
            ) : null}
        </>
    )
    return {
        component,
        isVisible,
        setVisible,
    }
}
