import * as React from 'react'
import {
  convertibleInstrumentsPath,
  OrganizationProps,
  PageProps,
  setExpanded,
  viewConvertibleInstrumentPath,
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadLegends, loadOrganizationHoldings, loadSecurities } from '@helpers/loaders'
import { CommonForm, Sticky, UploadHandler } from '@components/forms'
import arrayMutators from 'final-form-arrays'
import {
  createAuthorization,
  createHolding,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { getMaxShares, getOrganizationTotalUnits } from '../utility'
import { getQueryParams } from '@helpers/index'
import { ConvertibleInstrumentFormFields } from '../types'
import { InstrumentDetails, NewConvertibleInstrumentsForm } from '../components'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { HoldingRecordType } from '@shared/helpers/holdings'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & ExpandedHoldingsResponse & GetLegendsResponse & StoreProps

const withData = withLoadingCachedMultiple<Props>(loadSecurities('true'), loadOrganizationHoldings('all'), loadLegends(true))

const NewConvertibleInstrumentComponent = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, holdings, legends, setExpanded, stats } = props
    const { organization } = params
    const initialValues = {
      earlyExitMultiple: 1,
      seniority: 1,
      ...getQueryParams(),
    }
    const securitiesList = securities ? securities?.filter(s => !s.parent).map((arg): any => {
      const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
      return { ...arg, stats: statsValue }
    }) : []

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: ConvertibleInstrumentFormFields) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []

      const { authorizedRaiseAmount, valuationMin, conversionDiscount } = values
      const totalUnits = getOrganizationTotalUnits(securities)
      const maxUnits = getMaxShares({
        maxUsd: authorizedRaiseAmount!,
        valuationMin,
        totalUnits,
        discount: conversionDiscount!
      })

      const convertibleInstrument = {
        accrualFrequency: values?.accrualFrequency!,
        authorizedRaiseAmount: values?.authorizedRaiseAmount!,
        conversionDiscount: values?.conversionDiscount!,
        earlyExitMultiple: values?.earlyExitMultiple!,
        estimatedConversionPrice: values?.estimatedConversionPrice!,
        instrumentType: values?.instrumentType!,
        interestRate: values?.interestRate!,
        maturityDate: values?.maturityDate!,
        qualifiedFinancingAmount: values?.qualifiedFinancingAmount!,
        valuationMax: values?.valuationMax!,
        valuationMin: values?.valuationMin!,
      }
      const holdingRequest = {
        name: values.name,
        abbreviation: values.abbreviation!,
        seniority: values.seniority!,
        owner: organization,
        entity: organization,
        issueDate: new Date(),
        value: maxUnits,
        parent: values.convertsTo!,
        convertibleInstrument,
        legend: values?.legend!,
        pricePerUnit: values?.pricePerUnit!,
        holdingType: HoldingRecordType.convertibleInstrument,
      }
      const newInstrument = await createHolding(httpClient)({ organization }, holdingRequest)
      if (newInstrument) {
        const holding = newInstrument?.hash!
        modifyAuthorizations({ approvalDocuments, target: holding, httpClient, organization })
        setExpanded()
        navigate(viewConvertibleInstrumentPath, { organization, holding })
      }
    }

    const parent: BaseLinkProps = { path: convertibleInstrumentsPath, args: { organization } }
    const sticky: Sticky<ConvertibleInstrumentFormFields> = formProps => (
      <InstrumentDetails securities={securitiesList} values={formProps.values} forEdit={false} />
    )
    const seniorityHoldings = holdings.filter(h => h.convertibleInstrument)

    return (
      <PageContent>
        <PageContentHeader title="Create New Convertible Instrument" parent={parent} withSticky={true} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Create" sticky={sticky}>
          <NewConvertibleInstrumentsForm onUpload={onUpload} securities={securitiesList}
            holdings={seniorityHoldings} legends={legends} />
        </CommonForm>
      </PageContent>
    )
  }))

export const NewConvertibleInstrumentPage = connect(null, { setExpanded })(NewConvertibleInstrumentComponent)
