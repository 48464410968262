import styled from 'styled-components'
import { fontSizes, screenSizes } from '@src/styles/theme'
import { rgba } from 'polished'
import { FlexRow, FlexCol, semiBoldStyle } from '@src/styles'

export const StyledGraph = styled<any>(FlexRow)`
  justify-content: center;
  position: relative;
  svg {
    height: 180px;
    width: 180px;
  }
`

export const GraphInfoCont = styled<any>(FlexCol)`
  position: absolute;
`

export const GraphInfo = styled.span<any>`
  ${semiBoldStyle}
  text-align: center;
  font-size: 20px;
  line-height: 28px;
`

export const SmallInfo = styled.span<any>`
  font-size: 8px;
  line-height: 16px;
  color: #9CA6AD;
  text-transform: uppercase;
`

export const RecentActionCont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const RecentAction = styled.td`
  font-weight: bold;
  font-family: 'NunitoSansBold';
`
export const RecentActionDate = styled.td`
  text-align: right;
`
export const ShareAndRecentTitle = styled.div`
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  margin: 10px 0 10px 0;
  color: ${props => props.theme.white};
  @media (min-width: ${screenSizes.L}px) {
    font-size: 24px;
    line-height: 33px;
    margin: 0 0 22px 0;
  }
`

export const SwitchDotsCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

interface SwitchDotsProps {
  key: number
  active: boolean
  onClick: (k: number) => void
}

export const SwitchDots = styled.div<SwitchDotsProps>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: ${props => (props.active ? props.theme.accent : rgba(props.theme.white, 0.2))};
  border-radius: 50%;
  transition: 300ms background-color;

  :hover {
    background-color: ${props => props.theme.accent};
  }
`

export const NoAvailableActionBlock = styled.div`
  margin-top: 10%;
`

export const NoDataAvailable = styled.div`
  min-height: 230px;
  font-size: ${fontSizes.L}
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
`
