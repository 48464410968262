import * as React from 'react'
import { PageProps, actionsPath, ContactSupportRequest, ToastTypes } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
// import { FilesResponse, postFile, createSecurity, GetSecuritiesResponse, NewSecurityRequest } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { RequestAFeatureForm } from '../components/request-a-feature-form'
import { requestNewFeature, globalDisplayToast } from '@src/service'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>()

export const RequestAFeature = withData(
  (props: Props) => {
    const { httpClient, navigate, params } = props
    const { organization } = params
    const initialValues = { certifiedShares: false, fractionalShares: false, participatingPreferred: false }

    const onSubmit = async (values: any) => {
      const newFeatureRequest = {
        subject: 'Feature Request',
        body: values.request
      }

      const newRequest = await requestNewFeature(httpClient)({}, newFeatureRequest)
      if (newRequest) {
        globalDisplayToast(ToastTypes.success, 'Your feature request has been received!')
        navigate(actionsPath, { organization })
      }
    }

    const parent: BaseLinkProps = { path: actionsPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Request a Feature" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Send Request">
          <RequestAFeatureForm />
        </CommonForm>
      </PageContent>
    )
  }
)
