import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import {
    editPlanIncentivePath,
    newPlanIncentivePath,
    organizationViewLegendPath,
    organizationViewSecurityPath,
    PageProps,
    planIncentivePath,
    planIncentivesPath,
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission, getIncentiveTypes } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadLegends, loadPlan, loadSecurities } from '@helpers/loaders'
import { EmptyLanding } from '@shared/components/empty-landing'
import { ellipsesColumn, Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { deleteSecurity, GetLegendsResponse, GetPlanResponse, GetSecuritiesResponse } from '@src/service'
import { formatCurrency, formatSecurityName } from '@shared/helpers'
import { basicDeleteModal } from '@shared/components/modal'
import { filterEquities, filterIncentives } from '../logic'

type Props = PageProps & GetSecuritiesResponse & GetLegendsResponse & GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities(), loadLegends(true), loadPlan)

export const IncentivesList = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const [selectedIncentive, setSelectedIncentive] = React.useState<any>()
        const { params, securities, navigate, legends, httpClient, plan, stats, reload } = props
        const { organization } = params
        const securitiesList = filterEquities([], securities)
        const incentivesList = filterIncentives(securitiesList, plan)

        const handleDeleteIncentive = async () => {
            await deleteSecurity(params?.plan)(httpClient)({ organization, security: selectedIncentive?.hash })
            deleteModal.setVisible(false)
            reload()
        }
        const incentiveName = selectedIncentive ? `${selectedIncentive?.name} Incentive` : ''

        const deleteModal = basicDeleteModal(
            handleDeleteIncentive,
            [incentiveName],
        )

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink path={planIncentivePath} args={{ organization, plan: params.plan, incentive: row.original.hash }}>
                            {row.original.name}
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: ({ row }: CellProps<any>) => <span>{getIncentiveTypes[row.original.type]}</span>,
                className: 'left'
            },
            {
                Header: 'Abbreviation',
                accessor: 'abbreviation',
                Cell: ({ row }: CellProps<any>) => <span>{row.original.shareClassPrefix}</span>,
                className: 'left'
            },
            {
                Header: 'Approved Equity',
                accessor: 'equity',
                Cell: ({ row }: CellProps<any>) => {
                    const equity = securities?.find(s => s.hash === row.original.parent)
                    const text = equity ? `${formatSecurityName(equity)} ${equity.shareClassPrefix ? `(${equity.shareClassPrefix})` : ''}` : ''
                    return (
                        <PathLink path={organizationViewSecurityPath} args={{ organization, security: equity?.hash || '' }} target={'_blank'}>
                            {text}
                        </PathLink>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Strike Price',
                accessor: 'price',
                Cell: ({ row }: CellProps<any>) => <span>{row.original.price ? formatCurrency(row.original.price) : 'N/A'}</span>,
            },
            {
                Header: 'Legend',
                accessor: 'legend',
                Cell: ({ row }: CellProps<any>) => {
                    const legend = row.original.legend ? legends?.find(l => l.hash === row.original.legend)?.name : 'N/A'
                    return row.original.legend ? (
                        <PathLink path={organizationViewLegendPath} args={{ organization, legend: row.original.legend }} target={'_blank'}>
                            {legend}
                        </PathLink>
                    ) : <span>N/A</span>
                },
                className: 'left'
            },
            ellipsesColumn(
                [
                    ['View', ({ row }) => navigate(planIncentivePath, { organization, plan: params.plan, incentive: row.original.hash })],
                    ['Edit', ({ row }) => navigate(editPlanIncentivePath, { organization, plan: params.plan, incentive: row.original.hash })],
                    ['Delete', ({ row }) => { setSelectedIncentive(row.original), deleteModal.setVisible(true) }]
                ]
            ),
        ]

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    {incentivesList.length > 0 ?
                        <>
                            <PageContentHeader title="Incentives">
                                <PathLinkButton path={newPlanIncentivePath} args={params}>
                                    + Add
                                </PathLinkButton>
                            </PageContentHeader>
                            <Table columns={columns} data={incentivesList} scrollingTable={true} withAllBorders={true} noFooter={true} />
                        </>
                        :
                        <EmptyLanding path={planIncentivesPath} params={params} />
                    }
                </PageContent>
            </>
        )
    })
)
