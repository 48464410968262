import React from 'react'
import { viewRolePath } from '@logic'
import styled from 'styled-components'
import { FlexCol, FlexRow, fontSizes, Themes } from '@src/styles'
import { Panel } from './panels'
import { overflowParams, PathLink } from '../navigation'
import AdminShield from '@image/admin.svg'
import { toCommaNumber } from '@shared/helpers'
import Members from '@image/users.svg'

interface PanelProps {
  organization: string
  roles?: any
  members?: any
  navigate: any
}

export const PanelWrapper = styled<any>(Panel)`
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
    height: 100%;
    max-width: 411px;
    min-height: 184px;
    hr {
      margin: 0;
    }
    &:hover {
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      filter: drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.08));
    }
`

export const RolesPanelContent = styled<any>(FlexCol)`
  padding: 24px 32px 0 32px;
  justify-content: space-between;
  color: ${props => props.theme.white};
  position: relative;
  align-items: flex-start;
  height: 100%;
  .roleDesc {
    max-height: 48px;
    overflow: hidden;
    line-height: 24px;
    color: ${props => props.theme.listDesc};
    margin: 8px 0 24px 0;
    text-align: start;
  }
`

export const PanelRow = styled.div<any>`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  .shield {
    path{
      fill: ${props => props.theme.selected === Themes.LIGHT && '#2B3B44'}
    }
  }
`

export const RoleName = styled.div`
  font-size: ${fontSizes.M};
  text-align: left;
  width: 100%;
  line-height: 32px;
`

export const RolesFooter = styled<any>(FlexRow)`
   justify-content: space-between;
   padding: 8px 32px;
   border-top: 1px solid ${props => props.theme.highlight};
   min-height: 48px;
   a {
    line-height: 24px;
    text-transform: uppercase;
    align-self: center;
    font-weight: bold;
    font-family: 'NunitoSansBold';
    font-size: 14px;
    white-space: nowrap;
    margin-right: 10px;
  }
`

export const MembersWrap = styled<any>(FlexRow)`
  span {
    padding-right: 4px;
    line-heigh: 24px;
  }
`

type Props = PanelProps

export const RolesPanel = (props: Props) => {
  const { roles, navigate, members, organization } = props

  return roles?.map((role: any, i: number) => {
    const roleMembers = members?.filter(m => m.roles?.includes(role.id))
    const description = role?.description?.length > 70 ? role.description.slice(0, 70) + '...' : role.description
    return <PanelWrapper
      key={i}
      onClick={() => navigate(overflowParams(viewRolePath, { organization, role: role.id }))}>
      <RolesPanelContent>
        <PanelRow>
          <FlexCol alignItems={'stretch'}>
            <FlexRow>
              <RoleName>
                {role.name}
              </RoleName>
              {role.isShared && <AdminShield className='shield' />}
            </FlexRow>
            <span className="roleDesc">{description}</span>
          </FlexCol>
        </PanelRow>
      </RolesPanelContent>
      <RolesFooter>
        <PathLink key={i} path={viewRolePath} args={{ organization, role: role.id }}>
          View Permissions
        </PathLink>
        <MembersWrap>
          <span>{toCommaNumber(roleMembers?.length)}</span>
          <Members />
        </MembersWrap>
      </RolesFooter>
    </PanelWrapper>
  })
}
