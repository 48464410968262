import * as React from 'react'
import Input from '@components/input'
import { isEmail, isPassword } from '@helpers/field-validators'
import { Button } from '@components/button'
import { Link } from 'react-router-dom'
import { guestPortfolioPath, login, LoginDispatch, LoginRequest, organizationListPath, PageProps, Paths, User } from '@logic'
import { Form } from 'react-final-form'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { FlexCol } from '@src/styles'
import { getQueryParams } from '@helpers/util'
import { HeaderLink, StyledAuth, StyledAuthForm, StyledHeader } from '../style'
import { StateTransform } from '@src/index'
import { getQueryParamEmail } from '../utility'
import Carousel from '../components/carousel'
import { CommonForm } from '@shared/components/forms'
import { LoginForm } from '../components/login-form'
import arrayMutators from 'final-form-arrays'
import { PathLink } from '@shared/components/navigation'

interface Props extends RouteComponentProps<any> {
  login: LoginDispatch
}

interface StateProps {
  user?: User
}

const LoginComponent = (props: Props & StateProps & PageProps) => {
  const { history, match, navigate } = props
  const submitCallback = (data: any) => {
    const { user } = data
    if (user) {
      if (!user.emailVerified) {
        navigate(Paths.verify)
      } else {
        const from = getQueryParams().from
        if (from) {
          navigate(from)
        } else {
          navigate(organizationListPath)
        }
      }
    }
  }

  const initialValues = {
    usernameOrEmail: getQueryParamEmail(),
    password: '',
  }

  const onSubmit = (data: LoginRequest) => {
    data = { usernameOrEmail: data.usernameOrEmail, password: data.password }
    props.login(data, submitCallback)
  }
  const isRegister = match.path === Paths.userRegister || match.params.refId || match.params.inviteId
  const queryParams = getQueryParams()
  return (
    <StyledAuth>
      <StyledAuthForm>
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          stretched={true} noPrompt={true}>
          <LoginForm />
        </CommonForm>
        <FlexCol className="LoginSignupLink">
          <span>Don't have an account?
            <PathLink path={Paths.userRegister} args={queryParams}>
              {' '}Sign Up
            </PathLink>
          </span>
          <span>or</span>
          <PathLink path={guestPortfolioPath} args={{}}>Try as a guest</PathLink>
        </FlexCol>
      </StyledAuthForm>
    </StyledAuth>
  )
}

const mapStateToProps: StateTransform<StateProps> = s => ({ user: s.user })

export const Login = connect(
  mapStateToProps,
  {
    login,
  }
)(withRouter(LoginComponent as any))
