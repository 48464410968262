import * as React from 'react'
import styled from 'styled-components'
import { HeaderLinksGroup } from './style'
// import { HeaderLinksProps, SubHeaderLinks } from './sub-header-links'

type Props = HeaderLinksProps

const SubHeaderLinksGroup = styled(HeaderLinksGroup)`
  height: auto;
  background-color: ${props => props.theme.action};
  > div {
    margin-right: 0;
  }
  a {
    padding: 20px 0;
  }
`

export const SecondarySubHeader = (props: Props) => {
  const { links } = props
  return (
    <SubHeaderLinksGroup>
      {/* <SubHeaderLinks links={links} /> */}
    </SubHeaderLinksGroup>
  )
}
