import { Hash, Uuid } from '@helpers/util'


export enum TaskStatus {
  notStarted = 1,
  inProgress = 2,
  completed = 3,
  wontFix = 4,
}

export enum TaskType {
  completeSetup = 'b9614816-3a6f-4364-b001-495d6db96dba',
  exerciseOption = 'e043eaba-992e-11ea-bb37-0242ac130002',
  uploadDocument = '2bcc2a5e-bf09-44c7-a7ae-a33a5f2847df',
  captableCall = '48608f3d-3e28-4d56-8188-b34a7d667d17',
  confirmCaptable = 'f8804016-0cc7-403b-aa84-afa1cc1db9dd',
  handoffCall = '5904d1c0-6dae-460f-8fed-12fcb33e979b',
  completeOrganizationDetails = '52306433-ba2a-40d7-a77c-946ea8417ad3',
  addMember = '7d5b0f44-673a-4392-b9f9-283c4699221c',
  uploadDataRoomDocument = 'fdc1b49a-a352-4b34-9087-eb9700f8f4d0',
  createLegend = 'b91dc67a-78f0-4eb7-bd9f-2935689599d0',
  createEquity = 'aab9cb7d-5fad-4943-8992-65bf9997bee3',
  createConvertibleInstrument = '03e75e10-2bc6-44f8-af4f-57de98e234a5',
  createIncentivePlan = 'd8cffaca-2c3e-4c39-9dfb-51fcacd26586',
  createShareholding = '7fc97a36-93c7-44ac-b312-6b5404e8d2cb',
  inviteMember = '9fcd6dc7-30ce-4ca8-b98f-165226683a63',
}

export const onboardingTasks = [
  TaskType.completeSetup,
  TaskType.exerciseOption,
  TaskType.uploadDocument,
  TaskType.captableCall,
  TaskType.confirmCaptable,
  TaskType.handoffCall,
  TaskType.completeOrganizationDetails,
  TaskType.addMember,
  TaskType.uploadDataRoomDocument,
  TaskType.createLegend,
  TaskType.createEquity,
  TaskType.createConvertibleInstrument,
  TaskType.createIncentivePlan,
  TaskType.createShareholding,
  TaskType.inviteMember,
]

export interface Task {
  entity: string
  type: TaskType
  status: TaskStatus
}

export interface ExerciseOptionTask {
  id: Uuid
  option: Hash
  shares: number
}

export type TaskExtension = {} | ExerciseOptionTask

export type HeterogeneousTask = Task & TaskExtension

export interface GetTasksResponse {
  tasks: HeterogeneousTask[]
}

export interface UpdateTaskRequest {
  entity: Uuid
  type: TaskType
  status: TaskStatus
}

export interface NewUserStateRequest {
  userState?: any
}

export interface TaskOld {
  organization: string
  type: TaskType
  status: TaskStatus
}

export type TaskMap = { [key: string]: TaskOld }

export function mapTasks(tasks: TaskOld[]): TaskMap {
  const result: TaskMap = {}
  for (const task of tasks) {
    result[task.type] = task
  }
  return result
}
