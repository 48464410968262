import * as React from 'react'
import { renderFieldsSimple, textInputFieldComponent, } from '@shared/components/forms'
import { emptyValidator, isEmail } from '@shared/helpers'
import { Button } from '@shared/components/button'
import { InviteNowFormWrap } from '../style'

interface Props {
    invalid?: boolean
    submitting?: boolean
}

export const InviteForm = (props: Props) => {
    const { submitting, invalid } = props
    return (
        <InviteNowFormWrap>
            {renderFieldsSimple([
                {
                    name: 'name',
                    label: 'Recipient Name',
                    required: true,
                    validate: emptyValidator,
                    placeholder: 'Name',
                    component: textInputFieldComponent
                },
                {
                    name: 'email',
                    label: 'Email',
                    required: true,
                    validate: isEmail,
                    placeholder: 'email@example.com',
                    component: textInputFieldComponent
                },
            ])}
            <Button type='submit' disabled={invalid || submitting}>Invite Now</Button>
        </InviteNowFormWrap>
    )
}
