import { emptyValidator, nonZeroNumberWithOptionalCommas } from '@helpers/field-validators'
import * as React from 'react'
import { ShareholdingFormData, Member, ShareholdingRequest, ShareType } from '@logic'
import { optionsFromMembers, securitiesToSelectOptions, securityType, legendsToSelectOptions } from '@helpers/util'
import {
  dateField,
  renderFieldsSimple,
  selectFieldComponent,
  renderHeader,
  UploadHandler,
  uploadFileField,
  textAreaInputFieldComponent,
  additionalLegendOptions,
  numericInputFieldWithCommas,
} from '@components/forms'
import { CapitalContributions } from '@modules/captable/components/capital-contributions'
import { ExpandedSecurity, ExpandedLegend } from '@src/service'
import { formatToNumber } from '../utility'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'

export type InitialShareholdingFormValues = ShareholdingFormData

interface Props {
  members: Member[]
  securities: ExpandedSecurity[]
  legends: ExpandedLegend[]
  onUpload: UploadHandler
  values?: any
  securityType?: ExpandedSecurity
  legend?: any
}

export function topShareholderFields(members: Member[], securities: ExpandedSecurity[]) {
  const memberOptions = optionsFromMembers(members)
  return renderFieldsSimple([
    {
      name: 'member',
      label: 'Holder',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(memberOptions, 'Holder'),
    },
    {
      name: 'issueDate',
      label: 'Issue Date',
      required: true,
      ...dateField(React.useRef()),
    },
    {
      name: 'securityType',
      label: 'Equity',
      required: true,
      validate: emptyValidator,
      defaultValue: securityType?.hash,
      component: selectFieldComponent(securitiesToSelectOptions(securities), 'Equity'),
    },
  ])
}

export function securityDetailsFields(securities: ExpandedSecurity[], legendOptions: any, legend?: any) {
  return renderFieldsSimple([
    {
      name: 'shares',
      label: `Number of ${securities[0].shareType === ShareType.units ? 'Units' : 'Shares'}`,
      required: true,
      format: formatToNumber,
      placeholder: `Number of ${securities[0].shareType === ShareType.units ? 'Units' : 'Shares'}`,
      validate: nonZeroNumberWithOptionalCommas(),
      component: numericInputFieldWithCommas,
    },
    {
      name: 'legend',
      label: 'Legend',
      required: false,
      validate: emptyValidator,
      defaultValue: legend!,
      component: selectFieldComponent(legendsToSelectOptions(legendOptions), 'Legend'),
    },
  ])
}

export function notesFields(onUpload: UploadHandler) {
  return renderFieldsSimple([
    {
      name: 'approvalDocument',
      label: 'Upload Transaction Agreement',
      required: false,
      ...uploadFileField({ onUpload, placeholder: 'Upload a transaction document', accept: '.pdf' }),
    },
    {
      name: 'note',
      label: 'Notes',
      placeholder: 'Use this area to add any notes relevant to this certificate',
      required: false,
      validate: emptyValidator,
      component: textAreaInputFieldComponent()
    },
  ])
}

export const NewShareholdingForm = (props: Props) => {
  const { members, securities, legends, onUpload, values, securityType } = props
  const legend = values?.securityType ? securities?.find(s => s.hash === values.securityType)?.legend!
    :
    securityType ? securityType.legend! : undefined

  const additionalLegends = additionalLegendOptions(legends, legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  return (
    <>
      {renderHeader({ label: 'Recipient' })}
      {topShareholderFields(members, securities)}
      {renderHeader({ label: 'Transaction Value' })}
      <CapitalContributions />
      {renderHeader({ label: 'Holding Details' })}
      {securityDetailsFields(securities, legendOptions, legend)}
      <DocumentsAndNotes onUpload={onUpload} values={values} />
    </>
  )
}

export const EditShareholdingForm = (props: Props) => {
  const { members, securities, legends, onUpload, values, legend } = props
  const legendValue = legend ? legend : values?.securityType ? securities?.find(s => s.hash === values.securityType)?.legend : undefined
  const additionalLegends = additionalLegendOptions(legends, legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  return (
    <>
      {renderHeader({ label: 'Recipient' })}
      {topShareholderFields(members, securities)}
      {renderHeader({ label: 'Transaction Value' })}
      <CapitalContributions />
      {renderHeader({ label: 'Holding Details' })}
      {securityDetailsFields(securities, legendOptions, legendValue)}
      <DocumentsAndNotes onUpload={onUpload} values={values} />
    </>
  )
}
//TODO Refractor to only transform Capital Contributions instead of shareholding form data
export function prepareCapitalContributionsForSubmission(values: ShareholdingFormData): ShareholdingRequest {
  const capitalContributions = values.capitalContributions
    ? Object.keys(values.capitalContributions)
      .filter((key: string) => values.capitalContributions![key] !== null)
      .map((key: string) => {
        return { type: key, amount: values.capitalContributions![key]! }
      })
    : []
  return { ...values, capitalContributions }
}
