import { HeaderLinksGroup, StyledSubTitle, TitleHeader, TitleLogo } from '@src/app/components/header/style'
import * as React from 'react'
import { ReactComponentElement } from 'react'
import { formatAddress } from '@helpers/util'
import { Address } from '@logic'
import useWindowDimensions from '@shared/hooks/useWindowDimensions'
import { screenSizes } from '@src/styles/theme'
import { Avatar } from '@components/avatar'

interface Props {
  links?: ReactComponentElement<any>
  title: ReactComponentElement<any>
  hide?: boolean
  withMargin?: boolean
}
export const SubHeader = (props: Props) => {
  const { title, links, hide, withMargin } = props
  return (
    <>
      <HeaderLinksGroup hide={hide ? 'true' : 'false'} withMargin={withMargin ? 'true' : 'false'}>
        <div>
          {title}
          {links}
        </div>
      </HeaderLinksGroup>
    </>
  )
}

export const SubTitle = ({
  logo,
  name,
  address,
  hideLogo = false,
}: {
  logo?: string
  name?: string
  address?: Address
  hideLogo?: boolean
}) => {
  const { width } = useWindowDimensions()
  const avatarSize = width < screenSizes.S! ? '60px' : width < screenSizes.L! ? '70px' : '90px'
  return (
    <>
      <StyledSubTitle>
        {!hideLogo && name && <Avatar size={avatarSize} image={logo} fullName={name} />}
        <div>
          <TitleHeader>{name && name}</TitleHeader>
          <label>{address && formatAddress(address!)}</label>
        </div>
      </StyledSubTitle>
    </>
  )
}
