import React, { ComponentType, useEffect, useState } from 'react'
import { withCachedLoading, withLoadingCachedMultiple } from '@components/loading'
// import { CapTableTable } from './components/captable-table'
import {
    CapTableGrouping,
    captablePath,
    CapTableView,
    Features,
    FilterOption,
    FilterOptions,
    GetBlocksResponse,
    MembersProps,
    newShareholdingPath,
    OperatingAgreement,
    Organization,
    PageProps,
    selectYourPlanPath,
    ShareholdingsProps,
    transferShareholdingPath
} from '@logic'
import { loadBlocks, loadMembers, loadOrganizationHoldings, loadQueryData, loadSecurities, loadUserState } from '@helpers/loaders'
import { PathLinkButton, PathLinkButtonInverted, SolidButton } from '@components/button/button'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission, CompanyType } from '@helpers/constants'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { newBasicModalBundle } from '@components/basic-modal'
// import { RainbowBar } from './components/rainbow-bar'
import { formatSecurityAbbreviation, formatSecurityName, groupHoldingsByMember } from '@helpers/shareholdings'
import { ExpandedHolding, ExpandedHoldingsResponse, GetSecuritiesResponse, QueryResponse } from '@src/service'
import { colors, FlexRow, gapSizes, screenSizes } from '@src/styles'
import styled from 'styled-components'
import { RowsState } from '@components/tables'
import { formatDateString, formatTime, getQueryParams, Hash } from '@shared/helpers'
import { actionIds } from '@modules/actions/definition'
import { withNavigation } from '@shared/components/navigation'
import { withService } from '@shared/hocs/with-service'
import { GroupMultiSelect } from '@shared/components/select-multi/style'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { CSVLink, CSVDownload } from "react-csv";
import { CaptableTableNew } from '../components/captable-table-new'

interface StateProps {
    currentOrganization: Organization
}

interface BlockProps {
    block?: Hash
    setBlock: (block: Hash) => void
}

type BlockStateProps =
    PageProps
    & MembersProps
    & StateProps
    & GetSecuritiesResponse
    & GetBlocksResponse

type Props = BlockStateProps & BlockProps & ShareholdingsProps & QueryResponse

type WrapperProps = PageProps & PermissionProps & StateProps

const withBlockData = withLoadingCachedMultiple<Props>(loadMembers, loadPermissions, loadSecurities, loadBlocks, loadUserState)
const withData = withCachedLoading<any, Props>(loadQueryData, ['block'])

function withBlockState(Child: ComponentType<any>) {
    return (props: BlockStateProps) => {
        const { params } = props
        const blockVal = params.block
        const [block, setBlock] = useState<Hash | undefined>(blockVal)
        return <Child {...props} block={block} setBlock={setBlock} />
    }
}

const colorsList = ['#F29D4A', '#EB5757', '#9B51E0', '#56CCF2', '#27AE60', '#F2C94C', '#2F80ED', '#FFFFFF']
const StyledGroups = styled<any>(FlexRow)`
  justify-content: flex-end;
  .right {
    margin-left: ${gapSizes.M};
  }
  @media(max-width: ${screenSizes.S}px) {
    flex-direction: column;
    .right {
      margin: ${gapSizes.M} 0 0 0;
    }
    .select {
      width: 100%;
    }
  }
`


function prepareBlocks(blocks: OperatingAgreement[]) {
    const shareholdingsHashes = Array.from(
        new Set(blocks.map(block => block.shareholdings))
    )
    return shareholdingsHashes
        .map(shareholdings => {
            const duplicates = blocks.filter(block => block.shareholdings == shareholdings)
            const first = duplicates.map(block => block.timestamp).sort()[0]
            return duplicates.find(block => block.timestamp == first)!
        })
        .sort((a, b) =>
            // Descending
            b.timestamp.localeCompare(a.timestamp)
        )
}

const CaptableNewComponent = withPermissions([BuiltInPermission.viewCapTable, BuiltInPermission.viewSelf])(
    (props: WrapperProps) => {
        const [exportData, setExportData] = useState<any>([])
        const {
            permissions,
            params,
            currentOrganization,
            navigate,
            httpClient
        } = props
        const { organization } = params
        const canEditMembers = hasPermission(permissions)(BuiltInPermission.editMembers)
        const canEditBilling = hasPermission(permissions)(BuiltInPermission.editBilling)
        const hasTransferFeature =
            currentOrganization && currentOrganization.features && currentOrganization.features.includes(Features.transfers)
        const showTransferButton = (canEditMembers && hasTransferFeature) || canEditBilling

        useEffect(() => {
            const actionId = getQueryParams()
            if (actionId!.action === actionIds.transfer) {
                if (!hasTransferFeature) {
                    upgradeModal.setVisible(true)
                }
                else {
                    navigate(transferShareholdingPath, { organization })
                }
            }
        }, [])

        const upgradeModal = newBasicModalBundle({
            title: 'Upgrade your organization to transfer shares.',
            desc:
                'Transferring shares is a premium feature. In order to transfer shares,' +
                ' purchase a subscription and upgrade to a premium account.',
            buttons: [
                {
                    text: 'Confirm',
                    componentType : SolidButton,
                    onActivate: () => {
                        navigate(selectYourPlanPath, { organization })
                    },
                },
            ],
        })

        const RenderCapTable = withService(withNavigation(withBlockData(withBlockState(withData((props: Props) => {
            const [selectedRows, setSelectedRows] = useState<RowsState>({})
            const [expandedRows, setExpandedRows] = useState<RowsState>({})
            const [chartValues, setChartValues] = useState<any[]>([])
            const [viewBy, setViewBy] = useState<CapTableView>(CapTableView.holdings)
            const [groupBy, setGroupBy] = useState<CapTableGrouping>(CapTableGrouping.member)
            const [groupSelected, setGroupSelected] = useState<FilterOption>()
            const [timeSelected, setTimeSelected] = useState<FilterOption>()
            const {
                members,
                securities,
                stats,
                blocks,
                block,
                setBlock,
                holding,
                voidedHoldings,
            } = props

            const userState = props?.userStateRecords
            const holdingsList = holding && holding.length > 0 ? holding : []
            const shareholdings = holdingsList.filter(h => h.securityDetails && !h.convertibleInstrument && !h.plan)
            const shareholders = groupHoldingsByMember(members, holdingsList, voidedHoldings, securities)
            const sortedShareholders = shareholders?.sort((a, b) => b.totals.all - a.totals.all)
            let allHoldings: any[] = []
            sortedShareholders.forEach(s => allHoldings = allHoldings.concat(s.shareholdings))

            const securitiesList = securities ? securities.map((arg): any => {
                const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
                return { ...arg, stats: statsValue }
            }).sort((a, b) => b.stats.outstanding - a.stats.outstanding) : []
            const blocksList = blocks && blocks.length > 0 ? prepareBlocks(blocks) : []

            const headers = [
                { label: 'Equa Holder Id', key: 'holderId' },
                { label: 'Holder Number', key: 'number' },
                { label: 'Holder Name', key: 'name' },
                { label: 'Email', key: 'email' },
                { label: 'Equa Holding Id', key: 'id' },
                { label: 'Security Type', key: 'equity' },
                { label: 'Certificate Number', key: 'holdingName' },
                { label: 'Issue Date', key: 'date' },
                { label: 'Amount', key: 'amount' },
            ]

            const csvData: any = allHoldings.sort((a, b) => (b.shares || 0) - (a.shares || 0))
                .map((h: any, i: number) => {
                    const owner = sortedShareholders?.find(s => s.id === h.owner)
                    return {
                        holderId: owner?.id || '',
                        name: owner?.name || '',
                        email: owner?.email || '',
                        id: h.id,
                        equity: h.security ? formatSecurityName(h.security) + ` (${formatSecurityAbbreviation(h.security)})` : '',
                        number: i + 1,
                        holdingName: h.name,
                        date: h.issueDate ? formatDateString(h.issueDate) : '',
                        amount: h.shares
                    }
                })

            const viewOptions: FilterOptions = [
                { label: 'Groups', value: CapTableView.groups },
                { label: 'Holdings', value: CapTableView.holdings }
            ]
            const groupOptions: FilterOptions = [
                { label: 'Holder', value: CapTableGrouping.member },
                { label: 'Holdings', value: CapTableGrouping.securityType }
            ]

            const noSelection = [{ label: 'No selection', value: 'current' }]
            const blockOptions: FilterOptions = blocksList?.length > 0 ? noSelection.concat(
                blocksList.map(block => {
                    return block && {
                        label: formatDateString(block.timestamp) + ' ' + formatTime(block.timestamp),
                        value: block.hash
                    }
                })) : noSelection

            useEffect(() => {
                const timeOption = blockOptions.find(b => b.value === block)
                if (timeOption) setTimeSelected({ label: `Date: ${timeOption.label}`, value: timeOption.value })
                const option = groupOptions[0]
                setGroupSelected({ label: `View: ${option.label}`, value: option.value })
            }, [])

            useEffect(() => {
                const filteredHoldings = holdingsList.filter(el => (el.convertibleInstrument || el.plan) && securitiesList.some(s => s.hash === el.parent))
                    .map(holding => {
                        const notes = holdingsList.filter(s => s.parent === holding.id)
                        const value = notes.reduce((a, b) => a + b.value, 0)
                        return {
                            value,
                            selected: false
                        }
                    })
                setChartValues(!groupBy ? sortedShareholders?.map((shareholder, i: number) => {
                    return { value: shareholder.totals.all + shareholder?.holdingTotals?.all!, selected: false }
                }) : securitiesList.map((securityType, i: number) => {
                    return {
                        value: Math.max(securityType?.stats?.totalAuthorized!, securityType?.stats?.outstanding!),
                        selected: false
                    }
                }).concat(filteredHoldings)
                )
                return () => {
                    setSelectedRows({})
                    setExpandedRows({})
                }
            }, [groupBy])

            const toggleSelectedRows = (key: number) => {
                let select = {}
                if (selectedRows && selectedRows[key] === true) {
                    select = { [key]: false }
                } else {
                    select = { [key]: true }
                }
                setSelectedRows(select)
            }
            const toggleExpandedRows = (key: number) => {
                let expand = expandedRows
                if (expand && expand[key] === true) {
                    expand = { ...expand, [key]: false }
                } else {
                    expand = { ...expand, [key]: true }
                }
                setExpandedRows(expand)
            }

            const handleSelection = (key: number) => {
                const chartV = chartValues
                toggleSelectedRows(key)
                chartV.map((arg, i: number) => {
                    if (i === key) {
                        return arg.selected = !arg.selected
                    }
                    return arg.selected = false
                })
                setChartValues(chartV)
            }

            const navigateAndUpdateBlock = (block: Hash) => {
                setBlock(block)
                if (block.length > 0) {
                    navigate(captablePath, { organization, block })
                }
            }

            const dropOptions = [
                ['Transfer', () => !hasTransferFeature ? upgradeModal.setVisible(true) : navigate(transferShareholdingPath, { organization })],
                ['Draft', () => navigate(newShareholdingPath, { organization })],
                [<CSVLink
                    filename={`Cap Table - ${currentOrganization?.name} - ${formatDateString(new Date())}.csv`}
                    data={csvData}
                    headers={headers}
                    className='withPadding'>
                    Export
                </CSVLink>],
            ]

            return <PageContent>
                {upgradeModal.component}
                <PageContentHeader title="Cap Table">
                    <FlexRow alignItems={'flex-end'}>
                        {showTransferButton ? (
                            <ActionsDrop options={dropOptions} />
                        ) : null}
                    </FlexRow>
                </PageContentHeader>
                <StyledGroups>
                    {viewBy === CapTableView.groups ? <GroupMultiSelect
                        onChange={(selection: any) => {
                            if (selection) {
                                setGroupSelected({ label: `View: ${selection.label}`, value: selection.value })
                                setGroupBy(selection.value)
                            }
                        }}
                        value={groupSelected}
                        placeholder={`View: ${groupOptions[0].label}`}
                        options={viewOptions}
                        className='select right' />
                        : null}
                    <GroupMultiSelect
                        onChange={(selection: any) => {
                            selection && navigateAndUpdateBlock(selection.value)
                        }}
                        value={timeSelected}
                        options={blockOptions}
                        placeholder={`Date: ${blockOptions[0].label}`}
                        className='select'
                    />
                    <GroupMultiSelect
                        onChange={(selection: any) => {
                            if (selection) {
                                setGroupSelected({ label: `View: ${selection.label}`, value: selection.value })
                                setGroupBy(selection.value)
                            }
                        }}
                        value={groupSelected}
                        placeholder={`View: ${groupOptions[0].label}`}
                        options={groupOptions}
                        className='select right' />
                </StyledGroups>
                {/* <RainbowBar colors={colorsList} blocks={chartValues} handleSelection={handleSelection} /> */}
                {/* <CapTableTable
                    companyType={currentOrganization.companyType || CompanyType.Other}
                    shareholders={sortedShareholders}
                    shareholdings={shareholdings}
                    selectedRows={selectedRows}
                    expandedRows={expandedRows}
                    toggleExpandedRows={toggleExpandedRows}
                    securities={securitiesList}
                    groupBy={groupBy}
                    colorsList={colorsList}
                    members={members}
                    holdings={holdingsList}
                    voidedHoldings={voidedHoldings}
                    organization={organization}
                    userState={userState}
                    httpClient={httpClient}
                /> */}
                <CaptableTableNew />
            </PageContent>
        })))))

        return <RenderCapTable {...props} />
    })

const mapStateToProps: StateTransform<any> = s => ({
    currentOrganization: s.organization!.currentOrganization,
})

export const CaptablePageNew = connect(mapStateToProps)(CaptableNewComponent)
