import styled, { css } from 'styled-components'
import { fontSizes, gapSizes, screenSizes, colors, headerHeight } from '@src/styles/theme'
import { PathLink } from '@components/navigation/path-link'

export const pageContentWidth = screenSizes.XL

export const PageContent = styled.div<any>`
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: calc(100% - 2 * var(--pageMargin));
  max-width: ${pageContentWidth}px;
  margin: 0 var(--pageMargin);
  margin-top: ${props => props.withMargin ? headerHeight : 0};
  min-height: auto;
  padding: ${props => !props.noPadding && !props.noMargin ? `${gapSizes.XXL} 0 ` : '0'};
  padding-bottom: 100px;
  transition: all 300ms ease-in-out;
`

export const TitleGrid = styled.div<any>`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  h1 {
    font-size: ${fontSizes.M};
    line-height: 32px;
    color: ${props => props.theme.white};
    grid-column: 2;
    grid-row: 1;
  }
  p {
    font-size: ${fontSizes.XS};
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
    margin: 16px 0 0 0;
    grid-column: 2;
    grid-row: 2;
    color: ${props => props.theme.white};
  }
  + * {
    display: flex;
  }
`
export const ContentHeaderStyle = styled.div<any>`
  max-width: ${props => props.withSticky == 'true' ? '630px' : '100%'};
  display: flex;
  justify-content: ${props => props.noSpace === 'false' && 'space-between'};
  align-items: center;
  padding: ${gapSizes.S} 0px;
  background-color: ${props => props.theme.secondary};
  z-index: 2;
  position: sticky;
  top: ${props => props.hideCrumbs === 'true' ? headerHeight : '94px'};
  @media (max-width: ${screenSizes.S}px) {
    flex-direction: ${props => props.direction === 'true' ? 'row' : 'column'};
    align-items: flex-start;
    a {
      margin-top: ${gapSizes.S};
    }
  }
`

export const ArrowPathLink = styled(PathLink)`
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  margin-right: 8px;
  color: black;
   img {
     width: 16px;
     height: 13px;
   }
`
