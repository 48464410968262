import * as React from 'react'
import { PageProps, User, MembersProps, OrganizationsProps, organizationListPath, portfolioPath, profileDetailsPath, editProfilePath, guestOrganizationsPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { FlexRow, whiteColor, FlexCol, fontSizes, screenSizes, colors } from '@src/styles'
import { NavigationProps } from '@components/navigation'
import { formatExtendedDateString, formatSecurityAbbreviation, formatSecurityName, getTotals, percentageString, safeDivide } from '@shared/helpers'
import styled, { css } from 'styled-components'
import { Panel, PanelGrid, SimplePanel } from '@shared/components/panels/panels'
import { ExpandedHoldingsResponse, GetAddressesResponse, GetSecuritiesResponse, Holding } from '@src/service'
import { GetColorsResponse } from '@src/service/services/profile'
import { getHoldingsTotals } from '@shared/helpers/holdings'
import { optionalUsdString } from '@modules/convertibles/components'
import { OrganizationsPanel } from '@shared/components/organizations-panel'
import { getPhoneNumber } from '../utility'
import CopyWhite from '@image/copy.svg'
import NavigationLogo from '@image/navigation1.svg'
import PersonalInfo from '@image/personal-info.svg'
import { Link } from 'react-router-dom'
import { optionalInterestFromConvertible } from '@modules/convertibles/utility'
import { UserDashRainbowBar } from '../components/user-dash-rainbow-bar'
import { renderAddress } from '@modules/organization/pages'
import { HeaderInvertedButton } from '@shared/components/forms'
import { rgba } from 'polished'
import { isHolding } from '@modules/captable/utility'
import { CopyHover, CopyWrap } from '../pages'

type Props = PageProps &
{ user?: User, forGuest?: boolean, openSignUp?: () => void } &
    OrganizationsProps &
    NavigationProps &
    GetColorsResponse &
    MembersProps &
    ExpandedHoldingsResponse &
    GetSecuritiesResponse &
    GetAddressesResponse


const UserDashboardWrapper = styled.div`
  ${whiteColor}
  .clickable {
        cursor: pointer;
        &:hover {
            color: ${props => props.theme.accent};
            text-decoration: underline;
        }
  }
  .value {
    font-size: 38px;
    line-height: 52px;
  }
  .description {
    color: ${props => props.theme.label};
    line-height: 22px;
  }
`

const GuestPanel = styled<any>(Panel)`
  display: flex;
  flex-direction: column;

  span:last-child {
      margin-top: 4px;
      color: #9CA6AD;
  }
`

const sharedStyle = css<any>`
    height: ${props => props.noAddress === 'false' ? '100%' : 'auto'};
    span {
        line-height: 24px;
        font-weight: 600;
        font-family: 'NunitoSansSemiBold';
        margin: 16px 0;
    }
`

const StyledSubHeader = styled<any>(FlexRow)`
    a {
        ${whiteColor}
        font-weight: normal;
        font-family: 'NunitoSans';
        font-size: ${fontSizes.M};
        line-height: 33px;
        margin: 49px 0 33px 0;
    }
    @media(max-width: ${screenSizes.XS}px) {
    }
`

const InfoPanel = styled<any>(FlexCol)`
  justify-content: flex-start;
  width: 100%;
  max-width: 633px;
  align-items: normal;
  background: ${props => props.theme.panelBack};
  border-radius: 8px;
  padding: 32px;
  .noAdd {
    ${sharedStyle}
    }
  h2 {
    padding: 0 !important;
  }
  a {
      text-decoration: underline;
  }
`

const AddressInfoPanel = styled<any>(InfoPanel)`
  max-height: 400px;
  .noAdd {
     ${sharedStyle}
  }
  .addWrap {
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: ${rgba(81, 111, 119, 0.101961)};
        border-radius: 6px;
        border: 4px solid transparent;
      }
      &::-webkit-scrollbar-thumb {
        border: 1px solid transparent;
        background-color: #9CA6AD;
        border-radius: 6px;
        background-clip: content-box;
      }
  }
`

const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 14px;
  @media(max-width: ${screenSizes.XS}px) {
    display: flex;
    flex-direction: column;
    &:first-child {
        margin-bottom: 14px;
    }
  }
`

const InfoWrap = styled.div<any>`
    display: flex;
    align-items: ${props => props.middle && 'center'};
    margin: 8px 0;
    &:first-child {
      margin: 0 0 8px 0;
    }
    &:last-child {
      margin: 8px 0 0 0;
    }
    div {
        margin: 0;
    }
    label {
        width: 30%;
    }
    .valueSpan {
        width: 70%;
        word-break: break-all;
        text-align: right;
    }

    @media(max-width: ${screenSizes.XS}px) {
        flex-direction: column;
        label, .valueSpan {
            width: 100%;
            justify-content: center;
            text-align: center;
        }
    }
`

const StyledPinValue = styled.span<any>`
    ${whiteColor};
    background: transparent;
    border: none;
    font-size: ${fontSizes.XS};
    outline: none;
    line-height: 24px;
    text-align: right;
    @media(max-width: ${screenSizes.XS}px) {
        text-align: center;
    }
`

const colorsList = ['#F29D4A', '#EB5757', '#9B51E0', '#56CCF2', '#27AE60', '#F2C94C', '#2F80ED', '#FFFFFF']


export const DashboardComponent = (props: Props) => {
    const [copy, setCopy] = React.useState<boolean>(false)
    const [viewOrganizations, setViewOrganizations] = React.useState<any[]>([])
    const [organizationsLength, setOrganizationsLength] = React.useState<number>(0)
    const [chartValues, setChartValues] = React.useState<any[]>([])
    const { navigate,
        organizations,
        members,
        holdings,
        securities,
        colors,
        addresses,
        forGuest,
        openSignUp } = props



    const profileInfo = props?.profile ? props.profile : undefined
    const copyCodeToClipboard = (label: string) => {
        const el = document.getElementById(label)
        if (el) {
            navigator.clipboard.writeText(el.innerText!)
            setCopy(true)
        }
    }

    const renderPanelInfo = (label: string, value: any, index: number, isLink?: boolean) => {
        return label === 'Email Address' || label === 'Phone Number' ?
            (
                <InfoWrap justifyContent={'space-between'} alignItems={'flex-start'} key={index} middle={true}>
                    <label>{label}</label>
                    {value &&
                        <FlexRow className="valueSpan" justifyContent="flex-end">
                            <StyledPinValue id={label}>{value}</StyledPinValue>
                            <CopyWrap
                                copy={copy}
                                onClick={() => copyCodeToClipboard(label)}
                                onMouseLeave={() => setCopy(false)}>
                                <div className='arrow-up' />
                                <CopyHover copy={copy}>
                                    <span>{copy ? 'Copied!' : 'Copy'}</span>
                                </CopyHover>
                                <CopyWhite />
                            </CopyWrap>
                        </FlexRow>
                    }
                </InfoWrap>
            )
            :
            (
                <InfoWrap justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
                    <label>{label}</label>
                    {isLink ?
                        value && <Link to={{ pathname: value!.includes('http') ? value! : `http://${value!}` }}
                            target="_blank" className="valueSpan">{value!}</Link>
                        :
                        <span className="valueSpan">{value}</span>
                    }
                </InfoWrap>
            )
    }

    const colorsData = colors ? colors : {}

    const holdingsList = holdings?.length > 0 ? holdings.map(holding => {
        const investment = holding.value! * holding.originalPricePerUnit!
        const current = holding.value! * holding.pricePerUnit!
        return {
            ...holding,
            color: `#${colorsData[holding?.id!]!}`,
            investment,
            current,
            return: current - investment,
        }
    }) : []

    const shareholdingsList = members?.length > 0 ? members : []
    const totals = getHoldingsTotals(holdingsList) as any

    const tableData = () => {
        let list = [] as any
        securities && securities.length > 0 && securities.forEach(security => {
            return shareholdingsList.length > 0 ? shareholdingsList?.map(holding => {
                let data: any[] = []
                const shareholdings = holding?.holdings?.length > 0 ?
                    holding.holdings.filter(el => el.equity === security.hash && !el.notInRow && !isHolding(el.holdingType)) : []
                if (shareholdings?.length > 0) {
                    const shareholdingWithValues = shareholdings.map(el => {
                        const price = safeDivide(el.capitalContribution, el.value)
                        const originalValue = el.value / 1
                        const value = originalValue - el.value!
                        return { ...el, current: el.capitalContribution, price, return: value, shares: el.value }
                    })
                    data = data.concat(shareholdingWithValues)
                }
                const allHoldings = holding?.holdings!
                let optionsAndNotes: Holding[] = []
                const parentHoldings = allHoldings?.filter(h => (h.convertibleInstrument || h.plan) && h.parent === security.hash)
                if (parentHoldings?.length > 0) {
                    parentHoldings.forEach(parent => {
                        if (parent.convertibleInstrument) {
                            const child = allHoldings?.filter(h => h.parent === parent.id)?.map(h => ({ ...h, isNote: true }))
                            if (child.length > 0) optionsAndNotes = optionsAndNotes.concat(child)
                        }
                        else {
                            const child = allHoldings?.filter(h => h.parent === parent.id)?.map(h => ({ ...h, isNote: false }))
                            if (child.length > 0) optionsAndNotes = optionsAndNotes.concat(child)
                        }
                    })
                }
                if (optionsAndNotes.length > 0) {
                    const withValues = optionsAndNotes.map(el => {
                        if (el.isNote) {
                            const parent = allHoldings.find(h => el.parent === h.id)
                            const convertibleInstrument = parent?.convertibleInstrument!
                            const currentInterest = optionalInterestFromConvertible(convertibleInstrument, el.issueDate, new Date(), el.capitalContribution, true) || 0
                            const current = el.capitalContribution + currentInterest
                            const price = (current) / 1
                            const originalValue = current * 1
                            const returnValue = originalValue - el.capitalContribution!
                            return { ...el, current, price, return: returnValue, shares: 1 }
                        }
                        else {

                            const price = safeDivide(el.capitalContribution, el.value)
                            const originalValue = el.value / 1
                            const value = originalValue - el.value!
                            return { ...el, current: el.capitalContribution, price, return: value, shares: 1 }
                        }
                    })
                    data = data.concat(withValues)
                }

                if (data?.length > 0) {
                    const total = getTotals(data)
                    const price = data.reduce((a, b) => a + b.price, 0)
                    const value = data.reduce((a, b) => a + b.return, 0)
                    const quantity = data.reduce((a, b) => a + b.shares, 0)
                    const investmentTotal = data.reduce((a, b) => a + b.current, 0)
                    const totals = {
                        value: total.all,
                        investment: total.capitalContribution,
                        current: investmentTotal,
                        return: value,
                        price,
                        quantity,
                    }
                    const color = security.id ? colorsData[security.id] ? `#${colorsData[security.id]}` : ''
                        : colorsData[security.hash] ? `#${colorsData[security.hash]}` : ''
                    list = list.concat({ ...holding, shareholdings: data, security, totals, color })
                }
            })
                : security
        })
        const data = list.length > 0 ? list.map((el, i: number) => {
            if (el.color?.length == 0) {
                return { ...el, subRows: [...el.shareholdings], color: colorsList[i % colorsList.length] }
            } else {
                return { ...el, subRows: [...el.shareholdings] }
            }
        })
            .sort((a, b) => getTotals(b.shareholdings).capitalContribution - getTotals(a.shareholdings).capitalContribution)
            : list
        return data
    }

    const totalInvestment = totals.investment! + tableData()?.reduce((a, b) => a + b.totals.investment, 0)!
    const totalInvestmentInUsd = optionalUsdString(totalInvestment, 2)
    const totalCurrentValue = totals.currentValue! + tableData()?.reduce((a, b) => a + b.totals.current, 0)!
    const totalCurrentValueInUsd = optionalUsdString(totalCurrentValue, 2)
    const lifetimeGrowth = totalCurrentValue - totalInvestment
    const lifetimeGrowthInUsd = optionalUsdString(lifetimeGrowth, 2)
    const lifetimePercentage = percentageString(totalCurrentValue, totalInvestment)
    const columns = (totalInvestmentInUsd!.length > 11 || totalCurrentValueInUsd!.length > 11 || lifetimeGrowthInUsd!.length > 11) ? 2 : 4

    React.useEffect(() => {
        const sortedAndFilteredOrganizations = organizations && organizations.length > 0 ? organizations?.sort((a, b) => {
            return a.name.localeCompare(b.name)
        }) : []
        setOrganizationsLength(sortedAndFilteredOrganizations?.length)
        if (sortedAndFilteredOrganizations && sortedAndFilteredOrganizations.length > 0) {
            const orgs = sortedAndFilteredOrganizations
            setViewOrganizations(orgs?.splice(0, 3))
        }
        const data = tableData() && tableData().length > 0 ? tableData() : []
        const assets = holdingsList!
        const chartData = assets && assets.length > 0 ? data.concat(assets) : data
        setChartValues(chartData?.filter(c => {
            const value = c.shareholdings && c.shareholdings.length > 0 ? c.shareholdings.reduce((a, b) => a + b.capitalContribution, 0) : 0
            if (value) return c
        })?.map((el: any) => {
            const value = el.shareholdings && el.shareholdings.length > 0 ? el.shareholdings.reduce((a, b) => a + b.capitalContribution, 0) : 0
            return {
                value,
                selected: false,
                color: el.color,
                name: el.security ? formatSecurityName(el.security) : '',
                abb: el.security ? formatSecurityAbbreviation(el.security) : el.abbreviation
            }
        }))
    }, [])

    const userInfo = [
        {
            label: 'Email Address',
            value: profileInfo?.email!
        },
        {
            label: 'Phone Number',
            value: getPhoneNumber(profileInfo!)
        },
        {
            label: 'Date of Birth',
            value: profileInfo?.dateOfBirth && formatExtendedDateString(profileInfo.dateOfBirth)
        },
        {
            label: 'LinkedIn',
            value: profileInfo?.links?.linkedIn!,
            isLink: true,
        },
        {
            label: 'Facebook',
            value: profileInfo?.links?.facebook!,
            isLink: true,
        },
        {
            label: 'Twitter',
            value: profileInfo?.links?.twitter!,
            isLink: true,
        },
        {
            label: 'Instagram',
            value: profileInfo?.links?.instagram!,
            isLink: true,
        },
        {
            label: 'Website',
            value: profileInfo?.website!,
            isLink: true,
        },
    ]

    return (
        <>
            <PageContent withMargin={forGuest}>
                <UserDashboardWrapper>
                    <PageContentHeader title="Dashboard" hideCrumbs={true} />
                    <StyledSubHeader>
                        <Link className="clickable" to={portfolioPath}>Holdings</Link>
                    </StyledSubHeader>
                    <PanelGrid columns={columns}>
                        <SimplePanel
                            value={totalInvestmentInUsd}
                            description="Total Investment"
                            wrap={'true'} />
                        <SimplePanel
                            value={totalCurrentValueInUsd}
                            description="Total Current Value"
                            wrap={'true'} />
                        <SimplePanel
                            value={lifetimeGrowthInUsd}
                            description="Lifetime Growth (USD)"
                            wrap={'true'}
                            rawValue={lifetimeGrowth}
                            withArrow={true} />
                        <SimplePanel value={lifetimePercentage} description="Lifetime Growth (%)" wrap={'true'} rawValue={lifetimePercentage} withArrow={true} />
                    </PanelGrid>
                    {chartValues?.length > 0 && <UserDashRainbowBar blocks={chartValues} />}
                    <StyledSubHeader>
                        <Link className="clickable" to={organizationListPath}>Organizations {organizationsLength > 0 && `(${organizationsLength})`}</Link>
                    </StyledSubHeader>
                    {forGuest ?
                        <GuestPanel>
                            <span>No organizations to display. </span>
                            <span>To add new organization, go to <Link to={guestOrganizationsPath}>Org dashboard.</Link></span>
                        </GuestPanel>
                        : <PanelGrid columns={3}>
                            <OrganizationsPanel organizations={viewOrganizations} navigate={navigate} />
                        </PanelGrid>}
                    <StyledSubHeader>
                        <Link className="clickable" to={profileDetailsPath}>Personal Information</Link>
                    </StyledSubHeader>
                    <PanelsWrapper>
                        <InfoPanel>
                            {forGuest ?
                                <FlexCol justifyContent={'center'} className='noAdd'>
                                    <PersonalInfo />
                                    <span>Add your personal information</span>
                                    <HeaderInvertedButton onClick={!forGuest ? () => navigate(editProfilePath)
                                        : openSignUp}>
                                        + Add
                                    </HeaderInvertedButton>
                                </FlexCol>
                                :
                                userInfo.map((el, index: number) => {
                                    return renderPanelInfo(el.label, el.value, index, el.isLink)
                                })}
                        </InfoPanel>
                        <AddressInfoPanel noAddress={addresses?.length > 0 ? 'true' : 'false'}>
                            {addresses?.length > 0 ?
                                renderAddress(addresses)
                                :
                                <FlexCol justifyContent={'center'} className='noAdd'>
                                    <NavigationLogo />
                                    <span>Add New Address</span>
                                    <HeaderInvertedButton onClick={!forGuest ? () => navigate(editProfilePath)
                                        : openSignUp}>
                                        + Add
                                    </HeaderInvertedButton>
                                </FlexCol>
                            }
                        </AddressInfoPanel>
                    </PanelsWrapper>
                </UserDashboardWrapper>
            </PageContent>
        </>
    )
}