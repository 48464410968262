import * as React from 'react'
import { FlexCol } from '@src/styles'
import { CardHeader, DescriptionSpan, FlowingGrid, HeaderPanel } from '@modules/referrals/style'
import { InvertedButton, PathLinkButtonInverted, SolidButton } from '../../../shared/components/button'
import ScratchOff from '@image/scratch-off.svg'
import MoneyTransfer from '@image/money-transfer.svg'
import { equaCashTransferPath, organizationCashTransferPath } from '@logic'

interface Props {
  availableTickets: number
  scratchedTickets: number
  setShowTicketsModal: any
  params: { [key: string]: string }
  forOrg?: boolean
  mobileView?: boolean
  forGuest?: boolean
  openSignUp?: () => void
}

export const ScratchOffPanels = (props: Props) => {
  const { availableTickets, scratchedTickets, setShowTicketsModal, mobileView, params, forOrg, forGuest, openSignUp } = props
  const path = forOrg ? organizationCashTransferPath : equaCashTransferPath
  return (
    <FlowingGrid columns={2}>
      <HeaderPanel>
        <FlexCol alignItems={'flex-start'} style={{ marginRight: '24px' }}>
          <CardHeader>
            {availableTickets === 1 ? availableTickets + ' scratch-off' : availableTickets + ' scratch-offs'}
            {' '}available
          </CardHeader>
          <DescriptionSpan>
            {scratchedTickets === 1 ? scratchedTickets + ' ticket' : scratchedTickets + ' tickets'}
            {' '}scratched so far
          </DescriptionSpan>
          <SolidButton onClick={setShowTicketsModal} disabled={availableTickets == 0}>Scratch-off</SolidButton>
        </FlexCol>
        <ScratchOff />
      </HeaderPanel>
      <HeaderPanel>
        <FlexCol alignItems={'flex-start'} style={{ marginRight: '24px' }}>
          <CardHeader>{mobileView ? 'Equa Cash Transfer' : 'Equa Cash Transfer & History'}</CardHeader>
          <DescriptionSpan>Spend Equa Cash on subscription dues</DescriptionSpan>
          {forGuest ? <InvertedButton onClick={openSignUp} className='transfer'>Transfer</InvertedButton>
            : <PathLinkButtonInverted path={path} args={params}>
              Transfer
            </PathLinkButtonInverted>}
        </FlexCol>
        <MoneyTransfer />
      </HeaderPanel>
    </FlowingGrid>
  )
}
