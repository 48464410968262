import { HttpClient, WebResponse } from '@src/service'
import { PurchaseRequest } from '@src/service/services/billing/requests'
import { PurchasePreviewProps, UserCouponProps } from '@src/service/services/billing/responses'
import { Uuid } from '@helpers/util'

export const previewSubscriptionPurchase = (httpClient: HttpClient) => async (
  request: PurchaseRequest,
  organizationId: Uuid
): Promise<WebResponse<PurchasePreviewProps>> => {
  return httpClient.post(`organization/${organizationId}/subscription/preview`, request)
}

export const getUserCoupon = async (httpClient: HttpClient): Promise<WebResponse<UserCouponProps>> => {
  return httpClient.get(`user/coupon`)
}
