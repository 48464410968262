import * as React from 'react'
import { createRolePath, editRolePath, MembersProps, PageProps, rolesPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers, loadRole } from '@helpers/loaders'
import { deleteRole, GetRoleResponse } from '@src/service'
import { BaseLinkProps, overflowParams } from '@components/navigation/navigation'
import { ThemeProps, withTheme } from 'styled-components'
import { FlexRow } from '@src/styles'
import { RolesWrapper, SubHeaderText } from './create-role-page'
import { allPermissions, getRolesFooterBanner } from '../utility'
import { PermissionsTable } from '../components/permissions-table'
import { AssignedMembersPanel } from '@shared/components/panels/panel-assigned-members'
import { ActionsDrop } from '@shared/components/actionsDrop'
import AdminShield from '@image/admin.svg'
import { deleteRoleModalBundle } from '../components/delete-role-modal'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'
import { hasPermission, PermissionProps } from '@shared/components/permissions'
import { Roles } from '@shared/components/forms'

type Props = PageProps & GetRoleResponse & ThemeProps<any> & MembersProps & PermissionProps

const withData = withLoadingCachedMultiple<Props>(loadRole, loadMembers)

export const ViewRolePage = withData(withTheme(withPermissions([BuiltInPermission.viewMembers])((props: Props) => {
    const { navigate, params, theme, role, members, httpClient, permissions } = props
    const { organization } = params
    const isAdmin = role?.isShared!
    const parent: BaseLinkProps = { path: rolesPath, args: { organization } }
    const permission = role?.permissions!!
    const data = allPermissions?.filter(p => permission?.some(perm => perm.id === p.id))
    const canViewOptions = hasPermission(permissions)(BuiltInPermission.editMembers)
    const assignedMembers = members?.filter(m => m.roles?.includes(role?.id))?.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName)
    })
    const isAdminRole = Roles.admin === role?.id!

    const deleteModal = deleteRoleModalBundle({
        title: assignedMembers && assignedMembers.length > 0 ? 'Are you sure?' : 'Delete this role?',
        submitText: 'Confirm',
        onSubmit: () => deleteRoleFunction(),
        onCancel: () => deleteModal.setVisible(false),
        withMembers: assignedMembers && assignedMembers.length > 0 ? true : false
    })

    const deleteRoleFunction = async () => {
        await deleteRole(httpClient)({ organization, role: role?.id! })
        deleteModal.setVisible(false)
        navigate(rolesPath, { organization })
    }

    const dropOptions = !isAdmin ?
        [
            ['Edit', () => navigate(overflowParams(editRolePath, { organization, role: role.id, parent: location?.pathname! }))],
            ['Clone', () => navigate(overflowParams(createRolePath, { organization, clone: role.id, parent: location?.pathname! }))],
            ['Delete', () => deleteModal.setVisible(true)],
        ]
        :
        [
            ['Edit', () => navigate(overflowParams(editRolePath, { organization, role: role.id, parent: location?.pathname! }))],
            ['Clone', () => navigate(overflowParams(createRolePath, { organization, clone: role.id, parent: location?.pathname! }))],
        ]

    return (
        <RolesWrapper>
            {deleteModal.component}
            <PageContent>
                <PageContentHeader title={role?.name!} parent={parent} noSpace={isAdmin} direction>
                    <FlexRow justifyContent={'flex-end'} style={isAdmin ? { marginLeft: '16px' } : { marginLeft: 'auto' }}>
                        {!isAdmin ?
                            canViewOptions && <ActionsDrop options={dropOptions} />
                            :
                            <AdminShield />
                        }
                    </FlexRow>
                </PageContentHeader>
                <SubHeaderText style={{ marginTop: '11px' }} view={'true'}>{role?.description!}</SubHeaderText>
                {data && data.length > 0 &&
                    <PermissionsTable isAdmin={isAdmin} permissions={data} view={true} />
                }
                {members && members.length > 0 &&
                    <AssignedMembersPanel
                        members={assignedMembers}
                        navigate={navigate}
                        organization={organization}
                        isAdmin={isAdmin}
                        role={role}
                        isAdminRole={isAdminRole}
                    />
                }
            </PageContent>
            <img src={getRolesFooterBanner(theme?.selected!)} alt="rolesFooter" />
        </RolesWrapper>
    )
})))
