import * as React from 'react'
import { Field } from 'react-final-form'
import { isOrganizationName, required } from '@helpers/field-validators'
import { StyledStepField } from '@components/styledForm'
import { Address } from '@components/address'
import { companyType } from '@helpers/constants'
import { useEffect } from 'react'
import { formatFieldPath, resolveObjectByString } from '@helpers/util'
import { ToggleButtons } from '@components/toggle-buttons'
import styled from 'styled-components'
import { screenSizes } from '@src/styles/theme'
import useWindowDimensions from '@src/shared/hooks/useWindowDimensions'
import { renderFieldsSimple, textInputFieldComponent } from '@shared/components/forms'

interface Props {
  path?: string
  values?: any
  next?: () => void
}

export const HeaderLabel = styled.label`
  color: ${props => props.theme.white};
`

const StyledSectionOrganization = styled.div`
  max-width: 812px;
  margin: auto;
  margin-top: 20px;
  @media (max-width: ${screenSizes.M}px) {
    max-width: none;
    width: auto;
  }
`

export const SectionOrganizationForm = (props: Props) => {
  const { values } = props
  const fieldPath = formatFieldPath(props.path)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { width } = useWindowDimensions()

  return (
    <StyledSectionOrganization>
      <StyledStepField>
        <div>
          <HeaderLabel>What is the name of your organization?</HeaderLabel>
          {renderFieldsSimple([
            {
              name: fieldPath('name'),
              label: 'Organization name',
              required: true,
              validate: isOrganizationName,
              placeholder: 'Enter your organization name',
              component: textInputFieldComponent,
            },
          ])}
        </div>
      </StyledStepField>
      <StyledStepField>
        <div>
          <HeaderLabel>What is the business address for your organization?</HeaderLabel>
          <Address name={'address'} />
        </div>
      </StyledStepField>
      <Field
        name={fieldPath('companyType')}
        validate={required}
        render={({ meta }) => (
          <StyledStepField>
            <div>
              <label>Are you taxed as an LLC or as an S-Corp/C-Corp?</label>
              <ToggleButtons
                name={fieldPath('companyType')}
                items={companyType}
                selected={resolveObjectByString(fieldPath('companyType'), values)}
                vertical={width < screenSizes.S!}
              />
            </div>
          </StyledStepField>
        )}
      />
    </StyledSectionOrganization>
  )
}
