import {
  newDeleteFunction,
  NewHoldingResponse,
  newPostAndPutFunctions,
  newPostFunction,
  RequestResponse,
  newPutFunction,
  NewHoldingRequest,
  EditHoldingRequest,
  newPatchFunction
} from '@src/service'
import {
  exerciseUrl,
  optionsUrl,
  optionUrl,
  OptionUrlProps,
  plansUrl,
  planUrl,
  PlanUrlProps,
  poolsUrl,
  poolUrl,
  PoolUrlProps,
  requestExerciseUrl,
  vestingsUrl,
  vestingUrl,
  VestingUrlProps,
} from './urls'
import { EditVestingScheduleRequest, ExerciseOptionRequest, NewOptionRequest, NewPlanRequest, NewPoolRequest, NewVestingScheduleRequest } from './requests'
import {
  DeleteOptionResponse,
  DeletePlanResponse,
  DeletePoolResponse,
  ExerciseOptionResponse,
  NewOptionResponse,
  NewPlanResponse,
  NewPoolResponse,
  RequestExerciseOptionResponse,
} from './responses'
import { Hash } from '@shared/helpers'
import { capitalChangeUrl, conversionUrl, holdingsUrl, holdingUrl } from '../urls'
import { OrganizationUrlProps } from '@logic'
import { CapitalChangeRequest, NewConversionRequest, PatchHoldingRequest } from '../requests'
import { CapitalChange } from '../responses'

const newPlanRequestResponse: RequestResponse<NewPlanRequest, NewPlanResponse> = {}
const newPoolRequestResponse: RequestResponse<NewPoolRequest, NewPoolResponse> = {}
const newOptionRequestResponse: RequestResponse<NewOptionRequest, NewOptionResponse> = {}

export const [newPlanFromRequest, editPlan] = newPostAndPutFunctions(plansUrl, planUrl, newPlanRequestResponse)
export const [newPoolFromRequest, editPool] = newPostAndPutFunctions(poolsUrl, poolUrl, newPoolRequestResponse)
export const [newOptionFromRequest, editOption] = newPostAndPutFunctions(optionsUrl, optionUrl, newOptionRequestResponse)

export const createHolding = newPostFunction<{}, NewHoldingRequest, NewHoldingResponse>(holdingsUrl)
export const editHolding = newPutFunction<{ holding: Hash }, EditHoldingRequest, NewHoldingResponse>(holdingUrl)
export const patchHolding = newPatchFunction<{ holding: Hash }, PatchHoldingRequest, NewHoldingResponse>(holdingUrl)
export const newConversion = newPostFunction<{ holding: Hash }, NewConversionRequest, {}>(conversionUrl)
export const deleteHolding = newDeleteFunction<{ holding: Hash }, NewHoldingResponse>(holdingUrl)
export const newCapitalChange = newPostFunction<{ entity: Hash }, CapitalChangeRequest, CapitalChange>(capitalChangeUrl)
export const patchCapitalChange = newPatchFunction<{ entity: Hash }, CapitalChangeRequest, CapitalChange>(capitalChangeUrl)
export const deletePlan = newDeleteFunction<PlanUrlProps, DeletePlanResponse>(planUrl)
export const deletePool = newDeleteFunction<PoolUrlProps, DeletePoolResponse>(poolUrl)
export const deleteOption = newDeleteFunction<OptionUrlProps, DeleteOptionResponse>(optionUrl)
export const newVesting = newPostFunction<{}, NewVestingScheduleRequest, {}>(vestingsUrl)
export const editVesting = newPutFunction<{ vestingScheduleOld: Hash }, EditVestingScheduleRequest, {}>('vestingSchedule/:vestingScheduleOld')
export const deleteVesting = newDeleteFunction<PlanUrlProps & VestingUrlProps, {}>(`${planUrl}/${vestingUrl}`)

export const exerciseOption = newPostFunction<PoolUrlProps & OptionUrlProps, ExerciseOptionRequest, ExerciseOptionResponse>(exerciseUrl)
const requestExerciseOption = newPostFunction<OptionUrlProps, ExerciseOptionRequest, RequestExerciseOptionResponse>(requestExerciseUrl)
