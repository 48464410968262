import * as React from 'react'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadCaptablePools } from '@helpers/loaders'
import { whiteColor, gapSizes } from '@src/styles'
import { PathLink } from '@components/navigation'
import { CellProps } from 'react-table'
import { Table } from '@components/tables/table'
import styled from 'styled-components'
import { organizationViewSecurityPath, PageProps, poolPath, viewConvertibleInstrumentPath } from '@logic'
import { optionalUsdString } from '@modules/convertibles/components'
import { removeDuplicates, toCommaFloat } from '@shared/helpers'
import { dynamicFilter } from '@shared/components/dynamic-filter'
import { GetPoolsReportResponse } from '@src/service'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetPoolsReportResponse

const withData = withLoadingCachedMultiple<Props>(loadCaptablePools)

const HoldersListWrapper = styled.div`
  ${whiteColor}
  h4 {
    padding-bottom: ${gapSizes.S};
  }
  a {
    ${whiteColor}
  }
`

export const PoolsReportsPage = withPermissions([BuiltInPermission.viewCapTable, BuiltInPermission.viewSelf])(
    withData((props: Props) => {
        const { params } = props
        const { organization } = params
        const pageSize = 100000
        const orgPools = props.pools!!
        const poolsList = orgPools ? orgPools.map((a, i: number) => ({ ...a, index: i + 1 }))?.map(o => {
            return { ...o, hidden: !o.inTable }
        }) : []

        const equityOptions = removeDuplicates(poolsList.map(pool => ({ label: pool.equityName, value: pool.equity })))
        const typeOptions = [
            { label: 'Incentive', value: 'Incentive' },
            { label: 'Convertible', value: 'Convertible' },
        ]
        const filterBundle = dynamicFilter(
            {
                filters: [
                    { placeholder: 'Equity', accessor: 'equityName', options: equityOptions, withAll: true },
                    { placeholder: 'Type', accessor: 'internalType', options: typeOptions, withAll: true }
                ],
                data: poolsList
            })
        const filter = filterBundle.component
        const tableData = filterBundle.filteredData
        const isPool = (pool: any) => {
            if (pool.plan) return true
            else false
        }

        const columns = [
            {
                Header: 'Pool',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    const args = isPool(row.original) ?
                        { organization, plan: row.original.plan, pool: row.original.hash }
                        :
                        { organization, holding: row.original.hash }

                    return (
                        <PathLink
                            path={isPool(row.original) ? poolPath :
                                viewConvertibleInstrumentPath}
                            args={args}
                            target='_blank'>
                            <span>{row.original.name}</span>
                        </PathLink>
                    )
                },
                Footer: () => <div>TOTAL</div>,
            },
            {
                Header: 'Equity',
                accessor: 'equityName',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink
                            path={organizationViewSecurityPath}
                            args={{ organization, security: row.original.equity }}
                            target='_blank'>
                            <span>{row.original.equityName}</span>
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Type',
                accessor: 'internalType',
            },
            {
                Header: 'Authorized',
                accessor: 'authorized',
                Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.authorized),
                Footer: (info: any) => {
                    const investmentTotal = info.data.reduce((a: any, b: any) => a + b.authorized, 0)
                    return <span>{toCommaFloat(investmentTotal)}</span>
                },
            },
            {
                Header: 'Treasury',
                accessor: 'treasury',
                Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.treasury),
                Footer: (info: any) => {
                    const investmentTotal = info.data.reduce((a: any, b: any) => a + b.treasury, 0)
                    return <span>{toCommaFloat(investmentTotal)}</span>
                },
            },
            {
                Header: 'Investment',
                accessor: 'investment',
                Cell: ({ row }: CellProps<any>) => optionalUsdString(row.original.investment),
                Footer: (info: any) => {
                    const investmentTotal = info.data.reduce((a: any, b: any) => a + b.investment, 0)
                    return <span>{optionalUsdString(investmentTotal)}</span>
                },
            },
            {
                Header: 'Fully Diluted',
                accessor: 'fullyDiluted',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaFloat(row.original.fullyDiluted)}</span>,
                Footer: (info: any) => {
                    const fullyDilutedTotal = info.data.reduce((a: any, b: any) => a + b.fullyDiluted, 0)
                    return <span>{toCommaFloat(fullyDilutedTotal)}</span>
                }
            },
            {
                Header: 'Ownership',
                accessor: 'ownership',
                Cell: ({ row }: CellProps<any>) => {
                    const ownershipPer = row.original.ownership || 0
                    return <span>{ownershipPer.toFixed(2)}%</span>
                },
                Footer: (info: any) => {
                    const fullyDilutedPerTotal = info.data.reduce((a: any, b: any) => a + (b.ownership || 0), 0)
                    return <span>{fullyDilutedPerTotal.toFixed(2)}%</span>
                }
            },
        ]

        return (
            <PageContent>
                <PageContentHeader title='Pools' hideCrumbs={true}>
                    {filter}
                </PageContentHeader>
                <HoldersListWrapper>
                    <Table
                        columns={columns}
                        data={tableData}
                        scrollingTable={true}
                        withAllBorders={true}
                        pageSize={pageSize}
                    />
                </HoldersListWrapper>
            </PageContent>
        )
    }))
