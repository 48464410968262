import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { editPlanPath, MembersProps, newPlanPath, organizationMemberPath, PageProps, planPath, plansPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadMembers, loadPlans, loadSecurities } from '@helpers/loaders'
import { deletePlan, GetSecuritiesResponse, PlansProps } from '@src/service'
import { EmptyLanding } from '@shared/components/empty-landing'
import { ellipsesColumn, Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { formatDateString, formatSecurityName } from '@shared/helpers'
import { basicDeleteModal } from '@shared/components/modal'

type Props = PageProps & PlansProps & GetSecuritiesResponse & MembersProps

const withData = withLoadingCachedMultiple<Props>(loadPlans, loadSecurities(), loadMembers)

export const PlansList = withData(
  withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
    const [selectedPlan, setSelectedPlan] = React.useState<any>()
    const { params, plans, navigate, httpClient, securities, reload } = props
    const { organization } = params
    const plansList = plans || []
    const handleDeletePlan = async () => {
      await deletePlan(httpClient)({ plan: selectedPlan.hash })
      deleteModal.setVisible(false)
      reload()
    }
    const planName = selectedPlan ? `${selectedPlan.name} Plan` : ''
    const deleteModal = basicDeleteModal(
      handleDeletePlan,
      [planName],
      `WARNING: You are about to delete this entire incentive plan.
      Any incentives, pools, vesting schedules, and holdings you have attached to this plan will also be deleted. 
      Make sure this is your absolute intention.`,
      'Delete Permanently?',
      'Are you sure? You are about to delete the following item(s):'
    )

    const renderEquities = (equities: string[]) => {
      const filtered = securities?.filter(s => equities.some(e => e === s.hash))
      return filtered && filtered.length > 0 ?
        filtered.map((f, i: number) => (
          <span>
            {formatSecurityName(f)} {f.shareClassPrefix ? `(${f.shareClassPrefix})` : ''}{i !== filtered.length - 1 && ', '}
          </span>
        ))
        : ''
    }

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: CellProps<any>) => {
          return (
            <PathLink path={planPath} args={{ organization, plan: row.original.hash }}>
              {row.original.name}
            </PathLink>
          )
        },
      },
      {
        Header: 'Term',
        accessor: 'termYears',
        Cell: ({ row }: CellProps<any>) => {
          const termYear = row.original.termYears
          const text = termYear == 0 ? 'No Expiration' : termYear > 1 ? `${termYear} Years` : `${termYear} Year`
          return (
            <span>{text}</span>
          )
        },
        className: 'left'
      },
      {
        Header: 'APPROVED EQUITIES',
        accessor: 'approvedEquities.equity',
        Cell: ({ row }: CellProps<any>) => {
          const approvedEquities = row.original.approvedEquities?.equity
          return approvedEquities ? renderEquities(approvedEquities) : null
        },
        className: 'left'
      },
      {
        Header: 'CREATED BY',
        accessor: 'owner',
        Cell: ({ row }: CellProps<any>) => <span>{row.original.owner}</span>,
        className: 'left'
      },
      {
        Header: 'APPROVAL DATE',
        accessor: 'boardApprovalDate',
        Cell: ({ row }: CellProps<any>) => {
          return (
            <span>{formatDateString(row.original.boardApprovalDate)}</span>
          )
        },
      },
      ellipsesColumn(
        [
          ['View', ({ row }) => navigate(planPath, { organization, plan: row.original.hash })],
          ['Edit', ({ row }) => navigate(editPlanPath, { organization, plan: row.original.hash })],
          ['Delete', ({ row }) => { setSelectedPlan(row.original), deleteModal.setVisible(true) }]
        ]
      ),
    ]

    return (
      <>
        {deleteModal.component}
        <PageContent>
          {plansList.length > 0 ?
            <>
              <PageContentHeader title="Incentive Plans" hideCrumbs={true}>
                <PathLinkButton path={newPlanPath} args={params}>
                  + Add
                </PathLinkButton>
              </PageContentHeader>
              <Table columns={columns} data={plansList} scrollingTable={true} withAllBorders={true} noFooter={true} />
            </>
            :
            <EmptyLanding path={planPath} params={params} />
          }
        </PageContent>
      </>
    )
  })
)
