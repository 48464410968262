import * as React from 'react'
import { nonZeroCurrencyField, FieldGroup, renderFieldsSimple } from '@components/forms'
import { CapitalContributionType } from '@modules/captable/utility'

export const capitalContributionsFields = (): FieldGroup<any> => [
  {
    name: `capitalContributions.${CapitalContributionType.cashPaid}`,
    label: 'Cash Paid',
    placeholder: 'Enter amount of cash paid (in USD)',
    ...nonZeroCurrencyField,
  },
  {
    name: `capitalContributions.${CapitalContributionType.debtCanceled}`,
    label: 'Debt Canceled',
    placeholder: 'Enter amount of debt canceled (in USD)',
    ...nonZeroCurrencyField,
  },
  {
    name: `capitalContributions.${CapitalContributionType.valueOfIp}`,
    label: 'Value of IP Transferred',
    placeholder: 'Enter the value of any IP transferred (in USD)',
    ...nonZeroCurrencyField,
  },
  {
    name: `capitalContributions.${CapitalContributionType.other}`,
    label: 'Other Considerations',
    placeholder: 'Enter the value of any other goods or services',
    ...nonZeroCurrencyField,
  },
]

export const CapitalContributions = () => {
  return <>{renderFieldsSimple(capitalContributionsFields())}</>
}
