import * as React from 'react'
import { connect } from 'react-redux'
import { organizationPath, OrganizationsProps, Paths, welcomePath } from '@logic'
import { useEffect } from 'react'
import { Loading, withLoadingCachedMultiple } from '@components/loading'
import { NavigationProps, withNavigation } from '@components/navigation/navigation'
import { loadOrganizations } from '@shared/helpers'

interface StateProps {
  user: any
}

type Props = StateProps & NavigationProps & OrganizationsProps

const withData = withLoadingCachedMultiple<Props>(loadOrganizations)

const Component = withData((props: Props) => {
  const { organizations, navigate } = props

  useEffect(() => {
    if (props.user) {
      if (!organizations || organizations.length === 0) {
        navigate(welcomePath)
      } else {
        if (organizations!.length === 1) {
          const organization = organizations![0].id
          navigate(organizationPath, { organization })
        }
        else {
          navigate(Paths.organizationList, {})
        }
      }
    }
  }, [props.user])

  return <Loading />
})

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  }
}

export const UserDashboard = withNavigation(connect(mapStateToProps)(Component))
