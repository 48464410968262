import * as React from 'react'
import { ComponentType } from 'react'
import { NavigationProps, withNavigation } from '@shared/components/navigation'
import { withService } from '@shared/hocs/with-service'
import { Tabs } from '@shared/components/tabs/tabs'
import {
  equaCashTransferPath,
  guestEquaCashTransferPath,
  guestTransactionsHistoryPath,
  organizationCashTransferPath,
  organizationTransactionsHistoryPath,
  transactionsHistoryPath
} from '@logic'

type Props = NavigationProps & { forGuest?: boolean }

const EquaCashTransfer = withNavigation(withService((props: Props) => {
  const { params, forGuest } = props

  const links = forGuest ?
    [
      {
        destination: guestEquaCashTransferPath,
        name: 'Transfer',
        exact: true,
      },
      {
        destination: guestTransactionsHistoryPath,
        name: 'History',
      },
    ]
    :
    params.organization ?
      [
        {
          destination: organizationCashTransferPath,
          name: 'Transfer',
          exact: true,
        },
        {
          destination: organizationTransactionsHistoryPath,
          name: 'History',
        },
      ]
      :
      [
        {
          destination: equaCashTransferPath,
          name: 'Transfer',
          exact: true,
        },
        {
          destination: transactionsHistoryPath,
          name: 'History',
        },
      ]

  return <Tabs tabs={links} title={'Equa Cash Transfer & History'} params={params} />
}))

export function withEquaCashTransferHeader<P>(InnerComponent: ComponentType<P>, forGuest?: boolean): ComponentType<P> {
  return (props: any) => (
    <React.Fragment>
      <EquaCashTransfer {...props} forGuest={forGuest} />
      <InnerComponent {...props} />
    </React.Fragment>
  )
}
