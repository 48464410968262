import { ComponentType, default as React } from 'react'
import { LoadData, withCachedLoading } from '@components/loading'
import { BuiltInPermission } from '@helpers/constants'
import { PageProps, User } from '@logic'
import { Uuid } from '@helpers/util'
import { withService } from '@src/shared/hocs/with-service'
import { NavigationProps, withNavigation } from '@components/navigation/navigation'
import { Unauthorized } from '@src/app/components/routes/unauthorized'
import { getOrganizationPermissions } from '@src/service'

export interface Permission {
  id: BuiltInPermission
  name: string
}

export interface PermissionProps {
  permissions: Permission[]
}

export const loadPermissions: LoadData<PermissionProps | undefined> = async ({ httpClient, user, params }) => {
  return user && params.organization ?
    getOrganizationPermissions(httpClient)({
      organization: params.organization,
      user: user.id
    }) : { permissions: [] }
}

export type PermissionCheck = (_: ComponentType<any>) => ComponentType<any>

export type OrganizationPermissionCheck = (permissions: BuiltInPermission[]) => boolean

export const withOrganizationPermissionsCheck = (check: OrganizationPermissionCheck) =>
  <P extends PageProps>(WrappedComponent: ComponentType<P>): ComponentType<P> => {
    return (props => {
      const { permissions } = props
      const permitted = check(permissions.map(p => p.id))
      return (permitted ? <WrappedComponent {...props} /> : <Unauthorized />) as any
    })
  }

export const isUserSiteAdmin = (user?: User) => !!(user && user.isAdmin)

export function isSiteAdmin<P extends PageProps>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  return props => {
    const { user } = props
    const permitted = isUserSiteAdmin(user)
    return (permitted ? <WrappedComponent {...props} /> : <Unauthorized />) as any
  }
}

export const hasPermission = (permissions: Permission[]) => (permission: Uuid): boolean =>
  !!permissions.find(p => p.id === permission)

export const hasBuiltInPermission = (permission: BuiltInPermission) => (permissions: BuiltInPermission[]): boolean =>
  permissions.indexOf(permission) !== -1

export const canViewCapTable = withOrganizationPermissionsCheck(hasBuiltInPermission(BuiltInPermission.viewCapTable))
export const canEditCapTable = withOrganizationPermissionsCheck(hasBuiltInPermission(BuiltInPermission.editCapTable))

export const canViewMembers = withOrganizationPermissionsCheck(hasBuiltInPermission(BuiltInPermission.viewMembers))
export const canEditMembers = withOrganizationPermissionsCheck(hasBuiltInPermission(BuiltInPermission.editMembers))
