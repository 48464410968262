import * as React from 'react'
import { Paths } from '@logic'
import { FieldGroup, protectedInputFieldComponent, renderFieldsSimple, textInputFieldComponent, } from '@shared/components/forms'
import { isConfirmPassword, isEmail, isPassword } from '@shared/helpers'
import styled from 'styled-components'
import { FlexCol, fontSizes } from '@src/styles'
import { Button, LoginSignButton } from '@shared/components/button'
import { PathLink } from '@shared/components/navigation'

interface Props {
    history: any
    parsed: any
    values?: any
    invalid?: boolean
}

const ForgotFormWrapper = styled.div<any>`
margin-top: 30px;
text-align: left;
  span {
    font-weight: 600;
    font-family: 'NunitoSans';
    font-size: ${fontSizes.XXS};
    line-height: 19px;
    color: ${props => props.theme.label};
    margin: -1.2% 0 0 0;
  }
`

const StyledForgotLabel = styled.div`
  display: inline-block;
  margin-bottom: 30px;
  text-align: left;
`

function nameFields(values: any): FieldGroup<any> {
    return [
        {
            name: 'password',
            label: 'New Password',
            required: true,
            validate: isPassword,
            placeholder: '● ● ● ● ● ● ● ●',
            component: protectedInputFieldComponent({}, 'text', true),
            note: "Minimum 4 characters"
        },
        {
            name: 'passwordConfirm',
            label: 'Confirm New Password',
            required: true,
            validate: isConfirmPassword(values),
            placeholder: '● ● ● ● ● ● ● ●',
            component: protectedInputFieldComponent({}, 'text', true),
            note: "Minimum 4 characters"
        },
    ]
}

function signUpFields(): FieldGroup<any> {
    return [
        {
            name: 'usernameOrEmail',
            label: 'Email Address',
            placeholder: "example@gmail.com",
            required: true,
            validate: isEmail,
            component: textInputFieldComponent,
        },
    ]
}

export const ForgotPasswordForm = (props: Props) => {
    const { values, parsed, invalid, history } = props
    return (
        <ForgotFormWrapper>
            {parsed.password ?
                <>
                    {renderFieldsSimple(nameFields(values), true)}
                    <br />
                    <FlexCol>
                        <LoginSignButton type="submit" disabled={invalid}>RESET PASSWORD</LoginSignButton>
                        <PathLink path={Paths.userLogin} args={{}}>Cancel</PathLink>
                    </FlexCol>
                </>
                :
                <>
                    <StyledForgotLabel>To reset your password, please enter your e-mail address and we will send the password reset instructions.</StyledForgotLabel>
                    {renderFieldsSimple(signUpFields(), true)}
                    <br />
                    <FlexCol>
                        <LoginSignButton type="submit" disabled={invalid}>RESET PASSWORD</LoginSignButton>
                        <PathLink path={Paths.userLogin} args={{}}>Cancel</PathLink>
                    </FlexCol>
                </>
            }
        </ForgotFormWrapper>
    )
}
