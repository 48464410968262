import { yearsRange } from '@shared/helpers'
import { resolveRelativeDate } from './logic'

export const planTerm = yearsRange(10, 1).concat({ value: 0, label: 'No Expiration' })

export const securityClass = [
  { value: 'A', label: 'Class A' },
  { value: 'B', label: 'Class B' },
  { value: 'C', label: 'Class C' },
  { value: 'D', label: 'Class D' },
  { value: 'E', label: 'Class E' }, // Doesn't work as number type due to 0
]

export const getExactDays = (date1: number, date2: number) => {
  const difference = date1 - date2
  const days = difference / 86400 * 1000
  return days
}

export enum VestingScheduleType {
  continuous = 0,
  discrete = 1
}

export enum VestmentTrigger {
  anniversary = 1,
  firstDayOfMonth = 2,
  lastDayOfMonth = 3,
}

export const eventsWithData = (values: any) => {
  let events = values?.events ? values?.events?.filter(e => !e.isRemoved)
    .map(eve => {
      if (values?.vestingOptions.relative) {
        const date = resolveRelativeDate(values?.startDate, eve.month, eve.day)
        return { ...eve, date, value: eve.rValue }
      } else return eve
    })?.sort((a, b) => {
      if (a.date && b.date && Date.parse(a.date) && Date.parse(b.date)) {
        const d1 = a.date.getTime()
        const d2 = b.date.getTime()
        return d1 - d2
      }
    }) : []

  const eventsData = events?.map(event => {
    if (values?.vestingOptions.absolute) {
      if (typeof event.date === 'string') {
        const date = event.date?.split('/')
        const month = date[0]
        const day = date[1]
        const year = date[2]
        return { month: parseInt(month), day: parseInt(day), year: parseInt(year), value: event.value }
      } else {
        const month = event.date?.getMonth() + 1
        const day = event.date?.getDate()
        const year = event.date?.getFullYear()
        return { month, day, year, value: event.value }
      }
    }
    else return { ...event, value: event.value / 100 }
  })?.filter(e => e.value && e.month)

  if (values?.vestingOptions.procedural && !values?.cliff)
    delete values.vestingSchedule?.cliffMonths

  return values?.vestingOptions.procedural ? (values?.vestingSchedule || {})
    : { events: eventsData, isValueAbsolute: values?.vestingOptions?.absolute }
}

export function monthDiff(d1: Date, d2: Date) {
  var months: number = 0
  // Months between years.
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  // Months between... months.
  months += d2.getMonth() - d1.getMonth();
  // Subtract one month if b's date is less that a's.
  if (d2.getDate() < d1.getDate()) {
    months--
  }
  return months
}

export const vestingScheduleOptions = [
  { name: 'procedural', text: 'Standard' },
  { name: 'absolute', text: 'Custom (Absolute)' },
  { name: 'relative', text: 'Custom (Relative)' },
]

export const handleVestingToggle = (values: any) => {
  const vestingOptions: any = {}
  vestingScheduleOptions.forEach(p => {
    if (p.name === 'procedural') {
      vestingOptions['procedural'] = true
    }
  })
  const v = { ...values, vestingOptions }
  Object.assign(values, v)
}

export const handleCliffToggle = (values: any) => {
  delete values.vestingSchedule.cliffAmount
  delete values.vestingSchedule.cliffAmountType
}

export const handleVestingDeselect = (values: any, name: any) => {
  const vestingOptions: any = {}
  const previous = Object.entries(values.vestingOptions || {})
    .filter(v => v[1])
    .map(v => v[0])[0]

  if (previous != name) {
    if (values.previewStartDate) delete values.previewStartDate
    vestingScheduleOptions.forEach(p => {
      if (p.name !== name) {
        vestingOptions[`${p.name}`] = false
      }
    })
    const v = { ...values, vestingOptions, vestingSchedule: {}, events: [] }
    Object.assign(values, v)
  }
}

export const handleMemberDeselect = (values: any, name: string) => {
  if (name === 'individual') delete values.types.organization
  else delete values.types.individual
}
