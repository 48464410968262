import * as React from 'react'
import { renderFieldsSimple, renderHeader, numericInputFieldWithCommas, nonZeroCurrencyField } from '@components/forms'
import { emptyValidator, formatCurrency, toCommaFloat } from '@helpers/index'
import styled from 'styled-components'
import { FlexRow, gapSizes, LightDivider, screenSizes } from '@src/styles'
import { CellProps } from 'react-table'
import { Table } from '@shared/components/tables'
import { SubmitButton } from '@shared/components/button'
import { ExpandedHolding, ExpandedSecurity } from '@src/service'
import { getSharePrice } from '../utility'
import { optionalUsdString } from './instrument-details'
import { overflowParams } from '@shared/components/navigation'
import { organizationMemberPath } from '@logic'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
    values?: any
    notesData: any
    securitiesData: ExpandedSecurity[]
    holding: ExpandedHolding
    navigate?: any
    organization?: string
}

const ConvertWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 54px;
  align-items: center;
  @media(max-width: ${screenSizes.XS}px) {
  }
`

const ButtonsWrap = styled<any>(FlexRow)`
  justify-content: flex-end;
  padding-top: ${gapSizes.XL};
  @media(max-width: ${screenSizes.XS}px) {
  }
`

const TableWrap = styled.div`
  table {
    min-width: 0 !important;
  }
  @media(max-width: ${screenSizes.XS}px) {
  }
`

const ConversionTableWrap = styled.div<any>`
  span {
      margin: 0;
      display: inline;
  }
`
export const ConversionForm = (props: Props) => {
    const { values, notesData, securitiesData, holding, navigate, organization } = props

    const columns = [
        {
            Header: 'Total Shares',
            accessor: 'totalShares',
            Cell: ({ row }: CellProps<any>) => <span>{isNaN(row.original.totalShares) ? 0 : toCommaFloat(row.original.totalShares)}</span>,
        },
        {
            Header: 'Price Per Share',
            accessor: 'pricePerShare',
            Cell: ({ row }: CellProps<any>) => <span>{isNaN(row.original.pricePerShare) ?
                0
                : optionalUsdString(row.original.pricePerShare)}</span>,
        },
        {
            Header: 'PPS + Discount',
            accessor: 'withDiscount',
            Cell: ({ row }: CellProps<any>) => <span>{isNaN(row.original.withDiscount) ?
                0
                : optionalUsdString(row.original.withDiscount)}</span>,
        }
    ]

    const issuedColumns = [
        {
            Header: 'Cert #',
            accessor: 'name',
            Cell: ({ row }: CellProps<any>) => <div>{row.original.name}</div>,
        },
        {
            Header: 'Holder',
            accessor: 'holder',
            Cell: ({ row }: CellProps<any>) => <a href=""
                onClick={() => navigate(overflowParams(organizationMemberPath, { organization, member: row.original.owner, parent: window.location.pathname }))}>
                {row.original.holder!}
            </a>
        },
        {
            Header: 'Principal',
            accessor: 'capitalContribution',
            Cell: ({ row }: CellProps<any>) => <div>{formatCurrency(row.original?.capitalContribution!)}</div>
        },
        {
            Header: 'P+I',
            accessor: 'withInterest',
            Cell: ({ row }: CellProps<any>) => <div>{formatCurrency(row.original.withInterest)}</div>
        },
        {
            Header: 'Shares Owed',
            accessor: 'sharesOwed',
            Cell: ({ row }: CellProps<any>) => <div>{row.original.sharesOwed}</div>
        },
        {
            Header: '# Of Shares To Distribute',
            Cell: React.useMemo(() => ({ row }: CellProps<any>) => <>{renderFieldsSimple([
                {
                    name: `distributeShares.${row.original.hash!}`,
                    label: '',
                    required: false,
                    defaultValue: row.original?.sharesOwed!,
                    format: formatToNumber,
                    validate: emptyValidator,
                    component: numericInputFieldWithCommas,
                }
            ])}
            </>
                , []
            )
        }
    ]

    const totalShares = securitiesData.reduce((a, b) => a + b.shares, 0)
    const pricePerShare = getSharePrice(values.valuation, totalShares)
    const withDiscount = getSharePrice(values.valuation, totalShares, holding.convertibleInstrument?.conversionDiscount!)
    const tableData = [
        {
            totalShares,
            pricePerShare,
            withDiscount
        }
    ]

    return (
        <>
            {renderHeader({ label: 'Convert' })}
            <ConvertWrap>
                {renderFieldsSimple([
                    {
                        name: 'valuation',
                        label: 'Current Organization Valuation',
                        placeholder: 'Enter your Organization’s Current Valuation',
                        required: true,
                        ...nonZeroCurrencyField
                    }
                ])}
                <TableWrap>
                    <Table columns={columns} data={tableData} scrollingTable={true} />
                </TableWrap>
            </ConvertWrap>
            <ConversionTableWrap>
                <Table columns={issuedColumns} data={notesData} scrollingTable={true} manualPagination={true} />
            </ConversionTableWrap>
            <LightDivider stretch={true} />
            <ButtonsWrap>
                <SubmitButton>Convert</SubmitButton>
            </ButtonsWrap>
        </>
    )
}
