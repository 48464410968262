import { Hash, UrlPath } from '@helpers/util'
import { OrganizationUrlProps, SecurityUrlProps, LegendUrlProps, ProfileHoldingUrlProps } from '@logic'
import { organizationUrl } from '../organizations'

export const securitiesUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/security`
export const securityUrl: UrlPath<SecurityUrlProps> = `${securitiesUrl}/:security`
export const sharesUrl: UrlPath<SecurityUrlProps> = `${securityUrl}/shares`
export const legendsUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/legend`
export const legendUrl: UrlPath<LegendUrlProps> = `${legendsUrl}/:legend`
export const holdingsUrl: UrlPath<{}> = 'holding'
export const holdingUrl: UrlPath<ProfileHoldingUrlProps> = `${holdingsUrl}/:holding`
export const conversionUrl: UrlPath<ProfileHoldingUrlProps> = `${holdingUrl}/convert`
export const capitalChangeUrl: UrlPath<{ entity: Hash }> = `entity/:entity/capitalChange`

// shareholding
export const shareholdingsUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/shareholding`
export const blocksUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/block`

// captable
export const captableUrl: UrlPath<OrganizationUrlProps> = 'captable/:organization/dashboard'

//reports
export const captablePoolsUrl: UrlPath<OrganizationUrlProps> = 'captable/:organization/pool'

