import { logout } from '@logic'
import { clearDataCache } from '@shared/helpers'

const axios = require('axios').default

export enum ResponseCode {
  badRequest = '400',
  needsLogin = '401',
  accessDenied = '403',
  notFound = '404',
  serverError = '500',
  other = '0',
}

export class HttpError {
  status: ResponseCode
  key?: string
  message?: string

  constructor(code: ResponseCode, key?: string, message?: string) {
    this.status = code
    this.key = key
    this.message = message
  }
}

const handleError = async (response: any, dispatch: any): Promise<HttpError> => {
  const status = response.status.toString()
  const responseBody = response.data ? response.data : await response.clone().json()
  switch (status) {
    case ResponseCode.badRequest:
      return new HttpError(ResponseCode.badRequest, responseBody.key, responseBody.message)
    case ResponseCode.needsLogin:
      dispatch({ type: 'LOGOUT_SUCCESS' })
      localStorage.clear()
      return new HttpError(ResponseCode.needsLogin, responseBody.key, responseBody.message)
    case ResponseCode.notFound:
      return new HttpError(ResponseCode.notFound, responseBody.key, responseBody.message)
    case ResponseCode.serverError:
      return new HttpError(ResponseCode.serverError, responseBody.key, responseBody.message)
    case ResponseCode.accessDenied:
      return new HttpError(ResponseCode.accessDenied, responseBody.key, responseBody.message)
    default:
      return new HttpError(ResponseCode.other, responseBody.key, responseBody.message)
  }
}

export type WebResponse<T> = T | HttpError

async function baseRequest<T>(url: string, method: string, dispatch: any, body?: any): Promise<WebResponse<T>> {
  const response = await fetch(url, {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Cache-Control': 'no-cache'
    },
    body: JSON.stringify(body),
    credentials: 'include',
  })
  if (!response.ok) {
    return handleError(response, dispatch)
  }
  const contentType = response.headers.get('content-type')
  return contentType && contentType.indexOf('application/json') != -1 ? response.json() : ResponseCode.other
}

export class HttpClient {
  private readonly baseUrl: string
  dispatch: any

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl
  }

  setDispatch(dispatch: any) {
    this.dispatch = dispatch
  }

  async apiRequest<T>(path: string, method: string, body?: any, withoutBaseUrl?: boolean): Promise<WebResponse<T>> {
    return baseRequest<T>(!withoutBaseUrl ? this.baseUrl + '/' + path : path, method, this.dispatch, body)
  }

  async apiMultipartRequest<T>(path: string, body?: any, onUploadProgress?: (e: any) => void): Promise<WebResponse<T>> {
    return axios
      .post(this.baseUrl + '/' + path, body, {
        processData: false,
        contentType: undefined,
        withCredentials: true,
        onUploadProgress,
      })
      .then((response: any) => {
        return response.data!
      })
      .catch(async (error: any) => {
        return handleError(error.response, this.dispatch)
      })
  }

  async apiMultipartRequestWithData<T>(path: string, body?: any): Promise<WebResponse<T>> {
    return axios
      .post(this.baseUrl + '/' + path, body, {
        processData: false,
        contentType: undefined,
        withCredentials: true,
      })
      .then((response: any) => {
        return response
      })
      .catch(async (error: any) => {
        return handleError(error.response, this.dispatch)
      })
  }

  get<T = any>(url: string, withoutBaseUrl?: boolean): Promise<WebResponse<T>> {
    return this.apiRequest(url, 'GET', undefined, withoutBaseUrl)
  }

  delete<T = any>(url: string): Promise<WebResponse<T>> {
    clearDataCache()
    return this.apiRequest(url, 'DELETE', undefined)
  }

  post<T = any>(url: string, body: any): Promise<WebResponse<T>> {
    clearDataCache()
    return this.apiRequest(url, 'POST', body)
  }

  patch<T = any>(url: string, body: any): Promise<WebResponse<T>> {
    clearDataCache()
    return this.apiRequest(url, 'PATCH', body)
  }

  put<T = any>(url: string, body: any): Promise<WebResponse<T>> {
    clearDataCache()
    return this.apiRequest(url, 'PUT', body)
  }

  postMultipart<T>(url: string, body: any, onProgress?: any): Promise<WebResponse<T>> {
    const formData = new FormData()
    formData.append('file', body)
    return this.apiMultipartRequest(url, formData, onProgress)
  }

  postFiles<T>(url: string, files: any[], rootPath: string, onProgress: any): Promise<WebResponse<T>> {
    const formData = new FormData()
    files.forEach((file, i) => {
      formData.append(`files`, file)
    })
    const meta = {
      files: files.map(i => ({ path: i.path })),
    }
    formData.append('meta', JSON.stringify(meta))
    return this.apiMultipartRequest(url, formData, onProgress)
  }

  postMultiPartWithData<T>(url: string, data: any): Promise<WebResponse<T>> {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('organization', data.organization)
    return this.apiMultipartRequestWithData(url, formData)
  }
}
