import * as React from 'react'
import { PageProps, editPINPath, addPINPath, addCardPath, editCardPath, viewCardPath, PaymentProfilesResponse, guestAddPINPath, guestAddCardPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { colors, FlexCol, FlexRow, fontSizes, LightDivider, OrgDetailsWrapper, screenSizes, StyledPaymentMethod } from '@src/styles'
import { InvertedButton } from '@components/button/button'
import styled, { css } from 'styled-components'
import EditPen from '@image/edit-pen-new.svg'
import { renderHeader } from '@shared/components/forms'
import { Ellipses } from '@shared/components/tables'
import { defaultPaymentProfile, deletePaymentProfile } from '@src/service'
import { getLogo } from '@modules/payments/components/payment-profiles'
import { newDeleteCardModal } from '@modules/documents/components/modals'
import Plus from '@image/plus-16x16.svg'
import { overflowParams } from '@shared/components/navigation'


type Props = PageProps & PaymentProfilesResponse & { forGuest?: boolean }

const svgStyle = css`
    height: 40px;
    margin: 6px 20px;
`

const ViewAccountWrap = styled<any>(OrgDetailsWrapper)`
  width: 100%;
  max-width: 600px;
  h3 {
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin: 0 !important;
  }
  p {
    font-size: ${fontSizes.XS};
    line-height: 24px;
    margin: 1px 0 7px 0;
  }
  span {
    font-size: ${fontSizes.XS};
    line-height: 24px;
    margin: 1px 0 7px 0;
  }
  label {
      font-size: ${fontSizes.XXS} !important;
      color: #9CA6AD;
  }
  .cardLogo{
    svg {
        ${svgStyle}
    }
  }
`

const AccountWrapper = styled.div<any>`
  h1, h4 {
      font-weight: normal;
      font-family: 'NunitoSans';
  }
  h4 {
    padding: 0 !important;
  }

  .first {
    margin-top: 20px;
    @media (min-width: ${screenSizes.XL}px) {
        margin-top: 40px;
    }
  }
`

const StyledPin = styled<any>(FlexRow)`
    justify-content: space-between;
    svg {
        cursor: pointer;
    }
`

const StyledHead = styled<any>(FlexRow)`
    margin: 60px 0 0 0;
`


const StyledDivider = styled<any>(LightDivider)`
    border-color: ${props => props.theme.primary} !important;
    @media (min-width: ${screenSizes.XL}px) {
        margin: 24px 0;
    }
`

const StyledPinValue = styled.input<any>`
    background: transparent;
    border: none;
    font-size: ${fontSizes.S};
    outline: none;
    color: ${colors.darkGrey};
`

const StyledInvertedEdit = styled<any>(InvertedButton)`
    height: 32px;
    padding: 4px 32px;
    svg {
        height: auto !important;
        margin: 0 8px 0 0 !important;
        path {
            fill: ${colors.green};
        }
    }
`

const MethodWrapper = styled<any>(FlexRow)`
    ${StyledPaymentMethod};
    height: 60px;
    border: ${props => props.default && `2px solid ${colors.green}`};
    .cardValue {
        font-size: ${fontSizes.XXS};
        line-height: 22px;
    }
`

const StyledEmail = styled<any>(FlexCol)`
    margin-top: 35px;
    span {
        margin-top: 24px;
    }
`

export const AccountSettingsShared = (props: Props) => {
    const { navigate, reload, httpClient, paymentProfiles, user, forGuest } = props
    const [profile, setProfile] = React.useState<string>('')

    const setProfileAsDefault = async (profile: string) => {
        await defaultPaymentProfile(httpClient)({ entity: user?.id, profile }, {})
        reload()
    }

    const savedPaymentProfiles: any[] = paymentProfiles && paymentProfiles.length > 0 ? paymentProfiles.map(profile => {
        const { payment_profile } = profile
        const { card_type, masked_card_number } = payment_profile
        return {
            ...profile,
            logo: getLogo(card_type),
            value: `Card ending in ...${masked_card_number ? masked_card_number.replace(/-|\X/g, '') : ''}`,
        }
    }) : []

    const deleteProfile = async () => {
        await deletePaymentProfile(httpClient)({ entity: user?.id, profile })
        deleteModal.setVisible(false)
        reload()
    }
    const deleteModal = newDeleteCardModal(deleteProfile)
    const openDeleteModal = (card: string) => {
        setProfile(card)
        deleteModal.setVisible(true)
    }

    return (
        <>
            {deleteModal.component}
            <PageContent withMargin={true}>
                <AccountWrapper>
                    <PageContentHeader title="Account Settings" hideCrumbs={true} />
                    <ViewAccountWrap>
                        <>
                            <StyledEmail alignItems={'flex-start'}>
                                <h4>Login / Account Email</h4>
                                <span>{forGuest ?
                                    <label>You are not logged in.</label>
                                    : user ? user.email : null}</span>
                            </StyledEmail>
                            <StyledDivider />
                        </>
                        {!props?.account?.hasPin ?
                            <>
                                {renderHeader({
                                    label: 'Four Digit PIN',
                                    index: 1,
                                    buttonText: 'Add',
                                    buttonOnClick: () => navigate(overflowParams((forGuest ? guestAddPINPath : addPINPath), { parent: window.location.pathname }))
                                })}
                                <span>Create your custom four digit PIN</span> <br />
                                <label>This will be used in various cases throughout the app for document signing.</label>
                            </>
                            :
                            <>
                                <FlexRow justifyContent="space-between">
                                    <h4>Four Digit PIN</h4>
                                    <StyledInvertedEdit onClick={() => navigate(editPINPath)}>
                                        <EditPen />
                                        Edit
                                    </StyledInvertedEdit>
                                </FlexRow>
                                <StyledPin>
                                    <StyledPinValue id="pinValue" type={'password'} value={1234} disabled={true} data-lpignore="true" />
                                </StyledPin>
                                <StyledDivider />
                            </>

                        }
                        <StyledHead>
                            <h4>Payment Methods</h4>
                        </StyledHead>
                        {/* <h3>Online Transactions / Purchases</h3>
                    <p>Connect a bank account with Plaid</p>
                    <label>Requires a U.S. bank account. This will allow for purchasing and transfers.</label> */}
                        <FlexRow justifyContent="space-between" style={{ margin: '24px 0' }}>
                            <h3 className="second">Billing Payment Methods</h3>
                            <StyledInvertedEdit onClick={() => navigate(overflowParams((forGuest ? guestAddCardPath : addCardPath), { parent: window.location.pathname }))}>
                                <Plus />
                                Add
                            </StyledInvertedEdit>
                        </FlexRow>
                        {savedPaymentProfiles && savedPaymentProfiles.length > 0 ?
                            savedPaymentProfiles.map((method, i: number) => {
                                const Logo = method.logo
                                const { id } = method.payment_profile
                                return <MethodWrapper justifyContent={'space-between'} alignItems={'center'} default={method.isDefault} key={i}>
                                    <div className='cardLogo'>
                                        {Logo}
                                        <span className="cardValue">{method.value} {method.isDefault && '(Default)'}</span>
                                    </div>
                                    <Ellipses options={
                                        [
                                            ['View', () => navigate(viewCardPath, { card: id })],
                                            ['Edit', () => navigate(editCardPath, { card: id })],
                                            ['Delete', () => openDeleteModal(id)],
                                            !method.isDefault &&
                                            ['Set as Default', () => setProfileAsDefault(id)]
                                        ]}
                                    />
                                </MethodWrapper>
                            })

                            :
                            <>
                                <p>Add a debit or credit card to your account </p>
                                <label>These will be held on file for your future Equa billing purposes when upgrading.</label>
                            </>
                        }
                    </ViewAccountWrap>
                </AccountWrapper>
            </PageContent>
        </>
    )
}
