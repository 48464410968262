import StartIcon from '@image/start-icon.svg'
import GrowthIcon from '@image/growth-icon.svg'
import ScaleIcon from '@image/scale-icon.svg'

export const paymentPlans = [
  {
    Logo: StartIcon,
    Title: 'START',
    Price: '$0',
    Info: '',
    Description: 'Free trial with no risk.  Perfect for startups, special purpose vehicles, small companies and small organizations.',
  },
  {
    Logo: GrowthIcon,
    Title: 'GROWTH',
    Price: '$7.00',
    Info: 'Average monthly price per holder billed annually.',
    Description: 'Full Equity Management capabilities with built in digital transfer-ability and governance add ons.',
  },
  {
    Logo: ScaleIcon,
    Title: 'SCALE',
    Price: 'Contact Us',
    Info: 'Work with one of our experts to get the best plan for your growing business.',
    Description: 'A complete Equity Management system for issuers and organizations in later stages or preparing for their next growth phase or IPO.',
  },
]