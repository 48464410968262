import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import {
    captableCertificatePath,
    editOptionPath,
    MembersProps,
    newOptionPath,
    optionsPath,
    organizationMemberPath,
    organizationViewLegendPath,
    PageProps,
    poolPath,
    viewVestingPath
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission, getIncentiveTypes } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadLegends, loadMembers, loadOrganizationHoldings, loadPlan, loadSecurities } from '@helpers/loaders'
import { deleteHolding, ExpandedHoldingsResponse, GetLegendsResponse, GetPlanResponse, GetSecuritiesResponse } from '@src/service'
import { EmptyLanding } from '@shared/components/empty-landing'
import { ellipsesColumn, Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { formatCurrency, formatDateString, toCommaNumber } from '@shared/helpers'
import { basicDeleteModal } from '@shared/components/modal'
import { filterEquities, filterIncentives, filterPools } from '../logic'
import { optionalShares } from '@modules/convertibles/components'

type Props = PageProps & ExpandedHoldingsResponse & GetSecuritiesResponse & MembersProps & GetPlanResponse & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganizationHoldings('all'), loadSecurities(), loadMembers, loadPlan, loadLegends(true))

export const OptionHoldingsList = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const [selectedOption, setSelectedOption] = React.useState<any>()
        const { params, navigate, securities, holdings, members, httpClient, plan, reload, legends } = props
        const { organization } = params
        const securitiesList = filterEquities([], securities, true)
        const incentivesList = filterIncentives(securitiesList, plan)
        const planPools = filterPools(holdings, incentivesList, plan?.hash).map(p => {
            const incentive = incentivesList.find(i => i.hash === p.parent)
            return { ...p, incentive }
        })
        const planOptions = holdings?.filter(holding => planPools.some(el => (holding.parent! === el.id) || (holding.parent! === el.hash)))
            .map(o => {
                const holder = members?.find(m => m.id === o.owner)?.fullName || ''
                const pool = planPools?.find(p => p.id === o.parent)?.name
                const unVestedShares = (o.startingValue || 0) - (o.vestedShares || 0)
                return { ...o, holder, pool, unVestedShares }
            })

        const handleDeleteOption = async () => {
            await deleteHolding(httpClient)({ holding: selectedOption?.hash })
            deleteModal.setVisible(false)
            reload()
        }
        const deleteModal = basicDeleteModal(
            handleDeleteOption,
            [selectedOption?.name || ''],
        )

        const columns = [
            {
                Header: 'Cert #',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink
                            path={captableCertificatePath}
                            args={{
                                organization,
                                block: 'current',
                                shareholding: row.original.hash
                            }}
                            target={'_blank'}
                        >
                            {row.original.name}
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Holder',
                accessor: 'holder',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink
                            path={organizationMemberPath}
                            args={{ organization, member: row.original.owner }}
                            target={'_blank'}
                        >
                            {row.original.holder}
                        </PathLink>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Type',
                accessor: 'type',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{row.original.type ? getIncentiveTypes[row.original.type] : ''}</span>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Pool',
                accessor: 'pool',
                Cell: ({ row }: CellProps<any>) => {
                    const pool = holdings?.find(h => (h.hash == row.original.parent || h.id == row.original.parent))
                    return pool ? (
                        <PathLink
                            path={poolPath}
                            args={{ organization, plan: params.plan, pool: pool.hash }}
                            target={'_blank'}
                        >
                            {row.original.pool}
                        </PathLink>
                    ) : 'N/A'
                },
                className: 'left'
            },
            {
                Header: 'GRANT / EFFECTIVE DATE',
                accessor: 'issueDate',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{formatDateString(row.original.issueDate)}</span>
                    )
                },
            },
            {
                Header: 'STRIKE PRICE',
                accessor: 'strikePrice',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{formatCurrency(row.original.strikePrice || 0)}</span>
                    )
                },
            },
            {
                Header: 'Vesting Schedule',
                accessor: 'vestingSchedule',
                Cell: ({ row }: CellProps<any>) => {
                    const schedule = row.original.vestingSchedule
                    return schedule ? (
                        <PathLink
                            path={viewVestingPath}
                            args={{ organization, plan: params.plan, vesting: schedule.hash }}
                            target={'_blank'}
                        >
                            {schedule?.name}
                        </PathLink>
                    ) : 'N/A'
                },
                className: 'left'
            },
            {
                Header: 'Vesting Start Date',
                accessor: 'startDate',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{row.original.startDate ? formatDateString(row.original.startDate) : 'N/A'}</span>
                    )
                },
            },
            {
                Header: 'Total Amount',
                accessor: 'startingValue',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.startingValue))}</span>,
            },
            {
                Header: 'Vested Amount',
                accessor: 'vestedShares',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.vestedShares))}</span>,
                className: 'right'
            },
            {
                Header: 'Exercised Amount',
                accessor: 'exercisedShares',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.exercisedShares))}</span>,
                className: 'right'
            },
            {
                Header: 'Unvested Amount',
                accessor: 'unVestedShares',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaNumber(optionalShares(row.original.unVestedShares))}</span>,
                className: 'right'
            },
            {
                Header: 'Legend',
                accessor: 'legend',
                Cell: ({ row }: CellProps<any>) => {
                    const legend = row.original.legend ? legends?.find(l => l.hash === row.original.legend)?.name : 'N/A'
                    return row.original.legend ? (
                        <PathLink path={organizationViewLegendPath} args={{ organization, legend: row.original.legend }} target={'_blank'}>
                            {legend}
                        </PathLink>
                    ) : <span>N/A</span>
                },
                className: 'left'
            },
            ellipsesColumn(
                [
                    ['View', ({ row }) => navigate(captableCertificatePath, { organization, shareholding: row.original.hash })],
                    ['Edit', ({ row }) => navigate(editOptionPath, { organization, plan: params.plan, option: row.original.hash })],
                    ['Delete', ({ row }) => { setSelectedOption(row.original), deleteModal.setVisible(true) }]
                ]
            ),
        ]

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    {planOptions.length > 0 ?
                        <>
                            <PageContentHeader title="Holdings">
                                <PathLinkButton path={newOptionPath} args={params}>
                                    + Add
                                </PathLinkButton>
                            </PageContentHeader>
                            <Table columns={columns} data={planOptions} scrollingTable={true} withAllBorders={true} noFooter={true} />
                        </>
                        :
                        <EmptyLanding path={optionsPath} params={params} />
                    }
                </PageContent>
            </>
        )
    })
)
