import {
  MemberUrlProps,
  newGetFunction,
  newPostFunction,
  ProjectVestingScheduleRequest
} from '@src/service'
import {
  authorizationsUrl,
  memberOptionsUrl,
  optionUrl,
  OptionUrlProps,
  organizationOptionsUrl,
  organizationMemberUrl,
  plansUrl,
  planUrl,
  PlanUrlProps,
  poolUrl,
  PoolUrlProps,
  projectScheduleUrl,
  vestingUrl,
  vestingsUrl,
  VestingUrlProps,
  planDashboardUrl,
} from './urls'
import {
  GetAuthorizationsResponse,
  GetIncentiveDocumentsResponse,
  GetMemberOptionsResponse,
  GetOptionResponse,
  GetOptionsResponse,
  GetPlanResponse,
  GetPlansResponse,
  GetPoolResponse,
  GetPoolsResponse,
  GetVestingResponse,
  GetVestingsResponse,
  ProjectVestingScheduleResponse,
} from './responses'
import { Hash } from 'shared/helpers'
import { GetMemberResponse, OrganizationUrlProps, SecurityUrlProps } from '@logic'
import { securityUrl } from '../urls'
import { GetSecurityTypeResponse } from '../responses'

export type PlansProps = GetPlansResponse | undefined
export type PoolsProps = GetPoolsResponse | undefined
export type ProjectVestingScheduleProps = ProjectVestingScheduleResponse

export const getPlans = newGetFunction<OrganizationUrlProps, GetPlansResponse>(plansUrl)
export const getPlan = newGetFunction<PlanUrlProps, GetPlanResponse>(planUrl)
export const getPlanDashboard = newGetFunction<PlanUrlProps, {}>(planDashboardUrl)
export const getPool = newGetFunction<PoolUrlProps, GetPoolResponse>(poolUrl)
export const getPools = newGetFunction<PlanUrlProps, GetPoolsResponse>(planUrl)
export const getOption = newGetFunction<OptionUrlProps, GetOptionResponse>(optionUrl)
export const getOptions = newGetFunction<PoolUrlProps, GetOptionsResponse>(poolUrl)
export const getVesting = (plan: Hash) => newGetFunction<VestingUrlProps, GetVestingResponse>(`${vestingUrl}?plan=${plan}`)
export const getVestings = (plan: Hash) => newGetFunction<{}, GetVestingsResponse>(`${vestingsUrl}?plan=${plan}`)
export const getIncentiveDocuments = (plan: Hash) => newGetFunction<{}, GetIncentiveDocumentsResponse>(`incentive/documents?plan=${plan}`)
export const projectSchedule = newPostFunction<{}, ProjectVestingScheduleRequest, ProjectVestingScheduleResponse>(projectScheduleUrl)
export const getMemberOptions = newGetFunction<MemberUrlProps, GetMemberOptionsResponse>(memberOptionsUrl)
export const getOrganizationOptions = (block?: Hash) => newGetFunction<OrganizationUrlProps, GetOptionsResponse>(`${organizationOptionsUrl}${block ? `?block=${block}` : ''}`)
export const getAuthorizations = (target: Hash) => newGetFunction<{}, GetAuthorizationsResponse>(`${authorizationsUrl}?target=${target}`)
export const getMember = newGetFunction<OrganizationUrlProps & MemberUrlProps, GetMemberResponse>(organizationMemberUrl)
export const getSecurity = newGetFunction<SecurityUrlProps, GetSecurityTypeResponse>(securityUrl)
