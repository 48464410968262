import * as React from 'react'
import { displayToast, DisplayToastDispatch, PageProps, Paths } from '@logic'
import styled from 'styled-components'
import { AppContext } from '@src/app'
import { History } from 'history'
import { withRouter } from 'react-router'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { HttpError, Service } from '@src/service'
import { getQueryParams } from '@helpers/util'
import { StyledAuth, StyledAuthForm, StyledTopHeader } from '../style'
import ForgotLogo from '@image/forgot.svg'
import PadLockLogo from '@image/padlock.svg'
import arrayMutators from 'final-form-arrays'
import { CommonForm } from '@shared/components/forms'
import { ForgotPasswordForm } from '../components/forgot-password-form'

interface StateProps {
  displayToast: DisplayToastDispatch
}

type Props = StateProps & PageProps
const StyledForgot = styled.div`
  text-align: center;
  svg {
    margin-bottom: 24px;
  }
`

const ForgotPasswordComponent = (props: Props) => {
  const { history, service, navigate } = props
  const parsed = getQueryParams()
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (parsed.email && parsed.password) {
      service.login({ usernameOrEmail: parsed.email, password: parsed.password }, (loginData: any) => {
        if (!loginData.user) {
          navigate(Paths.userForgot)
        } else {
          setUser(loginData.user)
        }
      })
    }
  }, [])

  const initialValues = { usernameOrEmail: '' }

  const onSubmit = async (data: any) => {
    if (parsed.password) {
      if (user) {
        service.updateUser({ password: data.password }, (result: any) => {
          if (!(result instanceof HttpError)) {
            props.displayToast('passwordUpdated')
            service.logout()
            navigate(Paths.success)
          }
        })
      }
    }
    else {
      const resetPass = await service.passwordReset({ usernameOrEmail: data.usernameOrEmail })
      if (!resetPass.error) {
        navigate(Paths.emailSent, {}, { email: data.usernameOrEmail })
      }
    }
  }

  return (
    <StyledAuth>
      <StyledAuthForm>
        <StyledForgot>
          <div>{parsed.password ? <PadLockLogo /> : <ForgotLogo />}</div>
          <StyledTopHeader>
            {parsed.password ? <h2>Reset Your Password</h2> : <h2>Forgot Your Password</h2>}
          </StyledTopHeader>
          <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
            stretched={true} noPrompt={true}>
            <ForgotPasswordForm parsed={parsed} history={history} />
          </CommonForm>
        </StyledForgot>
      </StyledAuthForm>
    </StyledAuth>
  )
}

const ForgotWithService = (props: Props) => (
  <AppContext.Consumer>{context => <ForgotPasswordComponent {...props} service={context.service!} />}</AppContext.Consumer>
)

export const ForgotPassword = connect(
  null,
  { displayToast }
)(withRouter(ForgotWithService as any))
