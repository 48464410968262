import * as React from 'react'
import { formBasicModalBundle } from '@shared/components/basic-modal'
import styled from 'styled-components'
import { FlexCol, screenSizes, Themes } from '@src/styles'
import LinkedIn from '@image/linkedIn.png'
import Twitter from '@image/twitter.png'
import Facebook from '@image/facebook.png'
import Instagram from '@image/instagram.png'
import Messenger from '@image/messenger.png'
import Telegram from '@image/telegram.png'
import Reddit from '@image/reddit.png'
import { copyField, renderFieldsSimple } from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers'
import { SharingButton } from '@shared/components/sharing-button'

interface Props {
    referralLink: string
    forOrg?: boolean
}

const SocialLinksWrap = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  padding: 28px 32px 32px;
  border-radius: 8px;
  div {
      width: 100%;
  }
  .body {
      padding: 0;
  }
  .copyButton {
    width: 48px !important;
    height: 48px !important;
  }
  input {
      background: ${({ theme }) => theme.selected === Themes.LIGHT && '#DFE1E2'} !important;
  }
  @media(max-width: ${screenSizes.M}px) {
  }
`
const Links = styled.div<any>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 40px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  img {
      cursor: pointer;
  }
  a {
      width: 60px;
      margin-right: 0;
  }
  @media(max-width: ${screenSizes.M}px) {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    a {
        width: 46px;
    }
    img {
      width: 48px;
      height: 48px;
    }
  }
`

export interface SocialMediaProps {
    type: string,
    icon: any,
    fullUrl: string,
}


export function socialMediaLinksModal(props: Props) {
    const { forOrg, referralLink } = props
    const url = encodeURIComponent('app.equastart.io')
    const subject = 'Earn free Equa Cash with Me!'
    const message = 'Create a free account on this cool equity management tool I use, and we both can earn free Equa cash!'

    const socialMediaButtons: SocialMediaProps[] = [
        {
            type: 'linkedin',
            icon: LinkedIn,
            fullUrl: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
        },
        {
            type: 'twitter',
            icon: Twitter,
            fullUrl: `https://twitter.com/intent/tweet/?text=${message}&url=${url}`,
        },
        {
            type: 'facebook',
            icon: Facebook,
            fullUrl: 'http://www.facebook.com/',
        },
        {
            type: 'instagram',
            icon: Instagram,
            fullUrl: 'https://www.instagram.com/',
        },
        {
            type: 'messenger',
            icon: Messenger,
            fullUrl: 'http://m.me/',
        },
        {
            type: 'telegram',
            icon: Telegram,
            fullUrl: `https://telegram.me/share/url?url=${url}`,
        },
        {
            type: 'reddit',
            icon: Reddit,
            fullUrl: `https://reddit.com/message/compose/?subject=${subject}&message=${message}%0A${url}`,
        },
    ]

    const SocialMediaLinksComponent = () => {
        return (
            <SocialLinksWrap>
                <Links>
                    {socialMediaButtons.map(s => (
                        <SharingButton
                            type={s.type}
                            icon={s.icon}
                            fullUrl={s.fullUrl}
                        />
                    ))}
                </Links>
                {renderFieldsSimple([{
                    name: 'referralLink',
                    label: 'Or copy this link to share',
                    required: false,
                    validate: emptyValidator,
                    component: copyField,
                    noMargin: true,
                }])}
            </SocialLinksWrap>
        )
    }

    return formBasicModalBundle({
        title: forOrg ? 'Share your “Org” referral link' : 'Share your “Profile” referral link',
        body: <SocialMediaLinksComponent />,
        noButtons: true,
        max: '724px',
        noCancel: true,
        align: 'center',
        onSubmit: () => { },
        initialValues: { referralLink },
        noPadding: true
    })
}
