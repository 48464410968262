import * as React from 'react'
import { ChangeEventHandler } from 'react'
import styled, { css } from 'styled-components'
import { Checkbox } from '@components/checkbox'
import { DarkDivider, FlexRow, grayColor, SmallHeader } from '@src/styles'

interface Props {
  name: string
  email?: string
  selected: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

export const themeGray = css`
  color: ${({ theme }) => theme.label};
`

const DisabledSmallHeader = styled<any>(SmallHeader)`
  ${themeGray}
`

const SubHeader = styled.div`
  ${themeGray}
`

export const InvitedMemberRow = ({ name, email, selected, onChange }: Props) => (
  !!email
    ? <div>
      <FlexRow>
        <div style={{ paddingRight: '35px' }}>
          <Checkbox checked={selected} onChange={onChange} />
        </div>
        <div>
          <SmallHeader>{name}</SmallHeader>
          <SubHeader>{email}</SubHeader>
        </div>
      </FlexRow>
      <DarkDivider />
    </div>
    : <div>
      <FlexRow>
        <div style={{ width: '35px' }} />
        <div>
          <DisabledSmallHeader>{name}</DisabledSmallHeader>
          <SubHeader>No Email Address</SubHeader>
        </div>
      </FlexRow>
      <DarkDivider />
    </div>
)
