import * as React from 'react'
import { AddressType, CurrencyType, GetReferralDataResponse, OrganizationsProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import styled from 'styled-components'
import { CommonForm, Sticky } from '@shared/components/forms'
import { snackBar, TransferCashForm } from '../components'
import arrayMutators from 'final-form-arrays'
import { equaCashTransfer, NewEquaCashRequest } from '@src/service/services/profile'
import { TransferInfo } from '../components/transfer-info'
import { confirmTransferModal } from '../components/modal/confirm-transfer-modal'
import { CashTransferFormFields } from '../pages'

type Props = PageProps & OrganizationsProps & GetReferralDataResponse & { forGuest?: boolean }

const EquaCashWrap = styled.div<any>``

export const TransferEquaCashShared = (props: Props) => {
    const [formValues, setFormValues] = React.useState<CashTransferFormFields | undefined>()
    const { user, organizations, httpClient, userDetails, reload, forGuest } = props
    const balance = parseFloat(userDetails?.equaCash?.toFixed(2)) || 0
    const currentOrganizations = organizations ?
        organizations.filter(org => org.creator === user?.id)?.sort((a, b) => a.name.localeCompare(b.name)) : []
    const selectedOrg = currentOrganizations.find(org => org.id === formValues?.to)?.name
    const onSubmit = async (values?: CashTransferFormFields) => {
        const request: NewEquaCashRequest = {
            to: values?.to!,
            value: values?.value!,
            from: user?.id!,
            fromType: AddressType.organization,
            toType: AddressType.member,
            currency: 'equacash',
            currencyType: CurrencyType.usd,
            memo: values?.memo!,
        }
        const response = await equaCashTransfer(httpClient)({}, request)
        if (response) {
            verifyTransferModal.setVisible(false)
            snackBackMessage.setShow(true)
        }
        else console.log('transfer error') // TODO: add error message toast
    }

    const handleSubmit = (values: any) => {
        verifyTransferModal.setVisible(true)
        setFormValues(values)
    }

    const verifyTransferModal = confirmTransferModal({
        from: user?.profile?.fullName!,
        to: selectedOrg,
        amount: formValues?.value!,
        onSubmit: () => onSubmit(formValues),
        balance,
    })

    const snackBackMessage = snackBar({ message: 'Your transfer was successful!', reload })

    const sticky: Sticky<any> = (props: any) => (
        <TransferInfo values={props.values} balance={balance} />
    )

    return (
        <>
            {snackBackMessage.component}
            {verifyTransferModal.component}
            <PageContent withMargin={true}>
                <PageContentHeader
                    title={'Equa Cash Transfer'}
                    hideCrumbs={true}
                    subTitle={'Easily transfer from this profile’s Equa Cash balance to your other organizations to spend on their Equa subscription dues.'} />
                <EquaCashWrap>
                    <CommonForm
                        mutators={{ ...arrayMutators }}
                        onSubmit={handleSubmit}
                        initialValues={{}}
                        submitText={'transfer'}
                        sticky={sticky}>
                        <TransferCashForm balance={balance} organizations={currentOrganizations} />
                    </CommonForm>
                </EquaCashWrap>
            </PageContent>
        </>
    )
}
