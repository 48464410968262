import * as React from 'react'
import {
  OrganizationProps,
  PageProps,
  organizationNewSecurityPath,
  organizationViewSecurityPath,
  ShareType,
  organizationEditSecurityPath,
  organizationSecuritiesPath
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadSecurities, loadOrganization } from '@helpers/loaders'
import { PathLinkButtonInverted } from '@components/button/button'
import { GetSecuritiesResponse } from '@src/service'
import { FlexRow, LightDivider, whiteColor, gapSizes } from '@src/styles'
import { PathLink } from '@components/navigation'
import { CellProps } from 'react-table'
import { Table } from '@components/tables/table'
import { ellipsesColumn } from '@components/tables/ellipses'
import { formatSecurityName, percentageString, toCommaFloat } from '@shared/helpers'
import { formatSeniorityText } from '../utility'
import styled from 'styled-components'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { EmptyLanding } from '@shared/components/empty-landing'

type Props = PageProps & OrganizationProps & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganization, loadSecurities('true'))

const SecuritiesListWrapper = styled.div`
  ${whiteColor}
  h4 {
    padding-bottom: ${gapSizes.S};
  }
`

export const ListSecuritiesPage = withData(
  withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
    const { id } = props.organization
    const { securities, navigate, params, stats } = props
    const securitiesList = securities.filter(s => !s.parent).map((arg): any => {
      const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
      return { ...arg, stats: statsValue }
    })
    const issued = securitiesList.filter((arg: any) => arg.shareType !== ShareType.convertibleNotes)
    const authorizedTotal = issued.reduce((a, b) => a + b.stats.totalAuthorized, 0)
    const outstandingTotal = issued.reduce((a, b) => a + b.stats.outstanding, 0)
    const fullyDilutedTotal = issued.reduce((a, b) => a + b.stats.fullyDiluted, 0)
    const treasuryTotal = issued.reduce((a, b) => a + b.stats.treasury, 0)
    const reservedTotal = issued.reduce((a, b) => a + b.stats.reserved, 0)
    // const convertible = securitiesList.filter((arg: any) => arg.shareType === ShareType.convertibleNotes)
    const tableData = issued?.map(l => {
      const percentage = percentageString(l.stats.totalAuthorized!, authorizedTotal)
      return { ...l, percentage }
    })
    const columns = (forSafe: boolean) => {
      return [
        {
          Header: 'Equity',
          accessor: 'securityType',
          Cell: ({ row }: CellProps<any>) => {
            return (
              <PathLink path={organizationViewSecurityPath} args={{ organization: id, security: row.original.hash }}>
                <FlexRow>
                  <span>{formatSecurityName(row.original)}</span>
                </FlexRow>
              </PathLink>
            )
          },
          Footer: () => <div>TOTAL</div>,
        },
        {
          Header: 'Payout Order',
          accessor: 'payoutOrder',
          Cell: ({ row }: CellProps<any>) => {
            const seniority = row.original.seniority
            return (
              <span>{formatSeniorityText(seniority)}</span>
            )
          },
        },
        {
          Header: 'Total Authorized',
          accessor: 'stats.totalAuthorized',
          Cell: ({ row }: CellProps<any>) => {
            const totalAuthorized = row.original.stats.totalAuthorized
            return (
              <span>{toCommaFloat(totalAuthorized)}</span>
            )
          },
          Footer: () => <span>{toCommaFloat(authorizedTotal)}</span>,
        },
        {
          Header: 'Treasury',
          accessor: 'stats.treasury',
          Cell: ({ row }: CellProps<any>) => {
            const treasury = row.original.stats.treasury
            return (
              <span>{toCommaFloat(treasury)}</span>
            )
          },
          Footer: () => <span>{toCommaFloat(treasuryTotal)}</span>,
        },
        {
          Header: 'Reserved',
          accessor: 'stats.reserved',
          Cell: ({ row }: CellProps<any>) => {
            const reserved = row.original.stats.reserved
            return (
              <span>{toCommaFloat(reserved)}</span>
            )
          },
          Footer: () => <span>{toCommaFloat(reservedTotal)}</span>,
        },
        {
          Header: 'Outstanding',
          accessor: 'stats.outstanding',
          Cell: ({ row }: CellProps<any>) => {
            const outstanding = row.original.stats.outstanding
            return (
              <span>{toCommaFloat(outstanding)}</span>
            )
          },
          Footer: () => <span>{toCommaFloat(outstandingTotal)}</span>,
        },
        {
          Header: forSafe ? 'Redeemed' : 'Fully Diluted',
          accessor: forSafe ? 'redeemed' : 'stats.fullyDiluted',
          Cell: ({ row }: CellProps<any>) => {
            const fullyDiluted = row.original.stats.fullyDiluted
            return (
              <span>{toCommaFloat(fullyDiluted)}</span>
            )
          },
          Footer: () => <span>{toCommaFloat(fullyDilutedTotal)}</span>,
        },

        {
          Header: 'Total Auth %',
          accessor: 'percentage',
          Cell: ({ row }: CellProps<any>) => <span>{row.original.percentage!}</span>,
          Footer: () => <span>100%</span>,
        },
        ellipsesColumn(
          [
            ['Edit', ({ row }) => navigate(organizationEditSecurityPath, { organization: id, security: row.original.hash })],
            // ['Add Shares', ({ row }) => navigate(organizationAddSecuritySharesPath, { organization: id, security: row.original.hash })]
          ]
        ),
      ]
    }

    return (
      <PageContent>
        {securitiesList.length > 0 ?
          <>
            <PageContentHeader title="Equities">
              <PathLinkButtonInverted path={organizationNewSecurityPath} args={{ organization: id }}>
                ADD NEW EQUITY
              </PathLinkButtonInverted>
            </PageContentHeader>
            <SecuritiesListWrapper>
              <FlexRow>
                <h4>Issued Shares/Units</h4>
              </FlexRow>
              <Table columns={columns(false)} data={tableData} scrollingTable={true} withAllBorders={true} />
              <LightDivider stretch={true} />
            </SecuritiesListWrapper>
          </>
          :
          <EmptyLanding path={organizationSecuritiesPath} params={params} />
        }
      </PageContent>
    )
  }))
