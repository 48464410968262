import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { AmountType, editVestingPath, PageProps, vestingsPath, viewVestingPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission, getVestmentTrigger } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { FlexRow, screenSizes, semiBoldStyle } from '@src/styles'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { BaseLinkProps, overflowParams, PathLink } from '@shared/components/navigation'
import { Panel, PanelGrid } from '@shared/components/panels/panels'
import styled from 'styled-components'
import { EventPanelProps, InfoPanelProps, StyledInfo, ViewInfoPanel } from '../components/view-info-panel'
import { formatDateString, loadAuthorizations, loadVesting, normalizeDate, optionalCommaNumber, Uuid } from '@shared/helpers'
import { deleteVesting, ExpandedHolding, GetAuthorizationsResponse, GetVestingResponse } from '@src/service'
import { basicDeleteModal } from '@shared/components/modal'
import { StyledLink } from '@modules/captable/pages'
import { HorizontalVestingChart } from '../components/vesting-chart'

interface ViewProps {
    fromCertificate?: boolean
    holding?: ExpandedHolding
}

type Props = PageProps & GetVestingResponse & GetAuthorizationsResponse & ViewProps

const withData = withLoadingCachedMultiple<Props>(loadVesting, loadAuthorizations('vesting'))

const TitleRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px;
    color: ${({ theme }) => theme.white};
    h5 {
        margin: 0;
        ${semiBoldStyle}
        font-size: 24px;
        line-height: 32px;
    }
`

const StandardInfo = styled<any>(Panel)`
    div, .data {
        width: 100%;
    }
    .data {
        :first-child {
            margin-right: 82px;
        }
    }
    @media (max-width: ${screenSizes.M}px) {
        .dataWrap {
            flex-direction: column;
        }
        .data {
            margin-right: 0;
        }
    }
`

export const ViewVestingPage = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const {
            params,
            httpClient,
            navigate,
            vestingSchedule,
            authorizations,
            fromCertificate,
            holding } = props
        const { organization, plan, vesting } = params
        const handleDeleteVesting = async () => {
            await deleteVesting(httpClient)({ plan, vestingSchedule: vesting })
            deleteModal.setVisible(false)
            navigate(vestingsPath, { organization, plan })
        }
        const deleteModal = basicDeleteModal(
            handleDeleteVesting,
            [vestingSchedule?.name || ''],
        )
        const dropOptions = [
            ['Edit', () => navigate(overflowParams(editVestingPath, { organization, plan, vesting: vestingSchedule?.hash, parent: location?.pathname! }))],
            ['Delete', () => deleteModal.setVisible(true)],
        ]

        const openDocument = (doc?: Uuid, name?: string) => {
            if (doc && name) {
                const n = encodeURIComponent(name)
                window.open(`/api/v1/file/${doc}/content/${n}`)
            }
        }

        const renderStandard = (data: any) => {
            return <div className='data'>
                {data.map(d =>
                    <StyledInfo>
                        <span className='left'>{d.label}</span>
                        {(d.link && d.value !== 'N/A') ?
                            <PathLink path={d.link} args={d.args} target={'_blank'} className='right'>{d.value}</PathLink>
                            :
                            d.document ?
                                <StyledLink
                                    onClick={() => openDocument(d?.document!, d?.filename!)}
                                    style={{ width: 'auto', textAlign: 'right' }}>
                                    {d?.value!}
                                </StyledLink>
                                :
                                <span className='right'>{d.value}</span>
                        }
                    </StyledInfo>
                )}
            </div>
        }

        const documentsAndNotes = authorizations && authorizations.length > 0 ?
            authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
        const documents = documentsAndNotes?.map((auth, index: number) => {
            return {
                label: `Document ${index + 1}`,
                value: auth.documentTypeName! || auth.document?.filename || auth.filename,
                document: auth.document?.hash! || auth.hash,
                filename: auth.document?.filename || auth.filename
            }
        })

        const isDiscreet = vestingSchedule ? Object.keys(vestingSchedule)?.includes('events') : false
        const vestingEvents = vestingSchedule.events ?
            vestingSchedule?.isValueAbsolute ?
                vestingSchedule.events.map(event => {
                    const date = new Date(`${event.month}/${event.day}/${event.year}`)
                    return { ...event, date }
                }).sort((a, b) => a.date && b.date && a.date.getTime() - b.date.getTime())
                :
                vestingSchedule.events.map(event => ({ ...event, rValue: event.value * 100 }))
                    .sort((a, b) => a.month && b.month && a.month - b.month)
            : []
        const schedule = isDiscreet ? { ...vestingSchedule, events: vestingEvents } : vestingSchedule

        const standardLeft = [
            {
                label: 'Type',
                value: 'Standard'
            },
            {
                label: 'Name',
                value: vestingSchedule?.name || '',
                link: fromCertificate ? viewVestingPath : undefined,
                args: fromCertificate ? { organization, plan, vesting: vestingSchedule.hash } : undefined
            },
            {
                label: 'Vesting Duration (months)',
                value: vestingSchedule?.durationMonths || 0,
            },
            {
                label: 'Vesting Frequency',
                value: vestingSchedule?.frequencyMonths || 0,
            },
        ]
        const standardRight = [
            {
                label: 'Vests On',
                value: vestingSchedule?.vestsOn ? getVestmentTrigger[vestingSchedule.vestsOn] : 'N/A',
            },
            {
                label: 'Vesting Cliff (months)',
                value: vestingSchedule?.cliffMonths || 0,
            },
            {
                label: 'Custom Amount Vested at Cliff',
                value: vestingSchedule?.cliffAmount && vestingSchedule?.cliffAmountType ?
                    vestingSchedule.cliffAmountType == AmountType.percentage ?
                        `${vestingSchedule?.cliffAmount}%` : optionalCommaNumber(vestingSchedule?.cliffAmount)
                    : 0,
            },
            ...documents
        ]

        const customData: InfoPanelProps[] = isDiscreet ? [
            {
                label: 'Type',
                value: `Custom (${vestingSchedule?.isValueAbsolute ? 'Absolute' : 'Relative'})`
            },
            {
                label: 'Name',
                value: vestingSchedule?.name || '',
                link: fromCertificate ? viewVestingPath : undefined,
                args: fromCertificate ? { organization, plan, vesting: vestingSchedule.hash } : undefined
            },
            {
                label: 'Number of Events',
                value: vestingSchedule?.events?.length || 0,
            },
            ...documents
        ] : []

        const events: EventPanelProps[] = isDiscreet ?
            vestingEvents.map((e: any, i: number) => {
                const amount = vestingSchedule.isValueAbsolute ? optionalCommaNumber(e.rValue || e.value)
                    : `${e.rValue || e.value}%`
                const date = vestingSchedule.isValueAbsolute ?
                    formatDateString(new Date(e.year, (e.month - 1), e.day))
                    :
                    `Month ${e.month}`
                return {
                    event: i + 1,
                    date,
                    amount
                }
            }) : []

        const isAbsolute = isDiscreet ? vestingSchedule.isValueAbsolute : false
        const chartValue = fromCertificate ? isDiscreet ? !isAbsolute ? holding?.startingValue
            : undefined : holding?.startingValue
            : isDiscreet ? !isAbsolute ? 1 : undefined
                : vestingSchedule.cliffAmount ? vestingSchedule.cliffAmount * 10 : 100

        const parent: BaseLinkProps = { path: vestingsPath, args: { organization, plan } }

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    <PageContentHeader
                        title={vestingSchedule?.name || ''}
                        parent={parent} direction>
                        <FlexRow style={{ alignItems: 'baseline' }}>
                            {!fromCertificate ? <ActionsDrop options={dropOptions} /> : null}
                        </FlexRow>
                    </PageContentHeader>
                    <HorizontalVestingChart
                        vestingSchedule={schedule}
                        startDate={fromCertificate ? normalizeDate(holding?.startDate) : normalizeDate(vestingSchedule.startDate || undefined)}
                        value={chartValue}
                        dontNotify={fromCertificate ? false : true}
                        custom={isDiscreet}
                        forView={fromCertificate ? undefined : true}
                    />
                    {isDiscreet ? <PanelGrid columns={2}>
                        <ViewInfoPanel
                            title='Vesting Schedule Details'
                            data={customData}
                            navigate={navigate}
                            params={params}
                            max={'628px'}
                            height={'320px'}
                        />
                        <ViewInfoPanel
                            title='Vesting Event Details'
                            data={events}
                            navigate={navigate}
                            params={params}
                            max={'628px'}
                            height={'320px'}
                            forEvents={isDiscreet}
                        />
                    </PanelGrid>
                        :
                        <StandardInfo>
                            <TitleRow>
                                <h5>Vesting Schedule Details</h5>
                            </TitleRow>
                            <FlexRow alignItems={'flex-start'} className='dataWrap'>
                                {renderStandard(standardLeft)}
                                {renderStandard(standardRight)}
                            </FlexRow>
                        </StandardInfo>
                    }
                </PageContent>
            </>
        )
    })
)
