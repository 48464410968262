export const countries = [
  {
    label: 'Afghanistan',
    alpha2: 'AF',
    alpha3: 'AFG',
    value: '004',
    value_old: 'ISO 3166-2:AF',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Åland Islands',
    alpha2: 'AX',
    alpha3: 'ALA',
    value: '248',
    value_old: 'ISO 3166-2:AX',
    subRegionCode: '154',
    regionCode: '150',
    subRegion: 'Northern Europe',
    region: 'Europe',
  },
  {
    label: 'Albania',
    alpha2: 'AL',
    alpha3: 'ALB',
    value: '008',
    value_old: 'ISO 3166-2:AL',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Algeria',
    alpha2: 'DZ',
    alpha3: 'DZA',
    value: '012',
    value_old: 'ISO 3166-2:DZ',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'American Samoa',
    alpha2: 'AS',
    alpha3: 'ASM',
    value: '016',
    value_old: 'ISO 3166-2:AS',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Andorra',
    alpha2: 'AD',
    alpha3: 'AND',
    value: '020',
    value_old: 'ISO 3166-2:AD',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Angola',
    alpha2: 'AO',
    alpha3: 'AGO',
    value: '024',
    value_old: 'ISO 3166-2:AO',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Anguilla',
    alpha2: 'AI',
    alpha3: 'AIA',
    value: '660',
    value_old: 'ISO 3166-2:AI',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Antarctica',
    alpha2: 'AQ',
    alpha3: 'ATA',
    value: '010',
    value_old: 'ISO 3166-2:AQ',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Antigua and Barbuda',
    alpha2: 'AG',
    alpha3: 'ATG',
    value: '028',
    value_old: 'ISO 3166-2:AG',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Argentina',
    alpha2: 'AR',
    alpha3: 'ARG',
    value: '032',
    value_old: 'ISO 3166-2:AR',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Armenia',
    alpha2: 'AM',
    alpha3: 'ARM',
    value: '051',
    value_old: 'ISO 3166-2:AM',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Aruba',
    alpha2: 'AW',
    alpha3: 'ABW',
    value: '533',
    value_old: 'ISO 3166-2:AW',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Australia',
    alpha2: 'AU',
    alpha3: 'AUS',
    value: '036',
    value_old: 'ISO 3166-2:AU',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    regionCode: '009',
    subRegionCode: '053',
  },
  {
    label: 'Austria',
    alpha2: 'AT',
    alpha3: 'AUT',
    value: '040',
    value_old: 'ISO 3166-2:AT',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Azerbaijan',
    alpha2: 'AZ',
    alpha3: 'AZE',
    value: '031',
    value_old: 'ISO 3166-2:AZ',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Bahamas',
    alpha2: 'BS',
    alpha3: 'BHS',
    value: '044',
    value_old: 'ISO 3166-2:BS',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Bahrain',
    alpha2: 'BH',
    alpha3: 'BHR',
    value: '048',
    value_old: 'ISO 3166-2:BH',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Bangladesh',
    alpha2: 'BD',
    alpha3: 'BGD',
    value: '050',
    value_old: 'ISO 3166-2:BD',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Barbados',
    alpha2: 'BB',
    alpha3: 'BRB',
    value: '052',
    value_old: 'ISO 3166-2:BB',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Belarus',
    alpha2: 'BY',
    alpha3: 'BLR',
    value: '112',
    value_old: 'ISO 3166-2:BY',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Belgium',
    alpha2: 'BE',
    alpha3: 'BEL',
    value: '056',
    value_old: 'ISO 3166-2:BE',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Belize',
    alpha2: 'BZ',
    alpha3: 'BLZ',
    value: '084',
    value_old: 'ISO 3166-2:BZ',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Benin',
    alpha2: 'BJ',
    alpha3: 'BEN',
    value: '204',
    value_old: 'ISO 3166-2:BJ',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Bermuda',
    alpha2: 'BM',
    alpha3: 'BMU',
    value: '060',
    value_old: 'ISO 3166-2:BM',
    region: 'Americas',
    subRegion: 'Northern America',
    regionCode: '019',
    subRegionCode: '021',
  },
  {
    label: 'Bhutan',
    alpha2: 'BT',
    alpha3: 'BTN',
    value: '064',
    value_old: 'ISO 3166-2:BT',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    alpha2: 'BO',
    alpha3: 'BOL',
    value: '068',
    value_old: 'ISO 3166-2:BO',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    alpha2: 'BQ',
    alpha3: 'BES',
    value: '535',
    value_old: 'ISO 3166-2:BQ',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Bosnia and Herzegovina',
    alpha2: 'BA',
    alpha3: 'BIH',
    value: '070',
    value_old: 'ISO 3166-2:BA',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Botswana',
    alpha2: 'BW',
    alpha3: 'BWA',
    value: '072',
    value_old: 'ISO 3166-2:BW',
    region: 'Africa',
    subRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '018',
  },
  {
    label: 'Bouvet Island',
    alpha2: 'BV',
    alpha3: 'BVT',
    value: '074',
    value_old: 'ISO 3166-2:BV',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Brazil',
    alpha2: 'BR',
    alpha3: 'BRA',
    value: '076',
    value_old: 'ISO 3166-2:BR',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'British Indian Ocean Territory',
    alpha2: 'IO',
    alpha3: 'IOT',
    value: '086',
    value_old: 'ISO 3166-2:IO',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Brunei Darussalam',
    alpha2: 'BN',
    alpha3: 'BRN',
    value: '096',
    value_old: 'ISO 3166-2:BN',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Bulgaria',
    alpha2: 'BG',
    alpha3: 'BGR',
    value: '100',
    value_old: 'ISO 3166-2:BG',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Burkina Faso',
    alpha2: 'BF',
    alpha3: 'BFA',
    value: '854',
    value_old: 'ISO 3166-2:BF',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Burundi',
    alpha2: 'BI',
    alpha3: 'BDI',
    value: '108',
    value_old: 'ISO 3166-2:BI',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Cambodia',
    alpha2: 'KH',
    alpha3: 'KHM',
    value: '116',
    value_old: 'ISO 3166-2:KH',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Cameroon',
    alpha2: 'CM',
    alpha3: 'CMR',
    value: '120',
    value_old: 'ISO 3166-2:CM',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Canada',
    alpha2: 'CA',
    alpha3: 'CAN',
    value: '124',
    value_old: 'ISO 3166-2:CA',
    region: 'Americas',
    subRegion: 'Northern America',
    regionCode: '019',
    subRegionCode: '021',
  },
  {
    label: 'Cabo Verde',
    alpha2: 'CV',
    alpha3: 'CPV',
    value: '132',
    value_old: 'ISO 3166-2:CV',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Cayman Islands',
    alpha2: 'KY',
    alpha3: 'CYM',
    value: '136',
    value_old: 'ISO 3166-2:KY',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Central African Republic',
    alpha2: 'CF',
    alpha3: 'CAF',
    value: '140',
    value_old: 'ISO 3166-2:CF',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Chad',
    alpha2: 'TD',
    alpha3: 'TCD',
    value: '148',
    value_old: 'ISO 3166-2:TD',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Chile',
    alpha2: 'CL',
    alpha3: 'CHL',
    value: '152',
    value_old: 'ISO 3166-2:CL',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'China',
    alpha2: 'CN',
    alpha3: 'CHN',
    value: '156',
    value_old: 'ISO 3166-2:CN',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Christmas Island',
    alpha2: 'CX',
    alpha3: 'CXR',
    value: '162',
    value_old: 'ISO 3166-2:CX',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Cocos (Keeling) Islands',
    alpha2: 'CC',
    alpha3: 'CCK',
    value: '166',
    value_old: 'ISO 3166-2:CC',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Colombia',
    alpha2: 'CO',
    alpha3: 'COL',
    value: '170',
    value_old: 'ISO 3166-2:CO',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Comoros',
    alpha2: 'KM',
    alpha3: 'COM',
    value: '174',
    value_old: 'ISO 3166-2:KM',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Congo',
    alpha2: 'CG',
    alpha3: 'COG',
    value: '178',
    value_old: 'ISO 3166-2:CG',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Congo (Democratic Republic of the)',
    alpha2: 'CD',
    alpha3: 'COD',
    value: '180',
    value_old: 'ISO 3166-2:CD',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Cook Islands',
    alpha2: 'CK',
    alpha3: 'COK',
    value: '184',
    value_old: 'ISO 3166-2:CK',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Costa Rica',
    alpha2: 'CR',
    alpha3: 'CRI',
    value: '188',
    value_old: 'ISO 3166-2:CR',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Côte d’Ivoire',
    alpha2: 'CI',
    alpha3: 'CIV',
    value: '384',
    value_old: 'ISO 3166-2:CI',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Croatia',
    alpha2: 'HR',
    alpha3: 'HRV',
    value: '191',
    value_old: 'ISO 3166-2:HR',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Cuba',
    alpha2: 'CU',
    alpha3: 'CUB',
    value: '192',
    value_old: 'ISO 3166-2:CU',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Curaçao',
    alpha2: 'CW',
    alpha3: 'CUW',
    value: '531',
    value_old: 'ISO 3166-2:CW',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Cyprus',
    alpha2: 'CY',
    alpha3: 'CYP',
    value: '196',
    value_old: 'ISO 3166-2:CY',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Czech Republic',
    alpha2: 'CZ',
    alpha3: 'CZE',
    value: '203',
    value_old: 'ISO 3166-2:CZ',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Denmark',
    alpha2: 'DK',
    alpha3: 'DNK',
    value: '208',
    value_old: 'ISO 3166-2:DK',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Djibouti',
    alpha2: 'DJ',
    alpha3: 'DJI',
    value: '262',
    value_old: 'ISO 3166-2:DJ',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Dominica',
    alpha2: 'DM',
    alpha3: 'DMA',
    value: '212',
    value_old: 'ISO 3166-2:DM',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Dominican Republic',
    alpha2: 'DO',
    alpha3: 'DOM',
    value: '214',
    value_old: 'ISO 3166-2:DO',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Ecuador',
    alpha2: 'EC',
    alpha3: 'ECU',
    value: '218',
    value_old: 'ISO 3166-2:EC',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Egypt',
    alpha2: 'EG',
    alpha3: 'EGY',
    value: '818',
    value_old: 'ISO 3166-2:EG',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'El Salvador',
    alpha2: 'SV',
    alpha3: 'SLV',
    value: '222',
    value_old: 'ISO 3166-2:SV',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Equatorial Guinea',
    alpha2: 'GQ',
    alpha3: 'GNQ',
    value: '226',
    value_old: 'ISO 3166-2:GQ',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Eritrea',
    alpha2: 'ER',
    alpha3: 'ERI',
    value: '232',
    value_old: 'ISO 3166-2:ER',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Estonia',
    alpha2: 'EE',
    alpha3: 'EST',
    value: '233',
    value_old: 'ISO 3166-2:EE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Ethiopia',
    alpha2: 'ET',
    alpha3: 'ETH',
    value: '231',
    value_old: 'ISO 3166-2:ET',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    alpha2: 'FK',
    alpha3: 'FLK',
    value: '238',
    value_old: 'ISO 3166-2:FK',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Faroe Islands',
    alpha2: 'FO',
    alpha3: 'FRO',
    value: '234',
    value_old: 'ISO 3166-2:FO',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Fiji',
    alpha2: 'FJ',
    alpha3: 'FJI',
    value: '242',
    value_old: 'ISO 3166-2:FJ',
    region: 'Oceania',
    subRegion: 'Melanesia',
    regionCode: '009',
    subRegionCode: '054',
  },
  {
    label: 'Finland',
    alpha2: 'FI',
    alpha3: 'FIN',
    value: '246',
    value_old: 'ISO 3166-2:FI',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'France',
    alpha2: 'FR',
    alpha3: 'FRA',
    value: '250',
    value_old: 'ISO 3166-2:FR',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'French Guiana',
    alpha2: 'GF',
    alpha3: 'GUF',
    value: '254',
    value_old: 'ISO 3166-2:GF',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'French Polynesia',
    alpha2: 'PF',
    alpha3: 'PYF',
    value: '258',
    value_old: 'ISO 3166-2:PF',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'French Southern Territories',
    alpha2: 'TF',
    alpha3: 'ATF',
    value: '260',
    value_old: 'ISO 3166-2:TF',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Gabon',
    alpha2: 'GA',
    alpha3: 'GAB',
    value: '266',
    value_old: 'ISO 3166-2:GA',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Gambia',
    alpha2: 'GM',
    alpha3: 'GMB',
    value: '270',
    value_old: 'ISO 3166-2:GM',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Georgia',
    alpha2: 'GE',
    alpha3: 'GEO',
    value: '268',
    value_old: 'ISO 3166-2:GE',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Germany',
    alpha2: 'DE',
    alpha3: 'DEU',
    value: '276',
    value_old: 'ISO 3166-2:DE',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Ghana',
    alpha2: 'GH',
    alpha3: 'GHA',
    value: '288',
    value_old: 'ISO 3166-2:GH',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Gibraltar',
    alpha2: 'GI',
    alpha3: 'GIB',
    value: '292',
    value_old_old: 'ISO 3166-2:GI',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Greece',
    alpha2: 'GR',
    alpha3: 'GRC',
    value: '300',
    value_old: 'ISO 3166-2:GR',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Greenland',
    alpha2: 'GL',
    alpha3: 'GRL',
    value: '304',
    value_old: 'ISO 3166-2:GL',
    region: 'Americas',
    subRegion: 'Northern America',
    regionCode: '019',
    subRegionCode: '021',
  },
  {
    label: 'Grenada',
    alpha2: 'GD',
    alpha3: 'GRD',
    value: '308',
    value_old: 'ISO 3166-2:GD',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Guadeloupe',
    alpha2: 'GP',
    alpha3: 'GLP',
    value: '312',
    value_old: 'ISO 3166-2:GP',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Guam',
    alpha2: 'GU',
    alpha3: 'GUM',
    value: '316',
    value_old: 'ISO 3166-2:GU',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Guatemala',
    alpha2: 'GT',
    alpha3: 'GTM',
    value: '320',
    value_old: 'ISO 3166-2:GT',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Guernsey',
    alpha2: 'GG',
    alpha3: 'GGY',
    value: '831',
    value_old: 'ISO 3166-2:GG',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Guinea',
    alpha2: 'GN',
    alpha3: 'GIN',
    value: '324',
    value_old: 'ISO 3166-2:GN',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Guinea-Bissau',
    alpha2: 'GW',
    alpha3: 'GNB',
    value: '624',
    value_old: 'ISO 3166-2:GW',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Guyana',
    alpha2: 'GY',
    alpha3: 'GUY',
    value: '328',
    value_old: 'ISO 3166-2:GY',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Haiti',
    alpha2: 'HT',
    alpha3: 'HTI',
    value: '332',
    value_old: 'ISO 3166-2:HT',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Heard Island and McDonald Islands',
    alpha2: 'HM',
    alpha3: 'HMD',
    value: '334',
    value_old: 'ISO 3166-2:HM',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Holy See',
    alpha2: 'VA',
    alpha3: 'VAT',
    value: '336',
    value_old: 'ISO 3166-2:VA',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Honduras',
    alpha2: 'HN',
    alpha3: 'HND',
    value: '340',
    value_old: 'ISO 3166-2:HN',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Hong Kong',
    alpha2: 'HK',
    alpha3: 'HKG',
    value: '344',
    value_old: 'ISO 3166-2:HK',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Hungary',
    alpha2: 'HU',
    alpha3: 'HUN',
    value: '348',
    value_old: 'ISO 3166-2:HU',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Iceland',
    alpha2: 'IS',
    alpha3: 'ISL',
    value: '352',
    value_old: 'ISO 3166-2:IS',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'India',
    alpha2: 'IN',
    alpha3: 'IND',
    value: '356',
    value_old: 'ISO 3166-2:IN',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Indonesia',
    alpha2: 'ID',
    alpha3: 'IDN',
    value: '360',
    value_old: 'ISO 3166-2:ID',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Iran (Islamic Republic of)',
    alpha2: 'IR',
    alpha3: 'IRN',
    value: '364',
    value_old: 'ISO 3166-2:IR',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Iraq',
    alpha2: 'IQ',
    alpha3: 'IRQ',
    value: '368',
    value_old: 'ISO 3166-2:IQ',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Ireland',
    alpha2: 'IE',
    alpha3: 'IRL',
    value: '372',
    value_old: 'ISO 3166-2:IE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Isle of Man',
    alpha2: 'IM',
    alpha3: 'IMN',
    value: '833',
    value_old: 'ISO 3166-2:IM',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Israel',
    alpha2: 'IL',
    alpha3: 'ISR',
    value: '376',
    value_old: 'ISO 3166-2:IL',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Italy',
    alpha2: 'IT',
    alpha3: 'ITA',
    value: '380',
    value_old: 'ISO 3166-2:IT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Jamaica',
    alpha2: 'JM',
    alpha3: 'JAM',
    value: '388',
    value_old: 'ISO 3166-2:JM',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Japan',
    alpha2: 'JP',
    alpha3: 'JPN',
    value: '392',
    value_old: 'ISO 3166-2:JP',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Jersey',
    alpha2: 'JE',
    alpha3: 'JEY',
    value: '832',
    value_old: 'ISO 3166-2:JE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Jordan',
    alpha2: 'JO',
    alpha3: 'JOR',
    value: '400',
    value_old: 'ISO 3166-2:JO',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Kazakhstan',
    alpha2: 'KZ',
    alpha3: 'KAZ',
    value: '398',
    value_old: 'ISO 3166-2:KZ',
    region: 'Asia',
    subRegion: 'Central Asia',
    regionCode: '142',
    subRegionCode: '143',
  },
  {
    label: 'Kenya',
    alpha2: 'KE',
    alpha3: 'KEN',
    value: '404',
    value_old: 'ISO 3166-2:KE',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Kiribati',
    alpha2: 'KI',
    alpha3: 'KIR',
    value: '296',
    value_old: 'ISO 3166-2:KI',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Korea (Democratic People’S Republic of)',
    alpha2: 'KP',
    alpha3: 'PRK',
    value: '408',
    value_old: 'ISO 3166-2:KP',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Korea (Republic of)',
    alpha2: 'KR',
    alpha3: 'KOR',
    value: '410',
    value_old: 'ISO 3166-2:KR',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Kuwait',
    alpha2: 'KW',
    alpha3: 'KWT',
    value: '414',
    value_old: 'ISO 3166-2:KW',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Kyrgyzstan',
    alpha2: 'KG',
    alpha3: 'KGZ',
    value: '417',
    value_old: 'ISO 3166-2:KG',
    region: 'Asia',
    subRegion: 'Central Asia',
    regionCode: '142',
    subRegionCode: '143',
  },
  {
    label: 'Lao People’S Democratic Republic',
    alpha2: 'LA',
    alpha3: 'LAO',
    value: '418',
    value_old: 'ISO 3166-2:LA',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Latvia',
    alpha2: 'LV',
    alpha3: 'LVA',
    value: '428',
    value_old: 'ISO 3166-2:LV',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Lebanon',
    alpha2: 'LB',
    alpha3: 'LBN',
    value: '422',
    value_old: 'ISO 3166-2:LB',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Lesotho',
    alpha2: 'LS',
    alpha3: 'LSO',
    value: '426',
    value_old: 'ISO 3166-2:LS',
    region: 'Africa',
    subRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '018',
  },
  {
    label: 'Liberia',
    alpha2: 'LR',
    alpha3: 'LBR',
    value: '430',
    value_old: 'ISO 3166-2:LR',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Libya',
    alpha2: 'LY',
    alpha3: 'LBY',
    value: '434',
    value_old: 'ISO 3166-2:LY',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'Liechtenstein',
    alpha2: 'LI',
    alpha3: 'LIE',
    value: '438',
    value_old: 'ISO 3166-2:LI',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Lithuania',
    alpha2: 'LT',
    alpha3: 'LTU',
    value: '440',
    value_old: 'ISO 3166-2:LT',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Luxembourg',
    alpha2: 'LU',
    alpha3: 'LUX',
    value: '442',
    value_old: 'ISO 3166-2:LU',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Macao',
    alpha2: 'MO',
    alpha3: 'MAC',
    value: '446',
    value_old: 'ISO 3166-2:MO',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Macedonia (the former Yugoslav Republic of)',
    alpha2: 'MK',
    alpha3: 'MKD',
    value: '807',
    value_old: 'ISO 3166-2:MK',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Madagascar',
    alpha2: 'MG',
    alpha3: 'MDG',
    value: '450',
    value_old: 'ISO 3166-2:MG',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Malawi',
    alpha2: 'MW',
    alpha3: 'MWI',
    value: '454',
    value_old: 'ISO 3166-2:MW',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Malaysia',
    alpha2: 'MY',
    alpha3: 'MYS',
    value: '458',
    value_old: 'ISO 3166-2:MY',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Maldives',
    alpha2: 'MV',
    alpha3: 'MDV',
    value: '462',
    value_old: 'ISO 3166-2:MV',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Mali',
    alpha2: 'ML',
    alpha3: 'MLI',
    value: '466',
    value_old: 'ISO 3166-2:ML',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Malta',
    alpha2: 'MT',
    alpha3: 'MLT',
    value: '470',
    value_old: 'ISO 3166-2:MT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Marshall Islands',
    alpha2: 'MH',
    alpha3: 'MHL',
    value: '584',
    value_old: 'ISO 3166-2:MH',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Martinique',
    alpha2: 'MQ',
    alpha3: 'MTQ',
    value: '474',
    value_old: 'ISO 3166-2:MQ',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Mauritania',
    alpha2: 'MR',
    alpha3: 'MRT',
    value: '478',
    value_old: 'ISO 3166-2:MR',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Mauritius',
    alpha2: 'MU',
    alpha3: 'MUS',
    value: '480',
    value_old: 'ISO 3166-2:MU',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Mayotte',
    alpha2: 'YT',
    alpha3: 'MYT',
    value: '175',
    value_old: 'ISO 3166-2:YT',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Mexico',
    alpha2: 'MX',
    alpha3: 'MEX',
    value: '484',
    value_old: 'ISO 3166-2:MX',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Micronesia (Federated States of)',
    alpha2: 'FM',
    alpha3: 'FSM',
    value: '583',
    value_old: 'ISO 3166-2:FM',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Moldova (Republic of)',
    alpha2: 'MD',
    alpha3: 'MDA',
    value: '498',
    value_old: 'ISO 3166-2:MD',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Monaco',
    alpha2: 'MC',
    alpha3: 'MCO',
    value: '492',
    value_old: 'ISO 3166-2:MC',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Mongolia',
    alpha2: 'MN',
    alpha3: 'MNG',
    value: '496',
    value_old: 'ISO 3166-2:MN',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Montenegro',
    alpha2: 'ME',
    alpha3: 'MNE',
    value: '499',
    value_old: 'ISO 3166-2:ME',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Montserrat',
    alpha2: 'MS',
    alpha3: 'MSR',
    value: '500',
    value_old: 'ISO 3166-2:MS',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Morocco',
    alpha2: 'MA',
    alpha3: 'MAR',
    value: '504',
    value_old: 'ISO 3166-2:MA',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'Mozambique',
    alpha2: 'MZ',
    alpha3: 'MOZ',
    value: '508',
    value_old: 'ISO 3166-2:MZ',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Myanmar',
    alpha2: 'MM',
    alpha3: 'MMR',
    value: '104',
    value_old: 'ISO 3166-2:MM',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Namibia',
    alpha2: 'NA',
    alpha3: 'NAM',
    value: '516',
    value_old: 'ISO 3166-2:NA',
    region: 'Africa',
    subRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '018',
  },
  {
    label: 'Nauru',
    alpha2: 'NR',
    alpha3: 'NRU',
    value: '520',
    value_old: 'ISO 3166-2:NR',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Nepal',
    alpha2: 'NP',
    alpha3: 'NPL',
    value: '524',
    value_old: 'ISO 3166-2:NP',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Netherlands',
    alpha2: 'NL',
    alpha3: 'NLD',
    value: '528',
    value_old: 'ISO 3166-2:NL',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'New Caledonia',
    alpha2: 'NC',
    alpha3: 'NCL',
    value: '540',
    value_old: 'ISO 3166-2:NC',
    region: 'Oceania',
    subRegion: 'Melanesia',
    regionCode: '009',
    subRegionCode: '054',
  },
  {
    label: 'New Zealand',
    alpha2: 'NZ',
    alpha3: 'NZL',
    value: '554',
    value_old: 'ISO 3166-2:NZ',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    regionCode: '009',
    subRegionCode: '053',
  },
  {
    label: 'Nicaragua',
    alpha2: 'NI',
    alpha3: 'NIC',
    value: '558',
    value_old: 'ISO 3166-2:NI',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Niger',
    alpha2: 'NE',
    alpha3: 'NER',
    value: '562',
    value_old: 'ISO 3166-2:NE',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Nigeria',
    alpha2: 'NG',
    alpha3: 'NGA',
    value: '566',
    value_old: 'ISO 3166-2:NG',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Niue',
    alpha2: 'NU',
    alpha3: 'NIU',
    value: '570',
    value_old: 'ISO 3166-2:NU',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Norfolk Island',
    alpha2: 'NF',
    alpha3: 'NFK',
    value: '574',
    value_old: 'ISO 3166-2:NF',
    region: 'Oceania',
    subRegion: 'Australia and New Zealand',
    regionCode: '009',
    subRegionCode: '053',
  },
  {
    label: 'Northern Mariana Islands',
    alpha2: 'MP',
    alpha3: 'MNP',
    value: '580',
    value_old: 'ISO 3166-2:MP',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Norway',
    alpha2: 'NO',
    alpha3: 'NOR',
    value: '578',
    value_old: 'ISO 3166-2:NO',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Oman',
    alpha2: 'OM',
    alpha3: 'OMN',
    value: '512',
    value_old: 'ISO 3166-2:OM',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Pakistan',
    alpha2: 'PK',
    alpha3: 'PAK',
    value: '586',
    value_old: 'ISO 3166-2:PK',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Palau',
    alpha2: 'PW',
    alpha3: 'PLW',
    value: '585',
    value_old: 'ISO 3166-2:PW',
    region: 'Oceania',
    subRegion: 'Micronesia',
    regionCode: '009',
    subRegionCode: '057',
  },
  {
    label: 'Palestine, State of',
    alpha2: 'PS',
    alpha3: 'PSE',
    value: '275',
    value_old: 'ISO 3166-2:PS',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Panama',
    alpha2: 'PA',
    alpha3: 'PAN',
    value: '591',
    value_old: 'ISO 3166-2:PA',
    region: 'Americas',
    subRegion: 'Central America',
    regionCode: '019',
    subRegionCode: '013',
  },
  {
    label: 'Papua New Guinea',
    alpha2: 'PG',
    alpha3: 'PNG',
    value: '598',
    value_old: 'ISO 3166-2:PG',
    region: 'Oceania',
    subRegion: 'Melanesia',
    regionCode: '009',
    subRegionCode: '054',
  },
  {
    label: 'Paraguay',
    alpha2: 'PY',
    alpha3: 'PRY',
    value: '600',
    value_old: 'ISO 3166-2:PY',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Peru',
    alpha2: 'PE',
    alpha3: 'PER',
    value: '604',
    value_old: 'ISO 3166-2:PE',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Philippines',
    alpha2: 'PH',
    alpha3: 'PHL',
    value: '608',
    value_old: 'ISO 3166-2:PH',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Pitcairn',
    alpha2: 'PN',
    alpha3: 'PCN',
    value: '612',
    value_old: 'ISO 3166-2:PN',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Poland',
    alpha2: 'PL',
    alpha3: 'POL',
    value: '616',
    value_old: 'ISO 3166-2:PL',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Portugal',
    alpha2: 'PT',
    alpha3: 'PRT',
    value: '620',
    value_old: 'ISO 3166-2:PT',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Puerto Rico',
    alpha2: 'PR',
    alpha3: 'PRI',
    value: '630',
    value_old: 'ISO 3166-2:PR',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Qatar',
    alpha2: 'QA',
    alpha3: 'QAT',
    value: '634',
    value_old: 'ISO 3166-2:QA',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Réunion',
    alpha2: 'RE',
    alpha3: 'REU',
    value: '638',
    value_old: 'ISO 3166-2:RE',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Romania',
    alpha2: 'RO',
    alpha3: 'ROU',
    value: '642',
    value_old: 'ISO 3166-2:RO',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Russian Federation',
    alpha2: 'RU',
    alpha3: 'RUS',
    value: '643',
    value_old: 'ISO 3166-2:RU',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Rwanda',
    alpha2: 'RW',
    alpha3: 'RWA',
    value: '646',
    value_old: 'ISO 3166-2:RW',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Saint Barthélemy',
    alpha2: 'BL',
    alpha3: 'BLM',
    value: '652',
    value_old: 'ISO 3166-2:BL',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    alpha2: 'SH',
    alpha3: 'SHN',
    value: '654',
    value_old: 'ISO 3166-2:SH',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Saint Kitts and Nevis',
    alpha2: 'KN',
    alpha3: 'KNA',
    value: '659',
    value_old: 'ISO 3166-2:KN',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Saint Lucia',
    alpha2: 'LC',
    alpha3: 'LCA',
    value: '662',
    value_old: 'ISO 3166-2:LC',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Saint Martin (French part)',
    alpha2: 'MF',
    alpha3: 'MAF',
    value: '663',
    value_old: 'ISO 3166-2:MF',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Saint Pierre and Miquelon',
    alpha2: 'PM',
    alpha3: 'SPM',
    value: '666',
    value_old: 'ISO 3166-2:PM',
    region: 'Americas',
    subRegion: 'Northern America',
    regionCode: '019',
    subRegionCode: '021',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    alpha2: 'VC',
    alpha3: 'VCT',
    value: '670',
    value_old: 'ISO 3166-2:VC',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Samoa',
    alpha2: 'WS',
    alpha3: 'WSM',
    value: '882',
    value_old: 'ISO 3166-2:WS',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'San Marino',
    alpha2: 'SM',
    alpha3: 'SMR',
    value: '674',
    value_old: 'ISO 3166-2:SM',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Sao Tome and Principe',
    alpha2: 'ST',
    alpha3: 'STP',
    value: '678',
    value_old: 'ISO 3166-2:ST',
    region: 'Africa',
    subRegion: 'Middle Africa',
    regionCode: '002',
    subRegionCode: '017',
  },
  {
    label: 'Saudi Arabia',
    alpha2: 'SA',
    alpha3: 'SAU',
    value: '682',
    value_old: 'ISO 3166-2:SA',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Senegal',
    alpha2: 'SN',
    alpha3: 'SEN',
    value: '686',
    value_old: 'ISO 3166-2:SN',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Serbia',
    alpha2: 'RS',
    alpha3: 'SRB',
    value: '688',
    value_old: 'ISO 3166-2:RS',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Seychelles',
    alpha2: 'SC',
    alpha3: 'SYC',
    value: '690',
    value_old: 'ISO 3166-2:SC',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Sierra Leone',
    alpha2: 'SL',
    alpha3: 'SLE',
    value: '694',
    value_old: 'ISO 3166-2:SL',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Singapore',
    alpha2: 'SG',
    alpha3: 'SGP',
    value: '702',
    value_old: 'ISO 3166-2:SG',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    alpha2: 'SX',
    alpha3: 'SXM',
    value: '534',
    value_old: 'ISO 3166-2:SX',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Slovakia',
    alpha2: 'SK',
    alpha3: 'SVK',
    value: '703',
    value_old: 'ISO 3166-2:SK',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'Slovenia',
    alpha2: 'SI',
    alpha3: 'SVN',
    value: '705',
    value_old: 'ISO 3166-2:SI',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Solomon Islands',
    alpha2: 'SB',
    alpha3: 'SLB',
    value: '090',
    value_old: 'ISO 3166-2:SB',
    region: 'Oceania',
    subRegion: 'Melanesia',
    regionCode: '009',
    subRegionCode: '054',
  },
  {
    label: 'Somalia',
    alpha2: 'SO',
    alpha3: 'SOM',
    value: '706',
    value_old: 'ISO 3166-2:SO',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'South Africa',
    alpha2: 'ZA',
    alpha3: 'ZAF',
    value: '710',
    value_old: 'ISO 3166-2:ZA',
    region: 'Africa',
    subRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '018',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    alpha2: 'GS',
    alpha3: 'SGS',
    value: '239',
    value_old: 'ISO 3166-2:GS',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'South Sudan',
    alpha2: 'SS',
    alpha3: 'SSD',
    value: '728',
    value_old: 'ISO 3166-2:SS',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Spain',
    alpha2: 'ES',
    alpha3: 'ESP',
    value: '724',
    value_old: 'ISO 3166-2:ES',
    region: 'Europe',
    subRegion: 'Southern Europe',
    regionCode: '150',
    subRegionCode: '039',
  },
  {
    label: 'Sri Lanka',
    alpha2: 'LK',
    alpha3: 'LKA',
    value: '144',
    value_old: 'ISO 3166-2:LK',
    region: 'Asia',
    subRegion: 'Southern Asia',
    regionCode: '142',
    subRegionCode: '034',
  },
  {
    label: 'Sudan',
    alpha2: 'SD',
    alpha3: 'SDN',
    value: '729',
    value_old: 'ISO 3166-2:SD',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'Surilabel',
    alpha2: 'SR',
    alpha3: 'SUR',
    value: '740',
    value_old: 'ISO 3166-2:SR',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Svalbard and Jan Mayen',
    alpha2: 'SJ',
    alpha3: 'SJM',
    value: '744',
    value_old: 'ISO 3166-2:SJ',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Swaziland',
    alpha2: 'SZ',
    alpha3: 'SWZ',
    value: '748',
    value_old: 'ISO 3166-2:SZ',
    region: 'Africa',
    subRegion: 'Southern Africa',
    regionCode: '002',
    subRegionCode: '018',
  },
  {
    label: 'Sweden',
    alpha2: 'SE',
    alpha3: 'SWE',
    value: '752',
    value_old: 'ISO 3166-2:SE',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'Switzerland',
    alpha2: 'CH',
    alpha3: 'CHE',
    value: '756',
    value_old: 'ISO 3166-2:CH',
    region: 'Europe',
    subRegion: 'Western Europe',
    regionCode: '150',
    subRegionCode: '155',
  },
  {
    label: 'Syrian Arab Republic',
    alpha2: 'SY',
    alpha3: 'SYR',
    value: '760',
    value_old: 'ISO 3166-2:SY',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Taiwan, Province of China',
    alpha2: 'TW',
    alpha3: 'TWN',
    value: '158',
    value_old: 'ISO 3166-2:TW',
    region: 'Asia',
    subRegion: 'Eastern Asia',
    regionCode: '142',
    subRegionCode: '030',
  },
  {
    label: 'Tajikistan',
    alpha2: 'TJ',
    alpha3: 'TJK',
    value: '762',
    value_old: 'ISO 3166-2:TJ',
    region: 'Asia',
    subRegion: 'Central Asia',
    regionCode: '142',
    subRegionCode: '143',
  },
  {
    label: 'Tanzania, United Republic of',
    alpha2: 'TZ',
    alpha3: 'TZA',
    value: '834',
    value_old: 'ISO 3166-2:TZ',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Thailand',
    alpha2: 'TH',
    alpha3: 'THA',
    value: '764',
    value_old: 'ISO 3166-2:TH',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Timor-Leste',
    alpha2: 'TL',
    alpha3: 'TLS',
    value: '626',
    value_old: 'ISO 3166-2:TL',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Togo',
    alpha2: 'TG',
    alpha3: 'TGO',
    value: '768',
    value_old: 'ISO 3166-2:TG',
    region: 'Africa',
    subRegion: 'Western Africa',
    regionCode: '002',
    subRegionCode: '011',
  },
  {
    label: 'Tokelau',
    alpha2: 'TK',
    alpha3: 'TKL',
    value: '772',
    value_old: 'ISO 3166-2:TK',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Tonga',
    alpha2: 'TO',
    alpha3: 'TON',
    value: '776',
    value_old: 'ISO 3166-2:TO',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Trinidad and Tobago',
    alpha2: 'TT',
    alpha3: 'TTO',
    value: '780',
    value_old: 'ISO 3166-2:TT',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Tunisia',
    alpha2: 'TN',
    alpha3: 'TUN',
    value: '788',
    value_old: 'ISO 3166-2:TN',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'Turkey',
    alpha2: 'TR',
    alpha3: 'TUR',
    value: '792',
    value_old: 'ISO 3166-2:TR',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Turkmenistan',
    alpha2: 'TM',
    alpha3: 'TKM',
    value: '795',
    value_old: 'ISO 3166-2:TM',
    region: 'Asia',
    subRegion: 'Central Asia',
    regionCode: '142',
    subRegionCode: '143',
  },
  {
    label: 'Turks and Caicos Islands',
    alpha2: 'TC',
    alpha3: 'TCA',
    value: '796',
    value_old: 'ISO 3166-2:TC',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Tuvalu',
    alpha2: 'TV',
    alpha3: 'TUV',
    value: '798',
    value_old: 'ISO 3166-2:TV',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Uganda',
    alpha2: 'UG',
    alpha3: 'UGA',
    value: '800',
    value_old: 'ISO 3166-2:UG',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Ukraine',
    alpha2: 'UA',
    alpha3: 'UKR',
    value: '804',
    value_old: 'ISO 3166-2:UA',
    region: 'Europe',
    subRegion: 'Eastern Europe',
    regionCode: '150',
    subRegionCode: '151',
  },
  {
    label: 'United Arab Emirates',
    alpha2: 'AE',
    alpha3: 'ARE',
    value: '784',
    value_old: 'ISO 3166-2:AE',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    alpha2: 'GB',
    alpha3: 'GBR',
    value: '826',
    value_old: 'ISO 3166-2:GB',
    region: 'Europe',
    subRegion: 'Northern Europe',
    regionCode: '150',
    subRegionCode: '154',
  },
  {
    label: 'United States of America',
    alpha2: 'US',
    alpha3: 'USA',
    value: '840',
    value_old: 'ISO 3166-2:US',
    region: 'Americas',
    subRegion: 'Northern America',
    regionCode: '019',
    subRegionCode: '021',
  },
  {
    label: 'United States Minor Outlying Islands',
    alpha2: 'UM',
    alpha3: 'UMI',
    value: '581',
    value_old: 'ISO 3166-2:UM',
    subRegionCode: null,
    regionCode: null,
    subRegion: null,
    region: null,
  },
  {
    label: 'Uruguay',
    alpha2: 'UY',
    alpha3: 'URY',
    value: '858',
    value_old: 'ISO 3166-2:UY',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Uzbekistan',
    alpha2: 'UZ',
    alpha3: 'UZB',
    value: '860',
    value_old: 'ISO 3166-2:UZ',
    region: 'Asia',
    subRegion: 'Central Asia',
    regionCode: '142',
    subRegionCode: '143',
  },
  {
    label: 'Vanuatu',
    alpha2: 'VU',
    alpha3: 'VUT',
    value: '548',
    value_old: 'ISO 3166-2:VU',
    region: 'Oceania',
    subRegion: 'Melanesia',
    regionCode: '009',
    subRegionCode: '054',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    alpha2: 'VE',
    alpha3: 'VEN',
    value: '862',
    value_old: 'ISO 3166-2:VE',
    region: 'Americas',
    subRegion: 'South America',
    regionCode: '019',
    subRegionCode: '005',
  },
  {
    label: 'Viet Nam',
    alpha2: 'VN',
    alpha3: 'VNM',
    value: '704',
    value_old: 'ISO 3166-2:VN',
    region: 'Asia',
    subRegion: 'South-Eastern Asia',
    regionCode: '142',
    subRegionCode: '035',
  },
  {
    label: 'Virgin Islands (British)',
    alpha2: 'VG',
    alpha3: 'VGB',
    value: '092',
    value_old: 'ISO 3166-2:VG',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Virgin Islands (U.S.)',
    alpha2: 'VI',
    alpha3: 'VIR',
    value: '850',
    value_old: 'ISO 3166-2:VI',
    region: 'Americas',
    subRegion: 'Caribbean',
    regionCode: '019',
    subRegionCode: '029',
  },
  {
    label: 'Wallis and Futuna',
    alpha2: 'WF',
    alpha3: 'WLF',
    value: '876',
    value_old: 'ISO 3166-2:WF',
    region: 'Oceania',
    subRegion: 'Polynesia',
    regionCode: '009',
    subRegionCode: '061',
  },
  {
    label: 'Western Sahara',
    alpha2: 'EH',
    alpha3: 'ESH',
    value: '732',
    value_old: 'ISO 3166-2:EH',
    region: 'Africa',
    subRegion: 'Northern Africa',
    regionCode: '002',
    subRegionCode: '015',
  },
  {
    label: 'Yemen',
    alpha2: 'YE',
    alpha3: 'YEM',
    value: '887',
    value_old: 'ISO 3166-2:YE',
    region: 'Asia',
    subRegion: 'Western Asia',
    regionCode: '142',
    subRegionCode: '145',
  },
  {
    label: 'Zambia',
    alpha2: 'ZM',
    alpha3: 'ZMB',
    value: '894',
    value_old: 'ISO 3166-2:ZM',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
  {
    label: 'Zimbabwe',
    alpha2: 'ZW',
    alpha3: 'ZWE',
    value: '716',
    value_old: 'ISO 3166-2:ZW',
    region: 'Africa',
    subRegion: 'Eastern Africa',
    regionCode: '002',
    subRegionCode: '014',
  },
]
