import React from 'react'
import styled from 'styled-components'
import { TableOptions, useTable } from 'react-table'
import { gapSizes } from '@src/styles/theme'

const StyledTable = styled.table<any>`
  width: 100%;
  border-collapse: collapse;
  margin-top: ${gapSizes.M};
  thead {
    text-transform: uppercase;
  }
  tr {
    height: 50px;
    border-radius: ${props => props.theme.radius};
    td:first-child {
      border-left: 1px solid transparent;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-right: 1px solid transparent;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }
    td, th {
      padding: 0 ${gapSizes.S};
      text-align: start;
      &:last-child {
        text-align: end;
      }
    }
  }
  tbody tr {
    height: 50px;
    &:nth-child(odd) {
      background-color: ${props => props.theme.oddRow};
    }
  }
`

// For use inside Panels
export function PanelTable({ columns, data }: TableOptions<any>) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  })

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, i) => {
              return <th key={i} {...column.getHeaderProps()}>{column.render('Header')}</th>
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows &&
          rows.map((row, i) => {
            prepareRow(row)
            return (
              <React.Fragment key={i}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              </React.Fragment>
            )
          })}
      </tbody>
    </StyledTable>
  )
}
