import { OrganizationUrlProps, PaymentProfilesResponse, GetSubscriptionsResponse, TransactionsResponse, EntityProps } from '@logic'
import { newGetFunction } from '@src/service'
import { paymentProfilesUrl, subscriptionsUrl, billingHistoryUrl, pricePointsUrl } from './urls'

export const getPaymentProfiles = newGetFunction<EntityProps, PaymentProfilesResponse>(paymentProfilesUrl)

export const getSubscriptions = newGetFunction<OrganizationUrlProps, GetSubscriptionsResponse>(subscriptionsUrl)

export const getBillingHistory = newGetFunction<OrganizationUrlProps, TransactionsResponse>(billingHistoryUrl)

export const getPricePoints = newGetFunction<OrganizationUrlProps, TransactionsResponse>(pricePointsUrl)
