import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { editPlanIncentivePath, organizationViewLegendPath, organizationViewSecurityPath, PageProps, planIncentivesPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission, getIncentiveTypes } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { FlexRow } from '@src/styles'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { BaseLinkProps, overflowParams } from '@shared/components/navigation'
import { InfoPanelProps, ViewInfoPanel } from '../components/view-info-panel'
import { formatCurrency, formatSecurityName, loadAuthorizations, loadLegends, loadSecurities } from '@shared/helpers'
import { deleteSecurity, GetAuthorizationsResponse, GetLegendsResponse, GetSecuritiesResponse } from '@src/service'
import { basicDeleteModal } from '@shared/components/modal'

type Props = PageProps & GetSecuritiesResponse & GetLegendsResponse & GetAuthorizationsResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities(), loadLegends(true), loadAuthorizations('incentive'))

export const ViewIncentivePage = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const { params, navigate, securities, legends, location, httpClient, authorizations } = props
        const { organization, plan, incentive } = params
        const currentIncentive = securities?.find((s: any) => s.parent && incentive === s.hash) as any
        const approvedEquity = securities?.find(s => s.hash === currentIncentive.parent)
        const legend = legends?.find(s => s.hash === currentIncentive.legend)

        const documentsAndNotes = authorizations && authorizations.length > 0 ?
            authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
        const documents = documentsAndNotes?.map((auth, index: number) => {
            return {
                label: `Document ${index + 1}`,
                value: auth.documentTypeName! || auth.document?.filename || auth.filename,
                document: auth.document?.hash! || auth.hash,
                filename: auth.document?.filename || auth.filename
            }
        })

        const handleDeleteIncentive = async () => {
            await deleteSecurity(plan)(httpClient)({ organization, security: incentive })
            deleteModal.setVisible(false)
            navigate(planIncentivesPath, { organization, plan })
        }
        const incentiveName = currentIncentive ? `${currentIncentive?.name} Incentive` : ''
        const deleteModal = basicDeleteModal(
            handleDeleteIncentive,
            [incentiveName],
        )
        const dropOptions = [
            ['Edit', () => navigate(overflowParams(editPlanIncentivePath, { organization, plan: params.plan, incentive, parent: location?.pathname! }))],
            ['Delete', () => deleteModal.setVisible(true)],
        ]

        const data: InfoPanelProps[] = [
            {
                label: 'Type',
                value: getIncentiveTypes[currentIncentive?.type]
            },
            {
                label: 'Abbreviation',
                value: currentIncentive.shareClassPrefix
            },
            {
                label: 'Approved Equity',
                value: approvedEquity ?
                    `${formatSecurityName(approvedEquity)} ${approvedEquity?.shareClassPrefix ? `(${approvedEquity?.shareClassPrefix})` : ''}` : '',
                link: organizationViewSecurityPath,
                args: { organization, security: approvedEquity?.hash }
            },
            {
                label: 'Strike Price',
                value: currentIncentive.price ? formatCurrency(currentIncentive.price) : 'N/A'
            },
            {
                label: 'Legend',
                value: legend?.name || 'N/A',
                link: organizationViewLegendPath,
                args: { organization, legend: legend?.hash }
            },
            ...documents
        ]

        const parent: BaseLinkProps = { path: planIncentivesPath, args: { organization, plan } }

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    <PageContentHeader title={currentIncentive.name} parent={parent} direction>
                        <FlexRow style={{ alignItems: 'baseline' }}>
                            <ActionsDrop options={dropOptions} />
                        </FlexRow>
                    </PageContentHeader>
                    <ViewInfoPanel title='Incentive Details' data={data} navigate={navigate} params={params} />
                </PageContent>
            </>
        )
    })
)
