import * as React from 'react'
import { Paths } from '@logic'
import { FieldGroup, protectedInputFieldComponent, renderFieldsSimple, textInputFieldComponent, } from '@shared/components/forms'
import { isEmail, isPassword } from '@shared/helpers'
import styled from 'styled-components'
import { FlexCol } from '@src/styles'
import { Button, LoginSignButton } from '@shared/components/button'
import { Link } from 'react-router-dom'
import { LogoHeading } from './logo-heading'

interface Props {
    invalid?: boolean
    values?: any
}

const LoginFormWrapper = styled.div<any>`
.LoginText{
    margin: 32px 0px 24px 0px;
    font-size: 34px;
    line-height: 46px;
    font-weight: 600;
}
`

function loginFields(): FieldGroup<any> {
    return [
        {
            name: 'usernameOrEmail',
            label: 'Email Address',
            required: true,
            asterisks: false,
            placeholder: 'example@gmail.com',
            validate: isEmail,
            component: textInputFieldComponent
        },
        {
            name: 'password',
            label: 'Password',
            required: true,
            asterisks:false,
            placeholder: '● ● ● ● ● ● ● ● ',
            validate: isPassword,
            component: protectedInputFieldComponent({}, 'text', true)
        },
    ]
}

export const LoginForm = (props: Props) => {
    const { values, invalid } = props
    return (
        <LoginFormWrapper>
            <LogoHeading/>
            <div className='LoginText'>Log In</div>
            {renderFieldsSimple(loginFields(), true)}
            <FlexCol alignItems={'flex-start'}>
            <Link to={Paths.userForgot}>Forgot your password?</Link>
                <LoginSignButton type="submit" disabled={!values.usernameOrEmail || !values.password || invalid}>
                    LOG IN
                  </LoginSignButton>
            </FlexCol>
        </LoginFormWrapper>
    )
}
