import { BaseLinkProps } from '@components/navigation/navigation'
import styled from 'styled-components'
import { Header2 } from '@src/styles'
import { PathLink } from '@components/navigation/path-link'
import * as React from 'react'

interface Props extends BaseLinkProps {
  title: string
  disabled?: boolean
  button?: React.ReactComponentElement<any>
}

export const PanelSummaryHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const PanelSummaryHeader = (props: Props) => {
  const { args, disabled, path, title, button } = props
  return (
    <PanelSummaryHeaderContainer>
      <Header2>
        <PathLink disabled={!path || disabled} path={path} args={args}>
          {title}
        </PathLink>
      </Header2>
      {button && button}
    </PanelSummaryHeaderContainer>
  )
}
