import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, planPath, poolPath, poolsPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { PoolForm } from '../components/pool-form'
import {
  FormPool,
  GetSecuritiesResponse,
  ExpandedHoldingResponse,
  editHolding,
  EditHoldingRequest,
  GetAuthorizationsResponse,
  createAuthorization,
  postFile,
  FilesResponse,
  NewAuthorizationRequest,
  GetPlanResponse
} from '@src/service'
import { loadAuthorizations, loadHolding, loadPlan, loadSecurities } from '@helpers/loaders'
import { BaseLinkProps } from '@shared/components/navigation'
import { PoolTreasuryCalculator } from '../components/pool-treasury-calculator'
import { getQueryParams } from '@shared/helpers'
import { filterEquities, filterIncentives } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & GetSecuritiesResponse & ExpandedHoldingResponse & GetAuthorizationsResponse & GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities('true'), loadHolding, loadAuthorizations('pool'), loadPlan)

export const EditPool = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, holding, authorizations, stats, plan } = props
    const { organization, pool } = params
    const securitiesWithDeleted = filterEquities((stats || []), securities, true)
    const hasDeletedIncentive = securitiesWithDeleted?.filter(d => d.deleted).find(s =>
      s.hash === holding.parent || s.id === holding.parent) ? true : false

    const securitiesList = filterEquities((stats || []), securities)
    const incentivesList = filterIncentives(hasDeletedIncentive ? securitiesWithDeleted : securitiesList, plan)
    const currentPool = holding
    const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a =>
      a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })

    const { name, value } = currentPool
    const initialValues: Partial<FormPool> = {
      name,
      incentive: currentPool?.parent!,
      shares: value!,
      approvalDocument: documents,
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id,
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []

      const request = {
        name: values?.name,
        parent: values?.incentive,
        value: values?.shares,
        entity: organization,
        owner: organization,
        plan: holding?.plan,
      } as EditHoldingRequest

      const response = await editHolding(httpClient)({ holding: pool }, request)
      if (response?.hash) {
        modifyAuthorizations({ approvalDocuments, target: response.hash!, httpClient, organization })
        const parent = getQueryParams().parent
        if (parent) {
          navigate(poolPath, { organization, plan: params.plan, pool: response.hash })
        } else {
          navigate(poolsPath, { organization, plan: params.plan })
        }
      }
      else console.log('edit pool error') // TODO: add error message toast
    }

    const TreasuryCalculator: Sticky<any> = props => {
      const { values } = props
      const selectedIncentive = incentivesList?.find(i => i.hash === values?.incentive)
      const equityTreasury = securitiesList?.find(i => i.hash === selectedIncentive?.parent)?.stats.treasury
      return <PoolTreasuryCalculator
        current={equityTreasury || 0}
        allocated={values?.shares || 0}
      />
    }
    const parent: BaseLinkProps = { path: planPath, args: { organization, plan: params?.plan } }

    return (
      <PageContent>
        <PageContentHeader title={`Edit ${name}`} parent={parent} withSticky={true} />
        <CommonForm
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          sticky={TreasuryCalculator}
          submitText="Save"
        >
          <PoolForm
            securities={hasDeletedIncentive ? securitiesWithDeleted : securitiesList}
            onUpload={onUpload}
            plan={plan} />
        </CommonForm>
      </PageContent>
    )
  })
)
