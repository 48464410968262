import { FormButtonLayout } from '@components/styledForm'
import { SkipLinkButton, SubmitButton } from '@components/button'
import * as React from 'react'

interface Props {
  disabled?: boolean
  submitting?: boolean
  submitText?: string
  skipText?: string
  skip?: any
}

export const CommonSubmissionFooter = ({ disabled, submitText, skipText, skip, submitting }: Props) => (
  <FormButtonLayout>
    {skipText && <SkipLinkButton path={skip?.path!} args={skip?.args!}>
      {skipText}
    </SkipLinkButton>}
    {submitText && <SubmitButton
      type="submit"
      disabled={disabled}
      submitting={submitting}
      name='submit'
    >
      {submitText}
    </SubmitButton>}
  </FormButtonLayout>
)
