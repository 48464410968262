import * as React from 'react'
import { newBasicModalBundle } from "../basic-modal"
import { Button, ErrorButton,WarningButton } from '../button'
import Bin from '@image/bin.svg'
import styled from 'styled-components'
import { colors, FlexCol, FlexRow } from '@src/styles'
import { rgba } from 'polished'
import Warning from '@image/warning.svg'
const TitleWrap = styled<any>(FlexRow)`
  align-items: center;
  svg {
      margin-right: 16px;
      path {
          fill:${props => props.theme.warning};
      }
  }
`

const Wrap = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  overflow-y: auto;
  background: ${props => props.theme.modalBack};
  align-items: flex-start;
  div {
      width: 100%;
      @media only screen and (max-width: 500px) {
    width: max-content;
  }
  }
  .body {
    margin: 0;
    padding-inline-start: 22px;
    line-height: 24px;
  }
  .info {
    padding: 8px 12px;
    border: 1px solid #CEDAE1;
    background-color: ${({ theme }) => theme.input.default};
    border-radius: 4px;
  }
  .warning {
    padding: 8px 12px;
    border: 1px solid ${colors.errorRed};
    background-color: ${rgba(colors.errorRed, 0.4)};
    border-radius: 4px;
    margin-top: 24px;
    line-height: 24px;
  }
  .value {
      padding: 10px;
  }
  span {
      width: 100%;
      display: inline-block;
      line-height: 24px;
      text-align: left;
      margin-top: 24px;
  }
`

export function basicDeleteModal(onActivate: () => void, data: string[], warningText?: string, title?: string, desc?: string) {
    return newBasicModalBundle({
        title: <TitleWrap><Warning/>{title || 'Are you sure?'}</TitleWrap>,
        desc: desc || 'You are about to delete the following item(s):',
        body: <Wrap>
            <div className='info'>
                <ul className='body'>
                    {data.map(d => (
                        <div className='value'>
                            <li>{d}</li>
                        </div>))}
                </ul>
            </div>
            {warningText ? <div className='warning'>{warningText}</div> : null}
            <span>This action cannot be undone.</span>
        </Wrap>,
        buttons: [
            {
                text: 'Confirm',
                onActivate,
                componentType: WarningButton,
            },
        ],
        max: '650px'
    })
}