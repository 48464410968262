import {
    captablePath,
    Paths,
    organizationDetailsPath,
    actionsPath,
    organizationSettingsPath,
    capitalizationPath,
    organizationPath,
    organizationMembersPath,
    convertibleInstrumentsPath,
    organizationSecuritiesPath,
    plansPath,
    organizationLegendsPath,
    rolesPath,
    billingPath,
    logoutPath,
    organizationReferralsPath,
    organizationCashTransferPath,
    organizationPortfolioPath,
    reportsPath
} from '@logic'
import { HeaderLinkProps, HeaderLinks } from '@src/app/components/header/header-links'
import React from 'react'
import { hasPermission, Permission } from '@components/permissions'
import { BuiltInPermission } from '@helpers/constants'
import { HamburgerMenu } from '../hamburger'

interface Props {
    permissions: Permission[]
    mobileView: boolean
    navigate: any
}

export const OrganizationLinks = (props: Props) => {
    const { permissions, mobileView, navigate } = props
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)
    const canViewAndEditOrg = hasPermission(permissions)(BuiltInPermission.editOrganizationDetails)

    const captableLinks = [
        {
            path: captablePath,
            title: 'Cap Table',
            exact: true,
        },
        {
            path: reportsPath,
            title: 'Reports',
        },
        {
            path: organizationSecuritiesPath,
            title: 'Equities',
        },
        {
            path: convertibleInstrumentsPath,
            title: 'Convertible Instruments',
        },
        {
            path: plansPath,
            title: 'Incentive Plans',
        },
        {
            path: organizationLegendsPath,
            title: 'Legends',
        },
    ]
    const organizationSettingsLinks = [
        {
            path: organizationDetailsPath,
            title: 'Details',
        },
        {
            path: rolesPath,
            title: 'Roles & Permissions',
        },
        {
            path: organizationReferralsPath,
            title: 'Referrals',
        },
        {
            path: organizationCashTransferPath,
            title: 'E$ Transfer',
        },
        {
            path: billingPath,
            title: 'Billing',
        },
    ]

    const links: HeaderLinkProps[] = [
        {
            path: organizationPath,
            title: 'Dashboard',
            exact: true,
        },
        {
            path: organizationPortfolioPath,
            title: 'Portfolio',
        },
        {
            path: organizationMembersPath,
            title: 'Team Members',
            description: 'Build, view, and organize your team through our fully integrated and intuitive team management interface.',
        },
        {
            path: capitalizationPath,
            defaultUrl: captablePath,
            title: 'Capitalization',
            description: 'Fortify your business assets using the cap table management tool. Enjoy superior equity and asset management.',
            subLinks: captableLinks
        },
        {
            path: Paths.governingDocuments,
            title: 'Agreements',
        },
        {
            path: Paths.organizationDataRoom,
            title: 'Data Room',
            description:
                'Facilitate your operational and legal agreements using our software as a central source of truth. Never lose sleep over possible document errors.',
        },
        {
            path: actionsPath,
            title: 'Actions',
            description:
                'Interact with your organization using our communication system. Don\'t fret about getting bogged down with multiple chat platforms.',
        },
        {
            path: organizationSettingsPath,
            title: 'More',
            defaultUrl: organizationDetailsPath,
            subLinks: organizationSettingsLinks,
            disabledPath: !canViewAndEditOrg
        },
    ]
    return !mobileView ? <HeaderLinks links={links} />
        :
        <HamburgerMenu noPath={() => navigate(logoutPath)} setOpen={setMobileMenuOpen} isOpen={mobileMenuOpen} links={links} />
}
