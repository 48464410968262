import * as React from 'react'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import { guestDashboardPath, guestOrganizationsPath, guestPortfolioPath, guestProfilePath, guestWalletsPath, organizationListPath, Paths, profilePath, User } from '@logic'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { StyledRoutes, StyledRoutesContainer } from '@src/app/components/routes/style'
import { Banner } from '@components/banner/banner'
import { userIsEmailVerified, userIsLoggedIn } from '@helpers/util'
import { Loading } from '@components/loading'
import { guestOrganizationsRoutes, guestRoutes, organizationListRoutes, organizationRoutes, profileRoutes, routes } from '@src/app/routes'
import { SubHeader, SubTitle } from '@src/app/components/header/sub-header'
import { PageTitles } from '@shared/components/page-titles'
import { matchesPath, withNavigation } from '@shared/components/navigation'
import { Header } from '../header/header'
import { ComponentType } from 'react'
import { withTracker } from '@shared/hocs/with-tracker'
import { withService } from '@shared/hocs/with-service'

interface Props {
  initialLoad: boolean
}

interface RoutesProps {
  user?: User
}

export interface RouteDefinition {
  path: string
  protected?: boolean
  redirect?: string
  component?: any
  requires?: any
  routes?: RouteDefinition[]
}

function getRouteRenderWithAuth(user: User | undefined, isLoggedIn: boolean, route: RouteDefinition, i: number) {
  if (isLoggedIn === route.protected || !route.redirect) {
    return () => {
      const RouteComponent = route.requires ? route.requires(route.component) : route.component
      return (
        <>
          {PageTitles()}
          <RouteComponent user={user} />
        </>
      )

    }
  } else {
    return (routeProps: RouteComponentProps<any>) => {
      const from = route.redirect == Paths.userLogin ? `?from=${routeProps.match.url}` : ''
      return <Redirect to={`${route.redirect!}${from}`} />
    }
  }
}

const Routes = (props: RoutesProps & Props) => {
  const { user } = props
  const isLoggedIn = userIsLoggedIn(user) && userIsEmailVerified(user)
  if (!props.initialLoad) {
    return <Loading />
  }

  const mapRoutes = (route: RouteDefinition, i: number) => {
    const render = getRouteRenderWithAuth(user, isLoggedIn, route, i)
    const rest = { render }
    return <Route key={i} path={route.path} exact {...rest} />
  }

  return (
    <StyledRoutesContainer user={user ? 'true' : 'false'}>
      <Banner />
      <StyledRoutes>
        <Switch>
          <Route path={profilePath}>
            <Switch>{profileRoutes.map(mapRoutes)}</Switch>
          </Route>
          <Route exact path={guestOrganizationsPath}>
            <SubHeader title={<SubTitle hideLogo name={'My Organizations'} />} withMargin={true} />
            <Switch>{guestOrganizationsRoutes.map(mapRoutes)}</Switch>
          </Route>
          <Route path={guestProfilePath}>
            <Switch>{guestRoutes.map(mapRoutes)}</Switch>
          </Route>
          <Route exact path={Paths.organizationList}>
            <SubHeader title={<SubTitle hideLogo name={'My Organizations'} />} withMargin={true} />
            <Switch>{organizationListRoutes.map(mapRoutes)}</Switch>
          </Route>
          <Route>
            <Switch>{organizationRoutes.concat(routes).map(mapRoutes)}</Switch>
          </Route>
        </Switch>
      </StyledRoutes>
    </StyledRoutesContainer>
  )
}

const mapStateToProps: StateTransform<RoutesProps> = s => ({ user: s.user })

export const AppRoutes = connect(mapStateToProps)(Routes)
