import * as React from 'react'
import { PageProps, PaymentProfilesResponse, viewCardPath, accountPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import styled from 'styled-components'
import { CommonForm } from '@components/forms'
import { BaseLinkProps } from '@shared/components/navigation'
import arrayMutators from 'final-form-arrays'
import { defaultPaymentProfile, editPaymentProfile } from '@src/service'
import { loadPaymentProfiles } from '@shared/helpers'
import { OrganizationSettingsSubHeader } from '@modules/organization/components/organization-settings-sub-header'
import { NewCardForm } from '../components/new-card-form'

type Props = PageProps & PaymentProfilesResponse

const withData = withLoadingCachedMultiple<Props>(loadPaymentProfiles)

const NewPaymentMethodWrap = styled.div`
    width: 550px;
`

export const EditCardPage = withData(
    (props: Props) => {
        const { httpClient, navigate, params, paymentProfiles, user } = props
        const { card } = params
        const currentProfile = paymentProfiles && paymentProfiles.length > 0 ? paymentProfiles.find(arg => arg.payment_profile.id === parseInt(card)) : undefined
        const {
            billing_address,
            billing_address_2,
            billing_city,
            billing_country,
            billing_state,
            billing_zip,
            first_name,
            last_name
        } = currentProfile.payment_profile
        const initialValues = {
            country: billing_country,
            street1: billing_address,
            street2: billing_address_2,
            province: billing_state,
            city: billing_city,
            postalCode: billing_zip,
            firstName: first_name,
            lastName: last_name,
            defaultPayment: currentProfile?.isDefault ? true : false,
        }

        const onSubmit = async (values: any) => {
            const billingAddress = {
                street1: values.street1,
                street2: values.street2,
                country: values.country,
                city: values.city,
                postalCode: values.postalCode,
                province: values.province,
            }

            const fullNumber = values.fullNumber.replace(/\u2000/g, '').trim()
            const creditCard = {
                firstName: values.firstName || undefined,
                lastName: values.lastName || undefined,
                fullNumber,
                expirationMonth: parseInt(values.expiration.split('/')[0]),
                expirationYear: parseInt(values.expiration.split('/')[1]),
                cvv: values.cvv || undefined
            }

            const editedProfile = {
                billingAddress,
                creditCard,
            }

            const editProfile = await editPaymentProfile(httpClient)({ entity: user?.id!, profile: card! }, editedProfile)
            if (editProfile?.payment_profile) {
                const profileId = editProfile.payment_profile?.id
                if (!currentProfile?.isDefault && values?.defaultPayment) {
                    await defaultPaymentProfile(httpClient)({ entity: user?.id, profile: profileId }, {})
                }
                navigate(viewCardPath, { card: profileId })
            }
            else console.log('create Security error') // TODO: add error message toast
        }

        const parent: BaseLinkProps = { path: accountPath, args: {} }

        return (
            <PageContent>
                <PageContentHeader title="Edit Payment Method" parent={parent} />
                <NewPaymentMethodWrap>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                        submitText="Save">
                        <NewCardForm edit={true} isDefault={currentProfile?.isDefault!} />
                    </CommonForm>
                </NewPaymentMethodWrap>
            </PageContent>
        )
    }
)
