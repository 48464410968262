import * as React from 'react'
import { StateTransform } from 'index'
import { connect } from 'react-redux'
import { MembersProps, PageProps, User } from '@logic'
import { GetColorsResponse } from '@src/service/services/profile'
import { ExpandedHoldingsResponse, GetSecuritiesResponse } from '@src/service'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { loadColors, loadPortfolioData } from '@shared/helpers'
import { CommonPortfolio } from '@shared/components/common-portfolio'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

interface StateProps {
    user?: User
}

type Props = PageProps &
    StateProps &
    MembersProps &
    GetColorsResponse &
    ExpandedHoldingsResponse &
    GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadPortfolioData('organization'), loadColors('organization'))

export const OrganizationPortfolioComponent = withPermissions([BuiltInPermission.viewOrganization])(
    withData((props: Props) => {
        return <CommonPortfolio {...props} forOrganization={true} title='Org Portfolio' hideCrumbs={true} />
    }))

const mapStateToProps: StateTransform<StateProps> = s => ({
    user: s.user,
})

export const OrganizationPortfolioPage = connect(mapStateToProps, {})(OrganizationPortfolioComponent)
