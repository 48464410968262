import * as React from 'react'
import styled from 'styled-components'
import { gapSizes } from '@src/styles/theme'
import { MultiBoxField } from '@shared/components/multibox'
import { emptyValidator } from '@shared/helpers'
import { allPermissions } from '../utility'

interface Props {
    values?: any
    permissions?: any[]
    isAdmin?: boolean
    view?: boolean
}

const rowHeight = '56px'
const StyledTable = styled.div<any>`
  transition: all 300ms ease-in;
  overflow-x: auto;
  border-radius: ${props => props.theme.radius};
  margin: 32px 0 64px 0;
  border: 1px solid #D9D9D9;
  box-shadow: inset 0px 1px 0px #D9D9D9, inset 1px 0px 0px #D9D9D9, inset -1px 0px 0px #D9D9D9, inset 0px -1px 0px #D9D9D9;
  table {
    color: ${props => props.theme.white};
    width: 100%;
    border-collapse: collapse;
    border: 1px solid transparent;
    border-spacing: 0;
    padding-right: 20px;
    thead {
      background-color: #283840 !important;
      text-transform: uppercase;
      tr {
            border-top: none;
            border-bottom: none;
      }
    }
    tr {
      &[role='row'] {
        > *:nth-child(1) {
          font-weight: bold;
          font-family: 'NunitoSansBold';
        }
      }
      > *:nth-child(1) {
        text-align: start;
        width: ${props => !props.isAdmin ? '52px' : '451px'};
        padding: ${props => !props.isAdmin && '18px 16px'} !important;
      }
    }
    th,
    td {
      text-align: start;
      padding: 0 16px;
    }
    td {
        font-size: 16px;
        line-height: 24px;
    }
    th {
        font-weight: bold;
        font-family: 'NunitoSansBold';
        background-color: ${props => props.theme.tableHeader} !important;
    }
  }
`

export const TableRow = styled.tr<any>`
  height: ${rowHeight};
  padding: 0 ${gapSizes.S};
  border-top: ${props => props.highlight === 'true' ? '1px solid #33AA40 !important' : '1px solid #9CA6AD'};
  border-bottom: ${props => props.highlight === 'true' && '1px solid #33AA40'};
  th, td {
    border-right: 1px solid ${props => props.theme.listDesc};
    :last-child {
        border-right: none;
    }
  }
  td {
    background: ${props => props.highlight === 'true' ? props.theme.rowHighlight : props.theme.newNav};
  }
`

export const PermissionsTable = (props: Props) => {
    const { isAdmin, values, view, permissions } = props
    const handleSelectAll = () => {
        if (!values?.all) {
            const permission: any = {}
            permissions?.forEach(p => {
                permission[`${p.id}`] = true
            })
            const v = { ...values, permission }
            Object.assign(values, v)
        }
        else {
            delete values?.all
            delete values?.permission
        }
    }

    const handleSelect = (permission: string) => {
        const rolePermissions = values?.permission || {}
        const currentLength = Object.entries(rolePermissions)
            .filter(([key, value]) => value && key !== permission)
            .map(([key, _]) => key)
            .concat(rolePermissions[permission] ? [] : [permission])
            .length || 0
        if (currentLength == allPermissions.length) {
            const v = { ...values, all: true }
            Object.assign(values, v)
        } else {
            delete values.all
        }
    }
    
    const headers = !isAdmin && !view ? [
        <MultiBoxField
            name="all"
            type="checkbox"
            handleClick={() => handleSelectAll()}
            noLabel={true}
            validate={emptyValidator}
        />,
        'Permission Name',
        'Permission Description',
    ] : ['Permission Name', 'Permission Description',]

    return (
        <StyledTable isAdmin={isAdmin || view}>
            <table>
                <thead>
                    <TableRow>
                        {headers.map((column, i: number) => {
                            return (
                                <th key={i}>
                                    {column}
                                </th>
                            )
                        })}
                    </TableRow>
                </thead>
                <tbody>
                    {permissions?.map((cell, i) => {
                        const id = cell?.id!
                        return (
                            <TableRow key={i} highlight={values?.permission && values.permission[id]! ? 'true' : 'false'}>
                                {!isAdmin && !view && <td>
                                    <MultiBoxField
                                        name={`permission.${cell?.id!}`}
                                        type="checkbox"
                                        noLabel={true}
                                        handleClick={() => handleSelect(cell?.id!)}
                                        validate={emptyValidator}
                                    />
                                </td>
                                }
                                <td>
                                    {cell?.name!}
                                </td>
                                <td>
                                    {cell?.description!}
                                </td>
                            </TableRow>
                        )
                    })}
                </tbody>
            </table>
        </StyledTable>
    )
}
