import * as React from 'react'
import {
  capitalizeTextInputField,
  nonZeroCurrencyField,
  dateField,
  FieldGroup,
  percentageFieldComponent,
  renderFieldsSimple,
  renderHeader,
  selectFieldComponent,
  seniorityInputFieldComponent,
  textInputFieldComponent,
  UploadHandler,
  conditionalFields,
  additionalLegendOptions,
} from '@components/forms'
import {
  emptyValidator,
  isNotZeroAndNegative,
  LegendOptions,
  legendsToSelectOptions,
  securitiesToSelectOptions
} from '@helpers/index'
import styled from 'styled-components'
import { fieldLabel } from '@src/styles'
import { ExpandedHolding, ExpandedLegend, ExpandedSecurity } from '@src/service'
import { AccrualFrequency, ConvertibleInstrumentType, DynamicFields } from '../utility'
import { formatToNumber } from '@modules/captable/utility'
import { NavigationProps, withNavigation } from '@components/navigation'
import { SeniorityTable } from '@shared/components/seniority-table'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'

interface Props {
  securities: any
  holdings: ExpandedHolding[]
  onUpload: UploadHandler
  values?: any
  securityType?: ExpandedSecurity
  legend?: any
  legends: ExpandedLegend[]
}

const StyledLabel = styled.div`
  ${fieldLabel}
`

const frequencyOptions = [
  { label: 'Daily', value: AccrualFrequency.daily },
  { label: 'Monthly', value: AccrualFrequency.monthly },
  { label: 'Annual', value: AccrualFrequency.annual }
]

const instrumentTypeOptions = [
  { label: '500 Startups Convertible Note', value: ConvertibleInstrumentType.startupsConvertibleNote },
  { label: '500 Startups Convertible Security', value: ConvertibleInstrumentType.startupsConvertibleSecurity },
  { label: 'Convertible Note', value: ConvertibleInstrumentType.convertibleNote },
  { label: 'Custom Convertible Instrument', value: ConvertibleInstrumentType.custom },
  { label: 'Post-Money SAFE', value: ConvertibleInstrumentType.postMoneySafe },
  { label: 'Pre-Money SAFE', value: ConvertibleInstrumentType.preMoneySafe },
  { label: 'S.A.F.T', value: ConvertibleInstrumentType.saft },
  { label: 'YC Convertible Note', value: ConvertibleInstrumentType.ycConvertibleNote },
  { label: 'YCVC SAFE', value: ConvertibleInstrumentType.ycvcSafe },
]

const filterFields = {
  [ConvertibleInstrumentType.convertibleNote]: [DynamicFields.conversionDiscount, DynamicFields.interestRate, DynamicFields.maturity],
  [ConvertibleInstrumentType.startupsConvertibleNote]:
    [
      DynamicFields.conversionDiscount,
      DynamicFields.interestRate,
      DynamicFields.maturity
    ],
  [ConvertibleInstrumentType.ycConvertibleNote]: [DynamicFields.conversionDiscount, DynamicFields.maturity],
  [ConvertibleInstrumentType.preMoneySafe]: [DynamicFields.conversionDiscount, DynamicFields.interestRate],
  [ConvertibleInstrumentType.startupsConvertibleSecurity]:
    [
      DynamicFields.conversionDiscount,
      DynamicFields.interestRate,
      DynamicFields.maturity
    ],
  [ConvertibleInstrumentType.ycvcSafe]: [DynamicFields.interestRate],
  [ConvertibleInstrumentType.postMoneySafe]: [DynamicFields.fixedOwnership, DynamicFields.seniorityTable],
  [ConvertibleInstrumentType.saft]: [DynamicFields.interestRate],
  [ConvertibleInstrumentType.custom]:
    [
      DynamicFields.conversionDiscount,
      DynamicFields.fixedOwnership,
      DynamicFields.interestRate,
      DynamicFields.maturity,
      DynamicFields.seniorityTable,
    ],
}

function instrumentTypeField(enabled: boolean): FieldGroup<any> {
  const fields = [
    {
      name: 'instrumentType',
      label: 'Instrument Type',
      required: true,
      validate: emptyValidator,
      placeholder: 'Select Type of Convertible Instrument to Issue',
      component: selectFieldComponent(instrumentTypeOptions, 'Instrument Type'),
    }
  ]
  const fieldsArray = conditionalFields(fields, enabled)
  return fieldsArray
}

function maturityDateField(enabled: boolean): FieldGroup<any> {
  const fields = [
    {
      name: 'maturityDate',
      label: 'Maturity Date',
      required: false,
      placeholder: 'Select the Date This Note Matures',
      ...dateField(React.useRef())
    }
  ]
  const fieldsArray = conditionalFields(fields, enabled)
  return fieldsArray
}

function instrumentDetailsFields(
  securities: ExpandedSecurity[],
  dynamicFields: any,
  legendOptions: ExpandedLegend[],
  edit?: boolean,
  custom?: boolean,
  legend?: any
): FieldGroup<any> {
  return [
    ...instrumentTypeField(!edit),
    {
      name: 'name',
      label: 'Instrument Name',
      required: !custom ? true : false,
      validate: emptyValidator,
      placeholder: 'Enter the Name of the Instrument Here',
      component: textInputFieldComponent
    },
    {
      name: 'abbreviation',
      label: 'Certificate Abbreviation',
      required: !custom ? true : false,
      validate: emptyValidator,
      placeholder: 'Enter Abbreviation',
      component: capitalizeTextInputField
    },
    ...maturityDateField(dynamicFields?.maturity),
    {
      name: 'convertsTo',
      label: 'Converts To',
      required: true,
      validate: emptyValidator,
      placeholder: 'Select the Asset This Instrument Will Convert To',
      component: selectFieldComponent(securitiesToSelectOptions(securities), 'Converts To'),
    },
    {
      name: 'legend',
      label: 'Legend',
      required: false,
      validate: emptyValidator,
      defaultValue: legend!,
      component: selectFieldComponent(legendsToSelectOptions(legendOptions), 'Legend'),
    },
  ]
}

function conversionDiscountField(enabled: boolean): FieldGroup<any> {
  const fields = [
    {
      name: 'conversionDiscount',
      label: 'Discount Rate',
      required: false,
      validate: emptyValidator,
      placeholder: '0.00%',
      component: percentageFieldComponent
    }
  ]
  const fieldsArray = conditionalFields(fields, enabled)
  return fieldsArray
}

function interestRateField(enabled: boolean, interest: boolean): FieldGroup<any> {
  const fields = [
    {
      name: 'interestRate',
      label: 'Interest Rate',
      required: interest ? true : false,
      validate: emptyValidator,
      placeholder: '0.00%',
      component: percentageFieldComponent
    }
  ]
  const fieldsArray = conditionalFields(fields, enabled)
  return fieldsArray
}

function conversionSettingsFields(dynamicFields: any, custom: boolean, interest: boolean): FieldGroup<any> {
  return [
    {
      name: 'authorizedRaiseAmount',
      label: 'Authorized Max Raise Amount (in USD)',
      required: true,
      placeholder: 'Authorized max raise amount in USD',
      ...nonZeroCurrencyField
    },
    {
      name: 'valuationMin',
      label: 'Minimum Valuation',
      required: false,
      placeholder: '$0.00',
      ...nonZeroCurrencyField
    },
    {
      name: 'valuationMax',
      label: 'Maximum Valuation',
      required: false,
      placeholder: '$0.00',
      ...nonZeroCurrencyField
    },
    {
      name: 'pricePerUnit',
      label: 'Actual Price Per Unit',
      required: false,
      placeholder: 'Actual Price Per Unit at Time of Conversion',
      ...nonZeroCurrencyField
    },
    ...conversionDiscountField(dynamicFields?.conversionDiscount),
    ...interestRateField(dynamicFields?.interestRate, interest),
    {
      name: 'accrualFrequency',
      label: 'Accrual Frequency',
      required: interest ? true : false,
      validate: emptyValidator,
      defaultValue: interest ? AccrualFrequency.annual : undefined,
      placeholder: 'Choose Frequency',
      component: selectFieldComponent(frequencyOptions, 'Accrual Frequency')
    }
  ]
}

function otherSettingsFields(custom?: boolean): FieldGroup<any> {
  return [
    {
      name: 'qualifiedFinancingAmount',
      label: 'Qualified Financing Amount',
      required: false,
      placeholder: '$0.00',
      ...nonZeroCurrencyField
    },
    {
      name: 'earlyExitMultiple',
      label: 'Early Exit Multiple',
      required: false,
      validate: emptyValidator,
      placeholder: '1x',
      format: formatToNumber,
      component: textInputFieldComponent
    },
    {
      name: 'seniority',
      label: 'Seniority Level',
      required: !custom ? true : false,
      validate: isNotZeroAndNegative,
      placeholder: 'Enter the Seniority Level of This Instrument',
      component: seniorityInputFieldComponent
    }
  ]
}

const renderFields = (
  securities: ExpandedSecurity[],
  onUpload: UploadHandler,
  legendOptions: ExpandedLegend[],
  SeniorityTableWrapper: any,
  values: any,
  edit?: boolean,
  legend?: any,
) => {
  const dynamicFields = filterFields[values.instrumentType!]?.map(fields => {
    return { [fields]: true }
  })
    .reduce((a, b) => ({ ...a, ...b }), {})
  const custom = values?.instrumentType === ConvertibleInstrumentType.custom
  const interest = values?.interestRate ? true : false

  return (
    <>
      {renderHeader({ label: 'Instrument Details' })}
      {renderFieldsSimple(instrumentDetailsFields(securities, dynamicFields, legendOptions, edit, custom, legend))}
      {renderHeader({ label: 'Conversion Details' })}
      {renderFieldsSimple(conversionSettingsFields(dynamicFields, custom, interest))}
      {renderHeader({ label: 'Other Settings' })}
      {renderFieldsSimple(otherSettingsFields(custom))}
      {dynamicFields?.seniorityTable &&
        <>
          <StyledLabel style={{ marginBottom: '4px' }}>Seniority Preview</StyledLabel>
          {SeniorityTableWrapper}
        </>
      }
      <DocumentsAndNotes onUpload={onUpload} values={values} />
    </>
  )
}

export const NewConvertibleInstrumentsForm = withNavigation((props: Props & NavigationProps) => {
  const { onUpload, values, securities, holdings, legends, securityType } = props
  const legend = values?.convertsTo ? securities?.find(s => s.hash === values.convertsTo)?.legend!
    :
    securityType ? securityType.legend! : undefined

  const additionalLegends = additionalLegendOptions(legends, values?.legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  const SeniorityTableWrapper = () => {
    return <SeniorityTable securities={securities} holdings={holdings} editHolding={false} editSecurity={true} values={values} />
  }
  return renderFields(securities, onUpload, legendOptions, SeniorityTableWrapper(), values, false, legend)
})

export const EditConvertibleInstrumentsForm = (props: Props & { holding: string }) => {
  const { onUpload, values, securities, holdings, holding, securityType, legend, legends } = props
  const legendValue = legend ? legend
    :
    values?.convertsTo ? securities?.find(s => s.hash === values.convertsTo)?.legend! : undefined

  const additionalLegends = additionalLegendOptions(legends, legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  const SeniorityTableWrapper = () => {
    return <SeniorityTable
      securities={securities}
      holdings={holdings}
      holding={holding}
      editHolding={true}
      editSecurity={true}
      values={values} />
  }
  return renderFields(securities, onUpload, legendOptions, SeniorityTableWrapper(), values, true, legendValue)
}
