import * as React from 'react'
import styled from 'styled-components'
import { colors, fontSizes } from '@src/styles/theme'
import Bang from '@image/bang.svg'

export interface FieldMeta {
  active: boolean
  data: object
  dirty: boolean
  dirtySinceLastSubmit: boolean
  error: any
  initial: any
  invalid: boolean
  pristine: boolean
  submitError: any
  submitFailed: boolean
  submitSucceeded: boolean
  submitting: boolean
  touched: boolean
  valid: boolean
  visited: boolean
}

export interface FieldErrorsProps {
  meta: Partial<FieldMeta>
}

const ErrorList = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  svg {
    margin-right: 10px;
  }
`

export const ErrorLine = styled.li<any>`
  color: ${colors.errorRed};
  // background-color: ${colors.bannerError};
  display: flex;
  align-items: center;
  // padding: 5px;
  // margin: 2px 0;
  font-size: ${fontSizes.XS};
  line-height: 25px;
  // border-radius: ${props => props.theme.radius};
`

export function FieldErrors(props: FieldErrorsProps) {
  const { meta } = props
  const errors = Array.isArray(meta.error) ? meta.error : [meta.error]
  if (!meta.touched || errors.length === 0) return null
  let errorItems = errors.map((e: any, i: number) => {
    if (e !== undefined && e !== null)
      return (
        <ErrorLine key={i}>
          {/* <Bang /> */}
          {e}
        </ErrorLine>
      )
  })
  errorItems = errorItems.filter(errorItem => errorItem !== undefined)

  if (errorItems.length) return <ErrorList>{errorItems}</ErrorList>

  return null
}
