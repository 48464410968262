import * as React from 'react'
import { MembersProps, OrganizationProps, PageProps, viewConvertibleInstrumentPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding, loadMembers, loadSecurities } from '@helpers/loaders'
import { CommonForm, UploadHandler } from '@components/forms'
import arrayMutators from 'final-form-arrays'
import {
  CapitalChangeRequest,
  createAuthorization,
  createHolding,
  ExpandedHoldingResponse,
  FilesResponse,
  GetSecuritiesResponse,
  patchCapitalChange,
  NewHoldingRequest,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { ConvertibleNoteForm } from '../components'
import { CapitalContributionType } from '@modules/captable/utility'
import { getInterestFromConvertible, getMaxShares, getOrganizationTotalUnits } from '@modules/convertibles/utility'
import { ConvertibleNoteFormFields } from '@modules/convertibles'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { HoldingRecordType } from '@shared/helpers/holdings'

type Props = PageProps & OrganizationProps & ExpandedHoldingResponse & MembersProps & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadMembers, loadSecurities())

export const NewConvertibleNotePage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, holding, members, securities } = props
    const { organization } = params
    const initialValues = {}
    const parentName = holding.name

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }
    const onSubmit = async (values: ConvertibleNoteFormFields) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const { convertibleInstrument } = holding
      const { valuationMin, conversionDiscount } = convertibleInstrument
      const { issueDate, owner, principle } = values
      const interest = getInterestFromConvertible(convertibleInstrument, issueDate, principle)
      const projectedPrinciple = principle + interest
      const totalUnits = getOrganizationTotalUnits(securities)
      const maxUnits = getMaxShares({
        maxUsd: projectedPrinciple,
        valuationMin,
        totalUnits,
        discount: conversionDiscount
      })
      const holdingRequest = {
        owner,
        issueDate,
        parent: holding.hash!,
        entity: organization,
        value: maxUnits,
        legend: holding.legend!,
        holdingType: HoldingRecordType.convertibleNote,
        internalType: convertibleInstrument ? convertibleInstrument.instrumentType : '',
        class: holding ? holding.abbreviation : '',
      } as NewHoldingRequest
      const newHolding = await createHolding(httpClient)({ organization }, holdingRequest)
      if (newHolding) {
        const holding = newHolding?.hash!
        const capitalChangeRequest = {
          mod: principle,
          timestamp: new Date(),
          type: CapitalContributionType.cashPaid,
          source: holding
        } as CapitalChangeRequest
        await patchCapitalChange(httpClient)(
          { entity: organization }, capitalChangeRequest
        )
        modifyAuthorizations({ approvalDocuments, target: holding, httpClient, organization })
        navigate(viewConvertibleInstrumentPath, { organization, holding: params?.holding })
      } else console.log('Create Convertible Note error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = {
      path: viewConvertibleInstrumentPath,
      args: { organization, holding: params?.holding }
    }

    return (
      <PageContent>
        <PageContentHeader title={`New ${parentName}`} parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Issue">
          <ConvertibleNoteForm onUpload={onUpload} members={members} />
        </CommonForm>
      </PageContent>
    )
  }
  ))
