import { createAuthorization, deleteAuthorization, HttpClient, NewAuthorizationRequest } from '@src/service'
import { Hash } from './util'

export interface AuthProps {
    approvalDocuments: any[]
    target: Hash
    httpClient: HttpClient
    organization: Hash
}

export async function modifyAuthorizations(props: AuthProps) {
    const { approvalDocuments, target, httpClient, organization } = props
    if (approvalDocuments?.length > 0) {
        approvalDocuments?.forEach(async (doc: any) => {
            if (doc.isRemoved && doc.hash) {
                await deleteAuthorization(httpClient)({ organization, authorization: doc.hash })
            }
            else if (!doc.isRemoved) {
                const request = {
                    authorizationDate: doc.approvalDate!,
                    document: doc.approvalDocument.id!,
                    note: doc.note!,
                    target,
                    documentTypeName: doc.documentTypeName!,
                } as NewAuthorizationRequest
                await createAuthorization(httpClient)({ organization }, request)
            }
        })
    }
}