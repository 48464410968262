import * as React from 'react'
import {
  dateField,
  equaIDComponent,
  multiSelectFieldComponent,
  phoneNumberField,
  renderFieldsSimple,
  textInputFieldComponent
} from '@components/forms'
import { emptyValidator, isEmail, isFullName, isTitle } from '@helpers/field-validators'
import { Address } from '@shared/components/address'
import { RadioWrap, semiBoldStyle } from '@src/styles'
import { PathLink } from '@shared/components/navigation'
import { rolesPath } from '@logic'
import { isEquaId, optionsFromRoles } from '@shared/helpers'
import { Role } from '@src/service'
import styled from 'styled-components'
import { MultiBoxField } from '@shared/components/multibox'
import InfoCircle from '@image/info-circle-16x16.svg'
import { InfoWrap } from '@modules/organization/pages'
import { handleMemberDeselect } from '@modules/esop/utility'

interface Props {
  organization: string
  roles: Role[]
  values?: any
}

export const fullNameField = {
  validate: isFullName,
  component: textInputFieldComponent,
}

const FormWrap = styled.div<any>`
  width: 100%;
  .kHaIAq {
    padding-top: 0;
  }
`

const EquaLabel = styled.span<any>`
  display: flex !important;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
    margin-left: 5.5px;
  }
`

const MemberTitle = styled.h5<any>`
  ${semiBoldStyle}
  color: ${({ theme }) => theme.white};
  margin: 30px 0 24px 0;
  font-size: 24px;
  line-height: 32px;
`


const memberTypeOptions = [
  { name: 'individual', text: 'Individual' },
  { name: 'organization', text: 'Organization' }
]

export const MemberForm = (props: Props) => {
  const [info, setInfo] = React.useState<boolean>(false)
  const { organization, roles, values } = props
  const rolesOptions = optionsFromRoles(roles)
  const RolesText = () => {
    return <>Learn more about <PathLink path={rolesPath} args={{ organization, parent: window.location.pathname }}>
      Roles & Permissions
    </PathLink>
    </>
  }

  const startDatePicker = React.useRef()

  return <FormWrap>
    <MemberTitle>Team Member Information</MemberTitle>
    <span>
      Is this member an individual or an organization?
    </span>
    <RadioWrap>
      {memberTypeOptions.map(o => {
        return <MultiBoxField
          name={`types.${o.name}`}
          text={o.text}
          type='checkbox'
          styleType='radio'
          handleClick={() => handleMemberDeselect(values, o.name)}
        />
      })}
    </RadioWrap>
    {renderFieldsSimple([
      {
        name: 'fullName',
        label: 'Name',
        required: true,
        placeholder: 'Enter your full name',
        ...fullNameField,
      },
      {
        name: 'email',
        label: 'Email Address',
        required: false,
        placeholder: 'Enter email address',
        validate: isEmail,
        component: textInputFieldComponent,
      },
      {
        name: 'phone',
        label: 'Phone Number',
        required: false,
        validate: emptyValidator,
        component: phoneNumberField,
      },
      {
        name: 'dateOfBirth',
        label: values?.types.individual ? 'Date of Birth' : 'Start Date',
        required: false,
        placeholder: 'MM/DD/YYYY',
        ...dateField(startDatePicker),
      },
      {
        name: 'user',
        label: <EquaLabel>
          Equa ID
          <InfoCircle onMouseEnter={() => setInfo(true)} onMouseLeave={() => setInfo(false)} />
          {info ? <InfoWrap style={{ top: '-48px', maxWidth: values?.types.organization ? '363px' : '283px' }}>
            {values?.types.organization ?
              `You can link an Equa account using their Equa ID.
              Their Equa ID can be found on their Organization’s Details page`
              :
              `You can link an Equa account using their Equa ID.
              Their Equa ID can be found on their Profile page.`
            }
          </InfoWrap> : null
          }
        </EquaLabel>,
        required: false,
        placeholder: 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx',
        validate: isEquaId,
        component: equaIDComponent,
      },
      {
        name: 'title',
        label: 'Title',
        required: false,
        placeholder: 'Enter title',
        validate: isTitle,
        component: textInputFieldComponent,
      },
      {
        name: 'roles',
        label: 'Roles',
        required: false,
        validate: emptyValidator,
        note: <RolesText />,
        component: multiSelectFieldComponent(rolesOptions, 'Roles'),
      },
    ])}
    <Address name={'address'} />
  </FormWrap>
}
