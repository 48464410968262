import * as React from 'react'
import {
  FieldGroup,
  uploadFileField,
  UploadHandler,
  renderFieldsSimple,
  numericInputFieldWithCommas,
  renderHeader,
} from '@shared/components/forms'
import { emptyValidator, Hash, formatSecurityName } from '@shared/helpers'
import styled from 'styled-components'
import { whiteColor, FlexRow, DetailsLabel, FieldNote } from '@src/styles'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
  values?: any
  onUpload: UploadHandler
  securities: any
  security: Hash
}
const StyleAddSharesForm = styled.div``

const SecurityDetailsWrapper = styled.div`
  ${whiteColor}
`

function addSharesFields(onUpload: UploadHandler): FieldGroup<any> {
  return [
    {
      name: 'shares',
      label: 'Total Additional Shares to Authorize',
      required: true,
      validate: emptyValidator,
      format: formatToNumber,
      component: numericInputFieldWithCommas,
    },
    {
      name: 'boardApprovalDocument',
      label: 'Board Approval Document',
      required: false,
      note: 'Maximum file size: 30MB. Accepted file type(s): PDF',
      ...uploadFileField({ onUpload, placeholder: 'Board Approval Document', accept: '.pdf' }),
    },
  ]
}

const renderInfo = (label: string, value: any, index: number) => {
  return (
    <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
      <DetailsLabel>{label}</DetailsLabel>
      <div>
        {value}
      </div>
    </FlexRow>
  )
}

export const AddSharesForm = (props: Props) => {
  const { onUpload, securities, security, values } = props
  const securityItems = securities.filter((arg: any) => arg.hash === security)[0]
  const { shares } = securityItems
  const formShares = typeof values.shares === 'string' ? parseInt(values.shares.replace(/\,/g, '')) : values.shares

  const totalShares = (shares: number, newShares: number) => {
    return shares + newShares
  }

  const dilutionRate = (newShares: number, totalShares: number) => {
    if (newShares === 0 || totalShares === 0) {
      return '0%'
    } else {
      const value = newShares / totalShares
      const percentage = value * 100
      return `${percentage.toFixed(2)}%`
    }
  }

  const details = [
    { label: 'Equity', value: formatSecurityName(securityItems) },
    { label: 'Current Number of Authorized Shares', value: shares },
    { label: 'New Shares to Authorize', value: formShares || 0 },
    { label: 'Dilution Rate', value: dilutionRate(formShares || 0, totalShares(shares, formShares || 0)) },
    { label: 'New Total Authorized Shares', value: totalShares(shares, formShares || 0) },
  ]

  return (
    <StyleAddSharesForm>
      {renderFieldsSimple(addSharesFields(onUpload))}
      <SecurityDetailsWrapper>
        {renderHeader({ label: 'Details' })}
        {details.map((el, index: number) => {
          return renderInfo(el.label, el.value, index)
        })}
      </SecurityDetailsWrapper>
    </StyleAddSharesForm>
  )
}
