import * as  React from 'react'
import styled from 'styled-components'
import { colors, FlexRow, fontSizes, gapSizes, Themes } from '@src/styles'
import { useClickOutside } from '@shared/hooks/useClickOutside'
import { getIcon, StyledEllipses } from './tables'

const ActionsWrap = styled<any>(StyledEllipses)`
    width: 48px;
    height: 48px;
    border-radius: 4px;
    background-color: ${props => props.theme.actionsDrop.default};
    align-items: center;
    cursor: pointer;
    &:hover {
      background-color: ${props => props.theme.actionsDrop.hover};
      >div:first-child:after {
        color: ${props => props.theme.selected === Themes.LIGHT && colors.white};
      }
    }
    > div:first-child {
        color: ${props => props.theme.white};
        font-size: 10px;
        padding: 0;
    }
    >div:first-child:after {
        content: '• • •';
        color: ${props => props.theme.selected === Themes.LIGHT ? '#9CA6AD' : colors.white};
    }
`

const Options = styled.div<any>`
    display: ${props => !props.expanded ? 'none' : 'flex'};
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 42px;
    text-align: start;
    font-size: ${fontSizes.XS};
    white-space: nowrap;
    z-index: 4;
    opacity: 1;
`

const StyledOption = styled.div<any>`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: ${props => props.noClick === 'false' ? `${gapSizes.S} ${gapSizes.M}` : 0};
  background-color: ${props => props.theme.ellipseOption};
  width: 100%;
  border-radius: ${props => props.isFirst && props.isLast ? '4px 4px 4px 4px' : props.isFirst ? '4px 4px 0 0' : props.isLast ? '0 0 4px 4px' : ''};
  &:hover {
    background-color: ${props => props.isDelete ? colors.errorRed : props.theme.accent};
    span {
      color: ${props => props.isDelete ? colors.white : props.theme.white};
    }
    svg {
      path {
        fill: ${props => colors.white} !important;
      }
  }
  }
  .withPadding {
    width: 100%;
    padding: ${gapSizes.S} ${gapSizes.M};
  }
  svg {
    path {
      fill: ${props => props.isDelete ? colors.errorRed : props.theme.listIcon} !important;
    }
  }
  a {
    color: ${props => props.theme.white};
  }
`

const StyledSpan = styled.span<any>`
  display: flex;
  width: 100%;
  color: ${props => props.isDelete && colors.errorRed};
`

export const ActionsDrop = (props: any) => {
  const { options } = props
  const [expanded, setExpanded] = React.useState(false)
  const node = React.useRef(null)
  const handleClose = () => {
    setExpanded(false)
  }
  useClickOutside({ node, handleClose })
  return <ActionsWrap ref={node} onClick={() => setExpanded(!expanded)}>
    <div>
      <Options className='options' expanded={expanded}>{options.map((option: any, i: number) => {
        const isFirst = i === 0
        const isLast = i === options.length - 1
        return (
          option ? (
            <StyledOption
              key={option[0]}
              onClick={option[1]}
              isFirst={isFirst}
              isLast={isLast}
              isDelete={option[0] == 'Delete'}
              noClick={option[1] ? 'false' : 'true'}>
              {getIcon(option[0])}
              <StyledSpan isDelete={option[0] == 'Delete'}>{option[0]}</StyledSpan>
            </StyledOption>
          )
            : null
        )
      })}</Options>
    </div>
  </ActionsWrap>
}