import Modal from '@components/modal'
import { ButtonLayout, ModalCloseButton, StyledModal } from '@components/modal/style'
import Close from '@image/close-default.svg'
import { FlexRow } from '@src/styles'
import { Button, CancelButton, ErrorButton, ClearButton, SolidButton } from '@components/button'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import { gapSizes } from '@src/styles/theme'
import { CommonForm } from './forms'
import arrayMutators from 'final-form-arrays'
import { rgba } from 'polished'
import Warning from '@image/warning.svg'
interface ButtonProps {
  text: string
  onActivate?: () => void
  type?: 'submit' | 'reset' | 'button'
  form?: string
  componentType?: any
  disabled?: boolean
}

interface Props {
  title?: string | ReactElement
  desc?: string
  body?: ReactElement
  buttons?: ButtonProps[]
  onCancel?: () => void
  noCancel?: boolean
  cancelText?: string
  max?: string
  align?: string
  noButtons?: boolean
  noClose?: boolean
  warningIcon?: boolean
  noPadding?: boolean
}

interface FormProps {
  title: string | ReactElement
  desc?: string
  body?: ReactElement
  onCancel?: () => void
  noCancel?: boolean
  cancelText?: string
  onSubmit: (props: any) => void
  submitText?: string
  initialValues?: any
  invalid?: boolean
  noButtons?: boolean
  max?: string
  align?: string
  noPadding?: boolean
}

interface MobilePopUpProps {
  title: string | ReactElement
  desc?: string
  body?: ReactElement
  onCancel?: () => void
  noCancel?: boolean
  cancelText?: string
  buttons?: ButtonProps[]
  onSubmit?: (props: any) => void
  submitText?: string
  initialValues?: any
  invalid?: boolean
  noButtons?: boolean
  max?: string
  align?: string
  noPadding?: boolean
  noForm?: boolean
  defaultVisible?: boolean
  noClose?: boolean
}

const ModalHeader = styled.div<any>`
  width: 100%;
  padding: ${props => props.noPadding === 'true' ? `32px 32px 0 32px` : `32px 32px ${gapSizes.M}`};
  .warning {
    margin-right: 16px;
    path {
      fill: ${props => props.theme.warning};
    }
`

const TitleView = styled.h4<any>`
  margin: 0;
  color: ${({ theme }) => theme.white};
  width: 90%;
`

const BodyView = styled.div`
  width: 100%;
  overflow-y: auto;
  padding: 10px 32px 24px 32px;
  &::-webkit-scrollbar {
    width: 6px;
    background-color: ${rgba(81, 111, 119, 0.101961)};
    border-radius: 6px;
    border: 4px solid transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-color: #9CA6AD;
    border-radius: 6px;
    background-clip: content-box;
  }
`

const FormBodyView = styled.div<any>`
  width: 100%;
  overflow-y: auto;
  .body {
    padding: ${props => props.noPadding === 'true' ? 0 : `${gapSizes.S} ${gapSizes.L}`};
  }
`

const PopUpWrap = styled.div<any>`
   background: ${props => props.theme.modalBack};
   width: 100%;
   position: absolute;
   bottom: -56px;
   border-top-left-radius: 8px;
   border-top-right-radius: 8px;
`
const DescView = styled.div`
  margin-top: 24px;
`

const FormButtonLayout = styled<any>(ButtonLayout)`
  button {
    height: 40px;
    padding: 0 32px !important;
  }
`

export function BasicModal(props: Props) {
  const { title, desc, body, buttons, noCancel, cancelText = 'Cancel', max, align, noButtons, noClose, warningIcon,noPadding } = props
  const onCancel = props.onCancel || function () { }
  return (
    <Modal max={max} align={align}>
      {!noClose && <ModalCloseButton onClick={onCancel}>
        <Close />
      </ModalCloseButton>}
      {(title || desc) && <ModalHeader  noPadding={noPadding ? 'true' : 'false'}>
        <FlexRow>
          {(warningIcon) && <Warning className='warning' />}
          <TitleView>{title}</TitleView>
        </FlexRow>
        {desc ? <DescView>{desc}</DescView> : null}
      </ModalHeader>}
      <BodyView>{body}</BodyView>
      {!noButtons && <ButtonLayout>
        {!noCancel && <ClearButton onClick={onCancel}>{cancelText}</ClearButton>}
        {buttons &&
          buttons.map(({ text, onActivate, componentType, ...buttonProps }) => {
            const ButtonComponent = componentType || Button
            return (
              <ButtonComponent key={text} onClick={onActivate} {...buttonProps}>
                {text}
              </ButtonComponent>
            )
          })}
      </ButtonLayout>}
    </Modal>
  )
}

const FormButtons = (props: any) => {
  return <FormButtonLayout>
    {!props.noCancel && <ClearButton onClick={props.onCancel}>{props.cancelText}</ClearButton>}
    {props?.submitText === 'Delete' ?
      <ErrorButton disabled={props.invalid || props.submitting}>
        {props.submitText}
      </ErrorButton>
      :
      <SolidButton type="submit" disabled={props.invalid || props.submitting}>
        {props.submitText}
      </SolidButton>
    }
  </FormButtonLayout>
}

export function BasicModalWithForm(props: FormProps) {
  const { title,
    desc,
    body,
    noCancel,
    onSubmit,
    cancelText = 'Cancel',
    submitText,
    initialValues,
    noButtons,
    max,
    align,
    noPadding } = props
  const onCancel = props.onCancel || function () { }

  return (
    <Modal max={max} align={align}>
      <ModalCloseButton onClick={onCancel}>
        <Close />
      </ModalCloseButton>
      {(title || desc) && <ModalHeader noPadding={noPadding ? 'true' : 'false'}>
        <TitleView>{title}</TitleView>
        <DescView>{desc}</DescView>
      </ModalHeader>}
      <FormBodyView noPadding={noPadding ? 'true' : 'false'}>
        {noButtons ?
          <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues} stretched={true}>
            <div className="body">{body}</div>
          </CommonForm>
          :
          <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues} stretched={true}>
            <div className="body">{body}</div>
            <FormButtons noCancel={noCancel} onCancel={onCancel} cancelText={cancelText} submitText={submitText} />
          </CommonForm>
        }
      </FormBodyView>
    </Modal>
  )
}

export function MobilePopUpModal(props: MobilePopUpProps) {
  const { title,
    desc,
    body,
    noCancel,
    onSubmit,
    cancelText = 'Cancel',
    submitText,
    initialValues,
    noButtons,
    max,
    align,
    noPadding,
    noForm,
    buttons,
    noClose
  } = props
  const onCancel = props.onCancel || function () { }

  return (
    <StyledModal show={true} max={max} align={align} absolute={'true'}>
      <PopUpWrap>
        {!noClose && <ModalCloseButton onClick={onCancel}>
          <Close />
        </ModalCloseButton>}
        {(title || desc) && <ModalHeader noPadding={noPadding ? 'true' : 'false'}>
          <TitleView>{title}</TitleView>
          <DescView>{desc}</DescView>
        </ModalHeader>}
        {noForm ?
          <>
            <BodyView>{body}</BodyView>
            {!noButtons && <ButtonLayout>
              {!noCancel && <CancelButton onClick={onCancel}>{cancelText}</CancelButton>}
              {buttons &&
                buttons.map(({ text, onActivate, componentType, ...buttonProps }) => {
                  const ButtonComponent = componentType || Button
                  return (
                    <ButtonComponent key={text} onClick={onActivate} {...buttonProps}>
                      {text}
                    </ButtonComponent>
                  )
                })}
            </ButtonLayout>}
          </>
          : onSubmit &&
          <FormBodyView noPadding={noPadding ? 'true' : 'false'}>
            {noButtons ?
              <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues} stretched={true}>
                <div className="body">{body}</div>
              </CommonForm>
              :
              <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues} stretched={true}>
                <div className="body">{body}</div>
                <FormButtons noCancel={noCancel} onCancel={onCancel} cancelText={cancelText} submitText={submitText} />
              </CommonForm>
            }
          </FormBodyView>}
      </PopUpWrap>
    </StyledModal>
  )
}

export interface BasicModalBundle {
  component: ReactElement
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export function newBasicModalBundle(props: Props): BasicModalBundle {
  const [isVisible, setVisible] = useState<boolean>(false)
  const component = (
    <>
      {isVisible ? (
        <BasicModal warningIcon={props.warningIcon} cancelText={props.cancelText} noCancel={props.noCancel} onCancel={() => setVisible(false)} {...props} />
      ) : null}
    </>
  )
  return {
    component,
    isVisible,
    setVisible,
  }
}

export function formBasicModalBundle(props: FormProps): BasicModalBundle {
  const [isVisible, setVisible] = useState<boolean>(false)
  const component = (
    <>
      {isVisible ? (
        <BasicModalWithForm cancelText={props.cancelText} noCancel={props.noCancel} onCancel={() => setVisible(false)} {...props} />
      ) : null}
    </>
  )
  return {
    component,
    isVisible,
    setVisible,
  }
}

export function mobilePopUpBundle(props: MobilePopUpProps): BasicModalBundle {
  const { defaultVisible } = props
  const [isVisible, setVisible] = useState<boolean>(defaultVisible || false)
  const component = (
    <>
      {isVisible ? (
        <MobilePopUpModal cancelText={props.cancelText} noCancel={props.noCancel} onCancel={() => setVisible(false)} {...props} />
      ) : null}
    </>
  )
  return {
    component,
    isVisible,
    setVisible,
  }
}
