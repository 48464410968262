import * as React from 'react'
import { PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadSupportedProtocols } from '@helpers/loaders'
import { WalletPortfolioComponent } from '@modules/profile/components/wallet-port'
import { signUpModal } from '@shared/components/modal'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>(loadSupportedProtocols)

export const GuestWalletPortfolio = withData((props: Props) => {
    const { navigate } = props

    const signUpModalBundle = signUpModal({ navigate })
    return<>
    {signUpModalBundle.component}
     <WalletPortfolioComponent {...props} forGuest={true} openSignUp={() => signUpModalBundle.setVisible(true)}/>
     </>
})
