import * as React from 'react'
import { PanelSummaryHeader } from '@components/panels/PanelSummaryHeader'
import { Panel } from '@components/panels/panels'

interface Props {
  recentActions: any
}

export const RecentActions = (props: Props) => {
  const { recentActions } = props
  return (
    <Panel>
      <PanelSummaryHeader title="Actions" disabled={true} path="" args={{}} />
      <table>
        <tbody>
          {recentActions.length > 0 &&
            recentActions.map((v: any, k: any) => {
              return (
                <tr key={k}>
                  <td>{v.action}</td>
                  <td>{v.actionTime}</td>
                </tr>
              )
            })}
        </tbody>
      </table>
      {recentActions.length === 0 && (
        <div style={{ margin: '25%' }}>
          <h1 style={{ color: '#ccc', textAlign: 'center' }}>Coming Soon</h1>
        </div>
      )}
    </Panel>
  )
}
