import { emptyValidator, nonZeroNumberWithOptionalCommas } from '@helpers/field-validators'
import * as React from 'react'
import { Member, ShareholdingResponse, ShareType } from '@logic'
import {
  optionsFromMembers,
  optionsFromShareholders,
  Uuid,
} from '@helpers/util'
import {
  dateField,
  renderHeader,
  selectFieldComponent,
  renderFieldsSimple,
  UploadHandler,
  additionalLegendOptions,
  numericInputFieldWithCommas,
} from '@components/forms'
import { groupHoldingsByMember, legendsToSelectOptions } from '@src/shared/helpers'
import { TransferReview } from '@modules/captable/components/transfer-review'
import { CapitalContributions } from '@modules/captable/components/capital-contributions'
import { ExpandedHolding, ExpandedLegend, ExpandedSecurity } from '@src/service'
import { formatToNumber } from '../utility'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'

interface Props {
  securities: ExpandedSecurity[]
  members: Member[]
  values?: any
  holdings: ExpandedHolding[]
  voidedHoldings?: ExpandedHolding[]
  shareholdings: ExpandedHolding[]
  organization: Uuid
  legends: ExpandedLegend[]
  onUpload: UploadHandler
}

export function recipientFields(toMemberOptions: any, values: any) {
  return renderFieldsSimple([
    {
      name: 'recipient',
      label: 'Transfer To',
      placeholder: 'Select a team member to receive the transferred shares',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(
        toMemberOptions.filter(member => (values.fromMember ? member.value !== values.fromMember : true)),
        'Transfer To'
      ),
    },
  ])
}

export function dateAndLegendFields(legendOptions: any) {
  return renderFieldsSimple([
    {
      name: 'issueDate',
      label: 'Issue Date',
      required: true,
      ...dateField(React.useRef()),
    },
    {
      name: 'legend',
      label: 'Legend',
      required: false,
      validate: emptyValidator,
      component: selectFieldComponent(legendsToSelectOptions(legendOptions), 'Legend'),
    },
  ], true)
}

export function contributingFields(memberOptions: any, certificateOptions: any, shareholdings: any, securities: any, values: any) {
  return renderFieldsSimple([
    {
      name: 'fromMember',
      label: 'Transfer From Member',
      placeholder: 'Select the team member contributing shares',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(
        memberOptions.filter(member => (values.recipient ? member.value !== values.recipient : true)),
        'Transfer From Member'
      ),
    },
    {
      name: 'source',
      label: 'Transfer From Certificate',
      placeholder: 'Select the certificate to transfer shares from',
      required: true,
      defaultValue: certificateOptions && certificateOptions.length > 0 && certificateOptions[0].value,
      validate: emptyValidator,
      component: selectFieldComponent(certificateOptions, 'Transfer From Certificate'),
    },
    {
      name: 'shares',
      label: `Number of ${securities.length > 0 && securities[0].shareType === ShareType.units ? 'Units' : 'Shares'}`,
      placeholder: 'Enter the amount of shares to transfer',
      required: true,
      format: formatToNumber,
      validate: nonZeroNumberWithOptionalCommas(),
      component: numericInputFieldWithCommas,
    },
  ])
}

export const TransferShareholdingForm = (props: Props) => {
  const { values, members, holdings, organization, legends, onUpload, securities, shareholdings, voidedHoldings } = props
  const toMemberOptions = optionsFromMembers(members)

  const filteredShareholders = groupHoldingsByMember(members, holdings, voidedHoldings, securities)
    .map(member => {
      return { ...member, shareholdings: member.shareholdings }
    })
    .filter(b => b.shareholdings.length > 0)

  const memberOptions = optionsFromShareholders(filteredShareholders)
  const currentSelectedMemberShareholdings = filteredShareholders.find(memberShareholding => values.fromMember === memberShareholding.id)

  const certificateOptions = values.fromMember && currentSelectedMemberShareholdings ?
    currentSelectedMemberShareholdings.shareholdings?.map((s: ShareholdingResponse) => {
      return {
        label: `${s.name} (${s.value} Shares)`,
        value: s.id,
      }
    })
    : null

  const currentSelectedFromCertificate = certificateOptions
    ? certificateOptions.find(certificate => values.source === certificate.value)
    : null
  const currentShareholding =
    values.source &&
    currentSelectedMemberShareholdings &&
    currentSelectedMemberShareholdings.shareholdings.find(shareholding => shareholding.id === values.source)
  const shareAmounts = currentShareholding &&
    values.shares && {
    balance: currentShareholding.value,
    send: parseFloat(values.shares),
  }
  const currentSelectedRecipient = toMemberOptions.find(member => values.recipient === member.value)
  const currentSelectedFromMember = memberOptions.find(member => values.fromMember === member.value)
  const fullTransfer = shareAmounts?.balance! === shareAmounts?.send!

  const resetFilteredFields = () => {
    delete values.source
    delete values.fromMember
  }

  const additionalLegends = additionalLegendOptions(legends, values?.legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)
  const hasDocuments = values?.approvalDocument && values.approvalDocument.length > 0

  return (
    <>
      <div>
        {renderHeader({ label: 'Recipient' })}
        {recipientFields(toMemberOptions, values)}
        {renderHeader({ label: 'Transaction Value' })}
        {dateAndLegendFields(legendOptions)}
        <CapitalContributions />
        {renderHeader({ label: 'Contributing Team Member' })}
        {contributingFields(memberOptions, certificateOptions, shareholdings, securities, values)}
        <DocumentsAndNotes onUpload={onUpload} values={values} />
      </div>
      <TransferReview
        fromCertificate={currentSelectedFromCertificate}
        fromMember={currentSelectedFromMember}
        recipient={currentSelectedRecipient}
        shareAmounts={shareAmounts}
        hasDocuments={hasDocuments}
        fullTransfer={fullTransfer}
      />
    </>
  )
}
