import React from 'react'
import { renderFieldsSimple, fieldWithButton, numericInputFieldWithDecimals } from '@shared/components/forms'
import { colors } from '@src/styles'
import { renderHeader } from '@components/forms'
import { composeValidators, isLessOrEqual, isGreaterThanZero, optionalCommaAmount, emptyValidator } from '@shared/helpers'
import styled from 'styled-components'
import { checkPromoCode, HttpClient } from '@src/service'
import { FormApi } from 'final-form'
import { BillingManifest } from '@src/service/services/billing'

interface Props {
    balance: number
    learnModalOpen: () => void
    remove: boolean
    setRemove: (remove: boolean) => void
    setCouponValue: (value: number) => void
    httpClient: HttpClient
    manifest?: BillingManifest
    form?: FormApi<any>,
    values?: any
}

const CashAndPromoWrap = styled.div`
    span {
        display: inline;
    }
    .openModal {
        color: ${colors.green};
        :hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

const NoteError = styled.span<any>`
    color: ${colors.errorRed} !important;
`

export const CashAndPromo = (props: Props) => {
    const [isValid, setIsValid] = React.useState<boolean>(true)
    const { values,
        balance,
        learnModalOpen,
        remove,
        setRemove,
        httpClient,
        setCouponValue,
        form,
        manifest } = props
    const hasError = values?.equaCash > balance
    const handleCouponApply = async () => {
        if (remove) {
            form?.change('couponCode', undefined)
            setRemove(false)
            setCouponValue(0)
            setIsValid(true)
        } else {
            const response = await checkPromoCode(httpClient)({}, { promoCode: values.couponCode })
            if (response?.promo) {
                const balance = (manifest?.total_in_cents || 0) / 100
                const value = ((response.promo.percentage || 1) * balance) / 100
                setIsValid(true)
                setCouponValue(value)
            } else {
                setIsValid(false)
            }
            setRemove(true)
        }
    }

    return (
        <CashAndPromoWrap>
            {renderHeader({ label: 'Spend Your Equa Cash' })}
            {renderFieldsSimple([
                {
                    name: 'equaCash',
                    label: 'Equa Cash',
                    required: false,
                    placeholder: 'Enter amount',
                    validate: composeValidators([
                        isLessOrEqual(balance || 0, ''),
                        isGreaterThanZero
                    ]),
                    component: numericInputFieldWithDecimals,
                    note: hasError ?
                        <NoteError>
                            You’ve exceeded your Equa Cash balance. Max available: {optionalCommaAmount(balance)} E$.
                        </NoteError>
                        :
                        <>Max available: {optionalCommaAmount(balance)} E$. Need to transfer more?
                            {' '}<span onClick={learnModalOpen} className='openModal'>Learn more</span>
                        </>
                },
            ])}
            {renderHeader({ label: 'Promo Code' })}
            {renderFieldsSimple([
                {
                    name: 'couponCode',
                    label: 'Promo Code',
                    required: false,
                    placeholder: 'Enter promo code, then apply',
                    validate: emptyValidator,
                    note: isValid ? '' : <NoteError>Incorrect Promo Code entered</NoteError>,
                    component: fieldWithButton({ buttonText: 'Apply', onClick: () => handleCouponApply(), removeText: remove, isValid }),
                },
            ])}
        </CashAndPromoWrap>
    )
}
