import * as React from 'react'
import { ComponentType } from 'react'
import { AppContext } from '@src/app'
import { HttpClient, Service } from '@src/service'

export interface ServiceProps {
  serviceOld: Service
  service: HttpClient
  httpClient: HttpClient
}

export interface MinimalServiceProps {
  service: HttpClient
}

export function withService<P>(WrappedComponent: ComponentType<P & ServiceProps>): ComponentType<P> {
  return (props: P) => (
    <AppContext.Consumer>
      {context => <WrappedComponent {...props} serviceOld={context.service!} service={context.service!.httpClient}
                                    httpClient={context.service!.httpClient} />}
    </AppContext.Consumer>
  )
}
