import CapTableArrow from '@image/table-arrow.svg'
import * as React from 'react'
import styled from 'styled-components'
import { Row, UseExpandedRowProps } from 'react-table'

export const StyledTableArrow = styled.span<any>`
  cursor: pointer;
  svg {
    fill: ${props => (props.shouldRotate ? props.theme.accent : props.theme.white)};
    transform: ${props => (props.shouldRotate ? 'rotate(90deg)' : 'rotate(0deg)')};
    transition: 300ms;
  }
`

export const expanderColumn = {
  id: 'expander',
  Cell: ({ row }: any) =>
    row.canExpand ? (
      <StyledTableArrow shouldRotate={row.isExpanded} {...row.getToggleRowExpandedProps()}>
        {<CapTableArrow />}
      </StyledTableArrow>
    ) : null,
}
