import { Paths } from '@logic'

declare var PROMO_ACTIVE: boolean

export function isPromo() {
  return PROMO_ACTIVE
}

export function subscriptionsPath() {
  return isPromo() ? Paths.promo : Paths.subscriptions
}
