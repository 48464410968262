import React from 'react'
import { emptyValidator, isFloat, isInt } from '@helpers/field-validators'
import {
  FieldGroup,
  selectFieldComponent,
  SelectOption,
  numericInputFieldWithCommas,
  conditionalFields,
  renderFieldsSimple,
  renderHeader,
  UploadHandler,
  textInputFieldComponent,
  dateField
} from '@components/forms'
import { NewContinuousVestingSchedule } from '@src/service'
import { AmountType, supportedMaxShares, VestmentTrigger } from '@logic'
import { formatToNumber } from '@modules/captable/utility'
import { StyledDiv } from './pool-form'
import { AbsoluteVestingEvent } from '@shared/components/absolute-vesting-event'
import { RelativeVestingEvent } from '@shared/components/relative-vesting-event'
import { handleCliffToggle, handleVestingDeselect, vestingScheduleOptions } from '../utility'
import { RadioWrap } from '@src/styles'
import { MultiBoxField } from '@shared/components/multibox'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'
import { nonZeroNumberWithOptionalCommas, normalizeDate } from '@shared/helpers'

interface Props {
  onUpload: UploadHandler
  values?: any
}

export const vestingFrequncyMaxNumber = 600
export const isVestingFrequency = isInt({ min: 1, max: vestingFrequncyMaxNumber }, 'Must be a positive number between 1 and 600')
export const isCliffMonths = isInt({ min: 0, max: 600 }, 'Must be a positive number between 1 and 600')
export const isCliffAmount = isFloat({ min: 0, max: supportedMaxShares }, `Must be a number between 0 and ${supportedMaxShares}`)

const vestingSideNote = (
  <>
    The vesting schedule determines the schedule of when employees acquire full ownership of the asset. These details will apply to all
    options created under this pool.{' '}
    {/* <a target="_blank" href="https://www.equa.global/2020/04/27/option-pool/">
      {' '}
      Read more
    </a> */}
  </>
)

export const vestingTriggerOptions: Array<SelectOption<VestmentTrigger>> = [
  {
    label: 'Anniversary',
    value: VestmentTrigger.anniversary,
  },
  {
    label: 'First Day of Month',
    value: VestmentTrigger.firstDayOfMonth,
  },
  {
    label: 'Last Day of Month',
    value: VestmentTrigger.lastDayOfMonth,
  },
]

export const amountTypeOptions: Array<SelectOption<AmountType>> = [
  {
    label: '%',
    value: AmountType.percentage,
  },
  {
    label: 'Fixed',
    value: AmountType.fixed,
  },
]

function vestingScheduleFields(parent: string): FieldGroup<NewContinuousVestingSchedule> {
  const firstFields = [
    {
      name: `${parent}.durationMonths`,
      label: 'Vesting Duration (months)',
      required: true,
      validate: isVestingFrequency,
      format: formatToNumber,
      component: numericInputFieldWithCommas,
      placeholder: 'Length of vesting schedule',
      onChange: (e: number) => {
        if (e <= vestingFrequncyMaxNumber || e === 0) return true
      },
    },
    {
      name: `${parent}.frequencyMonths`,
      label: 'Vesting Frequency',
      required: true,
      validate: isVestingFrequency,
      format: formatToNumber,
      placeholder: 'How often this vests',
      component: numericInputFieldWithCommas,
    },
    {
      name: `${parent}.vestsOn`,
      label: 'Vests on',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(vestingTriggerOptions, 'Vests on'),
    },
  ]

  const fieldsArray = conditionalFields(firstFields as any, true)
  return fieldsArray
}

function vestingCliffMonths(parent: string): FieldGroup<NewContinuousVestingSchedule> {
  const fields = [
    {
      name: `${parent}.cliffMonths`,
      label: 'Vesting Cliff (months)',
      required: true,
      validate: isCliffMonths,
      format: formatToNumber,
      placeholder: 'Enter vesting cliff',
      component: numericInputFieldWithCommas,
    },
  ]
  const fieldsArray = conditionalFields(fields, true)
  return fieldsArray
}
function vestingCliffAmount(parent: string): FieldGroup<NewContinuousVestingSchedule> {
  const fields = [
    {
      name: `${parent}.cliffAmount`,
      label: 'Amount Vested at Cliff',
      required: true,
      validate: isCliffAmount,
      format: formatToNumber,
      placeholder: 'Vesting cliff amount',
      component: numericInputFieldWithCommas,
    },
  ]
  const fieldsArray = conditionalFields(fields, true)
  return fieldsArray
}

function vestingCliffAmountType(parent: string): FieldGroup<NewContinuousVestingSchedule> {
  const fields = [
    {
      name: `${parent}.cliffAmountType`,
      label: 'Type',
      required: false,
      validate: emptyValidator,
      component: selectFieldComponent(amountTypeOptions, 'Type'),
    },
  ]
  const fieldsArray = conditionalFields(fields, true)
  return fieldsArray
}


function valueField(enabled: boolean): FieldGroup<any> {
  const fields = [
    {
      name: 'startingValue',
      label: 'Preview Total Amount',
      required: false,
      format: formatToNumber,
      placeholder: 'Enter Amount',
      validate: nonZeroNumberWithOptionalCommas(),
      component: numericInputFieldWithCommas,
    },
  ]
  const fieldsArray = conditionalFields(fields, enabled)
  return fieldsArray
}

export const VestingScheduleForm = (props: Props) => {
  const { values, onUpload } = props
  const anniversaryDate = values.startDate ? new Date(values.startDate).getDate() : 0

  React.useEffect(() => {
    if (values.startDate && values.vestingOptions.relative) {
      let events: any = []
      events = values.events.map((event: any) => {
        if (event.day == 0) {
          return { ...event, day: anniversaryDate }
        } else return event
      })
      const v = { ...values, events }
      Object.assign(values, v)
    }
  }, [values.startDate])


  const vestingFields = () => {
    if (values.vestingOptions.procedural) {
      return <>
        {renderFieldsSimple(vestingScheduleFields('vestingSchedule'))}
        {renderHeader({
          label: 'Vesting Cliff',
          index: 0,
          buttonText: 'cliff',
          buttonOnClick: () => handleCliffToggle(values),
          buttonType: 'toggle'
        })}
        {values?.cliff! && renderFieldsSimple(vestingCliffMonths('vestingSchedule'))}
        {values?.cliff! &&
          <>
            {renderHeader({
              label: 'Custom Amount Vested at Cliff',
              index: 0,
              buttonText: 'amount',
              buttonOnClick: () => handleCliffToggle(values),
              buttonType: 'toggle'
            })}
            {values?.amount! && <StyledDiv>
              {renderFieldsSimple(vestingCliffAmount('vestingSchedule'))}
              {renderFieldsSimple(vestingCliffAmountType('vestingSchedule'))}
            </StyledDiv>
            }
          </>
        }
      </>
    }
    else if (values.vestingOptions.absolute) {
      return <AbsoluteVestingEvent name='events' values={values} />
    }
    else {
      return <RelativeVestingEvent name='events' values={values} anniversaryDate={anniversaryDate} />
    }
  }
  const startDatePicker = React.useRef()

  function startDateField(enabled: boolean, req?: boolean): FieldGroup<any> {
    const fields = [
      {
        name: 'startDate',
        label: 'Preview Start Date',
        required: req ? true : false,
        placeholder: 'MM/DD/YYYY',
        note: 'The start date of the vesting schedule or award commencement.',
        ...dateField(startDatePicker),
      },
    ]
    const fieldsArray = conditionalFields(fields, enabled)
    return fieldsArray
  }

  const showDateAndValue = !values?.vestingOptions.absolute ? true : false
  const dateRequired = values?.vestingOptions.procedural ? true : false
  return <>
    {renderHeader({ label: 'Vesting Schedule Details', index: 0 })}
    <label>Schedule Type</label>
    <RadioWrap>
      {vestingScheduleOptions.map(o => {
        return <MultiBoxField
          name={`vestingOptions.${o.name}`}
          text={o.text}
          type='checkbox'
          styleType='radio'
          handleClick={() => handleVestingDeselect(values, o.name)} />
      })}
    </RadioWrap>
    {renderFieldsSimple([
      {
        name: 'name',
        label: 'Vesting Schedule Name',
        required: true,
        validate: emptyValidator,
        component: textInputFieldComponent,
        placeholder: 'Vesting schedule name',
      },
      ...startDateField(showDateAndValue, dateRequired),
      ...valueField(showDateAndValue)
    ], values.vestingOptions.procedural ? true : false)}
    {vestingFields()}
    <DocumentsAndNotes onUpload={onUpload} values={values} />
  </>
}
