import * as React from 'react'
import { PageProps, PaymentProfilesResponse } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadPaymentProfiles } from '@shared/helpers'
import { AddCardShared } from '../components/add-card-shared'

type Props = PageProps & PaymentProfilesResponse

const withData = withLoadingCachedMultiple<Props>(loadPaymentProfiles)

export const AddCardPage = withData(
    (props: Props) => {
        return <AddCardShared {...props} />
    }
)
