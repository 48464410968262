import * as React from 'react'
import { Component, ErrorInfo } from 'react'
import { Header } from './unconnectedHeader/header'
import { PageContent } from '@components/pages'
import { FlexCol } from '@src/styles'
import styled from 'styled-components'

interface Props {}

interface State {
  hasError: boolean
  error?: any
  info?: any
}

const ErrorContent = styled(FlexCol)`
  align-self: center;
  text-align: center;
  color: ${props => props.theme.white};
`

class AppErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: undefined,
      info: undefined,
    }
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({
      hasError: true,
      error,
      info,
    })
  }
  render() {
    if (this.state.hasError) {
      return (
        <>
          <Header />
          <PageContent>
            <ErrorContent>
              <h2>Something went wrong.</h2>
              <a href="/">Return Home</a>
            </ErrorContent>
          </PageContent>
        </>
      )
    }
    return this.props.children
  }
}

export default AppErrorBoundary
