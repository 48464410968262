import * as React from 'react'
import { getTransferType, Organization } from '@logic'
import { formatDateString, formatImageUrl, optionalCommaAmount } from '@shared/helpers'
import styled from 'styled-components'
import { GetTransactionHistoryResponse } from '@src/service/services/profile'
import { Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { colors, FlexRow } from '@src/styles'
import DownArrow from '@image/arrow-down.svg'
import UpArrow from '@image/arrow-up.svg'
import Invite from '@image/invite.svg'
import Referral from '@image/referral.svg'
import Subscription from '@image/subscription.svg'
import { nameAbbreviation } from '@shared/components/avatar'

interface Props {
    transactionRecords: GetTransactionHistoryResponse[]
    organizations: Organization[]
    userProfile?: any
    description?: React.ReactElement
}

const TransferHistoryWrap = styled.div<any>`
    
`

const TypeWrap = styled<any>(FlexRow)`
    svg {
        margin-right: 10px;
        path {
            fill: ${props => props.earned === 'true' ? colors.green : colors.errorRed};
        }
    }
`

const Source = styled<any>(FlexRow)`
    svg, img {
        margin-right: 10px;
    }
    img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
    }
`

export const ImagePlaceholder = styled<any>(FlexRow)`
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background: ${colors.green};
    justify-content: center;
    align-items: center;
    margin-right: 10px;
`

const Amount = styled.span<any>`
    font-family: 'NunitoSansBold';
    font-weight: 700;
    color: ${props => props.earned === 'true' ? colors.green : colors.errorRed};
    line-height: 24px;
`

const Balance = styled.span<any>`
    font-family: 'NunitoSansBold';
    font-weight: 700;
    line-height: 24px;
`

export const TransactionHistoryTable = (props: Props) => {
    const { transactionRecords, organizations, userProfile, description } = props
    const getSource = (type: number, text?: string, earned?: boolean, org?: Organization) => {
        switch (type) {
            case 1:
                return earned ? 'From My own profile' : 'To My own profile'
            case 2:
                return earned ? `From ${org?.name}` : `To ${org?.name}`
            case 3:
                if (text?.includes('@')) {
                    return `From email invitation - ${text}`
                } else if (text == 'obcl') return 'From Onboarding your Org'
                else return 'From Org referral link'
            case 4:
                return 'To annual subscription dues'
        }
    }
    const getImage = (type: number, text?: string, org?: Organization) => {
        switch (type) {
            case 1:
                return userProfile?.file?.hash ?
                    <img src={formatImageUrl(userProfile.file.hash)} />
                    :
                    <ImagePlaceholder>{nameAbbreviation(userProfile?.fullName)}</ImagePlaceholder>
            case 2:
                return org?.logo?.hash ?
                    <img src={formatImageUrl(org.logo.hash)} />
                    :
                    <ImagePlaceholder>{nameAbbreviation(org?.name || '')}</ImagePlaceholder>
            case 3:
                if (text?.includes('@')) {
                    return <Invite />
                } else return <Referral />
            case 4:
                return <Subscription />
        }
    }
    const records = transactionRecords?.map(record => {
        const typeText = getTransferType[record.type]
        const org = organizations?.find(org => org.id === record.source)
        const sourceText = getSource(record.type, record.source, record.earned, org)
        return {
            ...record,
            typeText,
            sourceText,
            org,
        }
    })
    const tableData = records || []
    const columns = [
        {
            Header: 'Date',
            accessor: 'created',
            Cell: ({ row }: CellProps<any>) => <span>{formatDateString(row.original.created)}</span>
        },
        {
            Header: 'Type',
            accessor: 'typeText',
            Cell: ({ row }: CellProps<any>) =>
                <TypeWrap earned={row.original.earned ? 'true' : 'false'}>
                    {row.original.earned ? <DownArrow /> : <UpArrow />}
                    {row.original.typeText}
                </TypeWrap>,
            className: 'left'
        },
        {
            Header: 'Source',
            accessor: 'sourceText',
            Cell: ({ row }: CellProps<any>) =>
                <Source>
                    {getImage(row.original.type, row.original.source, row.original.org)}
                    {row.original.sourceText}
                </Source>,
            className: 'left'
        },
        {
            Header: 'Memo',
            accessor: 'memo',
            className: 'left'
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            Cell: ({ row }: CellProps<any>) =>
                <Amount earned={row.original.earned ? 'true' : 'false'}>
                    {row.original.earned ? '+' : '-'}{optionalCommaAmount(row.original.amount)} E$
                </Amount>,
        },
        {
            Header: 'Total Balance',
            accessor: 'totalAmount',
            Cell: ({ row }: CellProps<any>) =>
                <Balance>
                    {optionalCommaAmount(row.original.totalAmount)} E$
                </Balance>,
        },
    ]

    return (
        <TransferHistoryWrap>
            <Table
                columns={columns}
                data={tableData}
                pageSize={100000000}
                withAllBorders={true}
                noFooter={true}
                description={description} />
        </TransferHistoryWrap>
    )
}
