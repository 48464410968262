import * as React from 'react'
import { OrganizationProps, PageProps } from '@logic'
import { CapTableSummary, OrganizationCapital, RecentActions } from './components'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganization, loadOrganizationStats } from '@helpers/loaders'
import { PageContent, PageContentHeader } from '@components/pages'
import { useDidUpdateEffect } from '@src/shared/hooks/useDidUpdateEffect'
import { PanelGrid } from '@components/panels/panels'
import { GetOrganizationStatsResponse, GetSecuritiesResponse } from '@src/service'

type Props = PageProps & GetSecuritiesResponse & GetOrganizationStatsResponse & OrganizationProps

const withData = withLoadingCachedMultiple<Props>(loadOrganizationStats, loadOrganization)

export const Dashboard = withData((props: Props) => {
  const { reload, organizationStats, organization, params } = props
  const shareholders = organizationStats.captableSummary || []
  const { user } = props
  const currentMember = user ? shareholders.filter(m => m.userId == user!.id).map(m => m.memberId)[0] : undefined
  useDidUpdateEffect(reload, [params.organization])

  return (
    <PageContent>
      <PageContentHeader title={organization?.name || ''} hideCrumbs={true} />
      <OrganizationCapital
        currentMember={currentMember}
        shareholders={shareholders}
        holderCount={organizationStats?.holderCount}
        totalUnits={organizationStats?.totalUnits}
        totalCashRaised={organizationStats?.totalCashRaised} />
      <PanelGrid columns={2}>
        <CapTableSummary
          organizationStats={organizationStats.captableSummary}
          organization={params.organization} />
        <RecentActions recentActions={[]} />
      </PanelGrid>
    </PageContent>
  )
})
