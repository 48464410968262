import { FieldErrors } from '@components/forms/field-errors'
import * as React from 'react'
import styled from 'styled-components'
import { required } from '@helpers/field-validators'
import { Validator } from '@helpers/validation-utility'
import { Transform } from '@helpers/util'
import { FieldNote, FlexCol, FlexRow, StyledH2, StyledP } from '@src/styles'
import { Field } from 'react-final-form'
import { colors, fontSizes, gapSizes, screenSizes } from '@src/styles/theme'
import { InvertedButton } from '../button'
import { ToggleSwitchField } from '../toggleSwitch'
import { getIcon } from '../tables'

export type FieldComponent = (data: any) => any

export interface BaseFieldDefinition {
  validate: Validator
  component: FieldComponent
}

export interface FieldDefinition<T> extends BaseFieldDefinition {
  name: string
  required?: boolean // Required logic can be handled by `validate` but a dedicated field can be useful metadata for helper functions
  asterisks?: boolean
  label: React.ReactElement | string
  placeholder?: string
  pattern?: string
  onChange?: () => any
  format?: (value: any, name: string) => any
  defaultValue?: any
  parse?: (value: any, name: string) => any
  disabled?: boolean
  fixed?: boolean
  noMargin?: boolean
  note?: React.ReactElement | string
}

export const inputSpacing = '24px'

export const InputCont = styled.div<any>`
  max-width: ${props => props.fixed && '628px'};
  margin-bottom: ${props => (props.label === '' || props.noMargin === 'true') ? '' : inputSpacing};
  input {
    margin-bottom: 0;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    font-weight: normal;
    font-size: 18px;
    letter-spacing: -0.166667px;
  }
  section label {
    width: 100%;
  }
  section label img {
    width: 100%;
  }
  @media (max-width: 750px) {
    input {
      font-size: 14px;
      ::placeholder {
        font-size: 14px;
      }
    }
  }
`

export const StyledLabel = styled.label<any>`
  color: ${props => props.hasErrors ? `${colors.errorRed} !important` : props.disabled && `${props.theme.disabled} !important`};
  font-weight: ${props => props.hasErrors && 'bold'};
  font-family: ${props => props.hasErrors && 'NunitoSansBold'};
  display: flex;
  align-items: flex-end;
  margin-bottom: 8px;
  .prefix {
    margin: 0 !important;
    color: ${colors.errorRed} !important;
  }
`

export const makeOptional = (validator: Validator) => (value: any) => {
  // prettier-ignore
  return value === undefined || value === null
    ? undefined
    : validator(value)
}

export const makeRequired = (validator: Validator) => (value: any) => {
  return required(value) || validator(value)
}

export const requiredOrOptional = (required: boolean) => (required ? makeRequired : makeOptional)

export type FieldGroup<T> = Array<FieldDefinition<T>>

export function renderField<T>(field: FieldDefinition<T>) {
  const {
    component,
    pattern,
    format,
    placeholder,
    label,
    name,
    required,
    asterisks,
    validate,
    onChange,
    defaultValue,
    parse,
    disabled,
    fixed,
    noMargin,
    note } = field
  const labelPrefix = required && asterisks != false ? '* ' : ''
  return (
    <Field
      pattern={pattern}
      format={format || ((value, name) => value)}
      parse={parse}
      placeholder={placeholder}
      name={name}
      defaultValue={defaultValue}
      validate={requiredOrOptional(required || false)(validate)}
      formatOnBlur={true}
    // {...testKey}
    >
      {(input: any) => {
        const fieldInfo = { ...input, onChange }
        const { meta } = fieldInfo
        const errors = Array.isArray(meta.error) ? meta.error : [meta.error]
        let hasErrors: boolean = false
        if (!meta.touched || errors.length === 0) hasErrors = false
        else {
          let errorItems = errors.map((e: any, i: number) => {
            if (e !== undefined && e !== null)
              return e
          })
          errorItems = errorItems.filter(errorItem => errorItem !== undefined)
          if (errorItems.length) hasErrors = true
        }
        const inputInfo = {
          ...input,
          onChange,
          hasErrors,
          preventDefault: (e: any) => e?.key === 'Enter' && e?.preventDefault(),
          disabled: disabled ? true : false
        }

        return (
          <InputCont label={label} fixed={fixed} name={label} noMargin={noMargin ? 'true' : 'false'}>
            {label !== '' && <StyledLabel hasErrors={hasErrors} disabled={disabled}>
              {required && <span className='prefix'>{labelPrefix}&nbsp;</span>}
              {label}
            </StyledLabel>}
            {component(inputInfo)}
            {note && <FieldNote hasErrors={hasErrors}>{note}</FieldNote>}
            <FieldErrors meta={fieldInfo.meta} />
          </InputCont>
        )
      }}
    </Field>
  )
}

const Fields = styled<any>(FlexRow)`
  align-items: flex-start;
  flex: 4;
`

const FieldsWrap = styled<any>(FlexCol)`
  width: 100%;
  align-items: flex-start;
  border-bottom: ${props => props.noBorder == 'true' ? 'none' : `1px solid ${colors.grayDivider}`};
  ${InputCont} {
    width: 100%;
  }
`
const SideFieldsWrap = styled<any>(FlexRow)`
  border-bottom: ${props => props.noBorder == 'true' ? 'none' : `1px solid ${colors.grayDivider}`};
`

export function renderFields<T = any>(fieldGroup: FieldGroup<T>, i: number) {
  return (
    <Fields key={i}>
      <FlexCol alignItems="stretch" flex={4}>
        {fieldGroup.map(renderField)}
      </FlexCol>
    </Fields>
  )
}

export function renderFieldsSimple<T = any>(fieldGroup: FieldGroup<T>, hideBorder?: boolean) {
  return <FieldsWrap noBorder={hideBorder ? 'true' : 'false'}>
    {fieldGroup.map(field => renderField(field))}
  </FieldsWrap>
}
export function renderSideFields<T = any>(fieldGroup: FieldGroup<T>, hideBorder?: boolean) {
  return <SideFieldsWrap noBorder={hideBorder ? 'true' : 'false'}>
    {fieldGroup.map(field => renderField(field))}
  </SideFieldsWrap>
}

export const HeaderInvertedButton = styled<any>(InvertedButton)`
  min-width: 120px;
  height: 32px;
  white-space: nowrap;
  &:hover {
    background: ${props => props.theme.button.warningI.hover} !important;
    border: 1px solid ${props => props.theme.button.primary.hover};
    color: ${props => props.theme.button.primary.hover};
    path {
      fill: ${props => props.theme.button.primary.hover};
    }
  }
  svg {
    height: auto !important;
    margin: 0 8px 0 0 !important;
    path {
        fill: ${colors.green};
    }
  }
`

const HeaderWrapper = styled<any>(FlexRow)`
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 24px;
  h2 {
    font-size: ${fontSizes.M};
    line-height: 32px;
  }
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
  }
`

interface FormHeaderProps {
  label: string
  index?: number
  buttonText?: string
  buttonOnClick?: any
  buttonType?: string
  noHeader?: boolean
}

export function renderHeader<T>({ label, index, buttonText, buttonOnClick, buttonType, noHeader }: FormHeaderProps) {
  return (
    <>
      <HeaderWrapper className="headerWrap">
        <StyledH2 style={{ margin: '40px 0 24px 0', padding: 0 }} key={index}>
          {label}
        </StyledH2>
        {buttonText && buttonType === 'toggle' ?
          <ToggleSwitchField name={buttonText} type="checkbox" handleClick={buttonOnClick} />
          :
          buttonText && <HeaderInvertedButton name={buttonText} onClick={buttonOnClick}>{getIcon(buttonText)}{buttonText}</HeaderInvertedButton>}
      </HeaderWrapper>
      {/* {!noHeader && <HorizontalLine style={{ marginTop: '10px', marginBottom: '15px' }} />} */}
    </>
  )
}

export function conditionalFields<T = any>(fields: Array<FieldDefinition<T>>, enabled: boolean): Array<FieldDefinition<T>> {
  return enabled
    ? fields
    : fields.map(field => (
      {
        ...field,
        label: '',
        required: false,
        component: () => undefined,
        note: undefined
      }
    )
    ) as any
}

export function requiredFields<T = any>(fields: Array<FieldDefinition<T>>, enabled: boolean): Array<FieldDefinition<T>> {
  return enabled ? fields.map(field => ({ ...field, required: true })) : fields
}

export const SideNote = styled(FlexCol)`
  align-items: flex-start;
  flex: 2;
  margin-right: ${gapSizes.L};
`

export const FieldGroup = styled<any>(FlexRow)`
  padding-top: ${props => props.noPadding ? '' : '60px'};
  align-items: flex-start;
`

export function renderFieldGroups<T = any>(fieldGroups: Array<FieldGroup<T>>) {
  return <FieldGroup>{fieldGroups.map(renderFields)}</FieldGroup>
}

export function renderFieldGroupsWithSideNote<T = any>(fieldGroups: FieldGroup<T>, sideNote: { title: string; desc?: any }, noPadding?: boolean) {
  return (
    <FieldGroup noPadding={noPadding}>
      <SideNote>
        <StyledP>{sideNote.title}</StyledP>
        <label>{sideNote.desc}</label>
      </SideNote>
      <FlexCol alignItems="stretch" flex={4}>
        {fieldGroups.map(renderField)}
      </FlexCol>
    </FieldGroup>
  )
}

export function prefixField<T = any>(prefix: string): Transform<FieldDefinition<T>> {
  return field => ({ ...field, name: `${prefix}.${field.name}` })
}

export function prefixFieldGroup<T = any>(prefix: string): Transform<FieldGroup<T>> {
  return group => group.map(prefixField(prefix))
}
