import * as React from 'react'
import styled from 'styled-components'
import { FlexCol, fontSizes, gapSizes, screenSizes } from '@src/styles'

export interface BlockInfo {
  value: number,
  selected: boolean,
  color?: string,
  name?: string,
  abb: string,
}

interface Props {
  colors?: string[]
  blocks: BlockInfo[]
  handleSelection?: any
  PopUp?: React.ReactElement
}

const BarWrapper = styled.div<any>`
  display: flex;
  max-width: 1280px;
  margin: 50px 0px 20px;
  @keyframes expand {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  .blocks {
    width: 100%;
    animation: expand 1.5s forwards;
    display: flex;
  }
  span {
      font-size: ${fontSizes.XXS};
      line-height: 19px;
  }
`

const Block = styled.div<any>`
  background-color: ${(props: any) => props.color && props.color};
  overflow: hidden;
  opacity: 1;
  transition: opacity, 0.3s ease;
  width: ${(props: any) => props.width && props.width};
  position: relative;
  height: 20px;
  transform: ${(props: any) => (props.selected ? 'scaleY(1.2)' : 'scaleY(1)')};
`

const StyledWrap = styled.div<any>`
  width: ${(props: any) => props.width && props.width};
  margin-right: 2px;
  .block {
      border-radius: ${props => props.isFirst ? '4px 0 0 4px' : props.isLast ? '0 4px 4px 0' : '0'};
  }
  &:last-child {
    margin-right: 0;
  }
`

const StyledInfo = styled<any>(FlexCol)`
  margin: ${gapSizes.S} 0 0 0;
  display: ${props => props.value < 5 && 'none'};
  @media (max-width: ${screenSizes.S}px) {
    display: ${props => props.value < 20 && 'none'};
  }
  @media (min-width: ${screenSizes.S}px) and (max-width: ${screenSizes.M}px) {
    display: ${props => props.value < 10 && 'none'};
  }
`

export const UserDashRainbowBar = (props: Props) => {
  const { colors, blocks } = props
  const total = blocks?.reduce((a, b) => a + b.value, 0)
  const mappedBlocks = blocks?.map(block => ({ ...block, value: block.value * 100 / total }))

  return (
    <BarWrapper>
      <div className="blocks">
        {mappedBlocks?.map((el, i: number) => {
          const isFirst = i === 0
          const isLast = i === mappedBlocks.length - 1
          return el.value > 0 &&
            <StyledWrap width={`${el.value}%`} isFirst={isFirst} isLast={isLast} selected={el.selected}>
              <Block
                key={i}
                color={el.color ? el.color : colors![i % colors!.length]}
                width={'100%'}
                selected={el.selected}
                // onClick={() => handleSelection(i)}
                className="block"
              />
              <StyledInfo alignItems="start" value={el.value}>
                <span>{el.name} - {el.abb} Holdings</span>
                <label>{`${el.value.toFixed(2)}%`}</label>
              </StyledInfo>
            </StyledWrap>
        })}
      </div>
    </BarWrapper>
  )
}