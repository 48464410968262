import { Hash, safeDivide } from '@helpers/util'
import { ExpandedSecurity } from '@src/service'

export enum ConvertibleInstrumentType {
  convertibleNote = '4b651b16-1c87-4595-9205-cc05a45664dd',
  startupsConvertibleNote = '21e5fbb2-10f2-477b-aeb1-b78a17d9596a',
  ycConvertibleNote = 'c80987bb-4f1d-40b4-9f4c-b90316180657',
  preMoneySafe = '1f1a1b3e-d010-4b49-8887-0a9bd0886680',
  startupsConvertibleSecurity = 'a899716a-de44-4311-b513-2fa4216105af',
  ycvcSafe = '3f5206ab-0e3a-4758-acf0-985673b109f3',
  postMoneySafe = '3dd20b57-f5a5-4998-85c0-8831add6dcc1',
  saft = 'a30b7fd8-d06c-459c-9321-494f3d744e2f',
  custom = 'efbfd246-9c6b-476a-a3cc-2dcdc86cd030'
}

export const getInstrumentType = {
  [ConvertibleInstrumentType.convertibleNote]: 'Convertible Note',
  [ConvertibleInstrumentType.startupsConvertibleNote]: '500 Startups Convertible Note',
  [ConvertibleInstrumentType.ycConvertibleNote]: 'YC Convertible Note',
  [ConvertibleInstrumentType.preMoneySafe]: 'Pre-Money SAFE',
  [ConvertibleInstrumentType.startupsConvertibleSecurity]: '500 Startups Convertible Security',
  [ConvertibleInstrumentType.ycvcSafe]: 'YCVC SAFE',
  [ConvertibleInstrumentType.postMoneySafe]: 'Post-Money SAFE',
  [ConvertibleInstrumentType.saft]: 'S.A.F.T',
  [ConvertibleInstrumentType.custom]: 'Custom Convertible Instrument',
} as any

export enum AccrualFrequency {
  daily = 1,
  monthly = 2,
  annual = 3,
}

export const getAccrualFrequency: { [key: string]: string } = {
  [AccrualFrequency.daily]: 'Daily',
  [AccrualFrequency.monthly]: 'Monthly',
  [AccrualFrequency.annual]: 'Annual',
}

export interface ConvertibleInstrument {
  hash: Hash
  accrualFrequency: AccrualFrequency
  authorizedRaiseAmount: number
  conversionDiscount?: number
  convertsTo: Hash
  earlyExitMultiple?: number
  estimatedConversionPrice?: number
  instrumentType: ConvertibleInstrumentType
  interestRate: number
  maturityDate: Date
  qualifiedFinancingAmount?: number
  valuationMax?: number
  valuationMin?: number
}

export const dateDiffInDays = (first: Date, second: Date) => {
  const a = Date.UTC(first.getFullYear(), first.getMonth(), first.getDate())
  const b = Date.UTC(second.getFullYear(), second.getMonth(), second.getDate())
  return Math.floor((b - a) / (1000 * 60 * 60 * 24))
}

export const diffMonths = (dt1: Date, dt2: Date) => {
  let nextMonth = dt1
  for (let i = 1; i < 1000; i++) {
    const newDate = new Date(Date.UTC(dt1.getFullYear(), dt1.getMonth() + (i), dt1.getDate()))
    if (newDate.getTime() > dt2.getTime()) {
      break
    }
    nextMonth = newDate
  }
  let diff = dateDiffInDays(dt1, nextMonth)
  return diff
}

export const diffYears = (dt1: Date, dt2: Date) => {
  let nextMonth = dt1
  for (let i = 1; i < 200; i++) {
    const newDate = new Date(Date.UTC(dt1.getFullYear() + (i), dt1.getMonth(), dt1.getDate()))
    if (newDate.getTime() > dt2.getTime()) {
      break
    }
    nextMonth = newDate
  }
  let diff = dateDiffInDays(dt1, nextMonth)
  return diff
}

export function optionalSecurityTreasury(security?: ExpandedSecurity): number | undefined {
  return security?.stats?.treasury
}

export function getSecurityTreasury(security: ExpandedSecurity): number {
  return security.stats.treasury
}

export function getOrganizationTotalUnits(securities: ExpandedSecurity[]): number {
  return securities.reduce((a, b) => a + b.shares, 0)
}

export function getSharePrice(usd: number, totalUnits: number, discount: number = 0): number {
  const value = safeDivide(usd, totalUnits)
  const returnValue = value - value * discount / 100
  return isNaN(returnValue) ? 0 : returnValue
}

export function convertToShares(usdAmount: number, price: number): number {
  return Math.ceil(safeDivide(usdAmount, price))
}

export function getSharePriceOrUndefined(usd?: number, totalUnits?: number, discount: number = 0): number | undefined {
  return typeof usd == 'number' && typeof totalUnits == 'number'
    ? getSharePrice(usd, totalUnits, discount)
    : undefined
}

export function convertToSharesOrUndefined(usd?: number, price?: number): number | undefined {
  return typeof usd == 'number' && typeof price == 'number'
    ? convertToShares(usd, price)
    : undefined
}

export function getAccrualStepsWithNegative(start: Date, end: Date, frequency: AccrualFrequency): number {
  switch (frequency) {
    case AccrualFrequency.daily:
      return dateDiffInDays(start, end) // return number days in date range
    case AccrualFrequency.monthly:
      return diffMonths(start, end) // return number of months in date range
    case AccrualFrequency.annual:
      return diffYears(start, end) // return number of years in date range
  }
}

export function getAccrualSteps(start: Date | string, end: Date | string, frequency: AccrualFrequency): number {
  const value = getAccrualStepsWithNegative(new Date(start), new Date(end), frequency)
  return value > 0
    ? value
    : 0
}

export function calculateSimpleInterest(steps: number, principle: number, rate: number = 0): number {
  const currentPrinciple = principle
  const interest = currentPrinciple * steps * (rate / 365) / 100
  return interest
}

export function calculateCompoundInterest(steps: number, principle: number, rate: number): number {
  let currentPrinciple = principle
  for (let i = 0; i < steps; i++) {
    currentPrinciple += currentPrinciple * rate / 100
  }
  // These values may be added together again by any code that calls this function
  // but it's still clearer when this function only returns the earned interest
  return currentPrinciple - principle
}

export function getInterestFromConvertible(
  convertibleInstrument: ConvertibleInstrument,
  issueDate: Date | string,
  value: number,
  current?: boolean,
  total?: boolean,
): number {
  const date = current ? new Date() : convertibleInstrument.maturityDate
  const interestSteps = getAccrualSteps(issueDate, date, total ? 1 : convertibleInstrument.accrualFrequency)
  return calculateSimpleInterest(interestSteps, value, convertibleInstrument.interestRate)
}

export function optionalInterestFromConvertible(
  convertibleInstrument?: ConvertibleInstrument,
  issueDate?: Date | string,
  endDate?: Date | string,
  value?: number,
  current?: boolean,
  total?: boolean,
): number | undefined {
  const date = current ? new Date() : convertibleInstrument?.maturityDate
  return convertibleInstrument && issueDate && typeof value == 'number' && date
    ? getInterestFromConvertible(convertibleInstrument, issueDate, value, current, total)
    : undefined
}

export interface MaxSharesInput {
  maxUsd: number
  valuationMin: number
  totalUnits: number
  discount: number
}

export function getMaxShares(
  {
    maxUsd,
    valuationMin,
    totalUnits,
    discount
  }: MaxSharesInput) {
  const minPricePerShare = getSharePrice(valuationMin, totalUnits, discount)
  return convertToShares(maxUsd, minPricePerShare)
}

export enum DynamicFields {
  maturity = 'maturity',
  fixedOwnership = 'fixedOwnership',
  conversionDiscount = 'conversionDiscount',
  interestRate = 'interestRate',
  seniorityTable = 'seniorityTable'
}
