import * as React from 'react'
import { PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAuthorizations, loadHolding } from '@helpers/loaders'
import { FlexRow, DetailsLabel, whiteColor, DetailsMaxWidth, FlexCol, HorizontalLine, Themes } from '@src/styles'
import styled from 'styled-components'
import { ExpandedHoldingResponse, GetAuthorizationsResponse } from '@src/service'
import { hasPermission, loadPermissions, PermissionProps } from '@shared/components/permissions'
import { BuiltInPermission } from '@shared/helpers/constants'
import { Uuid } from '@shared/helpers'

type Props = PageProps & GetAuthorizationsResponse & PermissionProps & ExpandedHoldingResponse

const withData = withLoadingCachedMultiple<Props>(loadPermissions, loadAuthorizations('shareholding'), loadHolding)

const ViewCertificateWrapper = styled.div`
  ${whiteColor}
  ${DetailsMaxWidth}
  .notes {
    div, span {
        color: ${({ theme }) => theme.selected == Themes.LIGHT ? '#262626' : '#9CA6AD'};
    }
  }
`

export const StyledLink = styled.span<any>`
  word-break: break-all;
  width: 100%;
  font-family: 'NunitoSans';
  line-height: 22px;
  margin: 0 0 8px 0;
  color: ${({ theme }) => theme.accent};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`

const StyledLabel = styled<any>(DetailsLabel)`
    ${whiteColor}
    margin: 0 0 8px 0;
`

export const DocumentsAndNotesPage = withData(
    (props: Props) => {
        const { params, authorizations, permissions, holding } = props
        const { organization } = params
        const isLink = hasPermission(permissions)(BuiltInPermission.viewGoverningDocuments)
        const docs = holding?.issuanceAgreement ? authorizations.concat(
            {
                ...holding.issuanceAgreement,
                documentTypeName: 'Issuance Agreement',
                document: { hash: holding.issuanceAgreement.hash, filename: holding.issuanceAgreement.filename }

            })
            : authorizations
        const openDocument = (doc?: Uuid, name?: string) => {
            if (doc && name) {
                const n = encodeURIComponent(name)
                window.open(`/api/v1/file/${doc}/content/${n}`)
            }
        }

        return (
            <PageContent>
                <PageContentHeader title="Documents & Notes" />
                <ViewCertificateWrapper>
                    {docs?.length > 0 ? docs.map((authorization, i: number) => (
                        <>
                            <div key={i}>
                                <StyledLabel>{authorization?.documentTypeName!}</StyledLabel>
                                <FlexRow justifyContent={'space-between'} alignItems={'flex-start'}>
                                    {!isLink ?
                                        <span>{authorization.document?.filename!}</span>
                                        :
                                        <StyledLink onClick={() => openDocument(authorization.document?.hash!, authorization.document?.filename!)}>
                                            {authorization.document?.filename!}
                                        </StyledLink>
                                    }
                                </FlexRow>
                                <FlexCol justifyContent={'space-between'} alignItems={'flex-start'} className='notes'>
                                    <DetailsLabel>Notes:</DetailsLabel>
                                    <span style={{ wordBreak: 'break-all' }}>{authorization.note!}</span>
                                </FlexCol>
                            </div>
                            <HorizontalLine style={{ margin: '24px 0', background: '#667883' }} />
                        </>
                    ))
                        :
                        <FlexRow justifyContent={'center'}>No documents or notes</FlexRow>
                    }
                </ViewCertificateWrapper>
            </PageContent>
        )
    }
)
