import { AnyAction, Reducer } from 'redux'
import { ActionType } from '../actions'
import { MyReferralState } from '../types/types'

// @ts-ignore
export const myReferralReducer: Reducer<MyReferralState, AnyAction> = (state = { refData: {} }, action: any) => {
  switch (action.type) {
    case ActionType.GET_GOOGLE_CONTACT_START:
      return { ...state, isLoadingGoogleContacts: true }
    case ActionType.GET_GOOGLE_CONTACT_SUCCESS: {
      return { ...state, googleContacts: action.data, isLoadingGoogleContacts: false }
    }
    case ActionType.GET_GOOGLE_CONTACT_FAILURE:
      return { ...state, isLoadingGoogleContacts: false }
    case ActionType.GET_ALL_SCRATCH_CARDS_SUCCESS: {
      return { ...state, scratchCardList: action.data }
    }
    case ActionType.GET_REFERRAL_DATA_SUCCESS: {
      return { ...state, referralData: action.data }
    }
    case ActionType.INVITED_FRIENDS_SUCCESS: {
      return { ...state, invitedFriendList: action.data }
    }
    case ActionType.SCRATCH_CARDS_SUCCESS: {
      return { ...state, scratchCardsResponse: action.data }
    }
    // case ActionType.INVITED_FRIENDS_SUCCESS: {
    //   return { ...state, inviteResponse: action.data }
    // }
    case ActionType.SUBMIT_INFORMATION_SUCCESS: {
      return { ...state, submitInfoResponse: action.data }
    }
  }
  return state
}
