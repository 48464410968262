import * as React from 'react'
import { colors, FieldPanel, FlexRow, InputGroup, Themes } from '@src/styles'
import {
    FieldGroup,
    numericInputFieldComponent,
    percentageFieldComponent,
    renderFieldsSimple,
    renderHeader,
    selectFieldComponent,
} from '@components/forms'
import Cross from '@image/close-default.svg'
import { emptyValidator, isMonth, nextInternalId } from '@shared/helpers'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import { CancelButton } from './button'
import _ from 'lodash'

interface StateProps {
    name: string
    values: any
    anniversaryDate: number
}

const getDayOptions = (month: any, anniversaryDate: number) => {
    const year = new Date().getFullYear()
    const days = new Date(year, month, 0).getDate()

    const daysArray = _.range(1, days + 1, 1)
    const all = ['Last day of month', 'Anniversary Date'].concat(daysArray as any)
    const daysOptions = month ? all?.map(d => ({
        label: `${d}`, value: typeof d === 'string' ?
            d == 'Anniversary Date' ? anniversaryDate : daysArray.length : d
    })) : []
    return daysOptions
}

function relativeFields(name: string, month: any, anniversaryDate: number): FieldGroup<any> {
    return [
        {
            name: `${name}.month`,
            label: 'Month',
            required: true,
            placeholder: 'Enter relative month',
            validate: emptyValidator,
            component: numericInputFieldComponent,
        },
        {
            name: `${name}.day`,
            label: 'Day',
            required: true,
            placeholder: 'Select...',
            validate: emptyValidator,
            component: selectFieldComponent(getDayOptions(month, anniversaryDate), 'Day'),
        },
    ]
}

const AddEventBtn = styled<any>(CancelButton)`
  margin-top: 24px;
  color: ${colors.green} !important;
  height: 48px;
  line-height: 24px;
  border: none;
  background: transparent !important;
  &:hover {
    color: ${(props) => props.theme.white} !important;
    text-decoration: underline;
  }
`

const EventHeader = styled<any>(FlexRow)`
  margin-bottom: 24px;
  span {
    margin: 0 !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-family: 'NunitoSansSemiBold';
  }
`

const CrossWrap = styled<any>(FlexRow)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.buttonHover};
    path {
      fill: ${(props) =>
        props.theme.selected !== Themes.LIGHT
            ? props.theme.white
            : colors.white};
    }
  }
  &:active {
    background: ${(props) => props.theme.buttonActive};
  }
`

const StyledRoot = styled.div<any>`
  width: 100%;
  text-align: start;
  border-bottom: 1px solid ${colors.grayDivider};
`

type Props = StateProps

export const RelativeVestingEvent = (props: Props) => {
    const { name, values, anniversaryDate } = props
    const deleteField = (fields: any, index: number) => {
        const value = fields.value?.filter((v: any, i: number) => index === i)?.map(s => ({ ...s, isRemoved: true }))[0]
        fields.update(index, value)
    }

    const Fields = (
        name: string,
        fields: any,
        key: number,
        isLast: boolean,
        i: number,
        eventKey: number,
    ) => {
        return (
            <FieldPanel isLast={isLast} key={key}>
                <EventHeader justifyContent={'space-between'}>
                    <span>Vesting Event {eventKey}</span>
                    <CrossWrap onClick={() => deleteField(fields, i)}>
                        <Cross className='cross' />
                    </CrossWrap>
                </EventHeader>
                <InputGroup>
                    {renderFieldsSimple(relativeFields(name, fields?.value[i]?.month, anniversaryDate), true)}
                </InputGroup>
                {renderFieldsSimple([
                    {
                        name: `${name}.rValue`,
                        label: 'Percentage Amount',
                        required: true,
                        placeholder: 'Enter % Amount',
                        validate: emptyValidator,
                        component: percentageFieldComponent,
                    }
                ], true)}
            </FieldPanel>
        )
    }
    return (
        <>
            <FieldArray name={name || 'events'}>
                {({ fields, meta }) => {
                    const addToField = () => fields.push({ key: nextInternalId() })
                    let eventKey: number = 0
                    if (fields?.length! > 0) fields.value.sort((a, b) => a.month && b.month && a.month - b.month)
                    const eventsArray =
                        fields?.length! > 0
                            ? fields.map((name: string, i: number) => {
                                const key = fields?.value[i]?.key ? fields.value[i].key : i
                                const isLast = i === fields?.length! - 1
                                const removed = fields?.value[i]?.isRemoved
                                if (!removed) eventKey++
                                return !removed ? Fields(name, fields, key, isLast, i, eventKey) : null
                            })
                            : []
                    const currentFields = eventsArray.filter(e => e)

                    React.useEffect(() => {
                        if (fields?.length === 0) {
                            addToField()
                        }
                    }, [])
                    return (
                        <StyledRoot>
                            {renderHeader({
                                label: 'Vesting Events',
                                index: 0,
                                buttonText: 'Add',
                                buttonOnClick: () => addToField()
                            })}
                            {eventsArray}
                            {currentFields?.length > 0 && (
                                <AddEventBtn onClick={(e) => { e.preventDefault(), addToField() }}>
                                    + Add Event
                                </AddEventBtn>
                            )}
                        </StyledRoot>
                    )
                }}
            </FieldArray>
        </>
    )
}
