import * as React from 'react'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import styled from 'styled-components'
import { FlexCol, FlexRow, screenSizes, semiBoldStyle } from '@src/styles'
import MoneyTransfer from '@image/money-transfer-24x24.svg'
import { SolidButton } from '@shared/components/button'
import { optionalCommaAmount } from '@shared/helpers'

interface Props {
    from: string
    to?: string
    amount: number
    balance: number
    onSubmit: any
    forOrg?: boolean
}

const ConfirmTransferWrap = styled<any>(FlexCol)`
  align-items: flex-start;
  color: ${props => props.theme.white} !important;
  width: 100%;
  border-radius: 8px;
  div {
      width: 100%;
  }
`
const Info = styled.ul<any>`
  padding: 8px 12px;
  border-radius: 4px;
  background: ${({ theme }) => theme.modalOptionsBack};
  border: 1px solid #CEDAE1;
  margin: 24px 0;
  list-style-position: inside;
  width: 100%;
  div {
      padding: 10px;
  }
  span {
      position: relative;
      left: -8px;
  }
`

const ModalTitleWithIcon = styled<any>(FlexRow)`
  svg {
      margin-right: 16px;
  }
`

const TransferInfo = styled<any>(FlexCol)`
  align-items: flex-start;
  div {
      margin-bottom: 8px;
  }
  div:last-child { 
    border-top: 1px solid rgba(206, 218, 225, 0.3);
    padding: 8px 0 0 0;
    margin: 0;
  }
`

const Note = styled<any>(FlexRow)`
    margin-top: 24px;
`

const StyledHead = styled.h6<any>`
  ${semiBoldStyle}
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 24px 0;
`

export function confirmTransferModal(props: Props) {
    const { from, to, amount, onSubmit, balance, forOrg } = props
    const newBalance = balance - amount
    const ConfirmTransferComponent = () => {
        return (
            <ConfirmTransferWrap>
                <span className='title'>You are about to transfer the following:</span>
                <Info>
                    <div>
                        <li><span>{amount + ' E$' + ' from ' + from + ' to ' + to}</span></li>
                    </div>
                </Info>
                <TransferInfo>
                    <StyledHead>Transfer Ledger</StyledHead>
                    <FlexRow justifyContent={'space-between'}>
                        <span>{forOrg ? 'Company' : 'Profile'} Name</span>
                        <span>{from}</span>
                    </FlexRow>
                    <FlexRow justifyContent={'space-between'}>
                        <span>Current Balance</span>
                        <span>{optionalCommaAmount(balance)} E$</span>
                    </FlexRow>
                    <FlexRow justifyContent={'space-between'}>
                        <span>Transaction Amount</span>
                        <span>{optionalCommaAmount(amount)} E$</span>
                    </FlexRow>
                    <FlexRow justifyContent={'space-between'}>
                        <span>New Balance</span>
                        <span>{optionalCommaAmount(newBalance)} E$</span>
                    </FlexRow>
                </TransferInfo>
                <Note>In order to undo this transfer,
                    you will have to transfer this Equa Cash back from this receiving organization.
                    Continue with this transfer?</Note>
            </ConfirmTransferWrap>
        )
    }

    return newBasicModalBundle({
        title: <ModalTitleWithIcon><MoneyTransfer />Are you sure?</ModalTitleWithIcon>,
        body: <ConfirmTransferComponent />,
        max: '724px',
        align: 'center',
        buttons: [
            {
                text: 'Yes',
                onActivate: onSubmit,
                componentType: SolidButton,
            },
        ]
    })
}
