import { genericSum } from '@helpers/util'
import { Holding } from 'service'

export interface HoldingsTotals {
    quantity: number
    investment: number
    currentValue: number
    price: number
    return: number
}

export const sumQuantity = genericSum<Holding>(s => s.value)
export const sumInvestment = genericSum<Holding>(s => s.investment!)
export const sumCurrentValue = genericSum<Holding>(s => s.current!)
export const sumPricePerUnit = genericSum<Holding>(s => s.pricePerUnit!)
export const sumReturn = genericSum<Holding>(s => s.return!)
export function getHoldingsTotals(holdings: Holding[]): HoldingsTotals {
    return {
        quantity: sumQuantity(holdings),
        investment: sumInvestment(holdings),
        currentValue: sumCurrentValue(holdings),
        price: sumPricePerUnit(holdings),
        return: sumReturn(holdings),
    }
}

export enum HoldingRecordType {
    draft = '727a4fb5-7bee-439a-84ee-f1770d40aa34',
    convertibleInstrument = '48a3550f-9cd7-4b0b-a9da-202384c8f6ce',
    convertibleNote = 'e80eab0e-540b-4b98-93b7-d645259c3e5d',
    converted = 'e7b5c290-058b-4690-89c0-750bb9f95e50',
    pool = '79e89ac2-324c-482a-b6ba-8cbd54d1ae34',
    option = 'eaee6ab1-a51e-4e37-b618-53a6be772b38',
    purchased = 'f411e55c-bfd6-4d13-9f27-bb4b83a68bde',
    orgAsset = 'ce8e1323-5387-4b36-9c20-615443457262',
    userAsset = 'dcdfcc9a-49a4-4cdd-aba9-7b77f4c96202'
}
