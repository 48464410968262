import * as React from 'react'
import { MemberProps, organizationMemberPath, PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAddresses, loadMember, loadRoles } from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { MemberForm } from '@modules/team-members/components/member-form'
import { EditableMemberFields, InitialMemberFormValues } from '@modules/team-members/types'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import {
  createAddress,
  deleteAddress,
  editAddress,
  editMember,
  GetAddressesResponse,
  GetRolesResponse,
  NewAddressRequest1,
  UpdateAddressRequest1
} from '@src/service'
import { getQueryParams } from '@helpers/util'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions } from '@components/permissions'
import { MemberWrap } from './new-member-page'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'

type Props = PageProps & MemberProps & GetRolesResponse & GetAddressesResponse

const withData = withLoadingCachedMultiple<Props>(
  loadMember,
  loadPermissions,
  loadRoles,
  loadAddresses('member'),
)

export const EditMemberPage = withData(
  withPermissions([BuiltInPermission.editMembers])((props: any) => {
    const { memberRecord, navigate, params, service, roles, addresses, httpClient } = props
    const mapAddresses = addresses?.map(a => {
      const groups = a.groups?.map(g => g.id)
      return { ...a, groups }
    })
    const isIndividual = memberRecord?.isIndividual ? true : false

    const initialValues: InitialMemberFormValues = {
      ...memberRecord,
      address: mapAddresses,
      types: {
        individual: isIndividual,
        organization: !isIndividual
      },
    }
    const { member, organization } = params

    const onSubmit = async (values: EditableMemberFields) => {
      const addressesValues = values?.address?.map((address: any) => {
        const newAddress = { ...address }
        delete newAddress.key
        delete newAddress.format
        delete newAddress.created
        delete newAddress.content
        delete newAddress.context
        return newAddress
      })
      const memberRequest = {
        fullName: values?.fullName!,
        email: values?.email!,
        title: values?.title!,
        roles: values?.roles!,
        phone: values?.phone!,
        user: values?.user!,
        dateOfBirth: values?.dateOfBirth!,
        isIndividual: values.types.individual ? true : false
      }

      const response = await editMember(service)(organization, member, memberRequest)
      if (response) {
        if (addressesValues && addressesValues.length > 0) {
          addressesValues.forEach(async (address: any) => {
            if (address.isRemoved && address.id) {
              await deleteAddress(httpClient)({ entity: member, address: address.id })
            }
            if (!address.isRemoved) {
              if (address.id) {
                const editAddRequest = {
                  ...address,
                  groups: address?.groups ? address.groups : []
                } as UpdateAddressRequest1
                await editAddress(httpClient)({ entity: member, address: address.id }, editAddRequest)
              } else {
                const addRequest = {
                  ...address,
                  groups: address?.groups ? address.groups : []
                } as NewAddressRequest1
                await createAddress(httpClient)({ entity: member }, addRequest)
              }
            }
          })
        }
      }
      const parent = getQueryParams().parent
      if (parent) {
        navigate(parent)
      } else {
        navigate(organizationMemberPath, { organization, member })
      }
    }

    const parent: BaseLinkProps = { path: organizationMemberPath, args: { organization, member } }
    return (
      <MemberWrap>
        <PageContent>
          <PageContentHeader title={`Edit Member ${memberRecord.fullName}`} parent={parent} />
          <CommonForm onSubmit={onSubmit} initialValues={initialValues} submitText="Save Member" mutators={{ ...arrayMutators }}>
            <MemberForm organization={organization} roles={roles} />
          </CommonForm>
        </PageContent>
      </MemberWrap>
    )
  })
)
