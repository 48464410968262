import * as React from 'react'
import { Link } from 'react-router-dom'
import { Paths } from '@logic'
import { CenteringContainer, StyledH2 } from '@src/styles'

export function Unauthorized() {
  return (
    <CenteringContainer>
      <StyledH2>You do not have permission to access this page</StyledH2>
      <div>
        <Link to={Paths.userDashboard}>Home</Link>
      </div>
    </CenteringContainer>
  )
}
