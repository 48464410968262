import * as React from 'react'
import { createRolePath, MembersProps, PageProps } from '@logic'
import { PageContent } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { FlexCol, fontSizes, screenSizes, whiteColor } from '@src/styles'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { loadMembers, loadRoles } from '@helpers/index'
import { Tabs } from '@shared/components/tabs/tabs'
import { PanelGrid } from '@shared/components/panels/panels'
import { PanelWrapper, RoleName, RolesPanel, RolesPanelContent } from '@shared/components/panels/panel-roles'
import { PathLink } from '@shared/components/navigation'
import { getRolesFooterBanner, tabs } from '../utility'
import { RolesWrapper } from './create-role-page'
import { GetRolesResponse } from '@src/service'
import { Roles } from '@shared/components/forms'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetRolesResponse & MembersProps & ThemeProps<any>

const withData = withLoadingCachedMultiple<Props>(
    loadRoles,
    loadMembers
)

const Wrapper = styled<any>(FlexCol)`
  ${whiteColor}
  align-items: flex-start;
  margin-top: 24px;
  p {
    color: ${props => props.theme.label};
    font-size: ${fontSizes.XS};
    margin: 0;
  }
  h5 {
    font-size: ${fontSizes.S};
    line-height: 25px;
    font-weight: 300;
  }
`

const StyledPanelGrid = styled<any>(PanelGrid)`
  width: 100%;
  grid-gap: 24px;
  @media (max-width: ${screenSizes.XL}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${screenSizes.S}px) {
    grid-template-columns: 1fr;
  }
`

const StyledSub = styled.span<any>`
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
`

const PanelContentWrapper = styled<any>(RolesPanelContent)`
  padding: 28px 32px 68px 32px;
`

const RolesHeader = styled.h5<any>`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: 'NunitoSansSemiBold';
    margin: 0;
`

export const RolesPage = withData(withTheme(withPermissions([BuiltInPermission.viewMembers])((props: Props) => {
    const { params, navigate, roles, members, theme } = props
    const { organization } = params
    const filtered = roles?.filter(r => r.id !== Roles.admin)
    const admin = roles?.filter(r => r.id === Roles.admin)
    const sortedRoles = admin.concat(filtered?.sort((a, b) => {
        return a.name.localeCompare(b.name)
    }))

    return (
        <RolesWrapper>
            <Tabs tabs={tabs} title={'Roles & Permissions'} params={params} />
            <PageContent>
                <RolesHeader>Manage Your Roles</RolesHeader>
                <Wrapper>
                    <StyledSub>Click on the role below to see what permissions and members are associated with it. </StyledSub>
                    <StyledPanelGrid columns={3}>
                        <RolesPanel roles={sortedRoles} navigate={navigate} members={members} organization={organization} />
                        <PathLink path={createRolePath} args={{ organization }}>
                            <PanelWrapper>
                                <PanelContentWrapper>
                                    <FlexCol alignItems={"flex-start"}>
                                        <RoleName>
                                            + Create New Role
                                        </RoleName>
                                        <span className="roleDesc">Create a New, Custom Role From Scratch</span>
                                    </FlexCol>
                                </PanelContentWrapper>
                            </PanelWrapper>
                        </PathLink>
                    </StyledPanelGrid>
                </Wrapper>
            </PageContent>
            <img src={getRolesFooterBanner(theme?.selected!)} alt="rolesFooter" />
        </RolesWrapper>
    )
})))
