import * as React from 'react'
import { PageProps, Paths, welcomePath } from '@logic'
import styled from 'styled-components'
import { LoginSignButton } from '@components/button'
import { withRouter } from 'react-router'
import { History } from 'history'
import ThankYouLogo from '@image/thank-you.svg'
import { StyledAuth, StyledAuthForm, StyledTopHeader } from '../style'

const StyledThankYou = styled.div`
  text-align: center;
  label {
    display: inline-block;
    margin-bottom: 10px;
  }
  svg {
    margin-bottom: 24px;
  }
`

const ThankYouComponent = (props: PageProps) => {
  const { navigate } = props

  return (
    <StyledAuth>
      <StyledAuthForm>
        <StyledThankYou>
          <div><ThankYouLogo /></div>
          <StyledTopHeader>
            Thank You!
          </StyledTopHeader>
          <label>Your account has been successfully verified!</label>
          <br />
          <LoginSignButton onClick={() => navigate(welcomePath)}>OPEN APP</LoginSignButton>
        </StyledThankYou>
      </StyledAuthForm>
    </StyledAuth>
  )
}

export const ThankYou = withRouter(ThankYouComponent)
