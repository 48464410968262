import * as React from 'react'
import { ShareholdingResponse, captableCertificatePath, organizationMemberPath } from '@logic'
import {
  formatDateString,
  Hash,
  percentageString,
  roundFloat,
  toCommaFloat,
} from '@helpers/util'
import {
  formatCurrency,
  formatSecurityName,
  formatSecurityAbbreviation,
  getSecurityTypeTotals,
} from '@helpers/shareholdings'
import styled, { css } from 'styled-components'
import { CompanyType } from '@helpers/constants'
import { CertificateLink } from '@modules/captable/components/certificate-link'
import { rgba } from 'polished'
import {
  RowsState,
  Table,
  TableRow,
  isRowSelected,
  TableRowProps,
  SortRowsState,
  ColumnWithFooter,
} from '@components/tables/table'
import { FlexCol, FlexRow, StyledCertificateImage } from '@src/styles'
import { CellProps, Column } from 'react-table'
// @ts-ignore
import CertificateImage from '@image/certificate-image.jpg'
import { editUserState, ExpandedHolding, ExpandedSecurity } from '@src/service'
import CapTableArrow from '@image/table-arrow.svg'
import { StyledTableArrow } from '@shared/components/tables'
import { advancedTableRow } from '@components/tables'
import { CollapsibleRow, StyledTd } from './captable-collapsible-row'
import { PathLink } from '@shared/components/navigation'
import { optionalUsdString } from '@modules/convertibles/components'
import { isHolding } from '../utility'

interface Props {
  shareholders: any[]
  shareholdings: ShareholdingResponse[]
  companyType: CompanyType
  selectedRows: RowsState
  expandedRows: RowsState
  toggleExpandedRows: (key: number) => void
  securities: ExpandedSecurity[]
  groupBy: number
  colorsList: string[]
  holdings: ExpandedHolding[]
  organization: string
  userState: any
  httpClient: any
}

interface CompanyColumnConfig {
  accessor: string
  header: string
}

export const CertificateRow = styled<any>(TableRow)`
  &,
  a {
    color: ${props => props.voided && rgba(props.theme.white, 0.35)};
  }
  > td {
    a:after {
      content: '-Cancelled';
      display: ${props => (props.voided ? 'initial' : 'none')};
      pointer-events: all;
    }
  }
  img {
    opacity: ${props => props.voided ? '0.35' : '1'};
  }
`

const capTableWrapper = css`
  th {
    white-space: nowrap;
  }
`

export const RainbowChips = styled.div<any>`
  background-color: ${(props: any) => props.color && props.color};
  border-radius: 3px;
  width: 6px;
  height: 30px;
  margin-right: 7px;
`

const ExpandWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Wrap = styled.div`
  ${capTableWrapper}
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 5px;
  div {
    table { 
      td {
        white-space: nowrap;
      }
    }
  }
`

const LeftTable = styled.div`
table {
  width: 100px;
  min-width: auto;
  }
`

const RightTable = styled<any>(Table)`
  th {
    text-align: right !important;
  }
`

const DivisionCell = styled.td<any>`
  background-color: ${props => props.solid ? '' : props.theme.secondary};
  width: 5px;
  padding: 0 !important;
  border: none !important;
`

function getColumnId(column: Column): string {
  if (column.id) return column.id
  if (typeof column.accessor === 'string')
    return column.accessor

  throw Error('Cannot determine column id')
}

function diffColumns(columnGroups: Column[][]): string[][] {
  const groupedIds = columnGroups.map(group => group.map(getColumnId))
  const allIds = [...new Set(groupedIds.reduce((a, b) => a.concat(b)))]
  return groupedIds.map(ids => allIds.filter(id => !ids.includes(id)))
}

function hiddenColumn<T extends object = any>(name: string): Column<T> {
  return {
    accessor: name,
  } as any
}

export const CapTableTable = (props: Props) => {
  const [nestedCollapsed, setNestedCollapsed] = React.useState<number[]>([])
  // const [sortCount, setSortCount] = React.useState<any>({})
  const {
    shareholders,
    shareholdings,
    selectedRows,
    securities,
    expandedRows,
    toggleExpandedRows,
    groupBy,
    colorsList,
    holdings,
    userState,
    httpClient,
    organization
  } = props
  const [sorts, setSorts] = React.useState<SortRowsState>([])

  const shareholderData = shareholders ? shareholders.map(shareholder => {
    const holdings = shareholder.holdings.filter((h: any) => isHolding(h.holdingType))
    const shareholdings = shareholder.holdings.filter((h: any) => !isHolding(h.holdingType))
    const holdingSecurity = getSecurityTypeTotals(holdings)
    const shareholdingSecurity = getSecurityTypeTotals(shareholdings)
    const hidden = !shareholder.inTable

    return {
      ...shareholder,
      holdingSecurity,
      shareholdingSecurity,
      shareholdings,
      holdings,
      hidden,
      subRows: [
        ...holdings,
        ...shareholdings,
      ]
    }
  }) : []

  let instrumentsAndPools: any = []
  const uniqueValuesSet = new Set()
  holdings.forEach((h: any) => {
    instrumentsAndPools = instrumentsAndPools.concat({ name: h.instrumentName || h.poolName, id: h.parent })
  })

  const filteredHoldings = instrumentsAndPools.filter((obj: any) => {
    // check if name property value is already in the set
    const isPresentInSet = uniqueValuesSet.has(obj.id)
    // add name property value to Set
    uniqueValuesSet.add(obj.id)
    // return the negated value of
    // isPresentInSet variable
    return !isPresentInSet
  }).map((obj: any) => {
    const filterHoldings = holdings.filter(arg => arg.parent === obj.id && !arg.voided)
    const invested = filterHoldings?.reduce((a, b) => a + b.capitalContribution, 0)
    const fullyDiluted = filterHoldings?.reduce((a, b) => a + b.fullyDiluted, 0)
    const fdPer = filterHoldings?.reduce((a, b) => a + b.fullyDilutedPercentage, 0)
    const outPer = filterHoldings?.reduce((a, b) => a + b.outstandingPercentage, 0)
    const onlyHoldings = shareholderData.map(d => {
      const holdings = d.holdings.filter(s => s.parent == obj.id)?.map(s => ({ ...s, notDraft: true }))
      const notVoided = holdings.filter(s => !s.voided)
      const memberInvestment = notVoided?.reduce((a, b) => a + b.capitalContribution, 0)
      const memberFd = notVoided?.reduce((a, b) => a + b.fullyDiluted, 0)

      return {
        ...d,
        shareholdings: [],
        holdings,
        memberInvestment,
        memberOutstanding: 0,
        memberFd,
      }
    })?.filter(holder => holder.holdings.length > 0)

    return {
      ...obj,
      invested,
      outstanding: 0,
      fullyDiluted,
      fdPer,
      outPer,
      subRows: onlyHoldings
    }
  })?.filter(hol => hol.subRows.length > 0)

  const securityTypeData = securities.map(security => {
    const filteredShareholdings = shareholdings.filter(arg => arg.equity === security.hash && !arg.voided)
    const invested = filteredShareholdings?.reduce((a, b) => a + b.capitalContribution, 0)
    const outstanding = filteredShareholdings?.reduce((a, b) => a + b.value, 0)
    const fullyDiluted = filteredShareholdings?.reduce((a, b) => a + b.fullyDiluted, 0)
    const fdPer = filteredShareholdings?.reduce((a, b) => a + b.fullyDilutedPercentage, 0)
    const outPer = filteredShareholdings?.reduce((a, b) => a + b.outstandingPercentage, 0)
    const onlyShareholdings = shareholderData.map(d => {
      const shareholdings = d.shareholdings.filter(s => s.equity == security.hash)
      const notVoided = shareholdings.filter(s => !s.voided)
      const memberInvestment = notVoided?.reduce((a, b) => a + b.capitalContribution, 0)
      const memberOutstanding = notVoided?.reduce((a, b) => a + b.value, 0)
      const memberFd = notVoided?.reduce((a, b) => a + b.fullyDiluted, 0)

      return {
        ...d,
        holdings: [],
        shareholdings,
        memberInvestment,
        memberOutstanding,
        memberFd,
      }
    })?.filter(holder => holder.shareholdings.length > 0)

    return {
      ...security,
      invested,
      outstanding,
      fullyDiluted,
      fdPer,
      outPer,
      isEquity: true,
      subRows: onlyShareholdings
    }
  })?.filter(sec => sec.subRows.length > 0)

  React.useEffect(() => {
    const found = userState ? userState[`capSort|${organization}|${groupBy}`] : null
    if (found) {
      setSorts(found)
    }
    else if (!groupBy) {
      setSorts([{ id: 'totals.all', desc: true }])
    } else {
      setSorts([{ id: 'stats.outstanding', desc: true }])
    }
  }, [groupBy])

  const tableData = !groupBy ? shareholderData : [...securityTypeData, ...filteredHoldings]
  const pageSize = 100000

  const makeCompanyColumn = ({ header, accessor }: CompanyColumnConfig) => {
    return {
      Header: header,
      accessor: `shareholdingSecurity.${accessor}`,
      Cell: ({ row }: CellProps<any>) => {
        const total = row.original.shareholdingSecurity
        const value = total[accessor]
        return <>{value ? toCommaFloat(value) : 0}</>
      },
      Footer: (info: any) => {
        let sumSecurity = 0

        info.data.forEach((d: any) => {
          const securityTypes = d.shareholdingSecurity
          for (const key of Object.keys(securityTypes)) {
            if (key === accessor) sumSecurity += securityTypes[key];
          }
        })
        const total = roundFloat(sumSecurity)
        const totalOT = info.data.reduce((a: any, b: any) => a + (b.memberOutstanding || 0), 0)

        return (
          <>
            <div>{total && toCommaFloat(total)}</div>
            <div>{total && percentageString(total, totalOT)}</div>
          </>
        )
      },
    }
  }

  const makeHoldingsColumn = ({ header, accessor }: CompanyColumnConfig) => {
    return {
      Header: header,
      accessor: `holdingSecurity.${accessor}`,
      Cell: ({ row }: CellProps<any>) => {
        const total = row.original.holdingSecurity
        const value = total[accessor]
        return <>{value ? toCommaFloat(value) : 0}</>
      },
      Footer: (info: any) => {
        let sumSecurity = 0

        info.data.forEach((d: any) => {
          const securityTypes = d.holdingSecurity
          for (const key of Object.keys(securityTypes)) {
            if (key === accessor) sumSecurity += securityTypes[key];
          }
        })
        const total = roundFloat(sumSecurity)
        const totalFD = info.data.reduce((a: any, b: any) => a + (b.memberFullyDiluted || 0), 0)
        return (
          <>
            <div>{total && toCommaFloat(total)}</div>
            <div>{total && percentageString(total, totalFD)}</div>
          </>
        )
      },
    }
  }

  const holdingSecurities = () => {
    const filteredSecurities = securities?.filter(s => {
      const hold = holdings?.filter(v => v.equity === s.hash)
      if (hold?.length > 0) return s
    })
    return filteredSecurities
  }

  const holdingColumns = holdingSecurities()?.map(el => {
    const securityName = formatSecurityAbbreviation(el)
    const securityType = el.hash
    return makeHoldingsColumn({ header: `${securityName}`, accessor: securityType })
  })

  const companySecurities = () => {
    const filteredSecurities = securities?.filter(s => {
      const shareH = shareholdings?.filter(v => v.equity === s.hash)
      if (shareH?.length > 0) return s
    })
    return filteredSecurities
  }

  const companyColumns = companySecurities()?.map(el => {
    const securityName = formatSecurityAbbreviation(el)
    const securityType = el.hash
    return makeCompanyColumn({ header: `${securityName}`, accessor: securityType })
  })

  const getSubRowsPerSecurityType = (securityType: Hash, subRow: any, index: number, holding: boolean, voided?: boolean) => {
    const holdingFilter = isHolding(subRow.holdingType)
    return securityType === subRow.equity && holdingFilter == holding ? (
      <StyledTd key={index} voided={voided}>{toCommaFloat(voided ? 0 : subRow.value)}</StyledTd>
    )
      :
      <td key={index}></td>
  }

  const fixedSubComponent = !groupBy ? ({ row, selectedRows }: TableRowProps<any>) => {
    const holdings = row.original.holdings.filter((h: any) => !h.voided)
    const shareholdings = row.original.shareholdings.filter((h: any) => !h.voided)
    const voided = row.original.shareholdings.filter((h: any) => h.voided)
    const voidedHoldings = row.original.holdings.filter((h: any) => h.voided)

    const renderCertificateRow = (voided?: boolean) => (subRow: any, i: number) => (
      <CertificateRow voided={voided} key={i} isSelected={isRowSelected(selectedRows, row.id)}>
        <td />
        <td>
          <FlexRow>
            <StyledCertificateImage src={CertificateImage} />
            <FlexCol alignItems="flex-start" style={{ marginLeft: '10px' }}>
              <CertificateLink holding={subRow} path={captableCertificatePath} />
              <div style={{ whiteSpace: 'nowrap' }}>Issued {formatDateString(subRow.issueDate!)}</div>
            </FlexCol>
          </FlexRow>
        </td>
      </CertificateRow>
    )

    const renderHoldingCertificateRow = (voided?: boolean) => (subRow: any, i: number) => {
      const args = {
        organization: subRow.entity,
        shareholding: subRow.hash,
        member: subRow.owner,
      }

      return <CertificateRow key={i} isSelected={isRowSelected(selectedRows, row.id)} voided={voided}>
        <td />
        <StyledTd voided={voided}>
          <FlexRow>
            <StyledCertificateImage src={CertificateImage} />
            <FlexCol alignItems="flex-start" style={{ marginLeft: '10px' }}>
              <PathLink path={captableCertificatePath} args={args}>
                {subRow.name}
              </PathLink>
              <div style={{ whiteSpace: 'nowrap' }}>Issued {formatDateString(subRow.issueDate!)}</div>
            </FlexCol>
          </FlexRow>
        </StyledTd>
      </CertificateRow>
    }

    return (
      <>
        {shareholdings?.map(renderCertificateRow())}
        {holdings?.map(renderHoldingCertificateRow())}
        {voidedHoldings?.map(renderHoldingCertificateRow(true))}
        {voided?.map(renderCertificateRow(true))}
      </>
    )
  }
    :
    ({ row, selectedRows }: TableRowProps<any>) => {
      const rows = row.original.subRows
      const isSelected = isRowSelected(selectedRows, row.id)
      return rows.map((row: any, i: number) => {
        const holdings = row.holdings
        if (row.inTable) {
          if (holdings && holdings.length > 0) {
            return <CollapsibleRow
              key={i}
              id={i}
              name={row.name}
              shareholdings={holdings}
              fixed={true}
              collapsed={nestedCollapsed}
              setCollapsed={setNestedCollapsed}
              isSelected={isSelected} />
          } else {
            const shareholdings = row.shareholdings
            return <CollapsibleRow
              key={i}
              id={i}
              name={row.name}
              shareholdings={shareholdings}
              fixed={true}
              collapsed={nestedCollapsed}
              setCollapsed={setNestedCollapsed}
              isSelected={isSelected} />
          }
        }
        else return null
      })
    }

  const subComponent = !groupBy ? ({ row, selectedRows }: TableRowProps<any>) => {
    const holdings = row.original.holdings.filter((h: any) => !h.voided)
    const shareholdings = row.original.shareholdings.filter((h: any) => !h.voided)
    const voided = row.original.shareholdings.filter((h: any) => h.voided)
    const voidedHoldings = row.original.holdings.filter((h: any) => h.voided)

    const renderCertificateRow = (voided?: boolean) => (subRow: any, i: number) => (
      <CertificateRow key={i} isSelected={isRowSelected(selectedRows, row.id)}>
        <StyledTd />
        <StyledTd voided={voided}>{formatCurrency(voided ? 0 : subRow.capitalContribution)}</StyledTd>
        {companySecurities()?.map((securityType, index: number) => getSubRowsPerSecurityType(securityType.hash, subRow, index, false, voided))}
        <StyledTd />
        <StyledTd style={{ border: 'none' }} voided={voided} />
        <DivisionCell solid={false} />
        {holdingSecurities()?.map((securityType, index: number) => getSubRowsPerSecurityType(securityType.hash, subRow, index, true, voided))}
        <StyledTd voided={voided}>{toCommaFloat(voided ? 0 : subRow.value)}</StyledTd>
        <StyledTd />
      </CertificateRow>
    )

    return (
      <>
        {shareholdings?.map(renderCertificateRow())}
        {holdings?.map(renderCertificateRow())}
        {voidedHoldings?.map(renderCertificateRow(true))}
        {voided?.map(renderCertificateRow(true))}
      </>
    )
  }
    :
    ({ row, selectedRows }: TableRowProps<any>) => {
      const rows = row.original.subRows
      const isSelected = isRowSelected(selectedRows, row.id)
      return rows.map((row: any, i: number) => {
        const holdings = row.holdings
        if (row.inTable) {
          if (holdings && holdings.length > 0) {
            return <CollapsibleRow
              key={i}
              id={i}
              name={row.name}
              shareholdings={holdings}
              fixed={false}
              invested={row.memberInvestment}
              outstanding={row.memberOutstanding}
              fullyDiluted={row.memberFd}
              collapsed={nestedCollapsed}
              setCollapsed={setNestedCollapsed}
              isSelected={isSelected} />
          } else {
            const shareholdings = row.shareholdings
            return <CollapsibleRow
              key={i}
              id={i}
              name={row.name}
              shareholdings={shareholdings}
              fixed={false}
              invested={row.memberInvestment}
              outstanding={row.memberOutstanding}
              collapsed={nestedCollapsed}
              setCollapsed={setNestedCollapsed}
              isSelected={isSelected} />
          }
        }
        else return null
      })
    }

  const handleSort = async (id: string) => {
    const existing = sorts.filter(sort => sort.id == id)[0]
    const desc = existing ? !existing.desc : false
    setSorts([{ id, desc }])
    await editUserState(httpClient)({}, { userState: { ...userState, [`capSort|${organization}|${groupBy}`]: [{ id, desc }] } })
  }

  const fixedColumns: ColumnWithFooter[] = !groupBy
    ? [
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <ExpandWrapper>
              <RainbowChips color={colorsList[row.id % colorsList.length]} />
              <StyledTableArrow shouldRotate={row.isExpanded} onClick={() => toggleExpandedRows(parseInt(row.id))}>
                {<CapTableArrow />}
              </StyledTableArrow>
            </ExpandWrapper>
          ) : null,
      },
      {
        Header: 'Holder',
        accessor: 'name',
        Cell: ({ row }: any) => <PathLink
          path={organizationMemberPath}
          args={{
            organization: props?.organization!,
            member: row.original.id,
            parent: window.location.pathname
          }}>
          {row.original.name!}
        </PathLink>,
        Footer: () => (
          <>
            <div>TOTAL</div>
            <div>% OF TOTAL</div>
          </>
        ),
      },
    ]
    : [
      {
        id: 'expander',
        Cell: ({ row }: any) =>
          row.canExpand ? (
            <ExpandWrapper>
              <RainbowChips color={colorsList[row.id % colorsList.length]} />
              <StyledTableArrow shouldRotate={row.isExpanded} onClick={() => toggleExpandedRows(parseInt(row.id))}>
                {<CapTableArrow />}
              </StyledTableArrow>
            </ExpandWrapper>
          ) : null,
      },
      {
        Header: 'Holdings',
        accessor: 'securityType',
        Cell: ({ row }: CellProps<any>) => row.original.isEquity ?
          <span>{formatSecurityName(row.original)}</span>
          :
          <span>{row.original.name}</span>,
        Footer: () => (
          <>
            <div>TOTAL</div>
            <div>% OF TOTAL</div>
          </>
        ),
      }
    ]

  const secondColumns: ColumnWithFooter[] = !groupBy
    ? [
      {
        id: 'expander',
      },
      {
        Header: 'Investment',
        accessor: 'memberInvestment',
        Cell: ({ row }: CellProps<any>) => {
          const totalInvestment = row.original.memberInvestment || 0
          return <>{optionalUsdString(totalInvestment)}</>
        },
        Footer: (info: any) => {
          const totalInvestment = info.data.reduce((a: any, b: any) => a + (b.memberInvestment || 0), 0)
          return <>
            <div>{optionalUsdString(totalInvestment)}</div>
            <div className="footer-border" />
          </>
        },
      },
      ...companyColumns,
      {
        Header: 'OT',
        accessor: 'memberOutstanding',
        Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.memberOutstanding || 0),
        Footer: (info: any) => {
          const totalOT = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberOutstanding || 0).toPrecision(7)), 0)
          const totalPer = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberOutstandingPer || 0).toPrecision(7)), 0)
          return <>
            <div>{parseFloat(totalOT.toPrecision(1))}</div>
            <div>{parseFloat(totalPer.toPrecision(1))}%</div>
          </>
        },
      },
      {
        Header: '%',
        accessor: 'memberOutstandingPer',
        className: 'percentage',
        Cell: ({ row }: CellProps<any>) => <span>{toCommaFloat(row.original.memberOutstandingPer) || 0}%</span>,
        Footer: (info: any) => {
          const totalPer = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberOutstandingPer || 0).toPrecision(7)), 0)
          return <>
            <div>{parseFloat(totalPer.toPrecision(1))}%</div>
            <div />
          </>
        },
      },
      {
        Header: () => <></>,
        Cell: _ => <></>,
        id: 'division2',
        className: 'division1',
      },
      ...holdingColumns,
      {
        Header: 'FD',
        accessor: 'memberFullyDiluted',
        Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.memberFullyDiluted || 0),
        Footer: (info: any) => {
          const totalFD = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberFullyDiluted || 0).toPrecision(7)), 0)
          const totalPer = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberFullyDilutedPer || 0).toPrecision(7)), 0)
          return <>
            <div>{parseFloat(totalFD.toPrecision(1))}</div>
            <div>{parseFloat(totalPer.toPrecision(1))}%</div>
          </>
        },
      },
      {
        Header: '%',
        accessor: 'memberFullyDilutedPer',
        Cell: ({ row }: CellProps<any>) => <span>{row.original.memberFullyDilutedPer || 0}%</span>,
        Footer: (info: any) => {
          const totalPer = info.data.reduce((a: any, b: any) => parseFloat((a + b.memberFullyDilutedPer || 0).toPrecision(7)), 0)
          return <>
            <div>{parseFloat(totalPer.toPrecision(1))}%</div>
            <div />
          </>
        },
      },
    ]
    : [
      {
        id: 'expander',
      },
      {
        Header: 'Invested',
        accessor: 'invested',
        Cell: ({ row }: CellProps<any>) => <span>{optionalUsdString(row.original.invested || 0)}</span>,
        Footer: (info: any) => {
          const totalInvested = info.data.reduce((a: any, b: any) => a + (b.invested || 0), 0)
          return <>
            <div>{optionalUsdString(totalInvested)}</div>
            <div>100%</div>
          </>
        },
      },
      {
        Header: 'Outstanding',
        accessor: 'outstanding',
        Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.outstanding || 0),
        Footer: (info: any) => {
          const totalOutstanding = info.data.reduce((a: any, b: any) => a + (b.outstanding || 0), 0)
          const outPer = info.data.reduce((a: any, b: any) => a + (b.outPer || 0), 0)
          return <>
            <div>{toCommaFloat(totalOutstanding)}</div>
            <div>{outPer.toFixed(2)}%</div>
          </>
        },
      },
      {
        Header: 'Fully Diluted',
        accessor: 'fullyDiluted',
        Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.fullyDiluted || 0),
        Footer: (info: any) => {
          const totalFD = info.data.reduce((a: any, b: any) => a + (b.fullyDiluted || 0), 0)
          const fdPer = info.data.reduce((a: any, b: any) => a + (b.fdPer || 0), 0)
          return <>
            <div>{toCommaFloat(totalFD)}</div>
            <div>{fdPer.toFixed(2)}%</div>
          </>
        },
      },
      {
        Header: '%',
        accessor: 'percentage',
        Cell: ({ row }: CellProps<any>) => <span>{row.original.fdPer?.toFixed(2)}%</span>,
        Footer: (info: any) => {
          const fdPer = info.data.reduce((a: any, b: any) => a + (b.fdPer || 0), 0)
          return <>
            <div>{fdPer.toFixed(2)}%</div>
            <div />
          </>
        },
      },
    ]

  const [fixedHiddenIds, secondHiddenIds] = diffColumns([fixedColumns, secondColumns])

  return (
    <Wrap>
      <LeftTable>
        <Table
          initialState={{ hiddenColumns: fixedHiddenIds }}
          columns={fixedColumns.concat(fixedHiddenIds.map(hiddenColumn))}
          data={tableData}
          rowComponent={advancedTableRow(fixedSubComponent)}
          selectedRows={selectedRows}
          expandedRows={expandedRows}
          fixed={true}
          pageSize={pageSize}
          sortById={sorts}
          handleSort={handleSort} />
      </LeftTable>
      <RightTable
        initialState={{ hiddenColumns: secondHiddenIds }}
        columns={secondColumns.concat(secondHiddenIds.map(hiddenColumn))}
        data={tableData}
        rowComponent={advancedTableRow(subComponent)}
        selectedRows={selectedRows}
        expandedRows={expandedRows}
        fixed={false}
        pageSize={pageSize}
        sortById={sorts}
        handleSort={handleSort}
        scrollingTable={true} />
    </Wrap>
  )
}
