import * as React from 'react'
import { NavigationProps } from '@components/navigation/navigation'
import { OrganizationsListShared } from '@modules/organization/components/organizations-list-shared'
import { signUpModal } from '@shared/components/modal'

type Props = NavigationProps

export const GuestOrganizationsPage = (props: Props) => {
  const { navigate } = props

  const signUpModalBundle = signUpModal({ navigate })
  return<>    {signUpModalBundle.component}
   <OrganizationsListShared {...props} forGuest={true}  openSignUp={() => signUpModalBundle.setVisible(true)}/>
   </>
}
