import * as React from 'react'
import { PageProps, checkoutPath, billingPath, GetSubscriptionsResponse, PricePointsResponse } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { BaseLinkProps } from '@components/navigation/navigation'
import { FlexRow, colors, FlexCol, fontSizes, Header2, screenSizes } from '@src/styles'
import styled, { css } from 'styled-components'
import { PathLinkButton, LinkButton } from '@components/button'
import { PriceBreakdown } from '@modules/payments/components/price-breakdown'
import { FeaturesTable } from '../components/features-and-additional-services'
import { paymentPlans } from '../payments-plan'
import { ProgressBarHeader } from '../components/progress-bar-header'
import { loadSubscriptions, loadPricePoints } from '@shared/helpers'

type Props = PageProps & GetSubscriptionsResponse & PricePointsResponse

const withData = withLoadingCachedMultiple<Props>(loadSubscriptions, loadPricePoints)

const SelectYourPlanWrapper = styled.div<any>`
    color: ${props => props.theme.white};
`

const textLineHeight = css`
  line-height: 22px;
`

const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 55px;
  margin: 43px 0 70px 0;
  @media(max-width: ${screenSizes.XS}px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
  }
`

const PlansPanel = styled.div`
  width: 100%;
  background: ${props => props.theme.panelBack};
  border-radius: 8px;
`

const PlansPanelContent = styled<any>(FlexCol)`
  align-items: start;
  height: 100%;
  color: ${props => props.theme.white};
  position: relative;
  padding: ${props => props.title?.includes('GROWTH') ? '20px 30px 21px 30px' : '20px 30px 58px 30px'};
  p {
      ${textLineHeight};
      margin: 0;
      color: ${props => props.theme.gray};
  }
  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  a {
    margin: 15px 0 0 0;
  }
`
const PlanTitle = styled.span`
  margin: 30px 0 6px 0;
  font-size: ${fontSizes.M};
  line-height: 33px;
`

const PlanPricing = styled.span`
  color: ${colors.green};
  font-size: ${fontSizes.XXL};
  line-height: 65px;
`

const PlanDescription = styled.span<any>`
  ${textLineHeight};
  margin: ${props => props.title?.includes('START') ? '70px 0 34px 0' : '24px 0 34px 0'};
`

const buttonCSS = css`
  white-space: nowrap;
  margin: 0 20px;
`

export const SelectedButton = styled<any>(PathLinkButton)`
  ${buttonCSS};
  background: ${colors.lightNavy};
  color: ${props => props.theme.gray};
  &:hover {
    background: ${colors.lightNavy};
  }
`

export const StyledLinkButton = styled<any>(LinkButton)`
  ${buttonCSS};
`

export const StyledPathLink = styled<any>(PathLinkButton)`
  ${buttonCSS};
`

export const TalkToExpertLink = () => (
  <StyledLinkButton to={{ pathname: 'https://drift.me/sten1/meeting' }} target="_blank">Talk to an expert</StyledLinkButton>
)

const renderPanels = (panels: any, organization: string, toggle: any, hasSubscription: boolean) => {
  const renderButtons = (title: string) => {
    switch (title) {
      case 'SCALE':
        return TalkToExpertLink()
      case 'GROWTH':
        return hasSubscription ? <SelectedButton disabled={true}>Your Current Plan</SelectedButton> :
          <PathLinkButton path={checkoutPath} args={{ organization }}>Select {title} Plan</PathLinkButton>
      case 'START':
        return !hasSubscription ? <SelectedButton disabled={true}>Your Current Plan</SelectedButton> :
          TalkToExpertLink()
    }
  }

  return <PanelsWrapper>
    {panels.map((panel: any, i: number) => {
      const { Logo, Title, Price, Info, Description } = panel
      return <PlansPanel key={i}>
        <PlansPanelContent title={Title}>
          <Logo />
          <PlanTitle>{Title.includes('GROWTH') ? `${Title} - (Most Popular)` : Title}</PlanTitle>
          <PlanPricing>{Price}</PlanPricing>
          <p>{Info}</p>
          <PlanDescription title={Title}>{Description}</PlanDescription>
          <div>
            {renderButtons(Title)}
            {Title === 'GROWTH' && <a href={'#'} onClick={() => toggle(true)}>view pricing breakdown</a>}
          </div>
        </PlansPanelContent>
      </PlansPanel>
    })}
  </PanelsWrapper>
}

export const SelectYourPlanPage = withData(
  (props: Props) => {
    const { params, subscriptions, pricePoints } = props
    const { organization } = params
    const [priceBreakdown, setPriceBreakdown] = React.useState<boolean>(false)
    const hasSubscription = subscriptions?.length > 0
    const { prices } = pricePoints.filter(p => p.default)[0]

    const parent: BaseLinkProps = { path: billingPath, args: { organization } }

    return (
      <>
        <ProgressBarHeader current={'plan'} />
        <PageContent>
          {!priceBreakdown ?
            <>
              <PageContentHeader title="Step 1. Select Your Plan" parent={parent} />
              <SelectYourPlanWrapper>
                <FlexRow>
                  <span>
                    Equa has a plan that is right for your organization, no matter the size.
                    Select your desired plan size and billing frequency
                  </span>
                </FlexRow>
                {renderPanels(paymentPlans, organization, setPriceBreakdown, hasSubscription)}
                <Header2>Features and Additional Services by Plan</Header2>
                <FeaturesTable subscriptions={subscriptions} organization={organization} />
              </SelectYourPlanWrapper>
            </>
            :
            <PriceBreakdown toggle={setPriceBreakdown} prices={prices} />
          }
        </PageContent>
      </>
    )
  }
)
