import * as React from 'react'
import { StateTransform } from '@src/index'
import { connect } from 'react-redux'
import { Organization, User } from '@logic'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'
import { SubHeader, SubTitle } from '@src/app/components/header/sub-header'
import { userHasProfile, userIsEmailVerified, userIsLoggedIn } from '@helpers/util'

import { loadProfile } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { ComponentType } from 'react'
import { ServiceProps, withService } from '@shared/hocs/with-service'

interface StateProps {
  user: User
  currentOrganization: Organization
}

interface Props extends RouteComponentProps<any> {
  history: History
}

type ProfileHeaderProps = StateProps & Props & ServiceProps

const withData = withLoadingCachedMultiple<ProfileHeaderProps>()

const ProfileHeaderComponent = withData((props: ProfileHeaderProps) => {
  const { user } = props
  const userExists = userIsLoggedIn(user) && userHasProfile(user) && userIsEmailVerified(user)
  return <>{userExists && <SubHeader title={<SubTitle name={''} />} hide={true} />}</>
})

const mapStateToProps: StateTransform<StateProps> = s => ({
  user: s.user!,
  currentOrganization: s.organization!.currentOrganization!,
})

const ProfileHeader = withRouter(connect(mapStateToProps)(ProfileHeaderComponent))

export function withProfileHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
  return (props: any) => (
    <React.Fragment>
      <ProfileHeader {...props} />
      <InnerComponent {...props} />
    </React.Fragment>
  )
}
