import * as React from 'react'
import { createGlobalStyle, ThemeProps, css } from 'styled-components'
import { colors, fontSizes, gapSizes, screenSizes, Theme } from './theme'
import NunitoSans from '../assets/fonts/NunitoSans/NunitoSans-Regular.ttf'
import NunitoSansBlack from '../assets/fonts/NunitoSans/NunitoSans-Black.ttf'
import NunitoSansLight from '../assets/fonts/NunitoSans/NunitoSans-Light.ttf'
import NunitoSansBold from '../assets/fonts/NunitoSans/NunitoSans-Bold.ttf'
import NunitoSansExtraBold from '../assets/fonts/NunitoSans/NunitoSans-ExtraBold.ttf'
import NunitoSansSemiBold from '../assets/fonts/NunitoSans/NunitoSans-SemiBold.ttf'
import { rgba } from 'polished'
import { fieldBorder, fieldHeight } from '@src/styles'
import { subduedText } from '@src/styles'

export const LinkStyle = css`
  color: ${props => props.theme.accent};
  cursor: pointer;
`

export const GlobalStyle = createGlobalStyle`
  :root{
    transition: margin 300ms ease-in-out;
    --pageMargin: 80px;
    @media (max-width: ${screenSizes.L}px) {
      --pageMargin: 40px;
    }
    @media (max-width: ${screenSizes.M}px) {
      --pageMargin: 20px;
    }
  }
  @font-face {
    font-family: NunitoSans;
    src: url(${NunitoSans});
  } 
  @font-face {
    font-family: NunitoSansSemiBold;
    src: url(${NunitoSansSemiBold});
  } 
  @font-face {
    font-family: NunitoSansBlack;
    src: url(${NunitoSansBlack});
  }
  @font-face {
    font-family: NunitoSansBold;
    src: url(${NunitoSansBold});
  }
  @font-face {
    font-family: NunitoSansExtraBold;
    src: url(${NunitoSansExtraBold});
  }
  @font-face {
    font-family: NunitoSansLight;
    src: url(${NunitoSansLight});
  }
  h1,h2,h3,h4{
    margin: 0;
  }
  h1 {
    font-family: 'NunitoSans';
    font-size: ${fontSizes.XXL};
  }
  h2 {
    font-family: 'NunitoSans';
    font-size: ${fontSizes.XL};
  }
  h3 {
    font-family: 'NunitoSans';
    font-size: ${fontSizes.L};
  }
  h4 {
    font-family: 'NunitoSansSemiBold';
    font-size: ${fontSizes.M};
  }
  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${props => props.theme.secondary};
  }
  html,
  body {
    font-size: 16px;
    height: 100%;
    scroll-behavior: smooth;
    width: 100%;
  }
  body {
    min-height: 100%;
    margin: 0;
    padding: 0;
    background-color: ${(props: ThemeProps<Theme>) => props.theme.secondary};
  }
  *::-webkit-scrollbar {
    width: 12px;
    background-color: ${rgba(81, 111, 119, 0.101961)};
    border-radius: 4px;
  }
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    background-color: #9CA6AD;
    border-radius: 20px;
    background-clip: content-box;
  }
  #root{
    display: flex;
    flex-flow: column;
    min-height: 100vh;
    max-width: 100%;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
    font-family: NunitoSans;
  }
  a{
    font-family: 'NunitoSansSemiBold';
    text-decoration: none;
    color: ${(props: ThemeProps<Theme>) => props.theme.white};
  }
  label {
    font-size: ${fontSizes.XS}
    position: relative;
    color: ${props => props.theme.label};
  }
  input:disabled ~ label{
   color: red;
  }
  input[type="checkbox"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  -ms-appearance: radio; 
  }
  select {
    color: black;
    cursor: pointer;
    position: relative;
    margin-bottom: 12px;
    height: ${fieldHeight};
    border: none;
    border-bottom: 1px solid ${props => props.theme.highlight};
    -webkit-border-radius: 0;
     ${fieldBorder};
    font-family: NunitoSans;
    font-size: 16px;
    padding-left: 4%;
    background-color: white;
    option{
      color: black;
    }
    &:invalid, option[value=""] {
      color: #999999;
    }
    &:disabled{
      background-color: ${props => props.theme.highlight};
      cursor: initial;
    }
  }
  
  select::-ms-expand { 
    display: none; 
  }
  
  button {
    font-size: 16px;
    margin: 0;
    padding: 8px 12px;
  }
  hr {
    background-color: ${props => rgba(props.theme.white, 0.2)};
    border: 0;
  }
  dd {
    margin: 0;
  }
  a {
    ${LinkStyle};
  }
  a.disabled {
    pointer-events: none;
  }
  svg{
    overflow: visible;
    + span {
      margin-left: ${gapSizes.S};
    }
  }
  .react-hint {
    padding: 5px;
    position: absolute;
    z-index: 9999;
    cursor: default;
    animation: .5s fadeIn;
    text-align: center;
    }

    .react-hint__content {
      padding: 20px;
      border-radius: ${props => props.theme.radius};
      background: ${props => props.theme.primary};
      color: #fff;
      width: 250px;
    }

    .react-hint:after {
      content: '';
      width: 0;
      height: 0;
      margin: auto;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 5px solid transparent;
    }

    .react-hint--top:after {
      top: auto;
      border-bottom: none;
      border-top-color: ${props => props.theme.primary};
    }

    .react-hint--left:after {
      left: auto;
      border-right: none;
      border-left-color: ${props => props.theme.primary};
    }

    .react-hint--right:after {
      right: auto;
      border-left: none;
      border-right-color: ${props => props.theme.primary};
    }

    .react-hint--bottom:after {
      bottom: auto;
      border-top: none;
      border-bottom-color: ${props => props.theme.primary};
    }

    @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  svg{
    flex-shrink: 0;
  }
  .ms-ShimmerLine-topLeftCorner,
  .ms-ShimmerLine-bottomLeftCorner,
  .ms-ShimmerLine-topRightCorner,
  .ms-ShimmerLine-bottomRightCorner {
    display: none;
  }
  .ms-ContextualMenu-Callout{
    border: 0 !important;
  }
  .ms-Layer--fixed {
    .css-35{
      right: 74px !important;
    }
    + .ms-Layer--fixed{
      .css-35{
        right: 255px !important;
      }
    }
  }
  .ms-Callout-main{ 
    max-height: 200px;
  }
  .ms-ContextualMenu {
    background-color: ${props => props.theme.primary} !important;
    * {
    color: ${props => props.theme.white};
    }
    button:hover{
      background-color: ${props => props.theme.primary};
    }
  }
  .ms-TilesList {
    overflow-x: inherit !important;
  }
  .ms-Callout-beak {
    background-color: ${props => props.theme.primary} !important;
  }
  .ms-Callout {
    border: 0 !important;
  }
  .ms-Panel-main, .ms-Callout-main {
    background-color: ${props => props.theme.primary} !important;
    border-color: ${props => props.theme.primary} !important;
    * {
      color: ${props => props.theme.white} !important;
    }
  }
  .ms-Tooltip, .ms-Tooltip-subtext{
    display: none;
  }
  
  .ms-ContextualMenu-divider {
    display: none !important;
  }
  
  button[name='New'],
  button[name='Visio drawing'],
  button[name='Forms for Excel'], 
  button[name='Excel survey'], 
  button[name='OneNote notebook'], 
  button[name='PowerPoint presentation'], 
  button[name='Excel workbook'], 
  button[name='Word document'],
  button[name='Download'] {
    display: none !important;
  }
  
  .is-expanded{
    background-color: inherit !important;
  }
  
  .slick-prev, .slick-next{
    visibility: hidden;
  }
  .slick-active {
    button {
      background-color: ${props => props.theme.accent} !important;
    }
  }
  .cfy-cc-image img{
    top: 50%;
    transform: translateY(-50%);
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`

// export class GlobalVars {
//   public static _setMyComponentValue: any
//   public static _expanded: boolean = false
// }

// export function setMyComponentValue(value: boolean) {
//   if (GlobalVars._setMyComponentValue) {
//     GlobalVars._setMyComponentValue(value)
//     GlobalVars._expanded = value
//   }
// }

// export function getExpanded() {  
//   return GlobalVars._expanded
// }
