import * as React from 'react'
import { PageProps, viewConvertibleInstrumentPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm, Sticky, UploadHandler } from '@components/forms'
import arrayMutators from 'final-form-arrays'
import {
  createAuthorization,
  editHolding,
  ExpandedHoldingResponse,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetAuthorizationsResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import {
  loadAuthorizations,
  loadHolding,
  loadLegends,
  loadOrganizationHoldings,
  loadSecurities
} from '@helpers/loaders'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { EditConvertibleInstrumentsForm, InstrumentDetails } from '../components'
import { getMaxShares, getOrganizationTotalUnits } from '../utility'
import { ConvertibleInstrumentFormFields } from '@modules/convertibles'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & GetSecuritiesResponse & ExpandedHoldingsResponse & ExpandedHoldingResponse & GetLegendsResponse & GetAuthorizationsResponse

const withData = withLoadingCachedMultiple<Props>(
  loadSecurities('true'),
  loadOrganizationHoldings('all'),
  loadHolding,
  loadLegends(true),
  loadAuthorizations('holding')
)

export const EditConvertibleInstrumentPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, holdings, holding, legends, authorizations, stats } = props
    const { organization } = params
    const securitiesList = securities ? securities?.filter(s => !s.parent).map((arg): any => {
      const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
      return { ...arg, stats: statsValue }
    }) : []

    const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })
    const { convertibleInstrument, name, abbreviation, seniority, legend, pricePerUnit } = holding
    const {
      instrumentType,
      maturityDate,
      authorizedRaiseAmount,
      valuationMin,
      valuationMax,
      conversionDiscount,
      interestRate,
      accrualFrequency,
      qualifiedFinancingAmount,
      earlyExitMultiple } = convertibleInstrument
    const securityType = securities?.find(s => s.hash === holding?.parent!)
    const initialValues: ConvertibleInstrumentFormFields = {
      instrumentType: instrumentType!,
      name: name!,
      abbreviation: abbreviation ? abbreviation : undefined,
      maturityDate: maturityDate ? maturityDate : undefined,
      convertsTo: holding?.parent ? holding.parent : undefined,
      authorizedRaiseAmount: authorizedRaiseAmount ? authorizedRaiseAmount : undefined,
      valuationMin: valuationMin ? valuationMin : undefined,
      valuationMax: valuationMax ? valuationMax : undefined,
      conversionDiscount: conversionDiscount ? conversionDiscount : undefined,
      interestRate: interestRate ? interestRate : undefined,
      accrualFrequency: accrualFrequency ? accrualFrequency : undefined,
      qualifiedFinancingAmount: qualifiedFinancingAmount ? qualifiedFinancingAmount : undefined,
      earlyExitMultiple: earlyExitMultiple ? earlyExitMultiple : undefined,
      seniority: seniority ? seniority : undefined,
      approvalDocument: documents,
      pricePerUnit: pricePerUnit ? pricePerUnit : undefined
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []

      const { authorizedRaiseAmount, valuationMin, conversionDiscount, seniority } = values
      const totalUnits = getOrganizationTotalUnits(securities)
      const maxUnits = getMaxShares({
        maxUsd: authorizedRaiseAmount,
        valuationMin,
        totalUnits,
        discount: conversionDiscount
      })

      const convertibleInstrument = {
        accrualFrequency: values?.accrualFrequency!,
        authorizedRaiseAmount: values?.authorizedRaiseAmount!,
        conversionDiscount: values?.conversionDiscount!,
        earlyExitMultiple: values?.earlyExitMultiple!,
        estimatedConversionPrice: values?.estimatedConversionPrice!,
        instrumentType: values?.instrumentType!,
        interestRate: values?.interestRate!,
        maturityDate: values?.maturityDate!,
        qualifiedFinancingAmount: values?.qualifiedFinancingAmount!,
        valuationMax: values?.valuationMax!,
        valuationMin: values?.valuationMin!,
      }
      const holdingRequest = {
        name: values.name,
        abbreviation: values.abbreviation,
        seniority,
        owner: organization,
        entity: organization,
        issueDate: new Date(),
        value: maxUnits,
        parent: values.convertsTo!,
        convertibleInstrument,
        legend: values?.legend!,
        pricePerUnit: values?.pricePerUnit
      }
      const newInstrument = await editHolding(httpClient)({ holding: params?.holding }, holdingRequest)
      if (newInstrument) {
        const holding = newInstrument?.hash!
        modifyAuthorizations({ approvalDocuments, target: holding, httpClient, organization })
        navigate(viewConvertibleInstrumentPath, { organization, holding: newInstrument?.hash! })
      } else {
        console.log('Error editing convertible instrument')
      }
    }

    const parent: BaseLinkProps = {
      path: viewConvertibleInstrumentPath,
      args: { organization, holding: params?.holding }
    }

    const sticky: Sticky<ConvertibleInstrumentFormFields> = formProps => (
      <InstrumentDetails securities={securitiesList} values={formProps.values} forEdit={true} />
    )

    return (
      <PageContent>
        <PageContentHeader title={`Edit Instrument ${holding?.name}`} parent={parent} withSticky={true} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save" sticky={sticky}>
          <EditConvertibleInstrumentsForm onUpload={onUpload} securities={securitiesList} holdings={holdings}
            holding={params?.holding} securityType={securityType} legend={legend} legends={legends} />
        </CommonForm>
      </PageContent>
    )
  }
  ))
