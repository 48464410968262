import styled from "styled-components"
import { colors, FlexCol, FlexRow, fontSizes, headerHeight, Themes, whiteColor } from "@src/styles"

export const Checklist = styled<any>(FlexCol)`
    ${whiteColor}
    z-index: 1;
    justify-content: start;
    margin-bottom: 2px;
    height: 100vh;
    &.show {
      display: flex;
      width: 375px;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: width;
    }
    &.hide {
      width: 0;
      transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
      transition-duration: 0.5s;
      transition-property: width;
    }
    @media (max-width: 900px) {
      display: none !important;
    }
`

export const RocketWrap = styled<any>(FlexRow)`
  justify-content: center;
  position: fixed;
  cursor: pointer;
  top: 12px;
  right: 56px;
  z-index: 1000;
  margin-right: 24px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background: ${props => props.theme.buttonHover};
    path {
      fill: ${props => props.theme.selected !== Themes.LIGHT ? props.theme.white : colors.white};
    }
  }
  &:active {
      background: ${props => props.theme.buttonActive};
  }
  svg {
    cursor: pointer;
  }
  &.show {
    display: flex;
  }
  &.hide {
    display: none;
  }
  @media (max-width: 1117px) {
      display: none !important;
  }
`

export const HeaderWrap = styled<any>(FlexRow)`
  ${whiteColor}
  width: 375px;
  background: ${props => props.theme.listBack};
  z-index: 1;
  justify-content: center;
  padding: 24px;
  border-bottom: 1px solid ${props => props.theme.listBorder};
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.08), 0px 5px 22px rgba(0, 0, 0, 0.05), 0px 7px 8px rgba(0, 0, 0, 0.12);
  div {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    justify-content: center;
    &:hover {
      background: ${props => props.theme.buttonHover};
      path {
        fill: ${props => props.theme.selected !== Themes.LIGHT ? props.theme.white : colors.white};
      }
    }
    &:active {
      background: ${props => props.theme.buttonActive};
    }
  }
  span {
    margin: 0 16px;
    font-size: ${fontSizes.XS};
    line-height: 24px;
  }
  .cross {
    cursor: pointer;
  }
`

export const ListWrap = styled<any>(FlexCol)`
  background: ${props => props.theme.listBack};
  justify-content: start;
  padding: 16px 22px 0 24px;
  box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.08), 0px 5px 22px rgba(0, 0, 0, 0.05), 0px 7px 8px rgba(0, 0, 0, 0.12);
  overflow-x: hidden;
  overflow-y: auto;
  width: 375px;
  .openModal {
    color: ${props => props.theme.accent};
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  h5 {
    margin: 0 0 24px 0;
    line-height: 24px;
    font-size: ${fontSizes.XS};
    font-weight: 600;
    font-family: 'NunitoSansSemiBold';
  }
  &::-webkit-scrollbar {
    width: 6px;
    background: rgba(81, 111, 119, 0.101961);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    height: 88px;
    border: 1px solid transparent;
    background-color: #9CA6AD;
    border-radius: 4px;
    background-clip: content-box;
  }
`

export const WhiteGloveWrap = styled<any>(FlexRow)`
  padding: 28px 34px;
  width: 375px;
  background: ${props => props.theme.listBack};
  z-index: 1;
  box-shadow: 0px -5px 11px rgba(0, 0, 0, 0.2);
  div {
    margin-left: 16px;
    align-items: flex-start;
  }
  span {
    font-size: ${fontSizes.XXS};
    line-height: 22px;
    margin-bottom: 8px;
  }
  button {
    color: ${colors.white};
    cursor: pointer;
    background: ${props => props.theme.whiteGlove};
    border-radius: 4px;
    text-transform: uppercase;
    border: none;
    font-size: ${fontSizes.XXS};
    line-height: 24px;
    padding: 4px 12px;
    font-weight: bold;
    font-family: 'NunitoSansBold';
    &:hover {
      background: ${props => props.theme.buttonHover};
    }
    &:active {
      background: ${props => props.theme.buttonActive};
    }
  }
`

export const OptionWrap = styled<any>(FlexRow)`
  cursor: pointer;
  position: relative;
  max-width: 322px;
  width: 100%;
  align-items: start;
  background: ${props => !props.completed ? props.theme.listCard : props.theme.listCompleted};
  border-radius: 4px;
  margin-bottom: 16px;
  box-shadow: ${props => props.hover ?
    '0 0 0 2pt #33AA40'
    :
    '0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12)'
  };
  path {
    fill: ${props => !props.completed && props.theme.listIcon};
  }
  &:hover {
    box-shadow: 0 0 0 2pt #33AA40;
  }
  .expand {
    position: absolute;
    right: 8px;
    top: 8px;
    border-radius: 4px;
    transform: ${props => props.opened !== 'true' && 'rotate(90deg)'};
    path {
      fill: ${props => props.theme.listIcon};
    }
    &:hover {
      background: ${props => props.theme.buttonHover};
      path {
        fill: ${props => props.theme.selected !== Themes.LIGHT ? props.theme.white : colors.white};
      }
    }
    &:active {
      background: ${props => props.theme.buttonActive};
    }
  }
  min-height: -webkit-min-content;
  a {
    width: 100%;
    display: flex;
    align-items: start;
  }
`
export const Option = styled<any>(FlexRow)`
  width: auto;
  justify-content: center;
  align-items: start;
  margin: 8px 0 8px 8px;
  max-height: ${props => props.extended ? '72px' : '48px'};
  svg {
    path {
      fill: ${props => props.completed === 'true' ? colors.green : colors.white};
    }
  }
  .title {
    margin-bottom: 0;
    font-size: 20px;
    line-height: 28px;
    color: ${props => props.theme.listText};
  }
  .info {
    font-size: ${fontSizes.XXS};
    line-height: 19px;
    color: ${props => props.theme.listDesc};
    opacity: 0;
    display: none;
    .link {
      display: inline;
      width: auto;
      line-height: 19px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .infoWrap {
    padding: 0 16px 0 8px;
    span {
      width: 100%;
    }
  }
  &.opened {
    .title {
      margin-bottom: 16px;
    }
    max-height: 100%;
    .info {
      display: ${props => !props.link ? 'flex' : 'inline'};
      opacity: 1;
    }
  }
`

export const NeedHelpOption = styled<any>(FlexRow)`
  max-width: 322px;
  width: 100%;
  justify-content: center;
  background: ${props => props.theme.listCard};
  align-items: start;
  border-radius: 4px;
  position: relative;
  margin-bottom: 24px;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  min-height: -webkit-min-content;
  div {
    position: absolute;
    width: 122px;
    top: 16px;
    left: 42px;
    margin: 0 8px;
    align-items: start;
  }
  .title {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
  }
  .helpInfo {
    font-size: ${fontSizes.XXS};
    line-height: 19px;
    color: ${props => props.theme.selected !== Themes.LIGHT ? props.theme.listDesc : props.theme.listText};
    position: absolute;
    top: 44px;
  }
  a {
    &:hover {
        text-decoration: underline;
      }
  }
  svg {
    overflow: hidden;
    border-radius: 4px;
  }
`

export const FixedWrap = styled.div<any>`
  user-select: none;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: calc(100% - 62px);
  &.show {
    opacity: 1;
    width: 375px;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: width, opacity;
  }
  &.hide {
    opacity: 0;
    width: 0;
    transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition-duration: 0.5s;
    transition-property: width, opacity;
  }
`
