import styled from 'styled-components'
import { colors, headerHeight } from '@src/styles/theme'
import { ToastTypes } from '@logic'

export const StyledBanner = styled.div<any>`
  background-color: ${props => (props.type === ToastTypes.error ? colors.bannerError : colors.bannerSuccess)};
  font-size: 14px;
  font-weight: bolder;
  font-family: 'NunitoSansBold';
  position: sticky;
  top: 0px;
  width: 100%;
  color: ${props => props.theme.header};
  padding: 15px 0;
  z-index: 9999;
  > div {
    display: flex;
    justify-content: center;
  }
`
