import { ValidationErrors } from 'final-form'

export type FormValidator = (values: any) => ValidationErrors | Promise<ValidationErrors> | undefined

export type Validator = (value: any) => undefined | string | string[]

// Most validator functions
export function sanitizeStringValidator(validator: Validator): Validator {
  return value => validator(value === null || value === undefined ? '' : value)
}

// Creates a new Validator function with a string check and an error message
export function defineValidator(props: { check: (value: any) => boolean; message: string }): Validator {
  return sanitizeStringValidator(value => (props.check(value) ? undefined : props.message))
}

// Create a validator from multiple validators.
// All errors will be returned in an array.
export function composeValidators(validators: Validator[]): Validator {
  return value => {
    const errors = validators.map(v => v(value)).filter(result => result !== undefined)
    return errors as string[]
  }
}

// Create a validator from multiple validators.
// Works the same as `composeValidators` except only the first error will be returned.
// Provides better UX in some cases.
export function sequenceValidators(validators: Validator[]): Validator {
  return value => {
    for (const validator of validators) {
      const error = validator(value)
      if (error) return error
    }
    return []
  }
}

export function isEither(validators: Validator[], message?: string): Validator {
  return value => {
    const errors = validators
      .map(v => v(value))
      .filter(result => (Array.isArray(result) ? result.length > 0 && result : !!result && result)) as string[]
    return errors.length < validators.length ? [] : message || errors
  }
}

export const stringLength = (minOrExact: number, max?: number) => (value: any) => {
  const sanitized = value || ''
  const length = sanitized.length
  if (max === undefined) {
    const exact = minOrExact
    if (length != exact) {
      return `Must be ${exact} characters`
    }
  } else {
    const min = minOrExact
    if (length < min) {
      return `Must be at least ${min} characters`
    }
    if (length > max) {
      return `Cannot be greater than ${max} characters`
    }
  }
  return undefined
}
