import * as React from 'react'
import { Button } from '@components/button'
import styled from 'styled-components'
import { Field } from 'react-final-form'
import { MultiBox } from '@components/multibox'
import { FlexRow } from '@src/styles'

interface ToggleProps {
  selected: boolean
  name?: string
  vertical?: boolean | undefined
}

export const StyledToggle = styled(Button) <ToggleProps>`
  cursor: pointer;
  background-color: ${props => (props.selected ? props.theme.secondary : props.theme.highlight)};
  color: ${props => (props.selected ? props.theme.white : props.theme.gray)};
  border: ${props => (props.selected ? '2px solid' : 0)};
  border-color: ${props => (props.selected ? props.theme.accent : props.theme.gray)};
  min-width: 125px;
  border-radius: ${props => props.theme.radius};
  height: 50px;
  padding: 0;
  max-width: ${props => (props.vertical ? '100%' : '185px')};
  width: 100%;
  display: flex;
  align-items: center;
  text-align: start;
  &:hover {
    ${props => (!props.selected ? 'border-color: ' + props.theme.primary : '')};
    ${props => (props.selected ? 'cursor: ' + 'initial' : '')};
  }
`
const ToggleContainer = styled.div<any>`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};

  button {
    height: ${props => (props.vertical ? '80px' : '')};
    justify-content: ${props => (props.vertical ? 'space-between' : 'center')};
    padding: ${props => (props.vertical ? '10px' : '')};
    font-size: ${props => (props.vertical ? '.8em' : '')};
    color: ${props => (props.vertical ? props.theme.white : '')};
    margin: ${props => (props.vertical ? '6px 0' : '0 6px')};
    :first-child {
      margin-left: 0;
      margin-top: 0;
    }

    :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
  svg {
    margin: 0 10px;
  }
`

const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > div {
    color: ${props => props.theme.secondary};
  }
`

interface Props {
  items: {
    label: string
    value: string | boolean | number
    description?: string
    logo?: any
  }[]
  name?: string
  selected: string | boolean | number
  vertical?: boolean
  radioButtons?: boolean
  onChange?: (item: any) => void
}

export const ToggleButtons = (props: Props) => {
  const { vertical, items, name, selected, onChange, radioButtons } = props
  return (
    <ToggleContainer vertical={vertical}>
      {items.map((item, i: number) => {
        const value = item.value == null ? item.label : item.value
        return (
          <Field key={item.label} name={name ? name : ''} parse={() => value}>
            {({ input, meta }) => (
              <>
                <StyledToggle
                  type="button"
                  onClick={onChange ? () => onChange(item) : input.onChange}
                  selected={selected === value}
                  vertical={vertical}
                  name={`toggle.${item.label}`}
                >
                  {item.logo ? item.logo : null}
                  <FlexRow justifyContent="center">
                    {radioButtons && (
                      <MultiBox checked={selected === value} name={item.value.toString()} id={item.value.toString()} type={'radio'} />
                    )}
                    <StyledText>
                      {item.label}
                      {item.description && <div>{item.description}</div>}
                    </StyledText>
                  </FlexRow>
                </StyledToggle>
              </>
            )}
          </Field>
        )
      })}
    </ToggleContainer>
  )
}
