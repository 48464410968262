import { AnyAction, Reducer } from 'redux'
import { User } from '../types/types'
import { ActionType } from '../actions'

export const userReducer: Reducer<Partial<User> | undefined, AnyAction> = (state, action) => {
  let newState = { ...state } as User
  switch (action.type) {
    case ActionType.CHECK_VERIFICATION_SUCCESS:
      newState = { ...newState, emailVerified: true }
      return newState
    case ActionType.GET_CURRENT_USER_SUCCESS:
    case ActionType.REGISTER_SUCCESS:
    case ActionType.LOGIN_SUCCESS:
      newState = action.data.user ? { ...action.data.user, profile: action.data.profile, status: action.data.status } : action.data.user
      return newState
    case ActionType.LOGOUT_SUCCESS:
      return {}
    case ActionType.UPDATE_USER_SUCCESS:
      newState.profile = action.data.profile
      return newState
  }
  return state
}
