import * as React from 'react'
import { StateTransform } from '../../../index'
import { connect } from 'react-redux'
import { PageProps, UpdateUserProfileDispatch, UpdateUserProfileRequest, User, updateUserProfile, profileDetailsPath } from '@logic'
import { ProfileForm } from '../components/profile-form'
import { logout, LogoutDispatch } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { addEmails, createAddress, deleteAddress, editAddress, FilesResponse, GetAddressesResponse, GetEmailsResponse, postFile } from '@src/service'
import { loadAddresses, loadEmails, loadProfile } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { BaseLinkProps } from '@shared/components/navigation'
import { NewAddressRequest1, NewEmailDiffRequest, UpdateAddressRequest1 } from '@src/service/services/organizations/requests'

interface StateProps {
  user?: User
}

interface Props {
  logout: LogoutDispatch
  updateUserProfile: UpdateUserProfileDispatch
}

interface State {
  editing: boolean
}

type ProfileProps = PageProps & StateProps & Props & State & GetAddressesResponse & GetEmailsResponse

const withData = withLoadingCachedMultiple<ProfileProps>(loadProfile(), loadAddresses(''), loadEmails(''))

const ProfileComponent = withData((props: ProfileProps) => {
  const { httpClient, user, navigate, addresses } = props
  const profile = props?.profile!
  const fullName = profile?.fullName!
  const firstName = fullName?.substr(0, fullName.indexOf(' '))!
  const lastName = fullName?.substr(fullName.indexOf(' ') + 1)!
  const mapAddresses = addresses?.map(a => {
    const groups = a.groups?.map(g => g.id)
    return { ...a, groups }
  })
  const emails = props.emails!!
  const mapEmails = emails && emails.length > 0 ? emails.reverse().map(e => {
    return { email: e.email }
  }) : []


  const initialValues = {
    firstName,
    lastName,
    photo: profile?.file ? { name: `${profile.file.filename}`, id: profile.file.id } : '',
    phone: profile?.phone!,
    dateOfBirth: profile?.dateOfBirth!,
    address: mapAddresses,
    links: profile?.links!,
    website: profile?.website!,
    emails: mapEmails
  }

  const onUpload: UploadHandler = async files => {
    const response = (await postFile(httpClient)(`profile/${user?.id}/file`, files)) as FilesResponse
    const file = response.files[0]
    return {
      hash: file.hash,
      name: file.filename,
      id: file.id
    }
  }

  const onSubmit = async (data: UpdateUserProfileRequest) => {
    let profile = ''
    if (user && user.id) {
      profile = user.id
    }

    const emailValues = data.emails?.length > 0 ?
      data.emails.filter((e: any) => !e.isRemoved)?.map((email: any) => {
        const newEmail = { ...email }
        delete newEmail.key
        return newEmail.email
      }) : []

    const addressesValues = data?.address?.map((address: any) => {
      const newAddress = { ...address }
      delete newAddress.key
      delete newAddress.format
      delete newAddress.created
      delete newAddress.content
      delete newAddress.context
      return newAddress
    })
    const fullName = data?.firstName + ' ' + data?.lastName
    const request = {
      profile,
      fullName,
      website: data?.website!,
      email: data?.email!,
      phone: data?.phone!,
      dateOfBirth: data?.dateOfBirth!,
      file: data?.photo?.id!,
      links: data?.links!,
    } as UpdateUserProfileRequest
    const response = await props.updateUserProfile(request)
    if (response?.data?.profile) {
      if (addressesValues?.length > 0) {
        addressesValues.forEach(async (address: any) => {
          if (address.isRemoved && address.id) {
            await deleteAddress(httpClient)({ entity: user?.id, address: address.id })
          }
          if (!address.isRemoved) {
            if (address.id) {
              const editAddRequest = {
                ...address,
                groups: address?.groups ? address.groups : []
              } as UpdateAddressRequest1
              await editAddress(httpClient)({ entity: user?.id, address: address.id }, editAddRequest)
            } else {
              const addRequest = {
                ...address,
                groups: address?.groups ? address.groups : []
              } as NewAddressRequest1
              await createAddress(httpClient)({ entity: user?.id }, addRequest)
            }
          }
        })
      }
      if (emailValues) {
        const emailsRequest: NewEmailDiffRequest = {
          groups: [],
          emails: emailValues,
          entity: user?.id
        }
        await addEmails(httpClient)({ entity: user?.id }, emailsRequest)
      }
      navigate(profileDetailsPath)
    }
  }

  const parent: BaseLinkProps = { path: profileDetailsPath, args: {} }

  return (
    <PageContent>
      <PageContentHeader title='Edit Profile Details' parent={parent} hideCrumbs={true}/>
      <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
        submitText="Save">
        <ProfileForm onUpload={onUpload} />
      </CommonForm>
    </PageContent>
  )
})

const mapStateToProps: StateTransform<StateProps> = s => ({ user: s.user })

export const EditProfile = connect(
  mapStateToProps,
  { logout, updateUserProfile }
)(ProfileComponent)
