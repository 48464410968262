import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { editPlanPath, PageProps, plansPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { ViewPlan } from '../components/view-plan'
import {
  loadAuthorizations,
  loadOrganizationHoldings,
  loadPlanDashboard,
  loadSecurities
} from '@helpers/loaders'
import {
  deletePlan,
  ExpandedHoldingsResponse,
  GetAuthorizationsResponse,
  GetPlanResponse,
  GetSecuritiesResponse,
} from '@src/service'
import { FlexRow } from '@src/styles'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { overflowParams } from '@shared/components/navigation'
import { basicDeleteModal } from '@shared/components/modal'
import { filterEquities, filterIncentives, filterPools } from '../logic'

type Props =
  PageProps &
  GetSecuritiesResponse &
  GetPlanResponse &
  GetAuthorizationsResponse &
  ExpandedHoldingsResponse

const withData = withLoadingCachedMultiple<Props>(
  loadSecurities(),
  loadAuthorizations('plan'),
  loadPlanDashboard,
  loadOrganizationHoldings('all'),
)

export const ViewPlanPage = withData(
  withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
    const { params, httpClient, navigate, securities, plan, authorizations, holdings } = props
    const { organization } = params
    const securitiesList = filterEquities([], securities)
    const approvedEquities = plan?.approvedEquities?.equity!!
    const documentsAndNotes: any = authorizations && authorizations.length > 0 ?
      authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const allDocs = (withBoard?: any, withIncentive?: any) => {
      if (withBoard && withIncentive) {
        return documentsAndNotes.concat([withBoard, withIncentive])
      }
      else if (withBoard) return documentsAndNotes.concat([withBoard])
      else if (withIncentive) return documentsAndNotes.concat([withIncentive])
      else return documentsAndNotes
    }

    const documents = allDocs(plan?.boardApprovalDocument, plan?.incentivePlanDocument)?.map((auth: any) => {
      if (!auth.document) {
        return { ...auth, document: { hash: auth.hash, filename: auth.filename } }
      }
      else return auth
    })

    const approvedData = securitiesList.filter(s => approvedEquities?.some((a: string) => a === s.hash))
    const incentivesList = filterIncentives(securitiesList, plan)
    const pools = filterPools(holdings, incentivesList, plan?.hash).map(p => {
      const options = holdings?.filter(holding => (holding.parent! === p.id) || (holding.parent! === p.hash))
      const totalExercised = options.reduce((a, b) => a + b?.exercisedShares, 0)
      return { ...p, totalExercised }
    })
    const totalExercised = pools?.reduce((a, b) => a + b.totalExercised, 0)

    const handleDeletePlan = async () => {
      await deletePlan(httpClient)({ plan: params.plan })
      deleteModal.setVisible(false)
      navigate(plansPath, { organization })
    }

    const planName = plan ? `${plan.name} Plan` : ''

    const deleteModal = basicDeleteModal(
      handleDeletePlan,
      [planName],
      `WARNING: You are about to delete this entire incentive plan.
      Any incentives, pools, vesting schedules, and holdings you have attached to this plan will also be deleted. 
      Make sure this is your absolute intention.`,
      'Delete Permanently?'
    )

    const dropOptions = [
      ['Edit', () => navigate(overflowParams(editPlanPath, { organization, plan: params.plan, parent: location?.pathname! }))],
      ['Delete', () => deleteModal.setVisible(true)],
    ]

    return (
      <>
        {deleteModal.component}
        <PageContent>
          <PageContentHeader title='Dashboard' direction>
            <FlexRow style={{ alignItems: 'baseline' }}>
              <ActionsDrop options={dropOptions} />
            </FlexRow>
          </PageContentHeader>
          <ViewPlan
            organization={organization}
            plan={plan}
            httpClient={httpClient}
            navigate={navigate}
            securities={approvedData}
            params={params}
            documents={documents}
            totalExercised={totalExercised} />
        </PageContent>
      </>
    )
  })
)
