import * as React from 'react'
import { PageProps, NewPaymentProfileRequest, accountPath, viewCardPath, PaymentProfilesResponse } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import styled from 'styled-components'
import { CommonForm } from '@components/forms'
import { BaseLinkProps } from '@shared/components/navigation'
import arrayMutators from 'final-form-arrays'
import { createPaymentProfile, defaultPaymentProfile } from '@src/service'
import { PaymentProfiles } from '@modules/payments/components/payment-profiles'
import { NewCardForm } from '../components/new-card-form'

type Props = PageProps & PaymentProfilesResponse & { forGuest?: boolean, openSignUp?: () => void }

const NewPaymentMethodWrap = styled.div`
    max-width: 550px;
`

export const AddCardShared = (props: Props) => {
    const [newCard, setNewCard] = React.useState<boolean>(false)
    const [selectedProfile, setSelectedProfile] = React.useState<number>(0)
    const { httpClient, navigate, params, user, paymentProfiles, forGuest, openSignUp } = props
    const { organization } = params
    const initialValues = {
        defaultPayment: false,
    }

    const onSubmit = async (values: any) => {
        const billingAddress = {
            street1: values.street1,
            street2: values.street2,
            country: values.country,
            city: values.city,
            postalCode: values.postalCode,
            province: values.province,
        }
        const fullNumber = values.fullNumber.replace(/\u2000/g, '').trim()
        const creditCard = {
            firstName: values.firstName || undefined,
            lastName: values.lastName || undefined,
            fullNumber,
            expirationMonth: parseInt(values.expiration.split('/')[0]),
            expirationYear: parseInt(values.expiration.split('/')[1]),
            cvv: values.cvv || undefined
        }

        const newProfile = {
            organization,
            billingAddress,
            paymentMethod: 'credit_card',
            paymentToken: '',
            creditCard,
        } as NewPaymentProfileRequest

        const newPaymentProfile = await createPaymentProfile(httpClient)({ entity: user?.id! }, newProfile)
        if (newPaymentProfile?.payment_profile || paymentProfiles?.length === 0) {
            const profileId = newPaymentProfile.payment_profile?.id
            if (values?.defaultPayment! || paymentProfiles?.length === 0) {
                await defaultPaymentProfile(httpClient)({ entity: user?.id, profile: profileId }, {})
            }
            navigate(viewCardPath, { card: profileId })
        }
        else console.log('create Security error') // TODO: add error message toast
    }

    const handleNewCard = () => {
        setNewCard(true)
        setSelectedProfile(0)
    }
    const selectProfile = (profile: number) => {
        setNewCard(false)
        setSelectedProfile(profile)
    }

    const hasProfiles = paymentProfiles && paymentProfiles.length > 0 ? true : false

    const parent: BaseLinkProps = { path: accountPath, args: {} }

    return (
        <PageContent withMargin={forGuest}>
            <PageContentHeader title="Add New Payment Method" parent={parent} hideCrumbs={false} />
            <NewPaymentMethodWrap>
                <PaymentProfiles
                    newCard={newCard}
                    setNewCard={() => handleNewCard()}
                    selectedProfile={selectedProfile}
                    setSelectedProfile={selectProfile} />
                {newCard &&
                    <CommonForm
                        mutators={{ ...arrayMutators }}
                        onSubmit={!forGuest ? onSubmit : openSignUp}
                        initialValues={initialValues}
                        submitText="Save">
                        <NewCardForm hasProfiles={hasProfiles} />
                    </CommonForm>}
            </NewPaymentMethodWrap>
        </PageContent>
    )
}
