import styled from 'styled-components'
import Select from 'react-select'
import { colors, fieldLabel, Themes } from '@src/styles'
import { inputSpacing } from '@components/forms'

export const StyledSelect = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: ${inputSpacing};
  > div {
    ${fieldLabel}
  }
  select {
    &::placeholder {
      color: ${props => props.theme.gray};
    }
  }
`

export const StyledMultiSelect = styled<any>(Select)`
  border: ${props => props.hasErrors && `2px solid ${colors.errorRed}`};
  border-radius: 5px;
  input {
    color: ${props => props.theme.white} !important;
  }
  & div[class*='-placeholder'] {
    color: ${props => props.theme.listDesc};
  }
  & div[class*='-singleValue'] {
    width: 100%;
    color: ${props => props.theme.white};
  }
  & div[class*='-option'] {
    cursor: pointer;
    color: ${props => props.theme.white};
    padding: 8px 16px;
    background: transparent;
    &:hover, :focus {
      background: ${props => props.theme.accent};
      color: ${props => props.theme.selected === Themes.LIGHT && colors.white};
    }
  }
  & div[class*='-9gakcf'] {
    color: ${props => props.theme.accent} !important;
    background: transparent !important;
    cursor: default;
    &:before {
      content: '✔ ' !important;
    }
  }
  & div[class*='-menu'] {
    z-index: 999;
    background: ${props => props.theme.drop.options};
  }
  & div[class*='-Input'] {
    margin: 0;
    padding: 0 !important;
  }
  & span[class*='-indicatorSeparator'] {
    visibility: hidden;
  }
  & div[class*='-indicatorContainer'] {
    padding: 2px;
    svg {
      path {
        fill: ${props => props.theme.listText};
      }
    }
  }
  & div[class*='-control'] {
    background: ${props => props.filled === 'true' ? props.theme.input.basic : props.theme.input.default};
    border: none;
    padding: 8px 12px;
    box-shadow: none;
    min-height: 48px;
    :hover, :focus {
      background: ${props => props.theme.input.basic} !important;
      box-shadow: 0 1px 0 0 ${props => props.theme.accent},
                0 -1px 0 0 ${props => props.theme.accent},
                -1px 0 0 0 ${props => props.theme.accent},
                1px 0 0 0 ${props => props.theme.accent},
                0 0 0 1px ${props => props.theme.accent};
    }
    > div:first-child {
      padding: 0 !important;
    }
    :disabled {
      background: rgba(81, 111, 119, 0.1) !important;
    }
  }
  & div[class*='-multiValue'] {
    background: ${props => props.theme.drop.selected};
    border-radius: 4px;
    div {
      color: ${props => props.theme.white};
    }
  }
  & div[class*='-xb97g8'] {
    svg {
      path {
        fill: ${props => props.theme.listText};
      }
    }
    &:hover {
      background: ${colors.errorRed};
      svg {
        path {
          fill: ${colors.white};
        }
      }
    }
  }
  & svg[class*='-Svg'] {
    cursor: pointer;
    path {
      fill: ${props => props.theme.listText};
    }
    :hover {
      path {
          fill: ${colors.green};
      }
    }
  }
`

export const GroupMultiSelect = styled<any>(StyledMultiSelect)`
  min-width: 212px;
  & div[class*='-control'] {
    min-height: 40px;
  }
`
