import * as React from 'react'
import { ShareholdersProps } from '@helpers/shareholdings'
import { optionalCommaNumber, percentageString, toCommaFloat, Uuid } from '@helpers/util'
import { PanelGrid, SimplePanel } from '@components/panels/panels'
import { optionalUsdString } from '@modules/convertibles/components'

interface TotalsProps {
  totalUnits: number
  totalCashRaised: number
  holderCount: number
}

type Props = ShareholdersProps & TotalsProps & { currentMember?: Uuid }

export const OrganizationCapital = (props: Props) => {
  const { currentMember, shareholders, totalUnits, totalCashRaised, holderCount } = props
  const currentShareholder = currentMember ? shareholders.filter(s => s.memberId == currentMember)[0] : undefined
  const currentShareholderShares = currentShareholder ? (currentShareholder.memberOutstanding || 0) : 0
  const holdersCount = optionalCommaNumber(holderCount) || '0'
  const total = toCommaFloat(totalUnits)
  const cashRaised = optionalUsdString(totalCashRaised, 2) || '$0'
  const columns = (holdersCount && holdersCount.length > 9 ||
    total && total.length > 9 ||
    cashRaised && cashRaised.length > 9) ? 2 : 4
  return (
    <PanelGrid columns={columns}>
      <SimplePanel value={holdersCount} description="Total Holders" />
      <SimplePanel value={total} description="Total Shares" />
      <SimplePanel value={percentageString(currentShareholderShares, totalUnits || 0)} description="My Shares" />
      <SimplePanel value={cashRaised} description="Total Cash Raised" />
    </PanelGrid>
  )
}
