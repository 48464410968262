import { RootState } from '../types/types'
import { connectRouter } from 'connected-react-router'
import { History } from 'history'
import { serviceReducer } from './service-reducer'
import { combineDefinedReducers, combineReducers, pipeDefinedReducers, wrapDefinedReducer } from '../lib/utility'
import { userReducer } from './user-reducer'
import { organizationReducer } from './organization-reducer'
import { toastsReducer } from './toasts-reducer'
import { accessReducer } from './access-reducer'
import { myReferralReducer } from './my-referral-reducer'
import { capTableReducer } from './captable-reducer'
import { Service } from '@src/service'
import { checklistReducer } from './checklist-reducer'

export const rootReducer = (history: History, service: Service) =>
  wrapDefinedReducer(
    pipeDefinedReducers<any>([
      combineReducers<RootState>({
        router: connectRouter(history),
      }),
      combineDefinedReducers<RootState>({
        user: userReducer,
        organization: organizationReducer,
        access: accessReducer,
        toasts: toastsReducer,
        myReferral: myReferralReducer,
        capTable: capTableReducer,
        checklist: checklistReducer,
      }),
      serviceReducer(service),
    ])
  )
