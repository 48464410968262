import * as React from 'react'
import { StyledHeader } from '../../header/style'
import { FlexRow } from '@src/styles'
import Logo from '@image/equa-logo-square.svg'

function component() {
  return (
    <StyledHeader>
      <FlexRow flex={1}>
        <Logo />
      </FlexRow>
      <FlexRow flex={1} />
    </StyledHeader>
  )
}

export const Header = component
