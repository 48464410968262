import * as React from 'react'
import {
  FieldGroup,
  renderFieldsSimple,
  textAreaInputFieldComponent,
  textInputFieldComponent
} from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers'
import styled from 'styled-components'
import { LightDivider } from '@src/styles'
import { MultiBoxField } from '@shared/components/multibox'

interface Props {
  values?: any
  edit?: boolean
}
const StyleCreateLegendForm = styled.div``

function addLegendsFields(edit: boolean): FieldGroup<any> {
  return [
    {
      name: 'name',
      label: 'Name',
      required: true,
      placeholder: 'Enter Name',
      validate: emptyValidator,
      component: textInputFieldComponent,
    },
    {
      name: 'content',
      label: 'Content',
      required: true,
      placeholder: 'Enter Content',
      validate: emptyValidator,
      component: textAreaInputFieldComponent()
    }
  ]
}

export const LegendForm = (props: Props) => {
  return (
    <StyleCreateLegendForm>
      {renderFieldsSimple(addLegendsFields(props.edit!))}
      {props.edit! && <MultiBoxField name="updateReferences" text="Update Holdings" type="checkbox" />}
    </StyleCreateLegendForm>
  )
}
