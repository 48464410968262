import * as React from 'react'
import { ComponentType, useEffect, useState } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'
import { fullScreenOvershadow, screenSizes } from '@src/styles'
import { PageProps } from '@logic'
import { getFieldValues, wrapFunction } from '@helpers/util'
import EquaLoading from '@image/equa-loading.svg'

export const LoadingStyle = styled.div<any>`
  ${fullScreenOvershadow}
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${screenSizes.M}px) {
    svg {
      width: 100px;
      height: 100px;
    }
  }
`
export const StyledLoader = styled<any>(ReactLoading)`
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
  top: 42%;
  left: 50%;
  color: ${props => props.theme.white};
`

export const Loading = (props: any) => {
  return (
    <LoadingStyle>
      {/* <StyledLoader type={'bars'} height={200} width={200} /> */}
      <EquaLoading />
    </LoadingStyle>
  )
}

export type LoadData<T, P = PageProps> = (props: P) => Promise<T>

let c = 0

interface CachedDataBundle<T> {
  value?: T
  reload?: () => void
  loaded: boolean
}

export function withCachedLoading<Data, External>(
  load: LoadData<Data, External>,
  watchFields?: string[],
  hideLoader?: boolean
): (WrappedComponent: ComponentType<External & Data>) => ComponentType<External> {
  return WrappedComponent => {
    return (props: External) => {
      const initialState = { loaded: false }
      const [dataBundle, setData] = useState<CachedDataBundle<Data>>(initialState)
      const data = dataBundle.value
      const lc = ++c
      // console.log(lc, 'starting')
      let isSubscribed = true

      function reload() {
        setData(initialState)
      }

      async function loadAndSet() {
        // console.log(lc, 'loading data')
        const response = await load(props)
        if (isSubscribed) {
          // console.log(lc, 'setting data', response)
          setData({ value: response as any, loaded: true })
        }
      }

      useEffect(
        wrapFunction(
          async () => {
            if (!dataBundle.loaded) {
              // console.log(lc, 'useEffect1')
              await loadAndSet()
            }
          },
          () => {
            isSubscribed = false
          }
        ),
        [dataBundle]
      )

      if (watchFields) {
        const watchedProps = getFieldValues(watchFields)(props)
        // console.log('watchedProps', watchedProps)
        useEffect(
          wrapFunction(async () => {
            await loadAndSet()
          }),
          watchedProps
        )
      }
      return (data ?
        <WrappedComponent reload={reload} {...props} {...data} /> :
        hideLoader ? null : <Loading />) as any
    }
  }
}

export function composeLoaders<ComposedType>(loaders: Array<LoadData<any>>): LoadData<ComposedType> {
  return async props => {
    const promises = loaders.map(load => load(props))
    const results = await Promise.all(promises)
    return results.reduce((a, b) => ({ ...a, ...b }), {})
  }
}

export function withLoadingCachedMultiple<Data, External extends PageProps = PageProps>(
  ...loaders: Array<LoadData<any>>
): (WrappedComponent: ComponentType<External & Data>) => ComponentType<External> {
  const result = withCachedLoading<Data, External>(composeLoaders<Data>(loaders))
  return result
}
