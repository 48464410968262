import { SocialMediaProps } from '@modules/referrals/components/modal/social-media-links'
import * as React from 'react'

export const SharingButton = (props: SocialMediaProps) => {
    const { type, icon, fullUrl } = props
    return <a
        className={`react-sharing-button__link react-sharing-button--${type}`}
        href={fullUrl}
        target="_blank"
        rel="noopener noreferrer"
    >
        <img src={icon} alt='shareButton' />
    </a>
}
