import { AnyAction, Reducer } from 'redux'
import { CapTableState } from '../types/types'
import { ActionType } from '../actions'

export const capTableReducer: Reducer<CapTableState, AnyAction> = (state = { isLoading: true, isError: false }, action: any) => {
  switch (action.type) {
    case ActionType.GET_CAP_TABLE_START: {
      return { ...state, isLoading: true, isError: false }
    }
    case ActionType.GET_CAP_TABLE_SUCCESS: {
      return { ...state, capTableData: action.data, isLoading: false, isError: false }
    }
    case ActionType.ADD_TEAM_MEMBER_SUCCESS: {
      return { ...state, addMemberResponse: action.data, isLoading: false }
    }
    case ActionType.GET_CAP_TABLE_FAILURE: {
      return { ...state, isLoading: false, capTableData: action.data, isError: true }
    }
  }
  return state
}
