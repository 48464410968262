import React from 'react'
import {
    FieldGroup,
    selectFieldComponent,
    renderFieldsSimple,
    textInputFieldComponent,
    textInputFieldComponentAdvanced,
    creditCardInputFieldComponent,
} from '@shared/components/forms'
import { FlexRow, InputGroup, LightDivider } from '@src/styles'
import { renderHeader } from '@components/forms'
import { emptyValidator, isCVC, isExpirationDate, isPostalCode, isCreditCardNumber, isStreet, isCountry, isProvince, isCity } from '@shared/helpers'
import styled from 'styled-components'
import { formatExpirationDate } from '../utility'
import { countries } from '@shared/helpers/countries'
import { getCard } from './billing-history'

interface Props {
    values?: any
}

const StyledNewCardWrap = styled.div``

const CardNumberWrap = styled.div`
    position: relative;
`

const CardWrap = styled.div`
    position: absolute;
    right: 14px;
    top: 34px;
    svg {
        height: 40px;
    }
`

const countriesFormatted = countries.map(country => ({
    label: country.label,
    value: country.alpha3,
}))

function addressFields(): FieldGroup<any> {
    return [
        {
            name: 'country',
            label: 'Country',
            required: true,
            validate: isCountry,
            component: selectFieldComponent(countriesFormatted, 'Country'),
        },
        {
            name: 'street1',
            label: 'Street Address',
            required: true,
            placeholder: 'Enter Street Address',
            validate: isStreet,
            component: textInputFieldComponent,
        },
        {
            name: 'street2',
            label: 'Street Address 2',
            required: false,
            placeholder: 'Enter Street Address',
            validate: isStreet,
            component: textInputFieldComponent,
        },
        {
            name: 'postalCode',
            label: 'Postal Code',
            required: true,
            placeholder: 'Enter Postal Code',
            validate: isPostalCode,
            component: textInputFieldComponent,
        },
        {
            name: 'province',
            label: 'State',
            required: true,
            placeholder: 'Enter State',
            validate: isProvince,
            component: textInputFieldComponent,
        },
        {
            name: 'city',
            label: 'City',
            required: true,
            placeholder: 'Enter City',
            validate: isCity,
            component: textInputFieldComponent,
        },
    ]
}

function cardFields(): FieldGroup<any> {
    return [
        {
            name: 'firstName',
            label: 'First Name',
            required: false,
            placeholder: 'Enter First Name',
            validate: emptyValidator,
            component: textInputFieldComponent,
        },
        {
            name: 'lastName',
            label: 'Last Name',
            required: false,
            placeholder: 'Enter Last Name',
            validate: emptyValidator,
            component: textInputFieldComponent,
        },
    ]
}

export const CardForm = (props: Props) => {
    const { values } = props
    return (
        <StyledNewCardWrap>
            {renderHeader({ label: 'Billing Address' })}
            {renderFieldsSimple(addressFields())}
            {renderHeader({ label: 'Card Details' })}
            {renderFieldsSimple(cardFields(), true)}
            <CardNumberWrap>
                {renderFieldsSimple([
                    {
                        name: 'fullNumber',
                        label: 'Card Number',
                        required: true,
                        placeholder: 'Enter Card Number',
                        validate: isCreditCardNumber,
                        component: creditCardInputFieldComponent,
                    }
                ], true)}
                {values.fullNumber?.length > 0 && <CardWrap>{getCard(values.fullNumber.substring(0, 2))}</CardWrap>}
            </CardNumberWrap>
            <InputGroup alignItems={'start'}>
                {renderFieldsSimple([
                    {
                        name: 'expiration',
                        pattern: 'dd/dd',
                        label: 'Valid Thru (mm/yyyy)',
                        placeholder: 'Enter Date',
                        format: formatExpirationDate,
                        validate: isExpirationDate,
                        component: textInputFieldComponentAdvanced({ mask: [/\d/, /\d/, '\u2000', /\d/, /\d/, /\d/, /\d/], placeholderChar: '/' }),
                    },
                ])}
                {renderFieldsSimple([
                    {
                        name: 'cvv',
                        label: 'Security Code',
                        required: true,
                        placeholder: 'Enter Security Code',
                        validate: isCVC(values?.fullNumber!),
                        component: textInputFieldComponent,
                    },
                ])}
            </InputGroup>
        </StyledNewCardWrap>
    )
}
