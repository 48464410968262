import * as React from 'react'
import { editPermissionsPath, MembersProps, PageProps, viewRolePath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers, loadRoles } from '@helpers/loaders'
import { CommonForm, Roles } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { editRole, UpdateRoleRequest, NewMemberRoleRequest, editMemberRole, GetRolesResponse } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { preparePermissionsForSubmission, RoleForm } from '../components/role-form'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { colors, FlexRow, whiteColor } from '@src/styles'
import { RolesWrapper, SubHeaderText } from './create-role-page'
import { allPermissions, getRolesFooterBanner } from '../utility'
import AdminShield from '@image/admin.svg'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'
import { getQueryParams } from '@shared/helpers'

type Props = PageProps & MembersProps & GetRolesResponse & ThemeProps<any>

const FormWrapper = styled.div<any>`
`

const withData = withLoadingCachedMultiple<Props>(loadRoles, loadMembers)

export const EditRolePage = withData(withTheme(withPermissions([BuiltInPermission.editMembers])((props: Props) => {
    const { httpClient, navigate, params, members, theme, roles, user } = props
    const { organization, role } = params
    const fromPermissions = getQueryParams().matrix!
    const currentRole = roles?.find(r => r.id === role)
    const sortedMembers = members?.sort((a, b) => {
        return a.fullName.localeCompare(b.fullName)
    })
    const permission: any = {}
    currentRole?.permissions?.forEach(p => {
        permission[`${p.id}`] = true
    })
    const assignedMembers = members?.filter(m => m.roles.includes(currentRole?.id))?.map(m => m.id)
    const initialValues = {
        role: currentRole?.name!,
        description: currentRole?.description!,
        permission,
        all: currentRole?.permissions?.length === allPermissions?.length,
        members: assignedMembers!
    }

    const onSubmit = async (values: any) => {
        const data = preparePermissionsForSubmission(values)
        if (currentRole?.isShared) {
            if (values?.members) {
                const memberRoleRequest = {
                    member: values?.members!,
                } as NewMemberRoleRequest
                await editMemberRole(httpClient)({ organization, role: currentRole?.id }, memberRoleRequest)
            }
            navigate(viewRolePath, { organization, role: currentRole?.id })
        }
        else {
            const request = {
                role: currentRole?.id!,
                newRole: data.role,
                description: data.description,
                permission: data.permission!,
            } as UpdateRoleRequest
            const response = await editRole(httpClient)({ organization, role: currentRole?.id! }, request)
            if (response?.id) {
                const roleId = response.id!
                if (values?.members?.length > 0) {
                    const memberRoleRequest = {
                        member: values?.members!,
                    } as NewMemberRoleRequest
                    await editMemberRole(httpClient)({ organization, role: roleId }, memberRoleRequest)
                }
                if (!fromPermissions) navigate(viewRolePath, { organization, role: roleId })
                else navigate(editPermissionsPath, { organization })
            }
            else console.log('create role error') // TODO: add error message toast
        }
    }
    const isAdmin = currentRole?.isShared!
    const isAdminRole = Roles.admin === currentRole?.id!
    const parent: BaseLinkProps = { path: viewRolePath, args: { organization, role: currentRole?.id! } }

    return (
        <RolesWrapper>
            <PageContent>
                <PageContentHeader title={isAdmin ? currentRole?.name! : `Edit ${currentRole?.name!}`} parent={parent} noSpace={isAdmin}>
                    <FlexRow justifyContent={'flex-end'} style={isAdmin ? { marginLeft: '16px' } : { marginLeft: 'auto' }}>
                        {isAdmin && <AdminShield />}
                    </FlexRow>
                </PageContentHeader>
                <SubHeaderText style={{ marginTop: '11px' }}>{currentRole?.description!}</SubHeaderText>
                <FormWrapper>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                        stretched={true}>
                        <RoleForm
                            isAdmin={isAdmin}
                            isAdminRole={isAdminRole}
                            members={sortedMembers}
                            roles={roles}
                            edit={true}
                            skip={{ path: viewRolePath, args: { organization, role: currentRole?.id! } }}
                        />
                    </CommonForm>
                </FormWrapper>
            </PageContent>
            <img src={getRolesFooterBanner(theme?.selected!)} alt="rolesFooter" />
        </RolesWrapper>
    )
})))
