import * as React from 'react'
import { PageProps, billingPath, NewPaymentProfileRequest } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import styled from 'styled-components'
import { CommonForm } from '@components/forms'
import { BaseLinkProps } from '@shared/components/navigation'
import { PaymentProfiles } from '../components/payment-profiles'
import arrayMutators from 'final-form-arrays'
import { CardForm } from '../components/card-form'
import { createPaymentProfile } from '@src/service'
import { getQueryParams } from '@shared/helpers'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>()

const NewPaymentMethodWrap = styled.div`
    max-width: 550px;
`

export const NewPaymentProfilePage = withData(
    (props: Props) => {
        const [newCard, setNewCard] = React.useState<boolean>(false)
        const [selectedProfile, setSelectedProfile] = React.useState<number>(0)
        const { httpClient, navigate, params } = props
        const { organization } = params
        const initialValues = {}

        const onSubmit = async (values: any) => {
            const billingAddress = {
                street1: values.street1,
                street2: values.street2,
                country: values.country,
                city: values.city,
                postalCode: values.postalCode,
                province: values.province,
            }
            const fullNumber = values.fullNumber.replace(/\u2000/g, '').trim()

            const creditCard = {
                firstName: values.firstName || undefined,
                lastName: values.lastName || undefined,
                fullNumber,
                expirationMonth: parseInt(values.expiration.split('/')[0]),
                expirationYear: parseInt(values.expiration.split('/')[1]),
                cvv: values.cvv || undefined
            }

            const newProfile = {
                organization,
                billingAddress,
                paymentMethod: 'credit_card',
                paymentToken: '',
                creditCard,
            } as NewPaymentProfileRequest

            const newPaymentProfile = await createPaymentProfile(httpClient)({ entity: organization }, newProfile)
            if (newPaymentProfile?.payment_profile) {
                const query = getQueryParams()
                navigate(query.parent ? query.parent : billingPath, { organization })
            }
            else console.log('create Security error') // TODO: add error message toast
        }

        const handleNewCard = () => {
            setNewCard(true)
            setSelectedProfile(0)
        }
        const selectProfile = (profile: number) => {
            setNewCard(false)
            setSelectedProfile(profile)
        }

        const parent: BaseLinkProps = { path: billingPath, args: { organization } }

        return (
            <PageContent>
                <PageContentHeader title="Add New Payment Method" parent={parent} hideCrumbs={false}/>
                <NewPaymentMethodWrap>
                    <PaymentProfiles
                        newCard={newCard}
                        setNewCard={() => handleNewCard()}
                        selectedProfile={selectedProfile}
                        setSelectedProfile={selectProfile} />
                    {newCard &&
                        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                            submitText="Save payment method">
                            <CardForm />
                        </CommonForm>}
                </NewPaymentMethodWrap>
            </PageContent>
        )
    }
)
