import { TaskDetails } from '@modules/actions/types'
import { captablePath, organizationMembersPath, Paths } from '@logic'

export enum CommonAction {
  completeSetup = 'b9614816-3a6f-4364-b001-495d6db96dba',
  uploadDocuments = '2bcc2a5e-bf09-44c7-a7ae-a33a5f2847df',
  addMembers = '48608f3d-3e28-4d56-8188-b34a7d667d17',
  buildCaptable = 'f8804016-0cc7-403b-aa84-afa1cc1db9dd',
  // handoffCall = '5904d1c0-6dae-460f-8fed-12fcb33e979b',
}

export const commonTasks: TaskDetails[] = [
  {
    name: 'Complete your organization setup',
    description: `Enter some more information about your organization.`,
    actionType: CommonAction.completeSetup,
    buttonText: 'Complete Organization Setup',
    path: Paths.organizationStructure,
  },
  {
    name: 'Upload organization documents to your private data room',
    description: `Enter some more information about your organization.`,
    actionType: CommonAction.uploadDocuments,
    buttonText: 'Upload Documents',
    path: Paths.organizationDataRoom,
  },
  {
    name: 'Invite team members to your organization',
    description: `You can add team members to your organization’s cap table and give them access to important documents.`,
    actionType: CommonAction.addMembers,
    buttonText: 'Invite Team Members',
    path: organizationMembersPath,
  },
  {
    name: 'Build your cap table',
    description: 'Once you have added team members, you can assign shares to them, automaticaly generate share ' +
      'certificates, and add them to your organization’S living cap table.',
    actionType: CommonAction.buildCaptable,
    buttonText: 'Build Your Cap Table',
    path: captablePath,
  },
]

export const actionIds = {
  transfer: 'd95f46d1-3980-4994-bea7-51229222afcd',
  draft: '1327db9b-4576-4641-b6b6-6355308c8e1b',
  capitalCall: 'b4e83b45-5509-4ecb-a912-3de7ce4149dd',
  boardMeeting: 'bef484e2-6aff-419a-b6bf-073374c1e784',
  updateGoverningDocuments: 'adb822ab-2d74-4640-8890-21b5280dcb0d',
  signAnAgreement: 'f381553e-9835-4157-a0b8-dea57d8da35d',
  collaborate: '2b068df9-cfba-497e-b99b-3e808547791e',
  newDataRoom: '81580fe5-981a-4a14-ae54-fd7471a4df8b',
  newOrganization: '172575b9-f79a-4b15-9e8f-3b2362a6d902',
  incorporateNewOrganization: '022a3f26-e061-4efa-a539-ba20e76aee60',
  upgradeTransferAgent: '7aec822e-6bef-4cb6-a59a-2157ccecaaec',
}
