import * as React from 'react'
import { GetReferralDataResponse, OrganizationsProps, PageProps } from '@logic'
import { loadOrganizations, loadReferralsData } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { TransferEquaCashShared } from '../components/equa-transfer-component'

type Props = PageProps & OrganizationsProps & GetReferralDataResponse

const withData = withLoadingCachedMultiple<Props>(loadReferralsData, loadOrganizations)

export const TransferEquaCash = withData((props: Props) => {
    return <TransferEquaCashShared {...props} />
})
