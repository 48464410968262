import * as React from 'react'
import { emptyValidator } from '@helpers/field-validators'
import { FieldGroup, protectedInputFieldComponent, renderFieldsSimple, renderSideFields, textInputFieldComponent, } from '@shared/components/forms'
import { isConfirmPassword, isEmail, isPassword } from '@shared/helpers'
import styled from 'styled-components'
import { FlexRow, screenSizes } from '@src/styles'
import { MultiBoxField } from '@shared/components/multibox'
import { LoginSignButton } from '@shared/components/button'

// @ts-ignore
import { ReCaptcha } from 'react-recaptcha-google'
import { LogoHeading } from './logo-heading'

interface Props {
    verified: boolean
    setVerified: (verified: boolean) => void
    values?: any
    invalid?: boolean
}

declare var CAPTCHA_SITE_KEY: string

const SignUpFormWrapper = styled.div<any>`
.SignupText{
    margin: 32px 0px 24px 0px;
    font-size: 34px;
    line-height: 46px;
    font-weight: 600;
}
`

const InputRow = styled<any>(FlexRow)`
  justify-content: space-between;
  align-items: center;

  div {
      flex: 1;
  }
  div + div {
    margin-left: 12px;
  }
  @media (max-width: ${screenSizes.S}px) {
    flex-direction: column;
    align-items: center;
    > div {
      width: 100%;
    }
  }
`

const SignUpLayout = styled<any>(FlexRow)`
  > div:first-child {
    height: fit-content;
  }
  @media (max-width: ${screenSizes.XL}px) {
    flex-direction: column;
  }
`

const StyledRow = styled.div<any>`
  color: ${props => props.theme.white};
  font-size: 14px;
`

function nameFields(): FieldGroup<any> {
    return [
        {
            name: 'first',
            label: 'First Name',
            required: true,
            asterisks: false,
            validate: emptyValidator,
            placeholder: 'John',
            component: textInputFieldComponent
        },
        {
            name: 'last',
            label: 'Last Name',
            required: true,
            asterisks: false,
            validate: emptyValidator,
            placeholder: 'Doe',
            component: textInputFieldComponent
        },
    ]
}

function signUpFields(values: any): FieldGroup<any> {
    return [
        {
            name: 'email',
            label: 'Email Address',
            required: true,
            asterisks: false,
            placeholder: 'example@gmail.com',
            validate: isEmail,
            component: textInputFieldComponent,
        },
        {
            name: 'password',
            label: 'Password',
            required: true,
            asterisks: false,
            placeholder: '● ● ● ● ● ● ● ● ',
            validate: isPassword,
            component: protectedInputFieldComponent({}, 'text', true),
            note: "Minimum 4 characters"
        },
        {
            name: 'passwordConfirm',
            label: 'Confirm Password',
            required: true,
            asterisks: false,
            placeholder: '● ● ● ● ● ● ● ● ',
            validate: isConfirmPassword(values),
            component: protectedInputFieldComponent({}, 'text', true),
            note: "Minimum 4 characters"
        },
    ]
}

export const SignUpForm = (props: Props) => {
    const { setVerified, values, verified, invalid } = props

    return (
        <SignUpFormWrapper>
            <LogoHeading />
            <div className='SignupText'>Sign Up</div>
            <InputRow>
                {renderSideFields(nameFields(), true)}
            </InputRow>
            {renderFieldsSimple(signUpFields(values), true)}
            <FlexRow>
                <MultiBoxField
                    name="acceptedTerms"
                    id="acceptedTerms"
                    text={
                        <StyledRow>
                            I agree and consent to the{' '}
                            <a href="https://equa.global/privacy-policy/" target="_blank">
                                Privacy Policy{' '}
                            </a>
                            and{' '}
                            <a href="https://equa.global/terms-of-use/" target="_blank">
                                Terms of Use{' '}
                            </a>
                        </StyledRow>
                    }
                    type="checkbox"
                />
            </FlexRow>
            <ReCaptcha
                size="visible"
                render="explicit"
                sitekey={CAPTCHA_SITE_KEY}
                onloadCallback={() => console.log('captcha loaded')}
                verifyCallback={() => setVerified(true)}
                expiredCallback={() => setVerified(false)}
            />
            <LoginSignButton type="submit" disabled={!values.acceptedTerms || !values.email || !values.password || invalid || !verified}>
                SIGN UP
            </LoginSignButton>
        </SignUpFormWrapper>
    )
}
