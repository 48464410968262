import * as React from 'react'
import { useState } from 'react'
import { MembersProps, organizationMembersPath, PageProps, setExpanded } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers } from '@helpers/loaders'
import { getQueryParams, Uuid } from '@helpers/util'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { loadPermissions } from '@components/permissions'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { FormRow, StyledCommonForm } from '@components/forms'
import { Set } from 'immutable'
import { BaseLinkProps } from '@components/navigation'
import { InvitedMemberRow } from '@modules/team-members/components/invited-member-row'
import { Form } from 'react-final-form'
import { CommonSubmissionFooter } from '@components/forms/common-submission-footer'
import { displayErrorToast, globalDisplayToast, HttpError, inviteMembers, InviteMembersResponse } from '@src/service'
import { InvitationSuccess } from '@modules/team-members/components/invitation-success'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & MembersProps & StoreProps

const withData = withLoadingCachedMultiple<Props>(loadMembers, loadPermissions)

const InviteMembersComponent = withData(
  withPermissions([BuiltInPermission.editMembers])((props: Props) => {
    const { members, httpClient, setExpanded } = props
    const { organization } = props.params
    const queryParams = getQueryParams()
    const reducedMembers = (queryParams.members ? queryParams.members.split(',') : []) as Uuid[]
    const membersWithoutEmail = members
      .filter(m => !m.email)
      .map(m => m.id)

    const [selection, setSelection] = useState<Set<Uuid>>(Set(reducedMembers).subtract(membersWithoutEmail))
    const [submissionResult, setSubmissionResult] = useState<InviteMembersResponse | undefined>(undefined)

    const isSelected = (member: Uuid) => {
      return selection.includes(member)
    }

    const toggleSelected = (member: Uuid) => {
      const modifiedSelection = isSelected(member)
        ? selection.remove(member)
        : selection.add(member)

      setSelection(modifiedSelection)
    }

    const parent: BaseLinkProps = { path: organizationMembersPath, args: { organization } }
    const onSubmit = async () => {
      const response = await inviteMembers(httpClient)({ organization }, { members: selection.toArray() })
      if (response instanceof HttpError) {
        displayErrorToast('There was an error sending the invitations')
      } else {
        setExpanded()
        setSubmissionResult(response)
      }
    }

    const options = members.filter(member => reducedMembers.indexOf(member.id) !== -1)
    const memberRows = options.map(member => (
      <div key={member.id}>
        <InvitedMemberRow name={member.fullName} email={member.email} selected={isSelected(member.id)}
                          onChange={() => toggleSelected(member.id)} />
      </div>
    ))

    return (
      <>
        <PageContent>
          <PageContentHeader title="Invite Team Members" parent={parent} />
          {
            submissionResult
              ? <InvitationSuccess emails={submissionResult!.emails} organization={organization} />
              : <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                  <FormRow>
                    <StyledCommonForm onSubmit={handleSubmit}>
                      {memberRows}
                      <CommonSubmissionFooter
                        submitText={`Send ${selection.size} Invite${selection.size != 1 ? 's' : ''}`}
                        disabled={selection.isEmpty()} />
                    </StyledCommonForm>
                  </FormRow>
                )
                }
              />
          }
        </PageContent>
      </>
    )
  })
)

export const InviteMembersPage = connect(null, { setExpanded })(InviteMembersComponent)
