import * as React from 'react'
import {
  DividendConfig,
  getDividendAccrual,
  getDividendCategory,
  getDividendCumulation,
  getDividendInterest,
  organizationEditSecurityPath,
  OrganizationProps,
  organizationSecuritiesPath,
  PageProps
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  loadAuthorizations,
  loadLegends,
  loadOrganizationHoldings,
  loadSecurities,
  loadSecurity
} from '@helpers/loaders'
import { DetailsLabel, DetailsMaxWidth, FlexRow, LightDivider, whiteColor } from '@src/styles'
import { renderHeader } from '@components/forms'
import {
  ExpandedHoldingsResponse,
  GetAuthorizationsResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  GetSecurityTypeResponse
} from '@src/service'
import {
  formatDateString,
  formatSecurityName,
  parseOptionalFloat,
  shareTypeNames,
  toCommaNumber
} from '@shared/helpers'
import styled from 'styled-components'
import { BaseLinkProps } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { SeniorityTable } from '@shared/components/seniority-table'
import { optionalUsdString } from '@modules/convertibles/components'

type Props = PageProps &
  OrganizationProps &
  GetSecuritiesResponse &
  GetSecurityTypeResponse &
  ExpandedHoldingsResponse &
  GetAuthorizationsResponse &
  GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>(
  loadSecurities(),
  loadSecurity(),
  loadOrganizationHoldings('all'),
  loadAuthorizations('security'),
  loadLegends(false)
)

const ViewSecurityWrapper = styled.div`
  ${whiteColor}
  ${DetailsMaxWidth}
`

const renderInfo = (label: string, value: any, index: number) => {
  return (
    <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
      <DetailsLabel style={{ color: '#9CA6AD' }}>{label}</DetailsLabel>
      <span>
        {value}
      </span>
    </FlexRow>
  )
}

function formatDividendRows(dividendConfig: DividendConfig) {
  const { category, cumulation, accrual, interest } = dividendConfig
  return [
    { label: 'Dividend Type', value: getDividendCategory[category] },
    { label: 'Dividend Yield', value: `${dividendConfig?.yield || 0}%` },
    { label: 'Dividend Cumulation', value: getDividendCumulation[cumulation] },
    { label: 'Dividend Accrual', value: getDividendAccrual[accrual] },
    { label: 'Compounding Interest', value: getDividendInterest[interest] },
  ]
}

export const ViewSecurityPage = withData(
  withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
    const { securities, params, holdings, authorizations, legends } = props
    const { organization, security } = params
    const {
      shareType,
      shareClass,
      shareClassPrefix,
      certifiedShares,
      fractionalShares,
      votingShares,
      sharePricing,
      dividendConfig,
      legend } = props.security
    const { parValue, pricePerShare, originalIssuePrice, conversionPrice, conversionRate, multipler, participatingPreferred } = sharePricing
    const currentAuth = authorizations && authorizations.length ? authorizations[0] : undefined
    const legendValue = legends?.find(l => l.hash === legend as any)
    const securitiesList = securities?.filter(s => !s.parent)

    const shareClassDetails = [
      { label: 'Class Type', value: shareTypeNames[shareType] },
      { label: 'Share Class', value: shareClass },
      { label: 'Prefix', value: shareClassPrefix },
      { label: 'Legend', value: legendValue?.name! },
      { label: 'Certified Shares', value: certifiedShares ? 'Yes' : 'No' },
      { label: 'Fractional Shares', value: fractionalShares ? 'Yes' : 'No' },
      { label: 'Voting Shares', value: votingShares ? 'Yes' : 'No' },
      { label: 'Total Authorized Shares', value: props.security.value ? toCommaNumber(props.security.value) : 0 },
    ]

    const incDocument = [
      { label: 'Incorporation Document', value: currentAuth?.document?.name! },
      { label: 'Filing Date', value: currentAuth?.authorizationDate ? formatDateString(currentAuth.authorizationDate) : '' },
    ]

    const sharePrices = [
      { label: 'Par Value', value: optionalUsdString(parValue) },
      { label: 'Price Per Share', value: optionalUsdString(pricePerShare) },
      { label: 'Original Issue Price', value: optionalUsdString(originalIssuePrice) },
      { label: 'Conversion Price', value: optionalUsdString(conversionPrice) },
      { label: 'Conversion Ratio', value: parseOptionalFloat(conversionRate) },
      { label: 'Multiplier', value: parseOptionalFloat(multipler) },
      { label: 'Participating Preferred', value: participatingPreferred ? 'Yes' : 'No' },
    ]

    const dividends = dividendConfig ? formatDividendRows(dividendConfig) : []

    const parent: BaseLinkProps = { path: organizationSecuritiesPath, args: { organization } }
    const securityName = formatSecurityName(props.security)

    return (
      <PageContent>
        <PageContentHeader title={securityName} parent={parent}>
          <PathLinkButton path={organizationEditSecurityPath} args={{ organization, security }}>
            Edit
          </PathLinkButton>
        </PageContentHeader>
        <ViewSecurityWrapper>
          {renderHeader({ label: 'Share Class Details' })}
          {shareClassDetails.map((el, index: number) => {
            return renderInfo(el.label, el.value, index)
          })}
          <LightDivider />
          {renderHeader({ label: 'Incorporation Document' })}
          {incDocument.map((el, index: number) => {
            return renderInfo(el.label, el.value, index)
          })}
          <LightDivider />
          {renderHeader({ label: 'Share Prices' })}
          {sharePrices.map((el, index: number) => {
            return renderInfo(el.label, el.value, index)
          })}
          <LightDivider />
          {renderHeader({ label: 'Seniority' })}
          <SeniorityTable securities={securitiesList} holdings={holdings} security={security} view={true} />
          <LightDivider />
          {dividends.length > 0 &&
            <> {renderHeader({ label: 'Dividends' })}
              {dividends.map((el, index: number) => {
                return renderInfo(el.label, el.value, index)
              })}
              <LightDivider />
            </>
          }
        </ViewSecurityWrapper>
      </PageContent>
    )
  }))
