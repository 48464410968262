import * as React from 'react'
import styled from 'styled-components'
import { colorChangeDelay } from '@src/styles'
import { StyledModal } from '@components/modal/style'
import { fontSizes } from '@src/styles/theme'

export const StyledModalForm = styled.div`
  margin: 0 auto;
  padding: 32px;
  border-radius: ${props => props.theme.radius};
  text-align: left;
  max-width: 812px;
  background-color: ${props => props.theme.inputBorder};
  box-shadow: ${props => props.theme.boxShadow};
   form {
    display: flex;
    flex-direction: column;
  }
  > h1,
  p {
    margin: 10px;
    line-height: 1.6em;
  }
  hr {
    margin: 20px -32px;
  }
  input {
    &:disabled {
      color: ${props => props.theme.gray};
      &::placeholder {
        color: ${props => props.theme.gray};
      }
    }
  }
`

export const FormButtonLayout = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
  margin-top: 30px;
`

export const StyledField = styled.div`
  margin-bottom: 32px;
`

export const StyledStepField = styled.div<any>`
  padding-bottom: 45px;
  transition: border-left-color ${colorChangeDelay};
  p {
    color: ${props => props.theme.white};
    font-size: 0.9em;
  }
  > div {
    padding: 20px;
    border-radius: ${props => props.theme.radius};
    border: 2px solid transparent;
    transition: 300ms border;
    outline: 0;
    background-color: ${props => props.theme.primary};
    > label {
      display: block;
      text-align: start;
      margin-bottom: 10px;
      font-size: 1.2em;
      line-height: 29px;
    }
  }

  > div:focus {
    border: 2px solid ${props => props.theme.accent};
  }

  > div:focus-within:not(${StyledModal}) {
    border: 2px solid ${props => props.theme.accent};
  }
`

export const FileTarget = styled.div`
  display: flex;
  align-items: center;
  background: rgba(2, 22, 15, 0.09); // TODO: May need to be #e7f1eb; (CJ)
  border-radius: ${props => props.theme.radius};
  margin-top: 24px;
  padding: 18px;
  border: 2px dashed #5a6872;
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  cursor: pointer;
  @media (min-width: 760px) {
    width: 75%;
  }
  > div {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.secondary};
    font-size: 14px;
  }
  h3,
  span,
  p {
    color: ${props => props.theme.white};
  }
  h3 {
    margin-bottom: 0;
    margin-top: 40px;
  }
  p {
    text-align: center;
    margin-top: 0;
    margin-bottom: 40px;
  }
  svg {
    max-width: 500px;
  }
  span {
    color: ${props => props.theme.accent};
  }
`
export const DetailsButtonLayout = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 20px;
  *:focus {
    outline: none;
  }
  div {
    background: transparent;
    cursor: pointer;
    color: ${({ theme }) => theme.accent};
    font-weight: 800;
    font-family: NunitoSansBold;
    font-size: ${fontSizes.XXS};
    line-height: 19px;
    border: none;
    padding: 0;
  }
`
