import * as React from 'react'
import {
  nonZeroCurrencyField,
  dateField,
  FieldGroup,
  renderFieldsSimple,
  renderHeader,
  selectFieldComponent,
  UploadHandler
} from '@components/forms'
import { emptyValidator, optionsFromMembers } from '@helpers/index'
import { Member } from '@logic'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'

interface Props {
  members: Member[]
  onUpload: UploadHandler
  values?: any
  edit?: boolean
}

function instrumentDetailsFields(onUpload: UploadHandler, members: Member[]): FieldGroup<any> {
  return [
    {
      name: 'owner',
      label: 'Select Team Member',
      required: true,
      validate: emptyValidator,
      placeholder: 'Select Team Member',
      component: selectFieldComponent(optionsFromMembers(members), 'Select Team Member'),
    },
    {
      name: 'issueDate',
      label: 'Issue Date',
      required: true,
      ...dateField(React.useRef())
    },
    {
      name: 'principle',
      label: 'Principle',
      required: true,
      placeholder: '$0.00',
      ...nonZeroCurrencyField
    },
  ]
}

export const ConvertibleNoteForm = (props: Props) => {
  const { onUpload, values, members } = props
  return (
    <>
      {renderHeader({ label: 'Convertible Instrument Details' })}
      {renderFieldsSimple(instrumentDetailsFields(onUpload, members))}
      <DocumentsAndNotes onUpload={onUpload} values={values} />
    </>
  )
}
