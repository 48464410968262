import * as React from 'react'
import { useState, useEffect } from 'react'
import { Container } from '@src/styles'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import {
  ConnectToGoogleWrapper,
  AddFriendsCont,
  InviteFriendsCont,
  AddContactText,
  AddContactDesc,
  SelectFriendsTxt,
  DisconnectedImgCont,
  DisconnectedGoogleText,
  ConnectedGoogle,
  DisconnectBtnCont,
  DisconnectBtn,
  SelectAllCont,
  SelectAllBtn,
  ContactListCont,
  ContactListItem,
  ContactListItemPic,
  ContactNameAndEmail,
  InviteControl,
} from './style'

import GmailPic from '@image/gmail.png'
import FallBackDp from '@image/fallBackDp.png'
import DisconnectedGoogleImg from '@image/DisconnectedGoogle.png'
import CrossImg from '@image/close-default.svg'
import GoogleSignIn from '../google-sign-in/google-sign-in'
import { getGoogleContact, GoogleContactDispatch, sendInvitation, SendInvitationDispatch } from '@logic'

interface Props extends RouteComponentProps<any> {
  isConnectedToGoogle: boolean
  toggleDisconnectGoogleModal: () => void
  toggleGoogleConnection: () => void
  invitedFriendList: any[]
  invitedFriends: any
  userId: any
}
interface StateProps {
  user: any
  googleContactList: any
  getGoogleContact: GoogleContactDispatch
  sendInvitation: SendInvitationDispatch
}

interface Contact {
  name?: string
  email: string
  imgSrc: string
  isInvited: boolean
  id: number
  contactId: string
}
interface State {
  contactList: Contact[]
}
const ConnectToGoogle = (props: Props & StateProps) => {
  // const [contactList, setContactList] = useState<any>([])
  const removedEmails: any = localStorage.getItem('removedEmails')
  const [removedList, setRemovedList] = useState<any>(removedEmails ? removedEmails.split(',') : [])

  useEffect(() => {
    const googleToken: any = localStorage.getItem('googleToken')
    if (googleToken) {
      props.getGoogleContact(googleToken)
    }
  }, [localStorage.getItem('googleToken')])

  const handleDisconnectGoogle = () => {
    const { toggleDisconnectGoogleModal } = props

    toggleDisconnectGoogleModal()
  }

  const renderConnectToGoogle = () => {
    const { isConnectedToGoogle, toggleGoogleConnection } = props
    if (!isConnectedToGoogle) {
      return (
        <>
          <div className="gmail-img">
            <img src={GmailPic} alt="gmail" />
          </div>
          <AddContactText>Add Your Contacts</AddContactText>
          <AddContactDesc>
            Import your contacts to get started. Only selected people receive a notification. We currently only support Gmail.
          </AddContactDesc>

          <GoogleSignIn toggleGoogleConnection={toggleGoogleConnection} />
        </>
      )
    } else {
      return (
        <>
          <ConnectedGoogle>
            <img src={GmailPic} alt="gmail" />
            <div className="text">Gmail account connected</div>
          </ConnectedGoogle>

          <DisconnectBtnCont>
            <DisconnectBtn onClick={() => handleDisconnectGoogle()}>
              disconnect
            </DisconnectBtn>
          </DisconnectBtnCont>
        </>
      )
    }
  }

  const renderContactListContent = () => {
    const { isConnectedToGoogle } = props
    if (!isConnectedToGoogle) {
      return (
        <>
          <SelectFriendsTxt>Select Friends to Invite</SelectFriendsTxt>
          <DisconnectedImgCont>
            <img src={DisconnectedGoogleImg} alt="disconnected google" />
          </DisconnectedImgCont>
          <DisconnectedGoogleText>Connect your Google account to start.</DisconnectedGoogleText>
        </>
      )
    } else {
      return (
        <>
          <SelectAllCont>
            <span>Select Friends to Invite</span>
            <SelectAllBtn type="button" onClick={() => handleInviteAll()}>
              invite all
            </SelectAllBtn>
          </SelectAllCont>
          <ContactListCont>{renderContactList()}</ContactListCont>
        </>
      )
    }
  }

  const addDefaultSrc = (e: any) => {
    e.target.src = FallBackDp
  }

  const renderContactList = () => {
    if (props.googleContactList) {
      return props.googleContactList.map((v: any, k: any) => {
        const removed = removedList.find((id: any) => id === v.id)
        const invited = props.invitedFriendList.find((contact: any) => contact.email === v.email)
        if (!removed) {
          return (
            <ContactListItem key={v.id}>
              <ContactListItemPic>
                <img src={FallBackDp /* v.imgSrc */} width="36px" height="36px" onError={addDefaultSrc} />
              </ContactListItemPic>
              <ContactNameAndEmail>
                {!!v.name && <div className="contact-name"> {v.name} </div>}
                <div className="contact-email"> {v.email} </div>
              </ContactNameAndEmail>

              <InviteControl>
                {invited ? (
                  <div className="invite-sent-contact">invite sent</div>
                ) : (
                  <button className="invite-contact" onClick={e => handleContactInvite(e, v.id)}>
                    invite
                  </button>
                )}
                <button type="button" style={{ cursor: 'pointer' }} onClick={() => removeContactFromContactList(v.id)}>
                  <CrossImg />
                </button>
              </InviteControl>
            </ContactListItem>
          )
        }
      })
    }

    return <div>no contacts</div>
  }

  // send email and change nothing about list
  const handleContactInvite = async (e: any, id: number) => {
    const button = e.currentTarget
    button.classList.toggle('disable-btn')
    const emailData: any = []
    if (props.user) {
      const contact = props.googleContactList.find((listContact: any) => listContact.id === id)
      emailData.push(contact.email)
      const data = { emailArray: emailData }
      await props.sendInvitation(data)
      props.invitedFriends(props.userId)
      // button.classList.toggle('disable-btn')
      const googleToken = localStorage.getItem('googleToken')
      if (googleToken) props.getGoogleContact(googleToken)
    }
  }

  const removeContactFromContactList = (id: number) => {
    const removeEmails: any = [...removedList, id]
    localStorage.setItem('removedEmails', removeEmails)
    setRemovedList(removeEmails)
  }

  const handleInviteAll = async () => {
    const emailData: any = []
    const list = props.googleContactList
    if (props.user) {
      for (const contact of list) {
        const removed = removedList.find((id: any) => id === contact.id)
        if (!removed) {
          const invited = props.invitedFriendList.find((listContact: any) => listContact.email === contact.email)
          if (!invited) {
            emailData.push(contact.email)
          }
        }
      }
      const data = { emailArray: emailData }
      await props.sendInvitation(data)
      props.invitedFriends(props.userId)
      const googleToken = localStorage.getItem('googleToken')
      if (googleToken) props.getGoogleContact(googleToken)
    }
  }

  return (
    <Container>
      <ConnectToGoogleWrapper>
        <AddFriendsCont>{renderConnectToGoogle()}</AddFriendsCont>
        <InviteFriendsCont>{renderContactListContent()}</InviteFriendsCont>
      </ConnectToGoogleWrapper>
    </Container>
  )
}

const mapStateToProps = (state: any) => ({
  user: state.user,
  googleContactList: state.myReferral.googleContacts,
})
export default withRouter(
  connect(
    mapStateToProps,
    {
      getGoogleContact,
      sendInvitation,
    }
  )(ConnectToGoogle)
)
