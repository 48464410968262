import { guestWalletAddressBookPath, guestWalletPortfolioPath, walletAddressBookPath, walletPortfolioPath } from '@logic'
import { Tab } from '@shared/components/tabs/tabs'

export const getPhoneNumber = (profileRecord?: any) => {
    const value = profileRecord?.phone && profileRecord.phone
    if (value) {
        const split = value.split('-')
        if (split.length > 3) {
            const country = `+${split[0]}`
            const area = ` (${split[1]}) `
            split.shift()
            split.shift()
            const number = split.join('-')
            return country + area + number
        }
        else {
            const country = `+${split[0]} `
            split.shift()
            const number = split.join('-')
            return country + number
        }
    }
    else return undefined
}

export const walletTabs: Tab[] = [
    {
        name: 'Portfolio',
        destination: walletPortfolioPath,
        exact: false
    },
    {
        name: 'Address Book',
        destination: walletAddressBookPath,
    }
]

export const guestWalletTabs: Tab[] = [
    {
        name: 'Portfolio',
        destination: guestWalletPortfolioPath,
        exact: false
    },
    {
        name: 'Address Book',
        destination: guestWalletAddressBookPath,
    }
]

export const walletNetworks: any = [
    { name: 'ethereum', label: 'Ethereum' },
    { name: 'polygon', label: 'Polygon' },
    { name: 'binance-smart-chain', label: 'BSC' }
]

export const getNameForAddress = (name?: string, globalName?: string) => {
    if (name && globalName) {
        const nameIsAddress = name.match(/^0x[a-fA-F0-9]{40}$/)
        const globalNameIsAddress = globalName.match(/^0x[a-fA-F0-9]{40}$/)
        if (nameIsAddress) {
            if (globalNameIsAddress) return name
            else return globalName
        }
        else return name
    }
    else return ''
}

export const capitalize = (s: any) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

interface AddressDataBundle {
    data: any
    walletData: any
    savingsData: any
    claimData: any
    debtData: any
    vaultData: any
    networksData: any
    protocolsData: any
}

export const getAddressData = (addressData: any, address: string): AddressDataBundle => {
    let walletData: any = []
    let savingsData: any = []
    let claimData: any = []
    let debtData: any = []
    let vaultData: any = []
    let networksData: any = []
    let protocolsData: any = []

    const mapAssets = (assets: any, data: any) => {
        return {
            ...assets,
            name: assets.tokens[0]?.tokens ? assets.tokens[0]?.tokens[0].symbol : assets.tokens[0]?.symbol,
            price: assets.tokens[0]?.price,
            value: Math.abs(assets.tokens[0]?.balanceUSD),
            balance: assets.tokens[0]?.balance,
            network: data.network,
        }
    }

    const data = addressData ? addressData.filter(a => a.appId !== 'nft').map((d: any) => {
        const add = address.toLowerCase()
        const balances = d.balances[add]!!
        const meta = balances?.meta
        const products = balances?.products[0]
        const walletAssets = products?.assets?.filter((a: any) => a.type == 'wallet' && !a.tokens[0].hide)
        const savingsAssets = products?.assets?.filter((a: any) => a.type == 'lend')
        const debtAssets = products?.assets?.filter((a: any) => a.type == 'borrow')
        const claimAssets = products?.assets?.filter((a: any) => a.type == 'claimable')
        const vaultAssets = products?.assets?.filter((a: any) => a.type == 'vault')

        if (walletAssets && walletAssets.length > 0) {
            const data = walletAssets.map((w: any) => mapAssets(w, d))
            walletData = walletData.concat(data)
        }
        if (savingsAssets && savingsAssets.length > 0) {
            const data = savingsAssets.map((s: any) => mapAssets(s, d))
            savingsData = savingsData.concat(data)
        }
        if (debtAssets && debtAssets.length > 0) {
            const data = debtAssets.map((a: any) => mapAssets(a, d))
            debtData = debtData.concat(data)
        }
        if (claimAssets && claimAssets.length > 0) {
            const data = claimAssets.map((c: any) => mapAssets(c, d))
            claimData = claimData.concat(data)
        }
        if (vaultAssets && vaultAssets.length > 0) {
            const data = vaultAssets.map((c: any) => mapAssets(c, d))
            vaultData = vaultData.concat(data)
        }
        const worth = meta?.find((m: any) => m.label == 'Total')?.value
        return { ...d, worth }
    }) : []

    protocolsData = addressData.filter(d => (d.appId !== 'tokens' && d.appId !== 'nft'))?.map((data: any) => {
        const add = address.toLowerCase()
        const balances = data.balances[add]!!
        const meta = balances?.meta
        const worth = meta?.find((m: any) => m.label == 'Total')?.value
        const appId = data.appId.includes('-') ? data.appId.split('-') : data.appId
        const name = data.appId.includes('-') ? capitalize(appId[0]) + ' ' + capitalize(appId[1]) : capitalize(appId)
        return { ...data, value: worth || 0, name }
    })?.filter(p => p.value !== 0)

    networksData = walletNetworks.map((network: any) => {
        const networkArray = addressData.filter(d => d.network == network.name)?.map((data: any) => {
            const add = address.toLowerCase()
            const balances = data.balances[add]!!
            const meta = balances?.meta
            const worth = meta?.find((m: any) => m.label == 'Total')?.value
            return { ...data, value: worth }
        })
        const worth = networkArray?.reduce((a, b) => a + b.value, 0)
        return { ...network, type: '', value: worth || 0, name: network.label }
    })

    return {
        data,
        walletData,
        savingsData,
        claimData,
        debtData,
        vaultData,
        networksData,
        protocolsData
    }
}