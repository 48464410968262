import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { editPoolPath, organizationViewSecurityPath, PageProps, planIncentivePath, poolsPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission, getIncentiveTypes } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { FlexRow } from '@src/styles'
import { ActionsDrop } from '@shared/components/actionsDrop'
import { BaseLinkProps, overflowParams } from '@shared/components/navigation'
import { InfoPanelProps, ViewInfoPanel } from '../components/view-info-panel'
import { formatSecurityName, loadAuthorizations, loadHolding, loadOrganizationHoldings, loadSecurities, optionalCommaNumber } from '@shared/helpers'
import { deleteHolding, ExpandedHoldingResponse, ExpandedHoldingsResponse, GetAuthorizationsResponse, GetSecuritiesResponse } from '@src/service'
import { basicDeleteModal } from '@shared/components/modal'
import { filterEquities } from '../logic'
import { optionalShares } from '@modules/convertibles/components'

type Props = PageProps & ExpandedHoldingResponse & GetSecuritiesResponse & GetAuthorizationsResponse & ExpandedHoldingsResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadSecurities(), loadAuthorizations('pool'), loadOrganizationHoldings('all'))

export const ViewPoolPage = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const { params, navigate, holding, securities, location, httpClient, authorizations, holdings } = props
        const { organization, plan, pool } = params
        const securitiesList = filterEquities([], securities, true)
        const poolIncentive = securitiesList.length > 0 ? securitiesList.find(s => s.hash === holding.parent) : []
        const incentiveEquity = securitiesList.find(s => s.hash === poolIncentive?.parent)
        const poolOptions = holdings?.filter(h => h.parent === holding?.id)
        const totalVested = poolOptions.reduce((a, b) => a + b?.vestedShares, 0)
        const totalExercised = poolOptions.reduce((a, b) => a + b?.exercisedShares, 0)
        const totalUnvested = (holding.availableShares || 0) - totalVested

        const handleDeletePool = async () => {
            await deleteHolding(httpClient)({ holding: pool })
            deleteModal.setVisible(false)
            navigate(poolsPath, { organization, plan })
        }
        const deleteModal = basicDeleteModal(
            handleDeletePool,
            [holding?.name || ''],
        )
        const dropOptions = [
            ['Edit', () => navigate(overflowParams(editPoolPath, { organization, plan, pool, parent: location?.pathname! }))],
            ['Delete', () => deleteModal.setVisible(true)],
        ]

        const documentsAndNotes = authorizations && authorizations.length > 0 ?
            authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
        const documents = documentsAndNotes?.map((auth, index: number) => {
            return {
                label: `Document ${index + 1}`,
                value: auth.documentTypeName! || auth.document?.filename || auth.filename,
                document: auth.document?.hash! || auth.hash,
                filename: auth.document?.filename || auth.filename
            }
        })

        const data: InfoPanelProps[] = [
            {
                label: 'Incentive Type',
                value: getIncentiveTypes[poolIncentive?.type]
            },
            {
                label: 'Incentive Name',
                value: poolIncentive?.name,
                link: planIncentivePath,
                args: { organization, plan, incentive: poolIncentive?.hash, parent: location.pathname }
            },
            {
                label: 'Approved Equity',
                value: `${incentiveEquity && formatSecurityName(incentiveEquity)} ${incentiveEquity?.shareClassPrefix ? `(${incentiveEquity?.shareClassPrefix})` : ''}`,
                link: organizationViewSecurityPath,
                args: { organization, security: incentiveEquity?.hash, parent: location.pathname }
            },
            {
                label: 'Allocated Amount',
                value: optionalCommaNumber(optionalShares(holding.value)),
            },
            {
                label: 'Vested Amount',
                value: optionalCommaNumber(optionalShares(totalVested)),
            },
            {
                label: 'Exercised Amount',
                value: optionalCommaNumber(optionalShares(totalExercised)),
            },
            {
                label: 'Unvested Amount',
                value: optionalCommaNumber(optionalShares(totalUnvested)),
            },
            ...documents
        ]

        const parent: BaseLinkProps = { path: poolsPath, args: { organization, plan } }

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    <PageContentHeader title={holding?.name || 'Dashboard'} parent={parent} direction>
                        <FlexRow style={{ alignItems: 'baseline' }}>
                            <ActionsDrop options={dropOptions} />
                        </FlexRow>
                    </PageContentHeader>
                    <ViewInfoPanel title='Pool Details' data={data} navigate={navigate} params={params} />
                </PageContent>
            </>
        )
    })
)
