import * as React from 'react'
import { colors, StyledPaymentMethod, FlexRow } from '@src/styles'
import styled from 'styled-components'
import Visa from '@image/visa.svg'
import MasterCard from '@image/master-card.svg'
import Amex from '@image/american-express.svg'
import Discover from '@image/discover.svg'
import BankTransfer from '@image/bank-transfer.svg'
import { PaymentProfilesResponse } from '@logic'

interface PaymentProps {
    savedPayments?: any
    disableNewCard?: boolean
    newCard: boolean
    setNewCard: () => void
    selectedProfile: number
    setSelectedProfile: (profile: number) => void
}

type Props = PaymentProps

export const Selector = styled.div<any>`
    display: flex;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50% 50%;
    background: ${props => props.selected ? props.theme.accent : props.theme.selectorBack};
    margin-right: 17px;
    cursor: pointer;
    div {
        width: 8px;
        height: 8px;
        border-radius: 50% 50%;
        display: ${props => props.selected ? '' : 'none'} !important;
        background: rgba(0, 0, 0, 0.2);
    }
`

export const PaymentMethodWrap = styled<any>(FlexRow)`
    display: flex;
    padding: 0 12px 0 21px;
    ${StyledPaymentMethod}
    color: ${props => props.theme.white};
    border: ${props => props.selected && `2px solid ${colors.green}`};
    svg {
        height: 40px;
        margin: 6px 5px;
    }
`

export const getLogo = (name: string) => {
    switch (name) {
        case 'visa':
            return <Visa />
        case 'master':
            return <MasterCard />
        case 'american_express':
            return <Amex />
        case 'discover':
            return <Discover />
        case 'bankTransfer':
            return <BankTransfer />
    }
}

export const PaymentProfiles = (props: Props) => {
    const { savedPayments, newCard, setNewCard, selectedProfile, setSelectedProfile, disableNewCard } = props
    const savedPaymentProfiles = savedPayments && savedPayments.length > 0 ? savedPayments.map((profile: any) => {
        const { isDefault, payment_profile } = profile
        return {
            ...profile,
            logo: getLogo(payment_profile.card_type),
            value: `Checking ending in ...${payment_profile.masked_card_number.replace(/-|\X/g, '')} ${isDefault ? '(Default)' : ''}`,
        }
    }) : []

    return (
        <>
            {savedPaymentProfiles.length > 0 && savedPaymentProfiles.map((profile: any, i: number) => (
                <PaymentMethodWrap
                    justifyContent={'space-between'}
                    key={i}
                    onClick={() => setSelectedProfile(profile.payment_profile.id)}
                    selected={selectedProfile === profile.payment_profile.id}>
                    <div>
                        <Selector selected={selectedProfile === profile.payment_profile.id}> <div /> </Selector>
                        <span>{profile.value}</span>
                    </div>
                    {profile.logo}
                </ PaymentMethodWrap>
            ))}
            {!disableNewCard && <PaymentMethodWrap justifyContent={'space-between'} onClick={() => setNewCard()} selected={newCard}>
                <div>
                    <Selector selected={newCard}> <div /> </Selector>
                    <span>New Card</span>
                </div>
                <FlexRow>
                    <Visa />
                    <MasterCard />
                    <Amex />
                    <Discover />
                </FlexRow>
            </PaymentMethodWrap>
            }
        </>
    )
}