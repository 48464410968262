import * as React from 'react'
import { accountPath, OrganizationProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { GetSecuritiesResponse, GetLegendsResponse } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { editAccount } from '@src/service/services/profile'
import { AccountSettingsForm } from '../components/account-settings-form'

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & GetLegendsResponse & { forGuest?: boolean, openSignUp?: () => void }

export const EditAccountSettingsShared = (props: Props) => {
    const { httpClient, navigate, user, forGuest, openSignUp } = props
    const initialValues = {}
    const isEdit = props.account?.hasPin
    const onSubmit = async (values: any) => {
        if (user) {
            const request = {
                pin: values?.pin ? values.pin : null
            } as any

            const response = await editAccount(httpClient)({ user: user.id }, request)
            if (response?.id) {
                navigate(accountPath, {})
            }
            else console.log('Edit PIN error') // TODO: add error message toast
        }
    }

    const parent: BaseLinkProps = { path: accountPath, args: {} }

    return (
        <PageContent withMargin={forGuest}>
            <PageContentHeader title={isEdit ? 'Edit PIN' : 'Add PIN'} parent={parent} hideCrumbs={true} />
            <CommonForm mutators={{ ...arrayMutators }} leaveModal={forGuest} onSubmit={!forGuest ? onSubmit : openSignUp} initialValues={initialValues}
                submitText="Save">
                <AccountSettingsForm />
            </CommonForm>
        </PageContent>
    )
}
