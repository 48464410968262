import * as React from 'react'

export function useClose(open: boolean, setOpen: (open: boolean) => void, ref: any) {
    React.useEffect(() => {
        const checkIfClickedOutside = (e: Event) => {
            if (open && ref.current && ref?.current && !ref.current.contains(e.target)) {
                setOpen(false)
            }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [open])
}