import * as React from 'react'
import { StateTransform } from '../../../index'
import { connect } from 'react-redux'
import { PageProps, UpdateUserProfileDispatch, UpdateUserProfileRequest, User, updateUserProfile, profilePINPath } from '@logic'
import { logout, LogoutDispatch } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { createAddress, FilesResponse, NewAddressRequest1, postFile } from '@src/service'
import { loadProfile } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { ProfileForm } from '@modules/profile/components/profile-form'
import styled from 'styled-components'
import { whiteColor } from '@src/styles'
import WelcomeHeader from '@image/welcome-top.png'

interface StateProps {
    user?: User
}

interface Props {
    logout: LogoutDispatch
    updateUserProfile: UpdateUserProfileDispatch
}

interface State {
    editing: boolean
}

const Wrapper = styled.div`
  ${whiteColor}
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
      width: 100%;
      height: auto;
      margin-top: 57px;
  }
  .title{
    font-size: 48px;
    margin: 24px 0 0 0;
  }
`

const FormWrapper = styled.div<any>`
`

type ProfileProps = PageProps & StateProps & Props & State

const withData = withLoadingCachedMultiple<ProfileProps>(loadProfile())

const BuildProfileComponent = withData((props: ProfileProps) => {
    const { httpClient, user, navigate } = props
    const profile = props?.profile!
    const fullName = profile?.fullName!
    const firstName = fullName?.substr(0, fullName.indexOf(' '))!
    const lastName = fullName?.substr(fullName.indexOf(' ') + 1)!

    const initialValues = {
        firstName,
        lastName,
        photo: profile?.file ? { name: `${profile.file.filename}`, id: profile.file.id } : '',
        email: user?.email || profile?.email!,
        phone: profile?.phone!,
        dateOfBirth: profile?.dateOfBirth!,
        homeAddress: profile?.homeAddress!,
        links: profile?.links!,
        website: profile?.website!,
    }

    const onUpload: UploadHandler = async files => {
        const response = (await postFile(httpClient)(`profile/${user?.id}/file`, files)) as FilesResponse
        const file = response.files[0]
        return {
            hash: file.hash,
            name: file.filename,
            id: file.id
        }
    }

    const onSubmit = async (data: UpdateUserProfileRequest) => {
        let profile = ''
        if (user && user.id) {
            profile = user.id
        }
        const addresses = data?.address?.filter((a: any) => !a.isRemoved)
            .map((address: any) => {
                const newAddress = { ...address }
                delete newAddress.key
                return newAddress
            })
        const fullName = data?.firstName + ' ' + data?.lastName
        const request = {
            profile,
            fullName,
            homeAddress: data?.homeAddress!,
            website: data?.website!,
            email: data?.email!,
            phone: data?.phone!,
            dateOfBirth: data?.dateOfBirth!,
            file: data?.photo?.id!,
            links: data?.links!,
        } as UpdateUserProfileRequest
        const response = await props.updateUserProfile(request)
        if (response?.data?.profile) {
            if (addresses && addresses.length > 0) {
                addresses.forEach(async (address: any) => {
                    const addRequest = {
                        ...address,
                        groups: address?.groups ? address.groups : []
                    } as NewAddressRequest1
                    await createAddress(httpClient)({ entity: user?.id }, addRequest)
                })
            }
            navigate(profilePINPath)
        }
    }

    return (
        <Wrapper>
            <img src={WelcomeHeader} />
            <PageContent noPadding={true}>
                <span className="title">Build Profile Settings </span>
                <FormWrapper>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                        submitText="Save" skipText="Skip" skip={{ path: profilePINPath }}>
                        <ProfileForm onUpload={onUpload} noEmail={true} />
                    </CommonForm>
                </FormWrapper>
            </PageContent>
        </Wrapper>
    )
})

const mapStateToProps: StateTransform<StateProps> = s => ({ user: s.user })

export const BuildProfile = connect(
    mapStateToProps,
    { logout, updateUserProfile }
)(BuildProfileComponent)
