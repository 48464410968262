import * as React from 'react'
import styled from 'styled-components'
import ArrowRight from '@image/arrow-right.svg'
import { LabelValue } from '@components/select'
import { Panel } from '@components/panels/panels'

interface Props {
  fromCertificate?: LabelValue<string> | null
  fromMember?: LabelValue<string> | null
  recipient?: LabelValue<string> | null
  shareAmounts?: {
    balance: number
    send: number
  }
  hasDocuments?: boolean
  fullTransfer?: boolean
}

const StyledReview = styled<any>(Panel)`
  display: flex;
  justify-content: space-evenly;
  color: ${props => props.theme.white};
  text-align: start;
  width: 100% !important;
  padding: 20px 40px !important;
  margin-top: ${props => props.hasDocuments && '20px'};
`

const ReviewCol = styled.div`
  flex: 2;
  div + div {
    margin-top: 10px;
  }
`

const ReviewArrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  flex: 1;
`

const ReviewHeader = styled.span`
  opacity: 0.4;
`

export const TransferReview = (props: Props) => {
  const { fromCertificate, fromMember, shareAmounts, recipient, hasDocuments, fullTransfer } = props

  const requiredFields = fromCertificate && fromMember && shareAmounts && recipient && shareAmounts.balance >= shareAmounts.send && shareAmounts.send > 0

  return requiredFields ? (
    <StyledReview hasDocuments={hasDocuments}>
      <ReviewCol>
        <ReviewHeader>Canceling 1 Certificate</ReviewHeader>
        <div>
          {fromCertificate!.label} From {fromMember!.label}
        </div>
      </ReviewCol>
      <ReviewArrow>
        <ArrowRight />
      </ReviewArrow>
      <ReviewCol>
        <ReviewHeader>Creating {!fullTransfer ? '2 Certificates' : '1 Certificate'}</ReviewHeader>
        {!fullTransfer && <div>
          {shareAmounts!.balance - shareAmounts!.send} Shares To {fromMember!.label}
        </div>
        }
        <div>
          {shareAmounts!.send} Shares To {recipient!.label}
        </div>
      </ReviewCol>
    </StyledReview>
  ) : null
}
