import * as React from 'react'
import { ComponentType } from 'react'
import { NavigationProps, withNavigation } from '@shared/components/navigation'
import { withService } from '@shared/hocs/with-service'
import { Tab, Tabs } from '@shared/components/tabs/tabs'
import {
    holdingsReportsPath,
    planIncentivesPath,
    poolReportsPath,
    poolsPath,
    reportsPath
} from '@logic'

type Props = NavigationProps

const ReportsHeader = withNavigation(withService((props: Props) => {
    const { params } = props

    const links: Tab[] = [
        {
            destination: reportsPath,
            name: 'Holders',
            exact: true,
        },
        {
            destination: poolReportsPath,
            name: 'Pools',
        },
        {
            destination: holdingsReportsPath,
            name: 'Holdings',
        },
    ]

    return <Tabs tabs={links} title={'Reports'} params={params} />
}))

export function withReportsHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
    return (props: any) => (
        <React.Fragment>
            <ReportsHeader {...props} />
            <InnerComponent {...props} />
        </React.Fragment>
    )
}
