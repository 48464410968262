import styled, { css } from 'styled-components'
import DatePicker from 'react-datepicker'
import { colors, Themes, commonInputStyle } from '@src/styles'

export const DatePickerStyling = css`
  position: relative;
  .react-datepicker-wrapper {
    display: block;
    padding: 0;
    border: 0;
  }
  .react-datepicker__navigation--previous {
    left: -50px;
    border-right-color: #ccc;
  }
  .react-datepicker__navigation--next {
    right: -50px;
    border-left-color: #ccc;
  }
`

export const DatePickerComponent = styled.div`
  ${DatePickerStyling}
`

export const DateError = styled.div`
  margin-left: 10px;
`

export const DatePickerWrapper = styled.div<any>`
  border: ${props => props.hasErrors && `2px solid ${colors.errorRed}`};
  border-radius: 4px;
  width: 100%;
  position: relative;
  .react-datepicker-wrapper {
    width: 100%;
    input {
      border: none;
      outline: none;
      border-radius: 4px;
      background-image: none !important;
      cursor: auto !important;
      background: ${props => props.filled === 'true' ? props.theme.input.basic : props.theme.input.default} !important;
      box-shadow: ${props => props.hasErrors ? 'none' : ''} !important;
      ${commonInputStyle}
    }
  }
  .react-datepicker-popper {
    z-index: 999;
  }
  svg {
    position: absolute;
    top: 13px;
    right: 19px;
    path {
      fill: ${props => props.theme.selected === Themes.LIGHT ? '#2B3B44' : props.theme.listText};
    }
    :hover {
        path {
          fill: ${colors.green};
        }
    }
  }
`
