import * as React from 'react'
import styled from 'styled-components'
import { colors, FlexCol, FlexRow } from '@src/styles'
import DownArrow from '@image/carrot-down.svg'
import {  PathLink } from '@shared/components/navigation'
import {  guestOrganizationsPath } from '@logic'
import SmallBuilding from '@image/building-16x16.svg'
import { DropFooter } from '../style'

const DockWrapper = styled<any>(FlexRow)`
    min-width: ${props => props.styleChange === 'true' ? '180px' : '320px'};
    height: 40px;
    justify-content: space-between;
    position: relative;
    padding: 4px 12px;
    cursor: pointer;
    border-radius: 4px;
    color:${({ theme }) => theme.white};
    :hover {
        background: ${({ theme }) => theme.dock.hover};
        border: 1px solid ${colors.green};
    }
    img, svg {
        margin-right: 8px;
    }
    svg {
        :hover {
            path {
                fill: ${colors.green};
            }
        }
    }
    div {
        display: flex;
    }
    .orgText {
        @media(min-width: 1024px) {
            margin-left:2.7rem;
        }
    }
`

const OrganizationsDock = styled<any>(FlexCol)`
    position: absolute;
    top: 49px;
    left: 0;
    width: 320px;
    background: ${({ theme }) => theme.header};
    align-items: flex-start;
    padding: 8px 0px;
    a {
        width: 100%;
    }
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                    0px 3px 14px rgba(0, 0, 0, 0.05),
                    0px 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .footerLink {
        :hover {
          span {
            color: ${colors.green};
          }
          path {
            fill: ${colors.green};
          }
        }
    }
`





export const GuestNavigationDock = ((props:any) => {
    const {styleChange} = props;
    const [open, setOpen] = React.useState<boolean>(false)
    return <DockWrapper onClick={() => setOpen(!open)} styleChange={styleChange ? 'true' : ' false'}>
        <FlexRow alignItems={'center'}>
         <span className='orgText'>Guest</span>
        </FlexRow>
        <DownArrow />
        {open && Dock()}
    </DockWrapper>
})

const Dock = () => {
    return <OrganizationsDock>
        <PathLink path={guestOrganizationsPath} args={{}} className='footerLink'>
            <DropFooter>
                <SmallBuilding />
                <span >View All Orgs</span>
            </DropFooter>
        </PathLink>
    </OrganizationsDock>
}
