import * as React from 'react'
import { OrganizationPropsOld, Paths, selectYourPlanPath } from '@logic'
import { CenteringContainer, FlexCol, StyledH2, StyledP } from '@src/styles'
import { PathLinkButtonInverted } from '@components/button/button'

export function CannotTransferError(props: any) {
  const { organization } = props
  return (
    <FlexCol>
      <StyledP>You cannot transfer without a subscription</StyledP>
      <StyledP>Please upgrade your subscription</StyledP>
      <div>
        <PathLinkButtonInverted path={selectYourPlanPath} args={{ organization }}>
          Select a plan
        </PathLinkButtonInverted>
      </div>
    </FlexCol>
  )
}
