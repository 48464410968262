import * as React from 'react'
import { formBasicModalBundle } from '@shared/components/basic-modal'
import { renderFieldsSimple, textInputFieldComponent } from '@shared/components/forms'
import { isEthereumAddress, required } from '@shared/helpers'

const EditAddressForm = () => {
    return <>
        {renderFieldsSimple([
            {
                name: 'name',
                label: 'Name',
                required: true,
                validate: required,
                placeholder: 'Enter New Name',
                component: textInputFieldComponent
            },
            {
                name: 'address',
                label: 'Address',
                required: true,
                validate: isEthereumAddress,
                placeholder: 'Enter New Address',
                component: textInputFieldComponent
            },
        ], true)}
    </>
}

export function newEditAddressModal(onActivate: (props: any) => void, initialValues?: any) {
    return formBasicModalBundle({
        title: 'Edit address',
        desc: '',
        body: <EditAddressForm />,
        initialValues,
        onSubmit: onActivate,
        submitText: 'Save',
        max: '692px'
    })
}