import React, { useEffect } from 'react'
import ReactGA, { FieldsObject } from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { clearDataCache } from '@shared/helpers'

declare var GA_ID: string

ReactGA.initialize(GA_ID);

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
  options: FieldsObject = {},
) => {
  const trackPage = (page: string) => {
    ReactGA.set({ page, ...options });
    ReactGA.pageview(page);
  };

  return (props: P) => {
    useEffect(() => {
      GA_ID && trackPage(window.location.pathname);
      // This line does not have to do with GA tracking and could use it's own HOC but
      // is included inside `withTracker` for now for simplicity.
      // This functional call clears the data cache so that cached data is not preserved when
      // the user navigates to a new URL
      clearDataCache()
    }, [window.location.pathname]);

    return <WrappedComponent {...props} />;
  };
}
