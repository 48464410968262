import * as validator from 'validator'
import { composeValidators, defineValidator, isEither, stringLength } from './index'
import { Service } from '@src/service'
import { validateCardNumber } from '@modules/payments/helpers/cc-validators'

export const emptyValidator = defineValidator({
  check: _ => true,
  message: '',
})

export const isRepeated = (all?: any, edit?: boolean) => defineValidator({
  check: value => {
    if (edit) {
      const filtered = all?.filter(a => a !== value?.toLowerCase())
      if (!filtered?.includes(value?.toLowerCase())) return true
      else return false
    }
    else {
      if (!all?.includes(value?.toLowerCase())) return true
      else return false
    }

  },
  message: 'This cannot be the same as another role in your organization.',
})

export const isAlpha = defineValidator({
  check: validator.isAlpha,
  message: 'Must only contain letters',
})

export const isLessOrEqual = (currentValue: any, message?: string) => defineValidator({
  check: value => value ? parseFloat(value) <= currentValue : true,
  message: message || '',
})

export const isValidPromoCode = (isValid: boolean) => defineValidator({
  check: () => isValid ? true : false,
  message: 'Incorrect Promo Code entered',
})

export const pinMatches = (pin?: any) => defineValidator({
  check: value => value && pin ? parseInt(value) === parseInt(pin) : true,
  message: `PIN's must match`,
})

export const isCorrectUrl = defineValidator({
  check: validator.isURL,
  message: 'Enter correct url format',
})

export const isInt = (options: ValidatorJS.IsIntOptions, message: string) =>
  defineValidator({
    check: value => validator.isInt(value.toString(), options),
    message,
  })

export const isFloat = (options: ValidatorJS.IsFloatOptions, message: string) =>
  defineValidator({
    check: value => validator.isFloat(value.toString(), options),
    message,
  })

export const isNumeric = defineValidator({
  check: value => validator.isNumeric(value.toString(), { no_symbols: true }),
  message: 'Must only be numbers',
})

export const isCreditCardNumber = defineValidator({
  check: value => validateCardNumber(value.replace(/\u2000/g, '').trim()),
  message: 'Must be a valid credit card number',
})

export const isExpiration = defineValidator({
  check: value => {
    const currentMonth = new Date().getMonth() + 1
    const currentYear = new Date().getFullYear()
    const groups = value.match(/(\d+)\/(\d+)/)
    if (!groups) return false
    const month = parseInt(groups[1])
    const year = parseInt(groups[2])
    return year >= currentYear && (year > currentYear || month > currentMonth)
  },
  message: 'Should be a valid expiration date.',
})

export const isDate = defineValidator({
  check: value => value.split('/').join('').length === 6,
  message: 'Date must match mm/yyyy',
})

export const isMonth = defineValidator({
  check: value => value && value > 12 ? false : true,
  message: 'Enter a valid month',
})

export const isNumber = defineValidator({
  check: value => validator.isNumeric(value, { no_symbols: true }),
  message: 'Should only contain numbers'
})

export const isCVCLength = (card: string) => defineValidator({
  check: value => {
    return card?.startsWith('37') || card?.startsWith('34') ?
      validator.isLength(value, { min: 4, max: 4 }) : validator.isLength(value, { min: 3, max: 3 })
  },
  message: 'Must be a valid CVC',
})

export const isCVC = (card: string) => composeValidators([isNumber, isCVCLength(card)])
export const isExpirationDate = composeValidators([isDate, isExpiration])

export const numberWithOptionalCommas = defineValidator({
  check: value => {
    return value && validator.isNumeric(value.toString().replace(/,|\+/g, ''))
  },
  message: 'Must only be numbers',
})

export const isGreaterThanZero = defineValidator({
  check: value => value && parseFloat(value) > 0,
  message: 'Must be greater than zero',
})

export const hasPinLength = defineValidator({
  check: value => validator.isLength(value.toString().replace(/,|\$/g, ''), { min: 4, max: 4 }),
  message: 'PIN should have exactly 4 digits.',
})

export const numberLimit = defineValidator({
  check: value => validator.isLength(value.toString().replace(/,|\$/g, ''), { min: 1, max: 13 }),
  message: 'Number too long',
})

export const nonZeroNumberLimit = defineValidator({
  check: value => validator.isLength(value.toString().replace(/,|\$/g, ''), { min: 1, max: 13 }),
  message: 'Number too long',
})

export const decimalNumberLimit = defineValidator({
  check: value => {
    if (value!.toString().includes('.')) {
      return value!.toString().split('.')[1]!.length <= 8
    }
    else {
      return !value!.toString().includes('1e') || value!.toString().includes('1e-7') || value!.toString().includes('1e-8')
    }
  },
  message: 'Decimal Limit exceeded',
})

export const isCurrency = composeValidators([nonZeroNumberLimit, decimalNumberLimit])
export const isPin = (pin?: any) => composeValidators([hasPinLength, isNumeric, pinMatches(pin)])
export const isUrl = composeValidators([isCorrectUrl, stringLength(1, 128)])
export const nonZeroNumberWithOptionalCommas = () => composeValidators(
  [
    numberWithOptionalCommas,
    isGreaterThanZero,
    nonZeroNumberLimit
  ]
)

export const areRolesPresent = defineValidator({
  check: value => value.length !== 0,
  message: 'Please add at least one role',
})

const usernameDescription = 'characters a-z 0-9 underscores and hyphens'

const wordsDescription = 'alphanumeric characters, ampersands, periods, commas, spaces, hyphens or single quote marks'

export const isEthereumAddress = defineValidator({
  check: value => value ? value.match(/^0x[a-fA-F0-9]{40}$/) : true,
  message: 'Enter a valid ETH address',
})

const validUsernameCharacters = defineValidator({
  check: value => !!value.match(/^[a-z0-9-_]+$/),
  message: `Can only consist of ${usernameDescription}`,
})

const validOrganizationNameCharacters = defineValidator({
  check: value => !!value.match(/^[\w.,\-& ']+$/),
  message: `Can only consist of ${wordsDescription}`,
})

const validFullNameCharacters = defineValidator({
  check: value => !!value.match(/^[\w.,\-& ']+$/),
  message: `Can only consist of ${wordsDescription}`,
})

function isEmptyArray(value: any) {
  return Array.isArray(value) && value.length === 0
}

export function isRequiredFieldFilled(value: any) {
  return (typeof value === 'boolean' && value) || typeof value === 'number' || (value && !isEmptyArray(value))
}

export const isNotZeroAndNegative = defineValidator({
  check: value => Math.sign(value!) !== -1 && Math.sign(value!) !== 0,
  message: `Cannot be zero or negative`,
})

export const isUuid = defineValidator({
  check: value => value && validator.isUUID(value),
  message: `Not a valid ID.`,
})

export const hasUuidLength = defineValidator({
  check: value => validator.isLength(value.toString().replace(/,|\-/g, ''), { min: 32, max: 32 }),
  message: 'Must be 32 characters',
})

export const required = (value: any) => (isRequiredFieldFilled(value) ? undefined : 'Required')

export const isFullName = composeValidators([validFullNameCharacters, stringLength(2, 64)])
export const isTitle = composeValidators([validFullNameCharacters, stringLength(0, 64)])
export const isEquaId = composeValidators([isUuid, hasUuidLength])
export const isOrganizationName = composeValidators([validOrganizationNameCharacters, stringLength(1, 64)])
export const isUsername = composeValidators([validUsernameCharacters, stringLength(2, 32)])

export const isEmail = defineValidator({
  check: validator.isEmail,
  message: 'Must be a valid email address',
})

export const isPhoneNumber = defineValidator({
  check: value => {
    return validator.isMobilePhone(value)
  },
  message: 'Must be a valid phone number',
})

export const isEmailOptional = (value: any) => {
  const errors = isEmail(value)
  if (!value || !errors) {
    return undefined
  } else {
    return errors
  }
}

const isNumbersLettersAndHyphens = defineValidator({
  check: value => !!value.match(/^[0-9a-zA-Z\-]+$/),
  message: `Must only be numbers, letters, or hyphens`,
})

const secondValidator = defineValidator({
  check: value => !!value.match(/^[a-zA-Z\- .0-9]+$/),
  message: `Must only be numbers, letters, or hyphens`,
})

const streetValidator = defineValidator({
  check: value => !!value.match(/^[a-zA-Z\- .,'0-9#]+$/),
  message: `Must only be numbers, letters, or hyphens`,
})

export const isPostalCode = composeValidators([stringLength(4, 32), isNumbersLettersAndHyphens])
export const isCity = composeValidators([stringLength(1, 64), secondValidator])
export const isCountry = composeValidators([stringLength(1, 64), secondValidator])
export const isProvince = composeValidators([stringLength(1, 64), secondValidator])
export const isStreet = composeValidators([stringLength(1, 255), streetValidator])
export const isShareClass = composeValidators([stringLength(1, 16), isNumbersLettersAndHyphens])

export const isUsernameOrEmail = isEither([isUsername, isEmail], 'Must be a username or email address')

const validPasswordCharacters = defineValidator({
  check: value => !!value.match(/^[\w\-_@.`~!#$%^&*()+=?<>,[\]]+$/),
  message: `Can only consist of characters a-z A-Z 0-9 @-_.\`~!#$%^&*()+=?<>,[]`,
})

export const isPassword = composeValidators([validPasswordCharacters, stringLength(4, 128)])

export const isConfirmPassword = (values: any) => defineValidator({
  check: value => value === values.password,
  message: 'Passwords must match',
})

export const isTwoFactorSecret = composeValidators([isAlpha, stringLength(6, 128)])

export const isTwoFactorToken = composeValidators([isNumeric, stringLength(6)])

export const validateEmail = async (value: string, service: Service) => {
  const errors = isEmail(value)
  if (errors && errors.length) {
    return errors
  }
  const response = await service.emailAvailable({ email: value })
  return response.isAvailable ? '' : 'That email has already registered an account.  Forgot password?'
}

export const validateUsername = async (value: string, service: Service) => {
  const errors = isUsername(value)
  if (errors && errors.length) {
    return errors
  }
  const response = await service.usernameAvailable({ username: value })
  return response.isAvailable ? '' : 'That username is not available.'
}

export const validateTwoFactorToken = async (value: string, service: Service, secret: string) => {
  const errors = isTwoFactorToken(value)
  if (errors && errors.length) {
    return errors
  }
  const valid = await service.verifyTwoFactor({ token: value, secret })
  return valid ? '' : 'Two factor token does not match secret provided in QR Code.'
}

export const fileNameLimit = defineValidator({
  check: value => validator.isLength(value.toString(), { min: 1, max: 128 }),
  message: 'File Name too long',
})

export const urlCharLimit = defineValidator({
  check: value => validator.isLength(value.toString(), { min: 1, max: 1000 }),
  message: 'Url too long',
})
