import { Hash, safeDivide } from '@shared/helpers'

export function formatSeniorityText(seniority: number): string {
  switch (seniority) {
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${seniority}th`
  }
}
