import * as React from 'react'
import styled, { css } from 'styled-components'
import { FlexRow, FlexCol, fontSizes, semiBoldStyle } from '@src/styles'
import { Panel } from '@components/panels/panels'
import { SubmitButton } from '@shared/components/button'
import { dollarStringFromCents, optionalCommaAmount } from '@shared/helpers'
import { BillingManifest } from '@src/service/services/billing'

interface Props {
  manifest: BillingManifest
  nextManifest: BillingManifest
  invalid?: boolean
  remove: boolean
  selectedProfile: number
  couponValue: number
  balance: number
  values?: any
}

const Info = css`
  font-size: 14px;
  line-height: 19px;
`

const StyledSummary = styled<any>(Panel)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 40px;
  color: ${props => props.theme.white};
  padding: 16px;
  border-radius: 8px;
  div {
    justify-content: space-between;
  }
  p {
    margin: 0;
  }
  .foot {
    padding: 12px;
    span {
      ${semiBoldStyle}
      :last-child {
        font-size: 34px;
        line-height: 46px;
      }
    }
  }
`

const PanelHead = styled.h5`
  ${semiBoldStyle}
  font-size: ${fontSizes.M};
  line-height: 32px;
  margin: 0 0 24px 0;
  border-bottom: 1px solid rgba(206, 218, 225, 0.3);
  padding-bottom: 24px;
`

const SubHead = styled.span`
  ${semiBoldStyle}
  font-size: ${fontSizes.XS};
  line-height: 24px;
  margin: 0 0 24px 0;
`

const Divide = styled.div`
  margin: 16px 0;
  border-bottom: 1px solid rgba(255,255,255, 0.25);
`

const SubmitWrap = styled.div`
  margin: 15px 0;
  button {
    width: 100%;
  }
`

const DescriptionWrap = styled<any>(FlexCol)`
  text-align: left;
  font-size: ${fontSizes.XXS};
  line-height: 19px;
  color: ${props => props.theme.label};
  p {
    width: 100%;
    :first-child {
      margin: 24px 0 0 0;
    }
    :last-child {
      margin: 0;
    }
  }
`

const StyledRow = styled<any>(FlexRow)`
  padding: 12px 12px 12px 16px;
  div {
    width: 100%;
  }
  p {
    color: #9CA6AD;
  }
`

const DiscountPanel = styled<any>(FlexRow)`
  padding: 12px;
`

export const CartSummary = (props: Props) => {
  const { manifest, balance, invalid, selectedProfile, values, remove, couponValue } = props
  const disableSubmit = manifest?.total_in_cents !== 0 ? selectedProfile === 0 ? true : invalid : true
  const lineItems = manifest?.line_items!
  const showEquaCash = values?.equaCash && values.equaCash <= balance && values.equaCash !== 0 ? true : false

  const getDue = () => {
    if (values?.equaCash || couponValue) {
      if (showEquaCash) {
        const value = manifest?.total_in_cents / 100 - values.equaCash - couponValue
        return value < 0 ? 0 : value
      } else {
        const value = manifest?.total_in_cents / 100 - couponValue
        return value < 0 ? 0 : value
      }
    }
    else return dollarStringFromCents(manifest?.total_in_cents)
  }

  return (
    <>
      <StyledSummary>
        <PanelHead>Cart Summary</PanelHead>
        <SubHead>Payment</SubHead>
        {lineItems?.map(item => {
          return <StyledRow>
            <FlexCol alignItems={'start'}>
              <span>{item.memo!}</span>
              <p>Annual Billing</p>
            </FlexCol>
            <span>{dollarStringFromCents(item.amount_in_cents)}</span>
          </StyledRow>
        })}
        {showEquaCash &&
          <DiscountPanel>
            <span>Equa Cash</span>
            <span>- {optionalCommaAmount(values.equaCash)}</span>
          </DiscountPanel>
        }
        {remove &&
          <DiscountPanel>
            <span>Promo Code</span>
            <span>- {optionalCommaAmount(couponValue)}</span>
          </DiscountPanel>
        }
        <Divide />
        <FlexRow className='foot'>
          <span>Due Today:</span>
          <span>{typeof getDue() !== 'string' ? optionalCommaAmount(getDue()) : optionalCommaAmount(getDue())}</span>
        </FlexRow>
      </StyledSummary>
      <SubmitWrap>
        <SubmitButton disabled={disableSubmit} type='submit'>Pay now</SubmitButton>
      </SubmitWrap>
      <DescriptionWrap>
        You will be charged immediately for the “Due Today” amount.
        This subscription will automatically renew annually at the date of purchase.
        At that time, your default payment method will be automatically charged for the full amount of an annual subscription.
        Any new shareholders added to your cap table will affect the cost of your subscription.
        Updates to your organization that would change your billing amount are reconciled daily.
        <p>You can cancel your subscription at any time by going to:</p>
        <p><a href='mailto:support@equa.global'>support@equa.global</a></p>
      </DescriptionWrap>
    </>
  )
}