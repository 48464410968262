import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { MembersProps, organizationMemberPath, organizationViewLegendPath, PageProps, poolPath, viewVestingPath } from '@logic'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { FlexRow, semiBoldStyle } from '@src/styles'
import styled from 'styled-components'
import { formatDateString, loadLegends, loadMembers, loadPlan, loadSecurities, toCommaNumber } from '@shared/helpers'
import {
    ExpandedHoldingResponse,
    ExpandedHoldingsResponse,
    GetAuthorizationsResponse,
    GetLegendsResponse,
    GetPlanResponse,
    GetSecuritiesResponse,
    GetVestingsResponse
} from '@src/service'
import { InfoPanelProps, ViewInfoPanel } from './view-info-panel'
import { filterEquities, filterIncentives, filterPools } from '../logic'
import { optionalShares } from '@modules/convertibles/components'

type Props = PageProps &
    ExpandedHoldingResponse &
    GetAuthorizationsResponse &
    MembersProps &
    GetLegendsResponse &
    ExpandedHoldingsResponse &
    GetSecuritiesResponse &
    GetVestingsResponse &
    GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(loadMembers, loadLegends(true), loadSecurities(), loadPlan)

const TitleRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px;
    color: ${({ theme }) => theme.white};
    h5 {
        margin: 0;
        ${semiBoldStyle}
        font-size: 24px;
        line-height: 32px;
    }
`

export const ViewOption = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const {
            params,
            httpClient,
            navigate,
            holding,
            authorizations,
            members,
            holdings,
            securities,
            legends,
            plan } = props
        const { organization } = params
        const securitiesList = filterEquities([], securities, true)
        const incentivesList = filterIncentives(securitiesList, plan)
        const pools = filterPools(holdings, incentivesList, plan?.hash).map(p => {
            const incentive = incentivesList.find(i => i.hash === p.parent)
            return { ...p, incentive }
        })
        const currentPool = pools?.find(p => p.id || p.hash === holding.parent)
        const currentLegend = legends?.find(p => p.hash === holding.legend)
        const currentVesting = holding.vestingSchedule

        const documentsAndNotes = authorizations && authorizations.length > 0 ?
            authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
        const documents = holding?.issuanceAgreement ? documentsAndNotes.concat(holding.issuanceAgreement) : documentsAndNotes
        const docsWithIssuance = documents.map((auth, index: number) => {
            return {
                label: `Document ${index + 1}`,
                value: auth.documentTypeName! || auth.document?.filename || auth.filename,
                document: auth.document?.hash! || auth.hash,
                filename: auth.document?.filename || auth.filename
            }
        })

        const holder = members?.find(m => m.id === holding.owner)

        const data: InfoPanelProps[] = [
            {
                label: 'Holder',
                value: holder?.fullName || '',
                link: organizationMemberPath,
                args: { organization, member: holder?.id }
            },
            {
                label: 'Pool',
                value: currentPool?.name || '',
                link: poolPath,
                args: { organization, plan: plan?.hash, pool: currentPool?.hash }
            },
            {
                label: 'Grant / Effective date',
                value: holding?.issueDate ? formatDateString(holding.issueDate) : '',
            },
            {
                label: 'Vesting Schedule',
                value: currentVesting?.name || 'N/A',
                link: viewVestingPath,
                args: { organization, plan: plan?.hash, vesting: currentVesting?.hash }
            },
            {
                label: 'Vesting Start Date',
                value: holding?.startDate ? formatDateString(holding.startDate) : 'N/A',
            },
            {
                label: 'Total Amount',
                value: toCommaNumber(optionalShares(holding.value || 0)),
            },
            {
                label: 'Legend',
                value: currentLegend?.name || 'N/A',
                link: organizationViewLegendPath,
                args: { organization, legend: currentLegend?.hash }
            },
            ...docsWithIssuance
        ]

        return (
            <ViewInfoPanel title='Holding Details' data={data} navigate={navigate} params={params} />
        )
    })
)
