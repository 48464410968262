import * as React from 'react'
import * as PIXI from 'pixi.js-legacy'
import { ThemeProps, withTheme } from 'styled-components'
import { resolveVestingSchedule } from '@modules/esop/logic'
import { formatDateString, safeDivide } from '@shared/helpers'
import { NewVestingSchedule } from '@src/service'
import { RenderChartPixi } from './pixi-chart'
import _ from 'lodash'

enum Layout {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

interface WrapperProps extends ThemeProps<any> {
  vestingSchedule: NewVestingSchedule
  value: number | undefined,
  custom: boolean
  startDate?: Date
  forView?: boolean
  dontNotify?: boolean
  customWidth?: number
  customHeight?: number
  noMargin?: boolean
  noMarginBottom?: boolean
}

// const withData = withLoading<WrapperProps, MinimalServiceProps & ProjectVestingScheduleRequest>(loadVestingScheduleProjection as any)

function continuousVestingScheduleCanBeGraphed(vestingSchedule: NewVestingSchedule, startDate: Date | undefined, forView?: boolean): boolean {
  const hasStartDate = (!!startDate && typeof startDate !== 'string')
  const view = forView ? true : false
  return 'events' in vestingSchedule ? (vestingSchedule.isValueAbsolute || view || hasStartDate)
    : (hasStartDate || view) ?
      !!vestingSchedule.frequencyMonths && !!vestingSchedule.durationMonths && !!vestingSchedule.vestsOn
      : false
}

const calculateChartValues = (props: WrapperProps) => {
  const { vestingSchedule, value, startDate, forView } = props
  const initialSamples = continuousVestingScheduleCanBeGraphed(vestingSchedule, startDate, forView)
    ? resolveVestingSchedule(vestingSchedule, value, startDate)
    : []

  if (initialSamples.length == 0) return []
  const isAbsolute = vestingSchedule.isValueAbsolute!
  const samples = isAbsolute ? initialSamples : (startDate || forView) ? initialSamples : []

  const data = samples.map((sample, i) => {
    const date = sample.date ? formatDateString(sample.date) : undefined
    const percentage = (sample.cumulativeValue / samples[samples.length - 1]?.cumulativeValue) * 100
    return {
      value: sample.cumulativeValue,
      date,
      amount: sample.value,
      percentage: percentage.toFixed(2),
      event: sample.event ? sample.event : undefined
    }
  })
  return data
}

const calculateCliff = (isDiscreet: boolean, isAbsolute: boolean, vestingSchedule: any, events: any): number => {
  if (isDiscreet) {
    if (!isAbsolute) {
      return (events[0]?.month || 1) - 1
    }
    else return 0
  }
  else {
    if (vestingSchedule.cliffMonths) return vestingSchedule.cliffMonths
    else return 0
  }
}

export const HorizontalVestingChart = withTheme((props: WrapperProps) => {
  const [app, setApp] = React.useState<PIXI.Application | undefined>(undefined)
  const { vestingSchedule, theme, customWidth, customHeight, noMargin, forView, dontNotify, noMarginBottom } = props
  const data = calculateChartValues(props)
  const durationMonths = vestingSchedule.durationMonths!
  const isAbsolute = vestingSchedule.isValueAbsolute ? true : false
  const inView = forView ? true : false
  const removeNotification = dontNotify ? true : false
  const isDiscreet = vestingSchedule ? Object.keys(vestingSchedule)?.includes('events') : false
  const hasStartDate = props.startDate ? true : false

  const cliffMonths = calculateCliff(isDiscreet, isAbsolute, vestingSchedule, vestingSchedule?.events || [])
  const emptyRange = _.range(0, cliffMonths).map(r =>
    ({ empty: true, value: 0, amount: 0, width: cliffMonths == 1 ? 30.25 : 20.25 })
  )

  const events = vestingSchedule ? isDiscreet ? !isAbsolute ?
    vestingSchedule.events?.length : vestingSchedule.events?.length
    :
    safeDivide(vestingSchedule.durationMonths, 12) + 1
    : []


  const eventsWithoutCliff = _.range(0, events)?.map((e, i: number) => {
    if (isDiscreet) {
      return {
        value: data[i]?.amount,
        date: data[i]?.date,
        month: !isAbsolute ? vestingSchedule?.events[i]?.month : undefined,
        index: i
      }
    }
    else return e
  })

  const eventsRange = isDiscreet && !isAbsolute ? _.range(0, cliffMonths !== 0 ?
    safeDivide(cliffMonths, 12) + 1 :
    safeDivide(cliffMonths, 12))?.concat(eventsWithoutCliff as any)
    : eventsWithoutCliff as any


  React.useEffect(() => {
    setApp(new PIXI.Application({
      width: window.innerWidth >= 1280 ? 1280 : window.innerWidth,
      height: 288,
      backgroundAlpha: 0,
      antialias: true,
    }))
    return () => {
      setApp(undefined)
    }
  }, [])

  return app ? <RenderChartPixi
    app={app}
    theme={theme}
    data={data}
    durationMonths={durationMonths}
    custom={props?.custom}
    hasStartDate={hasStartDate}
    vestingData={vestingSchedule}
    isAbsolute={isAbsolute}
    customWidth={customWidth}
    customHeight={customHeight}
    noMargin={noMargin}
    noMarginBottom={noMarginBottom}
    forView={inView}
    notify={removeNotification}
    isDiscreet={isDiscreet}
    cliffMonths={cliffMonths}
    eventsRange={eventsRange}
    emptyRange={emptyRange}
    totalAmount={props.value} /> : null
})
