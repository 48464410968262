import * as React from 'react'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { PathLinkButtonInverted } from '@components/button/button'
import { Paths } from '@logic'
import { FlexRow, screenSizes } from '@src/styles'
import styled from 'styled-components'

const StyledLinks = styled<any>(FlexRow)`
  @media (max-width: ${screenSizes.S}px) {
    flex-direction: column;
    a {
      width: 100%;
      margin: 0;
      :last-child {
        margin-top: 8px;
      }
    }
  }
`

export const AdminRoot = () => {
  return (
    <PageContent>
      <PageContentHeader title="Admin Pages" />
      <StyledLinks>
        <PathLinkButtonInverted path={Paths.adminSiteStats}>Site Stats</PathLinkButtonInverted>
        <PathLinkButtonInverted path={Paths.adminOrganizationInvitation}>Organization Invitation</PathLinkButtonInverted>
      </StyledLinks>
    </PageContent>
  )
}
