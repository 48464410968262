import { NotFound } from '@src/app/components/routes/not-found'
import {
  accountPath,
  actionPath,
  actionsPath,
  addPINPath,
  billingPath,
  captableCertificatePath,
  captablePath,
  certificateDetailsPath,
  certificateLegendPath,
  checkoutPath,
  conversionPath,
  convertibleInstrumentsPath,
  dashboardPath,
  documentsAndNotesPath,
  editPINPath,
  editConvertibleInstrumentPath,
  editConvertibleNotePath,
  editHoldingPath,
  editMemberPath,
  editOptionPath,
  editPaymentProfilePath,
  editPlanPath,
  editPoolPath,
  editProfilePath,
  editShareholdingPath,
  inviteMembersPath,
  newConvertibleInstrumentPath,
  newConvertibleNotePath,
  newHoldingPath,
  newMemberPath,
  newOptionPath,
  newPaymentProfilePath,
  newPlanPath,
  newPoolPath,
  newShareholdingPath,
  organizationAddSecuritySharesPath,
  organizationCreateLegendPath,
  organizationDetailsEditPath,
  organizationDetailsPath,
  organizationEditLegendPath,
  organizationEditSecurityPath,
  organizationLegendsPath,
  organizationMemberCertificatePath,
  organizationMemberPath,
  organizationMembersPath,
  organizationNewSecurityPath,
  organizationPath,
  OrganizationPropsOld,
  organizationSecuritiesPath,
  organizationViewSecurityPath,
  Paths,
  planPath,
  plansPath,
  portfolioPath,
  profileDetailsPath,
  profilePath,
  purchasePath,
  referralsPath,
  requestAFeaturePath,
  selectYourPlanPath,
  themesPath,
  transferShareholdingPath,
  viewConvertibleInstrumentPath,
  addCardPath,
  editCardPath,
  viewCardPath,
  welcomePath,
  organizationListPath,
  buildProfilePath,
  profilePINPath,
  allSetPath,
  rolesPath,
  createRolePath,
  editRolePath,
  viewRolePath,
  permissionsPath,
  editPermissionsPath,
  logoutPath,
  organizationViewLegendPath,
  organizationReferralsPath,
  organizationCashTransferPath,
  organizationTransactionsHistoryPath,
  equaCashTransferPath,
  transactionsHistoryPath,
  planIncentivesPath,
  planIncentivePath,
  newPlanIncentivePath,
  editPlanIncentivePath,
  poolsPath,
  vestingsPath,
  newVestingPath,
  viewVestingPath,
  editVestingPath,
  optionsPath,
  poolPath,
  planDocumentsPath,
  certificateVestingSchedulePath,
  organizationPortfolioPath,
  newOrgHoldingPath,
  editOrgHoldingPath,
  walletPortfolioPath,
  walletAddressBookPath,
  walletAddressPortfolioPath,
  reportsPath,
  poolReportsPath,
  holdingsReportsPath,
  guestReferralsPath,
  guestEquaCashTransferPath,
  guestTransactionsHistoryPath,
  guestPortfolioPath,
  guestAddPINPath,
  guestAddCardPath,
  guestWalletPortfolioPath,
  guestWalletAddressPortfolioPath,
  guestWalletAddressBookPath,
  guestProfileDetailsPath,
  guestDashboardPath,
  guestThemesPath,
  guestAccountPath,
  guestOrganizationsPath,
} from '@logic'
import { OrganizationsPage } from '@modules/organization'
import { CaptablePage } from '@modules/captable'
import {
  CaptableCertificatePage,
  CertificateLegendPage,
  DocumentsAndNotesPage,
  EditShareholdingPage,
  MemberCertificatePage,
  NewShareholdingPage,
  TransferShareholdingPage,
  ViewCertificateDetailsPage,
  ViewVestingDetailsPage,
} from '@modules/captable/pages'
import { isSiteAdmin, loadPermissions, PermissionProps } from '@components/permissions'
import { OrganizationInvitation } from '@modules/admin/components/organization-invitation'
import { Dashboard } from '@modules/organization-dashboard'
import { RouteDefinition } from '@src/app/components/routes'
import { EditMemberPage, InviteMembersPage, MembersPage, NewMemberPage } from '@modules/team-members'
import { DataRoomPage } from '@modules/documents'
import { MemberPage } from '@modules/team-members/components/team-members-profile/team-member'
import { UserDashboard } from '@modules/user-dashboard'
import { SiteStats } from '@modules/admin/components/site-stats'
import { AdminRoot } from '@modules/admin/components/admin-root'
import { ActionsPage, RequestAFeature, ViewActionPage } from '@modules/actions/pages'
import { NewOrganizationPage } from '@modules/organization/new-organization-page'
import { ComponentType } from 'react'
import { NavigationProps, withNavigation } from '@components/navigation/navigation'
import { withTracker } from '@shared/hocs/with-tracker'
import { ServiceProps, withService } from '@shared/hocs/with-service'
import Settings from '@modules/profile/components/settings'
import {
  AllPlanDocuments,
  CreateIncentive,
  CreatePool,
  CreateVestingSchedule,
  EditIncentive,
  EditOption,
  EditPlan,
  EditPool,
  EditVestingSchedule,
  IncentivesList,
  OptionHoldingsList,
  PlansList,
  PoolsList,
  VestingList,
  ViewIncentivePage,
  ViewPlanPage,
  ViewPoolPage,
  ViewVestingPage
} from '@modules/esop'
import { CreatePlan } from '@modules/esop/pages/create-plan'
import { CreateOption } from '@modules/esop/pages/create-option'
import { PurchaseShares } from '@modules/esop/pages/purchase'
import { AdminUsers } from '@modules/admin'
import {
  AddSharesPage,
  CreateLegendPage,
  EditLegendPage,
  EditOrganizationDetailsPage,
  EditOrgHoldingPage,
  EditSecurityPage,
  ListLegendsPage,
  ListSecuritiesPage,
  NewOrgHoldingPage,
  NewSecurityPage,
  OrganizationPortfolioPage,
  ViewLegendPage,
  ViewOrganizationDetailsPage,
  ViewSecurityPage,
} from '@modules/organization/pages'
import {
  ConversionPage,
  EditConvertibleInstrumentPage,
  EditConvertibleNotePage,
  ListConvertibleInstrumentsPage,
  NewConvertibleInstrumentPage,
  NewConvertibleNotePage,
  ViewConvertibleInstrumentPage
} from '@modules/convertibles'
import {
  BillingPage,
  CheckoutPage,
  EditPaymentProfilePage,
  NewPaymentProfilePage,
  SelectYourPlanPage
} from '@modules/payments/pages'
import { withOrganizationHeader } from '@src/app/components/header/organization-header/organization-header'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganizationOld } from '@shared/helpers'
import { withUpgradeHeader } from '@src/app/components/header/upgrade-header/upgrade-header'
import { withCertificateHeader } from '@src/app/components/header/certificate-header/certificate-header'
import { ForgotPassword, Login, Logout, Register, ThankYou, VerifyLogin, SendEmail, Success } from '@modules/auth/pages'
import {
  AddCardPage,
  EditAccountSettings,
  EditCardPage,
  EditHoldingPage,
  NewHoldingPage,
  PortfolioPage,
  UserDashboardPage,
  ViewAccountSettings,
  ViewCardPage,
  WalletAddressBook,
  WalletPortfolio
} from '@modules/profile/pages'
import { withProfileHeader } from '@src/app/components/header/profile-header/profile-header'
import { EditProfile, ViewProfile } from '@modules/profile/pages'
import { WelcomePage, BuildProfile, AddPinPage, AllSetPage } from '@modules/welcome/pages'
import { CreateRolePage, EditPermissionsPage, EditRolePage, PermissionsPage, RolesPage, ViewRolePage } from '@modules/roles'
import { withHeader } from '@src/app/components/header/header'
import {
  MyReferrals,
  OrganizationReferrals,
  TransferOrgEquaCash,
  TransferEquaCash,
  TransferHistoryPage,
  OrgTransferHistoryPage
} from '@modules/referrals'
import { withEquaCashTransferHeader } from '@src/app/components/header'
import { withPlanHeader } from '@src/app/components/header/plan-header/plan-header'
import { withReportsHeader } from '@src/app/components/header/reports-header/reports-header'
import { HolderReportsPage, HoldingsReportsPage, PoolsReportsPage } from '@modules/reports'
import {
  GuestAddCardPage,
  GuestDashboardPage,
  GuestEditAccountSettings,
  GuestPortfolioPage,
  GuestReferrals,
  GuestTransferEquaCash,
  GuestTransferHistoryPage,
  GuestViewAccountSettings,
  GuestWalletAddressBook,
  GuestWalletPortfolio,
  ViewGuestProfile,
  GuestOrganizationsPage
} from '@modules/guest/pages'

const notFoundRoute: RouteDefinition = {
  path: '*',
  component: NotFound,
  protected: false,
}

export const asPage = (component: ComponentType<any>) => {
  return withNavigation(withTracker(withService(component))) as any
}

export const profileRoutes: RouteDefinition[] = [
  {
    path: referralsPath,
    component: MyReferrals,
  },
  {
    path: equaCashTransferPath,
    component: withEquaCashTransferHeader(TransferEquaCash),
  },
  {
    path: transactionsHistoryPath,
    component: withEquaCashTransferHeader(TransferHistoryPage),
  },
  {
    path: portfolioPath,
    component: PortfolioPage,
  },
  {
    path: walletPortfolioPath,
    component: WalletPortfolio,
  },
  {
    path: walletAddressPortfolioPath,
    component: WalletPortfolio,
  },
  {
    path: walletAddressBookPath,
    component: WalletAddressBook,
  },
  {
    path: profileDetailsPath,
    component: ViewProfile,
  },
  {
    path: dashboardPath,
    component: UserDashboardPage,
  },
  {
    path: editProfilePath,
    component: EditProfile,
  },
  {
    path: themesPath,
    component: Settings,
  },
  {
    path: newHoldingPath,
    component: NewHoldingPage,
  },
  {
    path: editHoldingPath,
    component: EditHoldingPage,
  },
  {
    path: accountPath,
    component: ViewAccountSettings,
  },
  {
    path: addCardPath,
    component: AddCardPage,
  },
  {
    path: viewCardPath,
    component: ViewCardPage,
  },
  {
    path: editCardPath,
    component: EditCardPage,
  },
  {
    path: addPINPath,
    component: EditAccountSettings,
  },
  {
    path: editPINPath,
    component: EditAccountSettings,
  },
].map(route => ({
  ...route,
  component: asPage(withHeader(withProfileHeader(route.component as any))),
  protected: true,
  redirect: Paths.userLogin,
}))

export const guestRoutes: RouteDefinition[] = [
  {
    path: guestReferralsPath,
    component: GuestReferrals,
  },
  {
    path: guestEquaCashTransferPath,
    component: withEquaCashTransferHeader(GuestTransferEquaCash, true),
  },
  {
    path: guestTransactionsHistoryPath,
    component: withEquaCashTransferHeader(GuestTransferHistoryPage, true),
  },
  {
    path: guestPortfolioPath,
    component: GuestPortfolioPage,
  },
  {
    path: guestWalletPortfolioPath,
    component: GuestWalletPortfolio,
  },
  {
    path: guestWalletAddressPortfolioPath,
    component: GuestWalletPortfolio,
  },
  {
    path: guestWalletAddressBookPath,
    component: GuestWalletAddressBook,
  },
  {
    path: guestProfileDetailsPath,
    component: ViewGuestProfile,
  },
  {
    path: guestDashboardPath,
    component: GuestDashboardPage,
  },
  {
    path: editProfilePath,
    component: EditProfile,
  },
  {
    path: guestThemesPath,
    component: Settings,
  },
  {
    path: guestAccountPath,
    component: GuestViewAccountSettings,
  },
  {
    path: guestAddCardPath,
    component: GuestAddCardPage,
  },
  {
    path: guestAddPINPath,
    component: GuestEditAccountSettings,
  },
].map(route => ({
  ...route,
  component: asPage(withHeader(withProfileHeader(route.component as any))),
  protected: false,
}))

export const guestOrganizationsRoutes: RouteDefinition[] = [
  {
    path: guestOrganizationsPath,
    component: asPage(withHeader(GuestOrganizationsPage)),
    protected: false,
  },
]

export const organizationListRoutes: RouteDefinition[] = [
  {
    path: organizationListPath,
    component: asPage(withHeader(OrganizationsPage)),
    protected: true,
    redirect: Paths.userLogin,
  },
]
export type OrganizationDataProps = OrganizationPropsOld & PermissionProps
export const withOrganizationData = withLoadingCachedMultiple<any>(loadOrganizationOld, loadPermissions)

export const organizationRoutes: RouteDefinition[] = [
  {
    path: Paths.organizationDataRoom,
    component: withOrganizationHeader(DataRoomPage),
  },
  {
    path: optionsPath,
    component: withPlanHeader(OptionHoldingsList),
  },
  {
    path: newOptionPath,
    component: withOrganizationHeader(CreateOption),
  },
  {
    path: editOptionPath,
    component: withOrganizationHeader(EditOption),
  },
  {
    path: plansPath,
    component: withOrganizationHeader(PlansList),
  },
  {
    path: newPlanPath,
    component: withOrganizationHeader(CreatePlan),
  },
  {
    path: editPlanPath,
    component: withOrganizationHeader(EditPlan),
  },
  {
    path: planPath,
    component: withPlanHeader(ViewPlanPage),
  },
  {
    path: planIncentivesPath,
    component: withPlanHeader(IncentivesList),
  },
  {
    path: newPlanIncentivePath,
    component: withOrganizationHeader(CreateIncentive),
  },
  {
    path: editPlanIncentivePath,
    component: withOrganizationHeader(EditIncentive),
  },
  {
    path: planIncentivePath,
    component: ViewIncentivePage,
  },
  {
    path: purchasePath,
    component: withOrganizationHeader(PurchaseShares),
  },
  {
    path: poolsPath,
    component: withPlanHeader(PoolsList),
  },
  {
    path: newPoolPath,
    component: withOrganizationHeader(CreatePool),
  },
  {
    path: poolPath,
    component: withOrganizationHeader(ViewPoolPage),
  },
  {
    path: editPoolPath,
    component: withOrganizationHeader(EditPool),
  },
  {
    path: vestingsPath,
    component: withPlanHeader(VestingList),
  },
  {
    path: newVestingPath,
    component: withOrganizationHeader(CreateVestingSchedule),
  },
  {
    path: viewVestingPath,
    component: withOrganizationHeader(ViewVestingPage),
  },
  {
    path: editVestingPath,
    component: withOrganizationHeader(EditVestingSchedule),
  },
  {
    path: planDocumentsPath,
    component: withPlanHeader(AllPlanDocuments),
  },
  {
    path: captablePath,
    component: withOrganizationHeader(CaptablePage),
    // component: withOrganizationHeader(CaptablePageNew),
  },
  {
    path: newShareholdingPath,
    component: withOrganizationHeader(NewShareholdingPage),
  },
  {
    path: transferShareholdingPath,
    component: withOrganizationHeader(TransferShareholdingPage),
  },
  {
    path: editShareholdingPath,
    component: withCertificateHeader(EditShareholdingPage),
  },
  {
    path: newMemberPath,
    component: withOrganizationHeader(NewMemberPage),
  },
  {
    path: editMemberPath,
    component: withOrganizationHeader(EditMemberPage),
  },
  {
    path: organizationMemberCertificatePath,
    component: withOrganizationHeader(MemberCertificatePage),
  },
  {
    path: actionsPath,
    component: withOrganizationHeader(ActionsPage),
  },
  {
    path: requestAFeaturePath,
    component: withOrganizationHeader(RequestAFeature),
  },
  {
    path: actionPath,
    component: withOrganizationHeader(ViewActionPage),
  },
  {
    path: organizationMembersPath,
    component: withOrganizationHeader(MembersPage),
  },
  {
    path: inviteMembersPath,
    component: withOrganizationHeader(InviteMembersPage),
  },
  {
    path: organizationMemberPath,
    component: withOrganizationHeader(MemberPage),
  },
  {
    path: organizationPath,
    component: withOrganizationHeader(Dashboard),
  },
  {
    path: organizationPortfolioPath,
    component: withOrganizationHeader(OrganizationPortfolioPage),
  },
  {
    path: newOrgHoldingPath,
    component: NewOrgHoldingPage,
  },
  {
    path: editOrgHoldingPath,
    component: EditOrgHoldingPage,
  },
  {
    path: organizationDetailsPath,
    component: withOrganizationHeader(ViewOrganizationDetailsPage),
  },
  {
    path: organizationReferralsPath,
    component: withOrganizationHeader(OrganizationReferrals),
  },
  {
    path: organizationCashTransferPath,
    component: withEquaCashTransferHeader(TransferOrgEquaCash),
  },
  {
    path: organizationTransactionsHistoryPath,
    component: withEquaCashTransferHeader(OrgTransferHistoryPage),
  },
  {
    path: organizationDetailsEditPath,
    component: withOrganizationHeader(EditOrganizationDetailsPage),
  },
  {
    path: reportsPath,
    component: withReportsHeader(HolderReportsPage),
  },
  {
    path: poolReportsPath,
    component: withReportsHeader(PoolsReportsPage),
  },
  {
    path: holdingsReportsPath,
    component: withReportsHeader(HoldingsReportsPage),
  },
  {
    path: organizationSecuritiesPath,
    component: withOrganizationHeader(ListSecuritiesPage),
  },
  {
    path: organizationNewSecurityPath,
    component: withOrganizationHeader(NewSecurityPage),
  },
  {
    path: organizationEditSecurityPath,
    component: withOrganizationHeader(EditSecurityPage),
  },
  {
    path: organizationAddSecuritySharesPath,
    component: withOrganizationHeader(AddSharesPage),
  },
  {
    path: organizationViewSecurityPath,
    component: withOrganizationHeader(ViewSecurityPage),
  },
  {
    path: organizationLegendsPath,
    component: withOrganizationHeader(ListLegendsPage),
  },
  {
    path: organizationCreateLegendPath,
    component: withOrganizationHeader(CreateLegendPage),
  },
  {
    path: organizationViewLegendPath,
    component: withOrganizationHeader(ViewLegendPage),
  },
  {
    path: organizationEditLegendPath,
    component: withOrganizationHeader(EditLegendPage),
  },
  {
    path: convertibleInstrumentsPath,
    component: withOrganizationHeader(ListConvertibleInstrumentsPage),
  },
  {
    path: newConvertibleInstrumentPath,
    component: withOrganizationHeader(NewConvertibleInstrumentPage),
  },
  {
    path: editConvertibleInstrumentPath,
    component: withOrganizationHeader(EditConvertibleInstrumentPage),
  },
  {
    path: viewConvertibleInstrumentPath,
    component: withOrganizationHeader(ViewConvertibleInstrumentPage),
  },
  {
    path: newConvertibleNotePath,
    component: withOrganizationHeader(NewConvertibleNotePage),
  },
  {
    path: editConvertibleNotePath,
    component: withOrganizationHeader(EditConvertibleNotePage),
  },
  {
    path: conversionPath,
    component: withOrganizationHeader(ConversionPage),
  },
  {
    path: billingPath,
    component: withOrganizationHeader(BillingPage),
  },
  {
    path: newPaymentProfilePath,
    component: withOrganizationHeader(NewPaymentProfilePage),
  },
  {
    path: editPaymentProfilePath,
    component: withOrganizationHeader(EditPaymentProfilePage),
  },
  {
    path: selectYourPlanPath,
    component: withUpgradeHeader(SelectYourPlanPage),
  },
  {
    path: checkoutPath,
    component: withUpgradeHeader(CheckoutPage),
  },
  {
    path: captableCertificatePath,
    component: withCertificateHeader(CaptableCertificatePage),
  },
  {
    path: certificateDetailsPath,
    component: withCertificateHeader(ViewCertificateDetailsPage),
  },
  {
    path: certificateVestingSchedulePath,
    component: withCertificateHeader(ViewVestingDetailsPage),
  },
  {
    path: certificateLegendPath,
    component: withCertificateHeader(CertificateLegendPage),
  },
  {
    path: documentsAndNotesPath,
    component: withCertificateHeader(DocumentsAndNotesPage),
  },
  {
    path: rolesPath,
    component: withOrganizationHeader(RolesPage),
  },
  {
    path: createRolePath,
    component: withOrganizationHeader(CreateRolePage),
  },
  {
    path: editRolePath,
    component: withOrganizationHeader(EditRolePage),
  },
  {
    path: permissionsPath,
    component: withOrganizationHeader(PermissionsPage),
  },
  {
    path: editPermissionsPath,
    component: withOrganizationHeader(EditPermissionsPage),
  },
  {
    path: viewRolePath,
    component: withOrganizationHeader(ViewRolePage),
  },
  {
    path: Paths.newOrganization,
    component: NewOrganizationPage,
    protected: true,
    redirect: Paths.userLogin,
  },
].map(route => ({
  ...route,
  component: asPage(withOrganizationData(withHeader(
    // withPermissions([BuiltInPermission.viewOrganization])
    (route.component as any)))),
  protected: true,
  redirect: Paths.userLogin,
}))

export const routes: RouteDefinition[] = [
  {
    path: Paths.userLogin,
    component: Login,
    protected: false,
  },
  {
    path: logoutPath,
    component: Logout,
    protected: false,
  },
  {
    path: Paths.userRegister,
    component: Register,
    protected: false,
  },
  {
    path: Paths.verify,
    component: VerifyLogin,
    protected: false,
  },
  {
    path: Paths.emailSent,
    component: SendEmail,
    protected: false,
  },
  {
    path: Paths.success,
    component: Success,
    protected: false,
  },
  {
    path: Paths.userForgot,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: Paths.userThanks,
    component: ThankYou,
    protected: true,
    redirect: organizationListPath,
  },
  {
    path: Paths.refSignup,
    component: Register,
    protected: false,
    redirect: organizationListPath,
  },
  {
    path: Paths.invitedSignup,
    component: Register,
    protected: false,
    redirect: organizationListPath,
  },
  {
    path: Paths.admin,
    component: AdminRoot,
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: Paths.userDashboard,
    component: UserDashboard,
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: welcomePath,
    component: withHeader(WelcomePage),
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: buildProfilePath,
    component: withHeader(BuildProfile),
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: profilePINPath,
    component: withHeader(AddPinPage),
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: allSetPath,
    component: withHeader(AllSetPage),
    protected: true,
    redirect: Paths.userLogin,
  },
  {
    path: Paths.adminOrganizationInvitation,
    component: OrganizationInvitation,
    requires: isSiteAdmin,
  },
  {
    path: Paths.adminSiteStats,
    component: SiteStats,
  },
  {
    path: Paths.adminUsers,
    component: AdminUsers,
    requires: isSiteAdmin,
  },
].concat(notFoundRoute as any)
  .map(route => ({
    ...route,
    component: asPage(route.component),
  }))
