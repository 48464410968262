import * as React from 'react'
import { OrganizationProps, PageProps, organizationSecuritiesPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadSecurities } from '@shared/helpers'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { AddSharesForm } from '../components/add-shares-form'
import { FilesResponse, postFile, GetSecuritiesResponse, addSecurityShares, AddSecuritySharesRequest } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'

type Props = PageProps & OrganizationProps & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities())

export const AddSharesPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities } = props
    const { organization, security } = params
    const initialValues = {}

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
      }
    }
    const onSubmit = async (values: AddSecuritySharesRequest) => {
      const { shares } = values
      const request = {
        shares
      }

      const response = await addSecurityShares(httpClient)({ organization, security }, request)
      if (response) navigate(organizationSecuritiesPath, { organization })
      else console.log('edit Organization error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationSecuritiesPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Authorize Additional Preferred A Shares" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Authorize">
          <AddSharesForm onUpload={onUpload} securities={securities} security={security} />
        </CommonForm>
      </PageContent>
    )
  }
  ))
