import { OrganizationHeader } from '@src/app/components/header/organization-header/organization-header'
import { Switch } from 'react-router'
import { organizationRoutes } from '@src/app/routes/routes'
import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganizationOld } from '@helpers/loaders'
import { NavigationProps, withNavigation } from '@components/navigation/navigation'
import { OrganizationPropsOld, PageProps } from '@src/logic/types/types'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { useContext, useEffect } from 'react'
import { AppContext } from '@src/app/app'
import { ServiceProps, withService } from '@shared/hocs/with-service'

interface StateProps {
  mapRoutes: () => void
}

type Props = PageProps & StateProps & NavigationProps & OrganizationPropsOld & ServiceProps

const withData = withLoadingCachedMultiple<Props>(loadOrganizationOld)

export const OrganizationRoutes = withPermissions([BuiltInPermission.viewOrganization])(
  withNavigation(withService(withData((props: Props) => {
    const { mapRoutes, reload } = props
    const { dispatch } = useContext(AppContext)

    useEffect(() => {
      dispatch({ type: 'SET_ORGANIZATION_RELOAD', payload: reload })
    }, [])

    return (
      <>
        <OrganizationHeader />
        <Switch>{organizationRoutes.map(mapRoutes)}</Switch>
      </>
    )
  })
)) as any)
