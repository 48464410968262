import * as React from 'react'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import styled from 'styled-components'
import { FlexCol, FlexRow } from '@src/styles'
import { SolidButton } from '@shared/components/button'
import Info from '@image/info-circle.svg'

interface Props {
    onCancel: () => void
}

const Wrap = styled<any>(FlexCol)`
  color: ${props => props.theme.white} !important;
  width: 100%;
  overflow-y: auto;
  background: ${props => props.theme.modalBack};
  .body {
    margin: 0;
    padding-inline-start: 22px;
  }
  span {
      display: inline-block;
      line-height: 24px;
  }
`

const TitleWrap = styled<any>(FlexRow)`
  align-items: center;
  svg {
      margin-right: 16px;
      path {
          fill: #177DDC;
      }
  }
`


export function needToTransferModal(props: Props) {
    const { onCancel } = props
    return newBasicModalBundle({
        title: <TitleWrap><Info />Need to transfer more Equa Cash?  </TitleWrap>,
        body: <Wrap>
            <ol className='body'>
                <li>Go to a specific org or your profile to see what balances are available</li>
                <li>Transfer from there to this org to spend your Equa Cash on these subscription dues</li>
            </ol>
        </Wrap>,
        buttons: [{
            text: 'Got it',
            onActivate: onCancel,
            componentType: SolidButton,
        }],
        max: '544px',
        noCancel: true,
        align: 'center',
    })
}
