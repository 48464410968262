import * as React from 'react'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadQueryData } from '@helpers/loaders'
import { FlexRow, whiteColor, gapSizes, StyledCertificateImage } from '@src/styles'
import { PathLink } from '@components/navigation'
import { CellProps } from 'react-table'
import { Table } from '@components/tables/table'
import styled from 'styled-components'
import { captableCertificatePath, organizationMemberPath, PageProps } from '@logic'
import { optionalUsdString } from '@modules/convertibles/components'
import { formatDateString, removeDuplicates, toCommaFloat } from '@shared/helpers'
import { QueryResponse } from '@src/service'
// @ts-ignore
import CertificateImage from '@image/certificate-image.jpg'
import { CertificateLink } from '@modules/captable/components'
import { dynamicFilter } from '@shared/components/dynamic-filter'
import { HoldingRecordType } from '@shared/helpers/holdings'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & QueryResponse

const withData = withLoadingCachedMultiple<Props>(loadQueryData)

const HoldersListWrapper = styled.div`
  ${whiteColor}
  h4 {
    padding-bottom: ${gapSizes.S};
  }
  a {
    ${whiteColor}
  }
`

export const HoldingsReportsPage = withPermissions([BuiltInPermission.viewCapTable, BuiltInPermission.viewSelf])(
    withData((props: Props) => {
        const { params, holding } = props
        const { organization } = params
        const pageSize = 100000
        const holdingsList = holding ? holding
            .filter(h =>
                h.holdingType !== HoldingRecordType.convertibleInstrument &&
                h.holdingType !== HoldingRecordType.pool &&
                h.holdingType !== HoldingRecordType.orgAsset)?.map((a) => {
                    const index = a.name?.split('-')[1]
                    return { ...a, index }
                })?.map(o => {
                    return { ...o, hidden: !o.inTable }
                }) : []

        const holderOptions = removeDuplicates(holdingsList.map(holding => ({ label: holding.holderName, value: holding.holderName })))
        const classOptions = removeDuplicates(holdingsList.map(holding => ({ label: holding.class, value: holding.class })))
        const typeOptions = removeDuplicates(holdingsList.map(holding => ({ label: holding.internalType, value: holding.internalType })))

        const filterBundle = dynamicFilter(
            {
                filters: [
                    { placeholder: 'Holder', accessor: 'holderName', options: holderOptions, withAll: true },
                    { placeholder: 'Class', accessor: 'class', options: classOptions },
                    { placeholder: 'Type', accessor: 'internalType', options: typeOptions }
                ],
                data: holdingsList
            })
        const filter = filterBundle.component
        const tableData = filterBundle.filteredData

        const columns = [
            {
                Header: 'Cert ID',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <FlexRow>
                            <StyledCertificateImage src={CertificateImage} style={{ marginRight: '8px' }} />
                            <CertificateLink holding={row.original} path={captableCertificatePath} />
                        </FlexRow>
                    )
                },
                Footer: () => <div>TOTAL</div>,
            },
            {
                Header: 'Holder',
                accessor: 'holderName',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink
                            path={organizationMemberPath}
                            args={{ organization, member: row.original.owner }}
                            target='_blank'>
                            <span>{row.original.holderName}</span>
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Class',
                accessor: 'class',
            },
            {
                Header: '#',
                accessor: 'index',
            },
            {
                Header: 'Type',
                accessor: 'internalType',
            },
            {
                Header: 'Issue Date',
                accessor: 'issueDate',
                Cell: ({ row }: CellProps<any>) => formatDateString(row.original.issueDate),
            },
            {
                Header: 'Investment',
                accessor: 'capitalContribution',
                Cell: ({ row }: CellProps<any>) => optionalUsdString(row.original.capitalContribution || 0),
                Footer: (info: any) => {
                    const investmentTotal = info.data.reduce((a: any, b: any) => a + (b.capitalContribution || 0), 0)
                    return <span>{optionalUsdString(investmentTotal)}</span>
                },
            },
            {
                Header: 'Outstanding',
                accessor: 'outstanding',
                Cell: ({ row }: CellProps<any>) => toCommaFloat(row.original.outstanding || 0),
                Footer: (info: any) => {
                    const outstandingTotal = info.data.reduce((a: any, b: any) => a + (b.outstanding || 0), 0)
                    return <span>{toCommaFloat(outstandingTotal)}</span>
                }
            },
            {
                Header: 'Ownership',
                accessor: 'memberOutstandingPer',
                Cell: ({ row }: CellProps<any>) => {
                    const outPer = row.original.outstandingPercentage || 0
                    return <span>{outPer.toFixed(2)}%</span>
                },
                Footer: (info: any) => {
                    const outstandingPerTotal = info.data.reduce((a: any, b: any) => a + (b.outstandingPercentage || 0), 0)
                    return <span>{outstandingPerTotal.toFixed(2)}%</span>
                }
            },
            {
                Header: 'Fully Diluted',
                accessor: 'memberFullyDiluted',
                Cell: ({ row }: CellProps<any>) => <span>{toCommaFloat(row.original.fullyDiluted || 0)}</span>,
                Footer: (info: any) => {
                    const fullyDilutedTotal = info.data.reduce((a: any, b: any) => a + (b.fullyDiluted || 0), 0)
                    return <span>{toCommaFloat(fullyDilutedTotal)}</span>
                }
            },
            {
                Header: 'Ownership',
                accessor: 'memberFullyDilutedPer',
                Cell: ({ row }: CellProps<any>) => {
                    const ownershipPer = row.original.fullyDilutedPercentage || 0
                    return <span>{ownershipPer.toFixed(2)}%</span>
                },
                Footer: (info: any) => {
                    const fullyDilutedPerTotal = info.data.reduce((a: any, b: any) => a + (b.fullyDilutedPercentage || 0), 0)
                    return <span>{fullyDilutedPerTotal.toFixed(2)}%</span>
                }
            },
        ]

        return (
            <PageContent>
                <PageContentHeader title='Holdings' hideCrumbs={true}>
                    {filter}
                </PageContentHeader>
                <HoldersListWrapper>
                    <Table
                        columns={columns}
                        data={tableData}
                        scrollingTable={true}
                        withAllBorders={true}
                        pageSize={pageSize}
                    />
                </HoldersListWrapper>
            </PageContent>
        )
    }))
