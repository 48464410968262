import * as React from 'react'
import { PageProps, Paths, welcomePath } from '@logic'
import styled from 'styled-components'
import { Button, LoginSignButton } from '@components/button'
import { withRouter } from 'react-router'
import { History } from 'history'
import SuccessLogo from '@image/success.svg'
import { StyledAuth, StyledAuthForm, StyledTopHeader } from '../style'

const StyledSuccess = styled.div`
  text-align: center;
  label {
    display: inline-block;
    margin-bottom: 10px;
  }
  svg {
    margin-bottom: 24px;
  }
`

export const Success = (props: PageProps) => {
  const { navigate } = props

  return (
    <StyledAuth>
      <StyledAuthForm>
        <StyledSuccess>
          <div><SuccessLogo /></div>
          <StyledTopHeader>
            Success!
          </StyledTopHeader>
          <label>Your password has been successfully reset.<br /> You can now log in to your account.</label>
          <br />
          <LoginSignButton onClick={() => navigate(Paths.userLogin)}>LOG IN</LoginSignButton>
        </StyledSuccess>
      </StyledAuthForm>
    </StyledAuth>
  )
}

// export const Success = SuccessComponent)