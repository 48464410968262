import { AmountType, ContinuousVestingSchedule, DiscreteVestingSchedule, VestingEvent, VestingSample, VestingSamples, VestmentTrigger } from '@logic'
import { ExpandedHolding, ExpandedSecurity, SecurityTypeStats } from 'service'
import { VestingScheduleType } from './utility'

function newRange(length: number) {
  return [...Array(length).keys()]
}

function sumReducer(a: number, b: number): number {
  return a + b
}

export type VestingSchedule = Omit<ContinuousVestingSchedule, 'hash'> | Omit<DiscreteVestingSchedule, 'hash'>

function flatten<T>(arrays: T[][]): T[] {
  return arrays.reduce((a, b) => a.concat(b), [])
}

export function getVestingScheduleType(schedule: any): VestingScheduleType {
  return Object.keys(schedule).includes('events')
    ? VestingScheduleType.discrete
    : VestingScheduleType.continuous
}

export function getCliffStartAmount(type: AmountType, amount: number, total: number): number {
  return type == AmountType.fixed
    ? amount
    : total * amount / 100
}

export type VestingCheck = (currentDate: Date) => (sample: VestingSample) => boolean
export const hasVested: VestingCheck = currentDate => sample => sample?.date ? sample.date <= currentDate : false
export const hasNotVested: VestingCheck = currentDate => sample => !hasVested(currentDate)(sample)

export function plotContinuousVestingSchedule(schedule: ContinuousVestingSchedule, shares: number): number[] {
  const { durationMonths, frequencyMonths, cliffMonths, cliffAmountType, cliffAmount } = schedule
  const stepCount = Math.floor(durationMonths / frequencyMonths)
  const vestingStartMonths = cliffMonths && cliffMonths > durationMonths ? durationMonths : cliffMonths || 0
  const vestingStartSteps = Math.ceil(vestingStartMonths / frequencyMonths)
  const vestingCount = stepCount > vestingStartSteps ? stepCount - vestingStartSteps : 0
  const startingShares = cliffAmountType && cliffAmount ? getCliffStartAmount(cliffAmountType, cliffAmount, shares) : 0
  const temporalOffset = startingShares ? 0 : 1 // With a cliff there is one less increment
  const temporalOffset2 = startingShares ? 1 : 0 // With a cliff there is one less increment
  const increment = (shares - startingShares) / (vestingCount - temporalOffset2)
  const preCliff = Array(vestingStartSteps * frequencyMonths).fill(0)
  const postCliff = flatten(
    newRange(vestingCount).map(step => {
      const value = startingShares + (step + temporalOffset) * increment
      return Array(frequencyMonths).fill(value)
    })
  )
  const samples = preCliff.concat(postCliff)
  const endPadding = samples.length < durationMonths ? Array(durationMonths - samples.length).fill(samples[samples.length - 1]) : []
  return samples.concat(endPadding)
}

export function resolveRelativeDate(startDate: Date, month: number, day: number = 0): Date {
  const date = new Date(startDate)
  date.setMonth(date.getMonth() + month)
  day ? date.setDate(day) : date.setDate(date.getDate() + day)
  return date
}

export function resolveContinuousVestingScheduleDate(startDate: Date, vestsOn: VestmentTrigger, index: number): Date {
  const date = resolveRelativeDate(startDate, index)
  switch (vestsOn) {
    case VestmentTrigger.anniversary:
      break
    case VestmentTrigger.firstDayOfMonth:
      date.setDate(1)
      break
    case VestmentTrigger.lastDayOfMonth:
      date.setMonth(date.getMonth() + 1)
      date.setDate(0)
      break
  }
  return date
}

export function resolveContinuousVestingSchedule(schedule: ContinuousVestingSchedule, shares: number, startDate?: Date): VestingSamples {
  const { vestsOn } = schedule
  const values = plotContinuousVestingSchedule(schedule, shares)
  return values
    .map((cumulativeValue, index) => ({
      cumulativeValue,
      date: startDate ? resolveContinuousVestingScheduleDate(startDate, vestsOn, index) : undefined
    }))
    .reduce((a, b) => {
      const previousCumulativeValue = a[a.length - 1]?.cumulativeValue || 0
      const value = b.cumulativeValue - previousCumulativeValue
      return value > 0
        ? a.concat({ ...b, value })
        : a
    }, [] as VestingSamples)
}

export function resolveVestingEventDate(startDate: Date, event: VestingEvent): Date {
  const { month, day, year } = event
  return year
    ? new Date(year, month - 1, day)
    : resolveRelativeDate(startDate, month - 1, day)
}

export function resolveDiscreteVestingSchedule(schedule: DiscreteVestingSchedule, shares: number = 0, startDate?: Date): VestingSamples {
  const { events, isValueAbsolute } = schedule
  let cumulativeValue = 0
  const sortedDate = events.map(event => isValueAbsolute ? event.date
    : startDate ? resolveVestingEventDate(startDate, event) : undefined).sort((a, b) => a.getTime() - b.getTime())
  return events.map((event, index) => {
    const date = sortedDate ? sortedDate[index] : undefined
    const value = isValueAbsolute
      ? event.value
      : event.value * shares

    cumulativeValue += value
    return { value, date, cumulativeValue, event }
  })
}

export function resolveVestingSchedule(schedule: VestingSchedule, shares: number = 0, startDate?: Date): VestingSamples {
  const type = getVestingScheduleType(schedule)
  return type == VestingScheduleType.discrete
    ? resolveDiscreteVestingSchedule(schedule as DiscreteVestingSchedule, shares, startDate)
    : resolveContinuousVestingSchedule(schedule as ContinuousVestingSchedule, shares!, startDate)
}

export function getVestedShares(samples: VestingSamples, currentDate: Date = new Date()): number {
  return samples
    .filter(hasVested(currentDate))
    .map(s => s.value)
    .reduce(sumReducer, 0)
}

export function getNextVestingEvent(samples: VestingSamples, currentDate: Date = new Date()): VestingSample | undefined {
  return samples.filter(hasNotVested(currentDate))[0]
}

export function filterEquities(stats: SecurityTypeStats[], securities?: ExpandedSecurity[], deleted?: boolean): ExpandedSecurity[] | [] {
  if (securities) {
    return securities.filter(s => deleted ? s : !s.deleted).map((arg): any => {
      const statsValue = stats.filter((el) => el.hash === arg.hash)[0]
      return { ...arg, stats: statsValue }
    })
  } else return []
}

export function filterIncentives(securities?: ExpandedSecurity[], plan?: any): ExpandedSecurity[] | [] {
  if (securities) {
    return securities.filter(s =>
      s.parent && plan?.approvedEquities?.equity?.some((a: string) => a === s.parent)
    )
  } else return []
}

export function filterPools(holdings?: ExpandedHolding[], securities?: ExpandedSecurity[], plan?: string): ExpandedHolding[] | [] {
  if (holdings && securities) {
    return holdings.filter(s =>
      securities.some(el => s.parent! === el.hash) && s.plan == plan && !s.convertibleInstrument
    )
  } else return []
}

export function mapEvents(vestingSchedule: any): VestingEvent[] {
  if (vestingSchedule.isValueAbsolute) {
    const events = vestingSchedule?.events?.map((event: any) => {
      const date = new Date(`${event.month}/${event.day}/${event.year}`)
      return { ...event, date }
    })?.sort((a: any, b: any) => a.date && b.date && a.date.getTime() - b.date.getTime())
    return events
  }
  else {
    const events = vestingSchedule?.events?.map((event: any) => ({ ...event, rValue: event.value * 100 }))
      ?.sort((a: any, b: any) => a.month - b.month)
    return events
  }
}
