import React from 'react'
import { organizationPath, OrganizationResponse, setExpanded } from '@logic'
import styled from 'styled-components'
import { FlexCol, FlexRow, fontSizes, gapSizes, HorizontalLine } from '@src/styles'
import { Panel } from './panels/panels'
import { Avatar } from './avatar'
import { overflowParams, PathLink } from './navigation'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

interface PanelProps {
  organizations?: OrganizationResponse[]
  navigate: any
}

const OrgPanelWrap = styled<any>(Panel)`
  padding: 28px 32px 8px 32px;
`

const OrgPanelContent = styled<any>(FlexCol)`
  justify-content: space-between;
  height: 100%;
  color: ${props => props.theme.white};
  position: relative;
  cursor: pointer;
  hr {
    position: absolute;
    bottom: 30px;
    left: -32px;
    right: -32px;
    margin: 0;
  }
  a {
    margin-top: 8px;
    align-self: flex-start;
    font-weight: bold;
    font-family: 'NunitoSansBold';
  }
`

const OrgFlexRow = styled<any>(FlexRow)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 ${gapSizes.XL};
`

const OrgName = styled.div`
  font-size: ${fontSizes.M};
  text-align: left;
  max-width: 220px;
`


type Props = PanelProps & StoreProps

const OrganizationsPanelComponent = (props: Props) => {
  const { organizations, navigate, setExpanded } = props
  return organizations?.map((organization: OrganizationResponse, i: number) => (
    <OrgPanelWrap key={i}
      onClick={() => {
        setExpanded()
        navigate(overflowParams(organizationPath, { organization: organization.id, parent: window.location.pathname }))
      }
      }>
      <OrgPanelContent>
        <OrgFlexRow>
          <FlexCol>
            <OrgName>
              {organization.name}
            </OrgName>
          </FlexCol>
          <Avatar size="70px" fullName={organization.name} image={organization.logo?.hash} />
        </OrgFlexRow>
        <HorizontalLine />
        <a key={i} href={''} name={`viewOrganization${organization.name}`}>
          View Organization
        </a>
      </OrgPanelContent>
    </OrgPanelWrap>
  ))
}

export const OrganizationsPanel = connect(null, { setExpanded })(OrganizationsPanelComponent as any)
