import * as React from 'react'
import { useEffect, useState } from 'react'
import { DropDiv, LabelButton, Paragraph } from './style'
import { connect } from 'react-redux'
import {
  displayToast,
  DisplayToastDispatch,
  DocumentRecord,
  Organization,
  OrganizationError,
  OrganizationState,
  PageProps,
  Paths,
  User,
  setExpanded
} from '@logic'
import DocumentSection from '@modules/documents/components/document-section'
import Loading from 'react-loading'
import { ProgressBar } from '@components/progressBar'
import { History } from 'history'
import { BuiltInPermission } from '@helpers/constants'
import { withService } from '@src/shared/hocs/with-service'
import { NavigationProps, withNavigation } from '@components/navigation/navigation'
import { addLinkModal, newFolderModal, newIntroductionModal } from '@modules/documents/components/modals'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadPermissions, PermissionProps } from '@components/permissions'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { withPermissions } from '@src/shared/hocs/with-permissions'
import { DocumentButtons } from '@modules/documents/components/document-buttons'
import { getQueryParams } from '@helpers/util'
import { SolidButton } from '@shared/components/button'
import { CommonInput, FlexCol, FlexRow } from '@src/styles'
import { ActionsDrop } from '@shared/components/actionsDrop'
import Dropzone from 'react-dropzone'
import FolderUploadIcon from '@image/folder-upload.svg'
import FileUploadIcon from '@image/file-upload.svg'
import LinkAddIcon from '@image/link-add.svg'
import NewFolderIcon from '@image/folder-add.svg'
import { ThemeProps, withTheme } from 'styled-components'
import { RouteComponentProps } from 'react-router'

type BaseProps = PageProps & PermissionProps

interface Props extends NavigationProps, BaseProps {
  displayToast: DisplayToastDispatch
  history: History
  setExpanded: any
}

interface StateProps {
  user: User
  currentOrganization: Organization
  organizationError?: OrganizationError
  organization?: OrganizationState
}
const LinkTemplate = `[InternetShortcut]
URL=$url
`

const withData = withLoadingCachedMultiple<Props>(loadPermissions) as any

const Documents = withTheme(withData(
  withPermissions([BuiltInPermission.viewDocuments])((props: Props & StateProps & ThemeProps<any>) => {
    const { currentOrganization, serviceOld, permissions, navigate, reload, setExpanded } = props
    const [documents, setDocuments] = useState<DocumentRecord[] | undefined>(undefined)
    const [uploadPercentageComplete, setUploadPercentageComplete] = useState<number | undefined>(undefined)
    const [loaded, setLoaded] = useState<boolean>(false)
    const [path, setPath] = useState<string[]>([])
    const fullPath = !currentOrganization?.legacyDataRoom ? encodeURIComponent('/' + path.join('/'))
      : encodeURIComponent(path.join('/') || '/')
    const canEditDocument = !!permissions.find(permission => permission.id === BuiltInPermission.editDocuments)
    const query = getQueryParams()

    const introductionModal = newIntroductionModal(() => {
      introductionModal.setVisible(false)
    })

    const handleLinkCreate = async (props: any) => {
      if (props) {
        const link = LinkTemplate.replace('$url', props.path!)
        const parts = [new Blob([link], { type: 'text/plain' })];
        const fileName = props.name! + '.url'
        const file = new File(parts, fileName, {
          lastModified: Date.now(),
        })
        await uploadDocuments([file])
        addALinkModal.setVisible(false)
      }
    }
    const addALinkModal = addLinkModal(handleLinkCreate)

    const createFolder = async (values: { folderName: string }) => {
      const result = (await serviceOld.addFolder(currentOrganization.id, {
        path: !currentOrganization?.legacyDataRoom ? '/' + path.join('/') :
          path.join('/') || '/',
        folderName: values.folderName.trim(),
      })) as any
      if (!result.error) {
        folderModal.setVisible(false)
        const records = await getDocuments()
        setDocumentsAndLoaded(records)
      }
    }

    const folderModal = newFolderModal(createFolder)

    const getDocuments = async (shouldSetDocuments?: boolean) => {
      setLoaded(false)
      if (currentOrganization) {
        const records = await serviceOld.getOrganizationDocuments(currentOrganization.id, fullPath)
        const recordsValue = records?.map(r => {
          if (r.folder) {
            return {
              ...r, items: r.folder.childCount == 1 ? `${r.folder.childCount} item`
                :
                `${r.folder.childCount} items`
            }
          }
          return r
        })
        if (shouldSetDocuments) {
          setDocumentsAndLoaded(recordsValue)
        }
        return recordsValue
      }
    }

    const setDocumentsAndLoaded = (records: DocumentRecord[] | undefined) => {
      setDocuments(records)
      setLoaded(true)
    }

    useEffect(() => {
      if (query.path && query.path !== '/') {
        setPath(query.path.split(','))
      }
    }, [])

    useEffect(() => {
      let mounted = true
        ; (async () => {
          const records = await getDocuments()
          if (mounted) {
            if (documents === undefined && (!records || records.length == 0) && path?.length === 0) {
              introductionModal.setVisible(true)
            }
            setDocumentsAndLoaded(records)
            const fullPath = encodeURIComponent(path.join('/') || '/')
            const search = `path=${fullPath}`
            navigate({ pathname: Paths.organizationDataRoom, search }, { organization: currentOrganization.id })
          }
        })()
      return () => {
        mounted = false
      }
    }, [path])

    const uploadDocuments = async (files: any) => {
      const onUploadProgress = (event: any) => {
        if (event.lengthComputable) {
          const percentages = +((event.loaded / event.total) * 100).toFixed(2)
          setUploadPercentageComplete(percentages)
        }
      }
      await serviceOld.uploadOrganizationDocuments(files, currentOrganization.id, fullPath, onUploadProgress)
      setUploadPercentageComplete(undefined)
      setExpanded()
      const records = await getDocuments()
      setDocumentsAndLoaded(records)
      reload()
    }

    const renderPath = () => {
      const all = ['Data Room'].concat(path)
      const links = all.slice(0, -1) // Every token except the last
      const last = all[all.length - 1]
      return (
        <div>
          {links.map((t, i) => (
            <span key={i}>
              <a
                href={''}
                onClick={e => {
                  e.preventDefault()
                  setPath(path.slice(0, i))
                }}
              >
                {t}
              </a>
              <span> > </span>
            </span>
          ))}
          <span>{last}</span>
        </div>
      )
    }
    const dropOptions = () => {
      const uploadFolder = () => {
        return <Dropzone onDrop={async (files: any) => {
          return uploadDocuments(files)
        }}>
          {({ getInputProps }) => (
            <LabelButton>
              <input {...getInputProps()} directory="" webkitdirectory="" type="file" />
              <FolderUploadIcon />
              <span>Upload Folder</span>
            </LabelButton>
          )}
        </Dropzone>

      }

      const uploadFile = () => {
        return <Dropzone onDrop={async (files: any) => {
          return uploadDocuments(files)
        }}>
          {({ getInputProps }) => (
            <LabelButton>
              <CommonInput {...getInputProps()} />
              <FileUploadIcon />
              <span>Upload File</span>
            </LabelButton>
          )}
        </Dropzone>

      }
      if (canEditDocument) {
        return [
          [<DropDiv>
            <NewFolderIcon />
            <span>New Folder</span>
          </DropDiv>, () => folderModal.setVisible(true)],
          [uploadFolder(), () => { }],
          [uploadFile(), () => { }],
          [<DropDiv>
            <LinkAddIcon />
            <span>Add Link</span>
          </DropDiv>, () => addALinkModal.setVisible(true)]
        ]
      } else return []
    }

    return (
      <>
        {folderModal.component}
        {introductionModal.component}
        {addALinkModal.component}
        <PageContent>
          <PageContentHeader title={renderPath()} direction>
            <FlexRow justifyContent={'flex-end'} style={{ marginLeft: 'auto' }}>
              <ActionsDrop options={dropOptions()} />
            </FlexRow>
          </PageContentHeader>
          <div>
            <ProgressBar percentage={uploadPercentageComplete} />
            <hr />
            <Paragraph>
              Upload all your files in one place for everyone in your organization to see and share. No more digging around to find
              important files, with Equa Document Manager we will keep your document sharing and organization simplified!
              <span>{' '} Not sure what to upload?
                <a onClick={() => introductionModal.setVisible(true)}>{' '}Click Here</a>
              </span>
            </Paragraph>
            {!loaded ? (
              <Loading />
            ) : (
              <>
                {documents && (
                  <DocumentSection
                    setLoaded={setLoaded}
                    getDocuments={getDocuments}
                    setDocumentsAndLoaded={setDocumentsAndLoaded}
                    canEditDocument={canEditDocument}
                    uploadDocuments={uploadDocuments}
                    path={path}
                    setPath={setPath}
                    documents={documents}
                    theme={props.theme!}
                    reload={reload}
                  />
                )}
              </>
            )}
          </div>
        </PageContent>
      </>
    )
  })
))

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    organizationError: state.organization!.organizationError,
    currentOrganization: state.organization!.currentOrganization,
  }
}

export const DataRoomPage = withNavigation(connect(mapStateToProps, { displayToast, setExpanded })(withService(Documents)))
