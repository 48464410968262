import styled from 'styled-components'

export const StyledRoutesContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  z-index: 10;
  overflow-x: ${props => props.user === 'true' && 'clip'};
<<<<<<< HEAD
=======
  /* margin-top: ${props => props.margin === true ? "48px" : "0px"} */
>>>>>>> 360d6e33094dbe6dfc0d7d1226d340db8d92ce26
`

export const StyledRoutes = styled.main<any>`
  z-index: 0;
  background-color: ${props => props.theme.secondary};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
`
