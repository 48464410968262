import styled, { css } from 'styled-components'
import { headerHeight, gapSizes, fontSizes, screenSizes, colors, Themes } from '@src/styles/theme'
import { PathLink } from '@components/navigation/path-link'
import { pageContentWidth } from '@components/pages/styles'
import { Avatar } from '@components/avatar'
import { FlexCol, FlexRow } from '@src/styles'
import { Paths } from '@logic'
import { rgba } from 'polished'

export const headerLinkCss = css<any>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  .headerTitle {
    color: ${props => props.disabled ? rgba(191, 191, 191, 0.4) :
    props.selected ? props.theme.accent : props.theme.beforeSelected};
    font-weight: 600;
    font-size: ${fontSizes.XXS};
    line-height: 22px;
    font-family: NunitoSansSemiBold !important;
  }
  cursor: pointer;
  padding: ${props => props.styleChange === 'true' ? '0 10px' : '0px 12px'};
  height: 40px;
  .down {
    path {
      fill: ${props => (props.selected && props.theme.accent)};
    }
    margin-left: 8px;
    :hover {
      path {
        fill: ${colors.green};
      }
    }
  }
  + a {
    margin-left: ${gapSizes.L};
  }
  p {
    white-space: normal;
  }
  :hover {
    background: ${({ theme }) => theme.tab.hover};
    .headerTitle {
      color: ${props => !props.selected ? props.theme.selected === Themes.LIGHT ? '#262626' : colors.white : colors.green};
    }
  }
`

export const StyledHeader = styled.header<any>`
  height: ${headerHeight};
  background-color: ${props => props.theme.header};
  color: ${props => props.theme.gray};
  display: flex;
  padding: 8px 24px;
`

export const HeaderLinksGroup = styled.div<any>`
  display: flex;
  height: ${props => props.hide === 'true' ? 0 : 'auto'};
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.title};
  margin: 0;
  padding: 0 var(--pageMargin);
  background-color: ${props => props.theme.tableRow};
  transition: all 300ms ease-in-out;
  overflow: auto;
  margin-top: ${props => props.withMargin === 'false' ? 0 : headerHeight};
  > div {
    max-width: ${pageContentWidth}px;
    width: 100%;
    align-self: flex-start;
    > div {
      flex-wrap: nowrap;
    }
  }
  &::-webkit-scrollbar {
    height: 10px;
    background-color: ${props => props.theme.primary};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    background-color: ${props => props.theme.scrollBar};
    border-radius: 4px;
    background-clip: content-box;
  }
  @media (max-width: ${screenSizes.XL}px) {
    min-width: 0;
  }
`

export const HeaderLink = styled<any>(PathLink)`
  ${headerLinkCss}
`

export const NestedOption = styled<any>(FlexRow)`
  ${headerLinkCss}
`

export const Divider = styled.div``

export const StyledSubTitle = styled.div<any>`
  display: flex;
  align-items: center;
  margin-bottom: ${gapSizes.XL};
  height: 145px;
  label {
    @media (max-width: ${screenSizes.M}px) {
      font-size: ${fontSizes.XXS};
    }
    @media (max-width: ${screenSizes.S}px) {
      font-size: 12px;
    }
  }
`

export const TitleLogo = styled<any>(Avatar)`
  font-size: ${fontSizes.M};
  @media (max-width: ${screenSizes.M}px) {
    font-size: ${fontSizes.XS} !important;
  }
`

export const TitleHeader = styled.div<any>`
  white-space: nowrap;
  font-family: 'NunitoSansSemiBold';
  font-size: ${fontSizes.XXL};
  transition: all 100ms ease-in;
  @media (max-width: ${screenSizes.M}px) {
    white-space: normal;
    font-size: calc(16px + 4vw);
  }
  @media (max-width: ${screenSizes.S}px) {
    white-space: normal;
    font-size: calc(12px + 4vw);
  }
`

export const MenuRow = styled<any>(FlexRow)`
  width: 100%;
  align-self: flex-end;
  justify-content: flex-start;
  margin-right: auto;
  @media (max-width: ${screenSizes.L}px) {
    margin: auto;
  }
`

export const LinksWrap = styled<any>(FlexCol)`
  padding: 10px 0;
  position: absolute;
  background: ${({ theme }) => theme.header};
  width: 230px;
  top: 49px;
  left: 0;
  border-radius: 4px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                0px 3px 14px rgba(0, 0, 0, 0.05),
                0px 5px 5px rgba(0, 0, 0, 0.12);
`

export const SubLink = styled<any>(PathLink)`
  white-space: nowrap;
  width: 100%;
  padding: 8px 16px;
  font-size: 16px;
  line-height: 24px;
  cursor: ${props => props.selected ? 'default' : 'pointer'};
  color: ${props => props.selected ? colors.green : props.theme.listText};
  :hover {
    background: ${props => !props.selected && colors.green};
    color: ${props => props.theme.selected === Themes.LIGHT && !props.selected && colors.white};
  }
  svg {
    path {
      fill: ${colors.green};
    }
  }
  .item {
    span {
      margin-left: ${props => !props.selected ? '26px' : '10px'};
    }
  }
`

export const DropBorder = styled.div<any>`
    width: 100%;
    border: 1px solid rgba(206, 218, 225, 0.3);
    margin: 10px 0;
`

export const DropFooter = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px 16px;
    justify-content: center;
    span {
        font-weight: 800;
        font-size: 14px;
        line-height: 24px;
        font-family: 'NunitoSansBold';
        color: #9CA6AD;
        margin-left: 10px;
        text-transform: uppercase;
    }
`