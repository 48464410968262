import * as React from 'react'
import styled from 'styled-components'
import { Button } from '@components/button'

const Heading = styled.div`
  width: 430px;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #2c3e50;
  margin: 39px auto 0 auto;
  max-width: 100%;
`

const Description = styled.div`
  width: 464px;
  font-weight: 700;
  font-family: 'NunitoSansBold';
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #2c3e50;
  margin: 20px auto 0 auto;
  max-width: 100%;
`

const PurchaseBtnCont = styled.div`
  display: flex;
  justify-content: center;
  margin: 60px 0 40px 0;
`

const PurchaseBtn = styled<any>(Button)`
  padding: 14px 28px;
  font-weight: 800;
  font-family: 'NunitoSansBold';
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
`
interface Props {
  goToPurchaseSubscription: () => void
}

class WaitListModal extends React.Component<Props> {
  render() {
    return (
      <>
        <Heading>How can I move to the front of the waitlist?</Heading>
        <Description>
          By purchasing a subscription, you can move up to the front of the waitlist and begin using Equa Start sooner!
        </Description>

        <PurchaseBtnCont>
          <PurchaseBtn onClick={this.props.goToPurchaseSubscription}>Purchase A Subscription</PurchaseBtn>
        </PurchaseBtnCont>
      </>
    )
  }
}

export default WaitListModal
