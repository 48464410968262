import * as React from 'react'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import styled from 'styled-components'
import { FlexCol, FlexRow, screenSizes, semiBoldStyle } from '@src/styles'
import Metamask from '@image/metamask.svg'
import Warning from '@image/warning.svg'
import { SolidButton } from '@shared/components/button'
import { optionalCommaAmount } from '@shared/helpers'
import { PathLink } from '@shared/components/navigation'
import MetaMaskOnboarding from '@metamask/onboarding'

const onboarding = new MetaMaskOnboarding()

interface Props {
    onActivate: any
    installed: boolean
}

const ConnectWalletWrap = styled<any>(FlexCol)`
  align-items: flex-start;
  span {
    line-height: 24px;
  }
  color: ${props => props.theme.white} !important;
  width: 100%;
  .metamaskLink {
      color: ${({ theme }) => theme.accent};
      cursor: pointer;
      margin: 0;
      :hover {
          text-decoration: underline;
      }
  }
`

const InfoSpan = styled.span<any>`
    margin: ${props => props.installed == 'true' ? '24px 0 0' : '12px 0'};
`

const IconRow = styled<any>(FlexRow)`
    span {
        ${semiBoldStyle}
        margin-left: 10px;
        font-size: 14px;
        line-height: 22px;
    }
`

const WarningWrap = styled<any>(FlexRow)`
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #F29D4A;
    span {
        ${semiBoldStyle}
        margin-left: 10px;
        font-size: 14px;
        line-height: 22px;
    }
    svg {
        path {
            fill: #F5A623;
        }
    }
`

export function connectWalletModal(props: Props) {
    const { installed, onActivate } = props
    const ConnectWalletComponent = () => {
        return (
            <ConnectWalletWrap>
                <IconRow>
                    <Metamask />
                    <span>MetaMask</span>
                </IconRow>
                <InfoSpan installed={installed ? 'true' : 'false'}>
                    The permissions we are requesting only allow viewing of your wallet assets.
                    This will not provide access to your private keys. You will also not be able to move your funds.
                    This connection will only display your portfolio performance and analytics.
                </InfoSpan>
                {!installed ?
                    <WarningWrap>
                        <Warning />
                        <span>You need to
                            {' '}<span onClick={() => onboarding.startOnboarding()} className='metamaskLink'>
                                install MetaMask
                            </span>{' '}
                            in your browser to continue. Once you have it installed, refresh this page.</span>
                    </WarningWrap> : null}
            </ConnectWalletWrap>
        )
    }

    return newBasicModalBundle({
        title: 'Connect a wallet',
        body: <ConnectWalletComponent />,
        max: '724px',
        align: 'center',
        buttons: [
            {
                text: 'Next',
                onActivate,
                componentType: SolidButton,
                disabled: installed ? false : true,
            },
        ]
    })
}
