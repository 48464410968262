import { ShareholdingResponse } from '@logic'
import { PathLink } from '@components/navigation/path-link'
import * as React from 'react'
import { getHoldingHashOrId } from '@helpers/util'
import { ExpandedHolding } from 'service'

interface Props {
  holding: ExpandedHolding
  path: string
  organization?: any
}

export const CertificateLink = (props: Props) => {
  const { path, holding, organization } = props
  
  const args = {
    organization: holding.entity || organization,
    shareholding: getHoldingHashOrId(holding),
    member: holding.owner!,
  }
  return (
    <PathLink path={path} args={args}>
      {holding.name}
    </PathLink>
  )
}
