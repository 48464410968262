import { AnyAction, Reducer } from 'redux'
import { ChecklistState } from '../types/types'
import { ActionType } from '../actions'

export const checklistReducer: Reducer<ChecklistState, AnyAction> = (state = { expanded: true }, action: any) => {
    switch (action.type) {
        case ActionType.CHECKLIST_EXPANDED: {
            return { ...state, expanded: true }
        }
        case ActionType.CHECKLIST_COLLAPSED: {
            return { ...state, expanded: false }
        }
    }
    return state
}
