import * as React from 'react'
import { connect } from 'react-redux'
import { StyledBanner } from './style'
import { StateTransform } from '../../../index'
import { dismissMessage, DismissMessageDispatch, ToastItems } from '@logic'
import { useEffect } from 'react'

interface StateProps {
  toasts?: ToastItems
}

interface DispatchProps {
  dismissMessage: DismissMessageDispatch
}

const component = (props: StateProps & DispatchProps) => {
  const { toasts } = props

  useEffect(() => {
    if (toasts) {
      for (const key of Object.keys(toasts)) {
        setTimeout(() => {
          props.dismissMessage(key)
        }, 4000)
      }
    }
  }, [toasts])

  return toasts ? (
    <>
      {toasts &&
        Object.keys(toasts).map((key: any) => (
          <StyledBanner type={toasts[key].type} key={key}>
            <div>{toasts[key].label}</div>
          </StyledBanner>
        ))}
    </>
  ) : null
}

const mapStateToProps: StateTransform<StateProps> = s => ({ toasts: s.toasts })

export const Banner = connect(
  mapStateToProps,
  { dismissMessage }
)(component)
