import { AnyAction, Reducer } from 'redux'
import { ActionType } from '../actions'
import { toasts } from '../toasts'
import { ToastTypes } from '@logic'

export const toastsReducer: Reducer<any, AnyAction> = (state, action) => {
  const newState = { ...state } || undefined
  switch (action.type) {
    case ActionType.REGISTER_FAILURE:
    case ActionType.LOGIN_FAILURE:
    case ActionType.CREATE_ORGANIZATION_FAILURE:
      const errorItem = toasts[action.error && action.error.key] || toasts.default
      newState[action.error.key] = errorItem
      return newState
    case ActionType.DISPLAY_TOAST:
      const toastItem = action.data.message
        ? {
            key: action.data.key,
            label: action.data.message,
            type: action.data.type,
          }
        : toasts[action.data.key] || toasts.default
      newState[action.data.key] = toastItem
      return newState
    case ActionType.DISMISS_MESSAGE:
      delete newState[action.data]
      return newState.constructor === Object && Object.keys(newState).length === 0 ? undefined : newState
    case ActionType.UPLOAD_CERTIFICATE_BACK_SUCCESS:
      const front_success_item = toasts.uploadedFile
      newState[toasts.uploadedFile.key] = front_success_item
      return newState
    case ActionType.UPLOAD_CERTIFICATE_FRONT_SUCCESS:
      let back_success_item = toasts.uploadedFile
      newState[toasts.uploadedFile.key] = back_success_item
      return newState
    case ActionType.UPLOAD_CERTIFICATE_BACK_FAILURE:
      let back_failure_item = toasts.unsupportedCertificateExtension
      newState[toasts.uploadedFile.key] = back_failure_item
      return newState
    case ActionType.UPLOAD_CERTIFICATE_FRONT_FAILURE:
      let front_fail_item = toasts.unsupportedCertificateExtension
      newState[toasts.uploadedFile.key] = front_fail_item
      return newState

    case ActionType.UPLOAD_CERTIFICATE_IMAGE_FAILURE:
      let fail_item = toasts.unsupportedCertificateExtension
      newState[toasts.uploadedFile.key] = fail_item
      return newState

    case ActionType.UPLOAD_CERTIFICATE_CSV_SUCCESS:
      let csv_upload_success = toasts.uploadedFile
      newState[toasts.uploadedFile.key] = csv_upload_success
      return newState

    case ActionType.UPLOAD_CERTIFICATE_CSV_FAILURE:
      let csv_upload_fail = toasts.invalidFileData
      newState[toasts.invalidFileData.key] = csv_upload_fail
      return newState

    case ActionType.UPLOAD_TEAM_MEMBER_CSV_SUCCESS:
      const teamMemberCsvUploadSuccess = toasts.uploadedFile
      newState[toasts.uploadedFile.key] = teamMemberCsvUploadSuccess
      return newState

    case ActionType.UPLOAD_TEAM_MEMBER_CSV_FAILURE:
      const teamMemberCsvUploadFailed = toasts.invalidFileData
      newState[toasts.invalidFileData.key] = teamMemberCsvUploadFailed
      return newState
  }
  return state
}
