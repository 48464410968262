import * as React from 'react'
import { useState, useEffect } from 'react'
import ReactMinimalPieChart from 'react-minimal-pie-chart'
import { StyledGraph, GraphInfoCont, GraphInfo, SmallInfo } from '../style'

interface Props {
  termYears: number
  chartValue: number
}

const ShareChart = (props: Props) => {
  const { termYears, chartValue } = props
  const renderInfoAboutChart = () => {
    return (
      <GraphInfoCont>
        {termYears !== 0 ? <>
          <GraphInfo>{termYears}</GraphInfo>
          <GraphInfo>{termYears == 1 ? 'Year' : 'Years'}</GraphInfo>
          <SmallInfo>Total</SmallInfo>
        </>
          :
          <GraphInfo>No Expiration</GraphInfo>

        }
      </GraphInfoCont>

    )
  }
  const data = termYears == 0 ?
    []
    :
    [
      {
        title: 'years',
        value: chartValue,
        color: '#3292C0',
      },
      {
        title: 'remaining',
        value: 100 - chartValue,
        color: '#1F313A',
      },
    ]

  return (
    <StyledGraph>
      {renderInfoAboutChart()}
      <ReactMinimalPieChart
        data={data}
        lineWidth={20}
        startAngle={270} />
    </StyledGraph>
  )
}

export default ShareChart
