import * as React from 'react'
import { emptyValidator } from '@helpers/field-validators'
import {
    dateField,
    renderFieldsSimple,
    renderHeader,
    textAreaInputFieldComponent,
    textInputFieldComponent,
    uploadFileField,
    UploadHandler
} from '@components/forms'
import { nextInternalId } from '@shared/helpers'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import Cross from '@image/close-default.svg'
import { colors, FlexRow, LightDivider } from '@src/styles'
import { CancelButton } from './button'

interface StateProps {
    onUpload: UploadHandler
    values: any
    notOptional?: boolean
    arrayName?: string
    noInitial?: boolean
    definedFields?: React.ReactElement | React.ReactElement[]
}

export const StyledNotes = styled.div<any>`
    width: 100%;
    background-color: ${props => props.theme.selectorBack};
    border-radius: 8px;
    padding: 44px 12px 1px 12px;
    position: relative;
    margin-bottom: ${props => !!props.isLast ? '' : '25px'};
    .cross {
        position: absolute;
        top: 15px;
        right: 21px;
        cursor: pointer;
        path {
            fill: ${props => props.theme.whiteIcon};
        }
    }
`

const StyledDocs = styled<any>(FlexRow)`
    width: 100%;
    justify-content: flex-end;
`

export const AddDocumentsBtn = styled<any>(CancelButton)`
  margin-top: 24px;
  color: ${colors.green} !important;
  height: 48px;
  line-height: 24px;
  border: none;
  background: transparent !important;
  &:hover {
    text-decoration: underline;
  }
`

type Props = StateProps

export const DocumentsAndNotes = (props: Props) => {
    const { notOptional, values, onUpload, arrayName, noInitial, definedFields } = props
    const required: boolean = !!notOptional
    const deleteField = (fields: any, index: number) => {
        const value = fields.value?.filter((v: any, i: number) => index === i)?.map(s => ({ ...s, isRemoved: true }))[0]
        fields.update(index, value)
    }
    let datePicker = React.useRef()

    const Fields = (name: string, fields: any, key: number, isLast: boolean, authorization: any, i: number) => {
        const disabled = authorization
        return (
            <StyledDocs key={key}>
                <StyledNotes isLast={isLast} key={key}>
                    {renderFieldsSimple([
                        {
                            name: `${name}.documentTypeName`,
                            label: 'Document Name / Title',
                            required,
                            placeholder: 'Enter document display name',
                            validate: emptyValidator,
                            component: textInputFieldComponent,
                            disabled
                        },
                        {
                            name: `${name}.approvalDocument`,
                            label: 'Upload Document',
                            required,
                            note: 'Maximum file size: 30MB. Accepted file type(s): PDF',
                            disabled,
                            ...uploadFileField({ onUpload, placeholder: 'Upload relevant document', accept: ['.pdf'] }),
                        },
                        {
                            name: `${name}.approvalDate`,
                            label: 'Date',
                            required,
                            placeholder: 'MM/DD/YYYY',
                            disabled,
                            ...dateField(datePicker),
                        },
                        {
                            name: `${name}.note`,
                            label: 'Notes',
                            required,
                            validate: emptyValidator,
                            placeholder: 'Use this area to add any relevant notes',
                            component: textAreaInputFieldComponent(),
                            disabled
                        }
                    ], true)}
                    <Cross
                        className='cross'
                        onClick={() => deleteField(fields, i)} />
                </StyledNotes>
            </StyledDocs>
        )
    }

    return (
        <>
            <FieldArray name={arrayName || 'approvalDocument'}>
                {({ fields, meta }) => {
                    const addToField = () => fields.push({ key: nextInternalId() })
                    const approvalDocumentsArray = fields?.length! > 0 ? fields.map((name: string, i: number) => {
                        const authorization = fields?.value[i]?.hash!
                        const key = fields?.value[i]?.key ? fields.value[i].key : i
                        const isLast = i === fields?.length! - 1
                        const removed = fields?.value[i]?.isRemoved
                        return !removed ? Fields(name, fields, key, isLast, authorization, i) : null
                    }) : []
                    const currentFields = approvalDocumentsArray.filter(e => e)

                    React.useEffect(() => {
                        if (!noInitial && (!values?.approvalDocument || values?.approvalDocument?.length === 0)) addToField()
                    }, [])
                    return (
                        <div style={{ width: '100%' }}>
                            {renderHeader({
                                label: 'Documents & Notes',
                                index: 0,
                                buttonText: 'Add',
                                buttonOnClick: () => addToField()
                            })}
                            {definedFields && definedFields}
                            {approvalDocumentsArray}
                            {(currentFields?.length > 0 && noInitial) && (
                                <AddDocumentsBtn onClick={(e) => { e.preventDefault(), addToField() }}>
                                    + Add DOCUMENT
                                </AddDocumentsBtn>
                            )}
                            <LightDivider stretch={true} />
                        </div>
                    )
                }}
            </FieldArray>
        </>
    )
}
