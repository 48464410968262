import {
  AddressResponse,
  AdminOrganizationInvitation,
  CreateOrganizationRequest,
  CreateSubscriptionRequest,
  displayToast,
  DocumentRecord,
  EmailAvailableRequest,
  EmptyResponse,
  ExerciseStockOptionResponse,
  getCurrentOrganization,
  GetCurrentOrganizationResponse,
  GetCurrentUserResponse,
  GetGoogleContactResponse,
  GetMemberResponse,
  GetOrganizationFeaturesResponse,
  getOrganizationList,
  GetOrganizationListResponse,
  GetOrganizationsResponse,
  GetRecentActionsResponse,
  GetReferralDataResponse,
  GetScratchCardResponse,
  GetUsersResponse,
  InvitedFriendsResponse,
  InviteTeamMemberResponse,
  IsAvailableResponse,
  LoginRequest,
  LoginResponse,
  NewMember,
  NewUserRequest,
  Organization,
  OrganizationResponse,
  PasswordResetRequest,
  ScratchCardsResponse,
  SendInvitationResponse,
  ShareholdingFormInfoResponse,
  SiteStatsResponse,
  SubmitInformationResponse,
  ToastTypes,
  UpdateUserProfileRequest,
  UploadTeamMemberCsvResponse,
  UsernameAvailableRequest,
  VerifyTwoFactorRequest,
} from '@logic'
import { HttpClient, HttpError, ResponseCode } from '../lib/http-client'
import { Uuid } from '@helpers/util'
import { clearDataCache } from '@shared/helpers'

export class Service {
  httpClient: HttpClient
  private dispatch: any
  private organizations: Organization[]

  constructor(baseUrl: string) {
    this.httpClient = new HttpClient(baseUrl)
    this.organizations = []
  }

  displayError(result: any) {
    const key = result.key ? result.key : result.code
    const message = result.message ? result.message : undefined
    if (!result.code || result.code !== ResponseCode.needsLogin) return this.dispatch(displayToast(key, ToastTypes.error, message))
  }

  addDispatch(dispatch: any) {
    this.dispatch = dispatch
    this.httpClient.setDispatch(dispatch)
  }

  resultOrThrow = (result: any) => {
    if (result instanceof HttpError) {
      this.displayError(result)
      return { error: true }
    } else {
      return result
    }
  }

  getOrganizationPermissions = async (organization: string, user: string) => {
    const result = (await this.httpClient.get(`organization/${organization}/user/${user}`)) as any
    return this.resultOrThrow(result)
  }

  login = async (data: LoginRequest, callback?: any): Promise<LoginResponse> => {
    const parsedData = { ...data, usernameOrEmail: data.usernameOrEmail.toLowerCase() }
    const result = (await this.httpClient.post('user/login', parsedData)) as any
    if (callback) callback(result)
    if (result instanceof HttpError) {
      return this.displayError(result)
    } else {
      // await this.dispatch(getOrganizationList())
      return {
        user: { ...result.user },
        profile: { ...result.profile },
        status: result.status,
      }
    }
  }

  sendVerification = async (data: any): Promise<any> => {
    const result = (await this.httpClient.post('user/email/verify/send', data)) as any
    if (!(result instanceof HttpError)) {
      this.dispatch(displayToast('verifyEmail'))
    }
    return this.resultOrThrow(result)
  }

  checkVerification = async (data: any, callback: any): Promise<any> => {
    const result = (await this.httpClient.post('user/email/verify', data)) as any
    if (!(result instanceof HttpError)) {
      this.dispatch(displayToast('checkEmail'))
      return result
    } else {
      this.displayError(result)
      throw result
    }
  }

  register = async (request: NewUserRequest): Promise<GetCurrentUserResponse> => {
    const result = (await this.httpClient.post('user', request)) as any
    return this.resultOrThrow(result)
  }

  updateUser = async (request: any, callback?: any): Promise<any> => {
    const result = (await this.httpClient.patch('user', request)) as any
    if (callback) callback(result)
    return this.resultOrThrow(result)
  }

  getCurrentUser = async (callback?: any): Promise<any> => {
    const result = await this.httpClient.get('user')
    if (callback) {
      callback(result)
    }
    return this.resultOrThrow(result)
  }

  logout = async (): Promise<any> => {
    let result
    try {
      result = (await this.httpClient.post('user/logout', {})) as any
    } catch (e) { }
    if (result instanceof HttpError) {
      throw result
    } else {
      return { user: undefined }
    }
  }

  passwordReset = async (request: PasswordResetRequest): Promise<EmptyResponse> => {
    const result = (await this.httpClient.post('user/password/reset', request)) as any
    if (!(result instanceof HttpError)) {
      this.dispatch(displayToast('resetPassword'))
    }
    return this.resultOrThrow(result)
  }

  verifyTwoFactor = async (request: VerifyTwoFactorRequest): Promise<boolean> => {
    const result = (await this.httpClient.post('user/2fa/verify', request)) as any
    return this.resultOrThrow(result)
  }

  emailAvailable = async (request: EmailAvailableRequest): Promise<IsAvailableResponse> => {
    const result = (await this.httpClient.post('user/email/available', request)) as any
    return this.resultOrThrow(result)
  }

  usernameAvailable = async (request: UsernameAvailableRequest): Promise<IsAvailableResponse> => {
    const result = (await this.httpClient.post('user/username/available', request)) as any
    return this.resultOrThrow(result)
  }

  updateUserProfile = async (request: UpdateUserProfileRequest): Promise<EmptyResponse> => {
    const result = (await this.httpClient.patch(`profile/${request.profile}`, request)) as any
    return this.resultOrThrow(result)
  }

  createOrganization = async (request: CreateOrganizationRequest): Promise<OrganizationResponse> => {
    const result = (await this.httpClient.post('organization', request)) as any
    if (result instanceof HttpError) {
      throw result
    } else {
      await this.dispatch(getOrganizationList())
      return {
        organization: result.organization,
      } as any
    }
  }

  getOrganization = async (organizationId: Uuid): Promise<any> => {
    const result = await this.httpClient.get(`organization/${organizationId}`)
    // const features = await this.getFeatures(organizationId)
    const organization = { ...result.organization }
    await this.dispatch(getCurrentOrganization(organization))
    return this.resultOrThrow(organization)
  }

  getAddress = async (addressId: string): Promise<AddressResponse> => {
    return (await this.httpClient.get(`address/${addressId}`)) as any
  }

  updateOrganization = async (organizationId: string, request: CreateOrganizationRequest): Promise<OrganizationResponse> => {
    const result = (await this.httpClient.patch(`organization/${organizationId}`, request)) as any
    if (result instanceof HttpError) {
      throw result
    } else {
      return {
        organization: result.organization,
      } as any
    }
  }

  getCurrentOrganization = async (organization: Organization): Promise<GetCurrentOrganizationResponse> => {
    return this.resultOrThrow(organization)
  }

  getFeatures = async (organization: Uuid): Promise<GetOrganizationFeaturesResponse> => {
    const result = (await this.httpClient.get(`organization/${organization}/features`)) as any
    return this.resultOrThrow(result)
  }

  async getOrganizations(): Promise<GetOrganizationsResponse> {
    return this.resultOrThrow(this.httpClient.get('organization'))
  }

  getOrganizationList = async (): Promise<GetOrganizationListResponse> => {
    const result: any = await this.httpClient.get('organization')
    const formattedResult = result.organizations ? result.organizations : result
    const sortedResult = formattedResult.sort((a: Organization, b: Organization) => a.name.localeCompare(b.name))
    if (!(sortedResult instanceof HttpError)) {
      this.organizations = sortedResult
    }
    return this.resultOrThrow(sortedResult)
  }

  uploadOrganizationDocuments = async (
    request: any,
    organizationId: string,
    path: string,
    onUploadProgress?: (e: any) => void
  ): Promise<any> => {
    const url = `organization/${organizationId}/document/many?path=${path}`
    const result = await this.httpClient.postFiles(url, request, path, onUploadProgress)
    if (!(result instanceof HttpError)) {
      this.dispatch(displayToast('uploadedFile'))
    }
    return this.resultOrThrow(result)
  }

  checkPromo = async (code: string): Promise<any> => {
    const result = await this.httpClient.post(`promo/validate`, { promoCode: code })
    if (result instanceof HttpError) {
      this.dispatch(displayToast('invalidPromo'))
    }
    return result
  }

  renameDocuments = async (organizationId: string, documentId: string, data: any, previousPath?: string): Promise<any> => {
    const queryParams = previousPath ? `?previousPath=${previousPath}` : ''
    const result = await this.httpClient.post(`organization/${organizationId}/document/${documentId}/move${queryParams}`, data)
    clearDataCache()
    return this.resultOrThrow(result)
  }

  deleteDocuments = async (organizationId: string, documentId: string, fullPath?: string): Promise<any> => {
    const queryParams = fullPath ? `?fullPath=${fullPath}` : ''
    const result = await this.httpClient.delete(`organization/${organizationId}/document/${documentId}${queryParams}`)
    clearDataCache()
    return this.resultOrThrow(result)
  }

  getOrganizationDocuments = async (organizationId: string, path: string): Promise<DocumentRecord[]> => {
    const result = await this.httpClient.get(`organization/${organizationId}/document?path=${path || '/'}`)
    return result instanceof HttpError ? [] : result.documents
  }

  uploadOrganizationLogo = async (request: any, organizationId: string): Promise<EmptyResponse> => {
    const result = (await this.httpClient.postMultipart(`organization/${organizationId}/logo`, request)) as any
    if (result instanceof HttpError) {
      throw result
    } else {
      return {}
    }
  }

  deleteMember = async (organizationId: string, memberId: string): Promise<any> => {
    const result = await this.httpClient.delete(`organization/${organizationId}/member/${memberId}`)
    return this.resultOrThrow(result)
  }

  getUsers = async (): Promise<GetUsersResponse> => {
    const result = (await this.httpClient.get(`users/`)) as any
    return this.resultOrThrow(result)
  }

  uploadTeamMemberCsv = async (data: any): Promise<UploadTeamMemberCsvResponse> => {
    const result = (await this.httpClient.postMultiPartWithData('organization/member/upload', data)) as any
    if (result instanceof HttpError) {
      throw result
    }
    return this.resultOrThrow(result)
  }

  inviteTeamMember = async (data: any): Promise<InviteTeamMemberResponse> => {
    const result = (await this.httpClient.post('organization/invite', data)) as any
    return this.resultOrThrow(result)
  }
  getRecentActions = async (data: any): Promise<GetRecentActionsResponse> => {
    const result = (await this.httpClient.get(
      'user/actions?organization=3e6937cf-e587-4b9c-9c72-19e1f20aa986&mId=f66dcf3b-d232-474b-a155-f593c128b548&page=1'
    )) as any
    return this.resultOrThrow(result)
  }
  exerciseStockOption = async (data: any): Promise<ExerciseStockOptionResponse> => {
    // let data = { organization: data }
    /*  const result = await this.httpClient.post('organization/stock', data)*/
    const result: any = await fetch('https://jsonplaceholder.typicode.com/todos/150').then(response => response.json())
    return this.resultOrThrow(result)
  }

  addFolder = async (id: string, data: any): Promise<any> => {
    const result = await this.httpClient.post(`organization/${id}/folder`, data)
    return this.resultOrThrow(result)
  }

  getReferralData = async (id: string): Promise<GetReferralDataResponse> => {
    const result = await this.httpClient.get('referral?id=' + id)
    return this.resultOrThrow(result)
  }

  getGoogleContact = async (token: string): Promise<GetGoogleContactResponse> => {
    const results: any = await this.httpClient.get('google?token=' + token)
    const newContactList: any = []
    for (const result of results) {
      if (result.email) {
        const data = {
          name: result.name,
          email: result.email,
          imgSrc: `https://www.google.com/m8/feeds/photos/media/${localStorage.getItem('userGmail')}/${result.id}?access_token=${token}`,
          isInvited: false,
          id: result.id,
        }
        newContactList.push(data)
      }
    }

    return this.resultOrThrow(newContactList)
  }

  getAllScratchCards = async (id: string): Promise<GetScratchCardResponse> => {
    const result = await this.httpClient.get('card?id=' + id)
    return this.resultOrThrow(result)
  }
  scratchCards = async (data: any): Promise<ScratchCardsResponse> => {
    const result = await this.httpClient.post('reward', data)
    return this.resultOrThrow(result)
  }

  invitedFriends = async (data: any): Promise<InvitedFriendsResponse> => {
    const result = await this.httpClient.get('invitation?id=' + data)
    return this.resultOrThrow(result)
  }

  sendInvitation = async (data: any): Promise<SendInvitationResponse> => {
    const result = await this.httpClient.post('invitation/send', data)
    if (result instanceof HttpError) {
      throw result
    }
    return result
  }

  submitInformation = async (data: any): Promise<SubmitInformationResponse> => {
    const result = await this.httpClient.post('referral/company', data)
    return this.resultOrThrow(result)
  }

  adminOrganizationInvitation = async (request: AdminOrganizationInvitation): Promise<any> => {
    const result = (await this.httpClient.post(`admin/invitation`, request)) as any
    if (result instanceof HttpError) {
      const key = result.key ? result.key : result.status
      const message = result.message ? result.message : undefined
      return this.dispatch(displayToast(key, ToastTypes.error, message))
    } else {
      return result
    }
  }

  createSubscription = async (request: CreateSubscriptionRequest): Promise<any> => {
    const { organization } = request
    const result = (await this.httpClient.post(`organization/${organization}/subscription`, request)) as any
    if (!(result instanceof HttpError)) {
      await this.dispatch(getOrganizationList())
    }
    return this.resultOrThrow(result)
  }

  getShareholdingFormConfig = async (organization: string): Promise<ShareholdingFormInfoResponse> => {
    const result = await this.httpClient.get(`organization/${organization}/shareholding/config`)
    return this.resultOrThrow(result)
  }

  getOnBoardingStatus = async (data: any): Promise<any> => {
    const result = await this.httpClient.get(`onboarding?id=${data}`)

    const org = await this.httpClient.get(`organization?id=${data}`)

    const currOrg = org.organizations.filter((v: any) => v.id === data)

    const currOrgName = currOrg[0].name

    if (result instanceof HttpError) {
      throw result
    } else {
      return { currOrgName, result }
    }
  }

  scheduleMeeting = async (data: any): Promise<any> => {
    const { orgId, eventId, id, userMsg } = data
    const req = {
      eventId,
      organization: orgId,
      id,
      userMsg,
    }

    const res = await this.httpClient.post(`onboarding`, req)

    if (res instanceof HttpError) {
      throw res
    } else {
      return res
    }
  }

  setupCapTable = async (data: any): Promise<any> => {
    const result: any = await this.httpClient.post(`captablesetup`, data)
    if (result instanceof HttpError) {
      throw result
    }

    return result
  }

  uploadCertificateCsv = async (data: any): Promise<any> => {
    const result = await this.httpClient.postMultiPartWithData('organization/shareholding/upload', data)
    if (result instanceof HttpError) {
      throw result
    } else return result
  }

  async getSiteStats(): Promise<SiteStatsResponse> {
    const result = await this.httpClient.get('site/stats')
    return this.resultOrThrow(result)
  }

  async getOrganizationMember(organization: Uuid, member: Uuid): Promise<GetMemberResponse> {
    const response = this.resultOrThrow(await this.httpClient.get(`organization/${organization}/member/${member}`))
    return {
      memberRecord: response.member,
    }
  }

  async createMember(organization: Uuid, memberRecord: NewMember): Promise<any> {
    return this.resultOrThrow(await this.httpClient.post(`organization/${organization}/member`, memberRecord))
  }

  async getOrganizationMemberLimit(organization: Uuid): Promise<any> {
    return this.resultOrThrow(await this.httpClient.get(`organization/${organization}/structure/member/limit`))
  }

  async getPaymentInfo(organization: Uuid): Promise<any> {
    const result = await this.httpClient.get(`organization/${organization}/subscription`)
    if (result instanceof HttpError) {
      return {}
    }
    return result
  }
}
