import * as React from 'react'
import { PageProps, portfolioPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding, loadColors } from '@helpers/loaders'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { NewHoldingRequest, patchCapitalChange, CapitalChangeRequest, ExpandedHoldingResponse, editHolding, PatchHoldingRequest, patchHolding } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { HoldingForm } from '../components/holding-form'
import { GetColorsResponse, newColor, NewColorRequest } from '@src/service/services/profile'
import { colorPicker } from '@shared/components/color-picker'
import { colorPickerModal } from '../components/profile-modals'
import { CapitalContributionType } from '@modules/captable/utility'
import { safeDivide } from '@shared/helpers'

type Props = PageProps & ExpandedHoldingResponse & GetColorsResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadColors('user'))

export const EditHoldingPage = withData((props: Props) => {
    const { httpClient, navigate, params, user, holding, colors } = props
    const initialValues = {
        name: holding?.name!,
        abbreviation: holding?.abbreviation!,
        tickerSymbol: holding?.tickerSymbol!,
        value: holding?.value!,
        originalPricePerUnit: holding?.originalPricePerUnit!,
        currentPricePerUnit: holding?.pricePerUnit!,
    }

    const onSubmit = async (values: any) => {
        const pricePerUnit = values?.currentPricePerUnit
        const request = {
            name: values!.name,
            abbreviation: values!.abbreviation,
            tickerSymbol: values!.tickerSymbol,
            value: values!.value,
            pricePerUnit,
            entity: user?.id,
            owner: user?.id,
            holding: params.holding
        } as NewHoldingRequest

        const response = await editHolding(httpClient)({ holding: params.holding }, request)
        if (response?.hash || response?.id) {
            const holdingHash = response.hash!
            if (values?.originalPricePerUnit !== holding?.originalPricePerUnit!) {
                const patchRequest = {
                    originalPricePerUnit: values?.originalPricePerUnit!,
                    holding: holdingHash,
                } as PatchHoldingRequest
                await patchHolding(httpClient)({ holding: response.hash }, patchRequest)
            }
            const capitalChangeRequest = {
                mod: values?.value * values?.originalPricePerUnit,
                timestamp: new Date(),
                type: CapitalContributionType.cashPaid,
                source: holdingHash
            } as CapitalChangeRequest
            await patchCapitalChange(httpClient)(
                { entity: user?.id! }, capitalChangeRequest
            )
            if (colors[response.id] !== selectedColor.replace('#', '')) {
                const colorRequest = {
                    value: selectedColor.replace('#', '')
                } as NewColorRequest
                await newColor(httpClient)(
                    { user: user?.id!, target: response.id }, colorRequest
                )
                navigate(portfolioPath, {})
            } else navigate(portfolioPath, {})
        }
        else console.log('edit holding error') // TODO: add error message toast
    }

    const colorPickerBundle = colorPicker(`#${colors[holding?.id!]}`)
    const ColorPicker = colorPickerBundle.component
    const selectedColor = colorPickerBundle.color
    const pickColor = () => {
        pickColorModal.setVisible(false)
    }
    const pickColorModal = colorPickerModal(pickColor, ColorPicker)

    const parent: BaseLinkProps = { path: portfolioPath, args: {} }

    return (
        <PageContent>
            {pickColorModal.component}
            <PageContentHeader title={`Edit ${holding?.name!} Asset Details`} parent={parent} />
            <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                submitText="Save">
                <HoldingForm color={selectedColor} colorModal={pickColorModal} />
            </CommonForm>
        </PageContent>
    )
})
