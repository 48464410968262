import * as React from 'react'
import { MembersProps, PageProps, viewConvertibleInstrumentPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { DetailsLabel, FlexCol, FlexRow, fontSizes, ListLabel, ListValue, screenSizes, whiteColor } from '@src/styles'
import styled from 'styled-components'
import {
  loadHolding,
  loadMembers,
  loadOrganizationHoldings,
  loadSecurities,
  toCommaFloat,
  toCommaNumber,
} from '@helpers/index'
import { CommonForm, renderHeader } from '@components/forms'
import { ExpandedHoldingResponse, ExpandedHoldingsResponse, GetSecuritiesResponse, newConversion } from '@src/service'
import {
  ConvertibleInstrument,
  getAccrualFrequency,
  getInterestFromConvertible,
  getMaxShares,
  getOrganizationTotalUnits
} from '../utility'
import { BaseLinkProps } from '@components/navigation'
import { ConversionForm } from '../components/conversion-form'
import { optionalUsdString } from '../components'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & ExpandedHoldingResponse & GetSecuritiesResponse & ExpandedHoldingsResponse & MembersProps

const withData = withLoadingCachedMultiple<Props>(loadHolding, loadSecurities(), loadOrganizationHoldings('all'), loadMembers)

const FormWrapper = styled.div`
  form {
    max-width: 100%;
  }
  div {
    margin: 0;
  }
`

const Wrapper = styled.div`
  ${whiteColor}
  p {
    color: ${props => props.theme.gray};
    font-size: ${fontSizes.XS};
    margin: 0;
  }
  h5 {
    font-size: ${fontSizes.S};
    line-height: 25px;
    font-weight: 300;
  }
`

const Panel = styled<any>(FlexCol)`
  justify-content: flex-start;
  width: 100%;
  align-items: normal;
  background: ${props => props.theme.panelBack};
  border-radius: 8px;
  padding: 0 30px 20px 30px;
  h2 {
    padding: 0 !important;
  }
`

const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 52px;
  @media(max-width: ${screenSizes.XS}px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`

const renderPanelInfo = (label: string, value: any, index: number) => {
  return (
    <FlexRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
      <ListLabel>{label}</ListLabel>
      <ListValue>
        {value}
      </ListValue>
    </FlexRow>
  )
}

export const ConversionPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { params, navigate, holding, httpClient, securities, holdings, stats, members } = props
    const { organization } = params
    const securitiesList = securities && securities.length > 0 ? securities : []
    const convertibleInstrument = holding.convertibleInstrument as ConvertibleInstrument

    const notesList = holdings?.filter(el => el.parent === holding.id)
    const notesData = notesList.map(el => {
      const interest = getInterestFromConvertible(convertibleInstrument, el.issueDate!, el.capitalContribution!)
      const projectedPrinciple = el.capitalContribution! + interest
      const valuationMin = convertibleInstrument?.valuationMin!
      const conversionDiscount = convertibleInstrument?.conversionDiscount!
      const totalUnits = getOrganizationTotalUnits(securities)
      const maxAmountOfSharesIssued = getMaxShares({
        maxUsd: projectedPrinciple,
        valuationMin,
        totalUnits,
        discount: conversionDiscount
      })
      const holder = members?.find(s => s.id === el.owner)?.fullName
      return {
        ...el,
        withInterest: projectedPrinciple,
        sharesOwed: maxAmountOfSharesIssued,
        holder,
      }
    })

    const conversionSettings = [
      {
        label: 'Minimum Valuation',
        value: optionalUsdString(convertibleInstrument.valuationMin)
      },
      {
        label: 'Maximum Valuation',
        value: optionalUsdString(convertibleInstrument.valuationMax)
      },
      {
        label: 'Discount Rate',
        value: `${toCommaFloat(convertibleInstrument.conversionDiscount)}%`
      },
      {
        label: 'Interest Rate',
        value: `${toCommaNumber(convertibleInstrument.interestRate || 0)}%`
      },
      {
        label: 'Accrual Frequency',
        value: getAccrualFrequency[convertibleInstrument.accrualFrequency]
      },
      {
        label: 'Actual Price Per Unit',
        value: optionalUsdString(holding.pricePerUnit!)
      },
    ]

    const otherSettings = [
      {
        label: 'Qualified Financing Amount',
        value: optionalUsdString(convertibleInstrument.qualifiedFinancingAmount)
      },
      {
        label: 'Early Exit Multiple',
        value: `${toCommaNumber(convertibleInstrument.earlyExitMultiple || 1)}x`
      },
      {
        label: 'Seniority Level',
        value: holding.seniority
      },
    ]

    const initialValues = {}

    const onSubmit = async (values: any) => {
      const distributions = notesData.map(el => {
        return {
          shareOwed: el.sharesOwed,
          value: values.distributeShares[el.hash],
          convertibleNote: el.hash
        }
      })

      const request = {
        holding: holding.hash!,
        valuation: values?.valuation!,
        distributions
      }
      const response = await newConversion(httpClient)({ holding: holding.hash }, request)
      if (response) navigate(viewConvertibleInstrumentPath, { organization, holding: holding.hash })
    }

    const parent: BaseLinkProps = { path: viewConvertibleInstrumentPath, args: { organization, holding: params.holding } }

    return (
      <PageContent>
        <PageContentHeader title={`Convertible Instrument ${holding?.name}`} parent={parent} />
        <Wrapper>
          <PanelsWrapper>
            <Panel>
              {renderHeader({ label: 'Conversion Settings' })}
              {conversionSettings.map((el, index: number) => {
                return renderPanelInfo(el.label, el.value, index)
              })}
            </Panel>
            <Panel>
              {renderHeader({ label: 'Other Settings' })}
              {otherSettings.map((el, index: number) => {
                return renderPanelInfo(el.label, el.value, index)
              })}
            </Panel>
          </PanelsWrapper>
          <FormWrapper>
            <CommonForm onSubmit={onSubmit} initialValues={initialValues} stretched={true}>
              <ConversionForm
                notesData={notesData}
                securitiesData={securitiesList}
                holding={holding}
                organization={organization}
                navigate={navigate}
              />
            </CommonForm>
          </FormWrapper>
        </Wrapper>
      </PageContent>
    )
  }
  ))
