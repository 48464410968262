import { Hash, UrlPath, Uuid } from '@helpers/util'

export interface OrganizationUrlProps {
  organization: Uuid
}

export interface PlanUrlProps extends OrganizationUrlProps, BlockUrlProps {
  plan: Uuid
}

export interface PlanIncentiveUrlProps extends PlanUrlProps {
  incentive: Uuid
}

export interface PoolUrlProps extends PlanUrlProps, BlockUrlProps {
  pool: Hash
}

export interface VestingUrlProps extends PlanUrlProps, BlockUrlProps {
  vesting: Hash
}

export interface PurchaseUrlProps extends PlanUrlProps, BlockUrlProps {
  option: Uuid
}

export interface InviteMembersProps extends OrganizationUrlProps {
  members: Uuid[]
}

export interface SecurityUrlProps extends OrganizationUrlProps, BlockUrlProps {
  security: Uuid
}

export interface LegendUrlProps extends OrganizationUrlProps {
  legend: Uuid
}

export interface ActionUrlProps extends OrganizationUrlProps {
  action: Uuid
}

export interface CertificateUrlProps extends OrganizationUrlProps, BlockUrlProps {
  shareholding: Uuid
}

export interface EntityProps {
  entity?: Uuid
}

export interface PaymentUrlProps extends EntityProps {
  profile: Uuid
}

export interface ProfileHoldingUrlProps {
  holding: Uuid
}
export interface HoldingUrlProps extends OrganizationUrlProps, BlockUrlProps {
  holding: Uuid
}
export interface ConvertibleNoteUrlProps extends HoldingUrlProps, BlockUrlProps {
  note: Uuid
}

export interface BlockUrlProps extends OrganizationUrlProps {
  block?: Uuid
}

export interface MemberUrlProps extends OrganizationUrlProps {
  member: Uuid
}

export interface MemberCertificateUrlProps extends MemberUrlProps, CertificateUrlProps {
  member: Uuid
}

export interface RolesUrlProps extends OrganizationUrlProps {
  role: Uuid
}

// Basic
export const welcomePath: UrlPath<{}> = `/welcome`
export const buildProfilePath: UrlPath<{}> = `/welcome/build-profile`
export const profilePINPath: UrlPath<{}> = `/welcome/add-pin`
export const allSetPath: UrlPath<{}> = `/welcome/completed`
export const logoutPath: UrlPath<{}> = `/logout`
export const userLogin:UrlPath<{}> =  '/login'

// Organizations
export const organizationListPath: UrlPath<{}> = `/organization`
export const organizationPath: UrlPath<OrganizationUrlProps> = `/organization/:organization`
export const organizationPortfolioPath: UrlPath<OrganizationUrlProps> = `/organization/:organization/portfolio`
export const newOrgHoldingPath: UrlPath<{}> = `${organizationPortfolioPath}/holding/new`
export const editOrgHoldingPath: UrlPath<ProfileHoldingUrlProps> = `${organizationPortfolioPath}/holding/:holding/edit`
export const organizationMembersPath: UrlPath<OrganizationUrlProps> = `/organization/:organization/member`
export const organizationMemberPath: UrlPath<MemberUrlProps> = `${organizationMembersPath}/:member`
export const newMemberPath: UrlPath<OrganizationUrlProps> = `${organizationMembersPath}/new`
export const editMemberPath: UrlPath<MemberUrlProps> = `${organizationMemberPath}/edit`
export const organizationMemberCertificatePath: UrlPath<MemberCertificateUrlProps> = `${organizationMemberPath}/certificate/:shareholding`
export const inviteMembersPath: UrlPath<OrganizationUrlProps> = `${organizationPath}/invite`

// Cap Table
export const capitalizationPath: UrlPath<BlockUrlProps> = `${organizationPath}/capitalization/block/:block`
export const captablePath: UrlPath<BlockUrlProps> = `${capitalizationPath}/captable`
export const newShareholdingPath: UrlPath<BlockUrlProps> = `${captablePath}/new`
export const transferShareholdingPath: UrlPath<BlockUrlProps> = `${captablePath}/transfer`
export const captableCertificatePath: UrlPath<CertificateUrlProps> = `${captablePath}/certificate/:shareholding`
export const editShareholdingPath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/edit`
export const certificateDetailsPath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/details`
export const certificateVestingSchedulePath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/vesting`
export const certificateApprovalsPath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/approvals`
export const certificateLegendPath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/legend`
export const documentsAndNotesPath: UrlPath<CertificateUrlProps> = `${captableCertificatePath}/documentsAndNotes`
export const convertibleInstrumentsPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/convertible`
export const newConvertibleInstrumentPath: UrlPath<BlockUrlProps> = `${convertibleInstrumentsPath}/new`
export const viewConvertibleInstrumentPath: UrlPath<HoldingUrlProps> = `${convertibleInstrumentsPath}/view/:holding`
export const editConvertibleInstrumentPath: UrlPath<HoldingUrlProps> = `${viewConvertibleInstrumentPath}/edit`
export const viewConvertibleNotePath: UrlPath<ConvertibleNoteUrlProps> = `${viewConvertibleInstrumentPath}/note/:note`
export const newConvertibleNotePath: UrlPath<HoldingUrlProps> = `${viewConvertibleInstrumentPath}/note/new`
export const editConvertibleNotePath: UrlPath<ConvertibleNoteUrlProps> = `${viewConvertibleNotePath}/edit`
export const viewConversionPath: UrlPath<ConvertibleNoteUrlProps> = `${viewConvertibleInstrumentPath}/conversion`
export const conversionPath: UrlPath<ConvertibleNoteUrlProps> = `${viewConvertibleInstrumentPath}/conversion`
export const reportsPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/reports`
export const poolReportsPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/reports/pools`
export const holdingsReportsPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/reports/holdings`
export const organizationSecuritiesPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/securities`
export const organizationNewSecurityPath: UrlPath<BlockUrlProps> = `${organizationSecuritiesPath}/new`
export const organizationViewSecurityPath: UrlPath<SecurityUrlProps> = `${organizationSecuritiesPath}/:security`
export const organizationEditSecurityPath: UrlPath<SecurityUrlProps> = `${organizationViewSecurityPath}/edit`
export const organizationAddSecuritySharesPath: UrlPath<SecurityUrlProps> = `${organizationViewSecurityPath}/add`
export const organizationLegendsPath: UrlPath<OrganizationUrlProps> = `${capitalizationPath}/legends`
export const organizationViewLegendPath: UrlPath<LegendUrlProps> = `${organizationLegendsPath}/:legend`
export const organizationCreateLegendPath: UrlPath<OrganizationUrlProps> = `${organizationLegendsPath}/new`
export const organizationEditLegendPath: UrlPath<LegendUrlProps> = `${organizationLegendsPath}/:legend/edit`

// Stock Options
export const plansPath: UrlPath<BlockUrlProps> = `${capitalizationPath}/plans`
export const planPath: UrlPath<PlanUrlProps> = `${plansPath}/view/:plan`
export const newPlanPath: UrlPath<BlockUrlProps> = `${plansPath}/new`
export const editPlanPath: UrlPath<PlanUrlProps> = `${planPath}/edit`
export const planIncentivesPath: UrlPath<PlanUrlProps> = `${planPath}/incentives`
export const planIncentivePath: UrlPath<PlanIncentiveUrlProps> = `${planIncentivesPath}/view/:incentive`
export const newPlanIncentivePath: UrlPath<BlockUrlProps> = `${planIncentivesPath}/new`
export const editPlanIncentivePath: UrlPath<PlanIncentiveUrlProps> = `${planIncentivePath}/edit`
export const poolsPath: UrlPath<PlanUrlProps> = `${planPath}/pool`
export const optionsPath: UrlPath<PlanUrlProps> = `${planPath}/option`
export const editOptionPath: UrlPath<PurchaseUrlProps> = `${optionsPath}/:option/edit`
export const purchasePath: UrlPath<PurchaseUrlProps> = `${optionsPath}/:option/purchase`
export const newPoolPath: UrlPath<PlanUrlProps> = `${poolsPath}/new`
export const newOptionPath: UrlPath<PlanUrlProps> = `${optionsPath}/new`
export const poolPath: UrlPath<PoolUrlProps> = `${poolsPath}/view/:pool`
export const editPoolPath: UrlPath<PoolUrlProps> = `${poolPath}/edit`
export const vestingsPath: UrlPath<PlanUrlProps> = `${planPath}/vesting`
export const newVestingPath: UrlPath<PlanUrlProps> = `${vestingsPath}/new`
export const viewVestingPath: UrlPath<VestingUrlProps> = `${vestingsPath}/view/:vesting`
export const editVestingPath: UrlPath<VestingUrlProps> = `${viewVestingPath}/edit`
export const planDocumentsPath: UrlPath<PlanUrlProps> = `${planPath}/documents`

// Organization Settings
export const organizationSettingsPath: UrlPath<OrganizationUrlProps> = `${organizationPath}/settings`
export const organizationReferralsPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/referrals`
export const organizationCashTransferPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/transfer`
export const organizationTransactionsHistoryPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/transfer/history`
export const organizationDetailsPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/details`
export const organizationDetailsEditPath: UrlPath<OrganizationUrlProps> = `${organizationDetailsPath}/edit`

// Roles & Permissions
export const rolesPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/roles`
export const createRolePath: UrlPath<OrganizationUrlProps> = `${rolesPath}/new`
export const viewRolePath: UrlPath<RolesUrlProps> = `${rolesPath}/:role/view`
export const editRolePath: UrlPath<RolesUrlProps> = `${viewRolePath}/edit`
export const permissionsPath: UrlPath<OrganizationUrlProps> = `${rolesPath}/permissions`
export const editPermissionsPath: UrlPath<OrganizationUrlProps> = `${permissionsPath}/edit`

// Actions
export const actionsPath: UrlPath<OrganizationUrlProps> = `${organizationPath}/actions`
export const actionPath: UrlPath<ActionUrlProps> = `${actionsPath}/:action`
export const requestAFeaturePath: UrlPath<ActionUrlProps> = `${actionsPath}/new`

// Billing
export const billingPath: UrlPath<OrganizationUrlProps> = `${organizationSettingsPath}/billing`
export const selectYourPlanPath: UrlPath<OrganizationUrlProps> = `${billingPath}/upgrade`
export const checkoutPath: UrlPath<OrganizationUrlProps> = `${selectYourPlanPath}/checkout`
export const newPaymentProfilePath: UrlPath<OrganizationUrlProps> = `${billingPath}/payment/profile/new`
export const editPaymentProfilePath: UrlPath<PaymentUrlProps & { organization: Uuid }> = `${billingPath}/payment/profile/:profile/edit`

// Profile
export const profilePath: UrlPath<{}> = '/profile'
export const profileDetailsPath: UrlPath<{}> = `${profilePath}/details`
export const editProfilePath: UrlPath<{}> = `${profileDetailsPath}/edit`
export const dashboardPath: UrlPath<{}> = `${profilePath}/dashboard`
export const portfolioPath: UrlPath<{}> = `${profilePath}/portfolio`
export const walletsPath: UrlPath<{}> = `${profilePath}/wallets`
export const walletPortfolioPath: UrlPath<{}> = `${walletsPath}/portfolio`
export const walletAddressPortfolioPath: UrlPath<{ address: string }> = `${walletPortfolioPath}/:address`
export const walletAddressBookPath: UrlPath<{}> = `${walletsPath}/book`
export const referralsPath: UrlPath<{}> = `${profilePath}/my-referrals`
export const equaCashTransferPath: UrlPath<{}> = `${profilePath}/transfer`
export const transactionsHistoryPath: UrlPath<{}> = `${profilePath}/transfer/history`
export const themesPath: UrlPath<{}> = `${profilePath}/themes`
export const accountPath: UrlPath<{}> = `${profilePath}/account`
export const addCardPath: UrlPath<{}> = `${accountPath}/card/add`
export const viewCardPath: UrlPath<{ card: Uuid }> = `${accountPath}/card/view/:card`
export const editCardPath: UrlPath<{ card: Uuid }> = `${viewCardPath}/edit`
export const addPINPath: UrlPath<{}> = `${accountPath}/pin/add`
export const editPINPath: UrlPath<{}> = `${accountPath}/pin/edit`
export const newHoldingPath: UrlPath<{}> = `${portfolioPath}/holding/new`
export const editHoldingPath: UrlPath<ProfileHoldingUrlProps> = `${portfolioPath}/holding/:holding/edit`

// Guest
export const guestProfilePath: UrlPath<{}> = '/guest/profile'
export const guestOrganizationsPath: UrlPath<{}> = `${guestProfilePath}/organizations`
export const guestProfileDetailsPath: UrlPath<{}> = `${guestProfilePath}/details`
export const guestDashboardPath: UrlPath<{}> = `${guestProfilePath}/dashboard`
export const guestPortfolioPath: UrlPath<{}> = `${guestProfilePath}/portfolio`
export const guestWalletsPath: UrlPath<{}> = `${guestProfilePath}/wallets`
export const guestWalletPortfolioPath: UrlPath<{}> = `${guestWalletsPath}/portfolio`
export const guestWalletAddressPortfolioPath: UrlPath<{ address: string }> = `${guestWalletPortfolioPath}/:address`
export const guestWalletAddressBookPath: UrlPath<{}> = `${guestWalletsPath}/book`
export const guestReferralsPath: UrlPath<{}> = `${guestProfilePath}/my-referrals`
export const guestEquaCashTransferPath: UrlPath<{}> = `${guestProfilePath}/transfer`
export const guestTransactionsHistoryPath: UrlPath<{}> = `${guestProfilePath}/transfer/history`
export const guestThemesPath: UrlPath<{}> = `${guestProfilePath}/themes`
export const guestAccountPath: UrlPath<{}> = `${guestProfilePath}/account`
export const guestAddCardPath: UrlPath<{}> = `${guestAccountPath}/card/add`
export const guestAddPINPath: UrlPath<{}> = `${guestAccountPath}/pin/add`

export const Paths = {
  admin: '/service',
  adminOrganizationInvitation: '/service/invitations',
  adminSiteStats: '/service/stats',
  adminUsers: '/service/users',
  billing: '/organization/:organization/billing/payment',
  billingInfo: '/organization/:organization/billing/payment-info',
  capTableCertificate: '/organization/:organization/certificate/:shareholding',
  certificates: '/certificate',
  editShareholding: '/organization/:organization/captable/:shareholding/edit',
  governingDocuments: '/organization/:organization/documents/governing',
  promo: '/nov-2019-sale',
  userLogin: '/login',
  verify: '/verify',
  emailSent: '/email-sent',
  success: '/success',
  userRegister: '/register',
  subscriptions: '/payments/subscriptions',
  payments: '/payments',
  organizationDataRoom: '/organization/:organization/dataroom',
  profile: '/profile',
  newOrganization: '/new/organization',
  organization: '/organization/:organization',
  organizationList: '/organization',
  organizationActions: '/organization/:organization/actions',
  organizationStructure: '/organization/:organization/structure',
  organizationCapTable: '/organization/:organization/captable',
  newShareholding: '/organization/:organization/captable/new',
  transferShares: '/organization/:organization/captable/transfer',
  newMember: '/organization/:organization/member/new',
  editMember: '/organization/:organization/member/:member/edit',
  organizationMembers: '/organization/:organization/member',
  organizationMember: '/organization/:organization/member/:member',
  organizationMemberCertificate: '/organization/:organization/member/:member/certificate/:shareholding',
  myReferrals: '/profile/my-referrals',
  refSignup: '/refer/r',
  settings: '/profile/settings',
  portfolio: '/profile/portfolio',
  invitedSignup: '/invite/r',
  userDashboard: '/',
  userForgot: '/forgot',
  userThanks: '/thank-you',
  welcome: '/welcome',
}