import { matchesPath } from './navigation'
import { PathTitles, PathTitleBundle } from '@shared/helpers/path-titles-list'

export const PageTitles = () => {
  PathTitles.map((item: PathTitleBundle, i: number) => {
    if (matchesPath(item.path, window.location.pathname, false)) {
       document.title = `Equa | ${item?.title()!}`
    }
  })
}
