import * as React from 'react'
import { byOutstandingDescending } from '@helpers/shareholdings'
import { Uuid } from '@helpers/util'
import { captablePath, organizationMemberPath } from '@logic'
import { PanelSummaryHeader } from '@components/panels/PanelSummaryHeader'
import { Panel } from '@components/panels/panels'
import { PanelTable } from '@components/panels/panel-table'
import { useMemo } from 'react'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'

interface Props {
  organization: Uuid
  organizationStats?: any
}

interface SimpleShareholder {
  name: string
  percentage: number
  id: string
}

function truncateShareholders(shareholders: any): SimpleShareholder[] {
  const sorted = shareholders
    .sort(byOutstandingDescending).filter((s: any) => s.outstandingPercentage > 0)
    .map((s: any) =>
      ({ name: s.member, percentage: s.outstandingPercentage, id: s.memberId })
    )
  const topShareholders = sorted.slice(0, 6)
  const remainingCount = sorted.length - topShareholders.length
  // prettier-ignore
  const remainingShareholders = remainingCount > 0
    ? [
      {
        name: `+ ${remainingCount} more`,
        percentage: `${sorted.slice(topShareholders.length).reduce((a: any, b: any) => a + (b.outstandingPercentage || 0), 0)}%`,
      },
    ]
    : []

  return topShareholders.concat(remainingShareholders)
}

export const CapTableSummary = (props: Props) => {
  const { organization, organizationStats } = props
  const finalShareholdings = truncateShareholders(organizationStats)
  const columns = useMemo(() => [
    {
      accessor: 'name',
      Cell: ({ row }: CellProps<any>) => <PathLink
        path={row.original.id ? organizationMemberPath : captablePath}
        args={row.original.id ? { organization, member: row.original.id, parent: window.location.pathname }
          :
          { organization, parent: window.location.pathname }}
      >
        {row.original.name}
      </PathLink>,
    },
    {
      accessor: 'percentage',
      Cell: ({ row }: CellProps<any>) => (
        <div className="member-percentage">{row.original.percentage}%</div>
      ),
    },
  ], [])
  return (
    <Panel>
      <PanelSummaryHeader title="Cap Table Summary" path={captablePath} args={{ organization }} />
      {finalShareholdings?.length > 0 && <PanelTable columns={columns} data={finalShareholdings} />}
    </Panel>
  )
}
