import { emptyValidator, nonZeroNumberLimit, numberLimit } from '@helpers/field-validators'
import * as React from 'react'
import { BaseFieldDefinition, FieldComponent, FieldDefinition } from '@components/forms/field-groups'
import { DatePickerWrapper } from '@components/custom-date-picker/style'
import DatePicker from 'react-datepicker'
import { StyledMultiSelect } from '@components/select-multi/style'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import Dropzone from 'react-dropzone'
import { Hash, floatStringToNumber, LegendOptions, formatToDate, optionalCommaNumber, Uuid } from '@helpers/util'
import { colors, CommonInput, commonInputStyle, FlexRow, TextAreaInput } from '@src/styles'
import { ErrorButton, InputButton } from '../button'
import styled from 'styled-components'
import { formatToNumberAndDecimals } from '@modules/captable/utility'
import { StyledLoader } from '../loading'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ExpandedLegend, globalDisplayToast } from '@src/service'
import { ToastTypes } from '@logic'
import CalendarIcon from '@image/calendar.svg'
import ProtectedGrey from '@image/protected-grey.svg'
import NotProtected from '@image/not-protected.svg'
import Copy from '@image/copy.svg'
import { copyTextToClipboard } from '@shared/helpers/referral'
import { components } from 'react-select'
import { CopyHover } from '@modules/profile'
import { PathLink } from '../navigation'

export interface SelectOption<T = any> {
  label: string | number
  value: T
}

export function stringToLabeledObject(value: string): SelectOption<string> {
  return {
    value,
    label: value,
  }
}

export function decimalToString(value: number): string {
  return parseFloat(value.toFixed(16)).toString().replace(/0+$/g, '')
}

export const currencyInputFieldComponent: FieldComponent = ({ input, placeholder, hasErrors, preventDefault, disabled }) => {
  const defaultMaskOptions = {
    prefix: '$',
    suffix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
  }
  const currencyMask = createNumberMask(defaultMaskOptions)
  const inputWithValue = {
    ...input,
    value: typeof input.value === 'number' && input.value.toString().includes('e-') ? input.value.toFixed(8) : input.value?.toString(),
  }
  return <StyledMaskedInput
    {...inputWithValue}
    placeholder={placeholder}
    mask={currencyMask}
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const equaIDComponent: FieldComponent = ({ input, placeholder, hasErrors, preventDefault, disabled }) => {
  const getIdMask = (): Array<RegExp | string> => {
    return [
      /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, '-',
      /\w/, /\w/, /\w/, /\w/, '-',
      /\w/, /\w/, /\w/, /\w/, '-',
      /\w/, /\w/, /\w/, /\w/, '-',
      /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/, /\w/
    ]
  }
  return <StyledMaskedInput
    {...input}
    placeholder={placeholder}
    mask={getIdMask()}
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    guide={false}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const textInputFieldComponent: FieldComponent = ({ input, placeholder, hasErrors, disabled, preventDefault }) => (
  <CommonInput
    placeholder={placeholder}
    disabled={disabled}
    type="text"
    notEmpty={input.value ? 'true' : 'false'}
    data-lpignore="true"
    onKeyPress={(e: any) => preventDefault(e)}
    {...input}
    hasErrors={hasErrors} />
)

export const disabledInputFieldComponent: FieldComponent = ({ input, placeholder, preventDefault }) => (
  <CommonInput
    disabled
    placeholder={placeholder}
    notEmpty={input.value ? 'true' : 'false'}
    type="text"
    onKeyPress={(e: any) => preventDefault(e)}
    {...input} />
)

export function eventValueToInt(onChange: any) {
  return (e: any) => {
    e.target.value ? onChange(parseInt(e.target.value)) : onChange(null)
  }
}

export function eventValueToFloat(onChange: any) {
  return (e: any) => {
    e.target.value ? onChange(parseFloat(e.target.value)) : onChange(null)
  }
}

export const numericInputFieldComponent: FieldComponent = ({ input, placeholder, onChange, hasErrors, disabled, preventDefault }) => {
  const mergedOnChange = (e: any) => {
    const shouldChange = onChange && onChange(e)
    if (shouldChange || !onChange) input.onChange(e)
  }
  const inputWithValue = {
    ...input,
    value: input.value,
  }
  return <CommonInput
    {...inputWithValue}
    placeholder={placeholder}
    onChange={eventValueToInt(mergedOnChange)}
    type="number"
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const seniorityInputFieldComponent: FieldComponent = ({ input, placeholder, onChange, hasErrors, disabled, preventDefault }) => {
  const mergedOnChange = (e: any) => {
    const shouldChange = onChange && onChange(e)
    if (shouldChange || !onChange) input.onChange(e)
  }
  const inputWithValue = {
    ...input,
    value: input.value,
  }
  return <CommonInput
    {...inputWithValue}
    placeholder={placeholder}
    onChange={eventValueToInt(mergedOnChange)}
    type="number"
    min={1}
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const numericInputFieldWithCommas: FieldComponent = ({ input, placeholder, hasErrors, preventDefault, disabled }) => {
  const defaultMaskOptions = {
    prefix: '',
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowDecimal: true,
    decimalLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
  }
  const onChange = (change: any) => {
    input.onChange(floatStringToNumber(change.target.value))
  }
  const currencyMask = createNumberMask(defaultMaskOptions)
  return <StyledMaskedInput
    {...input}
    placeholder={placeholder}
    mask={currencyMask}
    onChange={onChange}
    data-lpignore="true"
    hasErrors={hasErrors}
    onKeyPress={(e: any) => preventDefault(e)}
    disabled={disabled}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const numericInputFieldWithDecimals: FieldComponent = ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
  const inputWithValue = {
    ...input,
    value: input.value,
  }
  return <CommonInput
    {...inputWithValue}
    placeholder={placeholder}
    onChange={eventValueToFloat(input.onChange)}
    type="number"
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const textInputFieldComponentAdvanced = (additional: any): FieldComponent =>
  ({ input, placeholder, hasErrors, disabled, preventDefault }) => (
    <CommonInput
      placeholder={placeholder}
      type="text"
      {...input}
      {...additional}
      hasErrors={hasErrors}
      disabled={disabled}
      onKeyPress={(e: any) => preventDefault(e)}
      notEmpty={input.value ? 'true' : 'false'} />
  )

export const StyledMaskedInput = styled<any>(MaskedInput)`
  ${commonInputStyle}
  background-color: ${props => props.notEmpty === 'true' ? props.theme.input.basic : props.theme.input.default};
  border: ${props => props.hasErrors && `2px solid ${colors.errorRed}`} !important;
  color: ${props => props.hasErrors && colors.errorRed} !important;
  box-shadow: ${props => props.hasErrors && 'none'};
  &::placeholder {
    color: ${props => props.hasErrors && colors.errorRed} !important;
  }
`

export const creditCardInputFieldComponent: FieldComponent = ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
  const ccSpacer = ' '
  const americanExpressMask = [
    /\d/, /\d/, /\d/, /\d/, '\u2000',
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '\u2000',
    /\d/, /\d/, /\d/, /\d/, /\d/
  ]
  const defaultMask = [
    /\d/, /\d/, /\d/, /\d/, '\u2000',
    /\d/, /\d/, /\d/, /\d/, '\u2000',
    /\d/, /\d/, /\d/, /\d/, '\u2000',
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/
  ]
  const getMask = (num: string): Array<RegExp | string> => {
    if (num?.startsWith('37') || num?.startsWith('34')) {
      return americanExpressMask
    }
    else {
      return defaultMask
    }
  }
  return <StyledMaskedInput
    mask={getMask(input.value)}
    placeholderChar={ccSpacer}
    placeholder={placeholder}
    type="text" {...input}
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export const textInputMaskFieldComponent = (mask: Array<RegExp | string>, placeholderChar?: string): FieldComponent => ({
  input,
  placeholder,
  hasErrors,
  disabled,
  preventDefault
}) => {
  return <StyledMaskedInput
    mask={mask}
    placeholderChar={placeholderChar}
    placeholder={placeholder}
    type="text" {...input}
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export interface CustomSelectOption<T = any> {
  label: string | number
  value: T
  image?: React.ReactElement<T>
  info?: string
}

const CustomSelectOption = styled<any>(FlexRow)`
  align-items: center;
  height: 52px;
  img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    margin-right: 10px;
  }
  & div[class*='-option'] {
    display: flex;
    align-items: center
  }
  & div[class*='-9gakcf'] {
    img {
      border: 2px solid ${colors.green};
    }
    span {
      color: ${colors.green};
    }
    &:before {
      content: '';
    }
  }
  span {
    :last-child {
      margin-left: 4px;
    }
  }
`

const CreateOption = styled<any>(CustomSelectOption)`
  :hover {
    a {
      color: ${colors.white};
    }
  }
`

const MenuTitle = styled.div<any>`
  padding: 8px 16px;
  color: #9CA6AD;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 12px;
  line-height: 24px;
  font-family: 'NunitoSansBold';
`

export function selectFieldComponent<T>(options: Array<CustomSelectOption<T>> | null, titleText?: string, addOption?:
  { text: string, to: string, args: any }): FieldComponent {
  return ({ input, placeholder, onChange, hasErrors, disabled, preventDefault }) => {
    const filled = input.value && input.value !== '' ? true : false

    const MenuList = (props: any) => (
      <components.MenuList {...props}>
        <MenuTitle>{titleText || 'Select Option'}</MenuTitle>
        {props.children}
        {addOption && <CreateOption>
          <components.Option {...props}>
            <PathLink path={addOption.to} args={addOption.args} target={'_blank'}>
              {addOption.text}
            </PathLink>
          </components.Option>
        </CreateOption>}
      </components.MenuList>
    )

    const Option = (props: any) => {
      const { image, info, label } = props.data
      return (
        <CustomSelectOption>
          <components.Option {...props}>
            {image && image}
            <span>{label}</span>
            {info && <span>{info}</span>}
          </components.Option>
        </CustomSelectOption>
      )
    }

    return (
      <StyledMultiSelect
        onChange={(selection: any) => {
          const value = selection ? optionToValue(selection) : selection
          onChange && onChange(value)
          input.onChange(value)
        }}
        components={{ Option, MenuList }}
        value={options && valueToOption(options)(input.value)}
        isDisabled={!options}
        options={options}
        placeholder={placeholder}
        isClearable
        hasErrors={hasErrors}
        onBlur={(e: any) => input.onBlur(e)}
        filled={filled ? 'true' : 'false'}
        disabled={disabled}
        onKeyDown={(e: any) => preventDefault(e)}
        maxMenuHeight={250}
      />
    )
  }
}

export const optionToValue = <T extends unknown>(option: SelectOption<T>) => {
  return option.value
}

export const valueToOption = <T extends unknown>(options: Array<SelectOption<T>>) => (value: T) => {
  return options.filter(o => o.value == value)[0]
}

export const multiSelectFieldComponent: (options: SelectOption[], titleText?: string, addOption?:
  { text: string, to: string, args: any })
  => FieldComponent = (options, titleText, addOption) =>
    ({ input, hasErrors, placeholder, disabled, preventDefault }) => {
      const filled = input.value && input.value.length > 0 ? true : false

      const MenuList = (props: any) => (
        <components.MenuList {...props}>
          <MenuTitle>{titleText || 'Select Option'}</MenuTitle>
          {props.children}
          {addOption && <CreateOption>
            <components.Option {...props}>
              <PathLink path={addOption.to} args={addOption.args} target={'_blank'}>
                {addOption.text}
              </PathLink>
            </components.Option>
          </CreateOption>}
        </components.MenuList>
      )

      const Option = (props: any) => {
        const { image, info, label } = props.data
        return (
          <CustomSelectOption>
            <components.Option {...props}>
              {image && image}
              <span>{label}</span>
              {info && <span>{info}</span>}
            </components.Option>
          </CustomSelectOption>
        )
      }

      return <StyledMultiSelect
        onChange={(selection: any[]) => {
          const value = selection ? selection.map(optionToValue) : []
          input.onChange(value)
        }}
        components={{ Option, MenuList }}
        value={input.value?.map((v: any) => valueToOption(options)(v))}
        isMulti={true}
        options={options}
        isSearchable={true}
        placeholder={placeholder}
        isClearable
        hasErrors={hasErrors}
        filled={filled ? 'true' : 'false'}
        disabled={disabled}
        onKeyDown={(e: any) => preventDefault(e)}
        maxMenuHeight={250}
      />
    }

export const DateWrapper = (input: any, datePicker: any, preventDefault: any, placeholder?: string, hasErrors?: boolean, disabled?: boolean) => {
  const dateValueSelected = input.value && Date.parse(input.value) ?
    typeof input.value == 'string' ? new Date((input.value).split('T')[0].replace(/\-/g, '/'))
      : new Date(input.value)
    : null

  const toggleDate = () => {
    if (datePicker) datePicker.current.setOpen(true)
  }
  return (
    <DatePickerWrapper hasErrors={hasErrors} filled={dateValueSelected ? 'true' : 'false'} disabled={disabled}>
      <DatePicker
        ref={datePicker}
        dropdownMode="select"
        showMonthDropdown
        showYearDropdown
        selected={dateValueSelected}
        onChange={input.onChange}
        placeholderText={placeholder}
        disabled={disabled}
        onKeyPress={(e: any) => preventDefault(e)}
        onBlur={(event) => input.onBlur(event)}
      />
      <CalendarIcon style={{ cursor: 'pointer' }} onClick={() => toggleDate()} />
    </DatePickerWrapper>
  )
}

export function dateField(datePicker: any, noFormat?: boolean) {
  return {
    format: noFormat ? undefined : formatToDate,
    validate: emptyValidator,
    component: ({ input, hasErrors, placeholder, disabled, preventDefault }: any) =>
      DateWrapper(input, datePicker, preventDefault, placeholder, hasErrors, disabled),
  }
}

export const currencyField = {
  validate: numberLimit,
  format: formatToNumberAndDecimals,
  component: currencyInputFieldComponent,
}

export const nonZeroCurrencyField = {
  validate: nonZeroNumberLimit,
  format: formatToNumberAndDecimals,
  component: currencyInputFieldComponent,
}

export enum Roles {
  // Organization Role
  auditor = 'b05c5dd2-e47f-11e9-a359-2a2ae2dbcce4',
  chairman = '38071b4c-4389-469d-845a-d91c76cc228e',
  director = '7d0c7b91-df11-4455-9032-de0d5de0bcd7',
  guest = '2bcbe1f8-eee0-11e9-81b4-2a2ae2dbcce4',
  investor = 'f91375b4-528a-11ea-8d77-2e728ce88125',
  legal = '3bb346ae-b264-11ea-b3de-0242ac130004',
  admin = '15a29248-e47f-11e9-81b4-2a2ae2dbcce4',
  partner = '33c908ea-c6c1-11ea-87d0-0242ac130003',
  president = '9b3ed657-01cc-4803-8e3d-01e361622254',
  secretary = '52bee392-a131-11ea-bb37-0242ac130002',
  holder = 'b90d870a-c2d5-4573-a6e4-3cc786d2392f',
  signatory = '86f22f20-4155-11ea-b77f-2e728ce88125',
  treasurer = 'f65c7da2-1982-49b4-8fa9-253d9e8b5582',
  memberAnnual = 'ed6d3700-e47f-11e9-81b4-2a2ae2dbcce4',
  memberRead = '0956d660-e480-11e9-a359-2a2ae2dbcce4',

  // Site Role
  siteAdmin = 'b63f90c5-caf9-438d-b7a9-aef047b79baa',
  siteAuditor = '3e5467ef-48aa-4156-bb9a-e0821ad3a846',
}

export const legacyRoles = [
  {
    value: Roles.auditor,
    label: 'Auditor',
  },
  {
    value: Roles.chairman,
    label: 'Chairman',
  },
  {
    value: Roles.director,
    label: 'Director',
  },
  {
    value: Roles.guest,
    label: 'Guest',
  },
  {
    value: Roles.holder,
    label: 'Holder',
  },
  {
    value: Roles.investor,
    label: 'Investor',
  },
  {
    value: Roles.legal,
    label: 'Legal',
  },
  {
    value: Roles.manager,
    label: 'Manager',
  },
  {
    value: Roles.member,
    label: 'Member',
  },
  {
    value: Roles.partner,
    label: 'Partner',
  },
  {
    value: Roles.president,
    label: 'President',
  },
  {
    value: Roles.secretary,
    label: 'Secretary',
  },
  {
    value: Roles.signatory,
    label: 'Signatory',
  },
  {
    value: Roles.treasurer,
    label: 'Treasurer',
  },
]

export const rolesField: BaseFieldDefinition = {
  validate: emptyValidator,
  component: multiSelectFieldComponent([]),
}

export interface UploadedFile {
  name: string
  hash: Hash
  id?: Uuid
}

export type UploadHandler = (files: File[]) => Promise<UploadedFile>

export interface UploadFileFieldProps {
  onUpload: UploadHandler
  placeholder: string
  accept: string | string[]
  remove?: (props: number) => void
}

const UploadFileField = styled<any>(FlexRow)`
  width: 100%;
  input:first-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`

const StyledErrorButton = styled<any>(ErrorButton)`
  &:hover {
    background: ${props => props.theme.error};
  }
`
const LoadingWrap = styled.div<any>`
  background-color: rgba(0, 0, 0, 0.2) !important;
  width: 100%;
  height: 100%;
  position: absolute;
`
const UploadWrap = styled<any>(FlexRow)`
  border: ${props => props.hasErrors && `2px solid ${colors.errorRed}`};
  position: relative;
`

export const UploadWrapper = (props: UploadFileFieldProps & { input: any, hasErrors: boolean, disabled?: boolean }) => {
  const { onUpload, placeholder, accept, remove, input, hasErrors, disabled } = props
  const [loading, setLoading] = React.useState(false)
  return <Dropzone
    accept={accept}
    onDropAccepted={(files: File[]) => {
      if (files?.length > 0) {
        setLoading(true)
        onUpload(files).then(response => {
          if (response) {
            input.onChange(response)
            setLoading(false)
          }
        }).catch(err => { globalDisplayToast(ToastTypes.error, 'There was a problem uploading your file!'), setLoading(false), console.log('err: ', err) })
      }
    }}
    onDropRejected={e => globalDisplayToast(ToastTypes.error, 'Invalid file!')}
    disabled={disabled}
  >
    {({ getRootProps, getInputProps }) => (
      <UploadWrap>
        {loading && <LoadingWrap>
          <StyledLoader type={'bars'} height={50} width={50} />
        </LoadingWrap>
        }
        <UploadFileField {...getRootProps()}>
          <CommonInput
            type="text"
            placeholder={placeholder}
            value={input.value?.name || ''}
            readOnly
            hasErrors={hasErrors}
            disabled={disabled}
            notEmpty={input.value ? 'true' : 'false'} />
          <>
            <CommonInput {...getInputProps()} />
            {remove ?
              !input.value?.name ? <InputButton disabled={disabled}>Browse</InputButton> : null
              : <InputButton disabled={disabled} name={"browse"}>Browse</InputButton>
            }
          </>
        </UploadFileField>
        {remove && input.value?.name && <StyledErrorButton onClick={remove} disabled={disabled}>Remove</StyledErrorButton>}
      </UploadWrap>
    )}
  </Dropzone >
}

export function uploadFileField(props: UploadFileFieldProps): BaseFieldDefinition {
  const { onUpload, placeholder, accept, remove } = props
  return {
    validate: emptyValidator,
    component: ({ input, hasErrors, disabled }: any) => {
      return UploadWrapper({ input, onUpload, accept, placeholder, remove, hasErrors, disabled })
    },
  }
}

export const percentageFieldComponent: FieldComponent = ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
  const defaultMaskOptions = {
    prefix: '',
    suffix: '%',
    includeThousandsSeparator: false,
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 8,
    allowNegative: false,
    allowLeadingZeroes: false,
  }
  const percentageMask = createNumberMask(defaultMaskOptions)
  const onChange = (change: any) => {
    input.onChange(floatStringToNumber(change.target.value))
  }
  const inputWithValue = {
    ...input,
    value: isNaN(input.value) ? 0 : input.value,
  }
  return <StyledMaskedInput
    {...inputWithValue}
    onChange={onChange}
    placeholder={placeholder}
    mask={percentageMask}
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
}

export function eventValueToUpperCase(onChange: any) {
  return (e: any) => {
    e.target.value ? onChange(e.target.value.toUpperCase()) : onChange('')
  }
}

export const capitalizeTextInputField: FieldComponent = ({ input, placeholder, hasErrors, disabled, preventDefault }) => (
  <CommonInput
    {...input}
    placeholder={placeholder}
    onChange={eventValueToUpperCase(input.onChange)}
    type="text"
    data-lpignore="true"
    hasErrors={hasErrors}
    disabled={disabled}
    onKeyPress={(e: any) => preventDefault(e)}
    notEmpty={input.value ? 'true' : 'false'} />
)

export const textAreaInputFieldComponent: (height?: string) => FieldComponent = height =>
  ({ input, placeholder, hasErrors, disabled, preventDefault }) => (
    <TextAreaInput
      placeholder={placeholder}
      {...input}
      hasErrors={hasErrors}
      notEmpty={input.value ? 'true' : 'false'}
      height={height}
      disabled={disabled}
      onKeyPress={(e: any) => preventDefault(e)}
    ></TextAreaInput>
  )

const CharacterWrap = styled<any>(FlexRow)`
  position: relative;
  input {
    padding: 15px 90px 15px 15px !important;
  }
  span {
    position: absolute;
    right: 12px;
    top: 12px;
    color: ${props => props.characterError === 'true' && colors.errorRed} !important;
  }
`

export const inputFieldWithCharactersComponent: (total?: number) => FieldComponent = total =>
  ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
    const characterCount = input.value?.length
    const characterError = (characterCount || 0) > (total || 60) ? true : false
    return <CharacterWrap characterError={characterError ? 'true' : 'false'}>
      <CommonInput
        placeholder={placeholder}
        type="text"
        notEmpty={input.value ? 'true' : 'false'}
        data-lpignore="true"
        {...input}
        disabled={disabled}
        onKeyPress={(e: any) => preventDefault(e)}
        hasErrors={characterError || hasErrors} />
      <span>{characterCount || 0} / {total || 60}</span>
    </CharacterWrap>
  }

export function legendField<T>(legendOptions: LegendOptions): FieldDefinition<T> {
  return {
    name: 'legend',
    label: 'Legend',
    required: false,
    validate: emptyValidator,
    component: selectFieldComponent(legendOptions),
  }
}

export function additionalLegendOptions(legends: ExpandedLegend[], selectedLegend?: Hash) {
  if (!selectedLegend) return []

  const legendRecord = legends.filter(l => l.hash == selectedLegend)[0]
    || { name: 'Unknown', label: 'Unknown', hash: selectedLegend, deleted: true }

  return legendRecord.deleted
    ? [
      { ...legendRecord, name: `${legendRecord.name} ${legendRecord.hash.slice(0, 6)}`, label: `${legendRecord.name} ${legendRecord.hash.slice(0, 6)}` }
    ]
    : []
}

const PhoneWrapper = styled.div<any>`
  display: flex;
  position: relative;
  border: ${props => props.hasErrors && `2px solid ${colors.errorRed}`};
  .form-control {
    border: none !important;
  }
  input { 
    ${commonInputStyle}
    background: ${props => props.filled === 'true' ? props.theme.input.basic : props.theme.input.default} !important;
    color: ${props => props.hasErrors && colors.errorRed} !important;
    box-shadow: ${props => props.hasErrors && 'none'} !important;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .flag-dropdown {
    background-color: transparent !important;
    border: none !important;
    .country-list {
      background: ${props => props.theme.drop.options};
      .country {
        .country-name, .dial-code {
          color: ${props => props.theme.drop.text};
        }
        :hover {
          background: ${colors.green} !important;
        }
      }
      .country.highlight {
        background: ${props => props.theme.drop.options};
      }
    }
    .open {
      background-color: transparent !important;
    }
  }
  .selected-flag {
    :hover, :focus {
      background-color: transparent !important;
    }
    .open {
      background-color: transparent !important;
    }
  }
  li {
    display: flex;
    span {
      margin: 0;
      color: #3e3e3e;
    }
  }
  svg {
    position: absolute;
    z-index: 99;
    left: 14px;
    top: 16.5px;
  }
`

export const phoneNumberField: FieldComponent = ({ input, hasErrors, disabled, preventDefault }) => {
  let phone = input.value ? input.value : undefined

  const handleChange = (value: any, country: any, e: any, formattedValue: any) => {
    phone = formattedValue.replace(/\+/g, '').replace(/\D+/g, '-')
    input.onChange(phone)
  }
  return <PhoneWrapper hasErrors={hasErrors} filled={phone ? 'true' : 'false'}>
    <PhoneInput
      value={phone}
      onChange={handleChange}
      placeholder='Enter phone number'
      data-lpignore="true"
      autoFormat={true}
      country={'us'}
      disabled={disabled}
      onKeyPress={(e: any) => preventDefault(e)}
    />
  </PhoneWrapper>
}

const ProtectedInputWrap = styled.div<any>`
  position: relative;
  svg {
    cursor: pointer;
    position: absolute;
    top: 11px;
    right: ${props => props.lastPass ? '40px' : '13px'};
    path {
      fill: ${props => props.input === 'true' && colors.green};
    }
  }
`

export const protectedInputFieldComponent = (additional: any, type: string, lastPass?: boolean): FieldComponent =>
  ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
    const [notProtected, setNotProtected] = React.useState<boolean>(false)
    return <ProtectedInputWrap lastPass={lastPass} input={input.value ? 'true' : 'false'}>
      <CommonInput
        placeholder={placeholder}
        type={notProtected ? type : 'password'}
        data-lpignore={!lastPass}
        {...input}
        hasErrors={hasErrors}
        {...additional}
        disabled={disabled}
        onKeyPress={(e: any) => preventDefault(e)}
        notEmpty={input.value ? 'true' : 'false'} />
      {!notProtected
        ? <ProtectedGrey onClick={() => !notProtected ? setNotProtected(true) : {}} /> : <NotProtected onClick={() => setNotProtected(false)} />}
    </ProtectedInputWrap>
  }

const AmountInputWrap = styled.div<any>`
  position: relative;
  span {
    cursor: pointer;
    position: absolute;
    top: 11px;
    right: ${props => props.lastPass ? '40px' : '13px'};
    color: ${props => props.hasErrors && colors.errorRed} !important;
  }
`

export const amountInputFieldComponent = (total: number, lastPass?: boolean): FieldComponent =>
  ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
    return <AmountInputWrap
      lastPass={lastPass}
      input={input.value ? 'true' : 'false'}
      onKeyPress={(e: any) => preventDefault(e)}
      hasErrors={hasErrors}>
      {numericInputFieldWithCommas({ input, placeholder, hasErrors, preventDefault, disabled })}
      <span>/{optionalCommaNumber(total || 0)}</span>
    </AmountInputWrap>
  }

const CopyFieldWrap = styled<any>(FlexRow)`
  width: 100%;
  input {
    color: #9CA6AD;
  }
  &:hover {
    div {
      display: flex;
    }
  }
  svg {
    path {
      fill: ${props => props.theme.whiteIcon};
    }
  }
  .arrow-up {
    width: 0; 
    height: 0; 
    left: 42%;
    position: absolute;
    bottom: 5px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;    
    border-bottom: 7px solid ${props => !props.copy ? '#FAFAFA' : '#33AA40'};
  }
  .copyButton {
    position: relative;
    div {
      display: none;
    }
    :hover {
      div {
        display: flex;
      }
    }
  }
`

const HoverElement = styled<any>(CopyHover)`
  max-width: 50px;
  left: 12%;
  bottom: -19px;
  span {
    text-transform: capitalize !important;
  }
`

export const copyField: FieldComponent = ({ input, placeholder, hasErrors, disabled }) => {
  const [copy, setCopy] = React.useState<boolean>(false)
  const handleCopy = () => {
    copyTextToClipboard(input.value)
    setCopy(true)
  }
  return (
    <CopyFieldWrap copy={copy} onMouseLeave={() => setCopy(false)}>
      <CommonInput
        placeholder={placeholder}
        type="text"
        notEmpty={input.value ? 'true' : 'false'}
        data-lpignore="true"
        {...input}
        hasErrors={hasErrors}
        disabled={true} />
      <InputButton className='copyButton'>
        <Copy onClick={() => handleCopy()} />
        <div className='arrow-up' />
        <HoverElement copy={copy}>
          <span>{copy ? 'Copied!' : 'Copy'}</span>
        </HoverElement>
      </InputButton>
    </CopyFieldWrap>
  )
}

const FielWithButtonWrap = styled<any>(FlexRow)`
  width: 100%;
  input {
    border-right: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  svg {
    path {
      fill: ${colors.white};
    }
  }
`

interface FieldWithButtonProps {
  buttonText: string
  onClick: () => void
  removeText?: boolean
  numeric?: boolean
  isValid?: boolean
}

export const fieldWithButton: (props: FieldWithButtonProps) => FieldComponent =
  props => ({ input, placeholder, hasErrors, disabled, preventDefault }) => {
    const [inputValue, setInputValue] = React.useState<any>('')
    const { buttonText, onClick, removeText, numeric, isValid } = props
    const remove = removeText ? inputValue : false
    const inputWithValue = {
      ...input,
      value: inputValue,
    }
    const handleRemove = () => {
      onClick()
      if (remove)
        setInputValue('')
    }
    const handleOnChange = (e: any) => {
      if (numeric) {
        eventValueToFloat(input.onChange(e))
      } else {
        input.onChange(e.target.value)
      }
      setInputValue(e.target.value)
    }

    return <FielWithButtonWrap>
      <CommonInput
        {...inputWithValue}
        placeholder={placeholder}
        type={numeric ? 'number' : 'text'}
        onChange={handleOnChange}
        notEmpty={inputValue ? 'true' : 'false'}
        data-lpignore="true"
        disabled={disabled}
        onKeyPress={(e: any) => preventDefault(e)}
        hasErrors={hasErrors || !isValid}
      />
      <InputButton onClick={handleRemove} hasErrors={hasErrors || remove}>{remove ? 'Remove' : buttonText}</InputButton>
    </FielWithButtonWrap>
  }
