import styled, { css } from 'styled-components'
import { colors, FlexCol, FlexRow, screenSizes, semiBoldStyle, Themes } from '@src/styles'
import { PanelGrid } from '@shared/components/panels/panels'

export const mediumFontStyle = css`
  font-size: 14px;
  line-height: 22px;
`

export const MyReferralsPage = styled<any>(FlexRow)`
  color: ${props => props.theme.white};
  align-items: start;
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
    align-items: center;
  }
`

export const InviteWrap = styled<any>(FlexCol)`
  background: ${({ theme }) => theme.secondaryPanel};
  max-width: 302px;
  border-radius: 4px;
  align-items: center;
  @media (max-width: ${screenSizes.M}px) {
    max-width: 100%;
  }
`

export const InviteSection = styled<any>(FlexCol)`
  padding: 24px 36px;
  padding-bottom: 0;
  align-items: center;
  div {
    width: 100%;
  }
  @media (max-width: ${screenSizes.M}px) {
    padding: 24px 12px;
  }
`

export const CardHeader = styled.span<any>`
  ${semiBoldStyle}
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  @media (max-width: ${screenSizes.M}px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const InviteDescription = styled.span<any>`
  font-size: 14px;
  line-height: 19px;
  margin: 24px 0 48px 0;
  text-align: center;
  .openModal {
    color: ${colors.green};
    :hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media (max-width: ${screenSizes.M}px) {
    margin: 24px 0;
  }
`

export const InviteNowFormWrap = styled<any>(FlexCol)`
  padding: 16px 8px;
  background: ${props => props.theme.selected === Themes.LIGHT ? '#92AABA' : props.theme.button.cancel.default};
  border-radius: 4px;
  width: 100%;
  align-items: center;
  button, input {
    height: 32px !important;
  }
  div {
    margin-bottom: 16px;
  }
  label {
    color: ${colors.white};
  }
`

export const ReferralsWrap = styled<any>(FlexCol)`
  margin-left: 24px;
  width: 100%;
  overflow-x: auto;
  @media (max-width: ${screenSizes.M}px) {
    margin-top: 24px;
    margin-left: 0;
  }
`

export const FlowingGrid = styled<any>(PanelGrid)`
  width: 100%;
  margin-bottom: 0;
  @media (max-width: ${screenSizes.XL}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${screenSizes.L}px) {
    grid-template-columns: 1fr;
  }
`

export const HeaderPanel = styled<any>(FlexRow)`
  padding: 16px 16px 16px 32px;
  background: ${({ theme }) => theme.secondaryPanel};
  border-radius: 4px;
  justify-content: space-between;
  :first-child {
    margin-right: 4px;
  }
  button, a, .transfer {
    height: 32px !important;
  }
`

export const DescriptionSpan = styled.span<any>`
  ${semiBoldStyle}
  ${mediumFontStyle}
  margin: 4px 0 12px 0;
  color: #9CA6AD;
  @media (max-width: ${screenSizes.M}px) {
    font-size: 12px;
  }
`

export const FlowingWrap = styled<any>(FlexCol)`
  width: 100%;
  align-items: flex-start;
`

export const NormalHeader = styled.h5<any>`
  ${semiBoldStyle}
  font-size: 24px;
  line-height: 32px;
  margin: 24px 0;
`