import styled from 'styled-components'
import { colors, screenSizes } from '@src/styles/theme'
import { FlexRow } from '@src/styles'
import LoginBackground from '@image/login-background.png'

export const StyledAuth = styled.div`
background-image: url(${LoginBackground});
  display: flex;
  flex-direction: row;
  flex: 1;
  color: ${props => props.theme.white};
  min-height: 100vh;

  .LoginSignupLink {
    text-align: center;
    a {
      :hover {
        border-bottom: 1px solid ${({ theme }) => theme.accent};
      }
    }
  }
`

export const HeaderLink = styled.span<any>`
  cursor: pointer;
  color: ${props => `${props.theme.accent}`}
`
export const StyledHeader = styled<any>(FlexRow)`
  margin-top: 50px;
  margin-bottom: 20px;
  > div {
    font-family: NunitoSansLight;
  }
  > div:first-of-type {
    margin-left: 0;
  }
`
export const StyledTopHeader = styled.div<any>`
font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
  font-weight: 700;
`

export const StyledAuthForm = styled.div<any>`
  background-color: ${(props) => props.theme.header};
  box-shadow: rgba(0, 0, 0, 0.1) -5px 0 5px 0px;
  margin:auto;
  padding: 32px 24px 32px 24px;
  width : 35%;
  @media (max-width: ${screenSizes.L}px) {
      width: 50%;
    }
  @media (max-width: ${screenSizes.M}px) {
      width: 60%;
    }
    @media (max-width: ${screenSizes.S}px) {
      width: 70%;
    }
    @media (max-width: ${screenSizes.XS}px) {
      width: 100%;
    }
  input {
    color: ${props => props.theme.white};
    background-color: ${props => props.theme.action};
    border-bottom: 0;
  }
  button {
    width: 100%;
    min-width: 200px;
    margin: 20px 0;s
    @media (max-width: ${screenSizes.L}px) {
      width: 100%;
    }
  }
  /* > div {
    max-width: 600px;
  } */
`


