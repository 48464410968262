import { Hash, Uuid } from '@shared/helpers';

export enum DividendAccrual {
  monthly = 1,
  quarterly = 2,
  semiAnnual = 3,
  annual = 4,
}

export enum DividendCategory {
  cash = 1,
  nonCash = 2,
}

export enum DividendCumulation {
  cumulative = 1,
  nonCumulative = 2,
}

export enum DividendInterest {
  simple = 1,
  compound,
}

export enum CapTableView {
  groups,
  holdings,
}

export enum CapTableGrouping {
  member,
  securityType,
}

export const getDividendCategory = {
  [DividendCategory.cash]: 'Cash',
  [DividendCategory.nonCash]: 'Non-Cash',
};

export const getDividendAccrual = {
  [DividendAccrual.monthly]: 'Monthly',
  [DividendAccrual.quarterly]: 'Quarterly',
  [DividendAccrual.semiAnnual]: 'Semi-Annual',
  [DividendAccrual.annual]: 'Annual',
};

export const getDividendCumulation = {
  [DividendCumulation.cumulative]: 'Cumulative',
  [DividendCumulation.nonCumulative]: 'Non-Cumulative',
};

export const getDividendInterest = {
  [DividendInterest.simple]: 'Simple Interest',
  [DividendInterest.compound]: 'Compound Interest',
};

export interface DividendConfig {
  hash: Hash;
  accrual: DividendAccrual;
  interest: DividendInterest;
  cumulation: DividendCumulation;
  category: DividendCategory;
  yield: number;
}

export interface SharePricing {
  hash: Hash;
  parValue: number;
  pricePerShare: number;
  originalIssuePrice: number;
  conversionPrice: number;
  conversionRate: number;
  multipler: number;
  participatingPreferred: boolean;
}

export interface Legend {
  hash: Hash;
  content: string;
}

export interface Hashed {
  hash: Hash
  format: number
}

export interface BlockchainRecord extends Hashed {
  previous?: Uuid
  timestamp: string
}

export interface OperatingAgreementContents {
  organizationDetails: Hash
  plans?: Hash // TODO: Remove nullability in a later migration
  shareholdings?: Hash // TODO: Remove nullability in a later migration
  securityTypesSeniority: Hash
  securityTypesShares: Hash
  transactions?: Hash // TODO: Remove nullability in a later migration
}

export interface OperatingAgreement extends BlockchainRecord, OperatingAgreementContents {
  organization: Uuid
}
