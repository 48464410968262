import * as React from 'react'
import { OrganizationProps, organizationSecuritiesPath, PageProps, setExpanded } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadLegends, loadOrganizationHoldings, loadSecurities } from '@helpers/loaders'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { NewSecurityForm } from '../components/security-form'
import {
  createAuthorization,
  createSecurity,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  NewSecurityTypeRequest,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & GetLegendsResponse & ExpandedHoldingsResponse & StoreProps

const withData = withLoadingCachedMultiple<Props>(loadSecurities(), loadLegends(true), loadOrganizationHoldings('all'))

const NewSecurityComponent = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, legends, holdings, setExpanded, stats } = props
    const { organization } = params
    const initialValues = {
      certifiedShares: false,
      fractionalShares: false,
      votingShares: false,
      participatingPreferred: false,
      seniority: 1,
    }
    const securitiesList = securities ? securities.filter(s => !s.parent) : []

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const sharePricing = {
        parValue: values.parValue || 0,
        pricePerShare: values.pricePerShare || 0,
        originalIssuePrice: values.originalIssuePrice || 0,
        conversionPrice: values.conversionPrice || 0,
        conversionRate: values.conversionRatio || 0,
        multipler: values.multiplier || 0,
        participatingPreferred: values.participatingPreferred
      }
      const dividendConfig = values.issueDividends ? {
        accrual: values.dividendAccrual,
        interest: values.compoundingInterest,
        cumulation: values.dividendCumulation,
        category: values.dividendType,
        yield: values.dividendYield
      } : undefined

      const newSecurityType = {
        certifiedShares: values.certifiedShares,
        dividendConfig,
        filingDate: values.filingDate!,
        fractionalShares: values.fractionalShares,
        incorporationDocument: values.incorporationDocument,
        votingShares: values.votingShares,
        seniority: values.seniority,
        shareClass: values.shareClass,
        shareClassPrefix: values.shareClassPrefix,
        sharePricing,
        shareType: values.shareType,
        legend: values.legend,
        value: values?.value!
      } as NewSecurityTypeRequest

      const newSecurity = await createSecurity(httpClient)({ organization }, newSecurityType)
      if (newSecurity?.security) {
        modifyAuthorizations({ approvalDocuments, target: newSecurity.security.hash!, httpClient, organization })
        setExpanded()
        navigate(organizationSecuritiesPath, { organization })
      }
      else console.log('create Security error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationSecuritiesPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Add New Equity Type" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Create">
          <NewSecurityForm onUpload={onUpload} securities={securitiesList} legends={legends} holdings={holdings} />
        </CommonForm>
      </PageContent>
    )
  }))

export const NewSecurityPage = connect(null, { setExpanded })(NewSecurityComponent)
