import * as React from 'react'
import { AddressType, CurrencyType, GetReferralDataResponse, OrganizationsProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { loadOrganizations, loadProfile, loadReferralsData } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import styled from 'styled-components'
import { CommonForm, Sticky } from '@shared/components/forms'
import { snackBar, TransferCashForm } from '../components'
import arrayMutators from 'final-form-arrays'
import { equaCashTransfer, NewEquaCashRequest } from '@src/service/services/profile'
import { TransferInfo } from '../components/transfer-info'
import { confirmTransferModal } from '../components/modal/confirm-transfer-modal'
import { withPermissions } from '@shared/hocs/with-permissions'
import { PermissionProps } from '@shared/components/permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

export interface CashTransferFormFields {
    to: string
    value: number
    memo: string
}


type Props = PageProps & OrganizationsProps & GetReferralDataResponse & PermissionProps

const withData = withLoadingCachedMultiple<Props>(loadReferralsData, loadOrganizations, loadProfile())

const EquaCashWrap = styled.div<any>`
    
`

export const TransferOrgEquaCash = withPermissions([BuiltInPermission.viewOrganization])(
    withData((props: Props) => {
        const [formValues, setFormValues] = React.useState<CashTransferFormFields | undefined>()
        const { user, organizations, httpClient, params, userDetails, reload } = props
        const balance = parseFloat(userDetails?.equaCash?.toFixed(2)) || 0
        const organization = organizations?.find(org => org.id === params?.organization) || undefined
        const currentOrganizations = organizations ?
            organizations.filter(org => org.creator === user?.id && org.id !== params.organization)?.sort((a, b) => a.name.localeCompare(b.name))
            : []

        const userProfile = props.profile!!
        const selectedOrg = currentOrganizations.find(org => org.id === formValues?.to)?.name
        const onSubmit = async (values?: CashTransferFormFields) => {
            const request: NewEquaCashRequest = {
                to: values?.to!,
                value: values?.value!,
                from: params?.organization!,
                fromType: AddressType.organization,
                toType: AddressType.member,
                currency: 'equacash',
                currencyType: CurrencyType.usd,
                memo: values?.memo!,
            }
            const response = await equaCashTransfer(httpClient)({}, request)
            if (response) {
                verifyTransferModal.setVisible(false)
                snackBackMessage.setShow(true)
            }
            else console.log('transfer error') // TODO: add error message toast
        }
        const handleSubmit = (values: any) => {
            verifyTransferModal.setVisible(true)
            setFormValues(values)
        }

        const verifyTransferModal = confirmTransferModal({
            from: organization?.name!,
            to: selectedOrg || user?.profile.fullName,
            amount: formValues?.value!,
            onSubmit: () => onSubmit(formValues),
            balance,
            forOrg: true
        })

        const snackBackMessage = snackBar({ message: 'Your transfer was successful!', reload })

        const sticky: Sticky<any> = (props: any) => (
            <TransferInfo values={props.values} balance={balance} />
        )

        return (
            <>
                {snackBackMessage.component}
                {verifyTransferModal.component}
                <PageContent>
                    <PageContentHeader
                        title={'Equa Cash Transfer'}
                        hideCrumbs={true}
                        subTitle={'Easily transfer from this org’s Equa Cash balance to your other organizations to spend on their Equa subscription dues.'} />
                    <EquaCashWrap>
                        <CommonForm
                            mutators={{ ...arrayMutators }}
                            onSubmit={handleSubmit}
                            initialValues={{}}
                            submitText={'transfer'}
                            sticky={sticky}>
                            <TransferCashForm
                                organization={organization}
                                balance={balance}
                                organizations={currentOrganizations}
                                user={userProfile} />
                        </CommonForm>
                    </EquaCashWrap>
                </PageContent>
            </>
        )
    })
)
