import { BuiltInPermission } from '@helpers/constants'
import { withOrganizationPermissionsCheck } from '@components/permissions'

export const hasBuiltInPermissions = (requiredPermissions: BuiltInPermission[]) =>
  (userPermissions: BuiltInPermission[]): boolean =>
    requiredPermissions.some(permission => userPermissions.includes(permission))

// Hook to redirect to unauthorized if required permissions aren't displayed
// Right now we are checking only for either or in the required array
export const withPermissions = (required: BuiltInPermission[]) =>
  withOrganizationPermissionsCheck(hasBuiltInPermissions(required))
