import * as React from 'react'
import Rocket from '@image/rocket.svg'
import GradientRocket from '@image/gradient-rocket.svg'
import GreyCross from '@image/close-default.svg'
import WhiteTick from '@image/white-tick.svg'
import ExpandWhite from '@image/table-carrot-down.svg'
import WhiteGlove from '@image/white-glove.svg'
import HelpBack from '@image/help-checklist.svg'
import { FlexCol, FlexRow } from '@src/styles'
import { ChecklistOption } from './types'
import { matchesPath, PathLink, withNavigation } from '../navigation'
import { loadChecklistPermissions, TaskStatus, TaskType, Uuid } from '@shared/helpers'
import {
  captablePath,
  convertibleInstrumentsPath,
  organizationDetailsPath,
  organizationLegendsPath,
  organizationMembersPath,
  organizationPath,
  organizationSecuritiesPath,
  PageProps,
  Paths,
  plansPath,
  rolesPath,
  selectYourPlanPath,
  User,
} from '@logic'
import { withLoadingCachedMultiple } from '../loading'
import { Checklist, FixedWrap, HeaderWrap, ListWrap, NeedHelpOption, RocketWrap, WhiteGloveWrap, Option, OptionWrap } from './style'
import { ServiceProps, withService } from '@shared/hocs/with-service'
import { editUserState, getTasks, getUserState } from '@src/service'
import { StateTransform } from '../../../index'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { checklistModalBundle } from '@modules/organization-dashboard/components/welcome-back-modal/checklist-modal'
import { generatePath } from 'react-router'
import { hasPermission, PermissionProps } from '../permissions'
import { BuiltInPermission } from '@shared/helpers/constants'
import { learnMoreModal } from '@modules/referrals/components'

interface StateProps {
  user?: User
}

interface StoreProps {
  checklist?: any
}

let count = 0

type Props = PageProps & PermissionProps & ServiceProps

export const OnboardChecklistComponent = (props: StateProps & StoreProps) => {
  const { user, checklist } = props

  const withBlockData = withLoadingCachedMultiple<Props>(loadChecklistPermissions(user))

  const RenderChecklist = withNavigation(withService(withBlockData((props: Props) => {
    const [expand, setExpand] = React.useState(false)
    const [taskResponse, setTaskResponse] = React.useState<any>([])
    const [tasks, setTasks] = React.useState<any>([])
    const [userState, setUserState] = React.useState<any>({})
    const org = location?.pathname?.split('/')[2]!
    const { httpClient, permissions } = props

    const welcomeBackModal = checklistModalBundle({
      title: 'Welcome back. Check out our new feature!',
      submitText: 'Ok',
      onSubmit: () => closeModal(),
      onCancel: () => closeModal()
    })

    const closeModal = async () => {
      welcomeBackModal.setVisible(false)
      await editUserState(httpClient)({}, { userState: { ...userState, needsOBCLWelcomeScreen: false } })
    }

    const handleLearnCancel = () => {
      learnModal.setVisible(false)
    }
    const learnModal = learnMoreModal({ onCancel: handleLearnCancel, organization: org })

    const canViewChecklist = permissions &&
      hasPermission(permissions)(BuiltInPermission.editOrganizationDetails) &&
      hasPermission(permissions)(BuiltInPermission.editMembers) &&
      hasPermission(permissions)(BuiltInPermission.viewDocuments) &&
      hasPermission(permissions)(BuiltInPermission.editCapTable)
    const show = matchesPath(organizationPath, window.location.pathname, false) && canViewChecklist

    React.useEffect(() => {
      if (!!user?.id) {
        ; (async () => {
          const stateResponse = await getUserState(httpClient)({})
          if (stateResponse?.userStateRecords?.onboardingVisibility!) {
            const orgs = stateResponse.userStateRecords.onboardingVisibility!
            const close = orgs?.find(o => o === org)
            if (close) {
              setExpand(false)
            }
            else setExpand(true)
            setUserState(stateResponse.userStateRecords)
          } else setExpand(true)

          if (stateResponse?.userStateRecords?.needsOBCLWelcomeScreen && show) welcomeBackModal.setVisible(true)
        })()
      }
      return () => {
        setUserState({})
      }
    }, [!!user, org])

    React.useEffect(() => {
      if (show) {
        ; (async () => {
          const tasksResponse = await getTasks(httpClient)({ entity: org! })
          if (tasksResponse?.tasks && tasksResponse.tasks.length > 0) {
            setTaskResponse(tasksResponse.tasks)
            const allTasks = tasksResponse.tasks.filter(t => t.status !== TaskStatus.completed)
            setTasks(allTasks)
          }
        })()
      }
      return () => {
        setTaskResponse([])
        setTasks([])
      }
    }, [checklist.expanded, org])

    const toggleExpanded = async () => {
      if (expand) {
        const onboardingVisibility = userState?.onboardingVisibility ? userState?.onboardingVisibility.concat(org) : [org]
        const result = await editUserState(httpClient)({}, { userState: { ...userState, onboardingVisibility } })
        if (result) setExpand(false)
      }
      else {
        const onboardingVisibility = userState?.onboardingVisibility?.filter(a => a !== org)
        const result = await editUserState(httpClient)({}, { userState: { ...userState, onboardingVisibility } })
        if (result) setExpand(true)
      }
    }

    const toggleOptionExpanded = (option: ChecklistOption) => {
      if (option.expand) {
        const filteredTasks = tasks?.filter(t => t.type !== option.id)
        setTasks(filteredTasks)
      } else {
        const optionTask = taskResponse?.find(t => t.type === option.id)
        setTasks(tasks.concat(optionTask))
      }
    }
    const renderCard = (list: ChecklistOption[]) => {
      return list.map((l, index: number) => {
        return l.title !== 'Need Help?' ?
          optionItem(l, org, () => toggleOptionExpanded(l), index)
          :
          <NeedHelpOption key={index}>
            <HelpBack />
            <div>
              <span className="title">{l.title}</span> <br />
              <span className="helpInfo">{l.description}{' '}
                <a href="mailto:support@equa.global">Email</a> us
              </span>
            </div>
          </NeedHelpOption>
      })
    }

    const optionItem = (option: any, org: Uuid, setOpened: any, i: number) => {
      const completed = option.completed!
      const opened = option.expand!
      const extended = option.extended!
      const hover = option.id === defaultHover?.id! ? true : false

      return <OptionWrap
        hover={hover}
        onMouseEnter={() => setDefaultHover({})}
        key={i}
        completed={completed}
        opened={opened ? 'true' : 'false'}
      >
        <PathLink path={option.destination} args={{ organization: org }}>
          <Option className={opened && 'opened'} extended={extended} link={option.link ? true : false} completed={completed ? 'true' : 'false'}>
            <WhiteTick />
            <FlexCol className="infoWrap">
              <span className="title">{option.title}</span>
              <span className="info">
                {option.description}
                {option.link && <PathLink
                  path={option.link.to}
                  args={{ organization: org, parent: org && generatePath(option.link.parent!, { organization: org, block: 'current' }) }}
                  className="link">
                  {option.link.name}
                </PathLink>
                }
                {option.link?.textAfter!}
              </span>
            </FlexCol>
          </Option>
        </PathLink>
        <ExpandWhite onClick={setOpened} className="expand" />
      </OptionWrap>
    }

    const basicList: ChecklistOption[] = [
      {
        id: TaskType.completeOrganizationDetails,
        title: 'Complete Organization Details',
        description: 'Finish entering your organization’s information to store in Equa for easy access.',
        extended: true,
        destination: organizationDetailsPath
      },
      {
        id: TaskType.addMember,
        title: 'Add Team Members',
        description: 'Add team members, shareholders, investors, and more to your organization. Assign their ',
        destination: organizationMembersPath,
        link: { name: 'Roles', to: rolesPath, parent: window.location.pathname, textAfter: ' too!' }
      },
      {
        id: TaskType.uploadDataRoomDocument,
        title: 'Upload Organizational Documents',
        description: 'Upload your organization’s essential documents for easy access.',
        extended: true,
        destination: Paths.organizationDataRoom
      },
    ].map(l => {
      const task = taskResponse?.find(t => t.type === l.id)
      const expand = tasks?.find(t => t.type === l.id)
      if (task?.status === TaskStatus.completed) return { ...l, completed: true, expand: expand ? true : false }
      else return { ...l, expand: expand ? true : false }
    })

    const middleList: ChecklistOption[] = [
      {
        id: TaskType.createLegend,
        title: 'Create Legends',
        description: 'Add one or more legends to be used on the back of your certificates.',
        destination: organizationLegendsPath
      },
      {
        id: TaskType.createEquity,
        title: 'Create Equities',
        description: 'Add one or more equities, also known as securities, that will allow you to create share certificates on your cap table.',
        destination: organizationSecuritiesPath
      },
      {
        id: TaskType.createConvertibleInstrument,
        title: 'Create Convertible Instruments',
        description: 'If you have any convertible instruments, like a convertible note or SAFE, add them here to your organization.',
        extended: true,
        destination: convertibleInstrumentsPath
      },
      {
        id: TaskType.createIncentivePlan,
        title: 'Create an Incentive Plan',
        description: 'If you have an incentive plan for your organization, add it to this page here. You can have multiple pools for your holders as well!',
        destination: plansPath
      },
      {
        id: TaskType.createShareholding,
        title: 'Draft a Certificate',
        description: 'Now your cap table is ready for you to draft new share certificates. You can also transfer from this page by upgrading to our ',
        destination: captablePath,
        link: { name: 'Growth Plan.', to: selectYourPlanPath, parent: captablePath }
      },
    ].map(l => {
      const task = taskResponse?.find(t => t.type === l.id)
      const expand = tasks?.find(t => t.type === l.id)
      if (task?.status === TaskStatus.completed) return { ...l, completed: true, expand: expand ? true : false }
      else return { ...l, expand: expand ? true : false }
    })

    const lowerList: ChecklistOption[] = [
      {
        id: TaskType.inviteMember,
        title: 'Invite Team Members',
        description: 'Invite Team Members to join your organization.',
        destination: organizationMembersPath
      },
      {
        title: 'Need Help?',
        description: `Contact us via our ChatBot below or`,
      },
    ].map(l => {
      const task = taskResponse?.find(t => t.type === l.id)
      const expand = tasks?.find(t => t.type === l.id)
      if (task?.status === TaskStatus.completed) return { ...l, completed: true, expand: expand ? true : false }
      else return { ...l, expand: expand ? true : false }
    })

    const tList = basicList.concat(middleList, lowerList)
    const notCompleted = tasks?.filter(l => l.status !== TaskStatus.completed)?.filter(t => tList.some(v => v.id === t.type))
    const list = tList.filter(t => {
      const c = notCompleted?.find(n => n.type === t.id)
      if (c) {
        return t
      }
    })
    const first = list && list.length > 0 ? list[0] : {}
    React.useEffect(() => {
      if (tasks && tasks.length > 0 && count < 1) {
        const notCompleted = tasks?.filter(l => l.status !== TaskStatus.completed)?.filter(t => tList.some(v => v.id === t.type))
        const list = tList.filter(t => {
          const c = notCompleted?.find(n => n.type === t.id)
          if (c) {
            return t
          }
        })
        const first = list && list.length > 0 ? list[0] : {}
        count = 1
        setDefaultHover(first)
      }
      return () => {
        setDefaultHover({})
      }
    }, [tasks])

    const [defaultHover, setDefaultHover] = React.useState()

    return <>
      {welcomeBackModal.component}
      {learnModal.component}
      <RocketWrap onClick={() => toggleExpanded()} className={show ? 'show' : 'hide'}>
        <Rocket className="rock" />
      </RocketWrap>
      <Checklist className={expand && show ? 'show' : 'hide'} onMouseLeave={() => setDefaultHover(first)}>
        <FixedWrap className={expand && show ? 'show' : 'hide'}>
          <HeaderWrap>
            <GradientRocket />
            <span>Let’s Build Your Organization!</span>
            <FlexRow onClick={() => toggleExpanded()}>
              <GreyCross className="cross" />
            </FlexRow>
          </HeaderWrap>
          <ListWrap>
            <h5>With each task you complete, you will earn an Equa Cash Scratch Ticket.&nbsp;
              <span className="openModal" onClick={() => learnModal.setVisible(true)}>Learn more</span></h5>
            {renderCard(basicList)}
            <h5>Nice work on setting up your Organization! Now let’s get into Capitalization.</h5>
            {renderCard(middleList)}
            <h5>Great job so far! Once you’ve completed as many cards as you’d like, the last step is below.</h5>
            {renderCard(lowerList)}
            <h5 style={{ margin: 0, paddingBottom: '72px', minHeight: '-webkit-min-content' }}>We appreciate your business and look forward to working together.</h5>
          </ListWrap>
          <WhiteGloveWrap>
            <WhiteGlove />
            <FlexCol>
              <span>Busy? We can build this for you!</span>
              <Link to={{ pathname: 'https://drift.me/sten1/meeting' }} target="_blank">
                <button>
                  White-glove service
                </button>
              </Link>
            </FlexCol>
          </WhiteGloveWrap>
        </FixedWrap>
      </Checklist>
    </>
  })))

  return <RenderChecklist />
}

const mapStateToProps: StateTransform<StoreProps> = s => ({
  checklist: s.checklist
})

export const OnboardChecklist = connect(mapStateToProps, {})(OnboardChecklistComponent)
