import * as React from 'react'
import { PageContent } from '@components/pages'
import { PageProps, ShareholdingProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageContentHeader } from '@components/pages/page-content-header'
import { LegendView } from '../components/legend-view'

type Props = PageProps & ShareholdingProps

const withData = withLoadingCachedMultiple<Props>()

export const CertificateLegendPage = withData((props: Props) => {
  const { organization, shareholding } = props.params
  return (
    <PageContent>
      <PageContentHeader title={'Legend'}>
      </PageContentHeader>
      <LegendView shareholding={shareholding} />
    </PageContent>
  )
})
