import * as React from 'react'
import { MembersProps, organizationMembersPath, PageProps, setExpanded } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers, loadRoles } from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { MemberForm } from '@modules/team-members/components/member-form'
import { EditableMemberFields, InitialMemberFormValues } from '@modules/team-members/types'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions } from '@components/permissions'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { createAddress, GetRolesResponse } from '@src/service'
import { FlexRow } from '@src/styles'
import { NewAddressRequest1 } from '@src/service/services/organizations/requests'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & MembersProps & StoreProps & GetRolesResponse

export const MemberWrap = styled<any>(FlexRow)`
  justify-content: center;
  a {
    :hover {
      text-decoration: underline;
    }
  }
`

const withData = withLoadingCachedMultiple<Props>(loadMembers, loadPermissions, loadRoles)

const NewMemberComponent = withData(
  withPermissions([BuiltInPermission.editMembers])((props: Props) => {
    const { navigate, params, serviceOld, setExpanded, roles, httpClient } = props
    const initialValues: InitialMemberFormValues = {
      roles: [],
      types: {
        individual: true,
        organization: false
      }
    }
    const { organization } = params

    const onSubmit = async (values: EditableMemberFields) => {
      const addresses = values?.address?.filter((a: any) => !a.isRemoved).map((address: any) => {
        const newAddress = { ...address }
        delete newAddress.key
        return newAddress
      })
      const memberRequest = {
        fullName: values?.fullName!,
        email: values?.email || undefined,
        title: values?.title!,
        roles: values?.roles!,
        phone: values?.phone!,
        user: values?.user!,
        dateOfBirth: values?.dateOfBirth!,
        isIndividual: values.types.individual ? true : false
      }

      const response = await serviceOld.createMember(organization, memberRequest)
      if (response?.id) {
        const memberId = response.id
        if (addresses && addresses.length > 0) {
          addresses.forEach(async (address: any) => {
            const addRequest = {
              ...address,
              groups: address?.groups! ? address.groups : []
            } as NewAddressRequest1
            await createAddress(httpClient)({ entity: memberId }, addRequest)
          })
        }
        setExpanded()
        navigate(organizationMembersPath, { organization })
      }
    }

    const parent: BaseLinkProps = { path: organizationMembersPath, args: { organization } }
    return (
      <MemberWrap>
        <PageContent>
          <PageContentHeader title="Add Member" parent={parent} />
          <CommonForm onSubmit={onSubmit} initialValues={initialValues} submitText="Save Member" mutators={{ ...arrayMutators }}>
            <MemberForm organization={organization} roles={roles} />
          </CommonForm>
        </PageContent>
      </MemberWrap>
    )
  })
)

export const NewMemberPage = connect(null, { setExpanded })(NewMemberComponent)