import * as React from 'react'
import { ChangeEventHandler } from 'react'
import styled from 'styled-components'
import { MultiBox } from '@components/multibox'

interface Props {
  checked: boolean
  noLabel?: boolean
  onChange: ChangeEventHandler<HTMLInputElement>
}

const StyledMultiBox = styled(MultiBox)`
  flex-direction: row;
`

export const Checkbox = (props: Props) => {
  return <StyledMultiBox inverted={true} name="" type="checkbox" checked={props.checked} onChange={props.onChange} noLabel={props?.noLabel!} />
}
