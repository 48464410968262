import * as React from 'react'
import { Loading } from '@components/loading'
import styled from 'styled-components'
import { Uuid } from '@helpers/util'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import { getPageWidth } from './certificate-view'

interface Props {
  shareholding: Uuid
}

declare var API_URL: string

export const Pdf = styled(Page)`
  max-width: 100%;
`

export const PdfDocument = styled(Document)`
  canvas {
    max-width: 100%;
    max-height: 772px;
  }
  .react-pdf__Page__textContent{
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    cursor: inherit;
  }
  @media (max-width: 500px) {
    canvas {
      max-height: 300px;
    }
  }
`

export const LegendView = (props: Props) => {
  const { shareholding } = props
  const [numPages, setNumPages] = React.useState(null);
  const [width, setWidth] = React.useState<number>(0)

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  }
  const setPageWidth = () => {
    const pageWidth = getPageWidth()
    setWidth(pageWidth)
  }
  const fileUrl = API_URL + `/shareholding/${shareholding}/certificate/?page=2`
  window.onresize = function () {
    setPageWidth()
  }

  React.useEffect(() => {
    setPageWidth()
  }, [])

  return (
    <>
      <PdfDocument
        file={{
          url: fileUrl,
          withCredentials: true
        }}
        loading={<Loading />}
        onLoadSuccess={onDocumentLoadSuccess}
        renderMode='canvas' >
        {numPages !== null && Array.from(
          new Array(numPages),
          (el, index) => (
            <Pdf
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              loading={'Loading document'}
              width={width}
            />
          ),
        )}
      </PdfDocument>
    </>
  )
}
