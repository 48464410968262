import * as React from 'react'
import { FlexCol, FlexRow, semiBoldStyle } from '@src/styles'
import { Navigate, PathLink } from '@shared/components/navigation'
import { Panel } from '@shared/components/panels/panels'
import styled from 'styled-components'
import { Uuid } from '@shared/helpers'
import { StyledLink } from '@modules/captable/pages'

export interface InfoPanelProps {
    label: string
    value: string | number
    link?: string
    document?: string
    args?: any
}

export interface EventPanelProps {
    event: string
    date: string
    amount: string | number
}

interface Props {
    title: string
    data: InfoPanelProps[] | any
    navigate: Navigate
    params: { [key: string]: string }
    max?: string
    height?: string
    forEvents?: boolean
}

export const TitleRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px;
    color: ${({ theme }) => theme.white};
    margin-bottom: 16px;
    h5 {
        margin: 0;
        ${semiBoldStyle}
        font-size: 24px;
        line-height: 32px;
    }
`

export const StyledInfo = styled<any>(FlexRow)`
    width: 100%;
    padding: 12px;
    justify-content: space-between;
    color: ${({ theme }) => theme.white};
    span {
        line-height: 24px;
    }
    .left {
        text-align: left;
    }
    .right {
        text-align: right;
    }
    a {
        :hover {
            text-decoration: underline;
        }
    }
`

const DataWrap = styled<any>(FlexCol)`
    overflow-y: auto;
    height: ${props => props.overflow === 'true' ? '236px' : 'auto'};
    display: ${props => props.overflow === 'true' ? 'block' : 'flex'};
    padding: ${props => props.overflow === 'true' && '8px'};
    a {
        :hover {
            text-decoration: underline;
          }
    }
`

const EventRow = styled.div<any>`
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 16px 0;
    border-bottom: 1px solid rgba(206, 218, 225, 0.3);
    :first-child {
        padding-top: 0;
    }
    :last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
    span {
        text-align: left;
    }
    .label {
        color: #9CA6AD;
        line-height: 24px;
    }
    .value {
        ${semiBoldStyle}
        font-size: 20px;
        line-height: 28px;
    }
`

const CustomPanel = styled<any>(Panel)`

`

export const ViewInfoPanel = (props: Props) => {
    const { title, data, params, max, forEvents } = props

    const openDocument = (doc?: Uuid, name?: string) => {
        if (doc && name) {
            const n = encodeURIComponent(name)
            window.open(`/api/v1/file/${doc}/content/${n}`)
        }
    }

    return (
        <CustomPanel max={max || '575px'}>
            <TitleRow>
                <h5>{title}</h5>
            </TitleRow>
            <DataWrap overflow={forEvents ? 'true' : 'false'}>
                {data.map((d: any) => !forEvents ? (
                    <StyledInfo>
                        <span className='left'>{d.label}</span>
                        {(d.link && d.value !== 'N/A') ?
                            <PathLink path={d.link} args={d.args} target={'_blank'} className='right'>{d.value}</PathLink>
                            :
                            d.document ?
                                <StyledLink
                                    onClick={() => openDocument(d?.document!, d?.filename!)}
                                    style={{ width: 'auto', textAlign: 'right' }}>
                                    {d?.value!}
                                </StyledLink>
                                :
                                <span className='right'>{d.value}</span>
                        }
                    </StyledInfo>
                )
                    : (
                        <EventRow>
                            <span className='label'>Event</span>
                            <span className='label'>Date</span>
                            <span className='label'>Amount</span>
                            <span className='value'>{d.event}</span>
                            <span className='value'>{d.date}</span>
                            <span className='value'>{d.amount}</span>
                        </EventRow>
                    )
                )}
            </DataWrap>
        </CustomPanel>
    )
}
