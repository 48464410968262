import * as React from 'react'
import styled from 'styled-components'
import { colors, FlexCol, FlexRow, Themes } from '@src/styles'
import DownArrow from '@image/carrot-down.svg'
import { matchesPath, NavigationProps, PathLink, withNavigation } from '@shared/components/navigation'
import { ServiceProps } from '@shared/hocs/with-service'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { formatImageUrl, loadOrganizations, resetStateCount, userStateCount } from '@shared/helpers'
import { Organization, organizationListPath, organizationPath, OrganizationsProps, PageProps, Paths, User } from '@logic'
import Building from '@image/building.svg'
import SmallBuilding from '@image/building-16x16.svg'
import { Avatar } from '@shared/components/avatar'
import { DropBorder, DropFooter } from '../style'
import { useClose } from '../utility'
import Plus from '@image/plus-10x10.svg'
import { getOrganization, getUserState } from '@src/service'

const DockWrapper = styled<any>(FlexRow)`
    min-width: ${props => props.styleChange === 'true' ? '200px' : '320px'};
    height: 40px;
    justify-content: space-between;
    position: relative;
    padding: 4px 12px;
    cursor: pointer;
    border-radius: 4px;
    color: ${props => props.theme.listText};
    :hover {
        background: ${({ theme }) => theme.dock.hover};
        border: 1px solid ${colors.green};
    }
    img, svg {
        margin-right: 8px;
    }
    svg {
        :hover {
            path {
                fill: ${colors.green};
            }
        }
    }
    div {
        display: flex;
    }
`

const CustomImage = styled.div<any>`
    width: 38px;
    height: 38px;
    margin-right: 10px;
    border-radius: 19px;
    justify-content: center;
    align-items: center;
    border: ${props => props.selected === 'true' ? `2px solid ${colors.green}` : 'none'};
    img {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        margin: 0;
    }
`

const TitleBox = styled.div<any>`
    margin-left: ${props => props.inProfile ? '40px' : 0};
`

const OrganizationsDock = styled<any>(FlexCol)`
    position: absolute;
    top: 49px;
    left: 0;
    width: 320px;
    background: ${({ theme }) => theme.header};
    align-items: flex-start;
    padding: 8px 0px;
    a {
        width: 100%;
    }
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                    0px 3px 14px rgba(0, 0, 0, 0.05),
                    0px 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    .footerLink {
        :hover {
          span {
            color: ${colors.green};
          }
          path {
            fill: ${colors.green};
          }
        }
    }
`

const OrgRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px 16px;
    cursor: ${props => props.selected === 'true' && 'default'};
    :hover {
        background: ${props => props.selected === 'true' ? 'transparent' : colors.green};
        span {
            color: ${props => props.theme.selected === Themes.LIGHT && props.selected === 'false' && colors.white};
        }
    }
    span {
        font-size: 16px;
        line-height: 24px;
        color: ${props => props.selected === 'true' ? colors.green : props.theme.listText};
    }
`

const PlusWrap = styled<any>(FlexRow)`
  background: #283840;
  border: 3px solid ${props => props.theme.accent};
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  justify-content: center;
  margin-right: 10px;
  svg {
    margin-right: 0;
    path {
      fill: ${colors.green};
    }
  }
`

interface Props {
    inOrgsList: boolean
    inProfile: boolean
    user?: User
    styleChange: boolean
}

type DockProps = NavigationProps & ServiceProps & OrganizationsProps & PageProps & Props

const withData = withLoadingCachedMultiple<any>(loadOrganizations)

export const NavigationDock = withData((props: DockProps) => {
    const {
        inOrgsList,
        inProfile,
        user,
        styleChange,
        organizations,
        httpClient,
        params } = props
    const [open, setOpen] = React.useState<boolean>(false)
    const [userStateRecords, setUserStateRecords] = React.useState<any>({})
    const [currentOrganization, setCurrentOrganization] = React.useState<Organization | undefined>()

    const inCreateOrganization = matchesPath(Paths.newOrganization, window.location.pathname, true)
    const inOrganizations = !inProfile && !inOrgsList && !inCreateOrganization
    const userState = userStateRecords!!
    const organization = currentOrganization!!
    const recentOrganizations = userState?.recentOrganizations!!
    const dockOrganizations = organizations && organizations.length > 0 ? organizations.map(o => {
        if (recentOrganizations) {
            const value = recentOrganizations.find((r: any) => r.id === o.id)
            if (value) return { ...o, visited: value.visited }
            else return o
        }
        return o
    }).filter(org => recentOrganizations && recentOrganizations.some((o: any) => o.id === org.id)) : []

    let restOrganizations: Organization[] = []
    if (recentOrganizations) {
        if (dockOrganizations.length < 5) {
            const values = organizations.filter(org => {
                const found = recentOrganizations.find((o: any) => o.id === org.id)
                if (!found) return org
            })
            restOrganizations = values.slice(0, 5 - dockOrganizations.length)
        }
    }
    else if (organizations) {
        restOrganizations = organizations.slice(0, 5)
    }

    const list = dockOrganizations.sort((a: any, b: any) =>
        a.visited && b.visited && new Date(b.visited)?.getTime() - new Date(a.visited)?.getTime()
    ).splice(0, 5).concat(restOrganizations)

    const ref = React.useRef() as any
    useClose(open, setOpen, ref)
    const getName = (name: string) => {
        if (styleChange) {
            if (name.length > 10) return name.slice(0, 10) + '...'
            else return name
        }
        else {
            if (name.length > 18) return name.slice(0, 18) + '...'
            else return name
        }
    }

    React.useEffect(() => {
        if (!!user?.id) {
            ; (async () => {
                const stateResponse: any = await getUserState(httpClient)({})
                if (stateResponse?.userStateRecords) {
                    setUserStateRecords(stateResponse.userStateRecords)
                }
            })()
        }
        return () => {
            setUserStateRecords({})
            resetStateCount()
        }
    }, [!!user, userStateCount])

    React.useEffect(() => {
        if (params.organization && !!user) {
            ; (async () => {
                const orgResponse = await getOrganization(httpClient)({ organization: params.organization })
                if (orgResponse?.organization) {
                    setCurrentOrganization(orgResponse.organization)
                }
            })()
        }
        return () => {
            setCurrentOrganization(undefined)
        }
    }, [params.organization])

    return <DockWrapper onClick={() => setOpen(!open)} ref={ref} styleChange={styleChange ? 'true' : ' false'}>
        <FlexRow alignItems={'center'}>
            {inOrganizations && organization?.name ?
                <Avatar size="32px" fullName={organization.name || ''} image={organization.logo?.hash} />
                : null
            }
            {(inOrgsList || inCreateOrganization) && <Building className='building' />}
            <TitleBox inProfile={inProfile}>
                {(inOrgsList || inCreateOrganization) ?
                    'All Orgs' : inProfile ?
                        user?.profile.fullName && getName(user.profile.fullName)
                        :
                        organization?.name && getName(organization.name)
                }
            </TitleBox>
        </FlexRow>
        <DownArrow />
        {open && Dock(list, organizations, organization)}
    </DockWrapper>
})

const Dock = (orgs: Organization[], orgResponse: Organization[], org?: Organization) => {
    return <OrganizationsDock>
        {orgResponse && orgResponse.length > 0 ? orgs.map(o => {
            const image = o.logo && o.logo.hash
            return <PathLink path={organizationPath} args={{ organization: o.id }}>
                <OrgRow selected={org?.id === o.id ? 'true' : 'false'}>
                    <CustomImage selected={org?.id === o.id ? 'true' : 'false'}>
                        {image ? <img src={formatImageUrl(image)} alt='' />
                            :
                            <Avatar size="36px" fullName={o.name} image={image} />
                        }
                    </CustomImage>
                    <span>{o.name}</span>
                </OrgRow>
            </PathLink>
        }) :
            <PathLink path={Paths.newOrganization} args={{}}>
                <OrgRow>
                    <PlusWrap>
                        <Plus />
                    </PlusWrap>
                    <span>Create New Organization</span>
                </OrgRow>
            </PathLink>
        }
        <DropBorder />
        <PathLink path={organizationListPath} args={{}} className='footerLink'>
            <DropFooter>
                <SmallBuilding />
                <span>View All Orgs</span>
            </DropFooter>
        </PathLink>
    </OrganizationsDock>
}
