import { MutableRefObject, useEffect } from 'react'

interface Props {
  handleClose: any
  node: MutableRefObject<null>
  dependencies?: any
}

// Hook to close on clicking outside of Ref
export const useClickOutside = (props: Props) => {
  const { handleClose, node, dependencies = true } = props
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      // @ts-ignore
      if (node.current && !node.current.contains(e.target)) {
        setTimeout(() => {
          handleClose()
        }, 100)
      }
    }
    if (dependencies) {
      window.addEventListener('click', handleOutsideClick, true)
    }
    return () => window.removeEventListener('click', handleOutsideClick, true)
  }, [dependencies])
}
