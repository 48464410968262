import * as React from 'react'
import styled from 'styled-components'
import { gapSizes, colors, fontSizes } from '@src/styles/theme'
import Tick from '@image/table-tick.svg'
import Cross from '@image/close-default.svg'
import { PathLinkButton } from '@shared/components/button'
import { GetSubscriptionsResponse, checkoutPath } from '@logic'
import { SelectedButton, StyledPathLink, TalkToExpertLink } from '../pages'

interface Props {
    organization: string
    subscriptions: GetSubscriptionsResponse
}

enum Plans {
    start = 0,
    growth = 1,
    scale = 2,
}

const rowHeight = '60px'
const StyledTable = styled.div<any>`
  transition: all 300ms ease-in;
  overflow-x: auto;
  padding-bottom: 20px;
  border-radius: 8px;
  margin-top: 13px;
  table {
    background: ${props => props.theme.secondary};
    color: ${props => props.theme.white};
    width: 100%;
    border-collapse: collapse;
    border: 1px solid transparent;
    border-spacing: 0;
    padding-right: 20px;
    thead {
      text-transform: uppercase;
    }
    tr {
      > *:nth-child(1) {
        text-align: start;
      }
    }
    th,
    td {
      text-align: center;
      padding: 0 ${gapSizes.M};
      span {
        color: ${props => props.theme.accent};
        font-weight: 800;
        font-family: 'NunitoSansBold';
        text-transform: uppercase;
      }
    }
    td:nth-child(1) {
        border-radius: ${props => props.isOdd ? '' : '8px 0 0 8px'};
    }
    td:last-child {
        border-radius: ${props => props.isOdd ? '' : '0 8px 8px 0'};
    }
    }
  }
`

export const TableRow = styled.tr<any>`
  height: ${rowHeight};
  background: ${props => props.isOdd ? '' : props.theme.grayDivider};
  svg {
      path {
          fill: ${props => props.includes && colors.green};
      }
  }
`

export const SelectPlanWrap = styled.tr<any>`
  height: ${rowHeight};
  background: ${props => props.theme.grayDivider};
  font-size: ${fontSizes.M};
  td:first-child {
    padding-left: 22px !important;
  }
  td {
    padding: 22px 0 23px 0 !important;
  }
`

export function FeaturesTable(props: Props) {
    const { subscriptions, organization } = props
    const hasSubscription = subscriptions?.length > 0
    const headers = ['Feature', 'Start plan', 'Growth plan', 'Scale plan']
    const rows = [
        { name: 'ORM Platform', for: [0, 1, 2] },
        { name: 'Data Rooms', for: [0, 1, 2] },
        { name: 'Auto Updates to Cap Table', for: [0, 1, 2] },
        { name: 'Electronic Securities', for: [0, 1, 2] },
        { name: 'Option Exercising', for: [0, 1, 2] },
        { name: 'Member Management', for: [0, 1, 2] },
        { name: 'Equity Incentive Plans', for: [0, 1, 2] },
        { name: 'Incentive Plans', for: [0, 1, 2] },
        { name: 'Transfers', for: [1, 2] },
        { name: 'Repurchases', for: [1, 2] },
        { name: 'Actions Page' },
        { name: '409A Valuations' },
        { name: '409A Materials Refresh' },
        { name: 'Blockchain & Token Securities' },
        { name: 'Investor Issuance Portal Page' },
        { name: 'Legal Entity Formation' },
    ]

    return (
        <StyledTable>
            <table>
                <thead>
                    <TableRow isOdd={true}>
                        {headers.map((column, i: number) => {
                            return (
                                <th key={i}>
                                    {column}
                                </th>
                            )
                        })}
                    </TableRow>
                </thead>
                <tbody>
                    {rows.map((cell, i) => {
                        return (
                            <TableRow key={i} isOdd={Boolean(i % 2)} includes={cell.for?.includes(Plans.start) ? 'true' : 'false'}>
                                <td>
                                    {cell.name}
                                </td>
                                <td>
                                    {cell.for ?
                                        cell.for.includes(Plans.start) ? <Tick /> : <Cross />
                                        :
                                        <span>Add On</span>}
                                </td>
                                <td>
                                    {cell.for ?
                                        cell.for.includes(Plans.growth) ? <Tick /> : <Cross />
                                        :
                                        <span>Add On</span>}
                                </td>
                                <td>
                                    {cell.for ?
                                        cell.for.includes(Plans.scale) ? <Tick /> : <Cross />
                                        :
                                        <span>Add On</span>}
                                </td>
                            </TableRow>
                        )
                    })}
                    <SelectPlanWrap>
                        <td>Select A Plan:</td>
                        <td>
                            {hasSubscription ?
                                TalkToExpertLink() :
                                <SelectedButton disabled={true}>Your Current Plan</SelectedButton>
                            }
                        </td>
                        <td>
                            {!hasSubscription ?
                                <StyledPathLink path={checkoutPath} args={{ organization }}>Select Growth Plan</StyledPathLink> :
                                <SelectedButton disabled={true}>Your Current Plan</SelectedButton>
                            }
                        </td>
                        <td>
                            {TalkToExpertLink()}
                        </td>
                    </SelectPlanWrap>
                </tbody>
            </table>
        </StyledTable>
    )
}
