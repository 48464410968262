import * as React from 'react'
import {
  FieldGroup,
  renderFieldsSimple,
  selectFieldComponent,
  textInputFieldComponent,
  renderHeader,
  UploadHandler,
  amountInputFieldComponent,
} from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers/field-validators'
import { FormPool, ExpandedSecurity, NestedPlan } from '@src/service'
import { composeValidators, isGreaterThanZero, isLessOrEqual } from '@shared/helpers'
import { FlexCol } from '@src/styles'
import styled from 'styled-components'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'
import { formatToNumber } from '@modules/captable/utility'
import { NoteSpan, QuestionSpan } from './plan-form'
import { filterIncentives } from '../logic'

interface Props {
  securities: ExpandedSecurity[]
  values?: FormPool
  plan: NestedPlan
  onUpload: UploadHandler
  edit?: boolean
}

export const StyledHeaderRow = styled<any>(FlexCol)`
  width: 100%;
  align-items: start;
  h2 {
    margin-bottom: 0 !important;
  }
`

export const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 15px;
`

export const StyledDoc = styled<any>(FlexCol)`
  width: 100%;
  align-items: flex-end !important;
  .headerWrap: first-child {
    width: 100%;
    align-items: baseline;
  }
`

function poolFields(securities: ExpandedSecurity[], values: any, plan: NestedPlan): FieldGroup<FormPool> {
  const incentives = filterIncentives(securities, plan)
  const incentiveOptions = incentives?.map(i =>
    ({ label: `${i.name || ''} (${i.shareClassPrefix})`, value: i.hash })
  )
  const selectedIncentive = incentives?.find(i => i.hash === values?.incentive)
  const equityTreasury = securities?.find(i => i.hash === selectedIncentive?.parent)?.stats.treasury
  return [
    {
      name: 'name',
      label: 'Pool Name',
      required: true,
      validate: emptyValidator,
      placeholder: 'Enter pool name',
      component: textInputFieldComponent,
    },
    {
      name: 'incentive',
      label: 'Incentive',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(incentiveOptions, 'Incentive Name'),
    },
    {
      name: 'shares',
      label: 'Allocated Amount',
      required: true,
      format: formatToNumber,
      validate: composeValidators([
        isLessOrEqual(equityTreasury, 'Must be less or equal to equity treasury'),
        isGreaterThanZero
      ]),
      placeholder: 'Enter allocated shares',
      component: amountInputFieldComponent(equityTreasury || 0, false),
    },
  ]
}

export const PoolForm = (props: Props) => {
  const { values, onUpload, securities, plan } = props

  return (
    <>
      <QuestionSpan>What is a pool?</QuestionSpan>
      <NoteSpan>A pool consists of assets, usually shares of stock, reserved for holders of a private company.
        Enter the details of your pool here.
        All incentives attached to this pool will share these details.
      </NoteSpan>
      {renderHeader({ label: 'Pool Details' })}
      {renderFieldsSimple(poolFields(securities, values, plan))}
      <DocumentsAndNotes onUpload={onUpload} values={values} noInitial={true} />
    </>
  )
}
