import * as React from 'react'
import { Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { dollarStringFromCents, formatExtendedDateString } from '@shared/helpers'
import styled from 'styled-components'
import Visa from '@image/visa.svg'
import MasterCard from '@image/master-card.svg'
import AmericanExpress from '@image/american-express.svg'
import Discover from '@image/discover.svg'
import { colors } from '@src/styles'

interface Props {
    billing: any
}

const StatusWrapper = styled.div<any>`
    color: ${props => props.status === 'Pending' ? colors.warning : colors.green};
    svg {
        height: 40px;
    }
`

export const getCard = (num: string) => {
    if (num.startsWith('4')) return <Visa />
    else if (num.startsWith('5')) return <MasterCard />
    else if (num.startsWith('37') || num.startsWith('34')) return <AmericanExpress />
    else if (num.startsWith('6')) return <Discover />
}

export const BillingHistory = (props: Props) => {
    const { billing } = props
    const tableData = billing && billing.length > 0 ? billing : []

    const columns = [
        {
            Header: 'Date',
            accessor: 'date',
            Cell: ({ row }: CellProps<any>) => <div>{formatExtendedDateString(row.original.transaction.created_at)}</div>,
            className: 'left'
        },
        {
            Header: 'Amount',
            accessor: 'amount',
            Cell: ({ row }: CellProps<any>) => <div>{dollarStringFromCents(row.original.transaction.amount_in_cents)}</div>,
            className: 'right'
        },
        {
            Header: 'Description',
            accessor: 'description',
            Cell: ({ row }: CellProps<any>) => <div>{row.original.transaction.memo}</div>
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: ({ row }: CellProps<any>) => <StatusWrapper status={row.original.transaction.success}> {row.original.transaction.success ? 'Completed' : 'Pending'} </StatusWrapper>
        },
    ]

    return <Table
        data={tableData}
        columns={columns}
        fixed={false}
        scrollingTable={true}
        withAllBorders={true}
        noFooter={true}
        pageSize={10} />
}
