import styled, { css } from 'styled-components'
import React, { useEffect, useRef, useState } from 'react'
import { crossWithHoverCss, FlexCol, FlexRow } from '@src/styles'
import { colors } from '@src/styles/theme'
import { HeaderLink, headerLinkCss } from '@src/app/components/header/style'
import { getPathArray } from '@helpers/util'
import { useClickOutside } from '@shared/hooks/useClickOutside'
import { matchesPath, NavigationProps, withNavigation } from '@components/navigation/navigation'
import { HeaderLinkProps } from './header-links'
import HamburgerIcon from '@image/hamburger-mobile.svg'
import DownArrow from '@image/carrot-down.svg'
import RightArrow from '@image/carrot-right.svg'
import { certificateApprovalsPath, certificateVestingSchedulePath, Paths } from '@logic'
import { rgba } from 'polished'
import { SmallBetaNotify } from './shared/profile-drop'

const optionCss = css<any>`
  margin-left: 0 !important;
  width: 100%;
  padding: 16px 8px 16px 16px;
  color: ${props => props.selected ? colors.green : props.disabled ? rgba(191, 191, 191, 0.4) : colors.white};
  height: auto;
  :hover {
    background: ${props => props.selected && 'transparent'};
  }
`

const StyledHamburgerMenu = styled<any>(FlexRow)`
  display: flex;
  padding: 4px;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background: ${props => props.open === 'true' && props.theme.buttonHover};
  path {
    fill: ${props => props.open === 'true' && colors.white};
  }
  ${crossWithHoverCss}
`

const SubHeaderLink = styled<any>(HeaderLink)`
  ${optionCss}
`

const NestedOption = styled<any>(FlexRow)`
  ${headerLinkCss}
  ${optionCss}
  :hover {
    background: ${props => props.expanded === 'true' && 'transparent'};
  }
`

const HamburgerDock = styled<any>(FlexCol)`
  background: ${({ theme }) => theme.header};
  position: absolute;
  overflow-y: scroll;
  width: 241px;
  height: 394px;
  top: 56px;
  right: 0;
  padding: 8px 0px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08), 0px 3px 14px rgba(0, 0, 0, 0.05), 0px 5px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`

const SubMenuOption = styled<any>(SubHeaderLink)`
  padding: 16px;
`

interface Props {
  isOpen: boolean
  setOpen: (toggle: boolean) => void
}

export const HamburgerMenu = withNavigation(
  ({ setOpen, isOpen, links, noPath, location, params }: { links: HeaderLinkProps[]; noPath: (e: MouseEvent) => void } & Props & NavigationProps) => {
    const [subIsOpen, setSubIsOpen] = useState<string>('')
    const node = useRef(null)
    const disabledPaths = [
      Paths.governingDocuments,
      certificateVestingSchedulePath,
      certificateApprovalsPath,
    ]

    useClickOutside({ node, handleClose: () => setOpen(false) })
    const RenderOptions = (link: HeaderLinkProps, subLinks?: HeaderLinkProps[]) => {
      const expanded = subIsOpen === link.title
      return <FlexCol alignItems={'flex-start'} style={{ width: '100%' }}>
        <FlexRow style={{ marginBottom: expanded ? '10px' : 0 }}>
          <span className='headerTitle'>{link.title}</span>
          {link.beta ? <SmallBetaNotify>Beta</SmallBetaNotify> : null}
          {subLinks && subLinks.length > 0 ?
            expanded ? <DownArrow className='down' />
              : <RightArrow className='down' /> : null
          }
        </FlexRow>
        <FlexCol style={{ display: expanded ? 'flex' : 'none' }}>
          {subLinks && subLinks.length > 0 && subLinks.map((subLink, i) => {
            return <SubMenuOption
              key={i}
              path={subLink.defaultUrl || subLink.path}
              selected={matchesPath(subLink.path, window.location.pathname, subLink.exact || false)}
              args={params}
            >
              <FlexRow>
                <span className='headerTitle'>{subLink.title}</span>
              </FlexRow>
            </SubMenuOption>
          })}
        </FlexCol>
      </FlexCol>
    }

    const handleNested = (link: HeaderLinkProps, current: string) => {
      if (subIsOpen == '' || current !== subIsOpen)
        setSubIsOpen(link.title)
      else
        setSubIsOpen('')
    }

    return (
      <>
        <StyledHamburgerMenu onClick={() => setOpen(!isOpen)} open={isOpen ? 'true' : 'false'}>
          <HamburgerIcon />
        </StyledHamburgerMenu>
        {isOpen &&
          <HamburgerDock ref={node}>
            {links.map((link, i) => {
              const subLinks = link.subLinks
              const disabled = disabledPaths.indexOf(link.path) !== -1
              const expanded = subIsOpen === link.title
              return subLinks ?
                <NestedOption
                  key={i}
                  selected={matchesPath(link.path, window.location.pathname, link.exact || false)}
                  onClick={() => handleNested(link, link.title)}
                  disabled={disabled}
                  expanded={expanded ? 'true' : 'false'}
                >
                  {RenderOptions(link, subLinks)}
                </NestedOption>
                :
                <SubHeaderLink
                  key={i}
                  path={link.defaultUrl || link.path}
                  selected={matchesPath(link.path, window.location.pathname, link.exact || false)}
                  args={params}
                  disabled={disabled}
                >
                  {RenderOptions(link)}
                </SubHeaderLink>
            })}
          </HamburgerDock>
        }
      </>
    )
  }
)
