import { Hash, Uuid } from "@helpers/util";

export enum ShareType {
  common = 1,
  preferred = 2,
  units = 3,
  convertibleNotes = 4,
}

export enum ShareTypeUuid {
  commonStock = 'cefd46d7-82f0-44a2-a36b-24673a86d564',
  preferred = '536995e1-11d2-4034-8c3f-3e5ed9d75ae9',
  units = '3d62fd7f-f103-4b0f-ae58-d589b5f748e8',
}

export interface CapitalChangeResponse {
  hash: Hash;
  mod: number;
  source?: string; // Can be a hash, UUID, or some other external form of unique id
  timestamp: Date;
  type: Uuid;
  organization: Uuid;
}

export interface CapitalChangeRequest {
  amount: number;
  type: Uuid;
}

export interface ShareholdingCommon {
  capitalContribution?: number | undefined // deprecated
  member: Uuid
  issueDate: Date
  securityType: Hash
  shares: number
  legend?: Hash
}

export interface ShareholdingFormData extends ShareholdingCommon {
  capitalContributions: { [key: string]: number }
}
