import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router'
import { useState } from 'react'
import {
  RecentActionCont,
  SwitchDotsCont,
  SwitchDots,
  ShareAndRecentTitle,
  RecentAction,
  RecentActionDate,
  NoAvailableActionBlock,
  NoDataAvailable,
} from '../style'
import { getRecentActions, GetRecentActionsDispatch } from '@logic'
import { Panel } from '@components/panels/panels'
import { PanelSummaryHeader } from '@shared/components/panels/PanelSummaryHeader'

interface Props extends RouteComponentProps<any> {
  idList: any
  match: any
}

interface Action {
  action: string
  date: string
}

interface ActionList {
  actions?: Array<Action>
}

interface State {
  startIndex: number
  endIndex: number
  dots: Array<any>
}

interface StateProps {
  getRecentActions: GetRecentActionsDispatch
}
const RecentActions = (props: Props & StateProps) => {
  const [actions, setActions] = useState<any>([])
  const [state, setState] = useState<State>({
    startIndex: 0,
    endIndex: 6,
    dots: [],
  })
  const { startIndex, endIndex, dots } = state

  const renderActionsTable = () => {
    if (actions.length) {
      return (
        <table>
          <tbody>
            {actions.map((v: any, k: any) => {
              if (k >= startIndex && k <= endIndex) {
                return (
                  <tr key={k}>
                    <RecentAction> {v.action}</RecentAction>
                    <RecentActionDate> {v.date} </RecentActionDate>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      )
    }
  }

  const switchTabs = (k: number) => {
    for (let i = 0; i < dots.length; i++) {
      dots[i].active = false
    }

    dots[k].active = true

    setState({
      startIndex: k * 7,
      endIndex: (k + 1) * 7 - 1,
      dots: dots,
    })
  }

  const renderDots = () => {
    if (dots.length > 1) {
      return (
        <SwitchDotsCont>
          {dots.map((v, k) => (
            <SwitchDots key={k} active={v.active} onClick={() => switchTabs(k)} />
          ))}
        </SwitchDotsCont>
      )
    } else {
      return null
    }
  }

  return (
    <Panel>
      <PanelSummaryHeader title='Recent Actions' />
      {actions.length ? (
        <RecentActionCont>
          {renderActionsTable()}
          {renderDots()}
        </RecentActionCont>
      ) : (
        <NoAvailableActionBlock>
          <NoDataAvailable>Actions Coming Soon</NoDataAvailable>
        </NoAvailableActionBlock>
      )}
    </Panel>
  )
}
const mapStateToProps = (state: any) => ({
})

export default withRouter(
  connect(
    mapStateToProps,
    { getRecentActions }
  )(RecentActions)
)
