import * as React from 'react'
import { OrganizationsProps } from '@logic'
import { NavigationProps } from '@components/navigation/navigation'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { loadOrganizations } from '@shared/helpers'
import { OrganizationsListShared } from './components/organizations-list-shared'

type Props = NavigationProps & OrganizationsProps

const withData = withLoadingCachedMultiple<Props>(loadOrganizations)

export const OrganizationsPage = withData((props: Props) => {
  return <OrganizationsListShared {...props} />
})
