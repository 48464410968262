import * as React from 'react'
import styled, { css } from 'styled-components'
import { FlexRow, screenSizes } from '@src/styles'
import { UsePaginationInstanceProps, UsePaginationState } from 'react-table'
import CarrotRight from '@image/carrot-right.svg'
import CarrotLeft from '@image/carrot-left.svg'
import Next from '@image/next.svg'
import Previous from '@image/previous.svg'
import _ from 'lodash'

const hoverStyle = css<any>`
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.secondary};
`

const StyledPagination = styled<any>(FlexRow)`
  color: ${props => props.theme.white};
  justify-content: space-between;
  white-space: nowrap;
  max-width: 363px;
  align-self: flex-end;
  color: ${props => props.theme.white};
  margin-left: auto;
  svg {
    cursor: pointer;
    path {
      fill: ${props => props.theme.white};
    }
  }
  .left {
    justify-content: flex-end;
  }
  .right {
    width: auto;
    justify-content: flex-start;
  }
  @media (max-width: ${screenSizes.M}px) {
    margin-top: 16px;
    max-width: 100%;
    .left {
      width: auto;
      justify-content: flex-start;
    }
  }
`

const NumberWrap = styled<any>(FlexRow)`
  width: 24px !important;
  height: 24px;
  border-radius: 50%;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
  :last-child {
    margin-right: 0;
  }
  background: ${props => props.current === 'true' ? props.theme.white : ''};
  color: ${props => props.current === 'true' ? props.theme.secondary : ''};
  :hover {
    ${hoverStyle}
  }
`

export const Pagination = (props: Partial<UsePaginationInstanceProps<any> & UsePaginationState<any>> & { items: any[] }) => {
  const [paginationRange, setPaginationRange] = React.useState<any[]>([])
  const { pageIndex, pageOptions, nextPage, previousPage, canNextPage, canPreviousPage, gotoPage, pageSize } = props
  const totalPages = pageOptions!.length
  const DOTS = '...'

  const range = (start: number, end: number) => {
    let length = end - start + 1;
    return Array.from({ length }, (_, idx) => idx + start);
  }

  React.useEffect(() => {
    const totalPageCount = totalPages
    const siblingCount = 2
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = 5

    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      setPaginationRange(range(1, totalPageCount))
    }

    const leftSiblingIndex = Math.max(pageIndex - siblingCount + 1, 1)
    const rightSiblingIndex = Math.min(
      pageIndex + siblingCount + 1,
      totalPageCount
    )
    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 1
    const shouldShowRightDots = rightSiblingIndex <= totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (totalPageCount > 5 && !shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 1 + 2 * siblingCount
      let leftRange = range(1, leftItemCount)

      setPaginationRange([...leftRange, DOTS, totalPageCount])
    }

    if (totalPageCount > 5 && shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 1 + 2 * siblingCount
      let rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      )
      setPaginationRange([firstPageIndex, DOTS, ...rightRange])
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      setPaginationRange([firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex])
    }
  }, [totalPages, pageSize, pageIndex])

  return totalPages > 1 && gotoPage ? (
    <StyledPagination>
      <FlexRow className='left' justifyContent={'flex-end'}>
        <Previous onClick={() => gotoPage(0)} />
        <CarrotLeft onClick={canPreviousPage && previousPage} />
      </FlexRow>
      <FlexRow style={{ maxWidth: '203px', width: '100%' }} justifyContent={'center'}>
        {paginationRange?.map((r, i: number) => {
          if (typeof r !== 'string') {
            return <NumberWrap onClick={() => gotoPage(r - 1)} current={pageIndex === r - 1 ? 'true' : 'false'}>
              <span>{r}</span>
            </NumberWrap>
          }
          else return <span>{r}</span>
        })}
      </FlexRow>
      <FlexRow className='right' style={{ width: 'auto' }}>
        <CarrotRight onClick={canNextPage && nextPage} />
        <Next onClick={() => gotoPage(totalPages - 1)} />
      </FlexRow>
    </StyledPagination>
  ) : null
}
