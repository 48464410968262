import * as React from 'react'
import styled from 'styled-components'
import { boldStyle, colors, FlexCol, FlexRow, semiBoldStyle, Themes } from '@src/styles'
import { PathLink } from '@shared/components/navigation'
import { AddressBookBtn, CopyHover } from '../pages'
import AddressBook from '@image/address-book.svg'
import { useClose } from '@src/app/components/header/utility'
import { getNameForAddress } from '../utility'
import { walletAddressPortfolioPath } from '@logic'

interface AddressBookProps {
    addresses: any
}

const DockWrapper = styled.div<any>`
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    div {
        display: flex;
    }
    a {
        width: 100%;
    }
    &:active {
        img {
            border: 1px solid ${colors.green};
        }
        background: transparent;
    }
`

const Drop = styled<any>(FlexCol)`
    position: absolute; 
    padding: 8px 0px;
    align-items: flex-start;
    width: 330px;
    top: 56px;
    right: 0;
    z-index: 1;
    border-radius: 4px;
    background: ${({ theme }) => theme.header};
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                  0px 3px 14px rgba(0, 0, 0, 0.05),
                  0px 5px 5px rgba(0, 0, 0, 0.12);
    .title {
        font-size: 12px;
        line-height: 24px;
        color: #9CA6AD;
        ${boldStyle}
        text-transform: uppercase;
        padding: 8px 16px 8px 20px;
    }
    .empty {
        padding: 8px 16px 8px 20px;
    }
`

const OptionRow = styled<any>(FlexCol)`
    width: 100%;
    padding: 8px 16px 8px 20px;
    align-items: flex-start;
    :hover {
        background: ${colors.green};
        path {
            fill: ${props => props.theme.selected === Themes.LIGHT ? colors.white : ''};
        }
        span {
            color: ${props => props.theme.selected === Themes.LIGHT ? colors.white : ''};
        }
        .address {
            color: ${colors.white};
        }
    }
    span {
        font-size: 14px;
        line-height: 19px;
        color: ${({ theme }) => theme.listText};
    }
    path {
        fill: ${({ theme }) => theme.listText};
    }
    .address {
        font-size: 12px;
        line-height: 20px;
        color: #9CA6AD;
    }
`

const HoverText = styled<any>(CopyHover)`
    top: -32px;
`

export const AddressBookDrop = (props: AddressBookProps) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const { addresses } = props
    const ref = React.useRef() as any
    useClose(open, setOpen, ref)

    return <DockWrapper onClick={() => setOpen(!open)} ref={ref}>
        <AddressBookBtn>
            {!open ? <HoverText className='textHover'>
                <span>Address book</span>
            </HoverText>
                : null}
            <AddressBook />
        </AddressBookBtn>
        {open ? <Drop>
            <span className='title'>Address Book</span>
            {addresses.length > 0 ? addresses.map((add: any) => {
                const selectedName = getNameForAddress(add.name, add.globalName)
                const isAddress = selectedName.match(/^0x[a-fA-F0-9]{40}$/)
                const abbName = isAddress ?
                    selectedName.slice(0, 6) + '...' + selectedName.slice(selectedName.length - 4, selectedName.length)
                    : selectedName
                return <PathLink path={walletAddressPortfolioPath} args={{ address: add.address }}>
                    <OptionRow>
                        <span>{abbName}</span>
                        <span className='address'>{add.address}</span>
                    </OptionRow>
                </PathLink>
            }) : <span className='empty'>Address book is empty.</span>}
        </Drop> : null
        }
    </DockWrapper>
}