import * as React from 'react'
import { Field } from 'react-final-form'
import styled, { CSSObject } from 'styled-components'
import { ReactElement } from 'react'
import { colors, CommonInput, FlexRow, fontSizes } from '@src/styles'


interface BoxProps {
  name?: string
  id?: string
  text?: string | ReactElement
  type: string
  handleClick?: (e: any) => void
  defaultChecked?: boolean
  checked?: boolean
  info?: string
  onChange?: any
  input?: any
  validate?: any
}

interface Props extends BoxProps {
  name: string
  type: string
  inverted?: boolean
  required?: boolean
  noText?: boolean
  disabled?: boolean
}

const StyledMulti = styled<any>(FlexRow)`
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  opacity: ${props => props.disabled === 'true' ? 0.4 : 1};
  > div:first-child {
    font-size: 0.9em;
  }
  input {
    display: none;
    cursor: pointer;
  }
`

const CheckMark = styled<any>(FlexRow)`
  flex-direction: ${props => props.value ? 'row-reverse' : 'row'};
  cursor: pointer;
  height: 40px;
  width: 90px;
  background-color: ${props => props.value ? props.theme.accent : '#9CA6AD'};
  border-radius: 25px;
  justify-content: space-between;
  div {
    width: 32px;
    height: 32px;
    border-radius: 25px;
    background-color: ${colors.white};
    margin: ${props => props.value ? '0 4px 0 0' : '0 0 0 4px'};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  }
  span {
    font-size: ${fontSizes.XS};
    font-weight: bold;
    font-family: 'NunitoSansBold';
    color: ${colors.white};
    text-align: center;
    margin: ${props => !props.value ? '0 16px 0 0' : '0 0 0 16px'};
  }
`

const InfoBox = styled.div`
  display: flex;
  left: 0%;
  bottom: 0%;
  width: 400px;
  padding: 0.5rem;
  font-size: 14px;
  border: 1px solid white;
  z-index: 300;
  opacity: 0;
  transition: 700ms opacity;
  span {
    margin-right: 10px;
  }
`

const InfoIcon = styled.span`
  :hover ~ ${InfoBox} {
    opacity: 1;
  }
`

const RadioContainer = styled.label<any>`
  font-size: 1.1rem;
  line-height: 19px;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    display: none;
    cursor: pointer;
  }
`

export const ToggleSwitch = (props: Props) => {
  const { name, text, handleClick, type, checked, id, info, input, onChange = () => { }, noText, disabled } = props
  return (
    <StyledMulti disabled={disabled ? 'true' : 'false'}>
      <RadioContainer>
        <FlexRow justifyContent={'space-between'}>
          {text}
          {!!info && <InfoIcon>&#9432;</InfoIcon>}
        </FlexRow>
        {!disabled && <CommonInput id={id} type={type} name={name} onClick={handleClick} onChange={onChange} checked={checked} {...input} />}
        {info && (
          <InfoBox>
            <span>&#9432;</span>
            {info}
          </InfoBox>
        )}
        <CheckMark value={input?.checked!}>
          <div />
          {!noText && <span>{input?.checked! ? 'YES' : 'NO'}</span>}
        </CheckMark>
      </RadioContainer>
    </StyledMulti>
  )
}

export const ToggleSwitchField = (props: Props) => {
  const { name, type, validate } = props
  return (
    <>
      <Field name={name} type={type} validate={validate} render={({ input, meta }) => <ToggleSwitch {...props} input={input} />} />
    </>
  )
}
