import * as React from 'react'
import { PageProps, Paths, actionsPath, captablePath } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps, overflowParams } from '@components/navigation/navigation'
import { organizationActions } from '../organization-actions'
import styled from 'styled-components'
import { FlexRow, colors, gapSizes, screenSizes } from '@src/styles'
import { PathLinkButton, LinkButton, SubmitButton, SolidButton } from '@components/button'
import { actionIds } from '../definition'
import { Link } from 'react-router-dom'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>()

const InfoWrapper = styled<any>(FlexRow)`
  height: auto;
  color: ${props => props.theme.white};
  font-size: 20px;
  line-height: 36px;
  flex-direction: column-reverse;
  div {
    height: 100%;
    display: flex;
    align-items: start;
    justify-content: flex-start;
  }
  div:nth-child(1){
    width: 100%;
    background: ${props => props.theme.actionInfo};
    padding: 56px 62px;
    flex-direction: column;
  }
  div:nth-child(2){
    width: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  span {
    line-height: 36px;
    :nth-child(2), :nth-child(3) {
      padding-top: 15px;
    }
  }

  @media(min-width: ${screenSizes.XL}px) {
    height: 576px;
    flex-direction: row;
    div:nth-child(1){
      width: 44%;
    }
    div:nth-child(2){
      width: 52%;
    }
  }

  @media(max-width: ${screenSizes.S}px) {
    div:nth-child(1){
      padding: 26px 32px;
    }
   }
`

const ButtonsWrap = styled<any>(FlexRow)`
  padding-top: ${gapSizes.XXL};
  a, button {
    width: 325px;
  }
  a {
    margin-top: ${gapSizes.S};
  }
  @media(max-width: ${screenSizes.S}px) {
   flex-direction: column;
   a, button {
     width: 100%;
     margin-left: 0;
   }
  }
`

export const ViewActionPage = withData(
  (props: Props) => {
    const { params, navigate } = props
    const { organization, action } = params
    const data = organizationActions.filter((arg) => arg.id === action)[0]
    const title = data.title
    const description1 = data.description1
    const description2 = data.description2
    const description3 = data.description3
    const destination = data.destination
    const Diagram = data.diagram
    const args = destination !== Paths.newOrganization ? { organization } : {}

    const parent: BaseLinkProps = { path: actionsPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title={title} parent={parent} />
        <InfoWrapper>
          <div>
            <span>{description1}</span>
            <span>{description2}</span>
            <span>{description3}</span>
          </div>
          <div>
            <Diagram />
          </div>
        </InfoWrapper>
        <ButtonsWrap alignItems={'flex-end'}>
          {destination ?
            destination !== 'http://equatransfer.com' ?
              action !== actionIds.transfer ?
                <PathLinkButton path={destination} args={args}>{title}</PathLinkButton>
                :
                <SolidButton onClick={() => navigate(overflowParams(captablePath, {organization, action}))}>{title}</SolidButton>
              :
              <LinkButton to={{ pathname: destination }} target="_blank">{title}</LinkButton>
            :
            null
          }
          <LinkButton to={{ pathname: 'https://drift.me/sten1/meeting' }} target="_blank">Talk to an expert</LinkButton>
        </ButtonsWrap>
      </PageContent>
    )
  }
)
