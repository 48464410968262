import * as React from 'react'
import styled from 'styled-components'
import { fontSizes, gapSizes } from '@src/styles/theme'
import { formatImageUrl } from '@helpers/util'

interface Props {
  size?: string
  image?: string
  className?: string
  onClick?: () => void
  fullName: string
}

export const AvatarContainer = styled.div<any>`
  display: flex;
  align-items: center;
  width: ${props => props.size ? props.size : '35px'};
  height: ${props => props.size ? props.size : '35px'};
  transition: all 300ms ease-in;
  + * {
    padding-left: ${gapSizes.S};
  }
`

export const UserProfilePic = styled.img`
  width: inherit;
  height: inherit;
  border-radius: 50%;
`

export const DefaultUserProfilePic = styled.div`
  width: inherit;
  height: inherit;
  border-radius: 50%;
  background-color: ${props => props.theme.accent};
  color: ${props => props.theme.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: 'NunitoSansSemiBold';
  font-size: ${fontSizes.XS};
  text-transform: uppercase;
`

export function nameAbbreviation(fullName: string): string {
  const names = fullName.trim().split(' ')
  const firstLetter = names[0][0]
  const secondLetter = names.length > 1 ? names[names.length - 1][0] : names[0][1] || ''
  return firstLetter + secondLetter
}

export const Avatar = ({ size, image, fullName, className, onClick }: Props) => {
  const imageElement = image
    ? <UserProfilePic className={className} src={formatImageUrl(image) + '?dimensions=70x70'} />
    : <DefaultUserProfilePic className={className}>{nameAbbreviation(fullName)}</DefaultUserProfilePic>

  return <AvatarContainer size={size} onClick={onClick}>{imageElement}</AvatarContainer>
}
