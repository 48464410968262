import { UrlPath } from '@helpers/util'
import { EntityProps, OrganizationUrlProps, PaymentUrlProps } from '@logic'
import { organizationUrl } from '../organizations'

export const subscriptionsUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/subscription`
export const paymentProfilesUrl: UrlPath<EntityProps> = `entity/:entity/payment/profile`
export const paymentProfileUrl: UrlPath<PaymentUrlProps> = `${paymentProfilesUrl}/:profile`
export const defaultPaymentProfileUrl: UrlPath<PaymentUrlProps> = `${paymentProfileUrl}/default`
export const newSubscriptionUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/subscription`
export const billingHistoryUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/billing/transaction`
export const pricePointsUrl: UrlPath<OrganizationUrlProps> = 'billing/member/pricing'
