import * as React from 'react'
import { StyledModal } from '@components/modal/style'
import { useRef } from 'react'
import { useEffect } from 'react'
import { useClose } from '@src/app/components/header/utility'

const Modal = ({ show = true, children, max, align, toggleModal }: any) => {
  const node = useRef(null)
  useClose(show, toggleModal, node)

  useEffect(() => {
    const body = window.document.querySelector('body')
    const main = window.document.querySelector('main')
    if (body) {
      body.style.overflowY = 'hidden'
    }
    if (main) {
      main.style.zIndex = 'unset'
    }
    return () => {
      if (body) {
        body.style.overflowY = 'auto'
      }
      if (main) {
        main.style.zIndex = '0'
      }
    }
  }, [])

  return (
    <StyledModal show={show} max={max} align={align}>
      <section ref={node}>{children}</section>
    </StyledModal>
  )
}

export default Modal
