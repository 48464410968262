import styled from 'styled-components'
import { colors, screenSizes } from '@src/styles/theme'
import { FlexRow } from '@src/styles'
import { rgba } from 'polished'

export const NoTicketCont = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 24px auto 32px auto;
  img {
    height: 110px;
    margin-right: 24px;
    :last-child {
      margin-right: 0;
    }
    @media (min-width: ${screenSizes.XL}px) {
      height: 100%;
    }
  }
  @media (min-width: ${screenSizes.XL}px) {
    
  }
`

export const TicketsCont = styled<any>(FlexRow)`
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: 24px auto 32px auto;
  .ticket {
    width: 100%;
    height: 100%;
  }
  .single-ticket {
    position: relative;
    margin: 0 24px 0 0;
    max-width: 223px;
    max-height: 317px;
    :last-child {
      margin: 0;
    }
  }

  .brown-scratch-box {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    position: absolute;
    top: 119px;
    left: 36px;
  }

  @media(max-width: ${screenSizes.M}px) {
    flex-direction: column;
    .single-ticket {
      margin: 0 0 24px 0;
      max-width: 175px;
      max-height: 249px;
    }
    .brown-scratch-box {
      top: 93px;
      left: 28px;
    }
  }
`

export const ResultBox = styled.div<any>`
  height: ${props => props.height}px;
    width: ${props => props.width}px;
    border: 6px solid #2c3e50;
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .amount {
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 29px;
        height: 29px;
      }

      span {
        margin-left: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 800;
        font-family: 'NunitoSansBold';
        font-size: 36px;
        line-height: 24px;
        color: #3e3e3e;
      }
    }

    .equa-cash-text {
      font-weight: 800;
      font-family: 'NunitoSansBold';
      font-size: 18px;
      line-height: 24px;
      color: #3e3e3e;
      margin-top: 8px;
    }

    .equa-cash-value {
      margin-top: 8px;
      background: #9CA6AD;
      border-radius: ${props => props.theme.radius};
      font-weight: 600;
      font-family: 'NunitoSansSemiBold';
      font-size: 12px;
      line-height: 20px;
      color: #ffffff;
      width: 122px;
      text-align: center;
      padding: 4px 8px;
      height: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media(max-width: ${screenSizes.M}px) {
      .amount {
        display: flex;
        align-items: center;
        justify-content: space-between;

        img {   
          width: 23px;
         height: 23px;
        }

        span {
          font-size: 28px;
          line-height: 19px;
       }
      }
      .equa-cash-text {
        font-size: 14px;
        line-height: 19px;
      }
      .equa-cash-value {
        width: 88px;
        height: 22px;
      }
    }
`
export const CarouselDot = styled.div<any>`
    width: 8px;
    height: 8px;
    margin: 0px 8px;
    border-radius: 4px;
    background: ${props => props.active === 'true' ? colors.green : rgba(51, 187, 64, 0.321569)};
    :last-child {
      margin-right: 0;
    }
`

export const TicketsWrap = styled<any>(FlexRow)`
  justify-content: center;
  margin-left: ${props => props.margin === 'left' ? '48px' : 0};
  margin-right: ${props => props.margin === 'right' ? '48px' : 0};
  svg {
    cursor: pointer;
  }
 .carrotLeft {
   margin-right: 24px;
  }
 .carrotRight {
   margin-left: 24px;
  }
  @media(max-width: ${screenSizes.M}px) {
    margin-left: ${props => props.margin === 'left' ? '20px' : props.margin === 'right' ? '-20px' : 0};
    .carrotLeft {
      margin-right: 16px;
    }
    .carrotRight {
      margin-left: 16px;
    }
  }
`
