import Modal from '@components/modal'
import Close from '@image/close-default.svg'
import { FlexRow, LightDivider, screenSizes, whiteColor } from '@src/styles'
import { ClearButton, WarningButton } from '@components/button'
import * as React from 'react'
import { ReactElement, useState } from 'react'
import styled from 'styled-components'
import Warning from '@image/warning.svg'

interface Props {
    title: string
    onCancel?: () => void
    onSubmit: (props: any) => void
    submitText: string
    withMembers?: boolean
}

const TitleView = styled.h4`
  margin: 0;
  color: ${({ theme }) => theme.white};
  line-height: 32px;
  font-weight: bold;
  font-family: 'NunitoSansBold';
`

const ModalCloseButton = styled.div`
  position: absolute;
  top: 40px;
  right: 36px;
  width: auto !important;
  margin: 0 !important;
  cursor: pointer;
  svg {
    fill: #9CA6AD;
  }
`

const WelcomeWrap = styled.div`
  color: ${props => props.theme.white} !important;
  width: 100%;
  padding: 32px 32px 24px 32px !important;
  overflow-y: auto;
  background: ${props => props.theme.welcomeBack};
  .warning {
    margin-right: 16px;
    path {
      fill: ${props => props.theme.warning};
    }
}
  svg {
      margin-right: 16px;
  }
  .body {
      padding: 24px 0;
      align-items: flex-start;
  }
`
const DescView = styled.div`
  color: ${props => props.theme.white} !important;
  span {
      line-height: 24px;
      display: block;
      margin: 0;
      :last-child {
          margin-top: 24px;
      }
  }
  a {
      line-height: 24px;
      :hover {
        text-decoration: underline;
      }
  }
`

const FormButtonLayout = styled<any>(FlexRow)`
  padding-top: 24px;
  justify-content: flex-end;
  
  .submit {
      padding: 8px 32px;
      height: 40px;
      background: ${props => props.theme.warning} !important;
      color: #262626 !important;
  }
  .submit: hover{
    background: ${props => props.theme.button.warning.hover} !important ;
  }
  button {
    height: 40px;
    padding: 0 32px !important;
  }
  @media(max-width: ${screenSizes.M}px) {
      flex-direction: column;
      button: last-child {
          margin: 8px 0 0 0;
      }
  }
`


const StyledDivider = styled<any>(LightDivider)`
    margin: ${props => props.stretch && '0 -5.5%'};
    border-bottom: 1px solid #CEDAE1;
`

export function DeleteRoleModalComponent(props: Props) {
    const { title, onSubmit, submitText, withMembers } = props
    const onCancel = props.onCancel || function () { }
    return (
        <Modal max={'544px'}>
            <ModalCloseButton onClick={onCancel}>
                <Close />
            </ModalCloseButton>
            <WelcomeWrap>
                <FlexRow>
                    <Warning className='warning' />
                    <TitleView>{title}</TitleView>
                </FlexRow>
                <FlexRow className="body">
                    {withMembers ?
                        <DescView>
                            <span>You have members assigned to this role.</span>
                            <span>If you continue, these members will be removed from this role and will lose all permissions associated with this role only.</span>
                        </DescView>
                        :
                        <DescView>
                            <span>This is a role that you created. You can delete this role, but please know this action cannot be undone.</span>
                            <span>Do you want to continue and delete this role?</span>
                        </DescView>
                    }
                </FlexRow>
                <StyledDivider stretch={true} />
                <FormButtonLayout>
                    <ClearButton onClick={onCancel}>
                        Cancel
                    </ClearButton>
                    <WarningButton onClick={onSubmit} className='submit'>
                        {props.submitText}
                    </WarningButton>
                </FormButtonLayout>
            </WelcomeWrap>
        </Modal>
    )
}

export interface deleteRoleModalBundle {
    component: ReactElement
    isVisible: boolean
    setVisible: (value: boolean) => void
}

export function deleteRoleModalBundle(props: Props): deleteRoleModalBundle {
    const [isVisible, setVisible] = useState<boolean>(false)
    const component = (
        <>
            {isVisible ? (
                <DeleteRoleModalComponent {...props} />
            ) : null}
        </>
    )
    return {
        component,
        isVisible,
        setVisible,
    }
}
