import * as  React from 'react'
import styled from 'styled-components'
import { FlexCol, FlexRow } from '@src/styles'
import { matchesPath, PathLink } from '../navigation'
import { rgba } from 'polished'
import { Breadcrumbs } from '../breadcrumbs'
import _ from 'lodash'

export interface Tab {
    name: string
    destination: string
    disabled?: boolean
    exact?: boolean
}

export interface TabsProps {
    title: string | React.ReactElement
    tabs: Tab[]
    params: { [key: string]: string }
}

const ActionsWrap = styled<any>(FlexCol)`
    position: sticky;
    top: 56px;
    z-index: 10;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-end;
    background-color: ${props => props.theme.newNav};
    border-bottom: 1px solid #33AA40;
    div {
        width: calc(100% - 2 * var(--pageMargin));
        max-width: 1280px;
        :last-child {
            height: 40px;
            overflow-y: hidden;
            margin-bottom: -1px;
        }
    }
    *::-webkit-scrollbar {
        display: none !important;
    }
`

const StyledTab = styled<any>(PathLink)`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
  white-space: nowrap;
  background: ${props => props.selected && props.theme.secondary};
  border-bottom: ${props => props.selected && `1px solid ${props.theme.secondary}`};
  box-shadow: ${props => props.selected && 'inset 0px 1px 0px #33AA40, inset -1px 0px 0px #33AA40, inset 1px 0px 0px #33AA40'};
  border-radius: 4px 4px 0px 0px;
  padding: 0 16px;
  margin-bottom: ${props => props.selected && '-3px'};
  color: ${props => props.disabled ? rgba(191, 191, 191, 0.4) :
        props.selected ? props.theme.white : props.theme.listDesc};
  font-size: 18px;
  line-height: 26px;
`

const Title = styled.h4<any>`
    font-weight: 600;
    font-size: 34px;
    font-family: 'NunitoSansSemiBold';
    line-height: 46px;
    color: ${props => props.theme.white};
    height: auto;
    margin: 16px 0;
    display: flex;
    align-items: center;
`

export const Tabs = (props: TabsProps) => {
    const { tabs, title, params } = props
    const arrLength = tabs.length;
    const elRefs = React.useRef<any>([]);

    if (elRefs.current.length !== arrLength) {
        // add or remove refs
        const array = _.range(0, arrLength, 1)
        elRefs.current = array.map((_, i) => elRefs.current[i] || React.createRef());
    }

    React.useEffect(() => {
        const current = tabs?.findIndex(t => matchesPath(t.destination, window.location.pathname, true))
        const currentRef = elRefs?.current[current]?.current
        if (currentRef) {
            currentRef.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'center' })
        }
    }, [window.location.pathname])

    return <ActionsWrap>
        <FlexCol alignItems={'flex-start'} style={{ padding: '40px 0px 0px' }}>
            <Title>{title}</Title>
        </FlexCol>
        <FlexRow>
            {tabs?.map((tab: Tab, i: number) => {
                const exactCondition = tab.exact == false ? tab.exact : true
                const selected = matchesPath(tab.destination, window.location.pathname, exactCondition)
                return <span ref={elRefs.current[i]} key={i}>
                    <StyledTab
                        path={tab.destination}
                        args={params}
                        selected={selected}
                        disabled={tab.disabled}
                    >
                        {tab.name}
                    </StyledTab>
                </span>
            })}
        </FlexRow>
    </ActionsWrap >
}