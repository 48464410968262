import * as React from 'react'
import { OrganizationProps, PageProps, MemberProps, captablePath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganization, loadMember } from '@helpers/loaders'
import { DetailsLabel, whiteColor, DetailsMaxWidth, FlexCol } from '@src/styles'
import styled from 'styled-components'
import { BaseLinkProps } from '@shared/components/navigation'

type Props = PageProps & OrganizationProps & MemberProps

const withData = withLoadingCachedMultiple<Props>(loadOrganization, loadMember)

const CertificateApprovalsWrapper = styled.div`
  ${whiteColor}
  ${DetailsMaxWidth}
`

export const CertificateApprovalsPage = withData(
    (props: Props) => {
        const { memberRecord, params } = props
        const { organization } = params
        const parent: BaseLinkProps = { path: captablePath, args: { organization } }

        return (
            <PageContent>
                <PageContentHeader title="Details" parent={parent} />
                <CertificateApprovalsWrapper>
                    <FlexCol justifyContent={'space-between'} alignItems={'flex-start'}>
                        <DetailsLabel>Approval Text:</DetailsLabel>
                        <span>
                            I hereby acknowledge the receipt of the following Certificate:
                            PA-2 to Mike Johnson for 250,000 shares of Preferred Stock
                        </span>
                        <span>
                            Executed March, 30 2020
                        </span>
                        <span>
                            By: Mike Johnson
                        </span>
                    </FlexCol>
                </CertificateApprovalsWrapper>
            </PageContent>
        )
    }
)
