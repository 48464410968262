import * as React from 'react'
import { organizationListPath, portfolioPath } from '@logic'
import { PageContent } from '@components/pages'
import { FlexCol, FlexRow, whiteColor } from '@src/styles'
import { PathLinkButton, PathLinkInvertedButton } from '@components/button/button'
import styled from 'styled-components'
import WelcomeHeader from '@image/welcome-top.png'
import AllSet from '@image/all-set.svg'

const Wrapper = styled.div`
  ${whiteColor}
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
      width: 100%;
      height: auto;
      margin-top: 57px;
  }
  svg {
    margin-bottom: 24px;
  }
`

const ContentWrap = styled<any>(FlexCol)`
  align-items: center;
  h1 {
      line-height: 54px;
      font-weight: normal;
      margin-bottom: 32px;
  }
`

const Info = styled.h4<any>`
  line-height: 33px;
  font-weight: normal;
  margin: 40px 0;
  font-family: 'NunitoSans';
`

const Description = styled.span<any>`
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 40px;
  max-width: 853px;
  text-align: center;
`

export const AllSetPage = () => {
  return (
    <Wrapper>
      <img src={WelcomeHeader} />
      <PageContent noPadding={true}>
        <ContentWrap>
          <AllSet/>
          <h1>You’re All Set!</h1>
          <Description>You’re ready to start using Equa to manage<br></br> your public and private assets in one place!</Description>
          <FlexRow>
            <PathLinkButton path={portfolioPath} args={{}}>
              VIEW YOUR PORTFOLIO
            </PathLinkButton>
            <PathLinkInvertedButton path={organizationListPath} args={{}}>
              ONBOARD NEW ORGANIZATION
            </PathLinkInvertedButton>
          </FlexRow>
        </ContentWrap>
      </PageContent>
    </Wrapper>
  )
}
