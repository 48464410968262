import * as React from 'react'
import { Panel } from '@components/panels/panels'
import { FlexCol, FlexRow, fontSizes, LightDivider, screenSizes } from '@src/styles'
import { NestedPlan, ExpandedHolding } from '@src/service'
import styled from 'styled-components'
import { HorizontalVestingChart } from '@modules/esop/components/vesting-chart'
import { SolidButton } from '@shared/components/button'
import { captableCertificatePath, purchasePath } from '@logic'
import { NavigationProps, overflowParams, PathLink, withNavigation } from '@shared/components/navigation'
import { formatDateString, Hash, normalizeDate } from '@shared/helpers'
import { renderHeader } from '@shared/components/forms'
import { mapEvents } from '@modules/esop/logic'

interface Props extends NavigationProps {
  plans: NestedPlan[]
  options: ExpandedHolding[]
  organization: Hash
  isAdmin?: boolean
}

const EsopWrap = styled.div<any>`
  color: ${props => props.theme.white};
  h2 {
    font-weight: bold;
    font-family: 'NunitoSansBold';
    font-size: ${fontSizes.M};
    line-height: 33px;
    padding: 0 !important;
  }
  h3 {
    font-size: 20px;
    line-height: 27px;
  }
  a {
    font-size: 20px;
    line-height: 28px;
    margin-left: 8px;
  }
  .panelWrap {
    padding: 20px 28px 30px 30px;
  }
`

const EsopInfo = styled<any>(FlexCol)`
  color: ${props => props.theme.white};
  align-items: flex-start;
  justify-content: space-between;
  margin-left: ${props => props.hasVesting === 'false' ? '8px' : 0};
  width: 26%;
  .bold {
    font-weight: bold;
    font-family: 'NunitoSansBold';
    font-size: 20px;
    line-height: 28px;
  }
  .label {
    line-height: 24px;
    color: #9CA6AD;
  }
  @media (max-width: ${screenSizes.M}px) {
      width: 100%;
  }
`

const InfoRow = styled<any>(FlexCol)`
  margin-bottom : 8px;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
`

const StyledRow = styled<any>(FlexRow)`
  align-items: flex-start;
  margin-top: 16px;
  @media (max-width: ${screenSizes.M}px) {
    flex-direction: column;
  }
`

const StyledDivider = styled<any>(LightDivider)`
    border-color: ${props => props.theme.highlight} !important;
    @media (min-width: ${screenSizes.XL}px) {
        margin: 24px 0;
    }
`

export const EsopPreview = withNavigation((props: Props) => {
  const { options, navigate, params, plans, isAdmin, organization } = props
  const currentPlan = plans[0]

  return (
    <EsopWrap>
      <Panel className='panelWrap'>
        {renderHeader({ label: currentPlan?.name || 'Incentive Plan' })}
        {options.map((optionRecord, i: number) => {
          const exercisedShares = optionRecord.exercisedShares!
          const totalShares = optionRecord.startingValue!
          const availableShares = optionRecord.availableShares || 0
          const canPurchase = availableShares > 0 && isAdmin
          const isLast = i === options.length - 1
          const poolName = optionRecord.poolName || 'Incentive Pool'
          const vestingSchedule = optionRecord.vestingSchedule as any
          const custom = vestingSchedule ? Object.keys(vestingSchedule).includes('events') : false
          const events = vestingSchedule ? mapEvents(vestingSchedule) : undefined
          const chartValue = custom ? !vestingSchedule.isValueAbsolute ?
            optionRecord.startingValue : undefined : optionRecord.startingValue

          return <div key={optionRecord.hash}>
            <FlexRow justifyContent='space-between' style={{ padding: '8px' }}>
              <FlexRow>
                <h3>{`${poolName}`}</h3>
                <PathLink
                  path={captableCertificatePath}
                  args={{
                    organization,
                    block: 'current',
                    shareholding: optionRecord.hash
                  }}
                  target={'_blank'}>{optionRecord.name} </PathLink>
              </FlexRow>
              <SolidButton
                onClick={() =>
                  navigate(overflowParams(purchasePath, {
                    organization: params.organization,
                    plan: currentPlan.hash,
                    option: optionRecord.hash,
                    parent: window.location.pathname
                  }))
                }
                disabled={!canPurchase}
                style={{ height: '40px' }}
              >
                Purchase
              </SolidButton>
            </FlexRow>
            <StyledRow>
              {vestingSchedule ?
                <FlexRow style={{ width: '74%', marginRight: '24px' }}>
                  <HorizontalVestingChart
                    vestingSchedule={custom ? { ...vestingSchedule, events } : vestingSchedule}
                    startDate={normalizeDate(optionRecord.startDate) || undefined}
                    custom={custom}
                    noMargin={true}
                    value={chartValue}
                    forView={chartValue ? false : true}
                  />
                </FlexRow>
                : null
              }
              <EsopInfo hasVesting={vestingSchedule ? 'true' : 'false'}>
                <InfoRow>
                  <span className='label'>Amount available to purchase today</span>
                  <span className='bold'>{availableShares?.toFixed(2)}</span>
                </InfoRow>
                <InfoRow>
                  <span className='label'>Amount purchased to date</span>
                  <span className='bold'>{exercisedShares?.toFixed(2)}</span>
                </InfoRow>
                {optionRecord.nextVestingEvent?.date ? <InfoRow>
                  <span className='label'>Next vesting event</span>
                  <span className='bold'>{optionRecord.nextVestingEvent?.date && formatDateString(optionRecord.nextVestingEvent.date)}</span>
                </InfoRow> : null
                }
                <InfoRow style={{ marginBottom: 0 }}>
                  <span className='label'>Total amount once fully vested</span>
                  <span className='bold'>{totalShares?.toFixed(2)}</span>
                </InfoRow>
              </EsopInfo>
            </StyledRow>
            {!isLast && <StyledDivider stretch={true} />}
          </div>
        })}
      </Panel>
    </EsopWrap>
  )
})
