import * as React from 'react'
import { PageProps, User, MembersProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadColors, loadPortfolioData } from '@helpers/loaders'
import { ExpandedHoldingsResponse, GetSecuritiesResponse } from '@src/service'
import { StateTransform } from 'index'
import { connect } from 'react-redux'
import { GetColorsResponse } from '@src/service/services/profile'
import { CommonPortfolio } from '@shared/components/common-portfolio'

interface StateProps {
    user?: User
}

type Props = PageProps &
    StateProps &
    MembersProps &
    GetColorsResponse &
    ExpandedHoldingsResponse &
    GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadPortfolioData('user'), loadColors('user'))

export const PortfolioPageComponent = withData((props: Props) => {
    return <CommonPortfolio {...props} title='My Portfolio' />
})

const mapStateToProps: StateTransform<StateProps> = s => ({
    user: s.user,
})

export const PortfolioPage = connect(mapStateToProps, {})(PortfolioPageComponent)
