import { AnyAction, Reducer } from 'redux'
import { OrganizationError, OrganizationState } from '../types/types'
import { ActionType } from '../actions'

export const organizationReducer: Reducer<OrganizationState, AnyAction> = (state = {}, action: any) => {
  switch (action.type) {
    case ActionType.CREATE_ORGANIZATION_SUCCESS: {
      return { ...state, currentOrganization: action.data.organization }
    }
    case ActionType.ORGANIZATION_LIST_SUCCESS: {
      return { ...state, organizationList: action.data }
    }
    case ActionType.CURRENT_ORGANIZATION_SUCCESS: {
      return { ...state, currentOrganization: action.data }
    }
    case ActionType.GET_DRIVE_ID_SUCCESS: {
      return {
        ...state,
        currentOrganization: { ...state.currentOrganization, driveId: action.data },
        organizationError: undefined,
      }
    }
    case ActionType.GET_DRIVE_ID_FAILURE: {
      return { ...state, organizationError: OrganizationError.GetDriveFailed }
    }

    case ActionType.GET_CERTIFICATE_LIST_SUCCESS: {
      return {
        ...state,
        certificateList: action.data,
        isLoading: false,
      }
    }

    case ActionType.GET_CERTIFICATE_LIST_START: {
      return {
        ...state,
        isLoading: true,
      }
    }

    case ActionType.GET_CERTIFICATE_LIST_FAILURE: {
      return {
        ...state,
        isLoading: false,
      }
    }
  }
  return state
}
