import * as React from 'react'
import { connect } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { useEffect, useReducer, useState } from 'react'
import { History } from 'history'
import { GlobalStyle } from '@src/styles/global'
import { getTheme, headerHeight, Themes } from '@src/styles/theme'
import styled, { ThemeProvider } from 'styled-components'
import AppErrorBoundary from './components/app-error-boundary'
import { StateTransform } from '../index'
import {
  getCurrentUser,
  GetCurrentUserDispatch,
  getOrganizationList,
  GetOrganizationListDispatch,
  Organization,
  User,
  setExpanded,
  setCollapsed,
  organizationPath,
  Paths,
} from '@logic'
import { AppRoutes } from '@src/app/components/routes'
import { Service } from '@src/service'
import Cookies from 'universal-cookie'
import { OnboardChecklist } from '@shared/components/onboard-checklist/onboard-checklist'
import { matchesPath } from '@shared/components/navigation'

const cookies = new Cookies()

interface Props {
  history: History
  user?: User
  service: Service
  getCurrentUser: GetCurrentUserDispatch
  getOrganizationList: GetOrganizationListDispatch
  setExpanded: any
  setCollapsed: any
}

interface StateProps {
  user?: User
  organizationList?: Organization[]
  currentOrganization?: Organization
  checklist?: any
}

interface AppReducerState {
  service: Service
  theme: Themes
  organizationReload?: () => void
}

function appReducer(state: AppReducerState, action: any) {
  switch (action.type) {
    case 'SET_THEME':
      cookies.remove('theme')
      cookies.set('theme', action.payload, { path: '/' })
      return { ...state, theme: action.payload }
    case 'SET_ORGANIZATION_RELOAD':
      return { ...state, organizationReload: action.payload }
    default:
      return state
  }
}

interface DispatchProps {
  dispatch: (action: any) => void
}

export const AppContext = React.createContext<Partial<AppReducerState> & DispatchProps>({
  theme: Themes.EQUA,
  dispatch: () => { },
})




const AppWrap = styled.div<any>`
  display: flex;
  width: 100%;
  margin-top: ${headerHeight};
`

const NormalWrap = styled.div<any>`
  display: block;
  width: 100%;
`

const AppComponent = (props: Props & StateProps) => {
  const [initialLoad, setInitialLoad] = useState(false)
  const { getCurrentUser, history, currentOrganization, user } = props
  const setTheme = cookies.get('theme')
  const contextState = { theme: parseInt(setTheme) || Themes.EQUA, service: props.service }
  const [state, dispatch] = useReducer(appReducer, contextState)

  const getUser = async () => {
    await getCurrentUser(() => setInitialLoad(true))
  }

  // const noMarginPath = [ "/",Paths.userLogin,Paths.verify,Paths.userRegister,Paths.userThanks, Paths.userForgot]
  // const noMarginArray =  noMarginPath.map((path)=>{
  //   return matchesPath(path, window.location.pathname, true) 
  // })
  // const noMargin = noMarginArray.includes(true)
  const currentTheme = { ...getTheme(state.theme), selected: contextState.theme }

  useEffect(() => {
    getUser()
  }, [!!user])

  useEffect(() => {
    if (currentOrganization) (global as any).globalDriveId = currentOrganization.driveId
  }, [currentOrganization && currentOrganization.driveId])
  const show = matchesPath(organizationPath, window.location.pathname, false) && !!user

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider theme={currentTheme}>
        <ConnectedRouter history={history}>
          <GlobalStyle />
          <AppErrorBoundary>
            {show ?
              <AppWrap>
                <AppRoutes initialLoad={initialLoad} />
                <OnboardChecklist user={user} />
              </AppWrap>
              :
              <NormalWrap>
                <AppRoutes initialLoad={initialLoad} />
              </NormalWrap>
            }
          </AppErrorBoundary>
        </ConnectedRouter>
      </ThemeProvider>
    </AppContext.Provider>
  )
}

const mapStateToProps: StateTransform<StateProps> = s => ({
  user: s.user,
  organizationList: s.organization!.organizationList,
  currentOrganization: s.organization!.currentOrganization,
  checklist: s.checklist
})

export const App = connect(mapStateToProps, { getCurrentUser, getOrganizationList, setExpanded, setCollapsed })(AppComponent)
