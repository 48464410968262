import * as React from 'react'
import { Link } from 'react-router-dom'
import { applyPathArgs, BaseLinkProps, overflowParams } from '@components/navigation/navigation'

export interface PathLinkStatusProps {
  disabled?: boolean
  selected?: boolean
}

export interface PathLinkProps extends BaseLinkProps, PathLinkStatusProps {
  children?: any
  className?: string
  target?: string
}

export const PathLink = (props: PathLinkProps) => {
  const { children, disabled, path, args, target } = props
  const url = path ? overflowParams(path, args || {}) : ''
  const disabledClass = disabled ? ' disabled' : ''
  return (
    <Link target={target} className={(props.className || '') + disabledClass} to={url} name={children}>
      {children}
    </Link>
  )
}
