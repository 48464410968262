export const readLinkFromStream = (url: string) => {
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Cache-Control': 'no-cache'
        },
        credentials: 'include',
    })
        .then(response => response.body)
        .then(rb => {
            const reader = rb.getReader();
            return new ReadableStream({
                start(controller) {
                    function push() {
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            push();
                        })
                    }

                    push();
                }
            });
        })
        .then(stream => {
            return new Response(stream, { headers: { "Content-Type": "text/html" } });
        })
        .then(result => {
            return result.text().then((text) => {
                let newUrl = text.substr(text.toLowerCase().search('url=') + 4, text.length)

                if (!newUrl.startsWith('http')) {
                    newUrl = 'http://' + newUrl
                }
                return newUrl
            });
        })
}