import * as React from 'react'
import {
  captablePath,
  ShareholdingFormData,
  MembersProps,
  PageProps,
  ShareholdingFormInfoProps,
  OrganizationProps,
  setExpanded,
} from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  InitialShareholdingFormValues,
  prepareCapitalContributionsForSubmission,
  NewShareholdingForm
} from '@modules/captable/components/shareholding-form'
import { loadMembers, loadShareholdingFormConfig, loadSecurities, loadLegends, loadOrganization } from '@helpers/loaders'
import { BuiltInPermission } from '@helpers/constants'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadPermissions } from '@components/permissions'
import {
  GetSecuritiesResponse,
  GetLegendsResponse,
  postFile,
  FilesResponse,
  createHolding,
  CapitalChangeRequest,
  patchCapitalChange
} from '@src/service'
import { NeedsSecurityTypes } from '@shared/errors/needs-security-types'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { HoldingRecordType } from '@shared/helpers/holdings'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & MembersProps & ShareholdingFormInfoProps & GetSecuritiesResponse & GetLegendsResponse & OrganizationProps & StoreProps

const withData = withLoadingCachedMultiple<Props>(
  loadMembers,
  loadShareholdingFormConfig,
  loadPermissions,
  loadSecurities(),
  loadLegends(true),
  loadOrganization
)

const NewShareholdingComponent = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { members, navigate, params, httpClient, securities, legends, setExpanded } = props
    const organizationDetails = props.organization
    const { name } = organizationDetails
    const { organization } = params
    const securitiesList = securities ? securities?.filter(s => !s.parent) : []
    const securityType = securitiesList[0]
    const initialValues: InitialShareholdingFormValues = {
      issueDate: new Date(),
      member: members[0].id, // TODO: Add earlier/outer code that handles an organization with no members
      securityType: securityType?.hash,
      capitalContributions: {}
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: ShareholdingFormData) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const { organization } = params
      const capitalContributions = prepareCapitalContributionsForSubmission(values).capitalContributions
        .filter(arg => arg.amount !== 0)
      const equity = securitiesList?.find(sec => sec.hash == values?.securityType)
      const request = {
        owner: values?.member,
        issueDate: values?.issueDate,
        parent: values?.securityType,
        value: values?.shares,
        legend: values?.legend,
        entity: organization,
        holdingType: HoldingRecordType.draft,
        internalType: equity ? equity.shareTypeUuid : '',
        class: equity ? equity.shareClassPrefix : '',
      }
      const response = await createHolding(httpClient)({}, request)
      if (response?.id!) {
        const target = response.hash
        if (capitalContributions?.length > 0) {
          capitalContributions.forEach(async (c: any) => {
            const capitalChangeRequest = {
              mod: c.amount,
              timestamp: new Date(),
              type: c.type,
              source: target
            } as CapitalChangeRequest
            await patchCapitalChange(httpClient)(
              { entity: organization }, capitalChangeRequest
            )
          })
        }
        modifyAuthorizations({ approvalDocuments, target, httpClient, organization })
        setExpanded()
        navigate(captablePath, { organization })
      }
    }

    const parent: BaseLinkProps = { path: captablePath, args: { organization } }
    return (
      <PageContent>
        <PageContentHeader title="Draft" parent={parent} />
        {securities.length == 0 ?
          <NeedsSecurityTypes organization={organization} organizationName={name} />
          :
          <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
            submitText="Issue">
            <NewShareholdingForm members={members} securities={securitiesList} legends={legends} onUpload={onUpload} securityType={securityType} />
          </CommonForm>
        }
      </PageContent>
    )
  }
  ))

export const NewShareholdingPage = connect(null, { setExpanded })(NewShareholdingComponent)
