import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { MembersProps, PageProps, plansPath, setExpanded } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions, PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { PlanForm } from '../components/plan-form'
import {
  FilesResponse,
  FormPlan,
  GetSecuritiesResponse,
  newPlanFromRequest,
  NewPlanRequest,
  postFile
} from '@src/service'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'
import { BaseLinkProps } from '@shared/components/navigation'
import { loadMembers, loadSecurities } from '@shared/helpers'
import { filterEquities } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & PermissionProps & StoreProps & GetSecuritiesResponse & MembersProps

const withData = withLoadingCachedMultiple<Props>(loadPermissions, loadSecurities('true'), loadMembers)

const CreatePlanComponent = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, setExpanded, securities, stats, user, members } = props
    const { organization } = params
    const initialValues = {}
    const securitiesList = filterEquities((stats || []), securities)
    const approvedEquities = securitiesList?.filter(s => !s.parent)
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id,
      }
    }
    const currentUser = members?.find(m => m.user === user?.id)?.id || user?.id

    const createNewPlan = async (values: FormPlan) => {
      const { boardApprovalDocument, incentivePlanDocument } = values
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvedEquities = {
        equity: values.approvedEquities
      }
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const request: NewPlanRequest = {
        plan: {
          name: values?.name,
          boardApprovalDate: values.boardApprovalDate,
          approvedEquities,
          createdBy: currentUser!,
          termYears: Number(values.termYears),
          boardApprovalDocument: boardApprovalDocument ? boardApprovalDocument.id : undefined,
          incentivePlanDocument: incentivePlanDocument ? incentivePlanDocument.id : undefined,
        },
      }
      const newPlan = await newPlanFromRequest(httpClient)({ organization }, request)
      if (newPlan?.plan) {
        modifyAuthorizations({ approvalDocuments, target: newPlan.plan.hash, httpClient, organization })
        setExpanded()
        navigate(plansPath, { organization })
      }
      else console.log('create Plan error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: plansPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Create New Incentive Plan" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={createNewPlan} initialValues={initialValues}
          submitText="Save">
          <PlanForm onUpload={onUpload} securities={approvedEquities} params={params} />
        </CommonForm>
      </PageContent>
    )
  })
)

export const CreatePlan = connect(null, { setExpanded })(CreatePlanComponent)
