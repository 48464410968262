import * as React from 'react'
import { ScratchCardList } from '../../scratch-card-list/scratch-card-list'
import { Heading, SubHeading } from './style'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import ScratchHeaderLeft from '@image/scratch-header-left.svg'
import ScratchHeaderRight from '@image/scratch-header-right.svg'
import styled from 'styled-components'
import { FlexCol, FlexRow, screenSizes } from '@src/styles'
import { scratchCard } from '@src/service/services/profile'
import { HttpClient } from '@src/service'
import { PathLink } from '@shared/components/navigation'
import { CancelButton, SolidButton } from '@shared/components/button'
import { ExpandedReward, organizationReferralsPath, referralsPath } from '@logic'

interface Props {
  ticketList: ExpandedReward[]
  params: any
}
const ScratchModalWrap = styled<any>(FlexCol)`
  overflow-x: hidden;
  .body {
    padding: 0px 2px 24px;
    margin-top: 40px;
    width: 100%;
  }
`

const ScratchHeaderWrap = styled<any>(FlexRow)`
    width: 100%;
    justify-content: center;
    .head {
      padding: 32px 0px 0px;
    }
    svg {
      position: absolute;
    }
    .left {
      top: 0;
      left: 0;
    }
    .right {
      top: 0;
      right: 0;
    }
    @media(max-width: ${screenSizes.M}px) {
      svg {
        position: static;
      }
      .left {
        left: -115px;
      }
      .right {
        right: -109px;
      }
    }
`

export function scratchTicketModal(props: Props & { httpClient: HttpClient, onCancel: () => void }) {
  const [wasScratchAllClicked, setWasScratchAllClicked] = React.useState<any>(false)
  const { ticketList, params, httpClient, onCancel } = props
  const { organization } = params
  const notScratchedCards = ticketList?.filter(t => !t.scratched).map((ticket, i: number) => ({ ...ticket, index: i }))
  const [rewardsList, setRewardsList] = React.useState<ExpandedReward[]>(notScratchedCards || [])
  const showCards = notScratchedCards?.slice(0, 3).length > 0
  const mobileView = window.innerWidth < 800

  const handleScratchAll = async () => {
    const cards: any = []
    let totalReward: any = 0
    rewardsList.forEach(reward => {
      if (reward) {
        reward.scratched = true
        cards.push(reward.id)
        totalReward = totalReward + reward.reward
      }
    })
    const data = { rewardArray: cards }
    await scratchCard(httpClient)({}, data)
    setRewardsList([...rewardsList])
    setWasScratchAllClicked(true)
  }

  const getButtons = () => {
    if (notScratchedCards?.length && !wasScratchAllClicked) {
      return [
        {
          text: 'Continue Later',
          onActivate: onCancel,
          componentType: CancelButton,
        },
        {
          text: 'Scratch All',
          onActivate: handleScratchAll,
          componentType: SolidButton,
        },
      ]
    } else {
      if (notScratchedCards?.length && wasScratchAllClicked) {
        return [{
          text: 'Done',
          onActivate: onCancel,
          componentType: SolidButton,
        }]
      } else return [{
        text: 'Got it',
        onActivate: onCancel,
        componentType: SolidButton,
      }]
    }
  }

  const ScratchTicketModalComponent = () => {
    const scratchTicket = async (id: string) => {
      for (let i = 0; i < rewardsList.length; i++) {
        if (rewardsList[i].id === id) {
          const data: any = { rewardArray: [rewardsList[i].id] }
          await scratchCard(httpClient)({}, data)
          rewardsList[i].scratched = true
        }
      }
      setRewardsList([...rewardsList])
    }

    return (
      <ScratchModalWrap>
        <ScratchHeaderWrap>
          <ScratchHeaderLeft className='left' />
          <FlexCol className='head'>
            <Heading>{showCards ? 'Congratulations!' : 'Oops! You’re out of Equa'}</Heading>
            <Heading>{showCards ? 'You’ve earned Equa Cash' : 'Scratch-Off Tickets'}</Heading>
          </FlexCol>
          <ScratchHeaderRight className='right' />
        </ScratchHeaderWrap>
        <div className='body'>
          <SubHeading>{showCards ?
            mobileView ?
              'You can use your mouse to scratch-off the gold, or click on the button to redeem your Equa Cash reward.'
              :
              <>
                <div>You can use your mouse to scratch-off the gold,</div>
                <div>or click on the button to redeem your Equa Cash reward.</div>
              </>
            :
            !mobileView ? <>
              <div>Simply invite others to join the Equa app with either your
                {' '}<PathLink path={referralsPath} args={{}} target='_blank'>Profile</PathLink>
                {' '}or{' '}
                {organization ? <PathLink path={organizationReferralsPath} args={{ organization }} target='_blank'>Org Referral</PathLink>
                  : 'Org Referral'}
                {' '}links,
              </div>
              <div>and you’ll both earn a scratch-off ticket when they join.</div>
              <div>Complete tasks for your org, and earn more too!</div>
            </>
              : <>
                Simply invite others to join the Equa app with either your
                {' '}<PathLink path={referralsPath} args={{}} target='_blank'>Profile</PathLink>
                {' '}or{' '}
                <PathLink path={organizationReferralsPath} args={{ organization }} target='_blank'>Org Referral</PathLink>
                {' '}Links,
                and you’ll both earn a scratch-off ticket when they join. Complete tasks for your org, and earn more too!
              </>
          }</SubHeading>
          {/* <SubHeading>{scratchedCards.length} / {notScratchedCards.length}</SubHeading> */}
          <ScratchCardList
            rewards={rewardsList}
            wasScratchAllClicked={wasScratchAllClicked}
            scratchTicket={scratchTicket}
          />
        </div>
      </ScratchModalWrap>
    )
  }

  return newBasicModalBundle({
    title: '',
    body: <ScratchTicketModalComponent />,
    buttons: getButtons(),
    max: '900px',
    align: 'center',
    noClose: true,
    noCancel: true,
  })
}
