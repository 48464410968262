import * as React from 'react'
import {
    additionalLegendOptions,
    capitalizeTextInputField,
    FieldGroup,
    nonZeroCurrencyField,
    renderFieldsSimple,
    renderHeader,
    selectFieldComponent,
    textInputFieldComponent,
    UploadHandler,
} from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers/field-validators'
import { ExpandedLegend, ExpandedSecurity, FormPlan } from '@src/service'
import { LightDivider } from '@src/styles'
import { formatSecurityName, legendsToSelectOptions, optionalCommaNumber } from '@shared/helpers'
import { organizationCreateLegendPath } from '@logic'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'
import { QuestionSpan, NoteSpan } from './plan-form'
import { IncentiveTypes } from '@shared/helpers/constants'

interface Props {
    values?: any
    onUpload: UploadHandler
    securities: ExpandedSecurity[]
    legends: ExpandedLegend[]
    params: any
}

function mainFields(securities: ExpandedSecurity[], params: any, legendOptions: ExpandedLegend[]): FieldGroup<FormPlan> {
    const securitiesOptions = securities.map(security => ({
        label: formatSecurityName(security),
        value: security.hash,
        info: `- ${optionalCommaNumber(security.stats.treasury)} Available`
    }))
    const incentiveOptions = [
        { label: 'Award', value: IncentiveTypes.award },
        { label: 'Option', value: IncentiveTypes.option },
        { label: 'Warrant', value: IncentiveTypes.warrant },
        { label: 'Other', value: IncentiveTypes.other },
    ]

    return [
        {
            name: 'type',
            label: 'Type',
            required: true,
            validate: emptyValidator,
            component: selectFieldComponent(incentiveOptions, 'Incentive Type')
        },
        {
            name: 'name',
            label: 'Name',
            required: true,
            validate: emptyValidator,
            placeholder: 'Enter name of incentive',
            component: textInputFieldComponent,
        },
        {
            name: 'abbreviation',
            label: 'Abbreviation',
            required: true,
            validate: emptyValidator,
            placeholder: 'Enter abbreviation',
            component: capitalizeTextInputField,
            note: 'This is the prefix of your holding’s certificate number.'
        },
        {
            name: 'approvedEquity',
            label: 'Approved Equity',
            required: true,
            validate: emptyValidator,
            component: selectFieldComponent(securitiesOptions, 'Approved Equities')
        },
        {
            name: 'pricePerUnit',
            label: 'Strike Price / Price Per Share',
            required: false,
            placeholder: 'Enter price in USD',
            ...nonZeroCurrencyField,
        },
        {
            name: 'legend',
            label: 'Legend',
            required: false,
            validate: emptyValidator,
            // defaultValue: legend!,
            component: selectFieldComponent(
                legendsToSelectOptions(legendOptions),
                'Legend',
                { text: '+ Add New Legend', to: organizationCreateLegendPath, args: params }
            ),
        },
    ]
}

export const IncentiveForm = (props: Props) => {
    const { onUpload, securities, params, values, legends } = props
    const additionalLegends = additionalLegendOptions(legends, values?.legend!)
    const legendOptions = legends?.filter(l => !l.deleted)
        .concat(additionalLegends as any)

    return (
        <>
            <QuestionSpan>What are incentives?</QuestionSpan>
            <NoteSpan>Incentives are any compensation arrangement and can be a form of equity compensation usually offered to a company’s employees and executives.
                Enter the details of your incentives here.
                Once your incentive is complete, you will add it to the pools page.
            </NoteSpan>
            {renderHeader({ label: 'Incentive Details' })}
            {renderFieldsSimple(mainFields(securities, params, legendOptions))}
            <DocumentsAndNotes onUpload={onUpload} values={values} noInitial={true} />
        </>
    )
}
