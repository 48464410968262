import * as React from 'react'
import { Access, guestPortfolioPath, login, LoginDispatch, NewUserRequest, PageProps, Paths, register, RegisterDispatch, User } from '@logic'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getQueryParamEmail } from '../utility'
import { useEffect, useState } from 'react'
import { getQueryParams } from '@helpers/util'
import arrayMutators from 'final-form-arrays'
import { CommonForm } from '@shared/components/forms'

// @ts-ignore
import { loadReCaptcha } from 'react-recaptcha-google'
import { HeaderLink, StyledAuth, StyledAuthForm, StyledHeader } from '../style'
import Carousel from '../components/carousel'
import { StateTransform } from '@src/index'
import { SignUpForm } from '../components/sign-up-form'
import { matchesPath, PathLink } from '@shared/components/navigation'
import { FlexCol } from '@src/styles'

interface Props extends RouteComponentProps<any> {
  register: RegisterDispatch
  login: LoginDispatch
}

interface StateProps {
  access?: Access
  user?: User
}

const RegisterComponent = (props: Props & StateProps & PageProps) => {
  const { history, login, match, navigate } = props
  const [verified, setVerified] = useState(false)
  const initialValues = {
    email: getQueryParamEmail(),
    password: '',
    acceptedTerms: false,
  }


  const onSubmit = async (data: any) => {
    const query = getQueryParams()
    const withInvite = matchesPath(Paths.invitedSignup, window.location.pathname, false)
    const recipientByEmail = withInvite ? data.email : undefined
    const recipientByLink = !withInvite ? data.email : undefined
    const referral = query.organization ? {
      user: query.user,
      organization: query.organization,
      recipientByEmail,
      recipientByLink,
    } : query.user ? {
      referralLink: query.user,
      recipientByEmail,
      recipientByLink,
    } : {}

    const registerData = {
      ...data,
      profile: { fullName: `${data.first} ${data.last}` },
      referral,
      coupon: query.coupon || query.utm_coupon,
      metadata: query,
    }
    await props.register(registerData, async (data: any) => {
      if (!data.error) {
        const loginData = {
          usernameOrEmail: registerData.email,
          password: registerData.password,
        }
        await login(loginData)
        navigate(Paths.verify)
      }
    })
  }

  useEffect(() => {
    loadReCaptcha()
  }, [])

  const isRegister = match.path === Paths.userRegister || match.path === Paths.invitedSignup || match.path === Paths.refSignup
  const queryParams = getQueryParams()

  return (
    <StyledAuth>
      <StyledAuthForm>
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          stretched={true} noPrompt={true}>
          <SignUpForm verified={verified} setVerified={setVerified} />
        </CommonForm>
        <FlexCol className='LoginSignupLink'>
          <span>
            Already have an account?
            <PathLink path={Paths.userLogin} args={queryParams}>
              {' '}Log In
            </PathLink>
          </span>
          <span>or</span>
          <PathLink path={guestPortfolioPath} args={{}}>Try as a guest</PathLink>
        </FlexCol>
      </StyledAuthForm>
    </StyledAuth>
  )
}

const mapStateToProps: StateTransform<StateProps> = s => ({ user: s.user })

export const Register = connect(
  mapStateToProps,
  {
    register,
    login,
  }
)(withRouter(RegisterComponent as any))
