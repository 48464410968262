import * as React from 'react'
import styled from 'styled-components'

interface Props {
  percentage: any
  margin?: string
  fullHeight?: boolean
}

const StyledProgressBar = styled.div<any>`
  position: relative;
  width: 100%;
  margin: ${props => props.margin};
`

const Line = styled.div<any>`
  background: ${props => `linear-gradient(to right, ${props.theme.accent} ${props.left}%, ${props.theme.primary} ${props.left}%)`};
  height: ${props => props.fullHeight ? '24px' : '10px'};
  border-radius: ${props => props.theme.radius};
`

function ProgressBar(props: Props) {
  const { percentage, margin, fullHeight } = props
  return (
    <StyledProgressBar margin={margin}>
      <Line left={percentage} fullHeight={fullHeight} />
    </StyledProgressBar>
  )
}

export { ProgressBar }
