import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { MembersProps, PageProps, planPath, plansPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { PlanForm } from '../components/plan-form'
import {
  FilesResponse,
  FormPlan,
  postFile,
  editPlan,
  GetSecuritiesResponse,
  GetAuthorizationsResponse,
  GetPlanResponse,
  createAuthorization,
  NewAuthorizationRequest
} from '@src/service'
import { getQueryParams, loadAuthorizations, loadMembers, loadPlan, loadSecurities } from '@shared/helpers'
import { BaseLinkProps } from '@shared/components/navigation'
import { filterEquities } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & PermissionProps & GetSecuritiesResponse & GetAuthorizationsResponse & GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(loadPlan, loadSecurities('true'), loadAuthorizations('plan'))

export const EditPlan = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, plan, securities, stats, authorizations } = props
    const { organization } = params
    const currentPlan = plan!!
    const {
      hash,
      name,
      boardApprovalDate,
      boardApprovalDocument,
      incentivePlanDocument,
      termYears,
      approvedEquities } = currentPlan
    const securitiesList = filterEquities((stats || []), securities)
    const filteredEquities = securitiesList?.filter(s => !s.parent)

    const documentsAndNotes = authorizations && authorizations.length > 0 ?
      authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })

    const initialValues = {
      name,
      boardApprovalDate,
      boardApprovalDocument: boardApprovalDocument ?
        { name: boardApprovalDocument.filename, id: boardApprovalDocument.id } : undefined,
      incentivePlanDocument: incentivePlanDocument ?
        { name: incentivePlanDocument.filename, id: incentivePlanDocument.id } : undefined,
      termYears,
      approvedEquities: approvedEquities?.equity,
      approvalDocument: documents,
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id,
      }
    }

    const onSubmit = async (values: FormPlan) => {
      const { boardApprovalDocument, incentivePlanDocument } = values
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvedEquities = {
        equity: values.approvedEquities
      }
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const request: any = {
        newPlan: {
          name: values?.name,
          boardApprovalDate: values.boardApprovalDate,
          approvedEquities,
          createdBy: currentPlan?.createdBy!,
          termYears: Number(values.termYears),
          boardApprovalDocument: boardApprovalDocument ? boardApprovalDocument.id : undefined,
          incentivePlanDocument: incentivePlanDocument ? incentivePlanDocument.id : undefined,
        },
      }
      const edit = await editPlan(httpClient)({ plan: hash }, request)
      if (edit?.plan) {
        modifyAuthorizations({ approvalDocuments, target: edit.plan.hash, httpClient, organization })
        const parent = getQueryParams().parent
        if (parent) {
          navigate(planPath, { organization, plan: edit.plan.hash })
        } else {
          navigate(plansPath, { organization })
        }
      }
      else console.log('edit Plan error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: planPath, args: { organization, plan: currentPlan?.hash } }
    return (
      <PageContent>
        <PageContentHeader title={`Edit ${name}`} parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <PlanForm onUpload={onUpload} securities={filteredEquities} params={params} edit={true} />
        </CommonForm>
      </PageContent>
    )
  })
)
