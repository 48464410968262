import * as React from 'react'
import { PageProps, billingPath, PaymentProfilesResponse } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import styled from 'styled-components'
import { CommonForm } from '@components/forms'
import { BaseLinkProps } from '@shared/components/navigation'
import arrayMutators from 'final-form-arrays'
import { CardForm } from '../components/card-form'
import { editPaymentProfile } from '@src/service'
import { loadPaymentProfiles } from '@shared/helpers'

type Props = PageProps & PaymentProfilesResponse

const withData = withLoadingCachedMultiple<Props>(loadPaymentProfiles)

const NewPaymentMethodWrap = styled.div`
    width: 550px;
`

export const EditPaymentProfilePage = withData(
    (props: Props) => {
        const [isCard, setIsCard] = React.useState<boolean>(true)
        const { httpClient, navigate, params, paymentProfiles } = props
        const { organization, profile } = params
        const currentProfile = paymentProfiles.filter(arg => arg.payment_profile.id === parseInt(profile))[0]
        const {
            billing_address,
            billing_address_2,
            billing_city,
            billing_country,
            billing_state,
            billing_zip,
            first_name,
            last_name } = currentProfile.payment_profile
        const initialValues = {
            country: billing_country,
            street1: billing_address,
            street2: billing_address_2,
            province: billing_state,
            city: billing_city,
            postalCode: billing_zip,
            firstName: first_name,
            lastName: last_name
        }

        const onSubmit = async (values: any) => {
            const billingAddress = {
                street1: values.street1,
                street2: values.street2,
                country: values.country,
                city: values.city,
                postalCode: values.postalCode,
                province: values.province,
            }

            const fullNumber = values.fullNumber.replace(/\u2000/g, '').trim()

            const creditCard = {
                firstName: values.firstName || undefined,
                lastName: values.lastName || undefined,
                fullNumber,
                expirationMonth: parseInt(values.expiration.split('/')[0]),
                expirationYear: parseInt(values.expiration.split('/')[1]),
                cvv: values.cvv || undefined
            }

            const editedProfile = {
                profile,
                organization,
                billingAddress,
                creditCard,
            }

            const editProfile = await editPaymentProfile(httpClient)({ entity: organization, profile }, editedProfile)
            if (editProfile?.payment_profile) navigate(billingPath, { organization })
            else console.log('create Security error') // TODO: add error message toast
        }

        const parent: BaseLinkProps = { path: billingPath, args: { organization } }

        return (
            <PageContent>
                <PageContentHeader title="Edit Payment Method" parent={parent} />
                <NewPaymentMethodWrap>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                        submitText="Save">
                        <CardForm />
                    </CommonForm>
                </NewPaymentMethodWrap>
            </PageContent>
        )
    }
)
