import { CompanyType } from '@helpers/constants';
import { ShareType } from '@logic';
import { removeMask, Uuid } from '@shared/helpers';
import { HoldingRecordType } from '@shared/helpers/holdings';

export const companyShareTypeOptions = {
  [CompanyType.LLC]: [
    {
      value: ShareType.units,
      label: 'Units',
    },
  ],
  [CompanyType.SCorpOrCCorp]: [
    {
      value: ShareType.common,
      label: 'Common',
    },
    {
      value: ShareType.preferred,
      label: 'Preferred',
    },
  ],
  [CompanyType.Other]: [],
};

export enum CapitalContributionType {
  cashPaid = '58988c54-699f-418a-b280-e1854ff1e3b5',
  debtCanceled = '2d2d931f-f2a1-49a2-a026-fe44f85abfda',
  valueOfIp = 'f8b74fe1-a7b4-46ac-89f3-7b8d75be5fde',
  other = 'f7aa0663-7f0c-44b1-b40a-7439c908dee0',
}

export enum CancelReasonTypes {
  error = '3c48b22e-8b8b-41ec-9160-682225f24608',
  transfer = '18d84889-1c7d-4ad3-afd0-3058f9003520',
  buyBack = 'ee918480-9f85-4c0a-8f79-8b6a6e87793e',
  other = '075e9efc-33f3-4f8b-8d80-e81ee74651f1',
}

export function formatToNumber(value: any) {
  if (value) {
    const stack = (new Error()).stack
    if (stack?.includes('onBlur') || stack?.includes('beforeSubmit')) {
      const formattedValue = typeof value === 'string' ? parseFloat(value.replace(/,/g, '')) : parseFloat(value)
      return formattedValue;
    }
    else {
      return value
    }
  } else return undefined
}

export function formatToNumberAndDecimals(value: any) {
  const stack = (new Error()).stack
  if (stack?.includes('onBlur') || stack?.includes('beforeSubmit')) {
    const formattedValue = typeof value === 'string' ? parseFloat(removeMask(value) as any) : value
    return formattedValue;
  } else {
    return value
  }
}

export const isHolding = (holdingType?: Uuid) => {
  if (holdingType) {
    return holdingType !== HoldingRecordType.draft &&
      holdingType !== HoldingRecordType.purchased && holdingType !== HoldingRecordType.converted
  }
  else return false
}
