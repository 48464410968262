import * as React from 'react'
import { PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadWalletAddresses, loadSupportedProtocols } from '@helpers/loaders'
import { boldStyle, FlexRow, FlexCol, semiBoldStyle, whiteColor, colors } from '@src/styles'
import styled from 'styled-components'
import { WalletPortfolioComponent } from '../components/wallet-port'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>(loadWalletAddresses, loadSupportedProtocols)

export const BetaNotify = styled.span<any>`
  ${boldStyle}
  background: ${colors.white};
  color: ${({ theme }) => theme.accent} !important;
  font-size: 12px;
  line-height: 20px;
  border-radius: 4px;
  padding: 3px 7px;
  margin: 0px 12px;
  display: inline-block;
  text-transform: uppercase;
`

export const AddressBookBtn = styled<any>(FlexRow)`
    min-width: 48px;
    height: 48px;
    margin: 0 0 0 24px;
    border-radius: 4px;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background-color: ${({ theme }) => theme.actionsDrop.default};
    &:hover {
        background-color: ${({ theme }) => theme.dock.hover};
        div {
            display: flex;
        }
        .textHover {
            display: flex;
        }
    }
    svg {
        path {
            fill: ${props => props.theme.whiteIcon};
        }
    }
    .textHover {
        display: none;
    }
`

export const Description = styled.span<any>`
    font-weight: 600;
    font-family: 'NunitoSansSemiBold';
    color: ${({ theme }) => theme.white};
    text-align: center;
    font-size: 20px;
    line-height: 28px;
`

export const EmptyWalletWrap = styled<any>(FlexCol)`
    margin-top: 48px;
`

export const EnsName = styled.span<any>`
    ${semiBoldStyle}
    font-size: 32px;
    line-height: 38px;
    display: block;
`

export const AddressValue = styled.span<any>`
    line-height: 24px;
    display: block;
    color: #9CA6AD;
    word-break: break-all;
`

export const PortofolioWrap = styled.div<any>`
    ${whiteColor}
`

export const SaveButtonWrap = styled<any>(AddressBookBtn)`
    min-width: 32px;
    height: 32px;
    background: transparent;
    margin-left: 8px;
    position: relative;
    :hover {
        background: #283840;
        div {
            display: flex;
        }
    }
    div {
        display: none;
        top: -32px;
    }
`

export const WalletPortfolio = withData((props: Props) => {
    return <WalletPortfolioComponent {...props} />
})
