import * as React from 'react'
import { PageProps, organizationLegendsPath, setExpanded } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { LegendForm } from '../components/legend-form'
import { createLegend, NewLegendRequest } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'
import { RouteComponentProps } from 'react-router'
import { connect } from 'react-redux'

interface StoreProps extends RouteComponentProps<any> {
  setExpanded: any
}

type Props = PageProps & StoreProps

const withData = withLoadingCachedMultiple<Props>()

const CreateLegendComponent = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, setExpanded } = props
    const { organization } = params
    const initialValues = {}

    const onSubmit = async (values: NewLegendRequest) => {
      const { name, content } = values
      const request = {
        name,
        content
      }

      const response = await createLegend(httpClient)({ organization }, request)
      if (response?.legend.hash) {
        setExpanded()
        navigate(organizationLegendsPath, { organization })
      }
      else console.log('edit Organization error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationLegendsPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Create A New Legend" parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Create">
          <LegendForm />
        </CommonForm>
      </PageContent>
    )
  }
  ))

export const CreateLegendPage = connect(null, { setExpanded })(CreateLegendComponent)
