import * as React from 'react'
import { certificateVestingSchedulePath, PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding, } from '@helpers/loaders'
import { ExpandedHoldingResponse } from '@src/service'
import { ViewVestingPage } from '@modules/esop'
import { EmptyLanding } from '@shared/components/empty-landing'
import { PageContent } from '@shared/components/pages'

type Props = PageProps & ExpandedHoldingResponse

const withData = withLoadingCachedMultiple<Props>(loadHolding)

export const ViewVestingDetailsPage = withData(
    (props: Props) => {
        const { params, holding } = props
        const args: any = { ...params, vesting: holding?.vestingSchedule?.hash, plan: holding?.plan }
        const pageProps = { ...props, fromCertificate: true }
        return holding?.vestingSchedule ? (
            <ViewVestingPage {...pageProps} params={args} holding={holding} />
        )
            :
            <PageContent>
                <EmptyLanding path={certificateVestingSchedulePath} params={params} />
            </PageContent>
    }
)
