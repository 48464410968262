import { Member, ShareType, ShareholdingResponse } from '@logic'
import { genericSum, Uuid } from '@helpers/util'
import { ExpandedHolding, ExpandedSecurity } from 'service'
import { getInterestFromConvertible, getMaxShares, getOrganizationTotalUnits } from '@modules/convertibles/utility'

export interface Totals {
  all: number
  capitalContribution: number
  exercised: number
  securityTypes: { [key: string]: number }
}

export interface HoldingTotals {
  all: number
  capitalContribution: number,
  exercised: number,
  securityTypes: { [key: string]: number }
  sumSecurity?: number
}

export interface Shareholder {
  id: Uuid
  name: string
  email?: string
  shareholdings: ExpandedHolding[]
  voided: ExpandedHolding[]
  totals: Totals
  holdings?: ExpandedHolding[],
  voidedHolding?: ExpandedHolding[],
  holdingTotals?: HoldingTotals
}

export interface ShareholdersProps {
  shareholders: Shareholder[]
}

export interface TotalsProps {
  totals: Totals
}

export const isCommon = (shareholding: ShareholdingResponse) => shareholding.shareType == ShareType.common
export const isPreferred = (shareholding: ShareholdingResponse) => shareholding.shareType == ShareType.preferred
export const isUnits = (shareholding: ShareholdingResponse) => shareholding.shareType == ShareType.units
export const isExercised = (shareholding: ShareholdingResponse) => shareholding.exercisedShares
export const sumShareholdings = genericSum<ShareholdingResponse>(s => s?.shares || s?.value!)
export const sumCapitalContributions = genericSum<ShareholdingResponse>(s => s?.capitalContribution || 0)
export const sumHoldings = genericSum<ExpandedHolding>(s => s?.value)
export const sumHoldingCapital = genericSum<ExpandedHolding>(s => s?.capitalContribution!)

export function getSecurityTypeTotals(shareholdings: ShareholdingResponse[]): { [key: string]: number } {
  let result: any = {}
  for (const shareholding of shareholdings) {
    const { equity } = shareholding
    if (equity && !shareholding.voided) {
      result[equity] = (result[equity] || 0) + shareholding.value
    }
  }
  return result
}
export function getHoldingSecurityTypeTotals(holdings: ExpandedHolding[]): { [key: string]: number } {
  let result: any = {}
  for (const holding of holdings) {
    const { equity } = holding
    if (equity) {
      result[equity] = (result[equity] || 0) + holding.value
    }
  }
  return result
}
export function getTotals(shareholdings: ShareholdingResponse[]): Totals {
  return {
    all: sumShareholdings(shareholdings),
    capitalContribution: sumCapitalContributions(shareholdings),
    exercised: sumShareholdings(shareholdings.filter(isExercised)),
    securityTypes: getSecurityTypeTotals(shareholdings)
  }
}

export function getHoldingTotals(holdings: ExpandedHolding[], securities: ExpandedSecurity[]): HoldingTotals {
  const list = holdings.filter(el => {
    const parent = holdings.find(s => s.id === el.parent)
    if ((parent?.convertibleInstrument || parent?.plan) && securities?.some(s => parent?.parent! === s.hash)) return el
  })
    .map(holding => {
      const { parent } = holding
      const parentHolding = holdings?.find(el => el.id === parent && el.convertibleInstrument)
      if (parentHolding) {
        const { valuationMin, conversionDiscount } = parentHolding?.convertibleInstrument
        const { issueDate, capitalContribution } = holding
        const interest = getInterestFromConvertible(parentHolding?.convertibleInstrument!, issueDate, capitalContribution)
        const projectedPrinciple = capitalContribution! + interest
        const totalUnits = getOrganizationTotalUnits(securities)
        const maxUnits = getMaxShares({
          maxUsd: projectedPrinciple,
          valuationMin,
          totalUnits,
          discount: conversionDiscount
        })
        return { ...holding, value: maxUnits }
      }
      else {
        const securityType = holdings?.find(el => el.id === parent)?.parent!
        if (securityType) {
          return { ...holding, value: holding.value }
        }
      }
    })

  const securityTypes = getHoldingSecurityTypeTotals(list, holdings)
  let sumSecurity = 0
  for (const key of Object.keys(securityTypes)) {
    sumSecurity += securityTypes[key];
  }
  return {
    all: sumHoldings(list),
    capitalContribution: sumHoldingCapital(list),
    exercised: sumShareholdings(list.filter(isExercised) as any),
    securityTypes,
    sumSecurity,
  }
}

export function getNotesTotals(holdings: ExpandedHolding[], allHoldings?: ExpandedHolding[]): HoldingTotals {
  return {
    all: sumHoldings(holdings),
    capitalContribution: sumHoldingCapital(holdings),
    exercised: sumShareholdings(holdings.filter(isExercised) as any),
    securityTypes: getHoldingSecurityTypeTotals(holdings, allHoldings)
  }
}

export const newShareholder = (
  holdings: ExpandedHolding[],
  voidedHoldings?: ExpandedHolding[],
  securities?: ExpandedSecurity[]
) => (member: Member): Shareholder => {
  const filterAndSort = (toSortObject: ExpandedHolding[]) => {
    return toSortObject && toSortObject.filter(s => s.owner == member.id)
      .sort((a, b) => a.issueDate && b.issueDate && a.issueDate.toString().localeCompare(b.issueDate.toString()))
  }
  const holdingsData = holdings?.filter(el => {
    const parent = holdings?.find(s => (s.id === el.parent) || (s.hash === el.parent))
    if ((parent?.convertibleInstrument || parent?.plan) && securities?.some(s => parent?.parent! === s.hash)) return el
  })
  const voidedH = voidedHoldings?.filter(el => {
    const parent = holdings?.find(s => (s.id === el.parent) || (s.hash === el.parent))
    if ((parent?.convertibleInstrument || parent?.plan) && securities?.some(s => parent?.parent! === s.hash)) return el
  })
  const shareholdingsData = holdings?.filter(h => h.securityDetails && !h.convertibleInstrument && !h.plan)
  const voidedS = voidedHoldings?.filter(h => h.securityDetails && !h.convertibleInstrument && !h.plan)
  const shareholdings = filterAndSort(shareholdingsData)
    .map(el => {
      const security = securities?.find(s => s.hash === el.securityDetails)
      return { ...el, security, shares: el.value }
    })
  const voided = voidedS?.length > 0 ? filterAndSort(voidedS)
    .map(el => {
      const security = securities?.find(s => s.hash === el.securityDetails)
      return { ...el, security, shares: el.value }
    }) : []

  const voidedHolding = voidedH && voidedH.length > 0 ? filterAndSort(voidedH)
    .map(holding => {
      const { parent } = holding
      const parentHolding = holdings?.find(el => el.id === parent && el.convertibleInstrument)
      if (parentHolding) {
        const { valuationMin, conversionDiscount } = parentHolding?.convertibleInstrument
        const { issueDate, capitalContribution } = holding
        const interest = getInterestFromConvertible(parentHolding?.convertibleInstrument!, issueDate, capitalContribution)
        const projectedPrinciple = capitalContribution! + interest
        const totalUnits = securities && getOrganizationTotalUnits(securities)
        const maxUnits = getMaxShares({
          maxUsd: projectedPrinciple,
          valuationMin,
          totalUnits: totalUnits!,
          discount: conversionDiscount
        })
        return { ...holding, value: maxUnits }
      }
      else {
        const securityType = holdings?.find(el => el.id === parent)?.parent!
        if (securityType) {
          return { ...holding, value: holding.value }
        }
      }
    }) : []

  const holdingsList = holdingsData && holdingsData.length > 0 ? filterAndSort(holdingsData)
    .map(holding => {
      const { parent } = holding
      const parentHolding = holdings?.find(el => el.id === parent && el.convertibleInstrument)
      if (parentHolding) {
        const { valuationMin, conversionDiscount } = parentHolding?.convertibleInstrument
        const { issueDate, capitalContribution } = holding
        const interest = getInterestFromConvertible(parentHolding?.convertibleInstrument!, issueDate, capitalContribution)
        const projectedPrinciple = capitalContribution! + interest
        const totalUnits = securities && getOrganizationTotalUnits(securities)
        const maxUnits = getMaxShares({
          maxUsd: projectedPrinciple,
          valuationMin,
          totalUnits: totalUnits!,
          discount: conversionDiscount
        })
        return { ...holding, value: maxUnits }
      }
      else {
        const securityType = holdings?.find(el => el.id === parent)?.parent!
        if (securityType) {
          return { ...holding, value: holding.value }
        }
      }
    }) : []
  return {
    id: member.id,
    name: member.fullName,
    email: member.email || '',
    shareholdings,
    voided,
    totals: getTotals(shareholdings),
    holdings: holdingsList as any,
    voidedHolding: voidedHolding! as any,
    holdingTotals: getNotesTotals(holdingsList as any, holdings),
  }
}

export function groupHoldingsByMember(
  members: Member[],
  holdings: ExpandedHolding[],
  voidedHoldings?: ExpandedHolding[],
  securities?: ExpandedSecurity[]
): Shareholder[] {
  return members?.map(newShareholder(holdings, voidedHoldings, securities))
    .filter(b => b.shareholdings?.length! > 0 || b.voided?.length! > 0 || b.holdings?.length! > 0 || b.voidedHolding?.length! > 0)
}

export function byOutstandingDescending(a: any, b: any) {
  return a.memberOutstanding > b.memberOutstanding ? -1 : 1
}

export const formatCurrency = (currency: number): string => {
  return '$' + currency.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const shareTypeSymbols = {
  [ShareType.common]: 'C',
  [ShareType.preferred]: 'P',
  [ShareType.units]: 'U',
  [ShareType.convertibleNotes]: 'CN',
}

export const shareTypeNames = {
  [ShareType.common]: 'Common',
  [ShareType.preferred]: 'Preferred',
  [ShareType.units]: 'Unit',
  [ShareType.convertibleNotes]: 'Convertible Note',
}

export interface SecurityName {
  shareClass: string
  shareClassPrefix?: string
  shareType: ShareType
}

export function getInitial(value: string | undefined): string {
  return value && value.length > 0 ? value[0].toUpperCase() : ''
}

export function formatSecurityAbbreviation(security: SecurityName): string {
  const abbreviation = security.shareClassPrefix
  if (abbreviation && abbreviation.length > 0) {
    return abbreviation
  }
  const first = getInitial(shareTypeNames[security.shareType])
  const second = getInitial(security.shareClass)
  return `${first}${second}`
}

export function formatSecurityName(security: SecurityName): string {
  return `${shareTypeNames[security.shareType] || ''} ${security.shareClass}`
}

// export function certificateNumber(shareType: ShareType, shareClass: string, serialNumber: number): string {
//   return `${shareTypeSymbols[shareType]}${shareClass}-${serialNumber}`
// }

// export function certificateNumberFromShareholding(shareholding: ShareholdingResponse, security: ExpandedSecurity): string {
//   return certificateNumber(security?.shareType!, security?.shareClass!, shareholding?.serialNumber!)
// }
