import * as React from 'react'
import { colors, FlexCol, FlexRow, Theme } from '@src/styles'
import {
    certificateVestingSchedulePath,
    convertibleInstrumentsPath,
    newConvertibleInstrumentPath,
    newOptionPath,
    newPlanIncentivePath,
    newPlanPath,
    newPoolPath,
    newVestingPath,
    optionsPath,
    organizationCreateLegendPath,
    organizationLegendsPath,
    organizationNewSecurityPath,
    organizationSecuritiesPath,
    planDocumentsPath,
    planIncentivesPath,
    planPath,
    poolsPath,
    vestingsPath
} from '@logic'
import EmptyConvertible from '@image/empty-convertible.svg'
import EmptyEquities from '@image/empty-equities.svg'
import EmptyLegends from '@image/empty-legends.svg'
import EmptyPlan from '@image/empty-plan.svg'
import EmptyPools from '@image/empty-pools.svg'
import EmptyVesting from '@image/empty-vesting.svg'
import EmptyHoldings from '@image/empty-holdings.svg'
import EmptyDocuments from '@image/empty-documents.svg'
import Plus from '@image/plus-16x16.svg'
import styled, { css, ThemeProps } from 'styled-components'
import { PathLinkButton } from './button'
import { Breadcrumbs } from './breadcrumbs'

interface LandingProps {
    path: string
    params: { [key: string]: string }
}

interface PageDataBundle {
    title?: string
    logo: any
    target?: string
    description?: string
    description1?: string
    buttonText?: string
}

const getPageData = (path: string): PageDataBundle => {
    switch (path) {
        case convertibleInstrumentsPath:
            return {
                title: 'Convertible Instruments',
                logo: EmptyConvertible,
                target: newConvertibleInstrumentPath,
                description: 'This is where you will create convertible instruments like converible notes, SAFEs, and more!',
                description1: 'Once created, you can issue to your holders.',
                buttonText: 'Create A Convertible'
            }
        case organizationSecuritiesPath:
            return {
                title: 'Equities',
                logo: EmptyEquities,
                target: organizationNewSecurityPath,
                description: 'This is where you will create equities to be used throughout the Equa platform.',
                description1: 'Once created, you will be able to assign them to certain assets.',
                buttonText: 'Create Equity'
            }
        case planPath:
            return {
                title: 'Incentive Plans',
                logo: EmptyPlan,
                target: newPlanPath,
                description: 'To get started, create your incentive plan first.',
                description1: 'Then, you’ll add your incentive, pools, vesting schedules, and holdings.',
                buttonText: 'Create A Plan'
            }
        case planIncentivesPath:
            return {
                title: 'Incentives',
                logo: EmptyPlan,
                target: newPlanIncentivePath,
                description: `Create your plan’s incentives here.`,
                description1: 'You will use these later on when you create your pools and holdings.',
                buttonText: 'Create Incentives'
            }
        case poolsPath:
            return {
                title: 'Pools',
                logo: EmptyPools,
                target: newPoolPath,
                description: 'Create your pools for your plan here.',
                description1: 'You will use these pools to assign to your holdings, so you want to create these first.',
                buttonText: 'Create Pools'
            }
        case vestingsPath:
            return {
                title: 'Vesting Schedules',
                logo: EmptyVesting,
                target: newVestingPath,
                description: 'Create your vesting schedules for your incentive plan here.',
                description1: 'You will then assign these to your holders on the next step!',
                buttonText: 'Create Vesting Schedules'
            }
        case optionsPath:
            return {
                title: 'Holdings',
                logo: EmptyHoldings,
                target: newOptionPath,
                description: 'Create your holdings for your plan here.',
                description1: 'You will be able to assign incentives, pools, and vesting schedules on this step.',
                buttonText: 'Create Holdings'
            }
        case planDocumentsPath:
            return {
                logo: EmptyDocuments,
                description: 'As you add Documents & Notes to the specific previous tabs,',
                description1: 'they will show up on this tab for a quick view.',
            }
        case organizationLegendsPath:
            return {
                title: 'Legends',
                logo: EmptyLegends,
                target: organizationCreateLegendPath,
                description: 'This is where you will create legends to be used throughout the Equa platform.',
                description1: 'These will be shown on the back of your digital certificates.',
                buttonText: 'Create Legend'
            }
        case certificateVestingSchedulePath:
            return {
                logo: EmptyVesting,
                description: 'This certificate doesn’t have any vesting schedules assigned.',
            }
        default:
            return { title: '', logo: '', target: '', description: '', description1: '' }
    }
}

const sharedStyle = css<ThemeProps<Theme>>`
    font-weight: 600;
    font-family: 'NunitoSansSemiBold';
    color: ${({ theme }) => theme.white};
    text-align: center;
`

const EmptyLandingWrap = styled<any>(FlexCol)`
    // margin-top: 40px;
    .plus {
        margin-right: 8px;
        path {
            fill: ${colors.white};
        }
    }
`

const HeaderText = styled.span<any>`
    ${sharedStyle}
    margin: 8px 0px;
`

const LandingTitle = styled.span<any>`
    ${sharedStyle}
    font-size: 34px;
    line-height: 46px;
    margin: 8px 0px 32px 0px;
`

const Description = styled.span<any>`
    ${sharedStyle}
    font-size: 20px;
    line-height: 28px;
`


export const EmptyLanding = (props: LandingProps) => {
    const { path, params } = props
    const data = getPageData(path)
    const Logo = data.logo
    return <>
        {/* <Breadcrumbs /> */}
        <EmptyLandingWrap>
            {data.title ? <>
                <HeaderText>Create Your</HeaderText>
                <LandingTitle>{data.title}</LandingTitle>
            </>
                : null
            }
            <FlexRow style={{ padding: '48px' }}>
                <Logo />
            </FlexRow>
            <FlexCol style={{ margin: '32px 0px' }}>
                {data.description ? <Description>{data.description}</Description> : null}
                {data.description1 ? <Description>{data.description1}</Description> : null}
            </FlexCol>
            {data.buttonText ? <PathLinkButton
                path={data.target || ''}
                args={params}
            >
                <Plus className='plus' /> {data.buttonText}
            </PathLinkButton> : null
            }
        </EmptyLandingWrap>
    </>
}