import { Hash, Uuid } from '@helpers/util'
import {
  HttpClient,
  transferAccessDeniedResponse,
  TransferRequest,
  WebResponse,
  accessDeniedWithFallback,
  newPostFunction,
  NewSecurityTypeRequest,
  newPatchFunction
} from '@src/service'
import {
  TransferSharesResponse,
  OrganizationUrlProps,
  SecurityUrlProps,
  LegendUrlProps,
  ShareholdingRequest,
  ShareholdingResponse
} from '@logic'
import { securityUrl, sharesUrl, legendsUrl, legendUrl, shareholdingsUrl, securitiesUrl } from './urls'
import { NewSecurityResponse, NewLegendResponse, EditSecurityTypeResponse } from './responses'
import { AddSecuritySharesRequest, NewLegendRequest, EditLegendRequest, EditSecurityTypeRequest } from './requests'
import { newDeleteFunction, newPutFunction } from '@src/service/lib'

export const createShareholding = newPostFunction<OrganizationUrlProps, ShareholdingRequest, ShareholdingResponse>(shareholdingsUrl)

export const editShareholding = (httpClient: HttpClient) => async (
  shareholding: Uuid,
  data: Partial<ShareholdingRequest>
): Promise<WebResponse<void>> => {
  return await httpClient.put(`shareholding/${shareholding}`, data)
}

export const transferShareholding = (httpClient: HttpClient) => async (
  organization: Uuid,
  transferRequest: TransferRequest
): Promise<TransferSharesResponse> => {
  return accessDeniedWithFallback(transferAccessDeniedResponse)(
    await httpClient.post(`organization/${organization}/shareholding/transfer`, transferRequest)
  )
}

export const deleteShareholding = (httpClient: HttpClient) => async (shareholding: Uuid): Promise<TransferSharesResponse> => {
  return accessDeniedWithFallback(transferAccessDeniedResponse)(await httpClient.delete(`shareholding/${shareholding}`))
}

export const createSecurity = newPostFunction<OrganizationUrlProps, NewSecurityTypeRequest, NewSecurityResponse>(securitiesUrl)

export const editSecurity = newPutFunction<SecurityUrlProps, EditSecurityTypeRequest, EditSecurityTypeResponse>(securityUrl)

export const deleteSecurity = (plan?: Hash) => newDeleteFunction<SecurityUrlProps, {}>(`${securityUrl}${plan ? '?plan=' + plan : ''}`)

export const addSecurityShares = newPostFunction<SecurityUrlProps, AddSecuritySharesRequest, {}>(sharesUrl)

export const createLegend = newPostFunction<OrganizationUrlProps, NewLegendRequest, NewLegendResponse>(legendsUrl)

export const editLegend = newPatchFunction<LegendUrlProps, EditLegendRequest, {}>(legendUrl)

export const deleteLegend = newDeleteFunction<LegendUrlProps, {}>(legendUrl)
