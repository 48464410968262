import * as React from 'react'
import styled from 'styled-components'
import CarrotDown from '@image/carrot-down.svg'
import { FlexCol, FlexRow } from '@src/styles'
import { useClickOutside } from '@shared/hooks/useClickOutside'
import SelectCheckbox from '@image/select-check.png'
import { CheckMark } from '../multibox/style'

interface Filter {
    withAll?: boolean
    options: any[]
    placeholder: string
    accessor: string
}

interface Props {
    filters: Filter[]
    data: any[]
}

interface DynamicFilterBundle {
    component: React.ReactElement
    filteredData: any[]
}

const Wrap = styled.div<any>`
    position relative;
`

const StyledFilter = styled<any>(FlexRow)`
    padding: 8px 8px 8px 12px;
    border-radius: 4px;
    background: ${props => props.filled === 'true' ? props.theme.input.basic : props.theme.input.default};
    color: ${props => props.theme.listDesc};
    cursor: pointer;

    svg {
        margin-left: 12px;
    }

    :hover, :focus {
        background: ${props => props.theme.input.basic} !important;
        box-shadow: 0 1px 0 0 ${props => props.theme.accent},
                  0 -1px 0 0 ${props => props.theme.accent},
                  -1px 0 0 0 ${props => props.theme.accent},
                  1px 0 0 0 ${props => props.theme.accent},
                  0 0 0 1px ${props => props.theme.accent};
    }

    .dropVal {
        margin-left: 10px;
        color: ${({ theme }) => theme.accent};
    }
`

const OptionsWrap = styled<any>(FlexCol)`
    position: absolute;
    align-items: flex-start;
    background: ${props => props.theme.drop.options};
    top: 48px;
    right: 0;
    padding: 8px 0px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                 0px 3px 14px rgba(0, 0, 0, 0.05),
                  0px 5px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    .option {
        width: 100%;
        padding: 8px 16px;
        cursor: pointer;
        span {
            white-space: nowrap;
            margin-left: 10px;
            color: ${({ theme }) => theme.white};
        }
        img {
            height: 20px;
        }
        :hover {
            background: ${({ theme }) => theme.button.primary.disabled};
            .emptyCheck {
                border: 1px solid ${({ theme }) => theme.accent};
            }
        }
    }
`

const FilterRow = styled<any>(FlexRow)`
    .filter {
        margin-right: 16px;
        :last-child {
            margin-right: 0;
        }
    }
`

const getDropValue = (filters: any, all: any) => {
    const l = filters.length
    const allLength = all.length - 1

    if (l == 0) return ''
    if (l == allLength) return 'ALL'
    if (l > 0 && l <= 99) return `${l}`
    if (l > 99) return '99+'
}

export const dynamicFilter = (props: Props): DynamicFilterBundle => {
    const { filters, data } = props
    const [filteredData, setFilteredData] = React.useState<any>(data)
    const select: any = filters.map(f => {
        return { accessor: f.accessor, val: [] }
    })
    const [selections, setSelections] = React.useState<any[]>(select)

    React.useEffect(() => {
        let test = data
        selections.forEach(acc => {
            const d = test
            const r = d.filter((d: any) => (acc.val.length > 0) ? acc.val.includes(d[acc.accessor]) : true)
            test = r
        })

        setFilteredData(test)
    }, [selections])

    const renderFilter = (filter: Filter, index: number) => {
        const [open, setOpen] = React.useState<boolean>(false)
        const node = React.useRef(null)
        useClickOutside({ node, handleClose: () => setOpen(false) })
        const allOptions = [{ label: 'All', value: 'all' }].concat(filter.options)
        const currentFilters = selections.find(s => s.accessor == filter.accessor)?.val

        const removeSelection = (value: string, access: string) => {
            if (value == 'All') {
                const set = selections.map(s => {
                    if (s.accessor == access) {
                        return { ...s, val: [] }
                    }
                    else return s
                })
                setSelections(set)
            }
            else {
                const set = selections.map(s => {
                    if (s.accessor == access) {
                        return {
                            ...s, val: s.val.filter((v: any) => v !== value)
                        }
                    }
                    else return s
                })
                setSelections(set)
            }
        }

        const handleSelection = (value: string, access: string) => {
            if (value == 'All') {
                const selectedFilter = filters.find(f => f.accessor == access)
                const all = selectedFilter?.options.map(o => o.label) || []
                const set = selections.map(s => {
                    if (s.accessor == access) {
                        return { ...s, val: all }
                    }
                    else return s
                })
                setSelections(set)
            }
            else {
                const set = selections.map(s => {
                    if (s.accessor == access) {
                        return { ...s, val: s.val.concat(value) }
                    }
                    else return s
                })
                setSelections(set)
            }
        }

        const renderCheck = (checked: boolean) => {
            if (checked) {
                return <img src={SelectCheckbox} alt="check" />
            }
            else return <CheckMark
                inverted={false}
                noLabel={'true'}
                className='emptyCheck'
            />
        }

        return <Wrap className='filter'>
            <StyledFilter
                onClick={() => setOpen(true)}
                key={index}>
                <span>{filter.placeholder}</span>
                <span className='dropVal'>{getDropValue(currentFilters, allOptions)}</span>
                <CarrotDown />
            </StyledFilter>
            {open ? <OptionsWrap ref={node}>
                {allOptions.map((opt, i: number) => {
                    const filteredSelections: any = selections.find(s => s.accessor == filter.accessor)
                    let selected: boolean = false
                    if (opt.label == 'All') {
                        selected = filter.options.every(all => filteredSelections.val.includes(all.label))
                    }
                    else selected = filteredSelections.val?.includes(opt.label)

                    return <FlexRow
                        className='option'
                        key={i}
                        onClick={() => selected ? removeSelection(opt.label, filter.accessor)
                            : handleSelection(opt.label, filter.accessor)}>
                        {renderCheck(selected)}
                        <span>{opt.label}</span>
                    </FlexRow>
                })}
            </OptionsWrap> : null}
        </Wrap>
    }

    return {
        component: <FilterRow>
            {filters?.map((filter, i: number) => renderFilter(filter, i))}
        </FilterRow>,
        filteredData,
    }

}
