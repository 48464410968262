import * as React from 'react'
import {
    convertibleInstrumentsPath,
    editConvertibleInstrumentPath,
    newConvertibleInstrumentPath,
    PageProps,
    viewConvertibleInstrumentPath
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganizationHoldings, loadSecurities } from '@helpers/loaders'
import { PathLinkButton } from '@components/button/button'
import { ellipsesColumn, Table } from '@components/tables'
import { CellProps } from 'react-table'
import { GetHoldingsResponse, GetSecuritiesResponse } from '@src/service'
import { formatCurrency, formatSecurityName, toCommaFloat, usdString } from '@helpers/index'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { getInstrumentType } from '../utility'
import { FlexCol, gapSizes, LightDivider, whiteColor } from '@src/styles'
import styled from 'styled-components'
import { EmptyLanding } from '@shared/components/empty-landing'

type Props = PageProps & GetHoldingsResponse & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganizationHoldings('all'), loadSecurities())

const NoInstruments = styled<any>(FlexCol)`
    ${whiteColor}
    text-align: center;
    span {
        width: 52%;
        margin: ${gapSizes.M} 0;
    }
`
export const ListConvertibleInstrumentsPage = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const { params, navigate, holdings, securities } = props
        const { organization } = params
        const holdingsList = holdings && holdings.length > 0 ? holdings : []
        const holdingsData = holdingsList.filter(el => el.convertibleInstrument && securities.some(s => el.parent! === s.hash))
        const getConvertsTo = (hash: string) => {
            const security = securities && securities.length > 0 && securities.filter(el => el.hash === hash)[0]
            return security && formatSecurityName(security)
        }
        const tableData = holdingsData.map(el => {
            const outstanding = holdingsList.filter(v => v.parent === el.id).reduce((a, b) => a + b.capitalContribution!, 0)
            const treasury = el.convertibleInstrument?.authorizedRaiseAmount! - outstanding
            const type = el.convertibleInstrument?.instrumentType ? getInstrumentType[el.convertibleInstrument?.instrumentType] : ''
            const equity = getConvertsTo(el.parent!)
            return { ...el, outstanding, treasury, type, equity }
        })
        const raisedAmountTotal = tableData?.reduce((a, b) => a + b.convertibleInstrument?.authorizedRaiseAmount!, 0)
        const outstandingTotal = tableData?.reduce((a, b) => a + b.outstanding!, 0)
        const treasuryTotal = tableData?.reduce((a, b) => a + b.treasury!, 0)
        const maxEquitiesTotal = tableData?.reduce((a, b) => a + b.value!, 0)

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                sortType: 'caseInsensitive',
                Cell: ({ row }: CellProps<any>) => <span>{row.original.name!}</span>,
                Footer: () => <div>TOTAL</div>,
                className: 'basic'
            },
            {
                Header: 'Type',
                accessor: 'type',
                sortType: 'caseInsensitive',
                Cell: ({ row }: CellProps<any>) => {
                    return <div>{row.original.type}</div>
                },
                className: 'left'
            },
            {
                Header: 'Converts To',
                accessor: 'equity',
                sortType: 'caseInsensitive',
                Cell: ({ row }: CellProps<any>) => <div>{row.original.equity!}</div>,
                className: 'left'
            },
            {
                Header: 'Authorized Amount',
                accessor: 'convertibleInstrument.authorizedRaiseAmount',
                Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.convertibleInstrument?.authorizedRaiseAmount!)}</div>,
                Footer: () => <span>{formatCurrency(raisedAmountTotal)}</span>,
            },
            {
                Header: 'Outstanding',
                accessor: 'outstanding',
                Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.outstanding!)}</div>,
                Footer: () => <span>{formatCurrency(outstandingTotal)}</span>,
            },
            {
                Header: 'Treasury',
                accessor: 'treasury',
                sortType: 'basic',
                Cell: ({ row }: CellProps<any>) => <div>{usdString(row.original.treasury!)}</div>,
                Footer: () => <span>{formatCurrency(treasuryTotal)}</span>,

            },
            {
                Header: 'Maximum Issuance',
                accessor: 'value',
                Cell: ({ row }: CellProps<any>) => <div>{toCommaFloat(row.original.value)}</div>,
                Footer: () => <span>{toCommaFloat(maxEquitiesTotal)}</span>,
            },
            // {
            //     Header: '%',
            //     accessor: 'percentage',
            //     Cell: ({ row }: CellProps<any>) => <span>{percentageString(row.original.value, maxEquitiesTotal)}</span>,
            //     Footer: () => <span>100%</span>,
            // },
            ellipsesColumn(
                [
                    ['View', ({ row }) => navigate(viewConvertibleInstrumentPath, { organization, holding: row.original.hash })],
                    ['Edit', ({ row }) => navigate(editConvertibleInstrumentPath, { organization, holding: row.original.hash })]
                ]
            ),
        ]

        return (
            <PageContent>
                {holdingsData && holdingsData.length > 0 ?
                    <>
                        <PageContentHeader title="Authorized Convertible Instruments">
                            <PathLinkButton path={newConvertibleInstrumentPath} args={{ organization }}>
                                Create New Instrument
                            </PathLinkButton>
                        </PageContentHeader>
                        <Table columns={columns} data={tableData} scrollingTable={true} />
                    </>
                    :
                    // <>
                    //     <PageContentHeader title="Authorized Convertible Instruments" />
                    //     <LightDivider stretch={true} />
                    //     <NoInstruments justifyContent={'center'}>
                    //         <h2>Create Your First Convertible Instrument</h2>
                    //         <span>
                    //             Jump start fundraising by creating and issuing convertible instruments.
                    //             Click the "Create New Instrument" button below to get started.
                    //         </span>
                    //         <PathLinkButton path={newConvertibleInstrumentPath} args={{ organization }}>
                    //             Create New Instrument
                    //         </PathLinkButton>

                    //     </NoInstruments>
                    // </>
                    <EmptyLanding path={convertibleInstrumentsPath} params={params} />
                }
            </PageContent>
        )
    }
    ))
