import * as React from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { gapSizes, colors } from '@src/styles/theme'
import { formatSecurityName } from '@shared/helpers'
import { formatSeniorityText } from '@modules/organization/utility'

interface Props {
    securities: any
    holdings: any
    holding?: string
    security?: string
    editHolding?: boolean
    editSecurity?: boolean
    currentSecurity?: any
    values?: any
    view?: boolean
}

const rowHeight = '60px'
const StyledTable = styled.div<any>`
  transition: all 300ms ease-in;
  overflow-x: auto;
  border-radius: 8px;
  table {
    color: ${props => props.theme.white};
    width: 100%;
    border-collapse: collapse;
    border: 1px solid transparent;
    border-spacing: 0;
    border-radius: ${props => props.theme.radius};
    padding-right: 20px;
    padding-bottom: 20px;
    thead {
      background-color: ${props => props.theme.highlight};
      text-transform: uppercase;
    }
    tr {
      &[role='row'] {
        > *:nth-child(${props => (props.expandable ? 2 : 1)}) {
          font-weight: bold;
          font-family: 'NunitoSansBold';
        }
      }
      > *:nth-child(${props => (props.expandable ? 2 : 1)}) {
        text-align: start;
      }
    }
    th,
    td {
      text-align: end;
      padding: 0 ${gapSizes.M};
    }
    tbody {
      background-color: ${props => props.theme.highlight};
      tr {
        border-top: 2px solid;
        border-color: ${props => rgba(props.theme.white, 0.1)};
      }
    }
  }
`

export const TableRow = styled.tr<any>`
  height: ${rowHeight};
  padding: 0 ${gapSizes.S};
  background: ${props => props.highlight ? colors.tableHighlight : ''}
`

export const SeniorityTable = (props: Props) => {
    const { securities, holdings, holding, security, editHolding, editSecurity, currentSecurity, values, view } = props
    const filteredHoldings = holdings && holdings.length > 0 ? holdings?.filter(el => el.convertibleInstrument?.instrumentType!) : []

    const securityItems = (editSecurity || view) ?
        securities?.map((s: any) => {
            if (s.hash === security) {
                return { name: formatSecurityName(s), seniority: values?.seniority || s.seniority, highlight: true }
            }
            return { name: formatSecurityName(s), seniority: s.seniority }
        })
        :
        securities?.map((s: any) => {
            return { name: formatSecurityName(s), seniority: s.seniority }
        })
            .concat({ name: formatSecurityName(currentSecurity), seniority: values.seniority || 1, highlight: true })

    const holdingItems = editHolding ?
        filteredHoldings?.map((s: any) => {
            if (s.hash === holding) {
                return { name: s.name, seniority: s.seniority, highlight: true }
            }
            return { name: s.name, seniority: s.seniority }
        })
        :
        view ?
            filteredHoldings?.map((s: any) => {
                return { name: s.name, seniority: s.seniority }
            })
            :
            filteredHoldings?.map((s: any) => {
                return { name: s.name, seniority: s.seniority }
            })
                .concat({ name: values?.name, seniority: values?.seniority || 1, highlight: true })

    const rows = [...securityItems, ...holdingItems]
    const sortedRows = rows?.sort((a, b) => a.seniority - b.seniority)
    const headers = ['Share Class', 'Seniority', 'Payout Order']

    return (
        <StyledTable>
            <table>
                <thead>
                    <TableRow>
                        {headers.map((column, i: number) => {
                            return (
                                <th key={i}>
                                    {column}
                                </th>
                            )
                        })}
                    </TableRow>
                </thead>
                <tbody>
                    {sortedRows?.map((cell, i) => {
                        return (
                            <TableRow key={i} highlight={cell.highlight}>
                                <td>
                                    {cell.name}
                                </td>
                                <td>
                                    {cell.seniority}
                                </td>
                                <td>
                                    {formatSeniorityText(cell.seniority)}
                                </td>
                            </TableRow>
                        )
                    })}
                </tbody>
            </table>
        </StyledTable>
    )
}
