import * as React from 'react'
import { loadHolding } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { ComponentType } from 'react'
import { NavigationProps, withNavigation } from '@shared/components/navigation'
import { withService } from '@shared/hocs/with-service'
import { ExpandedHoldingResponse } from 'service'
import { Tabs } from '@shared/components/tabs/tabs'
import {
  captableCertificatePath,
  certificateApprovalsPath,
  certificateDetailsPath,
  certificateLegendPath,
  certificateVestingSchedulePath,
  documentsAndNotesPath,
  editOptionPath,
  editShareholdingPath
} from '@logic'
import { HoldingRecordType } from '@shared/helpers/holdings'
import { isHolding } from '@modules/captable/utility'

type Props = NavigationProps & ExpandedHoldingResponse
// TODO make subscription prop
const withData = withLoadingCachedMultiple<Props>(loadHolding)

const CertificateHeader = withNavigation(withService(withData((props: Props) => {
  const { holding, params } = props
  const certificateNumber = holding?.name
  const isShareholding = (!isHolding(holding?.holdingType) || holding?.holdingType == HoldingRecordType.option)

  const editLink = isShareholding ?
    [
      {
        destination: !isHolding(holding?.holdingType) ?
          editShareholdingPath
          : editOptionPath,
        name: 'Edit',
      },
    ] : []

  const links = [
    {
      destination: captableCertificatePath,
      name: 'Certificate',
      exact: true,
    },
    {
      destination: certificateDetailsPath,
      name: 'Details',
    },
    {
      destination: certificateVestingSchedulePath,
      name: 'Vesting Schedule',
      disabled: holding?.holdingType == HoldingRecordType.option ? false : true,
    },
    {
      destination: certificateApprovalsPath,
      name: 'Approvals',
      disabled: true,
    },
    {
      destination: certificateLegendPath,
      name: 'Legend',
    },
    {
      destination: documentsAndNotesPath,
      name: 'Documents & Notes',
    },
    ...editLink
  ]

  const args: any = { ...params, option: holding?.holdingType == HoldingRecordType.option ? holding?.hash : undefined, plan: holding?.plan! }

  return <Tabs tabs={links} title={certificateNumber} params={args} />
})))

export function withCertificateHeader<P>(InnerComponent: ComponentType<P>): ComponentType<P> {
  return (props: any) => (
    <React.Fragment>
      <CertificateHeader {...props} />
      <InnerComponent {...props} />
    </React.Fragment>
  )
}
