import * as React from 'react'
import { MembersProps, PageProps, viewConvertibleInstrumentPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm, UploadHandler } from '@components/forms'
import arrayMutators from 'final-form-arrays'
import {
  CapitalChangeRequest,
  createAuthorization,
  editHolding,
  ExpandedHoldingResponse,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetAuthorizationsResponse,
  GetSecuritiesResponse,
  patchCapitalChange,
  postFile
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import {
  loadAuthorizations,
  loadHolding,
  loadMembers,
  loadOrganizationHoldings,
  loadSecurities
} from '@helpers/loaders'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { ConvertibleNoteForm } from '../components'
import { getInterestFromConvertible, getMaxShares, getOrganizationTotalUnits } from '../utility'
import { CapitalContributionType } from '@modules/captable/utility'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps & GetSecuritiesResponse & ExpandedHoldingResponse & MembersProps & GetAuthorizationsResponse & ExpandedHoldingsResponse

const withData = withLoadingCachedMultiple<Props>(
  loadSecurities(),
  loadMembers,
  loadOrganizationHoldings('all'),
  loadHolding,
  loadAuthorizations('note')
)

export const EditConvertibleNotePage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, holding, members, authorizations, holdings } = props
    const { organization } = params
    const parentHolding = holdings.filter(el => el.id === holding.parent)[0]
    const documentsAndNotes = authorizations && authorizations.length > 0 ? authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })

    const initialValues = {
      owner: holding.owner,
      issueDate: holding?.issueDate ? holding.issueDate : '',
      principle: holding.capitalContribution,
      approvalDocument: documents,
    }
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const { convertibleInstrument } = parentHolding
      const { valuationMin, conversionDiscount } = convertibleInstrument
      const { issueDate, owner, principle } = values
      const interest = getInterestFromConvertible(convertibleInstrument, issueDate, principle)
      const projectedPrinciple = principle + interest
      const totalUnits = getOrganizationTotalUnits(securities)
      const maxUnits = getMaxShares({
        maxUsd: projectedPrinciple,
        valuationMin,
        totalUnits,
        discount: conversionDiscount
      })
      const request = {
        owner,
        issueDate,
        parent: parentHolding.id!,
        entity: organization,
        value: maxUnits,
        legend: holding?.legend!,
        name: holding?.name!,
        internalType: convertibleInstrument ? convertibleInstrument.instrumentType : '',
        class: parentHolding ? parentHolding.abbreviation : '',
      }
      const editHoldingResponse = await editHolding(httpClient)({ holding: holding?.hash }, request)
      if (editHoldingResponse) {
        if (principle !== holding.capitalContribution) {
          const capitalChangeRequest = {
            mod: principle,
            timestamp: new Date(),
            type: CapitalContributionType.cashPaid,
            source: editHoldingResponse?.hash!
          } as CapitalChangeRequest
          await patchCapitalChange(httpClient)(
            { entity: organization }, capitalChangeRequest
          )
        }
        modifyAuthorizations({ approvalDocuments, target: editHoldingResponse.hash!, httpClient, organization })
        navigate(viewConvertibleInstrumentPath, { organization, holding: params?.holding })
      } else console.log('edit Convertible Instrument error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = {
      path: viewConvertibleInstrumentPath,
      args: { organization, holding: params?.holding }
    }
    return (
      <PageContent>
        <PageContentHeader title={`Edit ${holding.name}`} parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <ConvertibleNoteForm onUpload={onUpload} members={members} edit={true} />
        </CommonForm>
      </PageContent>
    )
  }))
