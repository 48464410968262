import * as React from 'react'
import { DocumentRecord, Organization, OrganizationState } from '@logic'
import { CellProps } from 'react-table'
import { formatBytes, formatDateString } from '@helpers/util'
import { Ellipses } from '@components/tables/ellipses'
import { connect } from 'react-redux'
import styled from 'styled-components'
import FolderIcon from '@image/folder-icon.svg'
import FolderIconDark from '@image/folder-icon.svg'
import { screenSizes, Themes } from '@src/styles/theme'
import useWindowDimensions from '../../../shared/hooks/useWindowDimensions'
import { FileTarget } from '@components/styledForm'
import Dropzone from 'react-dropzone'
import DocUpload from '@image/doc-upload.svg'
import { useState } from 'react'
import { AppContext } from '@src/app'
import { Service } from '@src/service'
import { Table } from '@components/tables/table'
import { newDeleteConfirmationModal, newLeavingEquaModal, newRenameDocumentModal } from '@modules/documents/components/modals'
import { CommonInput } from '@src/styles'
import { readLinkFromStream } from '../utils'

declare var API_URL: string
interface StateProps {
  currentOrganization: Organization
  organization?: OrganizationState
}

interface Props {
  service: Service
  getDocuments: (shouldUpdateDocuments: boolean) => void
  setLoaded: (loaded: boolean) => void
  documents: DocumentRecord[]
  canEditDocument: boolean
  path: string[]
  setPath: (path: string[]) => void
  uploadDocuments: (files: any) => void
  theme: any
  reload: any
}

const DocumentName = styled.div`
  display: flex;
  cursor: pointer;
  a {
    color: ${props => props.theme.white};
  }
`

function DocumentSection(props: Props & StateProps) {
  const { path, setPath, documents, currentOrganization, uploadDocuments, canEditDocument, theme, reload } = props
  const [leavingEqua, setLeavingEqua] = useState<string | null>(null)
  const [deleteDocument, setDeleteDocument] = useState<DocumentRecord | null>(null)
  const [renameDocument, setRenameDocument] = useState<DocumentRecord | null>(null)
  const { width } = useWindowDimensions()

  const leavingEquaModal = newLeavingEquaModal(() => {
    window.open(leavingEqua!, '_blank')
    leavingEquaModal.setVisible(false)
  })

  const renameItem = async (name: string, isFolder: boolean) => {
    const fileExtension = renameDocument!.name.split('.').slice(-1)
    const nameWithExtension = isFolder || name.split('.').slice(-1)[0] === fileExtension[0] ? name
      : name.concat('.' + fileExtension)

    let previousNameWithExtension = ''
    let previousPath = ''
    let newFullPath = ''
    if (!currentOrganization.legacyDataRoom) {
      previousNameWithExtension = isFolder || renameDocument!.name.split('.').slice(-1)[0] === fileExtension[0] ? renameDocument!.name
        : renameDocument!.name.concat('.' + fileExtension)
      previousPath = path.length > 0 ? '/' + path.join('/') + '/' + previousNameWithExtension :
        '/' + previousNameWithExtension

      newFullPath = path.length > 0 ? '/' + path.join('/') + '/' + nameWithExtension : nameWithExtension
    } else newFullPath = path.length > 0 ? path.join('/') + '/' + nameWithExtension : nameWithExtension

    await props.service.renameDocuments(currentOrganization.id, renameDocument!.id, { newFullPath }, previousPath)

    // TODO: The above line is for the deprecated Microsoft file store and should eventually be replaced by the below commented-out code
    // const previousPath = path.length > 0 ? path + '/' + renameDocument!.name : renameDocument!.name
    // await props.service.renameDocuments(currentOrganization.id, renameDocument!.id, { previousPath, newFullPath })
    setRenameDocument(null)
    props.getDocuments(true)
  }

  const deleteItem = async () => {
    if (!currentOrganization.legacyDataRoom) {
      const isFolder = deleteDocument?.type == 2 ? true : false
      const fileExtension = deleteDocument!.name.split('.').slice(-1)
      const nameWithExtension = isFolder || deleteDocument!.name.split('.').slice(-1)[0] === fileExtension[0] ? deleteDocument!.name : deleteDocument!.name.concat('.' + fileExtension)
      const fullPath = path.length > 0 ? '/' + path + '/' + nameWithExtension :
        '/' + nameWithExtension
      await props.service.deleteDocuments(currentOrganization.id, deleteDocument!.id, fullPath)
    }
    else await props.service.deleteDocuments(currentOrganization.id, deleteDocument!.id)
    deleteDocumentModal.setVisible(false)
    reload()
  }

  const getFormat = (obj: any) => {
    if (!currentOrganization.legacyDataRoom ? obj?.type == 2 : obj?.folder) return 'folder'
    else if (obj?.name?.endsWith('.url')) return 'link'
    else if (obj?.image) return 'image'
    else return 'file'
  }

  const deleteDocumentModal = newDeleteConfirmationModal(deleteItem)
  const renameIsFolder = currentOrganization?.legacyDataRoom ? renameDocument?.folder
    : renameDocument?.type == 2 ? true : false
  const renameDocumentModal = newRenameDocumentModal(
    values => renameItem(values.name, renameIsFolder),
    getFormat(renameDocument),
    {
      name: !renameIsFolder && renameDocument?.name?.includes('.')
        ? renameDocument?.name
          .split('.')
          .slice(0, -1)
          .join('.')
        : renameDocument?.name!
    }
  )

  const viewDocument = (document: DocumentRecord) => {
    const isFolder = document.type == 2 ? true : false
    const fileExtension = document!.name.split('.').slice(-1)
    const nameWithExtension = isFolder || document!.name.split('.').slice(-1)[0] === fileExtension[0] ?
      document!.name : document!.name.concat('.' + fileExtension)
    const fullPath = path.length > 0 ? '/' + path.join('/') + '/' + nameWithExtension :
      '/' + nameWithExtension
    const docxRegex = /\.docx$/

    if (document.name.endsWith('.url')) {
      if (!currentOrganization.legacyDataRoom) {
        const queryString = docxRegex.test(document.name) ? `?format=pdf&fullPath=${fullPath}` : `?fullPath=${fullPath}`
        const url = `${API_URL}/organization/${currentOrganization.id}/document/${document.id}/content/${document.name}${queryString}`

        readLinkFromStream(url).then(res => {
          setLeavingEqua(res)
          leavingEquaModal.setVisible(true)
        })
      }
      else {
        return fetch(document['@microsoft.graph.downloadUrl']!)
          .then(function (response) {
            response.text().then(function (text) {
              let url = text.substr(text.toLowerCase().search('url=') + 4, text.length)
              if (!url.startsWith('http')) {
                url = 'http://' + url
              }
              setLeavingEqua(url)
              leavingEquaModal.setVisible(true)
            })
          })
          .catch(function (err) {
            console.log(err)
          })
      }
    } else {
      if (!currentOrganization.legacyDataRoom) {
        const queryString = docxRegex.test(document.name) ? `?format=pdf&fullPath=${fullPath}` : `?fullPath=${fullPath}`
        window.open(`${API_URL}/organization/${currentOrganization.id}/document/${document.id}/content/${document.name}${queryString}`)
      }
      else {
        const docxRegex = /\.docx$/
        const queryString = docxRegex.test(document.name) ? `?format=pdf` : ``
        window.open(`${API_URL}/organization/${currentOrganization.id}/document/${document.id}/content/${document.name}${queryString}`)
      }
    }
  }

  const renderDropzone = () => {
    return (
      <Dropzone
        onDrop={async (files: any) => {
          await uploadDocuments(files)
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <FileTarget {...getRootProps()}>
            <div>
              <CommonInput {...getInputProps()} />
              <DocUpload />
              <h3>Drag and Drop</h3>
              <p>
                or <span>browse</span> your files
              </p>
            </div>
          </FileTarget>
        )}
      </Dropzone>
    )
  }

  const renderFileType = (name: string, original: DocumentRecord) => {
    const isFolder = !currentOrganization.legacyDataRoom ? original.type == 2 ? true : false : original?.folder
    const extension = !isFolder && name.includes('.url') ? 'link' : name.includes('.') ? name.split('.').pop() : 'Folder'

    return (
      <div>
        <span style={{ textTransform: 'capitalize' }}>{extension!}</span>
      </div>
    )
  }

  const renderItemName = (name: string, original: DocumentRecord) => {
    const isFolder = !currentOrganization.legacyDataRoom ? original.type == 2 ? true : false : original?.folder
    const Icon = theme.selected === Themes.LIGHT ? FolderIconDark : FolderIcon
    const nameWithoutExtension = !isFolder && name.includes('.')
      ? name
        .split('.')
        .slice(0, -1)
        .join('.')
      : name
    return (
      <DocumentName
        onClick={e => {
          e.preventDefault()
          isFolder ? setPath(path.concat(name)) : viewDocument(original)
        }}
      >
        {isFolder && <Icon />}
        <span>{nameWithoutExtension}</span>
      </DocumentName>
    )
  }

  const columns = [
    {
      Header: 'FILE NAME',
      accessor: 'name',
      Cell: ({ row }: CellProps<any>) => renderItemName(row.original.name, row.original),
    },
    {
      Header: 'FILE TYPE',
      accessor: 'type',
      Cell: ({ row }: CellProps<any>) => renderFileType(row.original.name, row.original),
    },
    {
      Header: 'DATE UPLOADED',
      accessor: 'createdDateTime',
      Cell: ({ row }: CellProps<any>) => <div>{formatDateString(row.original.created)}</div>,
    },
    {
      Header: 'FILE SIZE',
      accessor: 'size',
      show: width > screenSizes.L!,
      Cell: ({ row }: CellProps<any>) => {
        const isFolder = !currentOrganization.legacyDataRoom ? row.original.type == 2 ? true : false
          : row.original.folder
        const size = row.original.size
        const folderItems = !currentOrganization.legacyDataRoom ? size > 1 ? `${size} items` : size == 0 ? 'No items'
          : `${size} item` : row.original.items
        return <div>{isFolder ? folderItems : formatBytes(row.original.size)}</div>
      }
    },
    {
      id: 'ellipses',
      Header: '',
      Cell: ({ row }: CellProps<any>) => {
        const isFolder = !currentOrganization.legacyDataRoom ? row.original.type == 2 ? true : false
          : row.original.folder

        return <Ellipses
          options={[
            !isFolder && ['View', () => viewDocument(row.original)],
            canEditDocument && [
              'Rename', () => {
                setRenameDocument(row.original)
                renameDocumentModal.setVisible(true)
              },
            ],
            canEditDocument && [
              'Delete',
              () => {
                deleteDocumentModal.setVisible(true)
                setDeleteDocument(row.original)
              },
            ],
          ]}
        />
      },
    },
  ]

  return (
    <div>
      {leavingEquaModal.component}
      {renameDocumentModal.component}
      {deleteDocumentModal.component}
      {documents.length === 0 && canEditDocument ? (
        renderDropzone()
      ) : (
        <>
          <Table columns={columns} data={documents} scrollingTable={true} />
        </>
      )}
    </div>
  )
}

export const DocumentSectionWithService = (props: Props & StateProps) => (
  <AppContext.Consumer>{context => <DocumentSection {...props} service={context.service!} />}</AppContext.Consumer>
)

const mapStateToProps = (s: StateProps) => {
  return {
    currentOrganization: s.organization!.currentOrganization,
  }
}

export default connect(mapStateToProps)(DocumentSectionWithService as any) as any
