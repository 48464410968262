import * as React from 'react'
import styled from 'styled-components'
import { colors, FlexCol, FlexRow, Themes } from '@src/styles'
import { Avatar } from '@shared/components/avatar'
import { guestProfileLinks, profileLinks } from '../types'
import { matchesPath, PathLink } from '@shared/components/navigation'
import { DropBorder, DropFooter } from '../style'
import TextLogo from '@image/text-logo.svg'
import Logo from '@image/logo.svg'
import { useClose } from '../utility'
import Tick from '@image/table-tick-16x16.svg'
import { BetaNotify } from '@modules/profile'
import Guest from '@image/guest-user.svg'

interface ProfileDropProps {
    profileData?: any
    forGuest?: boolean
}

const DockWrapper = styled.div<any>`
    min-width: 32px;
    height: 32px;
    padding: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        :hover {
            path {
                fill: ${colors.green};
            }
        }
    }
    div {
        display: flex;
    }
    :hover {
        background: ${({ theme }) => theme.dropHover};
    }
    a {
        width: 100%;
    }
    &:active {
        img {
            border: 1px solid ${colors.green};
        }
        background: transparent;
    }
    svg {
        path {
            fill: ${({ theme }) => theme.white};
        }
    }
`

const Drop = styled<any>(FlexCol)`
    position: absolute; 
    padding: 10px 0;
    width: 188px;
    top: 49px;
    right: 0;
    border-radius: 4px;
    background: ${({ theme }) => theme.header};
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08),
                  0px 3px 14px rgba(0, 0, 0, 0.05),
                  0px 5px 5px rgba(0, 0, 0, 0.12);
    
    .footerLink {
        path {
            fill: ${props => props.theme.selected === Themes.LIGHT && colors.green};
        }
    }
`

const OptionRow = styled<any>(FlexRow)`
    width: 100%;
    padding: 8px 16px;
    :hover {
        background: ${props => !props.selected && colors.green};
        path {
            fill: ${props => (props.logout === 'true' || props.theme.selected === Themes.LIGHT) &&
        !props.selected ? colors.white : ''};
        }
        span {
            color: ${props => (props.logout === 'true' || props.theme.selected === Themes.LIGHT) &&
        !props.selected ? colors.white : ''};
        }
    }
    span {
        font-size: 16px;
        line-height: 24px;
        color: ${props => props.logout === 'true' ? colors.errorRed : props.selected ? colors.green : props.theme.listText};
    }
    path {
        fill: ${props => props.logout === 'true' ? colors.errorRed : props.selected ? colors.green : props.theme.listText};
    }
`

export const SmallBetaNotify = styled<any>(BetaNotify)`
    width: 29px;
    height: 16px;
    font-size: 8px !important;
    line-height: 16px !important;
    padding: 0.5px 4px;
    margin-left: 8px;
    margin-right: 0px;
`

export const ProfileDrop = (props: ProfileDropProps) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const { profileData, forGuest } = props
    const ref = React.useRef() as any
    useClose(open, setOpen, ref)

    return <DockWrapper onClick={() => setOpen(!open)} ref={ref}>
       {forGuest ?  <Guest/>: profileData?.fullName &&
            <Avatar size="24px" fullName={profileData.fullName || ''} image={profileData.file?.hash} />
        }
        {open && <Drop>
            {forGuest ? guestProfileLinks.map(link => {
                const Image = link.image
                const selected = matchesPath(link.path, window.location.pathname, false)
                return <PathLink path={link.defaultUrl || link.path} args={{}}>
                    <OptionRow logout={link.title === 'Logout' ? 'true' : 'false'} selected={selected}>
                        {selected ? <Tick /> : <Image />}
                        <span>{link.title}</span>
                        {link.beta ? <SmallBetaNotify>Beta</SmallBetaNotify> : null}
                    </OptionRow>
                </PathLink>
            }) : profileLinks.map(link => {
                const Image = link.image
                const selected = matchesPath(link.path, window.location.pathname, false)
                return <PathLink path={link.defaultUrl || link.path} args={{}}>
                    <OptionRow logout={link.title === 'Logout' ? 'true' : 'false'} selected={selected}>
                        {selected ? <Tick /> : <Image />}
                        <span>{link.title}</span>
                        {link.beta ? <SmallBetaNotify>Beta</SmallBetaNotify> : null}
                    </OptionRow>
                </PathLink>
            })}
            <DropBorder />
            <a href={'https://www.equa.global/'} target='_blank' className='footerLink'>
                <DropFooter>
                    <Logo />
                    <TextLogo style={{ marginLeft: '5px' }} />
                </DropFooter>
            </a>
        </Drop>
        }
    </DockWrapper>
}