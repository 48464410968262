export enum TransactionType {
  adjustment = 'adjustment',
  charge = 'charge',
}

export enum LineItemKind {
  baseline = 'baseline',
  component = 'component',
  coupon = 'coupon',
}

export interface LineItem {
  transaction_type: TransactionType
  kind: string
  amount_in_cents: number
  memo: string
  discount_amount_in_cents: number
  taxable_amount_in_cents: number
}

export interface BillingManifest {
  line_items: LineItem[]
  total_in_cents: number
  total_discount_in_cents: number
  subtotal_in_cents: number
  start_date: string
  end_date: string
  existing_balance_in_cents: number
}

export interface PurchasePreview {
  current_billing_manifest: BillingManifest
  next_billing_manifest: BillingManifest
}

export interface PurchasePreviewProps {
  subscriptionPreview: PurchasePreview
}

export interface UserCouponProps {
  coupon?: string
}
