import * as React from 'react'
import { PageProps, PaymentProfilesResponse } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAccount, loadPaymentProfiles } from '@helpers/loaders'
import { AccountSettingsShared } from '../components/acc-settings-shared'


type Props = PageProps & PaymentProfilesResponse

const withData = withLoadingCachedMultiple<Props>(loadAccount, loadPaymentProfiles)

export const ViewAccountSettings = withData((props: Props) => {
    return <AccountSettingsShared {...props} />
})
