import * as React from 'react'
import { connect } from 'react-redux'
import { History } from 'history'
import { GetReferralDataResponse, GetScratchCardResponse, InvitedFriendsResponse, PageProps } from '@logic'
import { RouteComponentProps, withRouter } from 'react-router'
import { loadAllScratchCards, loadInvitedFirends, loadReferralsData } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { MyReferralsShared } from '../components/referral-component'

interface StateProps extends RouteComponentProps<any> {
  user: any
  history: History
}

type Props = PageProps &
  StateProps &
  GetReferralDataResponse &
  InvitedFriendsResponse &
  GetScratchCardResponse

const withData = withLoadingCachedMultiple<Props>(
  loadReferralsData,
  loadInvitedFirends,
  loadAllScratchCards(),
)

const MyReferralsComponent = withData((props: Props) => {
  return <MyReferralsShared {...props} />
})

const mapStateToProps = (state: any) => ({
  user: state.user,
})

export const MyReferrals = withRouter(
  connect(
    mapStateToProps,
    {}
  )(MyReferralsComponent)
)
