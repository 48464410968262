import {
  accessDeniedWithFallback,
  HttpClient,
  shareholdingAccessDeniedResponse,
  shareholdingsAccessDeniedResponse,
  GetSecuritiesResponse,
  GetLegendsResponse,
  MultiQueryRequest
} from '@src/service'
import { Uuid, Hash, normalizeDate } from '@helpers/util'
import {
  ShareholdingProps,
  ShareholdingsProps,
  OrganizationUrlProps,
  LegendUrlProps,
  GetBlocksResponse,
  SecurityUrlProps,
} from '@logic'
import { newGetFunction } from '@src/service'
import { securityUrl, legendsUrl, legendUrl, blocksUrl, securitiesUrl, holdingsUrl, holdingUrl, captableUrl, captablePoolsUrl } from './urls'
import { ExpandedHoldingResponse, ExpandedHoldingsResponse, GetCaptableDashboardResponse, GetLegendResponse, GetPoolsReportResponse, GetSecurityTypeResponse, QueryResponse } from './responses'
import { newPostFunction } from '@src/service/lib'

export const getShareholdings = (httpClient: HttpClient) => async (organization: Uuid, block?: Hash): Promise<ShareholdingsProps> => {
  const blockClause = block ? `&block=${block}` : ''
  return accessDeniedWithFallback(shareholdingsAccessDeniedResponse)(await httpClient.get(`organization/${organization}/shareholding?voided=true${blockClause}`))
}

export const getShareholding = (httpClient: HttpClient) => async (shareholding: Uuid): Promise<ShareholdingProps> => {
  const response = accessDeniedWithFallback(shareholdingAccessDeniedResponse)(await httpClient.get(`shareholding/${shareholding}`))
  // prettier-ignore
  const formattedShareholding = response.shareholding
    ? {
      ...response.shareholding,
      issueDate: normalizeDate(response.shareholding.issueDate)!,
    }
    : undefined

  return {
    ...response,
    shareholding: formattedShareholding,
  }
}

export const getSecurities = (stats?: string) => {
  const statsClause = stats ? `?stats=${stats}` : ''
  return newGetFunction<OrganizationUrlProps, GetSecuritiesResponse>(`${securitiesUrl}${statsClause}`)

}
export const getSecurityType = newGetFunction<SecurityUrlProps, GetSecurityTypeResponse>(securityUrl)
export const getLegends = (deleted: boolean) => newGetFunction<OrganizationUrlProps, GetLegendsResponse>(`${legendsUrl}${deleted ? '?includeDeleted=true' : ''}`)
export const getLegend = newGetFunction<LegendUrlProps, GetLegendResponse>(legendUrl)
export const getBlocks = newGetFunction<OrganizationUrlProps, GetBlocksResponse>(blocksUrl)
export const getHoldings = (entity: Hash, expansions: string, block?: Hash) => {
  const blockClause = block ? `&block=${block}` : ''
  return newGetFunction<{}, ExpandedHoldingsResponse>(`${holdingsUrl}?entity=${entity}&expansions=${expansions}${blockClause}`)
}
export const getHolding = (holding: Hash) => newGetFunction<{}, ExpandedHoldingResponse>(`${holdingsUrl}/${holding}?expansions=all`)
export const getQueryData = newPostFunction<{}, MultiQueryRequest, QueryResponse>('query')
export const getCaptableData = (voided: string, expansions?: string, block?: Hash,) => {
  const blockClause = block ? `&block=${block}` : ''
  const expanded = expansions ? `&expansions=${expansions}` : ''
  return newGetFunction<OrganizationUrlProps, GetCaptableDashboardResponse>(`${captableUrl}?voided=${voided}${blockClause}${expanded}`)
}
export const getCaptablePools = newGetFunction<OrganizationUrlProps, GetPoolsReportResponse>(captablePoolsUrl)
