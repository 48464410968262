import React from 'react'
import { isRowSelected, renderTableRowCells, TableRow, TableRowProps } from '@components/tables/index'

export function advancedTableRow<T extends object>(subComponent: React.ComponentType<TableRowProps<T>>) {
  return (props: TableRowProps<T>) => {
    // Need to use isRowSelected instead of row.isSelected because all rows need to be treated as selected when none are
    const { row, selectedRows } = props
    const SubComponent = subComponent
    return (
      <React.Fragment>
        <TableRow
          isExpanded={row.isExpanded}
          {...row.getRowProps()}
          isSelected={isRowSelected(selectedRows, row.id)}
        >
          {renderTableRowCells(row.cells)}
        </TableRow>
        {row.isExpanded && <SubComponent row={row} selectedRows={selectedRows} />}
      </React.Fragment>
    )
  }
}
