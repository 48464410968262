import { AddressResponse, ContinuousVestingSchedule, MembersProps, Organization } from '@logic'
import { Hash, Uuid } from '@helpers/util'
import { Role } from '../roles'

export const membersAccessDeniedResponse: MembersProps = {
  members: [],
  membersAccessDenied: true,
}

export interface FileResponse {
  filename: string
  id: Uuid
  hash: Hash
}

export interface FilesResponse {
  files: FileResponse[]
}

export interface InviteMembersResponse {
  emails: string[]
}

export interface GetOrganizationResponse {
  organization: Organization
}

export type FileContentResponse = any

export interface NewAuthorizationResponse {
  hash: Hash
  authorizationDate?: Date
  document?: Hash
  note?: string
  organization: Uuid
  target: string
  documentTypeName?: string
}

export interface OrganizationStats {
  holderCount: number
  outstanding: number
  totalUnits: number
  totalCashRaised: number
  captableSummary: any
}

export interface GetOrganizationStatsResponse {
  organizationStats: OrganizationStats
}

export interface Groups extends Role { }

export interface GetAddressResponse extends AddressResponse {
  groups: Groups[]
}

export interface GetAddressesResponse {
  addresses: GetAddressResponse[]
}

export interface EmailResponse {
  id: string
  email: string
  context: string
  groups: Groups[]
}

export interface GetEmailResponse {
  email: EmailResponse
}

export interface GetEmailsResponse {
  emails: EmailResponse[]
}