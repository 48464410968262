import * as React from 'react'
import { PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { withPermissions } from '@src/shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions } from '@components/permissions'
import { ActionList } from '../components/actions-list'
import { organizationActions } from '../organization-actions'
import { search } from '@shared/components/search'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>(loadPermissions)

const getActionsList = (value: string) => {
  return value ? organizationActions.filter((arg) => arg.title.toLowerCase().includes(value.toLowerCase())) : organizationActions
}

export const ActionsPage = withData(
  withPermissions([BuiltInPermission.viewMembers])((props: Props) => {
    const { params } = props
    const { organization } = params
    const searchBundle = search('Search Actions...')
    const Search = searchBundle.component
    const inputValue = searchBundle.inputValue
    const actions = getActionsList(inputValue)

    return (
      <PageContent>
        <PageContentHeader title='Actions'>
          {Search}
        </PageContentHeader>
        <ActionList actions={actions} organization={organization} />
      </PageContent>
    )
  })
)
