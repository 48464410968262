import styled from 'styled-components'
import { rgba } from 'polished'
import { colors } from '@src/styles'

export const CheckMark = styled.span<any>`
  cursor: pointer;
  height: 20px;
  width: 20px;
  position: ${props => props.noLabel === 'false' && 'absolute'};
  left: ${props => props.noLabel === 'false' && 0};
  background-color: ${props => props.inverted ? rgba(props.theme.black, 0.5) : props.theme.checkbox};
  border-radius: 5%;
  border: 1px solid rgba(191, 191, 191, 0.4);
  margin: 0 !important;
`

export const RadioCheckMark = styled<any>(CheckMark)`
  background-color: ${props => props.checked === 'true' && colors.green};
  border-radius: 50%;
  border: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
  span {
    height: 8px;
    width: 8px;
    margin: 0 !important;
    border-radius: 50%;
    display: flex;
    background-color: ${props => props.checked === 'true' && colors.white};
  }
`

export const InfoBox = styled.div`
  display: flex;
  position: absolute;
  left: 0%;
  bottom: 0%;
  width: 400px;
  padding: 0.5rem;
  font-size: 14px;
  border: 1px solid white;
  z-index: 300;
  opacity: 0;
  transition: 700ms opacity;
  span {
    margin-right: 10px;
  }
`

export const InfoIcon = styled.span`
  :hover ~ ${InfoBox} {
    opacity: 1;
  }
`

export const RadioContainer = styled.label<any>`
  display: flex;
  position: ${props => props.noLabel === 'false' && 'relative'};
  padding-left: ${props => props.noLabel === 'false' && '35px'};
  margin: ${props => props.noLabel === 'false' && '10px 0'};
  font-size: 1.1rem;
  line-height: 19px;
  color: white;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: ${props => props.noLabel === 'false' && 'absolute'};
    display: none;
    cursor: pointer;
  }
  :hover input ~ ${CheckMark} {
    background-color: ${props => rgba(props.theme.accent, 0.1)};
  }
  input:checked ~ ${CheckMark} {
    background-color: ${props => props.theme.accent};
  }
  input:checked ~ ${CheckMark}:after {
    display: block;
  }
  img {
    cursor: pointer;
    min-width: 20px;
    min-height: 20px;
    opacity: ${props => props.disabled && '0.4'};
    position: ${props => props.noLabel === 'false' && 'absolute'};
    left: ${props => props.noLabel === 'false' && 0};
  }
`
