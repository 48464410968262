import { ExpandedReward, SendInvitationResponse } from "@logic";
import { newDeleteFunction, newPatchFunction, newPostFunction, newPutFunction } from "@src/service/lib";
import { EmailInvitationRequest, NewAccountRequest, NewColorRequest, NewEquaCashRequest, RewardIdRequest } from "./requests";
import { accountUrl, ColorProps, colorUrl, equaCashTransferUrl, UserProps } from "./urls";

export const newColor = newPutFunction<ColorProps, NewColorRequest, {}>(colorUrl)
export const deleteColor = newDeleteFunction<ColorProps, {}>(colorUrl)
export const editAccount = newPatchFunction<UserProps, NewAccountRequest, {}>(accountUrl)
export const sendInvite = newPostFunction<{}, EmailInvitationRequest, SendInvitationResponse>('invitation/send')
export const scratchCard = newPostFunction<{}, RewardIdRequest, ExpandedReward[]>('reward')
export const equaCashTransfer = newPostFunction<{}, NewEquaCashRequest, {}>(equaCashTransferUrl)
