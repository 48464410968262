import { Hash, UrlPath, Uuid } from '@helpers/util'
import { EntityProps, HoldingUrlProps, OrganizationUrlProps } from '@logic'

export interface MemberUrlProps {
  member: Uuid
}

export interface FileContentUrlProps {
  file: Hash
}

export interface AuthorizationUrlProps extends OrganizationUrlProps {
  authorization: Hash
}

export interface AddressUrlProps extends EntityProps {
  address: Hash
}

export interface EmailProps extends EntityProps {
  email: Hash
}

export const organizationUrl: UrlPath<OrganizationUrlProps> = `organization/:organization`
export const organizationStatsUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/dashboard`
export const memberUrl: UrlPath<MemberUrlProps> = `member/:member`
export const inviteMembersUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/member/invite`
export const fileContentUrl: UrlPath<FileContentUrlProps> = `file/:file/content`
export const allAuthorizationUrl: UrlPath<OrganizationUrlProps> = `${organizationUrl}/authorization`
export const authorizationUrl: UrlPath<AuthorizationUrlProps> = `${allAuthorizationUrl}/:authorization`
export const taskUrl: UrlPath<{ entity: Uuid }> = 'entity/:entity/task'
export const editTaskUrl: UrlPath<{ entity: Uuid, task: Uuid }> = `${taskUrl}/:task`
export const userStateUrl: UrlPath<{}> = 'user/state'
export const addressesUrl: UrlPath<EntityProps> = 'entity/:entity/address'
export const addressUrl: UrlPath<AddressUrlProps> = `${addressesUrl}/:address`
export const emailsUrl: UrlPath<EntityProps> = 'entity/:entity/email'
export const emailUrl: UrlPath<EmailProps> = `${emailsUrl}/:email`
export const emailsManyUrl: UrlPath<EntityProps> = `${emailsUrl}/many`
export const orgReferralUrl: UrlPath<EntityProps> = 'organization/:entity/referral'
