import * as React from 'react'
import { captableCertificatePath } from '@logic'
import { Panel } from '@components/panels/panels'
import { PanelSummaryHeader } from '@components/panels/PanelSummaryHeader'
import { PanelTable } from '@components/panels/panel-table'
import { useMemo } from 'react'
import { CertificateLink } from '@modules/captable/components'
import { formatDateString, optionalCommaNumber, safeDivide } from '@helpers/util'
import { CellProps } from 'react-table'
import { ExpandedHolding } from '@src/service'
import styled from 'styled-components'

interface Props {
  outstandingShares: ExpandedHolding[]
}

const StyledPanel = styled<any>(Panel)`
  padding: 16px;
  div {
    padding-left: 4px;
  }
  table {
    border-collapse: separate !important;
    border-spacing: 0 16px;
    margin-top: 24px;
    thead {
      box-shadow: 0 1px 0 0 rgba(206, 218, 225, 0.3);
      &:after {
        content: '';
      }
    }
    tr {
      height: 48px;
    }
    tbody tr {
      height: 48px;
      &:nth-child(odd) {
        background-color: transparent !important;
      }
    }
  }
`

const ShareBlock = (props: Props) => {
  const { outstandingShares } = props
  const tableData = outstandingShares?.sort((a, b) => a.name?.localeCompare(b.name!))

  const columns = useMemo(
    () => [
      {
        Header: 'Cert ID',
        accessor: 'id',
        Cell: ({ row }: CellProps<any>) => <CertificateLink holding={row.original} path={captableCertificatePath} />,
      },
      {
        Header: 'Issue Date',
        accessor: 'issueDate',
        Cell: ({ row }: CellProps<any>) => formatDateString(row.original.issueDate),
      },
      {
        Header: 'Shares',
        accessor: 'shares',
        Cell: ({ row }: CellProps<any>) => !row.original.isHolding ? optionalCommaNumber(row.original.value) : '-',
      },
      {
        Header: 'Price/Share',
        accessor: 'capitalContribution',
        Cell: ({ row }: CellProps<any>) => !row.original.isHolding ?
          `$ ${safeDivide(row.original.capitalContribution, row.original.value).toFixed(2)}`
          : '-',
      },
    ],
    []
  )

  return (
    <StyledPanel>
      <PanelSummaryHeader title="Holdings" />
      {tableData?.length > 0 && <PanelTable data={tableData} columns={columns} />}
    </StyledPanel>
  )
}

export default ShareBlock
