import { formBasicModalBundle, newBasicModalBundle } from '@components/basic-modal'
import { IntroModal } from '@modules/documents/style'
import CircleCheck from '@image/check-circle.svg'
import * as React from 'react'
import { Form } from 'react-final-form'
import Input from '@components/input'
import { fileNameLimit, required, urlCharLimit } from '@helpers/field-validators'
import { CancelButton, ErrorButton, Button, WarningButton, SolidButton } from '@components/button'
import { FlexRow } from '@src/styles'
import { renderFieldsSimple, textInputFieldComponent } from '@shared/components/forms'

export function newDeleteConfirmationModal(onActivate: () => void, warningIcon?:boolean, name: string | undefined = 'this item') {
  return newBasicModalBundle({
    title: 'Confirm Deletion?',
    warningIcon: warningIcon,
    desc: `Are you sure you want to delete this ${name}?`,
    buttons: [
      {
        text: 'Confirm',
        onActivate,
        componentType: WarningButton,
      },
    ],
  })
}


export function newDeleteCardModal(onActivate: () => void) {
  return formBasicModalBundle({
    title: 'Are you sure?',
    desc: 'Are you sure you want to delete this debit/credit card on file? This action cannot be undone.',
    onSubmit: onActivate,
    submitText: 'Delete',
  })
}

export function newCloseModal(onActivate: () => void, title: string, desc: string) {
  return newBasicModalBundle({
    title,
    desc,
    buttons: [
      {
        text: 'Close',
        onActivate,
      },
    ],
    onCancel: onActivate,
    noCancel: true,
  })
}

export function newProcessingModal() {
  return newBasicModalBundle({
    title: 'Payment Processing',
    desc: 'Your payment is processing. Please wait a few moments for your payment to be completed.',
    body: (
      <FlexRow justifyContent={'center'}>
        <img src={require('@image/processing.gif')} alt="loading" />
      </FlexRow>
    ),
    noCancel: true,
  })
}

const NewFolderForm = (props: any) => {
  return <>
    {renderFieldsSimple([
      {
        name: 'folderName',
        label: 'Folder Name',
        required: true,
        validate: required,
        placeholder: 'Enter Your Folder Name',
        component: textInputFieldComponent
      },
    ], true)}
  </>

}

export function newFolderModal(onActivate: (props: any) => void) {
  return formBasicModalBundle({
    title: 'Create New Folder',
    desc: 'Please enter a name for the new folder',
    body: <NewFolderForm />,
    onSubmit: onActivate,
    submitText: 'Confirm'
  })
}

const RenameForm = (props: any) => {
  return <>
    {renderFieldsSimple([
      {
        name: 'name',
        label: `Rename ${props.format}`,
        required: true,
        validate: required,
        placeholder: 'Enter New Name',
        component: textInputFieldComponent
      },
    ], true)}
  </>
}

export function newRenameDocumentModal(onActivate: (props: any) => void, format: string, initialValues?: any) {
  return formBasicModalBundle({
    title: `Rename ${format}`,
    desc: `What would you like to rename your ${format.toLowerCase()} to?`,
    body: <RenameForm format={format} />,
    initialValues,
    onSubmit: onActivate,
    submitText: 'Confirm'
  })
}

export function newIntroductionModal(onActivate: () => void) {
  return newBasicModalBundle({
    title: 'Not sure what to upload?',
    body: (
      <IntroModal>
        <p>Here is a list of some standard organizational documents:</p>
        <ul>
          <li>
            <CircleCheck /> Bylaws or Operating Agreement
          </li>
          <li>
            <CircleCheck /> Certificate of Incorporation or Articles of Organization
          </li>
          <li>
            <CircleCheck /> Employer Identification Number
          </li>
          <li>
            <CircleCheck /> Board Consents for Each Round or Issuance
          </li>
          <li>
            <CircleCheck /> Term Sheet for Each Fundraising Round
          </li>
          <li>
            <CircleCheck /> Stock Certificates
          </li>
          <li>
            <CircleCheck /> Form of Note (if convertible note)
          </li>
          <li>
            <CircleCheck /> Subscription and Holder Agreements
          </li>
          <li>
            <CircleCheck /> Transfer Agreements
          </li>
          <li>
            <CircleCheck /> Any Stock or Equity Incentive Plans
          </li>
          <li>
            <CircleCheck /> Existing Cap Table (if available)
          </li>
        </ul>
      </IntroModal>
    ),
    buttons: [
      {
        text: 'Close',
        onActivate,
        componentType: CancelButton
      },
    ],
    noCancel: true,
  })
}

export function newLeavingEquaModal(onActivate: () => void) {
  return newBasicModalBundle({
    title: 'Leaving Equa Start',
    desc: 'Are you sure you want to leave Equa Start?',
    buttons: [
      {
        text: 'Confirm',
        onActivate,
        componentType: ErrorButton,
      },
    ],
  })
}

export function deletePlanModal(onActivate: () => void, title: string,warningIcon?:boolean, desc: string, text: string) {
  return newBasicModalBundle({
    title,
    warningIcon: warningIcon,
    desc,
    buttons: [
      {
        text,
        onActivate,
        componentType: WarningButton,
      },
    ],
  })
}

export function addLinkModal(onActivate: (props: any) => void) {
  return newBasicModalBundle({
    title: 'Add Link',
    desc: 'Please enter a name and url for the link you would like to create.',
    body: (
      <>
        <Form
          onSubmit={onActivate}
          render={({ handleSubmit }) => (
            <form id="new-link" onSubmit={handleSubmit}>
              {renderFieldsSimple([
                {
                  name: 'name',
                  label: 'File Name',
                  placeholder: 'Enter a file name',
                  required: true,
                  validate: fileNameLimit,
                  component: textInputFieldComponent,
                },
                {
                  name: 'path',
                  label: 'URL',
                  placeholder: 'Enter the url',
                  required: true,
                  validate: urlCharLimit,
                  component: textInputFieldComponent,
                },
              ], true)}
            </form>
          )}
        />
      </>
    ),
    buttons: [{ componentType: SolidButton, text: 'Confirm', type: 'submit', form: 'new-link' }],
  })
}
