import * as React from 'react'
import { organizationCreateLegendPath, organizationEditLegendPath, organizationLegendsPath, organizationViewLegendPath, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadLegends } from '@helpers/loaders'
import { PathLinkButtonInverted } from '@components/button/button'
import { ellipsesColumn, expanderColumn, Table } from '@components/tables'
import { CellProps } from 'react-table'
import { deleteLegend, GetLegendsResponse } from '@src/service'
import { formatDateString } from '@shared/helpers'
import styled from 'styled-components'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { deletePlanModal } from '@modules/documents/components/modals'
import { PathLink } from '@shared/components/navigation'
import { EmptyLanding } from '@shared/components/empty-landing'

type Props = PageProps & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>(loadLegends(false))

const StyledPathLink = styled<any>(PathLink)`
  :hover {
    text-decoration: underline;
  }
`

export const ListLegendsPage = withData(
  withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
    const [selected, setSelected] = React.useState<string>('')
    const { legends, params, navigate, httpClient, reload } = props
    const { organization } = params
    const tableData = legends ? legends : []

    const handleDeleteLegend = async () => {
      await deleteLegend(httpClient)({ organization, legend: selected })
      deleteModal.setVisible(false)
      reload()
    }

    const deleteModal = deletePlanModal(
      handleDeleteLegend,
      'Delete Legend?',
      true,
      'Are you sure you want to delete this legend? This action cannot be undone.',
      'Confirm'
    )

    const columns = [
      {
        Header: 'Legend name',
        accessor: 'name',
        Cell: ({ row }: CellProps<any>) => <StyledPathLink
          path={organizationViewLegendPath}
          args={{
            organization,
            legend: row.original.hash,
            parent: window.location.pathname
          }}>
          {row.original.name}
        </StyledPathLink>
      },
      {
        Header: 'Created by',
        accessor: 'createdBy',
        Cell: ({ row }: CellProps<any>) => <div>{row.original.author.name}</div>
      },
      {
        Header: 'Date created',
        accessor: 'dateCreated',
        Cell: ({ row }: CellProps<any>) => <div>{formatDateString(row.original.created)}</div>
      },
      ellipsesColumn(
        [
          ['View', ({ row }) => navigate(organizationViewLegendPath, { organization, legend: row.original.hash })],
          ['Edit', ({ row }) => navigate(organizationEditLegendPath, { organization, legend: row.original.hash })],
          ['Delete', ({ row }) => { setSelected(row.original.hash), deleteModal.setVisible(true) }]
        ]
      ),
    ]

    return (
      <>
        {deleteModal.component}
        <PageContent>
          {tableData.length > 0 ?
            <>
              <PageContentHeader title="Legends">
                <PathLinkButtonInverted path={organizationCreateLegendPath} args={{ organization }}>
                  Add new legend
                </PathLinkButtonInverted>
              </PageContentHeader>
              <Table columns={columns} data={tableData} scrollingTable={true} withAllBorders={true} />
            </>
            :
            <EmptyLanding path={organizationLegendsPath} params={params} />
          }
        </PageContent>
      </>
    )
  }
  ))
