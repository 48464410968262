import * as React from 'react'
import {
  dateField,
  FieldGroup,
  multiSelectFieldComponent,
  renderFieldsSimple,
  renderHeader,
  selectFieldComponent,
  textInputFieldComponent,
  uploadFileField,
  UploadHandler,
} from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers/field-validators'
import { planTerm } from '../utility'
import { ExpandedSecurity, FormPlan } from '@src/service'
import styled from 'styled-components'
import { LightDivider } from '@src/styles'
import { formatSecurityName, optionalCommaNumber } from '@shared/helpers'
import { organizationNewSecurityPath } from '@logic'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'

interface Props {
  values?: FormPlan
  onUpload: UploadHandler
  securities: ExpandedSecurity[]
  params: any
  edit?: boolean
}

export const QuestionSpan = styled.span<any>`
  font-weight: 600;
  font-family: 'NunitoSansSemiBold';
  font-size: 20px;
  line-height: 28px;
`

export const NoteSpan = styled.span<any>`
  line-height: 24px;
  margin: 16px 0 0 !important;
`

function mainFields(securities: ExpandedSecurity[], params: any): FieldGroup<FormPlan> {
  const securitiesOptions = securities.map(security => ({
    label: `${formatSecurityName(security)} ${security.shareClassPrefix ? `(${security.shareClassPrefix})` : ''}`,
    value: security.hash,
    info: `- ${security.stats.treasury ? optionalCommaNumber(security.stats.treasury) : 0} Available`
  }))

  return [
    {
      name: 'name',
      label: 'Plan Name',
      required: true,
      validate: emptyValidator,
      placeholder: 'Enter plan name',
      component: textInputFieldComponent,
    },
    {
      name: 'approvedEquities',
      label: 'Approved Equities',
      required: true,
      validate: emptyValidator,
      component: multiSelectFieldComponent(
        securitiesOptions,
        'Approved Plan Equities',
        { text: '+ Add New Equity', to: organizationNewSecurityPath, args: { organization: params.organization } }
      )
    },
    {
      name: 'boardApprovalDate',
      label: 'Approval Date',
      required: true,
      placeholder: 'MM/DD/YYYY',
      ...dateField(React.useRef()),
    },
    {
      name: 'termYears',
      label: 'Plan Term',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(planTerm, 'Plan Term'),
    },
  ]
}

export const PlanForm = (props: Props) => {
  const { onUpload, securities, params, values, edit } = props
  const docFields = renderFieldsSimple([
    {
      name: 'boardApprovalDocument',
      label: 'Approval Agreement',
      required: false,
      ...uploadFileField({ onUpload, placeholder: 'Upload document', accept: '.pdf' }),
    },
    {
      name: 'incentivePlanDocument',
      label: 'Incentive Plan',
      required: false,
      ...uploadFileField({ onUpload, placeholder: 'Upload document', accept: '.pdf' }),
    },
  ], true)
  return (
    <>
      <QuestionSpan>What is an Incentive Plan?</QuestionSpan>
      <NoteSpan>Incentive Plans are any compensation arrangement and can be a form of equity compensation granted by companies to their employees and executives.
        Enter the details of your plan here.
        Once your plan is completed, you can create multiple pools underneath this plan.
      </NoteSpan>
      {renderHeader({ label: 'Plan Details' })}
      {renderFieldsSimple(mainFields(securities, params))}
      <DocumentsAndNotes
        onUpload={onUpload}
        values={values}
        noInitial={true}
        definedFields={docFields} />
    </>
  )
}
