import * as React from 'react'
import { FlexRow, StyledH2 } from '@src/styles'
import { Themes } from '@src/styles/theme'
import { PageContent } from '@components/pages'
import { StyledToggle } from '@components/toggle-buttons'
import { AppContext } from '@src/app'
import { useContext } from 'react'
import { PageContentHeader } from '@components/pages/page-content-header'
import { matchesPath } from '@shared/components/navigation'
import { guestThemesPath } from '@logic'

function Settings() {
  const themes = [
    { value: Themes.EQUA, label: 'Equa' },
    { value: Themes.MIDNIGHT, label: 'Midnight' },
    { value: Themes.LIGHT, label: 'Light' },
  ]
  const { theme, dispatch } = useContext(AppContext)
  const withMargin = matchesPath(guestThemesPath, window.location.pathname, true)

  return (
    <PageContent withMargin={true}>
      <PageContentHeader title='Themes:' />
      <FlexRow>
        {themes.map((item, i) => (
          <StyledToggle
            onClick={() => {
              dispatch({ type: 'SET_THEME', payload: item.value })
            }}
            key={i}
            type="button"
            name={'theme'}
            selected={theme === item.value}
          >
            {item.label}
          </StyledToggle>
        ))}
      </FlexRow>
    </PageContent>
  )
}

export default Settings
