import * as React from 'react'
import styled from 'styled-components'
import EquaLogo from '@image/equa-logo-text.svg'

const Wrapper = styled.div<any>`
.HeadingText{
    margin: 24px 24.5px 32px 24.5px;
    text-align: center;
    font-size: 20px;
    line-height: 28px;
}
.Logo{
    text-align: center;
}
`
export const LogoHeading = () => {
    return (
        <Wrapper>
            <div className="Logo"><EquaLogo/></div>
            <div className='HeadingText'>Manage all of your private and public assets <br/> in one place.</div>
        </Wrapper>
    )
}
