import * as React from 'react'
import {
    capitalizeTextInputField,
    nonZeroCurrencyField,
    FieldGroup,
    renderFieldsSimple,
    renderHeader,
    textInputFieldComponent
} from '@shared/components/forms'
import { emptyValidator } from '@shared/helpers'
import styled from 'styled-components'
import { colors, FlexCol, gapSizes, LightDivider, StyledH2 } from '@src/styles'
import { InvertedButton } from '@shared/components/button'
import { colorPickerModal } from './profile-modals'
import { BasicModalBundle } from '@shared/components/basic-modal'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
    values?: any
    color: string
    colorModal: BasicModalBundle
    forOrganization?: boolean
}
const StyleCreateLegendForm = styled.div``
const Color = styled.div<any>`
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-bottom: ${gapSizes.M};
    background: ${props => props.color};
    border: 3px solid ${colors.white};
`

function assetDetailsFields(forOrganization?: boolean): FieldGroup<any> {
    return [
        {
            name: 'name',
            label: forOrganization ? 'Holding Name' : 'Asset Name',
            required: true,
            validate: emptyValidator,
            placeholder: forOrganization ? 'Enter holding name' : 'Enter asset name',
            component: textInputFieldComponent,
        },
        {
            name: 'abbreviation',
            label: 'Abbreviation',
            required: false,
            validate: emptyValidator,
            placeholder: forOrganization ? 'Enter holding abbreviation' : 'Enter asset abbreviation',
            component: capitalizeTextInputField
        },
        {
            name: 'tickerSymbol',
            label: 'Ticker Symbol',
            required: false,
            validate: emptyValidator,
            placeholder: forOrganization ? 'Enter holding ticker symbol' : 'Enter asset ticker symbol',
            component: capitalizeTextInputField,
        },
        // {
        //     name: 'content',
        //     label: 'Custom Name',
        //     required: false,
        //     validate: emptyValidator,
        //     placeholder: 'Enter a Custom Name For This Asset',
        //     component: textInputFieldComponent
        // }
    ]
}

function holdingsFields(): FieldGroup<any> {
    return [
        {
            name: 'value',
            label: 'Total Units Owned',
            required: true,
            validate: emptyValidator,
            format: formatToNumber,
            placeholder: 'Enter total number of units owned',
            component: textInputFieldComponent,
        },
        {
            name: 'originalPricePerUnit',
            label: 'Original Price Per Unit',
            required: true,
            placeholder: 'Enter the original value per unit',
            ...nonZeroCurrencyField,
        },
        {
            name: 'currentPricePerUnit',
            label: 'Current Price Per Unit',
            required: true,
            placeholder: 'Enter the current value per unit',
            ...nonZeroCurrencyField,
        },
    ]
}

export const HoldingForm = (props: Props) => {
    const { color, colorModal, forOrganization } = props
    return (
        <StyleCreateLegendForm>
            {renderHeader({ label: forOrganization ? 'Holding Details' : 'Asset Details', noHeader: true })}
            {renderFieldsSimple(assetDetailsFields(forOrganization), true)}
            {renderFieldsSimple(holdingsFields())}
            <StyledH2>Asset Color</StyledH2>
            <FlexCol alignItems={'start'}>
                <Color color={color} />
                <InvertedButton onClick={() => colorModal.setVisible(true)}>Change Color</InvertedButton>
            </FlexCol>
            <LightDivider stretch={true} />
        </StyleCreateLegendForm>
    )
}
