import * as React from 'react'
import {
    PageProps,
    selectYourPlanPath,
    newPaymentProfilePath,
    PaymentProfilesResponse,
    editPaymentProfilePath,
    GetSubscriptionsResponse,
    TransactionsResponse,
    checkoutPath,
    GetOrgReferralDataResponse,
    GetReferralDataResponse
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { colors, FlexCol, FlexRow, DetailsLabel, StyledPaymentMethod, screenSizes, semiBoldStyle } from '@src/styles'
import styled, { css } from 'styled-components'
import { renderHeader } from '@components/forms'
import { PathLinkButton, PathLinkButtonInverted } from '@components/button'
import { Ellipses } from '@shared/components/tables'
import { BillingHistory } from '../components/billing-history'
import { newDeleteConfirmationModal, newCloseModal } from '@modules/documents/components/modals'
import {
    loadPaymentProfiles,
    loadSubscriptions,
    loadSubscriptionPreview,
    LoadSubscriptionPreviewProps,
    formatExtendedDateString,
    dollarStringFromCents,
    loadBillingHistory,
    loadReferralsData,
} from '@shared/helpers'
import { deletePaymentProfile, defaultPaymentProfile } from '@src/service'
import { getLogo } from '../components/payment-profiles'
import { PathLink } from '@shared/components/navigation'
import { paymentPlans } from '../payments-plan'
import { PurchasePreviewProps } from '@src/service/services/billing'
import { BuiltInPermission } from '@shared/helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import CashTransfer from '@image/cash-transfer.svg'
import ArrowRight from '@image/arrow-right-16x16.svg'
import WhiteMark from '@image/equa-logo-44x44.svg'

type Props = PageProps &
    PaymentProfilesResponse &
    GetSubscriptionsResponse &
    LoadSubscriptionPreviewProps &
    PurchasePreviewProps &
    TransactionsResponse &
    GetReferralDataResponse


const withData = withLoadingCachedMultiple<Props>(
    loadPaymentProfiles,
    loadSubscriptions,
    loadSubscriptionPreview,
    loadBillingHistory,
    loadReferralsData
)

const PanelCss = css`
    width: 100%;
    align-items: normal;
    background: ${props => props.theme.panelBack};
    border-radius: 4px;
    padding: 16px;
    height: 100%;
    justify-content: flex-start;
    min-height: 258px;
`
const svgStyle = css`
    height: 40px;
    margin: 6px 20px;
`

const BillingWrapper = styled.div<any>`
    color: ${props => props.theme.white};
    .cardLogo{
        svg {
            ${svgStyle}
        }
    }
    table {
        td:first-child {
          font-weight: normal !important;
          font-family: NunitoSans !important;
        }
    }
    
`

const InfoWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 97px;
    margin-top: 48px;
    @media(max-width: ${screenSizes.XS}px) {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
    }
`

const CurrentPlan = styled<any>(FlexCol)`
    ${PanelCss};
    h4 {
        line-height: 33px;
        padding: 0 8px;
    }
    .viewPlan {
        padding: 8px 12px;
    }
    span, a {
        line-height: 22px;
        margin: 0;
    }
    span {
        padding: 12px;
    }
`

const YourNextBill = styled<any>(FlexCol)`
    ${PanelCss};
    span {
        line-height: 22px;
    }
    p {
        margin: 0 0 15px 0;
        font-size: 36px;
        line-height: 49px;
    }
    a {
        text-decoration: underline;
    }
`

const ChangeYourPlanBtn = styled<any>(PathLinkButton)`
    margin-top: 24px;
`

const MethodWrapper = styled<any>(FlexRow)`
    ${StyledPaymentMethod}
    border: ${props => props.default && `2px solid ${colors.green}`};
`

const CashBalanceWrap = styled<any>(FlexRow)`
    padding: 16px;
    border-radius: 4px;
    background: ${props => props.theme.dropHover};
    .wrap {
        width: 100%;
        padding: 0 12px;
        @media (max-width: ${screenSizes.M}px) {
            flex-direction: column;
            .staticWrap {
                flex-direction: column;
            }
            .middle {
                margin-left: 0;
            }
            a {
                align-self: center;
            }
        }
    }
    .left {
        padding: 8px;
    }
    .middle {
        max-width: 646px;
        padding: 8px;
        margin-left: 24px;
        h5 {
            margin: 0 0 8px 0;
            font-size: 24px;
            line-height: 32px;
        }
        span {
            display: inline-block;
            margin-bottom: 16px;
            line-height: 24px;
        }
        a {
            height: 40px;
            max-width: 234px;
            width: 100%;
            padding: 8px 30px;
        }
        svg {
            margin-left: 8px;
            path {
                fill: ${colors.green};
            }
        }
    }
    .right {
        padding: 0 8px;
        span {
            ${semiBoldStyle}
            font-size: 60px;
            line-height: 76px;
        }
        svg {
            path {
                fill: ${({ theme }) => theme.white};
            }
        }
    }
`

const PanelHeader = styled<any>(FlexRow)`
    margin-bottom: 32px;
`

const InfoRow = styled<any>(FlexRow)`
    padding: 12px;
`

const renderInfo = (label: string, value: any, index: number) => {
    return (
        <InfoRow justifyContent={'space-between'} alignItems={'flex-start'} key={index}>
            <DetailsLabel style={{ lineHeight: '24px', margin: 0 }}>{label}</DetailsLabel>
            <span>
                {value}
            </span>
        </InfoRow>
    )
}

export const BillingPage = withData(
    withPermissions([BuiltInPermission.editBilling])((props: Props) => {
        const [profileInfo, setProfileInfo] = React.useState<object>({})
        const { params,
            navigate,
            paymentProfiles,
            service,
            subscriptions,
            subscriptionPreview,
            transactions,
            reload,
            userDetails } = props
        const { organization } = params
        const noSubscription = paymentPlans[0]
        const { Title, Description } = noSubscription
        const subscriptionsList = subscriptions && subscriptions.length > 0 ? subscriptions : []
        const hasActiveSubscription = subscriptions?.some(s => s.raw.state === 'active')
        const manifest = hasActiveSubscription && subscriptionPreview ? subscriptionPreview.current_billing_manifest : undefined
        const nextManifest = hasActiveSubscription && subscriptionPreview ? subscriptionPreview.next_billing_manifest : undefined
        const lineItems = transactions!
        const organizationEquaCash = userDetails?.equaCash || 0

        const savedPaymentProfiles = paymentProfiles && paymentProfiles.length > 0 ? paymentProfiles.map(profile => {
            const { payment_profile } = profile
            const { card_type, masked_card_number } = payment_profile
            return {
                ...profile,
                logo: getLogo(card_type),
                value: `Card ending in ...${masked_card_number ? masked_card_number.replace(/-|\X/g, '') : ''}`,
            }
        }) : []

        const billingInfo = [
            {
                label: 'Billing Amount',
                value: hasActiveSubscription && manifest ? dollarStringFromCents(manifest.total_in_cents) : '$0.00'
            },
            {
                label: 'Next Billing Date',
                value: hasActiveSubscription && nextManifest?.start_date ? formatExtendedDateString(nextManifest.start_date) : '-'
            },
            {
                label: 'Payment Method',
                value: hasActiveSubscription && savedPaymentProfiles.filter(arg => arg.isDefault === true)[0] ?
                    savedPaymentProfiles.filter(arg => arg.isDefault === true)[0].value : '-'
            },
        ]

        const openDeleteModal = (name: string, profile: string) => {
            setProfileInfo({ name, profile })
            deleteModal.setVisible(true)
        }

        const deleteProfile = async () => {
            await deletePaymentProfile(service)({ entity: organization, profile: profileInfo?.profile! })
            deleteModal.setVisible(false)
            closePaymentModal.setVisible(true)
        }

        const handleClose = async () => {
            closePaymentModal.setVisible(false)
            reload()
        }

        const setProfileAsDefault = async (profile: string) => {
            await defaultPaymentProfile(service)({ entity: organization, profile }, {})
            reload()
        }

        const deleteModal = newDeleteConfirmationModal(deleteProfile,true, `payment method: ${profileInfo?.name}`)
        const closePaymentModal = newCloseModal(handleClose, 'Payment Method Deleted', `You have successfully deleted the following payment method: ${profileInfo?.name}`)

        return (
            <>
                {deleteModal.component}
                {closePaymentModal.component}
                <PageContent>
                    <PageContentHeader title="Billing" hideCrumbs={true} />
                    <BillingWrapper>
                        <CashBalanceWrap className='balance'>
                            <FlexRow justifyContent={'space-between'} className='wrap'>
                                <FlexRow className='staticWrap'>
                                    <div className='left'><CashTransfer /></div>
                                    <FlexCol className='middle' alignItems={'flex-start'}>
                                        <h5>Your Organization’s Equa Cash Balance</h5>
                                        <span>
                                            Spend some or all of your Org’s Equa Cash on your Equa subscription dues when you upgrade your plan.
                                            You can even spend your Profile Equa Cash on these dues.
                                        </span>
                                        <PathLinkButtonInverted path={hasActiveSubscription ? checkoutPath : selectYourPlanPath} args={{ organization }}>
                                            GO TO CHECK OUT
                                            <ArrowRight />
                                        </PathLinkButtonInverted>
                                    </FlexCol>
                                </FlexRow>
                                <FlexRow className='right'>
                                    <WhiteMark />
                                    <span>{organizationEquaCash.toFixed(2)}</span>
                                </FlexRow>
                            </FlexRow>
                        </CashBalanceWrap>
                        <InfoWrapper>
                            <div>
                                {subscriptionsList.length > 0 ?
                                    <CurrentPlan>
                                        <PanelHeader><h4>Current Plan</h4></PanelHeader>
                                        <h4>GROWTH</h4>
                                        <span>
                                            Full Equity Management capabilities with built in digital transfer-ability and governance add ons.
                                        </span>
                                        <PathLink path={selectYourPlanPath} args={{ organization }} className='viewPlan'>view plan details</PathLink>
                                        <ChangeYourPlanBtn path={selectYourPlanPath} args={{ organization }}>
                                            Change Your Plan
                                        </ChangeYourPlanBtn>
                                    </CurrentPlan>
                                    :
                                    <CurrentPlan>
                                        <PanelHeader><h4>Current Plan</h4></PanelHeader>
                                        <h4>{Title}</h4>
                                        <span>
                                            {Description}
                                        </span>
                                        <PathLink path={selectYourPlanPath} args={{ organization }} className='viewPlan'>view plan details</PathLink>
                                        <ChangeYourPlanBtn path={selectYourPlanPath} args={{ organization }}>
                                            Change Your Plan
                                        </ChangeYourPlanBtn>
                                    </CurrentPlan>
                                }
                            </div>
                            <div>
                                <YourNextBill>
                                    <PanelHeader><h4>Your Next Bill</h4></PanelHeader>
                                    {billingInfo.map((el, index: number) => {
                                        return renderInfo(el.label, el.value, index)
                                    })}
                                </YourNextBill>
                            </div>
                        </InfoWrapper>
                        {renderHeader({ label: 'Saved Payment Methods' })}
                        {savedPaymentProfiles.map((method, i: number) => {
                            const Logo = method.logo
                            const { id } = method.payment_profile
                            return <MethodWrapper justifyContent={'space-between'} alignItems={'center'} default={method.isDefault} key={i}>
                                <div className='cardLogo'>
                                    {Logo}
                                    <span>{method.value} {method.isDefault && '(Default)'}</span>
                                </div>
                                <Ellipses options={
                                    [
                                        ['Edit', () =>
                                            navigate(editPaymentProfilePath, { organization, profile: id })
                                        ],
                                        ['Delete', () => openDeleteModal(method.value, id)],
                                        hasActiveSubscription && !method.isDefault &&
                                        ['Set as Default', () => setProfileAsDefault(id)]
                                    ]} />
                            </MethodWrapper>
                        })}
                        <FlexRow>
                            <PathLinkButtonInverted path={newPaymentProfilePath} args={{ organization }}>Add new</PathLinkButtonInverted>
                        </FlexRow>
                        {renderHeader({ label: 'Billing History' })}
                        <BillingHistory billing={lineItems} />
                    </BillingWrapper>
                </PageContent>
            </>
        )
    }
    ))
