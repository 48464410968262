import { AsyncSuffix, RequestAction } from '../types/types'
import { Cmd, loop } from 'redux-loop'
import { AsyncBaseActionType } from '../reducers/service-reducer'
import { AnyAction } from 'redux'
import { DefinedReducer } from './utility'
import { Service } from '@src/service'

export interface AsyncHandler {
  type: AsyncBaseActionType
  asyncFunction: (data: any) => any
}

export interface ServiceHandler {
  type: AsyncBaseActionType
  asyncFunction: (service: Service) => (data: any, callback?: any) => any
}

export function startAsync<S, A extends RequestAction, T>(handler: AsyncHandler, state: S, action: A) {
  return loop(
    state,
    Cmd.run(() => handler.asyncFunction(action.data), {
      successActionCreator: data => {
        if (action.callback) {
          setTimeout(() => action.callback(data), 100)
        }
        return {
          type: handler.type + AsyncSuffix.success,
          data,
          error: undefined,
        }
      },
      failActionCreator: error => ({
        type: handler.type + AsyncSuffix.failure,
        error,
      }),
      args: [action.data],
    })
  )
}

export function reduceServices<S, A extends AnyAction>(serviceHandlers: ServiceHandler[]): (s: Service) => DefinedReducer<S, A> {
  return function(service) {
    const handlers: any = {}

    for (const handler of serviceHandlers) {
      handlers[handler.type + AsyncSuffix.start] = { ...handler, asyncFunction: handler.asyncFunction(service) }
    }
    return (state, action) => {
      const handler = handlers[action.type]
      return (handler ? startAsync(handler, state, action as any) : state) as S
    }
  }
}
