import * as React from 'react'
import { PageContent, PageContentHeader } from '@components/pages'
import { OrganizationsProps, Paths } from '@logic'
import { Panel, PanelGrid } from '@components/panels/panels'
import { FlexCol, FlexRow } from '@src/styles'
import styled from 'styled-components'
import { colors, fontSizes, gapSizes } from '@src/styles/theme'
import Plus from '@image/plus.svg'
import { NavigationProps } from '@components/navigation/navigation'
import { search } from '@shared/components/search'
import { OrganizationsPanel } from '@shared/components/organizations-panel'

const OrgPanelContent = styled<any>(FlexCol)`
  justify-content: space-between;
  height: 100%;
  color: ${props => props.theme.white};
  position: relative;
  hr {
    position: absolute;
    bottom: 30px;
    left: -${gapSizes.M};
    right: -${gapSizes.M};
  }
  a {
    margin-top: 7px;
    align-self: flex-start;
    font-weight: bold;
    font-family: 'NunitoSansBold';
  }
`

const OrgFlexRow = styled<any>(FlexRow)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 0 ${gapSizes.XL};
`

const OrgName = styled.div`
  font-size: ${fontSizes.M};
  text-align: left;
  max-width: 220px;
`

const PanelLink = styled<any>(Panel)`
  cursor: pointer;
  margin: 0;
`

const ImagesWrap = styled<any>(FlexRow)`
  background: ${colors.dropBorder};
  border: 3px solid ${props => props.theme.accent};
  min-width: 70px;
  height: 70px;
  border-radius: 50%;
  justify-content: center;
  svg {
    path {
      fill: ${colors.green};
    }
  }
`

type Props = NavigationProps & OrganizationsProps & { forGuest?: boolean, openSignUp?: any }

export const OrganizationsListShared = (props: Props) => {
  const { organizations, navigate, forGuest, openSignUp } = props
  const sortedOrganizations = organizations && organizations.length > 0 ? organizations?.sort((a, b) => {
    return a.name.localeCompare(b.name)
  }) : []

  const getOrganizationsList = (value: string) => {
    return value ? sortedOrganizations!.filter((arg) => arg.name.toLowerCase().includes(value.toLowerCase())) : sortedOrganizations!
  }

  const searchBundle = search('Search Organizations...')
  const Search = searchBundle.component
  const inputValue = searchBundle.inputValue
  const organizationList = getOrganizationsList(inputValue)

  return (
    <PageContent>
      <PageContentHeader title="" hideCrumbs={true}>
        {Search}
      </PageContentHeader>
      <PanelGrid columns={3}>
        {forGuest ?
          <PanelLink onClick={openSignUp}>
            <OrgPanelContent>
              <OrgFlexRow>
                <OrgName>Create New Organization</OrgName>
                <ImagesWrap>
                  <Plus />
                </ImagesWrap>
              </OrgFlexRow>
            </OrgPanelContent>
          </PanelLink>
          : <PanelLink onClick={() => navigate(Paths.newOrganization)} name={"newOrganization"}>
            <OrgPanelContent>
              <OrgFlexRow>
                <OrgName>Create New Organization</OrgName>
                <ImagesWrap>
                  <Plus />
                </ImagesWrap>
              </OrgFlexRow>
            </OrgPanelContent>
          </PanelLink>}
        <OrganizationsPanel organizations={organizationList} navigate={navigate} />
      </PanelGrid>
    </PageContent>
  )
}
