import { newPostFunction } from '@src/service'
import { NewPaymentProfileRequest, OrganizationUrlProps, EditPaymentProfileRequest, PaymentUrlProps, NewSubscriptionRequest, EntityProps } from '@logic'
import { paymentProfilesUrl, paymentProfileUrl, defaultPaymentProfileUrl, newSubscriptionUrl } from './urls'
import { newPutFunction, newDeleteFunction } from '@src/service/lib'
import { PromoRequest } from './requests'
import { PromoResponse } from './responses'

export const createPaymentProfile = newPostFunction<EntityProps, NewPaymentProfileRequest, {}>(paymentProfilesUrl)

export const editPaymentProfile = newPutFunction<PaymentUrlProps, EditPaymentProfileRequest, {}>(paymentProfileUrl)

export const defaultPaymentProfile = newPutFunction<PaymentUrlProps, {}, {}>(defaultPaymentProfileUrl)

export const deletePaymentProfile = newDeleteFunction<PaymentUrlProps, {}>(paymentProfileUrl)

export const newSubscription = newPostFunction<OrganizationUrlProps, NewSubscriptionRequest, {}>(newSubscriptionUrl)

export const checkPromoCode = newPostFunction<{}, PromoRequest, PromoResponse>('promo/validate')
