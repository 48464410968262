import { BuiltInPermission } from '@shared/helpers/constants'
import RolesFoot from '@image/roles-footer.png'
import RolesFootLight from '@image/roles-footer-light.png'
import RolesFootMid from '@image/roles-footer-mid.png'
import { Themes } from '@src/styles'
import { permissionsPath, rolesPath } from '@logic'

export const getRolesFooterBanner = (selected: number) => {
    switch (selected) {
        case Themes.EQUA:
            return RolesFoot
        case Themes.MIDNIGHT:
            return RolesFootMid
        case Themes.LIGHT:
            return RolesFootLight
    }
}

export const tabs: any = [
    {
        name: 'Roles',
        destination: rolesPath,
    },
    {
        name: 'Permissions Matrix',
        destination: permissionsPath,
    }
]

export const equals = (a?: any[], b?: any[]) => {
    const condition = b?.every((v, i) => v === a[i]!)
    return condition;
}

export const allPermissions = [
    {
        id: BuiltInPermission.deleteDocuments,
        name: 'Delete Documents',
        description: 'Allows the user to delete data room documents.',
        label: 'deleteDocuments'
    },
    {
        id: BuiltInPermission.editBilling,
        name: 'Edit Billing',
        description: 'Allows the user to view and update the billing payment method.',
        label: 'editBilling'
    },
    {
        id: BuiltInPermission.editCapTable,
        name: 'Edit Cap Table',
        description: 'Allows the user to manage holdings on the organization’s cap table.',
        label: 'editCapTable'
    },
    {
        id: BuiltInPermission.editDocuments,
        name: 'Edit Documents',
        description: 'Allows the user to upload and edit any of the organization’s documents in the data room.',
        label: 'editDocuments'
    },
    {
        id: BuiltInPermission.editIncentivePlan,
        name: 'Edit Incentive Plan',
        description: 'Allows the user to manage the incentive plan.',
        label: 'editIncentivePlan'
    },
    {
        id: BuiltInPermission.editMembers,
        name: 'Edit Members',
        description: 'Allows the user to edit and update their organization’s member information.',
        label: 'editMembers'
    },
    {
        id: BuiltInPermission.editOrganizationDetails,
        name: 'Edit Organization Details',
        description: 'Allows the user to update the organization’s details and settings.',
        label: 'editOrganizationDetails'
    },
    {
        id: BuiltInPermission.signing,
        name: 'Signing',
        description: 'Allows the user to sign documents and certificates throughout the platform.',
        label: 'signing'
    },
    {
        id: BuiltInPermission.viewGoverningDocuments,
        name: 'View Agreements',
        description: 'Allows the user to view the organization’s agreement documents.',
        label: 'viewAgreements'
    },
    {
        id: BuiltInPermission.viewCapTable,
        name: 'View Cap Table',
        description: 'Allows the user to view the cap table, including all of the holders and their data.',
        label: 'viewCapTable'
    },
    {
        id: BuiltInPermission.viewDocuments,
        name: 'View Documents',
        description: 'Allows the user to view any of the organization’s documents in the data room.',
        label: 'viewDocuments'
    },
    {
        id: BuiltInPermission.viewIncentivePlan,
        name: 'View Incentive Plans',
        description: 'Allows the user to view the incentive plan.',
        label: 'viewIncentivePlan'
    },
    {
        id: BuiltInPermission.viewMembers,
        name: 'View Members',
        description: 'Allows the user to view their organization’s member information.',
        label: 'viewMembers'
    },
    {
        id: BuiltInPermission.viewOrganization,
        name: 'View Organization',
        description: 'Allows the user to view the organization’s dashboard.',
        label: 'viewOrganization'
    },
    {
        id: BuiltInPermission.viewSelf,
        name: 'View Self',
        description: 'Allows the user to view their own holdings and member page.',
        label: 'viewSelf'
    },
]