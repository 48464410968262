import { CreateOrganizationRequest, NewUserRequest, PasswordResetRequest, ToastTypes, UpdateUserProfileRequest } from './types/types'

export enum ActionType {
  GET_CURRENT_USER_START = 'GET_CURRENT_USER_START',
  GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS',

  LOGIN_START = 'LOGIN_START',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGOUT_START = 'LOGOUT_START',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',

  PASSWORD_RESET_START = 'PASSWORD_RESET_START',

  REGISTER_START = 'REGISTER_START',
  REGISTER_SUCCESS = 'REGISTER_SUCCESS',
  REGISTER_FAILURE = 'REGISTER_FAILURE',

  UPDATE_USER_START = 'UPDATE_USER_START',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',

  CHECKLIST_EXPANDED = 'CHECKLIST_EXPANDED',
  CHECKLIST_COLLAPSED = 'CHECKLIST_COLLAPSED',

  LOGIN_WALLET_USER = 'CHECKLIST_EXPANDED',
  // CHECKLIST_COLLAPSED = 'CHECKLIST_COLLAPSED',

  DISMISS_MESSAGE = 'DISMISS_MESSAGE',
  DISPLAY_TOAST = 'DISPLAY_TOAST',

  CREATE_ORGANIZATION_START = 'CREATE_ORGANIZATION_START',
  CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS',
  CREATE_ORGANIZATION_FAILURE = 'CREATE_ORGANIZATION_FAILURE',

  ORGANIZATION_LIST_START = 'ORGANIZATION_LIST_START',
  ORGANIZATION_LIST_SUCCESS = 'ORGANIZATION_LIST_SUCCESS',

  CURRENT_ORGANIZATION_START = 'CURRENT_ORGANIZATION_START',
  CURRENT_ORGANIZATION_SUCCESS = 'CURRENT_ORGANIZATION_SUCCESS',

  GET_DRIVE_ID_SUCCESS = 'GET_DRIVE_ID_SUCCESS',
  GET_DRIVE_ID_FAILURE = 'GET_DRIVE_ID_FAILURE',

  GET_CAP_TABLE_START = 'GET_CAP_TABLE_START',
  GET_CAP_TABLE_SUCCESS = 'GET_CAP_TABLE_SUCCESS',
  GET_CAP_TABLE_FAILURE = 'GET_CAP_TABLE_FAILURE',

  GET_CERTIFICATE_LIST_START = 'CERTIFICATE_LIST_START',
  GET_CERTIFICATE_LIST_SUCCESS = 'CERTIFICATE_LIST_SUCCESS',
  GET_CERTIFICATE_LIST_FAILURE = 'CERTIFICATE_LIST_FAILURE',

  UPLOAD_CERTIFICATE_IMAGE_FAILURE = 'UPLOAD_CERTIFICATE_IMAGE_FAILURE',

  UPLOAD_CERTIFICATE_FRONT_SUCCESS = 'UPLOAD_CERTIFICATE_FRONT_SUCCESS',
  UPLOAD_CERTIFICATE_FRONT_FAILURE = 'UPLOAD_CERTIFICATE_FRONT_FAILURE',

  UPLOAD_CERTIFICATE_BACK_SUCCESS = 'UPLOAD_CERTIFICATE_BACK_SUCCESS',
  UPLOAD_CERTIFICATE_BACK_FAILURE = 'UPLOAD_CERTIFICATE_BACK_FAILURE',

  UPLOAD_CERTIFICATE_CSV_SUCCESS = 'UPLOAD_CERTIFICATE_CSV_SUCCESS',
  UPLOAD_CERTIFICATE_CSV_FAILURE = 'UPLOAD_CERTIFICATE_CSV_FAILURE',

  UPLOAD_TEAM_MEMBER_CSV_SUCCESS = 'UPLOAD_TEAM_MEMBER_CSV_SUCCESS',
  UPLOAD_TEAM_MEMBER_CSV_FAILURE = 'UPLOAD_TEAM_MEMBER_CSV_FAILURE',

  GET_RECENT_ACTIONS_START = 'GET_RECENT_ACTIONS_START',
  GET_RECENT_ACTIONS_SUCCESS = 'GET_RECENT_ACTIONS_SUCCESS',
  GET_RECENT_ACTIONS_FAILURE = 'GET_RECENT_ACTIONS_FAILURE',

  GET_GOOGLE_CONTACT_START = 'GET_GOOGLE_CONTACT_START',
  GET_GOOGLE_CONTACT_SUCCESS = 'GET_GOOGLE_CONTACT_SUCCESS',
  GET_GOOGLE_CONTACT_FAILURE = 'GET_GOOGLE_CONTACT_FAILURE',

  GET_REFERRAL_DATA_START = 'GET_REFERRAL_DATA_START',
  GET_REFERRAL_DATA_SUCCESS = 'GET_REFERRAL_DATA_SUCCESS',

  INVITED_FRIENDS_START = 'INVITED_FRIENDS_START',
  INVITED_FRIENDS_SUCCESS = 'INVITED_FRIENDS_SUCCESS',

  GET_ALL_SCRATCH_CARDS_START = 'GET_ALL_SCRATCH_CARDS_START',
  GET_ALL_SCRATCH_CARDS_SUCCESS = 'GET_ALL_SCRATCH_CARDS_SUCCESS',

  SCRATCH_CARDS_START = 'SCRATCH_CARDS_START',
  SCRATCH_CARDS_SUCCESS = 'SCRATCH_CARDS_SUCCESS',

  SEND_INVITATION_START = 'SEND_INVITATION_START',

  SUBMIT_INFORMATION_START = 'SUBMIT_INFORMATION_START',
  SUBMIT_INFORMATION_SUCCESS = 'SUBMIT_INFORMATION_SUCCESS',

  ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS',

  GET_ONBOARDING_STATUS_START = 'GET_ONBOARDING_STATUS_START',
  GET_ONBOARDING_STATUS_SUCCESS = 'GET_ONBOARDING_STATUS_SUCCESS',
  GET_ONBOARDING_STATUS_FAILURE = 'GET_ONBOARDING_STATUS_FAILURE',

  CHECK_VERIFICATION_START = 'CHECK_VERIFICATION_START',
  CHECK_VERIFICATION_SUCCESS = 'CHECK_VERIFICATION_SUCCESS',
}

// Action Creators
export type RegisterDispatch = (data: NewUserRequest, callback?: any) => void

export const register = (data: NewUserRequest, callback: any) => {
  return {
    type: ActionType.REGISTER_START,
    data,
    callback,
  }
}

export type LoginDispatch = (data: any, callback?: any) => void

export const login = (data: any, callback: any) => {
  return {
    type: ActionType.LOGIN_START,
    data,
    callback,
  }
}

export type LogoutDispatch = () => void

export const logout = () => {
  return {
    type: ActionType.LOGOUT_START,
  }
}

export type CheckVerification = (data: any, callback: (result: any) => void) => void

export const checkVerification = (data: any, callback: (result: any) => void) => {
  return {
    type: ActionType.CHECK_VERIFICATION_START,
    data,
    callback,
  }
}

export type GetCurrentUserDispatch = (callback?: any) => void

export const getCurrentUser = (callback?: any) => {
  return {
    type: ActionType.GET_CURRENT_USER_START,
    callback,
  }
}

export const setExpanded = () => {
  return {
    type: ActionType.CHECKLIST_EXPANDED,
  }
}

export const setCollapsed = () => {
  return {
    type: ActionType.CHECKLIST_COLLAPSED,
  }
}

export type DismissMessageDispatch = (data: string) => void

export const dismissMessage = (data: string) => {
  return {
    type: ActionType.DISMISS_MESSAGE,
    data,
  }
}

export type DisplayToastDispatch = (data: string) => void

export const displayToast = (key: string, type?: ToastTypes, message?: string) => {
  return {
    type: ActionType.DISPLAY_TOAST,
    data: { key, message, type },
  }
}

export type ResetPasswordDispatch = (data: PasswordResetRequest) => void

export const resetPassword = (data: PasswordResetRequest) => {
  return {
    type: ActionType.PASSWORD_RESET_START,
    data,
  }
}

export type UpdateUserProfileDispatch = (data: UpdateUserProfileRequest) => void

export const updateUserProfile = (data: UpdateUserProfileRequest) => {
  return {
    type: ActionType.UPDATE_USER_START,
    data,
  }
}

export type CreateOrganizationDispatch = (data: CreateOrganizationRequest) => void

export const createOrganization = (data: CreateOrganizationRequest) => {
  return {
    type: ActionType.CREATE_ORGANIZATION_START,
    data,
  }
}

export type GetOrganizationListDispatch = (callback?: any) => void

export const getOrganizationList = (callback?: any) => {
  return {
    type: ActionType.ORGANIZATION_LIST_START,
    callback,
  }
}

export type CurrentOrganizationDispatch = (request: string) => void

export const getCurrentOrganization = (data: string) => {
  return {
    type: ActionType.CURRENT_ORGANIZATION_START,
    data,
  }
}

export type GetReferralDataDispatch = (id: string) => void
export const getReferralData = (data: any) => {
  return {
    type: ActionType.GET_REFERRAL_DATA_START,
    data,
  }
}

export type GoogleContactDispatch = (request: string) => void
export const getGoogleContact = (data: any) => {
  return {
    type: ActionType.GET_GOOGLE_CONTACT_START,
    data,
  }
}

export type GetScratchCardDispatch = (id: string) => void
export const getAllScratchCards = (data: any) => {
  return {
    type: ActionType.GET_ALL_SCRATCH_CARDS_START,
    data,
  }
}

export type InvitedFriendsDispatch = (data: any) => void
export const invitedFriends = (data: any) => {
  return {
    type: ActionType.INVITED_FRIENDS_START,
    data,
  }
}

export type ScratchSingleCardDispatch = (data: any) => void
export const scratchCards = (data: any) => {
  return {
    type: ActionType.SCRATCH_CARDS_START,
    data,
  }
}

export type SendInvitationDispatch = (data: any) => void
export const sendInvitation = (data: any) => {
  return {
    type: ActionType.SEND_INVITATION_START,
    data,
  }
}

export type SubmitInformationDIspatch = (data: any) => void
export const submitInformation = (data: any) => {
  return {
    type: ActionType.SUBMIT_INFORMATION_START,
    data,
  }
}

export type GetRecentActionsDispatch = (data: any) => void

export const getRecentActions = (data: any) => {
  return {
    type: ActionType.GET_RECENT_ACTIONS_START,
    data,
  }
}
