import { Hash, StaticUrlPath, UrlPath } from '@helpers/util'
import { MemberUrlProps } from '@src/service'
import { CertificateUrlProps, OrganizationUrlProps } from '@logic'

export interface PlanUrlProps {
  plan: Hash
}

export interface PoolUrlProps {
  plan: Hash
  pool: Hash
}

export interface OptionUrlProps {
  option: Hash
}

export interface VestingUrlProps {
  vestingSchedule: Hash
}

export const plansUrl: UrlPath<OrganizationUrlProps> = `organization/:organization/plan`
export const plansUrlWithParam: UrlPath<OrganizationUrlProps & PlanUrlProps> = `/organization/:organization/plan/:plan`
export const planUrl: UrlPath<PlanUrlProps> = `plan/:plan`
export const planDashboardUrl: UrlPath<PlanUrlProps> = `${planUrl}/dashboard`
export const poolsUrl: UrlPath<PlanUrlProps> = `${planUrl}/pool`
export const poolUrl: UrlPath<PoolUrlProps> = `${poolsUrl}/:pool`
export const optionsUrl: UrlPath<PoolUrlProps> = `${poolUrl}/option`
export const optionUrl: UrlPath<OptionUrlProps> = `option/:option`
export const vestingsUrl: UrlPath<{}> = 'vestingSchedule'
export const vestingUrl: UrlPath<VestingUrlProps> = `${vestingsUrl}/:vestingSchedule`
export const exerciseUrl: UrlPath<OptionUrlProps & PoolUrlProps> = `${optionsUrl}/:option/exercise`
export const requestExerciseUrl: UrlPath<OptionUrlProps & PoolUrlProps> = `${exerciseUrl}/request`
export const projectScheduleUrl: StaticUrlPath = `schedule/project`
export const memberOptionsUrl: UrlPath<MemberUrlProps> = `member/:member/options`
export const organizationOptionsUrl: UrlPath<OrganizationUrlProps> = `organization/:organization/option`
export const authorizationsUrl: UrlPath<CertificateUrlProps> = `authorization`
export const organizationMemberUrl: UrlPath<OrganizationUrlProps & MemberUrlProps> = `organization/:organization/member/:member`
