import * as React from 'react'
import {
  PageProps,
  selectYourPlanPath,
  OrganizationProps,
  NewSubscriptionRequest,
  PaymentProfilesResponse,
  billingPath,
  GetReferralDataResponse,
  newPaymentProfilePath
} from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { BaseLinkProps } from '@components/navigation/navigation'
import styled from 'styled-components'
import { renderHeader, Sticky } from '@components/forms'
import { PaymentProfiles } from '../components/payment-profiles'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { CartSummary } from '../components/cart-summary'
import { newSubscription } from '@src/service'
import { loadPaymentProfiles, loadReferralsData, loadSubscriptionPreview, LoadSubscriptionPreviewProps } from '@shared/helpers'
import { PurchasePreviewProps } from '@src/service/services/billing'
import { newProcessingModal, newCloseModal } from '@modules/documents/components/modals'
import { ProgressBarHeader } from '../components/progress-bar-header'
import { CashAndPromo } from '../components/cash-and-promo-fields'
import { PathLinkButtonInverted } from '@shared/components/button'
import { CartSummaryModal } from '../components/cart-summary-modal'
import { needToTransferModal } from '../components/need-to-transfer-modal'

type Props =
  PageProps
  & OrganizationProps
  & PaymentProfilesResponse
  & LoadSubscriptionPreviewProps
  & PurchasePreviewProps
  & GetReferralDataResponse

const withData = withLoadingCachedMultiple<Props>(loadPaymentProfiles, loadSubscriptionPreview, loadReferralsData)

const CheckoutWrapper = styled.div<any>`
  padding-bottom: ${props => props.withPadding === 'true' ? '30px' : 0};
  a {
    width: 100%;
  }
`

export const CheckoutPage = withData(
  (props: Props) => {
    const [selectedProfile, setSelectedProfile] = React.useState<number>(0)
    const [couponValue, setCouponValue] = React.useState<number>(0)
    const [remove, setRemove] = React.useState<boolean>(false)
    const { httpClient, navigate, params, paymentProfiles, subscriptionPreview, userDetails } = props
    const { organization } = params
    const initialValues = {}
    const mobileView = window.innerWidth <= 800

    const balance = userDetails?.equaCash || 0
    const manifest = subscriptionPreview && subscriptionPreview.current_billing_manifest
    const nextManifest = subscriptionPreview && subscriptionPreview.next_billing_manifest // total in cents for annual fee
    React.useEffect(() => {
      if (paymentProfiles && paymentProfiles.length > 0)
        setSelectedProfile(paymentProfiles[0].payment_profile.id)
    }, [])
    const handleModalClose = () => {
      closeProcessingModal.setVisible(false)
      navigate(billingPath, { organization })
    }
    const paymentNotCompleted = () => {
      notCompletedModal.setVisible(false)
    }
    const paymentProcessingModal = newProcessingModal()
    const closeProcessingModal = newCloseModal(handleModalClose, 'Payment Complete!', 'Your payment has been processed successfully.')
    const notCompletedModal = newCloseModal(paymentNotCompleted, 'Payment Not Completed!', 'We were unable to process your payment information.')


    const onSubmit = async (values: any) => {
      const currentProfile = paymentProfiles.filter(arg => arg.payment_profile.id === selectedProfile)[0]
      const { billing_address, billing_city, billing_country, billing_state, billing_zip, id } = currentProfile.payment_profile
      const billingAddress = {
        country: billing_country,
        street1: billing_address,
        province: billing_state,
        city: billing_city,
        postalCode: billing_zip
      }
      const useEquaCash = values?.equaCash && values.equaCash <= balance && values.equaCash !== 0 ? true : false
      const newSubscriptionObj = {
        organization,
        billingAddress,
        paymentProfile: id,
        acceptedTerms: true,
        equaCash: useEquaCash ? values.equaCash : undefined,
        couponCode: values.couponCode!
      } as NewSubscriptionRequest

      paymentProcessingModal.setVisible(true)
      const newSub = await newSubscription(httpClient)({ organization }, newSubscriptionObj)
      if (newSub?.response) {
        paymentProcessingModal.setVisible(false)
        closeProcessingModal.setVisible(true)
      } else {
        paymentProcessingModal.setVisible(false)
        notCompletedModal.setVisible(true)
      }
    }

    const parent: BaseLinkProps = { path: selectYourPlanPath, args: { organization } }
    const selectProfile = (profile: number) => {
      setSelectedProfile(profile)
    }

    const handleLearnCancel = () => {
      learnModal.setVisible(false)
    }
    const learnModal = needToTransferModal({ onCancel: handleLearnCancel })

    const sticky: Sticky<any> = formProps => (
      !mobileView ?
        <CartSummary
          manifest={manifest}
          nextManifest={nextManifest}
          selectedProfile={selectedProfile}
          invalid={formProps.invalid}
          remove={remove}
          values={formProps.values}
          couponValue={couponValue}
          balance={balance} />
        : <div />
    )

    const RenderCartSummary = (formProps: any) => {
      const { values, invalid } = formProps
      const disableSubmit = manifest?.total_in_cents !== 0 ? selectedProfile === 0 ? true : invalid : true
      return <CartSummaryModal
        manifest={manifest}
        remove={remove}
        values={values}
        balance={balance}
        couponValue={couponValue}
        disableSubmit={disableSubmit}
      />
    }

    return (
      <>
        {learnModal.component}
        {paymentProcessingModal.component}
        {closeProcessingModal.component}
        {notCompletedModal.component}
        <ProgressBarHeader current={'check-out'} completed={'plan'} />
        <PageContent>
          <PageContentHeader title="Check Out" parent={parent} withSticky={true} />
          <CheckoutWrapper withPadding={mobileView ? 'true' : 'false'}>
            <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
              sticky={sticky}>
              {renderHeader({ label: 'Payment Method' })}
              <PaymentProfiles
                savedPayments={paymentProfiles}
                newCard={false}
                setNewCard={() => { }}
                selectedProfile={selectedProfile}
                setSelectedProfile={selectProfile}
                disableNewCard={true} />
              <PathLinkButtonInverted path={newPaymentProfilePath} args={{ organization, parent: window.location.pathname }}>
                Add new payment method
              </PathLinkButtonInverted>
              <CashAndPromo
                balance={balance}
                learnModalOpen={() => learnModal.setVisible(true)}
                remove={remove}
                setRemove={setRemove}
                httpClient={httpClient}
                setCouponValue={setCouponValue}
                manifest={manifest}
              />
              {mobileView ? <RenderCartSummary /> : <div />}
            </CommonForm>
          </CheckoutWrapper>
        </PageContent>
      </>
    )
  }
)
