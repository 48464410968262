import * as React from 'react'
import { colors, FieldPanel, FlexRow, LightDivider, Themes } from '@src/styles'
import { renderFieldsSimple, renderHeader, textInputFieldComponent } from '@components/forms'
import Cross from '@image/close-default.svg'
import { isEmail, nextInternalId } from '@shared/helpers'
import { FieldArray } from 'react-final-form-arrays'
import styled from 'styled-components'
import { CancelButton } from './button'
import _ from 'lodash'
import { EmailResponse } from 'service'

interface EmailSectionProps {
    name: string
    title?: string
}

const SharedStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start'
}

const AddEventBtn = styled<any>(CancelButton)`
  margin-top: 24px;
  color: ${colors.green} !important;
  height: 48px;
  line-height: 24px;
  border: none;
  background: transparent !important;
  &:hover {
    color: ${(props) => props.theme.white} !important;
    text-decoration: underline;
  }
`

const EventHeader = styled<any>(FlexRow)`
  margin-bottom: 24px;
  span {
    margin: 0 !important;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    font-family: 'NunitoSansSemiBold';
  }
`

const CrossWrap = styled<any>(FlexRow)`
  width: 48px;
  height: 48px;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.buttonHover};
    path {
      fill: ${(props) =>
        props.theme.selected !== Themes.LIGHT
            ? props.theme.white
            : colors.white};
    }
  }
  &:active {
    background: ${(props) => props.theme.buttonActive};
  }
`

const EmailTitle = styled.span<any>`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  font-family: 'NunitoSansSemiBold';
  color: #9CA6AD;
`

const EmailsWrap = styled.div<any>`
  div {
    margin-bottom: 24px;
    &:last-child {
      margin: 0;
    }
  }
  .email {
    font-size: 16px;
    line-height: 24px;
  }
`

const EmailsHolder = styled<any>(FlexRow)`
    height: 50px;
`

type Props = EmailSectionProps

export const EmailSection = (props: Props) => {
    const { name, title } = props
    const deleteField = (fields: any, index: number) => {
        const value = fields.value?.filter((v: any, i: number) => index === i)?.map(s => ({ ...s, isRemoved: true }))[0]
        fields.update(index, value)
    }

    const Fields = (
        name: string,
        fields: any,
        key: number,
        isLast: boolean,
        i: number,
        eventKey: number,
    ) => {
        return (
            <FieldPanel isLast={isLast} key={key}>
                <EventHeader justifyContent={'space-between'}>
                    <span>Email {eventKey}</span>
                    <CrossWrap onClick={() => deleteField(fields, i)}>
                        <Cross className='cross' />
                    </CrossWrap>
                </EventHeader>
                {renderFieldsSimple([
                    {
                        name: `${name}.email`,
                        label: 'Email Address',
                        required: true,
                        placeholder: 'Enter email address',
                        validate: isEmail,
                        component: textInputFieldComponent,
                        noMargin: true,
                    }
                ])}
            </FieldPanel>
        )
    }
    return (
        <>
            <FieldArray name={name || 'emails'}>
                {({ fields, meta }) => {
                    const addToField = () => fields.push({ key: nextInternalId() })
                    let eventKey: number = 0

                    const emailArray =
                        fields?.length! > 0
                            ? fields.map((name: string, i: number) => {
                                const key = fields?.value[i]?.key ? fields.value[i].key : i
                                const isLast = i === fields?.length! - 1
                                const removed = fields?.value[i]?.isRemoved
                                if (!removed) eventKey++
                                return !removed ? Fields(name, fields, key, isLast, i, eventKey) : null
                            })
                            : []
                    const currentFields = emailArray.filter(e => e)
                    return (
                        <div style={{ textAlign: 'start' }}>
                            {renderHeader({
                                label: title || 'Email Information',
                                index: 0,
                                buttonText: 'Add',
                                buttonOnClick: () => addToField()
                            })}
                            {emailArray}
                            {currentFields?.length > 0 && (
                                <AddEventBtn onClick={(e: MouseEvent) => { e.preventDefault(), addToField() }}>
                                    + Add Email
                                </AddEventBtn>
                            )}
                            <LightDivider stretch={true} />
                        </div>
                    )
                }}
            </FieldArray>
        </>
    )
}

export const renderEmails = (emails?: EmailResponse[]) => {
    return <EmailsWrap className='addWrap'>
        {emails && emails.length > 0 ? emails.map((emailValues, i: number) => {
            const { email } = emailValues
            return <FlexRow style={SharedStyle} key={i}>
                <EmailTitle>Email {i + 1}</EmailTitle>
                <span className='email'>{email}</span>
            </FlexRow>
        }) : <EmailsHolder />
        }
    </EmailsWrap>
}
