import { newGetFunction } from '@src/service'
import {
    accountUrl,
    ColorProps,
    colorsUrl,
    colorUrl,
    invitationUrl,
    profileUrl,
    referralUrl,
    scratchCardUrl,
    transactionsHistoryUrl,
    entityMembersUrl,
    UserProps
} from './urls'
import { Hash, Uuid } from '@shared/helpers'
import {
    EntityProps,
    GetReferralDataResponse,
    GetScratchCardResponse,
    InvitedFriendsResponse,
    MembersProps
} from '@logic'
import { holdingsUrl } from '../captable/urls'
import { GetColorResponse, GetColorsResponse, GetTransactionsHistoryResponse } from './responses'

export const getProfile = newGetFunction<{ profile: Hash }, {}>(profileUrl)
export const getPortfolioData = newGetFunction<{ entity: Hash }, {}>('entity/:entity/profile')
export const getAccount = newGetFunction<UserProps, {}>(accountUrl)
export const getEntityMembers = newGetFunction<{ entity: Hash }, MembersProps>(entityMembersUrl)
export const getUserHoldings = (entity: Hash) => newGetFunction<{}, {}>(entity ? `${holdingsUrl}?entity=${entity}` : holdingsUrl)
export const getColors = newGetFunction<UserProps, GetColorsResponse>(colorsUrl)
export const getColor = newGetFunction<ColorProps, GetColorResponse>(colorUrl)
export const getReferralsData = newGetFunction<EntityProps, GetReferralDataResponse>(referralUrl)
export const getInvitedFriends = (organization?: Uuid) => newGetFunction<{}, InvitedFriendsResponse>(`${invitationUrl}${organization ? `?organization=${organization}` : ''}`)
export const getAllScratchCards = (organization?: Uuid) => newGetFunction<{}, GetScratchCardResponse>(`${scratchCardUrl}${organization ? `?organization=${organization}` : ''}`)
export const getTransactionsHistory = newGetFunction<EntityProps, GetTransactionsHistoryResponse>(transactionsHistoryUrl)
