import * as React from 'react'
import styled from 'styled-components'
import { gapSizes } from '@src/styles/theme'
import { StyledTableArrow } from '@shared/components/tables'
import CapTableArrow from '@image/table-arrow.svg'
import { FlexRow, FlexCol, StyledCertificateImage } from '@src/styles'
import { CertificateLink } from '@modules/captable/components/certificate-link'
import { ShareholdingResponse, captableCertificatePath } from '@logic'
import { formatDateString, toCommaFloat } from '@shared/helpers'
// @ts-ignore
import CertificateImage from '@image/certificate-image.jpg'
import { optionalUsdString } from '@modules/convertibles/components'
import { rgba } from 'polished'

interface Props {
  id: number
  name?: string
  shareholdings?: ShareholdingResponse[]
  invested?: number
  outstanding?: number
  fullyDiluted?: number
  fixed?: boolean
  collapsed: number[]
  setCollapsed: any
  isSelected: boolean
}

const rowHeight = '60px'
const TableRow = styled.tr<any>`
  height: ${rowHeight};
  padding: 0 ${gapSizes.S};
  border: ${props => props.expanded ? 'none !important' : ''};
  td:not(:first-child) {
    opacity: ${props => props.isSelected ? '' : '0.2'};
  }
`
const StyledCell = styled.td`
span{
  float: left;
  margin-right: ${gapSizes.L};
}
`

export const StyledTd = styled.td<any>`
  &,
  a {
    color: ${props => props.voided && rgba(props.theme.white, 0.35)};
  }
  a:after {
    content: '-Cancelled';
    display: ${props => (props.voided ? 'initial' : 'none')};
    pointer-events: all;
  }
  img {
    opacity: ${props => props.voided ? '0.35' : '1'};
  }
`

export function CollapsibleRow(props: Props) {
  const { name,
    shareholdings,
    invested,
    outstanding,
    fixed,
    collapsed,
    setCollapsed,
    id,
    isSelected,
    fullyDiluted } = props

  return (
    <>
      <TableRow isSelected={isSelected}>
        <td />
        {fixed ? <StyledCell>
          <StyledTableArrow shouldRotate={collapsed.includes(id)} onClick={() => collapsed.includes(id) ?
            setCollapsed(collapsed.filter(el => el !== id)) : setCollapsed(collapsed.concat(id))}>
            <CapTableArrow />
          </StyledTableArrow>
          {name}
        </StyledCell>
          :
          <>
            <td>{optionalUsdString(invested)}</td>
            <td>{toCommaFloat(outstanding)}</td>
            <td>{toCommaFloat(fullyDiluted || outstanding)}</td>
            <td></td>
          </>
        }
      </TableRow>
      {collapsed.includes(id) &&
        <>
          {shareholdings && shareholdings.length > 0 && shareholdings.map(((shareholding, i: number) => {
            return <TableRow key={i} isSelected={isSelected} expanded={collapsed.includes(id)}>
              <td />
              {fixed ?
                <StyledTd voided={shareholding.voided}>
                  <FlexRow style={{ marginLeft: `${gapSizes.L}` }}>
                    <StyledCertificateImage src={CertificateImage} />
                    <FlexCol alignItems="flex-start" style={{ marginLeft: '10px' }}>
                      <CertificateLink holding={shareholding} path={captableCertificatePath} />
                      <div>Issued {formatDateString(shareholding.issueDate!)}</div>
                    </FlexCol>
                  </FlexRow>
                </StyledTd> :
                <>
                  <StyledTd voided={shareholding.voided}>
                    {optionalUsdString(shareholding.voided ? 0 : shareholding.capitalContribution)}
                  </StyledTd>
                  <StyledTd voided={shareholding.voided}>
                    {toCommaFloat((shareholding.voided || shareholding.notDraft) ? 0 : shareholding.value)}
                  </StyledTd>
                  <StyledTd voided={shareholding.voided}>
                    {toCommaFloat(shareholding.voided ? 0 : shareholding.value)}
                  </StyledTd>
                  <StyledTd />
                </>
              }
            </TableRow>
          }))}
        </>
      }
    </>
  )
}
