import * as React from 'react'
import styled from 'styled-components'
import { FlowingWrap, NormalHeader } from '@modules/referrals/style'
import { Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { InvitationDetails } from '@logic'
import { formatDateString } from '@shared/helpers'

interface Props {
    invitationDetails: InvitationDetails
    forOrg?: boolean
}

const ReferralsHeader = styled<any>(NormalHeader)`
  margin: 64px 0 0 0;
`

const ReferralsTableWrap = styled<any>(FlowingWrap)`
    table {
        td {
            padding: 16px;
            text-align: start;
        }
    }
    div {
        width: 100%;
    }
`

export const ReferralsTable = (props: Props) => {
    const { invitationDetails, forOrg } = props
    const invitations = invitationDetails?.invitations!!
    const columns = [
        {
            accessor: 'date',
            Cell: ({ row }: CellProps<any>) => <span>{row.original.date && formatDateString(row.original.date)}</span>,
        },
        {
            accessor: 'email',
            Cell: ({ row }: CellProps<any>) => <span>{row.original.email}</span>,
        },
    ]
    const data = invitations ? invitations.map(invitation => ({ date: invitation.created, email: invitation.email })) : []

    return (
        <ReferralsTableWrap>
            <ReferralsHeader>Your {forOrg ? 'Org' : 'Profile'} Referrals</ReferralsHeader>
            <span style={{ margin: '16px 0' }}>Total {forOrg ? 'Org' : 'Profile'} Referrals: {invitations ? invitations.length : 0}</span>
            <Table columns={columns} data={data} withAllBorders={true} noHeader={true} noFooter={true} pageSize={10} />
        </ReferralsTableWrap>
    )
}
