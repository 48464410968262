import * as React from 'react'
import { MembersProps, PageProps, permissionsPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadMembers, loadRoles } from '@helpers/loaders'
import { editRole, GetRolesResponse, UpdateRoleRequest } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import styled from 'styled-components'
import { FlexCol, FlexRow, whiteColor } from '@src/styles'
import { SubHeaderText } from './create-role-page'
import { allPermissions, equals } from '../utility'
import { RolePermissionsTable } from '../components/role-permissions-table'
import { CommonForm, Roles } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import EllipsesIcon from '@image/ellipses.svg'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetRolesResponse & MembersProps

const PermissionsFormWrapper = styled.div<any>`
    label {
        ${whiteColor}
    }
`

const withData = withLoadingCachedMultiple<Props>(loadRoles, loadMembers)


function prepareRolesForSubmission(values: any): any {
    const roles = values.roles
    if (roles) {
        const data = Object.entries(roles).map(([key, value]: any) => {
            const permission = value?.permission && Object.entries(value?.permission!)?.map((value: any) => {
                if (value[1]) return value[0]
            })?.filter(v => v)
            return { ...value, permission }
        })
        return data
    }
    else return []
}

export const EditPermissionsPage = withData(withPermissions([BuiltInPermission.editMembers])((props: Props) => {
    const { navigate, params, roles, httpClient, members, reload } = props
    const { organization } = params
    const filtered = roles?.filter(r => r.id !== Roles.admin)
    const admin = roles?.filter(r => r.id === Roles.admin)
    const sortedRoles = admin.concat(filtered?.sort((a, b) => {
        return a.name.localeCompare(b.name)
    }))

    const role: any = {}
    const areShared: any = {}
    sortedRoles?.forEach(r => {
        const permission: any = {}
        r.permissions.forEach(p => permission[`${p.id}`] = true)
        role[`${r.id}`] = { ...r, permission }
        if (r?.permissions?.length >= allPermissions.length) areShared[`${r.id}`] = true
    })

    const initialValues = {
        roles: role,
        ...areShared
    }

    const onSubmit = async (values: any) => {
        const data = prepareRolesForSubmission(values)
        await Promise.all(data.map(async (role: any) => {
            const current = roles?.find(r => r.id === role?.id!)
            const currentPermissions = current?.permissions?.map(p => p.id)
            const permissionsChanged = !equals(currentPermissions, role?.permission!)
            if (!current?.isShared && permissionsChanged) {
                const request = {
                    role: role?.id!,
                    newRole: role?.name!,
                    description: role?.description!,
                    permission: role?.permission!,
                } as UpdateRoleRequest
                await editRole(httpClient)({ organization, role: role?.id! }, request)
            }
            return role
        }))
        navigate(permissionsPath, { organization })
    }

    const parent: BaseLinkProps = { path: permissionsPath, args: { organization } }

    return (
        <PageContent>
            <PageContentHeader title={'Edit Your Permissions'} parent={parent} />
            <FlexCol alignItems={'flex-start'}>
                <SubHeaderText style={{ maxWidth: '100%' }}>Use this page to quickly view and compare all of the permissions assigned to your roles in one place.</SubHeaderText>
                <SubHeaderText style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
                    <EllipsesIcon />Edit, Clone, or Delete Options
                </SubHeaderText>
            </FlexCol>
            <PermissionsFormWrapper>
                <CommonForm
                    mutators={{ ...arrayMutators }}
                    onSubmit={onSubmit}
                    initialValues={initialValues}
                    stretched={true}
                >
                    {sortedRoles &&
                        <RolePermissionsTable
                            roles={sortedRoles}
                            edit={true}
                            navigate={navigate}
                            organization={organization}
                            httpClient={httpClient}
                            members={members}
                            reload={reload}
                        />
                    }
                </CommonForm>
            </PermissionsFormWrapper>
        </PageContent>
    )
}))
