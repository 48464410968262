import { Paths, certificateVestingSchedulePath, certificateApprovalsPath } from '@logic'
import * as React from 'react'
import { HeaderLink, LinksWrap, MenuRow, NestedOption, SubLink } from '@src/app/components/header/style'
import { matchesPath, NavigationProps, withNavigation } from '@components/navigation/navigation'
import DownArrow from '@image/carrot-down.svg'
import Tick from '@image/table-tick-16x16.svg'
import { FlexRow } from '@src/styles'
import { SmallBetaNotify } from './shared/profile-drop'

interface LinkProps {
  path: string
  title: string
  exact?: boolean // Deprecated
  description?: string
  defaultUrl?: string
  disabledPath?: boolean
  beta?: boolean
}

export interface HeaderLinkProps extends LinkProps {
  subLinks?: LinkProps[]
}

export interface HeaderLinksProps {
  links: HeaderLinkProps[]
}

type Props = NavigationProps & HeaderLinksProps

const disabledPaths = [
  Paths.governingDocuments,
  certificateVestingSchedulePath,
  certificateApprovalsPath,
]

const toHeaderLink = (currentPath: string, args: any) => ({ path, title, exact, defaultUrl, subLinks, disabledPath, beta }: HeaderLinkProps) => {
  const disabled = disabledPaths.indexOf(path) !== -1
  const [open, setOpen] = React.useState<any>(false)
  const styleChange = window.innerWidth < 1290

  return !disabledPath ? (
    <FlexRow
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      style={{ height: '57px' }}>
      {subLinks ?
        <NestedOption selected={matchesPath(path, currentPath, exact || false)}>
          <span className='headerTitle' name={title}>{title}</span>
          <DownArrow className='down' name={`${title}-headerDownArrow`} />
          {open ?
            <LinksWrap className='subHeader'
              onMouseLeave={() => setOpen(false)}
            >
              {subLinks.map((l) => {
                const selected = matchesPath(l.path, currentPath, l.exact || false)
                return <SubLink
                  key={l.path}
                  disabled={disabled}
                  selected={selected}
                  path={l.defaultUrl || l.path}
                  args={args}
                >
                  <FlexRow className='item'>
                    {selected && <Tick />}
                    <span name={l.title}>{l.title}</span>
                  </FlexRow>
                </SubLink>
              })}
            </LinksWrap> : null
          }
        </NestedOption>
        :
        <HeaderLink
          key={path}
          disabled={disabled}
          selected={matchesPath(path, currentPath, exact || false)}
          path={defaultUrl || path}
          args={args}
          styleChange={styleChange ? 'true' : 'false'}
        >
          <span className='headerTitle' name={title}>{title}</span>
          {beta ? <SmallBetaNotify>Beta</SmallBetaNotify> : null}
        </HeaderLink>
      }

    </FlexRow>
  ) : null
}

export function renderHeaderLinks(currentPath: string, args: any, links: HeaderLinkProps[]) {
  return links.map(toHeaderLink(currentPath, args))
}

const subHeaderDisabled: any[] = []

export const HeaderLinks = withNavigation(
  (props: Props) => {
    const { location, links, params } = props
    const headerLinks = renderHeaderLinks(location.pathname, params, links)
    let disabled: boolean = false
    subHeaderDisabled?.every(s => {
      disabled = matchesPath(s, location.pathname, true)
      if (disabled) return false
    })
    return !disabled && <MenuRow>{headerLinks}</MenuRow>
  }
)