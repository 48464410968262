import { UrlPath, Uuid } from '@shared/helpers'

export interface WalletUrlProps {
    wallet: Uuid
}

export interface EntityUrlProps {
    entity: Uuid
}

export interface AddressTokensProps {
    address: string
}

export const walletUrl: UrlPath<{}> = 'wallet'
export const editWalletUrl: UrlPath<WalletUrlProps> = `${walletUrl}/:wallet`
export const entityWalletsUrl: UrlPath<EntityUrlProps> = `entity/:entity/${walletUrl}`
export const entityWalletUrl: UrlPath<EntityUrlProps & { address: Uuid }> = `${entityWalletsUrl}/:address`
export const supportedProtocolsUrl: UrlPath<AddressTokensProps> =
    'https://api.zapper.fi/v1/protocols/balances/supported?api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241'
export const ensUrl: UrlPath<AddressTokensProps> =
    'https://api.zapper.fi/v1/ens?api_key=96e0cc51-a62e-42ca-acee-910ea7d2a241&addresses[]=:address'