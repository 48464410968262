import * as React from 'react'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { TransactionHistoryTable } from '@modules/referrals/components'
import { semiBoldStyle, whiteColor } from '@src/styles'
import styled from 'styled-components'

const TableDescription = styled.span<any>`
    ${whiteColor}
    ${semiBoldStyle}
    font-size: 18px;
    line-height: 26px;
`

export const GuestTransferHistoryPage = () => {
    return (
        <PageContent withMargin={true}>
            <PageContentHeader title={'Latest Transaction History'} hideCrumbs={true} />
            <TransactionHistoryTable
                transactionRecords={[]}
                organizations={[]}
                userProfile={{}}
                description={<>
                    <TableDescription>No transactions to display.</TableDescription>
                </>} />
        </PageContent>
    )
}
