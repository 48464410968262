import * as React from 'react'
import { OrganizationProps, PageProps, organizationDetailsEditPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadAddresses, loadEmails, loadOrganization } from '@helpers/loaders'
import { FlexCol, FlexRow, fontSizes, LightDivider, OrgDetailsWrapper } from '@src/styles'
import { Avatar } from '@components/avatar'
import { PathLinkButtonInverted } from '@components/button/button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getCompanyType } from '@shared/helpers/constants'
import { formatToDate } from '@shared/helpers'
import { GetAddressesResponse, GetEmailsResponse } from '@src/service'
import { countries } from '@shared/helpers/countries'
import { renderEmails } from '@shared/components/email-section'
import InfoCircle from '@image/info-circle.svg'
import Copy from '@image/copy.svg'
import { CopyHover, CopyWrap } from '@modules/profile'

type Props = PageProps & OrganizationProps & GetAddressesResponse & GetEmailsResponse

const withData = withLoadingCachedMultiple<Props>(loadOrganization, loadAddresses('organization'), loadEmails('organization'))

const SharedStyle = {
  flexDirection: 'column',
  alignItems: 'flex-start'
}

const InfoStyledRow = styled<any>(FlexRow)`
  p, span {
    font-size: ${fontSizes.S};
    line-height: 25px;
  }
  p {
    margin: 0 10px 0 0;
  }
`

const AddressTitle = styled.span<any>`
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  font-family: 'NunitoSansSemiBold';
  color: #9CA6AD;
`

const AddressesWrap = styled.div<any>`
  div {
    margin-bottom: 24px;
    &:last-child {
      margin: 0;
    }
  }
`

const StyledDivider = styled<any>(LightDivider)`
  max-width: 100%;
`

const EquaIDWrap = styled<any>(FlexRow)`
  padding-top: 40px;
  position: relative;
  svg {
    cursor: auto !important;
  }
`
export const InfoWrap = styled<any>(FlexRow)`
  max-width: 348px;
  font-weight: 600;
  font-family: 'NunitoSansSemiBold';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 10px;
  left: 20px;
  background: #FAFAFA;
  padding: 2px 8px;
  border-radius: 4px;
  color: #262626;
`

export const renderAddress = (addresses?: any) => {
  return <AddressesWrap className='addWrap'>
    {addresses?.map((address: any, i: number) => {
      const { street1, street2, street3, province, city, country, postalCode, groups } = address
      const countryName = countries?.find(c => c.alpha3 === country)?.label
      return <FlexRow style={SharedStyle} key={i}>
        <AddressTitle>Address {i + 1}</AddressTitle>
        <span>{groups?.map((g, index: number) => {
          if (index === (groups.length - 1)) return g.name
          else if (index < groups.length - 1) {
            return `${g.name}, `
          }
          else return g.name
        })}</span>
        <span>
          {street1}{street2 && `, ${street2}`}{street3 && `, ${street3}`}
        </span>
        <span>
          {city},{' '}{address?.provinceAbbreviation ? address?.provinceAbbreviation : province}{' '}{postalCode}
        </span>
        <span>{countryName ? countryName : country}</span>
      </FlexRow>
    })}
  </AddressesWrap>
}

const renderOrgInfo = (info: any | undefined) => {
  return info.map(s => (
    <FlexCol alignItems={'start'}>
      <InfoStyledRow>
        <p>{s.title}</p>
        <span>
          {s.value!}
        </span>
      </InfoStyledRow>
    </FlexCol>
  ))
}

export const ViewOrganizationDetailsPage = withData((props: Props) => {
  const [copy, setCopy] = React.useState<boolean>(false)
  const [info, setInfo] = React.useState<boolean>(false)
  const { organization, addresses, emails } = props
  const orgInfo = [
    { title: 'Business Type Tax Filing Status: ', value: organization?.companyType && getCompanyType[organization.companyType] },
    { title: 'Articles of Incorporation Registration Number: ', value: organization?.registrationNumber! },
    { title: 'Organization Start Date: ', value: formatToDate(organization.startDate) },
    // { title: 'Sales Tax ID: ', value: '' },
    // { title: 'EIN: ', value: '' },
    // { title: 'Unemployment Tax ID: ', value: '' },
  ]

  const copyCodeToClipboard = (label: string) => {
    const el = document.getElementById(label)
    if (el) {
      navigator.clipboard.writeText(el.innerText!)
      setCopy(true)
    }
  }

  return (
    <PageContent>
      <PageContentHeader title="Organization Details">
        <PathLinkButtonInverted path={organizationDetailsEditPath} args={{ organization: organization?.id }}>
          Edit
        </PathLinkButtonInverted>
      </PageContentHeader>
      <OrgDetailsWrapper>
        <FlexRow>
          <h4 style={{ paddingTop: '0' }}>Organization Name & Logo</h4>
        </FlexRow>
        <FlexRow>
          <Avatar size="70px" fullName={organization?.name!} image={organization?.logo?.hash} /> <span>{organization?.name!}</span>
        </FlexRow>
        <EquaIDWrap>
          <h4>Equa ID</h4>
          <InfoCircle onMouseEnter={() => setInfo(true)} onMouseLeave={() => setInfo(false)} />
          {info ? <InfoWrap>
            Your organization’s unique ID on the Equa platform.
            Others can use this ID to add your organization to their team.
          </InfoWrap> : null
          }
        </EquaIDWrap>
        <div className='idCopy'>
          <span className='greyed' id={'equaId'}>{organization?.id && `${organization?.id}`}</span>
          <CopyWrap
            copy={copy}
            onClick={() => copyCodeToClipboard('equaId')}
            onMouseLeave={() => setCopy(false)}>
            <div className='arrow-up' />
            <CopyHover copy={copy}>
              <span>{copy ? 'Copied!' : 'Copy'}</span>
            </CopyHover>
            <Copy />
          </CopyWrap>
        </div>
        <StyledDivider />
        <FlexRow>
          <h4>Phone Number</h4>
        </FlexRow>
        <span className='greyed'>{organization?.phoneNumber && `+${organization?.phoneNumber}`}</span>
        <StyledDivider />
        <FlexRow>
          <h4>Address Information</h4>
        </FlexRow>
        {renderAddress(addresses)}
        <StyledDivider />
        <FlexRow>
          <h4>Billing / Accounting Email Information</h4>
        </FlexRow>
        {renderEmails(emails)}
        <StyledDivider />
        <FlexRow>
          <h4>Important Organization Information</h4>
        </FlexRow>
        {renderOrgInfo(orgInfo)}
        <StyledDivider />
        <FlexRow>
          <h4>Important Organization Number(s)</h4>
        </FlexRow>
        {organization?.taxIds?.map((el, index: number) => {
          return (
            <InfoStyledRow key={index} style={{ display: 'flex' }}>
              <p>{el.name}:</p>
              <span>{' '}{el.value}</span>
            </InfoStyledRow>
          )
        })
        }
        <StyledDivider />
        <FlexRow><h4>Website</h4></FlexRow>
        <FlexRow>
          {
            organization?.website && <Link
              to={{ pathname: organization.website.includes('http') ? organization.website : `http://${organization.website}` }}
              style={{ textDecoration: 'underline' }}
              target="_blank">
              {organization.website}
            </Link>
          }
        </FlexRow>
        <StyledDivider />
      </OrgDetailsWrapper>
    </PageContent>
  )
})
