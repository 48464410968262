import * as React from 'react'
import { PageProps, organizationLegendsPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { LegendForm } from '../components/legend-form'
import { editLegend, EditLegendRequest, GetLegendResponse } from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { loadLegend } from '@helpers/loaders'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'

type Props = PageProps & GetLegendResponse

const withData = withLoadingCachedMultiple<Props>(loadLegend)

export const EditLegendPage = withData(
  withPermissions([BuiltInPermission.editCapTable, BuiltInPermission.viewCapTable])((props: Props) => {
    const { httpClient, navigate, params, legend } = props
    const { organization } = params
    const { name, content, hash } = legend
    const initialValues = { name, content }

    const onSubmit = async (values: EditLegendRequest) => {
      const { name, content } = values
      const updateReferences = values?.updateReferences ? true : undefined
      const request = {
        name,
        content,
        updateReferences
      }

      const response = await editLegend(httpClient)({ organization, legend: hash }, request)
      if (response) navigate(organizationLegendsPath, { organization })
      else console.log('edit Organization error') // TODO: add error message toast
    }

    const parent: BaseLinkProps = { path: organizationLegendsPath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title={`Edit "${name}" Legend`} parent={parent} />
        <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
          submitText="Save">
          <LegendForm edit={true} />
        </CommonForm>
      </PageContent>
    )
  }
  ))
