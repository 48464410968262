import * as React from 'react'
import { PageProps } from '@logic'
import { MyReferralsShared } from '@modules/referrals/components/referral-component'
import { signUpModal } from '@shared/components/modal'

type Props = PageProps

export const GuestReferrals = (props: Props) => {
    const { navigate } = props
    const signUpModalBundle = signUpModal({ navigate })

    return <>
        {signUpModalBundle.component}
        <MyReferralsShared {...props} forGuest={true} openSignUp={() => signUpModalBundle.setVisible(true)} />
    </>
}
