import * as React from 'react'
import { PageProps, ToastTypes } from '@logic'
import { PageContent } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadUserState, loadWalletAddresses } from '@helpers/loaders'
import { FlexRow, screenSizes } from '@src/styles'
import { Tabs } from '@shared/components/tabs/tabs'
import { BetaNotify } from './wallet-portfolio'
import { getNameForAddress, walletTabs } from '../utility'
import { SolidButton } from '@shared/components/button'
import { connectWalletModal } from '../components/modal/connect-wallet-modal'
import {
    deleteWalletAddress,
    editUserState,
    editWalletAddress,
    EditWalletAddressRequest,
    getENS,
    globalDisplayToast,
    newWalletAddress,
    NewWalletAddressRequest
} from '@src/service'
import { dynamicAddField } from '@shared/components/dynamic-add-field'
import { ellipsesColumn, StyledTable, Table } from '@shared/components/tables'
import styled from 'styled-components'
import { basicDeleteModal } from '@shared/components/modal'
import { newEditAddressModal } from '../components/modal/edit-wallet-address'
import { successModal } from '../components/modal/success-modal'

const AddressBookWrap = styled.div`
    ${StyledTable} {
        max-width: 845px !important;
        margin-top: 24px;
    }
    table {
        min-width: 100%;
        thead tr {
            background-color: rgba(255,255,255,0.05) !important;
        }
        th, td {
            border-right: 0;
        }
        tr {
            td {
                font-weight: normal !important;
                font-family: NunitoSans !important;
            }
        }
    }
`

const StyledAddRow = styled<any>(FlexRow)`
    width: 100%;
    max-width: 758px;
    .connectButton {
        margin-left: 16px;
    }
    @media (max-width: ${screenSizes.S}px) {
        flex-direction: column;
        .connectButton {
            margin: 16px 0 0 0;
        }
    }
`

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>(loadUserState, loadWalletAddresses)

export const WalletAddressBook = withData((props: Props) => {
    const [installed, setInstalled] = React.useState<boolean>(false)
    const [selectedAddress, setSelectedAddress] = React.useState<any>()
    const { params, httpClient, user, reload } = props
    const records = props?.walletAddressRecords!!
    const userState = props?.userStateRecords!!

    const tableData = records ? records.map((r: any) => {
        const selectedName = getNameForAddress(r.name, r.globalName)
        const isAddress = selectedName.match(/^0x[a-fA-F0-9]{40}$/)
        const abbName = isAddress ?
            selectedName.slice(0, 6) + '...' + selectedName.slice(selectedName.length - 4, selectedName.length)
            : selectedName
        return { ...r, abbName }
    }) : []

    const addField = dynamicAddField({
        placeholder: 'Enter address...',
        onClick: () => addUser([addField.inputValue], true)
    })
    const AddField = addField.component
    const closeSuccessModal = () => {
        successModalBundle.setVisible(false)
        reload()
    }
    const successModalBundle = successModal({ onActivate: () => closeSuccessModal() })

    const addUser = async (accounts: any, get?: boolean) => {
        if (accounts.length > 0) {
            const ensName: any = await getENS(httpClient)({ address: accounts[0] })
            const addressRequest: NewWalletAddressRequest = {
                entity: user?.id || '',
                address: accounts[0],
                name: accounts[0],
                globalName: ensName && ensName[0] !== null ? ensName[0] : accounts[0]
            }
            const res = await newWalletAddress(httpClient)({}, addressRequest)
            if (res) globalDisplayToast(ToastTypes.success, 'Address successfully added to address book')
            if (get) reload()
        }
    }

    const onClickConnect = async () => {
        const { ethereum } = window as any
        try {
            if (userState && userState.walletUser) {
                await ethereum.request({
                    method: "wallet_requestPermissions",
                    params: [
                        {
                            eth_accounts: {}
                        }
                    ]
                })
            }
            else await ethereum.request({ method: 'eth_requestAccounts' })
            const accounts = await ethereum.request({ method: 'eth_accounts' })
            if (accounts.length > 0) {
                await editUserState(httpClient)({}, { userState: { ...userState, walletUser: accounts[0] } })
                addUser(accounts)
                connectWalletBundle.setVisible(false)
                successModalBundle.setVisible(true)
            }
            else connectWalletBundle.setVisible(false)
        } catch (error) {
            console.error(error)
        }
    }

    const connectWalletBundle = connectWalletModal({ installed, onActivate: onClickConnect })

    const initialize = () => {
        const isMetaMaskInstalled = () => {
            const { ethereum } = window as any
            return Boolean(ethereum && ethereum.isMetaMask)
        }

        const metaMaskClientCheck = () => {
            if (!isMetaMaskInstalled()) setInstalled(false)
            else setInstalled(true)
            connectWalletBundle.setVisible(true)
        }
        metaMaskClientCheck()
    }

    const handleDeleteAddress = async () => {
        await deleteWalletAddress(httpClient)({ entity: selectedAddress?.entity, address: selectedAddress?.address })
        deleteModal.setVisible(false)
        reload()
    }

    const deleteModal = basicDeleteModal(
        handleDeleteAddress,
        [selectedAddress?.address || ''],
        '', '',
        'You are about to delete the following item from your address book:'
    )

    const editAddress = async (values: any) => {
        const editAddressRequest = {
            entity: selectedAddress?.entity,
            address: values.address,
            name: values.name,
            globalName: selectedAddress?.globalName
        } as EditWalletAddressRequest
        const response = await editWalletAddress(httpClient)({ wallet: selectedAddress?.id }, editAddressRequest)
        if (response) {
            editAddressModal.setVisible(false)
            reload()
        }
    }

    const editAddressModal = newEditAddressModal(
        values => editAddress(values),
        {
            name: getNameForAddress(selectedAddress?.name, selectedAddress?.globalName),
            address: selectedAddress?.address || '',
        }
    )

    const columns = [
        {
            Header: 'Name',
            accessor: 'abbName',
        },
        {
            Header: 'Address',
            accessor: 'address',
            className: 'left'
        },
        ellipsesColumn(
            [
                ['Edit', ({ row }) => { setSelectedAddress(row.original), editAddressModal.setVisible(true) }],
                ['Delete', ({ row }) => { setSelectedAddress(row.original), deleteModal.setVisible(true) }]
            ]
        ),
    ]

    // ethereum?.on('accountsChanged', async (accounts: any) => {
    //     const accts = await ethereum.enable();
    //     await editUserState(httpClient)({}, { userState: { ...userState, walletUser: accts[0] } })
    //     addUser(accts, true)
    // })

    return (
        <>
            {editAddressModal.component}
            {deleteModal.component}
            {connectWalletBundle.component}
            {successModalBundle.component}
            <Tabs tabs={walletTabs} title={
                <>
                    Wallets
                    <BetaNotify>Beta</BetaNotify>
                </>
            } params={params} />
            <PageContent withMargin={true}>
                <AddressBookWrap>
                    <StyledAddRow alignItems={'flex-start'}>
                        {AddField}
                        <SolidButton
                            id={'connectButton'}
                            className='connectButton'
                            onClick={() => initialize()}>CONNECT A WALLET</SolidButton>
                    </StyledAddRow>
                    <Table
                        columns={columns}
                        data={tableData}
                        description={tableData.length > 0 ? undefined : <span>Your address book is empty.</span>}
                        noFooter={tableData.length > 0 ? false : true}
                    />
                </AddressBookWrap>
            </PageContent>
        </>
    )
})
