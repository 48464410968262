import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { checkVerification, CheckVerification, PageProps, Paths, User } from '@logic'
import { AppContext } from '@src/app'
import { HttpError, Service } from '@src/service'
import queryString from 'query-string'
import { isPromo } from '@helpers/promo'
import { StateTransform } from '@src/index'
import { StyledAuth, StyledAuthForm, StyledTopHeader } from '../style'
import VerifyLogo from '@image/verify-email.svg'

interface Props extends PageProps {
  checkVerification: CheckVerification
}


const StyledVerify = styled.div`
    text-align: center;
  label {
    display: inline-block;
    margin-bottom: 30px;
  }
  svg {
    margin-bottom: 24px;
  }
  a {
    font-size: 14px;
    white-space: nowrap;
  }
`

export const SendEmail = (props: Props) => {
    return (
      <StyledAuth>
        <StyledAuthForm>
          <StyledVerify>
          <div><VerifyLogo/></div>
          <StyledTopHeader>
        Email Sent
          </StyledTopHeader>
            <label>Please check your email for a link to reset your password.</label>
            <br />
            <a onClick={() => props.serviceOld.passwordReset({ usernameOrEmail: props?.location?.state?.email ? props.location.state.email : " "  })}>
              Resend Email
            </a>
          </StyledVerify>
        </StyledAuthForm>
      </StyledAuth>
    )
}