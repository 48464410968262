import * as React from 'react'
import { CarouselDot, NoTicketCont, ResultBox, TicketsCont, TicketsWrap } from './style'
import CarrotLeft from '@image/carrot-left.svg'
import CarrotRight from '@image/carrot-right.svg'
import NoTicketImg from '@image/noticket.png'
import TicketImg from '@image/ticket.png'
import EquaCashSymbol from '@image/equacashsign.png'
import ScratchBox from '@image/scratchbox.png'
import ScratchCard from '../scratch-card/scratch-card'
import _ from 'lodash'
import { FlexCol, FlexRow } from '@src/styles'

interface Props {
  rewards: any[]
  wasScratchAllClicked: boolean
  scratchTicket: (id: string) => void
}

export const getCanvasSettings = () => {
  const settings: any = {
    width: 150,
    height: 152,
    image: ScratchBox,
    finishPercent: 50,
  }
  const screenWidth: any = window.innerWidth
  if (screenWidth <= 800) return {
    width: 118,
    height: 120,
    image: ScratchBox,
    finishPercent: 50,
  }
  else return settings
}

export const ScratchCardList = (props: Props) => {
  const mobileView = window.innerWidth <= 800
  const itemsRange = mobileView ? 1 : 3
  const { rewards, wasScratchAllClicked, scratchTicket } = props
  const [items, setItems] = React.useState(rewards.slice(0, itemsRange))
  const [activeIdx, setActiveIdx] = React.useState(1)
  const cards = items
  const settings = getCanvasSettings()
  const showButtons = rewards.length > itemsRange ? true : false
  const showLeftButton = showButtons && cards[0]?.index !== 0 ? true : false
  const showRightButton = showButtons && cards[cards.length - 1]?.index !== rewards.length - 1 ? true : false
  const emptyRange = itemsRange - cards.length === 0 ? [] : _.range(0, itemsRange - cards.length)
  const dotsRange = showButtons ? _.range(0, rewards.length / itemsRange) : []

  const prevClick = (jump = itemsRange) => {
    const prevIndex = activeIdx - 1
    setItems(rewards.slice((prevIndex - 1) * jump, jump * prevIndex))
    setActiveIdx(activeIdx - 1)
  }

  const nextClick = (jump = itemsRange) => {
    const nextIndex = activeIdx + 1
    setItems(rewards.slice((nextIndex - 1) * jump, jump * nextIndex))
    setActiveIdx(activeIdx + 1)
  }

  const renderScratchArea = (ticket: any) => {
    if (ticket === undefined) {
      if (wasScratchAllClicked) {
        return <ResultBox className="result-box" width={settings.width} height={settings.height}></ResultBox>
      }
      return !ticket.scratched ? (
        <ScratchCard {...settings} onComplete={() => { }}>
          <ResultBox className="result-box" width={settings.width} height={settings.height}></ResultBox>
        </ScratchCard>
      ) : null
    } else {
      if (ticket.scratched) {
        return (
          <ResultBox className="result-box" width={settings.width} height={settings.height}>
            <div className="amount">
              <img src={EquaCashSymbol} alt="equa cash" />
              <span>{ticket.reward}</span>
            </div>

            <div className="equa-cash-text">EQUA CASH</div>

            <div className="equa-cash-value">${ticket.reward} value!</div>
          </ResultBox>
        )
      } else {
        return (
          <ScratchCard {...settings} onComplete={() => scratchTicket(ticket.id)}>
            <ResultBox className="result-box" width={settings.width} height={settings.height}>
              <div className="amount">
                <img src={EquaCashSymbol} alt="equa cash" />
                <span>{ticket.reward}</span>
              </div>

              <div className="equa-cash-text">EQUA CASH</div>

              <div className="equa-cash-value">${ticket.reward} value!</div>
            </ResultBox>
          </ScratchCard>
        )
      }
    }
  }

  const dynamicMargin: string = showLeftButton && showRightButton ? '' : showRightButton ? 'left' : showLeftButton ? 'right' : ''

  return cards.length ? (
    <TicketsWrap margin={dynamicMargin}>
      {showLeftButton && <CarrotLeft className='carrotLeft' onClick={() => prevClick()} />}
      <FlexCol style={{ width: 'auto' }}>
        <TicketsCont width={settings.width} height={settings.height}>
          {cards.map((card: any) => (
            <div className="single-ticket" key={card.id}>
              <img src={TicketImg} alt="scratch-ticket" className="ticket" />
              <div className="brown-scratch-box" onClick={() => mobileView ? scratchTicket(card.id) : {}}>{renderScratchArea(card)}</div>
            </div>
          ))}
          {emptyRange.map(e => <img src={NoTicketImg} alt="no ticket" className="single-ticket" />)}
        </TicketsCont>
        <FlexRow justifyContent={'center'}>
          {dotsRange.map((d, i: number) => <CarouselDot active={activeIdx - 1 === i ? 'true' : 'false'} />)}
        </FlexRow>
      </FlexCol>
      {showRightButton && <CarrotRight className='carrotRight' onClick={() => nextClick()} />}
    </TicketsWrap>
  )
    :
    (
      <NoTicketCont>
        <img src={NoTicketImg} alt="no ticket" />
        <img src={NoTicketImg} alt="no ticket" />
        <img src={NoTicketImg} alt="no ticket" />
      </NoTicketCont>
    )
}
