import React, { useState } from 'react'
import {
  dateField,
  FieldGroup,
  uploadFileField,
  UploadHandler,
  selectFieldComponent,
  numericInputFieldWithDecimals,
  percentageFieldComponent,
  capitalizeTextInputField,
  renderFieldsSimple,
  seniorityInputFieldComponent,
  nonZeroCurrencyField,
  legendField,
  additionalLegendOptions,
  numericInputFieldWithCommas
} from '@shared/components/forms'
import { MultiBoxField } from '@components/multibox'
import { fieldLabel, FieldNote } from '@src/styles'
import { renderHeader } from '@components/forms'
import { LightDivider } from '@src/styles'
import {
  emptyValidator,
  nonZeroNumberLimit,
  securityType,
  dividendType,
  dividendCumulation,
  dividendAccrual,
  dividendInterest,
  isShareClass,
  LegendOptions,
  isNotZeroAndNegative,
  Hash,
  legendsToSelectOptions
} from '@shared/helpers'
import styled from 'styled-components'
import { ExpandedHolding, ExpandedLegend, ExpandedSecurity } from '@src/service'
import { SeniorityTable } from '@shared/components/seniority-table'
import { DocumentsAndNotes } from '@shared/components/documents-and-notes'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
  values?: any
  onUpload: UploadHandler
  securities: any
  legends: ExpandedLegend[]
  holdings: ExpandedHolding[]
}

interface EditProps {
  security: ExpandedSecurity
}

const StyledLabel = styled.div`
  ${fieldLabel}
`
const StyleSecurityForm = styled.div``

function shareClassFields(legendOptions: LegendOptions): FieldGroup<any> {
  return [
    {
      name: 'shareType',
      label: 'Type',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(securityType, 'Type'),
    },
    {
      name: 'shareClass',
      label: 'Class',
      required: true,
      validate: isShareClass,
      placeholder: 'Enter class',
      component: capitalizeTextInputField,
    },
    {
      name: 'shareClassPrefix',
      label: 'Abbreviation',
      required: false,
      validate: isShareClass,
      placeholder: 'Enter abbreviation',
      component: capitalizeTextInputField,
    },
    // {
    //   name: 'customName',
    //   label: 'Custom Name',
    //   required: false,
    //   validate: emptyValidator,
    //   placeholder: 'Enter a Custom Name',
    //   component: textInputFieldComponent,
    // },
    {
      name: 'legend',
      label: 'Legend',
      required: false,
      validate: emptyValidator,
      component: selectFieldComponent(legendsToSelectOptions(legendOptions), 'Legend'),
    },
  ]
}

function sharePricesFields(): FieldGroup<any> {
  return [
    {
      name: 'parValue',
      label: 'Par Value',
      placeholder: 'Enter Par Value (in USD)',
      ...nonZeroCurrencyField,
    },
    {
      name: 'pricePerShare',
      label: 'Current Price Per Share',
      placeholder: 'Enter Price Per Share (in USD)',
      ...nonZeroCurrencyField,
    },
    {
      name: 'originalIssuePrice',
      label: 'Original Issue Price',
      placeholder: 'Enter Original Issue Price (in USD)',
      ...nonZeroCurrencyField,
    },
    {
      name: 'conversionPrice',
      label: 'Conversion Price',
      placeholder: 'Enter Conversion Price (in USD)',
      ...nonZeroCurrencyField,
    },
    {
      name: 'conversionRatio',
      label: 'Conversion Ratio',
      placeholder: 'Enter Conversion Ratio',
      validate: nonZeroNumberLimit,
      component: numericInputFieldWithDecimals,
    },
    {
      name: 'multiplier',
      label: 'Multiplier',
      placeholder: 'Enter Multiplier',
      validate: nonZeroNumberLimit,
      component: numericInputFieldWithDecimals,
    },
  ]
}

function dividendsFields(): FieldGroup<any> {
  return [
    {
      name: 'dividendType',
      label: 'Dividend Type',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(dividendType, 'Dividend Type'),
    },
    {
      name: 'dividendYield',
      label: 'Dividend Yield',
      required: true,
      validate: emptyValidator,
      component: percentageFieldComponent,
    },
    {
      name: 'dividendCumulation',
      label: 'Dividend Cumulation',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(dividendCumulation, 'Dividend Cumulation'),
    },
    {
      name: 'dividendAccrual',
      label: 'Dividend Accrual',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(dividendAccrual, 'Dividend Accrual'),
    },
    {
      name: 'compoundingInterest',
      label: 'Compounding Interest',
      required: true,
      validate: emptyValidator,
      component: selectFieldComponent(dividendInterest, 'Compounding Interest'),
    },
  ]
}

const renderFields = (
  legendOptions: LegendOptions,
  onUpload: UploadHandler,
  issueDividends: boolean,
  setIssueDividends: (issueDividends: boolean) => void,
  SeniorityTableWrapper: any,
  values?: any) => {
  return (
    <StyleSecurityForm>
      {renderHeader({ label: 'Equity Details' })}
      {renderFieldsSimple(shareClassFields(legendOptions), true)}
      <MultiBoxField name="certifiedShares" text="Certified Shares" type="checkbox" />
      <MultiBoxField name="fractionalShares" text="Fractional Shares" type="checkbox" />
      <MultiBoxField name="votingShares" text="Voting Shares" type="checkbox" />
      <LightDivider stretch={true} />
      <DocumentsAndNotes onUpload={onUpload} values={values} />
      {renderHeader({ label: 'Share Prices' })}
      {renderFieldsSimple(sharePricesFields(), true)}
      <MultiBoxField name="participatingPreferred" text="Participating Preferred" type="checkbox" />
      <LightDivider stretch={true} />
      {renderHeader({ label: 'Authorize Units' })}
      {renderFieldsSimple([
        {
          name: 'value',
          label: 'Total Authorized Units',
          placeholder: 'Add Units to Authorize',
          required: true,
          validate: isNotZeroAndNegative,
          format: formatToNumber,
          component: numericInputFieldWithCommas,
        }
      ])}
      {renderHeader({ label: 'Seniority' })}
      {renderFieldsSimple([
        {
          name: 'seniority',
          label: 'Seniority Level',
          required: true,
          validate: isNotZeroAndNegative,
          component: seniorityInputFieldComponent,
        },
      ], true)}
      <StyledLabel>Seniority Preview</StyledLabel>
      {SeniorityTableWrapper}
      <LightDivider stretch={true} />
      {renderHeader({ label: 'Dividends' })}
      <MultiBoxField
        name="issueDividends"
        text="Does this equity issue dividends?"
        type="checkbox"
        handleClick={() => setIssueDividends(!issueDividends)} />
      {issueDividends ? renderFieldsSimple(dividendsFields()) : <LightDivider stretch={true} />}
    </StyleSecurityForm>
  )
}

export const NewSecurityForm = (props: Props) => {
  const { onUpload, securities, values, legends, holdings } = props
  const [issueDividends, setIssueDividends] = useState<boolean>(false)

  const additionalLegends = additionalLegendOptions(legends, values?.legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)

  const SeniorityTableWrapper = () => {
    const currentSecurity = {
      shareClassPrefix: values.shareClassPrefix || '',
      shareType: values.shareType,
      shareClass: values.shareClass || '...',
    }
    return <SeniorityTable securities={securities} holdings={holdings} editSecurity={false} editHolding={true} currentSecurity={currentSecurity} values={values} />
  }
  return renderFields(legendOptions, onUpload, issueDividends, setIssueDividends, SeniorityTableWrapper(), values)
}

export const EditSecurityForm = (props: Props & EditProps) => {
  const { onUpload, securities, values, legends, security, holdings } = props
  const [issueDividends, setIssueDividends] = useState<boolean>(values?.issueDividends)

  const additionalLegends = additionalLegendOptions(legends, values?.legend!)
  const legendOptions = legends?.filter(l => !l.deleted)
    .concat(additionalLegends as any)
  const SeniorityTableWrapper = () => {
    return <SeniorityTable securities={securities} holdings={holdings} editSecurity={true} editHolding={true} values={values} security={security.hash} />
  }
  return renderFields(legendOptions, onUpload, issueDividends, setIssueDividends, SeniorityTableWrapper(), values)
}
