import {
    accountPath,
    dashboardPath,
    logoutPath,
    portfolioPath,
    profileDetailsPath,
    referralsPath,
    themesPath,
    equaCashTransferPath,
    walletsPath,
    walletPortfolioPath,
    guestDashboardPath,
    guestPortfolioPath,
    guestAccountPath,
    guestThemesPath,
    guestProfileDetailsPath,
    guestEquaCashTransferPath,
    guestReferralsPath,
    guestWalletsPath,
    userLogin,
    guestWalletPortfolioPath,

} from '@logic'
import { HeaderLinkProps } from './header-links'
import Dashboard from '@image/dashboard-16x16.svg'
import Account from '@image/account-16x16.svg'
import Portfolio from '@image/portfolio-chart-16x16.svg'
import Wallet from '@image/wallet-drop.svg'
import Referrals from '@image/referrals-16x16.svg'
import MoneyTransfer from '@image/money-transfer-16x16.svg'
import Profile from '@image/profile-16x16.svg'
import Themes from '@image/themes-16x16.svg'
import Logout from '@image/log-out-16x16.svg'

interface ProfileLinkProps extends HeaderLinkProps {
    image: any
}
export const profileLinks: ProfileLinkProps[] = [
    {
        path: dashboardPath,
        title: 'Dashboard',
        image: Dashboard,
    },
    {
        path: portfolioPath,
        title: 'Portfolio',
        image: Portfolio,
    },
    {
        path: walletsPath,
        title: 'Wallets',
        image: Wallet,
        defaultUrl: walletPortfolioPath,
        beta: true
    },
    {
        path: referralsPath,
        title: 'Referrals',
        image: Referrals,
    },
    {
        path: equaCashTransferPath,
        title: 'E$ Transfer',
        image: MoneyTransfer,
    },
    {
        path: profileDetailsPath,
        title: 'Profile',
        image: Profile,
    },
    {
        path: themesPath,
        title: 'Themes',
        image: Themes,
    },
    {
        path: accountPath,
        title: 'Account',
        image: Account,
    },
    {
        path: logoutPath,
        title: 'Logout',
        image: Logout,
    },
]


export const guestProfileLinks: ProfileLinkProps[] = [
    {
        path: guestDashboardPath,
        title: 'Dashboard',
        image: Dashboard,
    },
    {
        path: guestPortfolioPath,
        title: 'Portfolio',
        image: Portfolio,
    },
    {
        path: guestWalletsPath,
        title: 'Wallets',
        image: Wallet,
        defaultUrl: guestWalletPortfolioPath,
        beta: true
    },
    {
        path: guestReferralsPath,
        title: 'Referrals',
        image: Referrals,
    },
    {
        path: guestEquaCashTransferPath,
        title: 'E$ Transfer',
        image: MoneyTransfer,
    },
    {
        path: guestProfileDetailsPath,
        title: 'Profile',
        image: Profile,
    },
    {
        path: guestThemesPath,
        title: 'Themes',
        image: Themes,
    },
    {
        path: guestAccountPath,
        title: 'Account',
        image: Account,
    },
    {
        path: userLogin,
        title: 'Login/Signup',
        image: Logout,
    },
]