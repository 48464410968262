import { Uuid } from '@helpers/util'
import {
  accessDeniedWithFallback,
  FilesResponse,
  HttpClient,
  InviteMembersResponse,
  membersAccessDeniedResponse,
  newRawPostFunction,
  WebResponse,
  newPatchFunction,
} from '@src/service'
import { EntityProps, MembersProps, NewMember, OrganizationUrlProps } from '@logic'
import { InviteMembersRequest, NewAddressRequest1, NewAuthorizationRequest, NewEmailDiffRequest, NewEmailRequest, PatchOrganizationRequest, UpdateAddressRequest1, UpdateEmailRequest } from './requests'
import {
  addressesUrl,
  AddressUrlProps,
  addressUrl,
  allAuthorizationUrl,
  authorizationUrl,
  AuthorizationUrlProps,
  editTaskUrl,
  inviteMembersUrl,
  organizationUrl,
  userStateUrl,
  emailsUrl,
  EmailProps,
  emailUrl,
  emailsManyUrl
} from './urls'
import { GetOrganizationResponse, NewAuthorizationResponse } from './responses'
import { newDeleteFunction, newPostFunction, newPutFunction } from '@src/service/lib'
import { NewUserStateRequest, UpdateTaskRequest } from '@shared/helpers'

export const editMember = (httpClient: HttpClient) => async (
  organization: Uuid,
  member: Uuid,
  data: Partial<NewMember>
): Promise<MembersProps> => {
  return accessDeniedWithFallback(membersAccessDeniedResponse)(await httpClient.patch(`organization/${organization}/member/${member}`, data))
}

export const postFile = (httpClient: HttpClient) => async (
  url: string,
  files: any[],
  onProgress?: any
): Promise<WebResponse<FilesResponse>> => {
  return httpClient.postFiles(url, files, '', onProgress)
}

export const inviteMembers = newRawPostFunction<OrganizationUrlProps, InviteMembersRequest, InviteMembersResponse>(inviteMembersUrl)

export const editOrganizationDetails = newPatchFunction<OrganizationUrlProps, PatchOrganizationRequest, GetOrganizationResponse>(organizationUrl)

export const createAuthorization = newPostFunction<OrganizationUrlProps, NewAuthorizationRequest, NewAuthorizationResponse>(allAuthorizationUrl)

export const deleteAuthorization = newDeleteFunction<AuthorizationUrlProps, {}>(authorizationUrl)

export const editTaskList = newPutFunction<{ entity: Uuid, task: Uuid }, UpdateTaskRequest, {}>(editTaskUrl)

export const editUserState = newPatchFunction<{}, NewUserStateRequest, {}>(userStateUrl)

export const createAddress = newPostFunction<EntityProps, NewAddressRequest1, {}>(addressesUrl)

export const editAddress = newPutFunction<AddressUrlProps, UpdateAddressRequest1, {}>(addressUrl)

export const deleteAddress = newDeleteFunction<AddressUrlProps, {}>(addressUrl)

export const addEmails = newPostFunction<EntityProps, NewEmailDiffRequest, {}>(emailsManyUrl)

export const addEmail = newPostFunction<EntityProps, NewEmailRequest, {}>(emailsUrl)

export const editEmail = newPutFunction<EmailProps, UpdateEmailRequest, {}>(emailUrl)

export const deleteEmail = newDeleteFunction<EmailProps, {}>(emailUrl)

