import * as React from 'react'
import { buildProfilePath, portfolioPath } from '@logic'
import { PageContent } from '@components/pages'
import { FlexCol, screenSizes, Themes, whiteColor } from '@src/styles'
import { PathLinkButton,SkipLinkButton } from '@components/button/button'
import styled, { css } from 'styled-components'
import WelcomeHeader from '@image/welcome-top.png'
import Protect from '@image/security.svg'
import Safeguard from '@image/cloud.svg'
import Control from '@image/contract.svg'
import { AppContext } from '@src/app'

const spanCSS = css`
  line-height: 24px;
`

const Wrapper = styled.div`
  ${whiteColor}
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
      width: 100%;
      height: auto;
      margin-top: 57px;
  }
`

const ContentWrap = styled<any>(FlexCol)`
  align-items: center;
  h1 {
      line-height: 54px;
      font-weight: normal;
  }
  span {
    ${spanCSS}
  }
`

const Info = styled.h4<any>`
  line-height: 32px;
  font-weight: normal;
  margin: 14px 0 40px 0;
  font-family: 'NunitoSans';
`

const Title = styled.span<any>`
  font-weight: bold;
  margin: 24px 0;
  font-family: 'NunitoSansBold';
`

const FooterInfo = styled.span<any>`
  max-width: 587px;
  margin: 40px 0;
  text-align: center;
`

const PanelsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25.4px;
  @media(max-width: ${screenSizes.XS}px) {
    display: flex;
    flex-direction: column;
    grid-gap: 1;
  }
`

const Panel = styled<any>(FlexCol)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background: #263946;
  border-radius: 4px;
  padding: 32px;
  text-align: center;
`

export const WelcomePage = () => {
  const welcomeCards = [
    {
      image: Control,
      title: 'CONTROL & EMPOWER AGREEMENTS',
      desc: `Upload to our secure storage repository.
             Then, confidently share, review, and execute signed agreements.`,
    },
    {
      image: Safeguard,
      title: 'SAFEGUARD YOUR ENTITY',
      desc: `Rest well in the assurance that your entity is backed up to the cloud, and protected 
            with multiple layers of security and redundancy.`,
    },
    {
      image: Protect,
      title: 'PROTECT & MANAGE OWNERSHIP',
      desc: `Invite your colleagues, partners, and investors for seamless communication and updates of organization arrangements.`,
    },
  ]
  const { dispatch } = React.useContext(AppContext)
  React.useEffect(() => {
    dispatch({ type: 'SET_THEME', payload: Themes.EQUA })
  }, [])

  return (
    <Wrapper>
      <img src={WelcomeHeader} />
      <PageContent noPadding={true}>
        <ContentWrap>
          <h1>Welcome to Equa!</h1>
          <Info>Where you can easily manage all of your public and private assets in one place.</Info>
          <PanelsWrapper>
            {welcomeCards.map((w, i: number) => {
              const Image = w.image
              return <Panel key={i}>
                <Image />
                <Title>
                  {w.title}
                </Title>
                <span>
                  {w.desc}
                </span>
              </Panel>
            })}
          </PanelsWrapper>
          <FooterInfo>We’re happy to have you! To get started, build your profile.
                         </FooterInfo>
          <PathLinkButton path={buildProfilePath} args={{}}>
          BUILD YOUR PROFILE
                    </PathLinkButton>
                    <SkipLinkButton path={portfolioPath}>
      SKIP
    </SkipLinkButton>
        </ContentWrap>
      </PageContent>
    </Wrapper>
  )
}
