import * as React from 'react'
import styled from 'styled-components'
import { withNavigation, NavigationProps, matchesPath } from './navigation'
import { withCachedLoading } from './loading'
import { PathTitles, PathTitleBundle, PageBreadCrumbs } from '@shared/helpers/path-titles-list'
import { withService } from '@shared/hocs/with-service'
import { User } from '@logic'
import { connect } from 'react-redux'
import { StateTransform } from '@src/index'
import { headerHeight } from '@src/styles/theme'
interface StateProps {
    user?: User
}

type Props = NavigationProps & StateProps & { withSticky?: boolean }

const Wrapper = styled.div<any>`
  max-width: ${props => props.withSticky == 'true' ? '630px' : '100%'};
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  background-color: ${props => props.theme.secondary};
  z-index: 2;
  position: sticky;
  top: ${headerHeight};
  height:40px;
  align-items: center;
  span {
      padding: 5px 0 5px 0;
  }
`
const CrumbsWithNavigation = styled.span<any>`
  color: ${props => props.theme.breadcrumb};
  :hover {
      color: ${props => props.theme.accent};
  }
`

export const BreadcrumbsComponent = withNavigation((props: Props) => {
    const { navigate, params, user, withSticky } = props

    const renderCrumb = (item: PathTitleBundle, i: number) => {
        const loaders = item.loaders
        if (loaders) {
            const Result = withService(withCachedLoading<any, any>(loaders, undefined, true)((props: any) => {
                return <CrumbsWithNavigation
                    onClick={() => navigate(item.target || item.path, params)}>
                    {item.crumb!(props)}&nbsp;
                </CrumbsWithNavigation>
            }
            ))
            return <Result key={i} params={params} user={user} />
        }
        else {
            if (item.crumb)
                return <CrumbsWithNavigation onClick={() => navigate(item.target || item.path, params)}
                    key={i}>{item.crumb({})}&nbsp;</CrumbsWithNavigation>
        }
    }

    return <Wrapper withSticky={withSticky ? 'true' : 'false'}>
        {PathTitles.map((item: PathTitleBundle, i: number) => {
            if (matchesPath(item.path, window.location.pathname, false)) {
                const isSeparate = PageBreadCrumbs.find(p => p.path === item.path)
                if (isSeparate && matchesPath(isSeparate?.path!, window.location.pathname, true)) {
                    return isSeparate.items?.map((it: PathTitleBundle, index: number) => renderCrumb(it, index))
                }
                else return renderCrumb(item, i)
            }
            else {
                return null
            }
        })}
    </Wrapper>
})

const mapStateToProps: StateTransform<StateProps> = s => ({
    user: s.user,
})

export const Breadcrumbs = connect(mapStateToProps, {})(BreadcrumbsComponent)
