import * as React from 'react'
import { OrganizationProps, PageProps, portfolioPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import {
    GetSecuritiesResponse,
    GetLegendsResponse,
    createHolding,
    NewHoldingRequest,
    CapitalChangeRequest,
    patchCapitalChange,
    editHolding,
} from '@src/service'
import { BaseLinkProps } from '@components/navigation/navigation'
import { HoldingForm } from '../components/holding-form'
import { colorPicker } from '@shared/components/color-picker'
import { colorPickerModal } from '../components/profile-modals'
import { newColor, NewColorRequest } from '@src/service/services/profile'
import { CapitalContributionType } from '@modules/captable/utility'
import { HoldingRecordType } from '@shared/helpers/holdings'

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>()

export const NewHoldingPage = withData((props: Props) => {
    const { httpClient, navigate, user } = props
    const initialValues = {}

    const onSubmit = async (values: any) => {
        const pricePerUnit = values?.originalPricePerUnit
        const request = {
            name: values!.name,
            abbreviation: values!.abbreviation,
            tickerSymbol: values!.tickerSymbol,
            value: values!.value,
            pricePerUnit,
            entity: user?.id!,
            owner: user?.id!,
            holdingType: HoldingRecordType.userAsset,
        } as NewHoldingRequest

        const response = await createHolding(httpClient)({}, request)
        if (response?.id || response?.hash) {
            if (values?.originalPricePerUnit !== values?.currentPricePerUnit) {
                const editRequest = {
                    ...request,
                    pricePerUnit: values?.currentPricePerUnit,
                    holding: response.hash,
                    holdingType: HoldingRecordType.userAsset,
                }
                await editHolding(httpClient)({ holding: response.hash }, editRequest)
            }
            const holdingId = response.id!
            const capitalChangeRequest = {
                mod: values?.value * values?.originalPricePerUnit,
                timestamp: new Date(),
                type: CapitalContributionType.cashPaid,
                source: response.hash
            } as CapitalChangeRequest
            await patchCapitalChange(httpClient)(
                { entity: user?.id! }, capitalChangeRequest
            )
            const colorRequest = {
                value: selectedColor.replace('#', '')
            } as NewColorRequest
            await newColor(httpClient)(
                { user: user?.id!, target: holdingId }, colorRequest
            )
            navigate(portfolioPath, {})
        }
        else console.log('create holding error') // TODO: add error message toast
    }

    const colorPickerBundle = colorPicker()
    const ColorPicker = colorPickerBundle.component
    const selectedColor = colorPickerBundle.color
    const pickColor = () => {
        pickColorModal.setVisible(false)
    }
    const pickColorModal = colorPickerModal(pickColor, ColorPicker)

    const parent: BaseLinkProps = { path: portfolioPath, args: {} }

    return (
        <PageContent>
            {pickColorModal.component}
            <PageContentHeader title="Add New Asset" parent={parent} />
            <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                submitText="Save">
                <HoldingForm color={selectedColor} colorModal={pickColorModal} />
            </CommonForm>
        </PageContent>
    )
})
