import * as React from 'react'
import { Component } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { History } from 'history'
import VerifyPlane from '@image/verify-plane.svg'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { checkVerification, CheckVerification, Paths, User } from '@logic'
import { AppContext } from '@src/app'
import { HttpError, Service } from '@src/service'
import queryString from 'query-string'
import { isPromo } from '@helpers/promo'
import { StateTransform } from '@src/index'
import { StyledAuth, StyledAuthForm, StyledHeader, StyledTopHeader } from '../style'
import VerifyLogo from '@image/verify-email.svg'
import Carousel from '../components/carousel'

interface Props extends RouteComponentProps<any> {
  service: Service
  history: History
  checkVerification: CheckVerification
}

interface StateProps {
  user: User
}

const StyledVerify = styled.div`
    text-align: center;
  label {
    display: inline-block;
    margin-bottom: 30px;
  }
  svg {
    margin-bottom: 24px;
  }
  a {
    font-size: 14px;
    white-space: nowrap;
  }
`

class Verify extends Component<Props & StateProps> {
  componentDidMount(): void {
    const { history, user, location, checkVerification } = this.props
    const parsed = queryString.parse(location.search)
    if (!user || (!!user && user.emailVerified)) {
      history.push('/')
    } else if (parsed.code) {
      const { code } = parsed
      if (code) {
        checkVerification({ code }, (result: any) => {
          if (!(result instanceof HttpError)) {
            if (isPromo()) {
              history.push(Paths.promo)
            } else {
              history.push(Paths.userThanks)
            }
          }
        })
      }
    }
  }

  render() {
    const { user } = this.props
    return (
      <StyledAuth>
        <StyledAuthForm>
          <StyledVerify>
            <div><VerifyLogo /></div>
            <StyledTopHeader>
              Verify your email
            </StyledTopHeader>
            <label>Please click on the confirmation link in the email we just sent.</label>
            <br />
            <a onClick={() => this.props.service.sendVerification({ usernameOrEmail: user.username ? user.username : user.email })}>
              Resend Verification Email
            </a>
          </StyledVerify>
        </StyledAuthForm>
      </StyledAuth>
    )
  }
}

const VerifyWithService = (props: Props & StateProps) => (
  <AppContext.Consumer>{context => <Verify {...props} service={context.service!} />}</AppContext.Consumer>
)

const mapStateToProps: StateTransform<StateProps> = s => ({
  user: s.user!,
})

export const VerifyLogin = connect(
  mapStateToProps,
  { checkVerification }
)(withRouter(VerifyWithService as any))
