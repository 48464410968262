import * as React from 'react'
import {
  dateField,
  FieldGroup,
  phoneNumberField,
  renderField,
  renderFieldsSimple,
  selectFieldComponent,
  textInputFieldComponent,
  uploadFileField,
  UploadHandler,
} from '@shared/components/forms'
import { FieldArray } from 'react-final-form-arrays'
import { FlexRow, InputGroup, LightDivider, whiteColor } from '@src/styles'
import { Address } from '@components/address'
import { renderHeader } from '@components/forms'
import { emptyValidator, isUrl, nextInternalId } from '@shared/helpers'
import styled from 'styled-components'
import Cross from '@image/close-default.svg'
import { companyType } from '@shared/helpers/constants'
import { EmailSection } from '@shared/components/email-section'
import { colors } from '@src/styles/theme'

interface Props {
  values?: any
  onUpload: UploadHandler
}

const StyledDetailsForm = styled.div`
  p {
    line-height: 22px;
    margin: -9px 0 10px 0;
  }
`

const StyledTaxId = styled.div<any>`
  background-color: ${props => props.theme.selectorBack};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: ${props => !props.isLast ? '20px' : '24px'};
  .cross {
    cursor: pointer;
    path {
      fill: ${props => props.theme.whiteIcon};
    }
  }
`

const TaxIdHead = styled.h6<any>`
  ${whiteColor}
  margin: 0;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  font-family: 'NunitoSansSemiBold';
`
const ImportOrgNO = styled.div<any>`
border-bottom: ${props => props.noBorder == 'true' ? 'none' : `1px solid ${colors.grayDivider}`};
`
function mainFields(onUpload: UploadHandler): FieldGroup<any> {
  return [
    {
      name: 'name',
      label: 'Organization Name',
      required: true,
      validate: emptyValidator,
      component: textInputFieldComponent,
    },
    {
      name: 'logo',
      label: 'Logo',
      required: false,
      note: 'Square logos work best. Accepted file type(s): PNG, JPG.',
      ...uploadFileField({ onUpload, placeholder: 'Logo', accept: ['.jpg', '.jpeg', '.png'] }),
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      required: false,
      validate: emptyValidator,
      component: phoneNumberField,
    }
  ]
}

function orgInfoFields(): FieldGroup<any> {
  return [
    {
      name: 'companyType',
      label: 'Organization Type for Tax Filing Status',
      required: false,
      validate: emptyValidator,
      component: selectFieldComponent(companyType, 'Organization Type'),
    },
    {
      name: 'registrationNumber',
      label: 'Organization Registration Number',
      required: false,
      validate: emptyValidator,
      placeholder: 'Enter Your Organization Registration Number',
      component: textInputFieldComponent,
    },
    {
      name: 'startDate',
      label: 'Organization Start Date',
      required: false,
      placeholder: 'MM/DD/YYYY',
      ...dateField(React.useRef()),
    },
  ]
}

export const OrganizationDetailsForm = (props: Props) => {
  const { onUpload, values } = props

  const deleteTaxId = (fields: any, index: number) => {
    const value = fields.value?.filter((v: any, i: number) => index === i)?.map(s => ({ ...s, isRemoved: true }))[0]
    fields.update(index, value)
  }

  return (
    <StyledDetailsForm>
      {renderHeader({ label: 'Organization Name & Logo' })}
      {renderFieldsSimple(mainFields(onUpload))}
      <Address name={'address'} />
      <EmailSection name='emails' title='Billing / Accounting Email Information' />
      {renderHeader({ label: 'Important Organization Information' })}
      {renderFieldsSimple(orgInfoFields())}
      <ImportOrgNO>
      <FieldArray name="taxIds">
        {({ fields, meta }) => {
          const taxIdArray = fields.map((name: string, i: number) => {
            const key = fields.value[i].key ? fields.value[i].key : i
            const removed = fields?.value[i]?.isRemoved
            return !removed ? (
              <StyledTaxId key={key} isLast={i === fields?.length! - 1}>
                <FlexRow style={{ margin: '0 0 24px 0' }} justifyContent={'space-between'}>
                  <TaxIdHead>Organization Number {i + 1}</TaxIdHead>
                  <Cross onClick={() => deleteTaxId(fields, i)}
                    className="cross" /></FlexRow>
                {renderTaxIds(name, values, i)}
              </StyledTaxId>
            ) : null
          })
          return (
            <>
              {renderHeader({
                label: 'Important Organization Number(s)',
                index: 1,
                buttonText: 'Add',
                buttonOnClick: () => fields.push({ key: nextInternalId() })
              })}
              {taxIdArray}
            </>
          )
        }}
      </FieldArray>  
      </ImportOrgNO>      
      {renderHeader({ label: 'Website' })}
      {renderFieldsSimple([
        {
          name: 'website',
          label: 'Website',
          required: false,
          placeholder: 'Enter website link',
          validate: isUrl,
          component: textInputFieldComponent,
        }
      ])}
    </StyledDetailsForm>
  )
}

const renderTaxIds = (name: string, values: any, index: number) => {
  const fieldRequired = values?.taxIds[index]?.name ? true : false
  return (
    <InputGroup>
      {[
        {
          name: `${name}.name`,
          label: 'ID Name',
          required: fieldRequired,
          validate: emptyValidator,
          placeholder: 'Enter Tax ID Name',
          component: textInputFieldComponent,
          noMargin: true,
        },
        {
          name: `${name}.value`,
          label: 'ID Number',
          required: fieldRequired,
          validate: emptyValidator,
          placeholder: 'Enter Tax ID Number',
          component: textInputFieldComponent,
          noMargin: true,
        },
      ].map(renderField)}
    </InputGroup>
  )
}
