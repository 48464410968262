import * as React from 'react'
import { PageProps, editProfilePath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { FlexRow, fontSizes, LightDivider, OrgDetailsWrapper, screenSizes } from '@src/styles'
import { Avatar } from '@components/avatar'
import { InvertedButton, PathLinkButtonInverted } from '@components/button/button'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { formatExtendedDateString } from '@shared/helpers'
import { getPhoneNumber } from '../utility'
import { renderAddress } from '@modules/organization/pages'
import { GetAddressesResponse, GetEmailsResponse } from '@src/service'
import { renderEmails } from '@shared/components/email-section'
import Copy from '@image/copy.svg'
import ProfileDetails from '@image/profile-details.svg'
import { CopyHover, CopyWrap } from '../pages'

type Props = PageProps & GetAddressesResponse & GetEmailsResponse & { forGuest?: boolean, openSignUp?: () => void }

const InfoStyledRow = styled<any>(FlexRow)`
    width: 100%;
    justify-content: space-between;
    padding-bottom: ${props => !props.isLast && '8px'};
    align-items: start;
    p, span {
        font-size: ${fontSizes.XS} !important;
        line-height: 22px;
    }
    p {
        width: 50%;
        margin: 0 10px 0 0;
        color: ${props => props.titleColor ? props.titleColor : props.theme.label};
    }
    span {
        width: 50%;
        word-break: break-all;
        text-align: right;
    }
`

const ViewProfileWrap = styled<any>(OrgDetailsWrapper)`
  width: 100%;
  max-width: 600px;
  a {
      text-decoration: underline;
  }
  .idCopy {
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      margin: 0;
    }
  }
`

const ProfileWrapper = styled.div<any>`
  h1, h4 {
      font-weight: normal;
  }

`

const renderUserInfo = (info: any | undefined, links?: boolean, titleColor?: string) => {
    return info?.map((s: any, i: number) => {
        const isLast = i === info.length - 1
        return <InfoStyledRow isLast={isLast} key={i} titleColor={titleColor}>
            <p>{s.title}</p>
            {links ?
                s.value && <Link to={{ pathname: s.value!.includes('http') ? s.value! : `http://${s.value!}` }}
                    target="_blank">{s.value!}</Link>
                :
                <span>
                    {s.value!}
                </span>
            }
        </InfoStyledRow>
    })
}

export const ViewProfileShared = (props: Props) => {
    const [copy, setCopy] = React.useState<boolean>(false)
    const { addresses, emails, forGuest, openSignUp } = props
    const profileRecord = props?.profile ? props.profile : undefined
    const address = profileRecord && profileRecord.homeAddress ? profileRecord.homeAddress : undefined
    const links = profileRecord && profileRecord.links ? profileRecord.links : undefined

    const personalInfo = [
        { title: 'Main / Account Email Address', value: profileRecord?.email! },
        { title: 'Phone Number', value: getPhoneNumber(profileRecord!) },
        { title: 'Date of Birth', value: profileRecord?.dateOfBirth && formatExtendedDateString(profileRecord.dateOfBirth) },
    ]

    const profileLinks = [
        { title: 'LinkedIn', value: links?.linkedIn! },
        { title: 'Facebook', value: links?.facebook! },
        { title: 'Twitter', value: links?.twitter! },
        { title: 'Instagram', value: links?.instagram! },
    ]


    const copyCodeToClipboard = (label: string) => {
        const el = document.getElementById(label)
        if (el) {
            navigator.clipboard.writeText(el.innerText!)
            setCopy(true)
        }
    }

    return (
        <PageContent withMargin={forGuest}>
            <ProfileWrapper>
                <PageContentHeader title="Profile Details" hideCrumbs={true}>
                    {forGuest ? <InvertedButton onClick={openSignUp}>Edit</InvertedButton>
                        : <PathLinkButtonInverted path={editProfilePath} args={{}}>
                            Edit
                        </PathLinkButtonInverted>}
                </PageContentHeader>
                <ViewProfileWrap>
                    <FlexRow>
                        <h4 style={{ paddingTop: '0' }}>Name & Profile Image</h4>
                    </FlexRow>
                    <FlexRow>
                        {forGuest ?
                            <ProfileDetails />
                            :
                            <>
                                <Avatar size="70px" fullName={profileRecord?.fullName || ''} image={profileRecord?.file?.hash} />
                                <span>{profileRecord?.fullName!}</span>
                            </>
                        }
                    </FlexRow>
                    <FlexRow>
                        <h4 className="first">Personal Information</h4>
                    </FlexRow>
                    {renderUserInfo(personalInfo, undefined, "#9CA6AD")}
                    <LightDivider />
                    {forGuest ? null : <><FlexRow>
                        <h4>Equa ID</h4>
                    </FlexRow>
                        <div className='idCopy'>
                            <span className='greyed' id={'equaId'}>{profileRecord?.id && `${profileRecord?.id}`}</span>
                            <CopyWrap
                                copy={copy}
                                onClick={() => copyCodeToClipboard('equaId')}
                                onMouseLeave={() => setCopy(false)}>
                                <div className='arrow-up' />
                                <CopyHover copy={copy}>
                                    <span>{copy ? 'Copied!' : 'Copy'}</span>
                                </CopyHover>
                                <Copy />
                            </CopyWrap>
                        </div>
                        <LightDivider /></>}
                    <FlexRow>
                        <h4>Address Information</h4>
                    </FlexRow>
                    {forGuest ? null : renderAddress(addresses)}
                    <LightDivider />
                    <FlexRow>
                        <h4>Email Information</h4>
                    </FlexRow>
                    {forGuest ? null : renderEmails(emails)}
                    <LightDivider />
                    <FlexRow>
                        <h4>Profile Links</h4>
                    </FlexRow>
                    {renderUserInfo(profileLinks, true, "#9CA6AD")}
                    <LightDivider />
                    <FlexRow><h4>Website</h4></FlexRow>
                    <FlexRow>
                        {
                            profileRecord?.website && <Link to={{ pathname: profileRecord.website.includes('http') ? profileRecord.website : `http://${profileRecord.website}` }}
                                target="_blank">{profileRecord.website}</Link>
                        }
                    </FlexRow>
                    <LightDivider />
                </ViewProfileWrap>
            </ProfileWrapper>
        </PageContent>
    )
}