import { applyMiddleware, createStore } from 'redux'
import { rootReducer } from './reducers'
import { History } from 'history'
import { routerMiddleware } from 'connected-react-router'
import * as reduxLoop from 'redux-loop'
import { RootState } from './types/types'
import { composeWithDevTools } from 'redux-devtools-extension'
import { Service } from '@src/service'

export function initialState(): RootState {
  return {
    router: undefined,
    user: undefined,
    organization: {},
  }
}

export function newStore(history: History, service: Service) {
  return createStore(
    rootReducer(history, service),
    initialState(),
    composeWithDevTools(applyMiddleware(routerMiddleware(history)), reduxLoop.install())
  )
}
