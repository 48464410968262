import * as React from 'react'
import styled, { ThemeProps, withTheme } from 'styled-components'
import { rgba } from 'polished'
import { gapSizes, fontSizes, Themes } from '@src/styles/theme'
import Arrow from '@image/arrow-left.svg'
// import ArrowDark from '@image/arrow-dark.png'

const rowHeight = '60px'
const StyledTable = styled.div<any>`
  max-width: 550px;
  transition: all 300ms ease-in;
  overflow-x: auto;
  padding-bottom: 20px;
  border-radius: 8px;
  table {
    color: ${props => props.theme.white};
    width: 100%;
    border-collapse: collapse;
    border: 1px solid transparent;
    border-spacing: 0;
    border-radius: ${props => props.theme.radius};
    padding-right: 20px;
    thead {
      background-color: ${props => props.theme.highlight};
      text-transform: uppercase;
    }
    tr {
      &[role='row'] {
        > *:nth-child(${props => (props.expandable ? 2 : 1)}) {
          font-weight: bold;
          font-family: 'NunitoSansBold';
        }
      }
      > *:nth-child(${props => (props.expandable ? 2 : 1)}) {
        text-align: start;
      }
    }
    th,
    td {
      text-align: end;
      padding: 0 ${gapSizes.M};
    }
    tbody {
      tr {
        border-top: 2px solid;
        border-color: ${props => rgba(props.theme.white, 0.1)};
        background-color: ${props => props.theme.highlight};
      }
    }
  }
`

export const TableRow = styled.tr<any>`
  height: ${rowHeight};
  padding: 0 ${gapSizes.S};
`

export const HeaderWrap = styled.div`
  display: flex;
  padding-bottom: 36px;
  align-items: center;
  svg {
    cursor: pointer;
    margin: 0 8px 0 0;
  }
`

const PageHeader = styled.h1`
  font-size: ${fontSizes.XL};
  line-height: 49px;
  color: ${props => props.theme.white};
`

interface Props {
  toggle: any
  prices: any[]
}

export const PriceBreakdown = withTheme((props: Props & ThemeProps<any>) => {
  const { toggle, prices } = props
  const headers = ['Number of Holders', 'Annual Pricing']
  const rows = prices.map(el => {
    return { members: `${el.starting_quantity} - ${el.ending_quantity}`, price: el.formatted_unit_price }
  })

  return (
    <>
      <HeaderWrap>
        <Arrow onClick={() => toggle(false)} />
        <PageHeader>Price Breakdown Per Holder</PageHeader>
      </HeaderWrap>
      <StyledTable>
        <table>
          <thead>
            <TableRow>
              {headers.map((column, i: number) => {
                return (
                  <th key={i}>
                    {column}
                  </th>
                )
              })}
            </TableRow>
          </thead>
          <tbody>
            {rows.map((cell, i) => {
              return (
                <TableRow key={i}>
                  <td>
                    {cell.members} Members
                  </td>
                  <td>
                    {cell.price} each
                  </td>
                </TableRow>
              )
            })}
          </tbody>
        </table>
      </StyledTable>
    </>
  )
})
