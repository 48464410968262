import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, plansPath, vestingsPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import {
    createAuthorization,
    FilesResponse,
    FormVestingSchedule,
    NewAuthorizationRequest,
    newVesting,
    NewVestingScheduleRequest,
    postFile
} from '@src/service'
import { BaseLinkProps } from '@shared/components/navigation'
import { VestingScheduleForm } from '../components/vesting-schedule-form'
import { normalizeDate } from '@shared/helpers'
import { eventsWithData } from '../utility'
import { HorizontalVestingChart } from '../components/vesting-chart'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { QuestionSpan, NoteSpan } from '../components/plan-form'

type Props = PageProps

const withData = withLoadingCachedMultiple<Props>()

export const CreateVestingSchedule = withData(
    withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
        const { httpClient, navigate, params } = props
        const { organization, plan } = params
        const initialValues: any = {
            vestingSchedule: {},
            cliff: false,
            amount: false,
            vestingOptions: { procedural: true, absolute: false, relative: false },
        }

        const onUpload: UploadHandler = async files => {
            const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
            const file = response.files[0]
            return {
                hash: file.hash,
                name: file.filename,
                id: file.id,
            }
        }

        const onSubmit = async (values: FormVestingSchedule) => {
            const documents = values?.approvalDocument?.filter((d: any) =>
                (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
            const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
                const newDocument = { ...document }
                delete newDocument.key
                return newDocument
            }) : []

            const isProcedural = values?.vestingOptions.procedural
            const events = !isProcedural ?
                values?.events?.filter(f => !f.isRemoved).map(event => {
                    delete event.key
                    if (values?.vestingOptions.absolute) {
                        const date = event.date
                        const month = date?.getMonth() + 1
                        const day = date?.getDate()
                        const year = date.getFullYear()
                        return {
                            month: parseInt(month),
                            day: parseInt(day),
                            year: parseInt(year),
                            value: event.value,
                        }
                    } else {
                        const value = event.rValue / 100
                        delete event.rValue
                        return { ...event, value }
                    }
                }) : {}

            const cliffMonths = isProcedural && values?.cliff ? {
                cliffMonths: values?.vestingSchedule?.cliffMonths
            } : undefined
            const cliffAmountAndType = isProcedural && values?.cliff && values?.amount ? {
                cliffAmount: parseFloat(values!.vestingSchedule!.cliffAmount!.toString()),
                cliffAmountType: values?.vestingSchedule?.cliffAmountType
            } : undefined

            const vestingSchedule = isProcedural ?
                {
                    durationMonths: values?.vestingSchedule?.durationMonths,
                    frequencyMonths: values?.vestingSchedule?.frequencyMonths,
                    vestsOn: values?.vestingSchedule?.vestsOn,
                    ...cliffMonths,
                    ...cliffAmountAndType,
                    name: values?.name,
                    startDate: values?.startDate!,
                }
                :
                {
                    events,
                    isValueAbsolute: values?.vestingOptions.absolute,
                    name: values?.name,
                }

            const request: NewVestingScheduleRequest = {
                vestingSchedule,
                plan,
            }
            const response: any = await newVesting(httpClient)({}, request)

            if (response?.hash) {
                modifyAuthorizations({ approvalDocuments, target: response.hash, httpClient, organization })
                navigate(vestingsPath, { organization, plan })
            }
            else console.log('create vesting schedule error') // TODO: add error message toast
        }

        const parent: BaseLinkProps = { path: vestingsPath, args: { organization, plan } }

        const VestingChartWrapper: Sticky<any> = props => {
            const { values } = props
            const vestingSchedule = eventsWithData(values)
            const custom = Object.keys(vestingSchedule).includes('events')
            const isAbsolute = custom ? vestingSchedule.isValueAbsolute : false
            return <>
                <PageContentHeader title='Create New Vesting Schedule' parent={parent} />
                <QuestionSpan>What is a Vesting Schedule?</QuestionSpan>
                <NoteSpan>Vesting Schedules determine the schedule of when holders acquire full ownership of the asset.
                    Create all of your different vesting schedules here, then you’ll be able to assign them to your incentives later on.
                </NoteSpan>
                {vestingSchedule ? <HorizontalVestingChart
                    vestingSchedule={vestingSchedule}
                    value={values?.startingValue}
                    custom={custom}
                    startDate={values?.startDate && !isAbsolute ? normalizeDate(values.startDate) : undefined}
                    dontNotify={true}
                    noMarginBottom={true}
                /> : <div />}
            </>
        }

        return (
            <PageContent>
                <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                    submitText="Save" sticky={VestingChartWrapper} stickyOnTop={true}>
                    <VestingScheduleForm onUpload={onUpload} />
                </CommonForm>
            </PageContent>
        )
    })
)
