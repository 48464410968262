import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { certificateDetailsPath, MembersProps, optionsPath, PageProps, planPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions, PermissionProps } from '@components/permissions'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import {
  createAuthorization,
  editHolding,
  EditHoldingRequest,
  ExpandedHoldingResponse,
  ExpandedHoldingsResponse,
  FilesResponse,
  FormOption,
  GetAuthorizationsResponse,
  GetLegendsResponse,
  GetPlanResponse,
  GetSecuritiesResponse,
  GetVestingsResponse,
  NewAuthorizationRequest,
  PlansProps,
  postFile
} from '@src/service'
import {
  loadAuthorizations,
  loadHolding,
  loadOrganizationHoldings,
  loadLegends,
  loadMembers,
  loadPlans,
  loadSecurities,
  loadVestings,
  loadPlan
} from '@helpers/loaders'
import { HorizontalVestingChart } from '../components/vesting-chart'
import { OptionForm } from '../components/option-form'
import { BaseLinkProps } from '@shared/components/navigation'
import { getQueryParams, normalizeDate } from '@shared/helpers'
import { filterEquities, filterIncentives, filterPools } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { QuestionSpan, NoteSpan } from '../components/plan-form'

type Props = PageProps &
  PermissionProps &
  PlansProps &
  MembersProps &
  GetLegendsResponse &
  ExpandedHoldingResponse &
  GetAuthorizationsResponse &
  GetSecuritiesResponse &
  ExpandedHoldingsResponse &
  GetVestingsResponse &
  GetPlanResponse

const withData = withLoadingCachedMultiple<Props>(
  loadPermissions,
  loadPlans,
  loadMembers,
  loadLegends(true),
  loadHolding,
  loadAuthorizations('option'),
  loadSecurities(),
  loadOrganizationHoldings('all'),
  loadVestings,
  loadPlan
)

export const EditOption = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient,
      navigate,
      params,
      holdings,
      members,
      legends,
      holding,
      authorizations,
      securities,
      vestingSchedule,
      plan } = props
    const { organization, option } = params
    const { owner, legend } = holding

    const securitiesList = filterEquities([], securities, true)
    const incentivesList = filterIncentives(securitiesList, plan)
    const planPools = filterPools(holdings, incentivesList, params?.plan)
    const documentsAndNotes = authorizations && authorizations.length > 0 ?
      authorizations.filter(a => a.authorizationDate || a.documentTypeName || a.document || a.note) : []
    const documents = documentsAndNotes?.map((auth, index: number) => {
      return {
        documentTypeName: auth.documentTypeName!,
        approvalDocument: { name: auth.document?.filename!, id: auth.document?.id! },
        approvalDate: auth.authorizationDate!,
        note: auth.note!,
        hash: auth.hash!
      }
    })

    const vestings = vestingSchedule && vestingSchedule.length > 0 ? vestingSchedule : []

    const initialValues: Partial<FormOption> = {
      member: owner,
      startingValue: holding?.startingValue!,
      legend,
      vestingSchedule: holding?.vestingSchedule?.hash || undefined,
      approvalDocument: documents,
      name: holding?.name!,
      startDate: holding?.startDate!,
      issueDate: holding?.issueDate!,
      pool: holding?.parent!,
      issuanceAgreement: holding?.issuanceAgreement ?
        { name: holding?.issuanceAgreement.filename, id: holding?.issuanceAgreement.id } : undefined,
    }
    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note) && !d.isRemoved)
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []

      const currentPool = planPools?.find(pool => pool.id == values?.pool)
      const currentIncentive = incentivesList?.find(incentive => (currentPool?.parent == incentive.id) ||
        (currentPool?.parent == incentive.hash))

      const request = {
        legend: values?.legend!,
        value: holding?.value!,
        startingValue: values?.startingValue!,
        entity: organization,
        name: holding?.name!,
        owner: values?.member!,
        issueDate: values?.issueDate!,
        startDate: values?.startDate!,
        parent: values?.pool!,
        vestingSchedule: values?.vestingSchedule!,
        issuanceAgreement: values?.issuanceAgreement ? values?.issuanceAgreement.id : undefined,
        internalType: currentIncentive ? currentIncentive.type : '',
        class: currentIncentive ? currentIncentive.shareClassPrefix : '',
      } as EditHoldingRequest

      const editOption = await editHolding(httpClient)({ holding: option }, request)
      if (editOption) {
        const option = editOption.hash!
        modifyAuthorizations({ approvalDocuments, target: option, httpClient, organization })
        const parent = getQueryParams().shareholding
        if (parent) {
          navigate(certificateDetailsPath, { organization, shareholding: option })
        } else {
          navigate(optionsPath, { organization, plan: params?.plan })
        }
      }
      else console.log('Edit Option error')
    }
    const VestingChartWrapper: Sticky<any> = props => {
      const { values } = props
      const vestingSchedule = vestings?.find(v => v.hash === values?.vestingSchedule)
      const custom = vestingSchedule ? Object.keys(vestingSchedule).includes('events') : false
      const events = custom ?
        vestingSchedule.isValueAbsolute ?
          vestingSchedule?.events?.map(event => {
            const date = new Date(`${event.month}/${event.day}/${event.year}`)
            return { ...event, date }
          })?.sort((a, b) => a.date && b.date && a.date.getTime() - b.date.getTime())
          :
          vestingSchedule?.events?.map(event => ({ ...event, rValue: event.value * 100 }))
            ?.sort((a, b) => a.month - b.month)
        : []
      const isAbsolute = custom ? vestingSchedule.isValueAbsolute : false

      return <>
        <PageContentHeader title='Edit Holding' parent={parent} />
        <QuestionSpan>What is a holding?</QuestionSpan>
        <NoteSpan>A holding is the issuance of the incentive to the holder.
          This is where it all comes together.
          Enter all of your specific Holding’s details here.
        </NoteSpan>
        {vestingSchedule ? <HorizontalVestingChart
          vestingSchedule={custom ? { ...vestingSchedule, events } : vestingSchedule}
          value={values?.startingValue}
          custom={custom}
          startDate={values?.startDate && !isAbsolute ? normalizeDate(values.startDate) : undefined}
          dontNotify={true}
          noMarginBottom={true}
        /> : <div />}
      </>
    }
    const parent: BaseLinkProps = { path: planPath, args: { organization, plan: params?.plan } }

    return (
      <PageContent>
        <CommonForm
          mutators={{ ...arrayMutators }}
          onSubmit={onSubmit}
          initialValues={initialValues}
          sticky={VestingChartWrapper}
          stickyOnTop={true}
          submitText='Save'
        >
          <OptionForm
            members={members}
            pools={planPools}
            legends={legends}
            edit={true}
            onUpload={onUpload}
            securities={securities}
            vestings={vestings}
            params={params} />
        </CommonForm>
      </PageContent>
    )
  })
)
