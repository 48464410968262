import React, { ReactElement, useState } from 'react'
import { ChromePicker, CirclePicker } from 'react-color'
import { FlexCol, gapSizes, LightDivider } from '@src/styles'
import styled from 'styled-components'

export interface ColorPickerBundle {
    component: ReactElement
    color: string
}
const ColorWrapper = styled<any>(FlexCol)`
    width: 100%;
    padding: 0 ${gapSizes.S};
    .chrome-picker, .circle-picker {
        min-width: 100%;
    }
`

const CurrentColor = styled.div<any>`
    display: flex;
    width: 100%;
    margin: ${gapSizes.M} 0;
    align-items: center;
    span {
        color: ${props => props.theme.white};
        margin-right: ${gapSizes.M};
        font-weight: bold;
        font-family: NunitoSansBold;
    }
    div {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: ${props => props.color};
    }
`

export function colorPicker(defaultColor?: string): ColorPickerBundle {
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    const value = defaultColor?.length! > 0 ? defaultColor! : randomColor
    const [color, setColor] = useState<string>(value)

    const handleChangeComplete = (color: any) => {
        setColor(color.hex)
    }

    const component = (
        <ColorWrapper>
            <CirclePicker color={color}
                onChangeComplete={handleChangeComplete} />
            <LightDivider />
            <ChromePicker
                color={color}
                onChangeComplete={handleChangeComplete}
                disableAlpha
            />
            <CurrentColor color={defaultColor?.length! > 0 ? defaultColor! : color} >
                <span>Current Color: </span>
                <div />
            </CurrentColor>
        </ColorWrapper>
    )
    return {
        component,
        color,
    }
}
