import * as React from 'react'
import { newBasicModalBundle } from '@components/basic-modal'
import { SolidButton } from '@components/button'

export function colorPickerModal(onActivate: () => void, body: any) {
  return newBasicModalBundle({
    title: 'Change Color',
    body,
    buttons: [
      {
        text: 'Change Color',
        onActivate,
        componentType: SolidButton,
      },
    ],
  })
}