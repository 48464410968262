import {
  HttpClient,
  httpDelete,
  httpGetWithCache,
  httpPatch,
  httpPost,
  httpPut,
  WebResponse,
  whenAccessDenied
} from '@src/service'
import { resolveUrlPath } from '@components/navigation'
import { UrlPath } from '@shared/helpers'

export type GetOrDeleteFunction<UrlProps, Response> = (httpClient: HttpClient) => (urlProps: UrlProps) => Promise<Response | undefined>

export type PostOrPutFunction<UrlProps, Request, Response> = (
  httpClient: HttpClient
) => (urlProps: UrlProps, body: Request) => Promise<Response | undefined>

export type PostPutPair<PostUrlProps, PutUrlProps, Request, Response> = [
  PostOrPutFunction<PostUrlProps, Request, Response>,
  PostOrPutFunction<PutUrlProps, Request, Response>
]

export interface RequestResponse<Request, Response> {
  request?: Request
  response?: Response
}

export function newPostFunction<UrlProps, Request, Response>(
  urlPath: UrlPath<UrlProps>,
  _?: RequestResponse<Request, Response> // Only used for type inference
): PostOrPutFunction<UrlProps, Request, Response> {
  return httpClient => async (urlProps, body) => whenAccessDenied(await httpPost(httpClient, resolveUrlPath(urlPath, urlProps), body))
}

export function newRawPostFunction<UrlProps, Request, Response>(
  urlPath: UrlPath<UrlProps>,
  _?: RequestResponse<Request, Response> // Only used for type inference
): PostOrPutFunction<UrlProps, Request, WebResponse<Response>> {
  return httpClient => (urlProps, body) => httpPost(httpClient, resolveUrlPath(urlPath, urlProps), body)
}

export function newPutFunction<UrlProps, Request, Response>(
  urlPath: UrlPath<UrlProps>,
  _?: RequestResponse<Request, Response> // Only used for type inference
): PostOrPutFunction<UrlProps, Request, Response> {
  return httpClient => async (urlProps, body) => whenAccessDenied(await httpPut(httpClient, resolveUrlPath(urlPath, urlProps), body))
}

export function newGetFunction<UrlProps, Response>(urlPath: UrlPath<UrlProps>, withoutBaseUrl?: boolean): GetOrDeleteFunction<UrlProps, Response> {
  return httpClient => async urlProps => whenAccessDenied(await httpGetWithCache(httpClient, resolveUrlPath(urlPath, urlProps), withoutBaseUrl))
}

export function newPostAndPutFunctions<PostUrlProps, PutUrlProps, Request, Response>(
  postUrl: UrlPath<PostUrlProps>,
  putUrl: UrlPath<PutUrlProps>,
  requestResponse?: RequestResponse<Request, Response> // Only used for type inference
): PostPutPair<PostUrlProps, PutUrlProps, Request, Response> {
  return [newPostFunction(postUrl, requestResponse), newPutFunction(putUrl, requestResponse)]
}

export function newDeleteFunction<UrlProps, Response>(urlPath: UrlPath<UrlProps>): GetOrDeleteFunction<UrlProps, Response> {
  return httpClient => async urlProps => whenAccessDenied(await httpDelete(httpClient, resolveUrlPath(urlPath, urlProps)))
}

export function newPatchFunction<UrlProps, Request, Response>(
  urlPath: UrlPath<UrlProps>,
  _?: RequestResponse<Request, Response> // Only used for type inference
): PostOrPutFunction<UrlProps, Request, Response> {
  return httpClient => async (urlProps, body) => whenAccessDenied(await httpPatch(httpClient, resolveUrlPath(urlPath, urlProps), body))
}
