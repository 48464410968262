import * as React from 'react'
import {
    additionalLegendOptions,
    dateField,
    disabledInputFieldComponent,
    FieldGroup,
    numericInputFieldWithCommas,
    renderFieldGroupsWithSideNote,
    selectFieldComponent
} from '@shared/components/forms'
import { emptyValidator, nonZeroNumberLimit } from '@shared/helpers/field-validators'
import { ExpandedLegend, FormOption } from '@src/service'
import { Member } from '@logic'
import { Hash, LegendOptions, legendsToSelectOptions } from '@helpers/util'
import { capitalContributionsFields } from '@modules/captable/components/capital-contributions'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
    legends: ExpandedLegend[]
    currentShareholder?: Member
    values?: FormOption
}

function recipientFields(currentShareholder?: Member): FieldGroup<any> {
    return [
        {
            name: 'name',
            label: 'Holder',
            required: false,
            disabled: true,
            validate: emptyValidator,
            defaultValue: currentShareholder?.fullName,
            component: disabledInputFieldComponent,
        },
        {
            name: 'issueDate',
            label: 'Issue Date',
            required: true,
            ...dateField(React.useRef()),
        },
    ]
}

function shareholdingDetailsFields(legendOptions: LegendOptions): FieldGroup<any> {
    return [
        {
            name: 'shares',
            label: 'Amount of Shares to Transfer',
            required: true,
            placeholder: 'Enter the amount of shares to transfer',
            validate: nonZeroNumberLimit,
            format: formatToNumber,
            component: numericInputFieldWithCommas,
        },
        {
            name: 'legend',
            label: 'Legend',
            required: false,
            validate: emptyValidator,
            component: selectFieldComponent(legendsToSelectOptions(legendOptions), 'Legend'),
        },
    ]
}

export const PurchaseForm = (props: Props) => {
    const { values, legends, currentShareholder } = props
    const additionalLegends = additionalLegendOptions(legends, values?.legend!)
    const legendOptions = legends?.filter(l => !l.deleted)
        .concat(additionalLegends as any)

    return (
        <>
            {renderFieldGroupsWithSideNote(recipientFields(currentShareholder), { title: 'Recipient', })}
            {renderFieldGroupsWithSideNote(capitalContributionsFields(), { title: 'Transaction Value', })}
            {renderFieldGroupsWithSideNote(shareholdingDetailsFields(legendOptions as any), { title: 'Holding Details', })}
        </>
    )
}
