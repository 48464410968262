import * as React from 'react'
import { History } from 'history'
import { GetReferralDataResponse, GetScratchCardResponse, InvitedFriendsResponse, PageProps } from '@logic'
import { MyReferralsPage, ReferralsWrap } from '../style'
import { RouteComponentProps } from 'react-router'
import { copyTextToClipboard } from '@helpers/referral'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { EmailInvitationRequest, sendInvite } from '@src/service/services/profile'
import {
    scratchTicketModal,
    socialMediaLinksModal,
    ReferralsTable,
    ReferralStatistics,
    ScratchOffPanels,
    ReferralInvitePanel
} from '../components'

interface StateProps extends RouteComponentProps<any> {
    user: any
    history: History
}

type Props = PageProps &
{ forGuest?: boolean, openSignUp?: () => void } &
    StateProps &
    GetReferralDataResponse &
    InvitedFriendsResponse &
    GetScratchCardResponse

export const MyReferralsShared = (props: Props) => {
    const [copy, setCopy] = React.useState<boolean>(false)
    const {
        user,
        rewardDetails,
        userDetails,
        invitationDetails,
        httpClient,
        reload,
        params,
        forGuest,
        openSignUp, } = props
    const referralData = userDetails!!
    const referralLink = user ? `${window.location.origin}/refer/r?user=${referralData?.referralLink}` : ''
    const scratchTickets = rewardDetails?.rewards || []
    const scratchedTickets = scratchTickets && scratchTickets.filter(t => t.scratched).length
    const availableTickets = scratchTickets && scratchTickets.filter(t => !t.scratched).length
    const mobileView = window.innerWidth <= 800
    const copyReferralLink = () => {
        copyTextToClipboard(referralLink)
        setCopy(true)
    }

    const onSubmit = async (data: any) => {
        const request: EmailInvitationRequest = {
            referralLink: userDetails.referralLink!,
            emailArray: data.email ? [data.email!] : [],
            recipientName: data.name!,
            entity: user?.id!,
        }
        await sendInvite(httpClient)({}, request)
        if (!forGuest)
            reload()
    }

    const handleCancel = () => {
        scratchModal.setVisible(false)
        if (!forGuest)
            reload()
    }

    const scratchModal = scratchTicketModal({
        ticketList: scratchTickets,
        params,
        onCancel: handleCancel,
        httpClient
    })

    const socialMediaModal = socialMediaLinksModal({ referralLink })

    return (
        <>
            {scratchModal.component}
            {socialMediaModal.component}
            <PageContent withMargin={forGuest}>
                <PageContentHeader title={'Profile Referrals'} hideCrumbs={true} />
                <MyReferralsPage>
                    <ReferralInvitePanel
                        onSubmit={onSubmit}
                        copyReferralLink={copyReferralLink}
                        copy={copy}
                        mobileView={mobileView}
                        socialMediaModalOpen={() => socialMediaModal.setVisible(true)}
                        forGuest={forGuest}
                        openSignUp={openSignUp} />
                    <ReferralsWrap>
                        <ScratchOffPanels
                            availableTickets={availableTickets}
                            scratchedTickets={scratchedTickets}
                            setShowTicketsModal={() => scratchModal.setVisible(true)}
                            mobileView={mobileView}
                            params={params}
                            forGuest={forGuest}
                            openSignUp={openSignUp} />
                        <ReferralStatistics invitationDetails={invitationDetails} rewardDetails={rewardDetails} />
                        <ReferralsTable invitationDetails={invitationDetails} />
                    </ReferralsWrap>
                </MyReferralsPage>
            </PageContent>
        </>
    )
}