import PaperArrows from '@image/paper-arrows.svg'
import PaperBox from '@image/paper-box.svg'
import * as React from 'react'
import CircleCheck from '@image/check-circle.svg'
import MajorityVote from '@image/majority-vote.svg'
import TwoThirdsVote from '@image/two-thirds-vote.svg'
import AllVote from '@image/unanimous-vote.svg'
import { VestmentTrigger } from '@logic'

export enum BuiltInPermission {
  // Organization Permission
  viewOrganization = '7c574e78-e3e6-11e9-81b4-2a2ae2dbcce4',
  viewCapTable = '95efb3d4-e3e6-11e9-81b4-2a2ae2dbcce4',
  viewGoverningDocuments = '9ed2b9b8-e406-11e9-81b4-2a2ae2dbcce4',
  viewMembers = '9ed2bc7e-e406-11e9-81b4-2a2ae2dbcce4',
  viewSelf = 'f632b0ec-5278-11ea-8d77-2e728ce88125',
  viewDocuments = '9ed2bdd2-e406-11e9-81b4-2a2ae2dbcce4',
  viewIncentivePlan = 'e4c6a55b-1016-4fcb-bddc-14938cf6989c',
  NOT_USED_viewPortfolio = '9ed2bf08-e406-11e9-81b4-2a2ae2dbcce4',
  editMembers = '9ed2c322-e406-11e9-81b4-2a2ae2dbcce4',
  editDocuments = '9ed2c566-e406-11e9-81b4-2a2ae2dbcce4',
  editOrganizationDetails = '9ed2c6c4-e406-11e9-81b4-2a2ae2dbcce4',
  editCapTable = '9ed2c7f0-e406-11e9-81b4-2a2ae2dbcce4',
  editIncentivePlan = 'aa8e8f2f-1771-43c8-8071-23038260991e',
  deleteDocuments = '9ed2c926-e406-11e9-81b4-2a2ae2dbcce4',
  signing = '9ed2ca52-e406-11e9-81b4-2a2ae2dbcce4',
  fullVoting = '9ed2cb7e-e406-11e9-81b4-2a2ae2dbcce4',
  partialVoting = '9ed2cf84-e406-11e9-81b4-2a2ae2dbcce4',
  editBilling = '4a1e071a-e40b-11e9-81b4-2a2ae2dbcce4',
  // Site Permission
  writeSite = '5ba8f1a8-7e76-406d-80db-223ca6e132ff',
  readSite = 'aa464cc2-5991-4a1f-b00a-48093af0b695',
}

export enum AgentType {
  individual = 1,
  entity = 2,
}

export enum CompanyType {
  LLC = 1,
  SCorpOrCCorp = 2,
  Other = 3,
}


export const getCompanyType = {
  [CompanyType.LLC]: 'LLC',
  [CompanyType.SCorpOrCCorp]: 'S-Corp/C-Corp',
  [CompanyType.Other]: 'Other',
} as any

export const companyType = [
  { label: 'LLC', value: CompanyType.LLC },
  { label: 'S-Corp/C-Corp', value: CompanyType.SCorpOrCCorp },
  { label: 'Other', value: CompanyType.Other },
]

export const roles: any = {
  [CompanyType.LLC]: [
    { label: 'Member A (Annual Vote)', value: 'memberA' },
    { label: 'Member B (Read Only)', value: 'memberB' },
    { label: 'Manager', value: 'manager' },
    { label: 'Legal', value: 'legal' },
    { label: 'Admin', value: 'admin' },
    { label: 'Advisor (Read Only)', value: 'advisor' },
    { label: 'Signatory', value: 'signatory' },
  ],
  [CompanyType.SCorpOrCCorp]: [
    { label: 'Preferred Shareholder (Annual Vote)', value: 'annual' },
    { label: 'Common Shareholder (Read Only)', value: 'common' },
    { label: 'Director', value: 'director' },
    { label: 'Officer', value: 'officer' },
    { label: 'Legal', value: 'legal' },
    { label: 'Admin', value: 'admin' },
    { label: 'Advisor (Read Only)', value: 'advisor' },
    { label: 'Signatory', value: 'signatory' },
  ],
  [CompanyType.Other]: [{ label: 'Signatory', value: 'signatory' }],
}

export enum EquityStructure {
  singleClass = 1,
  voting = 2,
}

export const equityStructure = [
  {
    label: 'The Company shall issue a single class of Units with no differences in voting or economic rights.',
    value: EquityStructure.singleClass,
    logo: <PaperArrows />,
  },
  {
    label: 'All members will have equal economic rights, but there will be both Voting and Non-Voting Members.',
    value: EquityStructure.voting,
    logo: <PaperBox />,
  },
]

export enum OwnershipTransferability {
  freely = 1,
  majority = 2,
  twoThirds = 3,
  all = 4,
}

export const ownershipTransferability = [
  {
    label: 'Ownership is freely transferable.',
    value: OwnershipTransferability.freely,
    logo: <CircleCheck />,
  },
  {
    label: 'A simple majority of members must approve',
    value: OwnershipTransferability.majority,
    logo: <MajorityVote />,
  },
  {
    label: 'Two-thirds majority of members must approve',
    value: OwnershipTransferability.twoThirds,
    logo: <TwoThirdsVote />,
  },
  {
    label: 'All members must approve',
    value: OwnershipTransferability.all,
    logo: <AllVote />,
  },
]

export enum IncentiveTypes {
  award = '6a20eb29-e509-4eb0-8a45-437d99c61500',
  option = '100611f9-2362-44d7-870e-996ac80524d7',
  warrant = '956efa11-011c-4696-8952-715c34a6d889',
  other = '39de7285-34fd-4cc8-a171-d0e28152b273'
}

export const getIncentiveTypes = {
  [IncentiveTypes.award]: 'Award',
  [IncentiveTypes.option]: 'Option',
  [IncentiveTypes.warrant]: 'Warrant',
  [IncentiveTypes.other]: 'Other'
} as any

export const getVestmentTrigger = {
  [VestmentTrigger.anniversary]: 'Anniversary',
  [VestmentTrigger.firstDayOfMonth]: 'First Day of Month',
  [VestmentTrigger.lastDayOfMonth]: 'Last Day of Month',
} as any

export enum AddressGroups {
  business = 'd311e7c9-3e17-451c-818e-394ec0a9a00c',
  home = '981d817a-4f74-48c2-b3ed-ffc7707381e1',
  mailing = '054374b6-8ca1-42dc-b50c-ae23f26a0281',
  physical = 'bb045916-b58e-4de8-95ce-276e1cd188df'
}

export const addressGroupOptions = [
  { label: 'Business', value: AddressGroups.business },
  { label: 'Home', value: AddressGroups.home },
  { label: 'Mailing', value: AddressGroups.mailing },
  { label: 'Physical', value: AddressGroups.physical },
]

export enum PrimaryActivity {
  Accommodation = 1,
  AccommodationAndFoodServices = 2,
  AdministrativeAndSupportAndWasteManagementAndRemediationServices = 3,
  AdministrativeAndSupportServices = 4,
  AgricultureForestryFishingAndHunting = 5,
  AirTransportation = 6,
  AmbulatoryHealthCareServices = 7,
  AmusementGamblingandRecreationIndustries = 8,
  AnimalProduction = 9,
  ApparelManufacturing = 10,
  ArtsEntertainmentandRecreation = 11,
  BeverageandTobaccoProductManufacturing = 12,
  Broadcasting = 13,
  BuildingMaterialandGardenEquipmentandSuppliesDealers = 14,
  ChemicalManufacturing = 15,
  ClothingandClothingAccessoriesStores = 16,
  ComputerandElectronicProductManufacturing = 17,
  Construction = 18,
  ConstructionofBuildings = 19,
  CouriersandMessengers = 20,
  CreditIntermediationandRelatedActivities = 21,
  CropProduction = 22,
  DataProcessingHostingandRelatedServices = 23,
  EducationandHealthServices = 24,
  EducationalServices = 25,
  ElectricalEquipmentApplianceandComponentManufacturing = 26,
  ElectronicsandApplianceStores = 27,
  FabricatedMetalProductManufacturing = 28,
  FinanceandInsurance = 29,
  FinancialActivities = 30,
  FishingHuntingandTrapping = 31,
  FoodandBeverageStores = 32,
  FoodManufacturing = 33,
  FoodServicesandDrinkingPlaces = 34,
  ForestryandLogging = 35,
  FundsTrustsandOtherFinancialVehicles = 36,
  FurnitureandHomeFurnishingsStores = 37,
  FurnitureandRelatedProductManufacturing = 38,
  GasolineStations = 39,
  GeneralMerchandiseStores = 40,
  GoodsProducingIndustries = 41,
  HealthandPersonalCareStores = 42,
  HealthCareandSocialAssistance = 43,
  HeavyandCivilEngineeringConstruction = 44,
  Hospitals = 45,
  Information = 46,
  InsuranceCarriersandRelatedActivities = 47,
  InternetPublishingandBroadcasting = 48,
  LeatherandAlliedProductManufacturing = 49,
  LeisureandHospitality = 50,
  LessorsofNonfinancialIntangibleAssets = 51,
  MachineryManufacturing = 52,
  ManagementofCompaniesandEnterprises = 53,
  Manufacturing = 54,
  MerchantWholesalersDurableGoods = 55,
  MerchantWholesalersNondurableGoods = 56,
  Mining = 57,
  MiningQuarryingandOilandGasExtraction = 58,
  MiscellaneousManufacturing = 59,
  MiscellaneousStoreRetailers = 60,
  MonetaryAuthoritiesCentralBank = 61,
  MotionPictureandSoundRecordingIndustries = 62,
  MotorVehicleandPartsDealers = 63,
  MuseumsHistoricalSitesandSimilarInstitutions = 64,
  NaturalResourcesandMining = 65,
  NonmetallicMineralProductManufacturing = 66,
  NonstoreRetailers = 67,
  NursingandResidentialCareFacilities = 68,
  OilandGasExtraction = 69,
  OtherInformationServices = 70,
  OtherServices = 71,
  PaperManufacturing = 72,
  PerformingArtsSpectatorSportsandRelatedIndustries = 73,
  PersonalandLaundryServices = 74,
  PetroleumandCoalProductsManufacturing = 75,
  PipelineTransportation = 76,
  PlasticsandRubberProductsManufacturing = 77,
  PostalService = 78,
  PrimaryMetalManufacturing = 79,
  PrintingandRelatedSupportActivities = 80,
  PrivateHouseholds = 81,
  ProfessionalandBusinessServices = 82,
  ProfessionalScientificandTechnicalServices = 83,
  PublishingIndustries = 84,
  RailTransportation = 85,
  RealEstate = 86,
  RealEstateandRentalandLeasingccommodation = 87,
  ReligiousGrantmakingCivicProfessionalandSimilarOrganizations = 88,
  RentalandLeasingServices = 89,
  RepairandMaintenance = 90,
  RetailTrade = 91,
  ScenicandSightseeingTransportation = 92,
  SecuritiesCommodityContractsandOtherFinancialInvestmentsandRelatedActivities = 93,
  ServiceProvidingIndustries = 94,
  SocialAssistance = 95,
  SpecialtyTradeContractors = 96,
  SportingGoodsHobbyBookandMusicStores = 97,
  SupportActivitiesforAgricultureandForestry = 98,
  SupportActivitiesforMining = 99,
  SupportActivitiesforTransportation = 100,
  Telecommunications = 101,
  TextileMills = 102,
  TextileProductMills = 103,
  TradeTransportationandUtilities = 104,
  TransitandGroundPassengerTransportation = 105,
  TransportationandWarehousing = 106,
  TransportationEquipmentManufacturing = 107,
  TruckTransportation = 108,
  Utilities = 109,
  WarehousingandStorage = 110,
  WasteManagementandRemediationServices = 111,
  WaterTransportation = 112,
  WholesaleElectronicMarketsandAgentsandBrokers = 113,
  WholesaleTrade = 114,
  WoodProductManufacturing = 115,
}

export const primaryActivities = [
  { label: 'Accommodation', value: PrimaryActivity.Accommodation },
  { label: 'Accommodation and Food Services', value: PrimaryActivity.AccommodationAndFoodServices },
  {
    label: 'Administrative and Support and Waste Management and Remediation Services',
    value: PrimaryActivity.AdministrativeAndSupportAndWasteManagementAndRemediationServices,
  },
  { label: 'Administrative and Support Services', value: PrimaryActivity.AdministrativeAndSupportServices },
  { label: 'Agriculture Forestry Fishing and Hunting', value: PrimaryActivity.AgricultureForestryFishingAndHunting },
  { label: 'Air Transportation', value: PrimaryActivity.AirTransportation },
  { label: 'Ambulatory Health Care Services', value: PrimaryActivity.AmbulatoryHealthCareServices },
  { label: 'Amusement Gambling and Recreation Industries', value: PrimaryActivity.AmusementGamblingandRecreationIndustries },
  { label: 'Animal Production', value: PrimaryActivity.AnimalProduction },
  { label: 'Apparel Manufacturing', value: PrimaryActivity.ApparelManufacturing },
  { label: 'Arts Entertainment and Recreation', value: PrimaryActivity.ArtsEntertainmentandRecreation },
  { label: 'Beverage and Tobacco Product Manufacturing', value: PrimaryActivity.BeverageandTobaccoProductManufacturing },
  { label: 'Broadcasting', value: PrimaryActivity.Broadcasting },
  {
    label: 'Building Material and Garden Equipment and Supplies Dealers',
    value: PrimaryActivity.BuildingMaterialandGardenEquipmentandSuppliesDealers,
  },
  { label: 'Chemical Manufacturing', value: PrimaryActivity.ChemicalManufacturing },
  { label: 'Clothing and Clothing Accessories Stores', value: PrimaryActivity.ClothingandClothingAccessoriesStores },
  { label: 'Computer and Electronic Product Manufacturing', value: PrimaryActivity.ComputerandElectronicProductManufacturing },
  { label: 'Construction', value: PrimaryActivity.Construction },
  { label: 'Construction of Buildings', value: PrimaryActivity.ConstructionofBuildings },
  { label: 'Couriers and Messengers', value: PrimaryActivity.CouriersandMessengers },
  { label: 'Credit Intermediation and Related Activities', value: PrimaryActivity.CreditIntermediationandRelatedActivities },
  { label: 'Crop Production', value: PrimaryActivity.CropProduction },
  { label: 'Data Processing Hosting and Related Services', value: PrimaryActivity.DataProcessingHostingandRelatedServices },
  { label: 'Education and Health Services', value: PrimaryActivity.EducationandHealthServices },
  { label: 'Educational Services', value: PrimaryActivity.EducationalServices },
  {
    label: 'Electrical Equipment Appliance and Component Manufacturing',
    value: PrimaryActivity.ElectricalEquipmentApplianceandComponentManufacturing,
  },
  { label: 'Electronics and Appliance Stores', value: PrimaryActivity.ElectronicsandApplianceStores },
  { label: 'Fabricated Metal Product Manufacturing', value: PrimaryActivity.FabricatedMetalProductManufacturing },
  { label: 'Finance and Insurance', value: PrimaryActivity.FinanceandInsurance },
  { label: 'Financial Activities', value: PrimaryActivity.FinancialActivities },
  { label: 'Fishing Hunting and Trapping', value: PrimaryActivity.FishingHuntingandTrapping },
  { label: 'Food and Beverage Stores', value: PrimaryActivity.FoodandBeverageStores },
  { label: 'Food Manufacturing', value: PrimaryActivity.FoodManufacturing },
  { label: 'Food Services and Drinking Places', value: PrimaryActivity.FoodServicesandDrinkingPlaces },
  { label: 'Forestry and Logging', value: PrimaryActivity.ForestryandLogging },
  { label: 'Funds Trusts and Other Financial Vehicles', value: PrimaryActivity.FundsTrustsandOtherFinancialVehicles },
  { label: 'Furniture and Home Furnishings Stores', value: PrimaryActivity.FurnitureandHomeFurnishingsStores },
  { label: 'Furniture and Related Product Manufacturing', value: PrimaryActivity.FurnitureandRelatedProductManufacturing },
  { label: 'Gasoline Stations', value: PrimaryActivity.GasolineStations },
  { label: 'General Merchandise Stores', value: PrimaryActivity.GeneralMerchandiseStores },
  { label: 'Goods Producing Industries', value: PrimaryActivity.GoodsProducingIndustries },
  { label: 'Health and Personal Care Stores', value: PrimaryActivity.HealthandPersonalCareStores },
  { label: 'Health Care and Social Assistance', value: PrimaryActivity.HealthCareandSocialAssistance },
  { label: 'Heavy and Civil Engineering Construction', value: PrimaryActivity.HeavyandCivilEngineeringConstruction },
  { label: 'Hospitals', value: PrimaryActivity.Hospitals },
  { label: 'Information', value: PrimaryActivity.Information },
  { label: 'Insurance Carriers and Related Activities', value: PrimaryActivity.InsuranceCarriersandRelatedActivities },
  { label: 'Internet Publishing and Broadcasting', value: PrimaryActivity.InternetPublishingandBroadcasting },
  { label: 'Leather and Allied Product Manufacturing', value: PrimaryActivity.LeatherandAlliedProductManufacturing },
  { label: 'Leisure and Hospitality', value: PrimaryActivity.LeisureandHospitality },
  { label: 'Lessors of Nonfinancial Intangible Assets', value: PrimaryActivity.LessorsofNonfinancialIntangibleAssets },
  { label: 'Machinery Manufacturing', value: PrimaryActivity.MachineryManufacturing },
  { label: 'Management of Companies and Enterprises', value: PrimaryActivity.ManagementofCompaniesandEnterprises },
  { label: 'Manufacturing', value: PrimaryActivity.Manufacturing },
  { label: 'Merchant Wholesalers Durable Goods', value: PrimaryActivity.MerchantWholesalersDurableGoods },
  { label: 'Merchant Wholesalers Nondurable Goods', value: PrimaryActivity.MerchantWholesalersNondurableGoods },
  { label: 'Mining', value: PrimaryActivity.Mining },
  { label: 'Mining Quarrying and Oil and Gas Extraction', value: PrimaryActivity.MiningQuarryingandOilandGasExtraction },
  { label: 'Miscellaneous Manufacturing', value: PrimaryActivity.MiscellaneousManufacturing },
  { label: 'Miscellaneous Store Retailers', value: PrimaryActivity.MiscellaneousStoreRetailers },
  { label: 'Monetary Authorities Central Bank', value: PrimaryActivity.MonetaryAuthoritiesCentralBank },
  { label: 'Motion Picture and Sound Recording Industries', value: PrimaryActivity.MotionPictureandSoundRecordingIndustries },
  { label: 'Motor Vehicle and Parts Dealers', value: PrimaryActivity.MotorVehicleandPartsDealers },
  { label: 'Museums Historical Sites and Similar Institutions', value: PrimaryActivity.MuseumsHistoricalSitesandSimilarInstitutions },
  { label: 'Natural Resources and Mining', value: PrimaryActivity.NaturalResourcesandMining },
  { label: 'Nonmetallic Mineral Product Manufacturing', value: PrimaryActivity.NonmetallicMineralProductManufacturing },
  { label: 'Nonstore Retailers', value: PrimaryActivity.NonstoreRetailers },
  { label: 'Nursing and Residential Care Facilities', value: PrimaryActivity.NursingandResidentialCareFacilities },
  { label: 'Oil and Gas Extraction', value: PrimaryActivity.OilandGasExtraction },
  { label: 'Other Information Services', value: PrimaryActivity.OtherInformationServices },
  { label: 'Other Services', value: PrimaryActivity.OtherServices },
  { label: 'Paper Manufacturing', value: PrimaryActivity.PaperManufacturing },
  {
    label: 'Performing Arts Spectator Sports and Related Industries',
    value: PrimaryActivity.PerformingArtsSpectatorSportsandRelatedIndustries,
  },
  { label: 'Personal and Laundry Services', value: PrimaryActivity.PersonalandLaundryServices },
  { label: 'Petroleum and Coal Products Manufacturing', value: PrimaryActivity.PetroleumandCoalProductsManufacturing },
  { label: 'Pipeline Transportation', value: PrimaryActivity.PipelineTransportation },
  { label: 'Plastics and Rubber Products Manufacturing', value: PrimaryActivity.PlasticsandRubberProductsManufacturing },
  { label: 'Postal Service', value: PrimaryActivity.PostalService },
  { label: 'Primary Metal Manufacturing', value: PrimaryActivity.PrimaryMetalManufacturing },
  { label: 'Printing and Related Support Activities', value: PrimaryActivity.PrintingandRelatedSupportActivities },
  { label: 'Private Households', value: PrimaryActivity.PrivateHouseholds },
  { label: 'Professional and Business Services', value: PrimaryActivity.ProfessionalandBusinessServices },
  { label: 'Professional Scientific and Technical Services', value: PrimaryActivity.ProfessionalScientificandTechnicalServices },
  { label: 'Publishing Industries', value: PrimaryActivity.PublishingIndustries },
  { label: 'Rail Transportation', value: PrimaryActivity.RailTransportation },
  { label: 'Real Estate', value: PrimaryActivity.RealEstate },
  { label: 'Real Estate and Rental and Leasing', value: PrimaryActivity.RealEstateandRentalandLeasingccommodation },
  {
    label: 'Religious Grantmaking Civic Professional and Similar Organizations',
    value: PrimaryActivity.ReligiousGrantmakingCivicProfessionalandSimilarOrganizations,
  },
  { label: 'Rental and Leasing Services', value: PrimaryActivity.RentalandLeasingServices },
  { label: 'Repair and Maintenance', value: PrimaryActivity.RepairandMaintenance },
  { label: 'Retail Trade', value: PrimaryActivity.RetailTrade },
  { label: 'Scenic and Sightseeing Transportation', value: PrimaryActivity.ScenicandSightseeingTransportation },
  {
    label: 'Securities Commodity Contracts and Other Financial Investments and Related Activities',
    value: PrimaryActivity.SecuritiesCommodityContractsandOtherFinancialInvestmentsandRelatedActivities,
  },
  { label: 'Service Providing Industries', value: PrimaryActivity.ServiceProvidingIndustries },
  { label: 'Social Assistance', value: PrimaryActivity.SocialAssistance },
  { label: 'Specialty Trade Contractors', value: PrimaryActivity.SpecialtyTradeContractors },
  { label: 'Sporting Goods Hobby Book and Music Stores', value: PrimaryActivity.SportingGoodsHobbyBookandMusicStores },
  { label: 'Support Activities for Agriculture and Forestry', value: PrimaryActivity.SupportActivitiesforAgricultureandForestry },
  { label: 'Support Activities for Mining', value: PrimaryActivity.SupportActivitiesforMining },
  { label: 'Support Activities for Transportation', value: PrimaryActivity.SupportActivitiesforTransportation },
  { label: 'Telecommunications', value: PrimaryActivity.Telecommunications },
  { label: 'Textile Mills', value: PrimaryActivity.TextileMills },
  { label: 'Textile Product Mills', value: PrimaryActivity.TextileProductMills },
  { label: 'Trade Transportation and Utilities', value: PrimaryActivity.TradeTransportationandUtilities },
  { label: 'Transit and Ground Passenger Transportation', value: PrimaryActivity.TransitandGroundPassengerTransportation },
  { label: 'Transportation and Warehousing', value: PrimaryActivity.TransportationandWarehousing },
  { label: 'Transportation Equipment Manufacturing', value: PrimaryActivity.TransportationEquipmentManufacturing },
  { label: 'Truck Transportation', value: PrimaryActivity.TruckTransportation },
  { label: 'Utilities', value: PrimaryActivity.Utilities },
  { label: 'Warehousing and Storage', value: PrimaryActivity.WarehousingandStorage },
  { label: 'Waste Management and Remediation Services', value: PrimaryActivity.WasteManagementandRemediationServices },
  { label: 'Water Transportation', value: PrimaryActivity.WaterTransportation },
  { label: 'Wholesale Electronic Markets and Agents and Brokers', value: PrimaryActivity.WholesaleElectronicMarketsandAgentsandBrokers },
  { label: 'Wholesale Trade', value: PrimaryActivity.WholesaleTrade },
  { label: 'Wood Product Manufacturing', value: PrimaryActivity.WoodProductManufacturing },
]

