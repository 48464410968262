import * as React from 'react'
import { renderFieldsSimple, textInputFieldComponent, textAreaInputFieldComponent, multiSelectFieldComponent } from '@shared/components/forms'
import { emptyValidator, isRepeated, optionsFromMembers } from '@shared/helpers'
import styled from 'styled-components'
import { FieldNote, whiteColor } from '@src/styles'
import { Member } from '@logic'
import { PermissionsTable } from './permissions-table'
import { NewRoleRequest, Role } from '@src/service'
import { allPermissions } from '../utility'
import { CommonSubmissionFooter } from '@shared/components/forms/common-submission-footer'

interface Props {
    values?: any
    members?: Member[]
    roles?: Role[]
    isAdmin?: boolean
    isAdminRole?: boolean
    edit?: boolean
    clone?: boolean
    skip?: any
}
const StyleCreateLegendForm = styled.div`
    margin: 64px 0;
    p {
        margin: 0;
        font-size: 14px;
        line-height: 19px;
    }
`
export const StyledHead = styled.h5<any>`
    ${whiteColor}
    font-weight: 600;
    font-family: 'NunitoSansSemiBold';
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 32px 0;
    span {
        display: inline;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
`

export const RoleForm = (props: Props) => {
    const { members, values, isAdmin, edit, roles, skip, clone, isAdminRole } = props
    const membersOptions = optionsFromMembers(members!)
    const all = roles?.map(r => r?.name?.toLowerCase())
    // const noPermissionsSelected = () => {
    //     if (values.permission) {
    //         const data = Object.entries(values.permission!).map(p => p[1])
    //         if (data.includes(true)) return false
    //         else return true
    //     }
    //     else return true
    // }
    const disableSubmit = props?.invalid! || props?.submitting!

    React.useEffect(() => {
        if (clone) document?.getElementsByName('role')[0]?.focus()
    }, [])

    return (
        <>
            <StyleCreateLegendForm>
                {!isAdmin && renderFieldsSimple([
                    {
                        name: 'role',
                        label: 'Enter Role Name',
                        required: true,
                        validate: isRepeated(all, edit),
                        placeholder: 'Enter role name',
                        component: textInputFieldComponent,
                        fixed: true,
                    },
                    {
                        name: 'description',
                        label: 'Enter Role Definition',
                        required: true,
                        validate: emptyValidator,
                        placeholder: 'Enter role definition',
                        note: 'This appears on your role view page.',
                        component: textAreaInputFieldComponent()
                    }
                ], true)
                }
                <PermissionsTable values={values} isAdmin={isAdmin} permissions={allPermissions} />
                <StyledHead>Assign Members{isAdminRole && <span> (1 or more Required)</span>}</StyledHead>
                {renderFieldsSimple([
                    {
                        name: 'members',
                        label: 'Assign Members',
                        required: isAdminRole ? true : false,
                        validate: emptyValidator,
                        note: 'Members can be assigned to multiple roles. This assignment is only for this specific role.',
                        component: multiSelectFieldComponent(membersOptions, 'Assign Members'),
                    },
                ])}
            </StyleCreateLegendForm>
            <CommonSubmissionFooter submitText={'Save'} disabled={disableSubmit} skipText={'Cancel'} skip={skip} />
        </>
    )
}

export function preparePermissionsForSubmission(values: any): NewRoleRequest {
    const permission = values.permission
        ? Object.entries(values.permission)
            .map((value: any) => {
                if (value[1]) return value[0]
            })?.filter(v => v)
        : []
    return { ...values, permission }
}
