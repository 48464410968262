import * as React from 'react'
import { accountPath, allSetPath, OrganizationProps, PageProps } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { CommonForm } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { GetSecuritiesResponse, GetLegendsResponse } from '@src/service'
import { editAccount } from '@src/service/services/profile'
import { AccountSettingsForm } from '@modules/profile/components/account-settings-form'
import { whiteColor } from '@src/styles'
import styled from 'styled-components'
import WelcomeHeader from '@image/welcome-top.png'

const Wrapper = styled.div`
  ${whiteColor}
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
      width: 100%;
      height: auto;
      margin-top: 57px;
  }

  .title{
    font-size: 48px;
    margin: 24px 0 52px 0;
    font-weight: 400; 
  }
`

const PINFormWrapper = styled.div<any>`
`

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>()

export const AddPinPage = withData((props: Props) => {
    const { httpClient, navigate, user, location } = props
    const initialValues = {}
    const isEdit = location?.pathname && location.pathname.split('/')[3] === 'edit' ? true : false
    const onSubmit = async (values: any) => {
        if (user) {
            const request = {
                pin: values?.pin ? values.pin : null
            } as any

            const response = await editAccount(httpClient)({ user: user.id }, request)
            if (response?.id) {
                navigate(allSetPath)
            }
            else console.log('Edit PIN error') // TODO: add error message toast
        }
    }

    return (
        <Wrapper>
            <img src={WelcomeHeader} />
            <PageContent noPadding={true}>
                <span className="title">Add Four Digit PIN </span>
                <PINFormWrapper>
                    <CommonForm mutators={{ ...arrayMutators }} onSubmit={onSubmit} initialValues={initialValues}
                        submitText="Save" skipText="skip" skip={{ path: allSetPath }}>
                        <AccountSettingsForm />
                    </CommonForm>
                </PINFormWrapper>
            </PageContent>
        </Wrapper>
    )
})
