import * as React from 'react'
import styled from 'styled-components'
import { colors, FlexRow, Themes } from '@src/styles'

const SnackBarEle = styled<any>(FlexRow)`
    background: ${({ theme }) => theme.selected === Themes.LIGHT ? colors.white : theme.header};
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.08),
                0px 1px 18px rgba(0, 0, 0, 0.05),
                0px 3px 5px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 12px 16px;
    position: fixed;
    top: 76px;
    left: calc(50vw - 120px);
    color: ${({ theme }) => theme.white};
    display: ${props => !props.show && 'none'};
    z-index: 999;
`

interface Props {
    message: string
    reload: () => void
}

export interface SnackBarBundle {
    component: React.ReactElement
    show: boolean
    setShow: (value: boolean) => void
}

export function snackBar(props: Props): SnackBarBundle {
    const [show, setShow] = React.useState<boolean>(false)
    const { message, reload } = props
    React.useEffect(() => {
        if (show)
            setTimeout(() => {
                setShow(false)
                reload()
            }, 4000)
    })
    return {
        show,
        setShow,
        component: <SnackBarEle show={show}>{message}</SnackBarEle>
    }
}
