import * as React from 'react'
import { FlexCol, FlexRow, semiBoldStyle } from '@src/styles'
import WhiteMark from '@image/equa-logo-32x32.svg'
import WhiteMarkSmall from '@image/equa-logo-16x16.svg'
import styled from 'styled-components'
import { FlowingGrid, FlowingWrap, mediumFontStyle, NormalHeader } from '@modules/referrals/style'
import { PathLink } from '@shared/components/navigation'
import { InvitationDetails, ReferralOrganizationResponse, referralsPath, ReferralUserResponse, ScratchCardResponse } from '@logic'

interface Props {
    referralData?: ReferralOrganizationResponse
    userReferralData?: ReferralUserResponse
    invitationDetails: InvitationDetails
    rewardDetails: ScratchCardResponse
    forOrg?: boolean
}

const InfoBox = styled<any>(FlexCol)`
  background: ${({ theme }) => theme.panelBack};
  border-radius: 8px;
  justify-content: space-between;
  svg {
      path {
          fill: ${({ theme }) => theme.white};
      }
  }
`

const TopInfo = styled<any>(FlexCol)`
  width: 100%;
  padding: 24px 24px 0px;
  svg {
    margin-right: 10px;
  }
`

const BottomInfo = styled<any>(FlexRow)`
  padding: 12px 0;
  width: 100%;
  border-top: 1px solid rgba(206, 218, 225, 0.3);
  .values {
    display: flex;
    flex-direction: column;
    :last-child {
      align-items: flex-end;
    }
    svg {
        margin-right: 4px;
    }
  }
`

const BoxHeader = styled.span<any>`
  ${semiBoldStyle}
  ${mediumFontStyle}
  text-align: center;
  text-transform: uppercase;
  a {
      :hover {
          text-decoration: underline;
      }
  }
`

const EquaCash = styled.span<any>`
  font-size: 40px;
  line-height: 55px;
  margin: 32px 0 28px 0;
`
interface PanelData {
    title: string
    value: number
    day: number
    lastMonth: number
    link?: string
    target?: string
}

interface InfoProps {
    data: PanelData[]
    forOrg?: boolean
}

const renderInfo = (props: InfoProps) => {
    const { data, forOrg } = props
    return data.map(d => {
        return <InfoBox>
            <TopInfo>
                <BoxHeader>
                    {d.title}
                    {d.link &&
                        <>
                            {' '}<PathLink path={d.target} args={{}}>
                                {d.link}
                            </PathLink>
                        </>
                    }
                </BoxHeader>
                <EquaCash>
                    {forOrg ? <WhiteMark /> : d.title.includes('cash') ? <WhiteMark /> : null}
                    {d.value}
                </EquaCash>
            </TopInfo>
            <BottomInfo>
                <FlexRow style={{ padding: '0px 24px', width: '100%' }} justifyContent={'space-between'}>
                    <div className='values'>
                        <FlexRow>
                            {forOrg ? <WhiteMarkSmall /> : d.title.includes('cash') ? <WhiteMarkSmall /> : null}
                            {d.day}</FlexRow>
                        <span>24 hours</span>
                    </div>
                    <div className='values'>
                        <FlexRow>
                            {forOrg ? <WhiteMarkSmall /> : d.title.includes('cash') ? <WhiteMarkSmall /> : null}
                            {d.lastMonth}</FlexRow>
                        <span>Last Month</span>
                    </div>
                </FlexRow>
            </BottomInfo>
        </InfoBox>
    }
    )
}

export const ReferralStatistics = (props: Props) => {
    const { referralData, forOrg, invitationDetails, rewardDetails, userReferralData } = props
    const invitations = invitationDetails?.invitations!!

    const data: PanelData[] = !forOrg ?
        [
            {
                title: 'TOTAL PROFILE REFERRALS',
                value: invitations?.length || 0,
                day: invitationDetails?.dailyInvitation || 0,
                lastMonth: invitationDetails?.monthlyInvitation || 0
            },
            {
                title: 'TOTAL SCRATCH-OFF TICKETS EARNED',
                value: rewardDetails?.rewards?.length || 0,
                day: rewardDetails?.dailyTicket || 0,
                lastMonth: rewardDetails?.monthlyTicket || 0
            },
            {
                title: 'TOTAL PROFILE EQUA cash EARNED',
                value: rewardDetails?.totalReward || 0,
                day: rewardDetails?.dailyReward || 0,
                lastMonth: rewardDetails?.monthlyReward || 0
            }
        ]
        :
        [
            {
                title: 'TOTAL MEMBER EQUA CASH EARNED FOR THIS ORG',
                value: userReferralData?.totalReward || 0,
                day: userReferralData?.dailyReward || 0,
                lastMonth: userReferralData?.monthlyReward || 0
            },
            {
                title: 'total org Equa cash earned for this org',
                value: referralData?.orgTotalReward || 0,
                day: referralData?.orgDailyReward || 0,
                lastMonth: referralData?.orgMonthlyReward || 0
            },
            {
                title: 'your personal equa cash earned FOR',
                value: rewardDetails?.totalReward || 0,
                day: rewardDetails?.dailyReward || 0,
                lastMonth: rewardDetails?.monthlyReward || 0,
                link: 'Your Profile',
                target: referralsPath
            }
        ]

    return (
        <FlowingWrap>
            <NormalHeader>Equa Cash Statistics</NormalHeader>
            <FlowingGrid columns={3}>
                {renderInfo({ data, forOrg })}
            </FlowingGrid>
        </FlowingWrap>
    )
}
