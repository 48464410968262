import * as React from 'react'
import { User } from '@logic'
import { isPin } from '@helpers/field-validators'
import { FieldGroup, protectedInputFieldComponent, renderFieldsSimple } from '@shared/components/forms'
import styled from 'styled-components'
import { fontSizes, gapSizes, LightDivider } from '@src/styles'
import { PathLinkButtonInverted } from '@shared/components/button'
import { formatToNumber } from '@modules/captable/utility'

interface Props {
    user?: User,
    values?: any
}

const AccountSettingsWrapper = styled.div<any>`
  color: ${props => props.theme.white};
  span {
    font-size: ${fontSizes.XS};
    line-height: 24px;
    margin: 1px 0 7px 0;
  }
  p {
      font-size: ${fontSizes.XXS} !important;
      color: #9CA6AD !important;
      margin: 0;
  }
  h3 {
      font-size: 20px;
      line-height: 28px;
      font-weight: normal;
      margin: 9px 0 24px 0;
  }
`

const StyledFields = styled.div<any>`
  margin-top: ${gapSizes.M};
`

const StyledButton = styled<any>(PathLinkButtonInverted)`
  margin: 24px 0 ${gapSizes.M} 0;
`

function PINFields(values: any): FieldGroup<any> {
    return [
        {
            name: 'pin',
            label: 'Enter your four digit PIN',
            required: false,
            validate: isPin(),
            format: formatToNumber,
            placeholder: 'Enter your four digit PIN here',
            component: protectedInputFieldComponent({}, 'number', true),
        },
        {
            name: 'confirmPin',
            label: 'Re-enter your four digit PIN',
            required: false,
            format: formatToNumber,
            validate: isPin(values?.pin!),
            placeholder: 'Re-enter your four digit PIN here',
            component: protectedInputFieldComponent({}, 'number', true),
        },
    ]
}

export const AccountSettingsForm = (props: Props) => {
    const { values } = props
    return (
        <AccountSettingsWrapper>
            <span>Create your custom four digit PIN</span>
            <p>This will be used in various cases throughout the app for document signing.</p>
            <StyledFields>
                {renderFieldsSimple(PINFields(values))}
            </StyledFields>
        </AccountSettingsWrapper>
    )
}
