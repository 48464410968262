import * as React from 'react'
import { editPermissionsPath, PageProps } from '@logic'
import { PageContent } from '@components/pages'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadRoles } from '@helpers/loaders'
import { GetRolesResponse } from '@src/service'
import styled from 'styled-components'
import { colors, FlexRow, Themes, whiteColor } from '@src/styles'
import { SubHeaderText } from './create-role-page'
import { RolePermissionsTable } from '../components/role-permissions-table'
import EditPen from '@image/edit-pen-new.svg'
import { PathLink } from '@shared/components/navigation'
import { tabs } from '../utility'
import { Tabs } from '@shared/components/tabs/tabs'
import { Roles } from '@shared/components/forms'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

type Props = PageProps & GetRolesResponse

const HeadWrap = styled<any>(FlexRow)`
    ${whiteColor}
    justify-content: space-between;
    margin: 0 0 16px 0;
    span {
        line-height: 24px;
    }
`

const PermissionsHeader = styled.h5<any>`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: 'NunitoSansSemiBold';
    margin: 0;
`

const EditButton = styled<any>(PathLink)`
    color: ${props => props.theme.listIcon};
    cursor: pointer;
    background: ${props => props.theme.actionsDrop.default};
    border-radius: 4px;
    padding: 12px 32px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-family: 'NunitoSansBold';
    span {
        font-size: 14px;
        line-height: 24px;
    }
    svg {
        path {
            fill: ${props => props.theme.listIcon};
        }
    }
    &:hover {
        background: ${props => props.theme.actionsDrop.hover};
        color: ${props => props.theme.selected === Themes.LIGHT && colors.white};
        path {
            fill: ${props => props.theme.selected === Themes.LIGHT && colors.white};
        }
    }
`

const withData = withLoadingCachedMultiple<Props>(loadRoles)

export const PermissionsPage = withData(withPermissions([BuiltInPermission.viewMembers])((props: Props) => {
    const { navigate, params, roles, httpClient, reload } = props
    const { organization } = params
    const filtered = roles?.filter(r => r.id !== Roles.admin)
    const admin = roles?.filter(r => r.id === Roles.admin)
    const sortedRoles = admin.concat(filtered?.sort((a, b) => {
        return a.name.localeCompare(b.name)
    }))

    return (
        <>
            <Tabs tabs={tabs} title={'Roles & Permissions'} params={params} />
            <PageContent>
                <HeadWrap>
                    <PermissionsHeader>Manage Your Permissions</PermissionsHeader>
                    <EditButton path={editPermissionsPath} args={{ organization }}>
                        <EditPen />
                        <span>Edit</span>
                    </EditButton>
                </HeadWrap>
                <SubHeaderText style={{ maxWidth: '100%' }}>Use this page to quickly view and compare all of the permissions assigned to your roles in one place.</SubHeaderText>
                {sortedRoles && sortedRoles.length > 0 &&
                    <RolePermissionsTable
                        roles={sortedRoles}
                        navigate={navigate}
                        organization={organization}
                        httpClient={httpClient}
                        reload={reload}
                    />
                }
            </PageContent>
        </>
    )
}))
