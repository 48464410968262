import * as React from 'react'
import {
    renderFieldsSimple,
    inputFieldWithCharactersComponent,
    renderHeader,
    numericInputFieldWithDecimals,
    selectFieldComponent,
    CustomSelectOption
} from '@shared/components/forms'
import { composeValidators, emptyValidator, formatImageUrl, isGreaterThanZero, isLessOrEqual, stringLength, optionalCommaAmount } from '@shared/helpers'
import styled from 'styled-components'
import { whiteColor } from '@src/styles'
import { Organization, User } from '@logic'
import { nameAbbreviation } from '@shared/components/avatar'
import { ImagePlaceholder } from './transaction-history-table'

interface Props {
    organizations: any
    organization?: Organization
    balance: number
    values?: any
    user?: any
}

export const StyledHead = styled.h5<any>`
    ${whiteColor}
    font-weight: 600;
    font-family: 'NunitoSansSemiBold';
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 32px 0;
    span {
        display: inline;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
`

export const TransferFormWrap = styled.div<any>`
    h2 {
        margin-top: 0 !important;
    }
`

export const TransferCashForm = (props: Props) => {
    const { organizations, organization, balance, user } = props

    const toOptions: CustomSelectOption[] = user ?
        [user].concat(organizations).map((org: any) => ({
            label: org.name || 'Your Profile',
            value: org.id,
            image: (org.logo?.hash || org.file?.hash) ? <img src={formatImageUrl(org.logo?.hash || org.file?.hash)} />
                :
                <ImagePlaceholder>{nameAbbreviation(org.name || org.fullName)}</ImagePlaceholder>,
            info: `(Balance: ${optionalCommaAmount(org.equaCash)} E$)`
        }))
        :
        organizations.map((org: any) => ({
            label: org.name,
            value: org.id,
            image: org.logo?.hash ? <img src={formatImageUrl(org.logo?.hash)} />
                :
                <ImagePlaceholder>{nameAbbreviation(org.name)}</ImagePlaceholder>,
            info: `(Balance: ${optionalCommaAmount(org.equaCash)} E$)`
        }))

    return (
        <TransferFormWrap>
            {renderHeader({ label: organization ? `Transfer from ${organization?.name}` : 'Transfer from your Profile' })}
            {renderFieldsSimple([
                {
                    name: 'to',
                    label: 'Transfer To',
                    required: true,
                    validate: emptyValidator,
                    component: selectFieldComponent(toOptions, 'Transfer To'),
                    fixed: true,
                },
                {
                    name: 'value',
                    label: 'Amount ',
                    required: true,
                    validate: composeValidators([
                        isLessOrEqual(balance || 0, organization ?
                            'Must be less or equal to organization balance'
                            :
                            'Must be less or equal to profile balance'),
                        isGreaterThanZero
                    ]),
                    placeholder: 'Enter amount',
                    note: `Current Balance: ${optionalCommaAmount(balance)} E$`,
                    component: numericInputFieldWithDecimals
                },
                {
                    name: 'memo',
                    label: 'Memo',
                    required: false,
                    validate: stringLength(0, 60),
                    placeholder: 'Example: Thank you for the referrals!',
                    component: inputFieldWithCharactersComponent()
                }
            ])}
        </TransferFormWrap>
    )
}
