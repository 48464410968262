import * as React from 'react'
import { OrganizationResponse, OrganizationsProps, PageProps, Paths } from '@logic'
import { emptyValidator, isEmail, isTitle } from '@helpers/field-validators'
import { CommonForm, dateField, multiSelectFieldComponent, renderFieldGroups, rolesField, textInputFieldComponent } from '@components/forms'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadOrganizations } from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { BaseLinkProps } from '@components/navigation/navigation'
import { PageContentHeader } from '@components/pages/page-content-header'
import { fullNameField } from '@modules/team-members/components/member-form'

type Props = PageProps & OrganizationsProps

const withData = withLoadingCachedMultiple<Props>(loadOrganizations)

function mapOrganizationOptions(organizations: OrganizationResponse[]) {
  return organizations.map(o => ({ value: o.id, label: o.name }))
}

export const OrganizationInvitation = withData((props: Props) => {
  const { organizations, serviceOld } = props
  const parent: BaseLinkProps = { path: Paths.admin }

  async function onSubmit(data: any) {
    const { dataRoomName, email, fullName, roles } = data
    const organizations = data.organizations.map((i: any) => i.value)
    const requestData = { dataRoomName, email, fullName, organizations, roles }
    const response = await serviceOld.adminOrganizationInvitation(data)
    if (response.organizations) {
      const globalWindow = window as any
      globalWindow.store.dispatch({ type: 'DISPLAY_TOAST', data: { key: 'invitedToOrganization' } })
    }
  }

  const initialValues = {
    email: '',
    fullName: '',
    organizations: [],
    roles: [],
  }

  return (
    <PageContent>
      <PageContentHeader title="Organization Invitation" parent={parent} />
      <CommonForm onSubmit={onSubmit} initialValues={initialValues} submitText="Invite User">
        {renderFieldGroups([
          [
            {
              name: 'fullName',
              label: 'Name',
              required: true,
              ...fullNameField,
            },
            {
              name: 'title',
              label: 'Title',
              required: false,
              validate: isTitle,
              component: textInputFieldComponent,
            },
            {
              name: 'email',
              label: 'Email Address',
              required: false,
              validate: isEmail,
              component: textInputFieldComponent,
            },
            {
              name: 'dateOfBirth',
              label: 'Date of Birth',
              required: false,
              placeholder: 'MM/DD/YYYY',
              ...dateField(React.useRef()),
            },
            {
              name: 'organizations',
              label: 'Organizations',
              required: true,
              validate: emptyValidator,
              component: multiSelectFieldComponent(mapOrganizationOptions(organizations), 'Organizations'),
            },
            {
              name: 'roles',
              label: 'Roles',
              required: true,
              ...rolesField,
            },
            {
              name: 'dataRoomName',
              label: 'Data Room (Optional)',
              required: false,
              validate: emptyValidator,
              component: textInputFieldComponent,
            },
          ],
        ])}
      </CommonForm>
    </PageContent>
  )
})
