import * as React from 'react'
import { PageProps } from '@logic'

type Props = PageProps

export const AdminUsers = (props: Props) => {
  const { service } = props

  const usersAndMembers = [
    { email: 'j.cooley42@gmail.com', organizationCount: 4, isAdmin: false, userId: '23042398237-fsdkj-fskkj3-234' },
    { email: 'froggy@gmail.com', organizationCount: 4, isAdmin: false, userId: '23042398237-fsdkj-fskkj3-234' },
    { email: 'jeff@gmail.com', organizationCount: 4, isAdmin: false, userId: '23042398237-fsdkj-fskkj3-234' },
    { email: 'j.cooley42@gmail.com', organizationCount: 4, isAdmin: true, userId: '23042398237-fsdkj-fskkj3-234' },
  ]
  return (
    <div>
      <div>
        {/*<ReactTable*/}
        {/*  data={usersAndMembers}*/}
        {/*  columns={[*/}
        {/*    {*/}
        {/*      Header: 'Email',*/}
        {/*      accessor: 'email',*/}
        {/*      Cell: props => <div>{props.value}</div>,*/}
        {/*    },*/}
        {/*    {*/}
        {/*      Header: 'Organization Count',*/}
        {/*      accessor: 'organizationCount',*/}
        {/*      Cell: props => <div>{props.value}</div>,*/}
        {/*    },*/}
        {/*    {*/}
        {/*      Header: 'Is Admin',*/}
        {/*      accessor: 'isAdmin',*/}
        {/*      Cell: props => (props.value ? 'true' : 'false'),*/}
        {/*    },*/}
        {/*    {*/}
        {/*      Header: 'User Id',*/}
        {/*      accessor: 'userId',*/}
        {/*      Cell: props => <div>{props.value}</div>,*/}
        {/*    },*/}
        {/*  ]}*/}
        {/*  defaultPageSize={defaultPageSize}*/}
        {/*  showPagination={usersAndMembers.length >= 10}*/}
        {/*  minRows={0}*/}
        {/*/>*/}
      </div>
    </div>
  )
}
