import * as React from 'react'
import { PageContent } from '@components/pages'
import { captablePath, organizationEditLegendPath, organizationLegendsPath, PageProps, ShareholdingProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { loadHolding, loadLegend } from '@helpers/loaders'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import { CertificateView } from '@modules/captable/components/certificate-view'
import { ErrorButton, PathLinkButton, SubmitButton } from '@components/button/button'
import { hasPermission, loadPermissions, PermissionProps } from '@components/permissions'
import { deleteHolding, ExpandedHoldingResponse, GetLegendResponse } from '@src/service/services/captable'
import styled from 'styled-components'
import { FlexRow } from '@src/styles'
import { ViewLegend } from '../components/view-legend'

type Props = PageProps & GetLegendResponse

declare var API_URL: string

const withData = withLoadingCachedMultiple<Props>(loadLegend)

const StyledForm = styled<any>(FlexRow)`
  height: 235px;
  justify-content: center;
  align-items: start;
  form {
    width: 79.55%;
  }
`

export const ViewLegendPage = withData((props: Props) => {
  const { legend, params } = props
  const { organization } = params

  const legendName = legend?.name!
  const parent: BaseLinkProps = { path: organizationLegendsPath, args: { organization } }

  return (
    <PageContent>
      <PageContentHeader title={legendName} parent={parent}>
        <PathLinkButton path={organizationEditLegendPath} args={{ organization, legend: params.legend }}>Edit</PathLinkButton>
      </PageContentHeader>
      <ViewLegend organization={organization} legend={legend?.hash} />
    </PageContent>
  )
})
