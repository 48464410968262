import * as React from 'react'
import { newBasicModalBundle } from '@shared/components/basic-modal'
import { SolidButton } from '@shared/components/button'

interface Props {
    onActivate: any
}

export function successModal(props: Props) {
    const { onActivate } = props
    return newBasicModalBundle({
        title: 'Success!',
        body: <span>Your wallet is successfully connected.</span>,
        max: '544px',
        align: 'center',
        buttons: [
            {
                text: 'Done',
                onActivate,
                componentType: SolidButton,
            },
        ],
        noCancel: true
    })
}
