import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { AmountType, editVestingPath, newVestingPath, PageProps, vestingsPath, viewVestingPath } from '@logic'
import { PageContent, PageContentHeader } from '@components/pages'
import { BuiltInPermission, getVestmentTrigger } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { loadVestings } from '@helpers/loaders'
import { deleteVesting, GetVestingsResponse } from '@src/service'
import { EmptyLanding } from '@shared/components/empty-landing'
import { ellipsesColumn, Table } from '@shared/components/tables'
import { CellProps } from 'react-table'
import { PathLink } from '@shared/components/navigation'
import { PathLinkButton } from '@shared/components/button'
import { basicDeleteModal } from '@shared/components/modal'
import { monthDiff } from '../utility'
import { safeDivide } from '@shared/helpers'

type Props = PageProps & GetVestingsResponse

const withData = withLoadingCachedMultiple<Props>(loadVestings)

export const VestingList = withData(
    withPermissions([BuiltInPermission.viewCapTable])((props: Props) => {
        const [selectedVesting, setSelectedVesting] = React.useState<any>()
        const { params, navigate, vestingSchedule, httpClient, reload } = props
        const { organization, plan } = params
        const vestingSchedules = vestingSchedule && vestingSchedule.length > 0 ?
            vestingSchedule.map((v: any) => {
                const isDiscreet = Object.keys(v)?.includes('events')
                const vestingEvents = isDiscreet ?
                    v.isValueAbsolute ?
                        v.events.map((event: any) => {
                            const date = new Date(`${event.month}/${event.day}/${event.year}`)
                            return { ...event, date }
                        }).sort((a: any, b: any) => a.date && b.date && a.date.getTime() - b.date.getTime())
                        :
                        v.events.map((event: any) => ({ ...event, rValue: event.value * 100 }))
                            .sort((a: any, b: any) => a.month && b.month && a.month - b.month)
                    : []
                if (isDiscreet) {
                    return {
                        ...v,
                        events: vestingEvents,
                        frequency: vestingEvents.length,
                        vestsOn: 'Various Dates'
                    }
                }
                else {
                    const frequency = safeDivide((v.durationMonths - (v.cliffMonths || 0)), v.frequencyMonths)
                    return {
                        ...v,
                        frequency,
                        vestsOn: getVestmentTrigger[v.vestsOn],
                    }
                }
            }) : []
        const handleDeleteVesting = async () => {
            await deleteVesting(httpClient)({ plan, vestingSchedule: selectedVesting?.hash })
            deleteModal.setVisible(false)
            reload()
        }
        const deleteModal = basicDeleteModal(
            handleDeleteVesting,
            [selectedVesting?.name || ''],
        )

        const columns = [
            {
                Header: 'Name',
                accessor: 'name',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <PathLink path={viewVestingPath} args={{ organization, vesting: row.original.hash, plan }}>
                            {row.original.name}
                        </PathLink>
                    )
                },
            },
            {
                Header: 'Schedule Type',
                Cell: ({ row }: CellProps<any>) => {
                    const isDiscreet = Object.keys(row.original)?.includes('events')
                    return (
                        <span>
                            {isDiscreet ?
                                `Custom (${row.original.isValueAbsolute ? 'Absolute' : 'Relative'})`
                                : 'Standard'
                            }</span>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Duration',
                accessor: 'durationMonths',
                Cell: ({ row }: CellProps<any>) => {
                    const events = row.original.events
                    const duration = events ? row.original.isValueAbsolute ?
                        monthDiff(events[0].date, events[events.length - 1].date)
                        :
                        events[events.length - 1]?.month
                        : row.original.durationMonths
                    return (
                        duration ? <span>{duration} {duration === 1 ? 'month' : 'months'}</span> : 'N/A'
                    )
                },
                className: 'left'
            },
            {
                Header: 'Frequency',
                accessor: 'frequency',
                Cell: ({ row }: CellProps<any>) => {
                    const frequency = row.original.frequency
                    return <span>{frequency} {frequency == 1 ? 'event' : 'events'}</span>
                },
                className: 'left'
            },
            {
                Header: 'Vests On',
                accessor: 'vestsOn',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{row.original.vestsOn}</span>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Cliff',
                accessor: 'cliffMonths',
                Cell: ({ row }: CellProps<any>) => {
                    return (
                        <span>{row.original.cliffMonths ? 'Yes' : 'No'}</span>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Cliff Duration',
                accessor: 'cliffDuration',
                Cell: ({ row }: CellProps<any>) => {
                    const months = row.original.cliffMonths
                    return (
                        <span>
                            {months ?
                                `${months} ${months == 1 ? 'month' : 'months'}` : 'N/A'}
                        </span>
                    )
                },
                className: 'left'
            },
            {
                Header: 'Amount at cliff',
                accessor: 'cliffAmount',
                Cell: ({ row }: CellProps<any>) => {
                    const amount = row.original.cliffAmount
                    const type = row.original.cliffAmountType
                    return (
                        <span>
                            {amount ?
                                `${amount}${type == AmountType.percentage ? '%' : ''}` : 'N/A'}
                        </span>
                    )
                },
            },
            ellipsesColumn(
                [
                    ['View', ({ row }) => navigate(viewVestingPath, { organization, plan, vesting: row.original.hash })],
                    ['Edit', ({ row }) => navigate(editVestingPath, { organization, plan, vesting: row.original.hash })],
                    ['Delete', ({ row }) => { setSelectedVesting(row.original), deleteModal.setVisible(true) }]
                ]
            ),
        ]

        return (
            <>
                {deleteModal.component}
                <PageContent>
                    {vestingSchedules.length > 0 ?
                        <>
                            <PageContentHeader title="Vesting Schedules">
                                <PathLinkButton path={newVestingPath} args={params}>
                                    + Add
                                </PathLinkButton>
                            </PageContentHeader>
                            <Table columns={columns} data={vestingSchedules} scrollingTable={true} withAllBorders={true} noFooter={true} />
                        </>
                        :
                        <EmptyLanding path={vestingsPath} params={params} />
                    }
                </PageContent>
            </>
        )
    })
)
