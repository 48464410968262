import * as React from 'react'
import { withLoadingCachedMultiple } from '@components/loading'
import { PageProps, poolsPath } from '@logic'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BuiltInPermission } from '@helpers/constants'
import { withPermissions } from '@shared/hocs/with-permissions'
import { CommonForm, Sticky, UploadHandler } from '@shared/components/forms'
import arrayMutators from 'final-form-arrays'
import { PoolForm } from '../components/pool-form'
import {
  createAuthorization,
  createHolding,
  FilesResponse,
  FormPool,
  GetPlanResponse,
  GetSecuritiesResponse,
  NewAuthorizationRequest,
  NewHoldingRequest,
  postFile
} from '@src/service'
import { loadPlan, loadSecurities } from '@helpers/loaders'
import { BaseLinkProps } from '@shared/components/navigation'
import { PoolTreasuryCalculator } from '../components/pool-treasury-calculator'
import { filterEquities, filterIncentives } from '../logic'
import { modifyAuthorizations } from '@shared/helpers/authorizations'
import { HoldingRecordType } from '@shared/helpers/holdings'

type Props = PageProps & GetPlanResponse & GetSecuritiesResponse

const withData = withLoadingCachedMultiple<Props>(loadSecurities('true'), loadPlan)

export const CreatePool = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient, navigate, params, securities, stats, plan } = props
    const { organization } = params
    const securitiesList = filterEquities((stats || []), securities)
    const incentivesList = filterIncentives(securitiesList, plan)
    const initialValues: Partial<FormPool> = {}

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id,
      }
    }

    const createNewPool = async (values: any) => {
      const documents = values?.approvalDocument?.filter((d: any) =>
        (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []

      const request = {
        name: values?.name,
        parent: values?.incentive,
        value: values?.shares,
        entity: organization,
        owner: organization,
        plan: params.plan,
        holdingType: HoldingRecordType.pool,
      } as NewHoldingRequest

      const response = await createHolding(httpClient)({}, request)
      if (response?.hash) {
        modifyAuthorizations({ approvalDocuments, target: response.hash, httpClient, organization })
        navigate(poolsPath, { organization, plan: params.plan })
      }
      else console.log('create pool error') // TODO: add error message toast
    }
    const TreasuryCalculator: Sticky<any> = props => {
      const { values } = props
      const selectedIncentive = incentivesList?.find(i => i.hash === values?.incentive)
      const equityTreasury = securitiesList?.find(i => i.hash === selectedIncentive?.parent)?.stats.treasury
      return <PoolTreasuryCalculator
        current={equityTreasury || 0}
        allocated={values?.shares || 0}
      />
    }
    const parent: BaseLinkProps = { path: poolsPath, args: { organization, plan: params?.plan } }

    return (
      <PageContent>
        <PageContentHeader title="Create New Pool" parent={parent} withSticky={true} />
        <CommonForm
          mutators={{ ...arrayMutators }}
          onSubmit={createNewPool}
          initialValues={initialValues}
          sticky={TreasuryCalculator}
          submitText="Save"
        >
          <PoolForm securities={securitiesList} onUpload={onUpload} plan={plan} />
        </CommonForm>
      </PageContent>
    )
  })
)
