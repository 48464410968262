import * as React from 'react'
import { connect } from 'react-redux'
import { History } from 'history'
import { GetOrgReferralDataResponse, GetScratchCardResponse, InvitedFriendsResponse, PageProps } from '@logic'
import { MyReferralsPage, ReferralsWrap } from '../style'
import { RouteComponentProps, withRouter } from 'react-router'
import { copyTextToClipboard } from '@helpers/referral'
import { PageContent, PageContentHeader } from '@shared/components/pages'
import { loadAllScratchCards, loadInvitedFirends, loadOrgRewardsData } from '@shared/helpers'
import { withLoadingCachedMultiple } from '@shared/components/loading'
import { EmailInvitationRequest, sendInvite } from '@src/service/services/profile'
import {
    scratchTicketModal,
    socialMediaLinksModal,
    ReferralsTable,
    ReferralStatistics,
    ScratchOffPanels,
    ReferralInvitePanel
} from '../components'
import { withPermissions } from '@shared/hocs/with-permissions'
import { PermissionProps } from '@shared/components/permissions'
import { BuiltInPermission } from '@shared/helpers/constants'

interface StateProps extends RouteComponentProps<any> {
    user: any
    history: History
}

type Props = PageProps &
    StateProps &
    GetOrgReferralDataResponse &
    InvitedFriendsResponse &
    GetScratchCardResponse &
    PermissionProps

const withData = withLoadingCachedMultiple<Props>(
    loadOrgRewardsData,
    loadInvitedFirends,
    loadAllScratchCards('organization'),
)

const OrganizationReferralsComponent = withData(withPermissions([BuiltInPermission.viewOrganization])
    ((props: Props) => {
        const [copy, setCopy] = React.useState<boolean>(false)
        const {
            user,
            rewardDetails,
            orgRewardDetails,
            userRewardDetails,
            invitationDetails,
            httpClient,
            reload,
            params,
            referralLink,
            userReferralLink,
            orgRewards
        } = props
        const referralData = orgRewardDetails!!
        const userReferralData = userRewardDetails!!
        const orgReferralLink = user ? `${window.location.origin}/refer/r?user=${userReferralLink}&organization=${referralLink}` : ''
        const scratchTickets = orgRewards?.orgTickets!!
        const scratchedTickets = scratchTickets && scratchTickets.filter(t => t.scratched).length
        const availableTickets = scratchTickets && scratchTickets.filter(t => !t.scratched).length
        const mobileView = window.innerWidth <= 800

        const copyReferralLink = () => {
            copyTextToClipboard(orgReferralLink)
            setCopy(true)
        }

        const onSubmit = async (data: any) => {
            const request: EmailInvitationRequest = {
                referralLink,
                emailArray: data.email ? [data.email!] : [],
                recipientName: data.name!,
                entity: params?.organization!,
            }
            await sendInvite(httpClient)({}, request)
            reload()
        }
        const handleCancel = () => {
            scratchModal.setVisible(false)
            reload()
        }

        const scratchModal = scratchTicketModal({
            ticketList: scratchTickets,
            onCancel: handleCancel,
            params,
            httpClient
        })

        const socialMediaModal = socialMediaLinksModal({ referralLink: orgReferralLink, forOrg: true })

        return (
            <>
                {scratchModal.component}
                {socialMediaModal.component}
                <PageContent>
                    <PageContentHeader title={'Organization Referrals'} />
                    <MyReferralsPage>
                        <ReferralInvitePanel
                            onSubmit={onSubmit}
                            copyReferralLink={copyReferralLink}
                            copy={copy}
                            forOrg={true}
                            mobileView={mobileView}
                            socialMediaModalOpen={() => socialMediaModal.setVisible(true)} />
                        <ReferralsWrap>
                            <ScratchOffPanels
                                availableTickets={availableTickets}
                                scratchedTickets={scratchedTickets}
                                forOrg={true}
                                setShowTicketsModal={() => scratchModal.setVisible(true)}
                                mobileView={mobileView}
                                params={params} />
                            <ReferralStatistics
                                referralData={referralData}
                                invitationDetails={invitationDetails}
                                rewardDetails={rewardDetails}
                                userReferralData={userReferralData}
                                forOrg={true} />
                            <ReferralsTable invitationDetails={invitationDetails} forOrg={true} />
                        </ReferralsWrap>
                    </MyReferralsPage>
                </PageContent>
            </>
        )
    })
)

const mapStateToProps = (state: any) => ({
    user: state.user,
})

export const OrganizationReferrals = withRouter(
    connect(
        mapStateToProps,
        {}
    )(OrganizationReferralsComponent)
)
