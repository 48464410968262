import * as React from 'react'
import {
  captablePath,
  Features,
  GetOrganizationFeaturesResponse,
  MembersProps,
  OrganizationProps,
  PageProps,
  ShareholdingFormInfoProps,
} from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import {
  InitialShareholdingFormValues,
  prepareCapitalContributionsForSubmission
} from '@modules/captable/components/shareholding-form'
import {
  loadFeatures,
  loadLegends,
  loadMembers,
  loadOrganization,
  loadOrganizationHoldings,
  loadSecurities,
  loadShareholdingFormConfig
} from '@helpers/loaders'
import { PageContent } from '@components/pages'
import { PageContentHeader } from '@components/pages/page-content-header'
import { BaseLinkProps } from '@components/navigation/navigation'
import { CommonForm, UploadHandler } from '@components/forms'
import { TransferShareholdingForm } from '@modules/captable/components/transfer-shareholding-form'
import {
  createAuthorization,
  ExpandedHoldingsResponse,
  FilesResponse,
  GetLegendsResponse,
  GetSecuritiesResponse,
  postFile,
  transferShareholding
} from '@src/service'
import { withPermissions } from '@shared/hocs/with-permissions'
import { BuiltInPermission } from '@helpers/constants'
import { loadPermissions } from '@components/permissions'
import { CannotTransferError } from '../cannot-transfer'
import arrayMutators from 'final-form-arrays'
import { modifyAuthorizations } from '@shared/helpers/authorizations'

type Props = PageProps
  & MembersProps
  & ShareholdingFormInfoProps
  & ExpandedHoldingsResponse
  & GetLegendsResponse
  & GetSecuritiesResponse
  & OrganizationProps
  & GetOrganizationFeaturesResponse

const withData = withLoadingCachedMultiple<Props>(
  loadOrganizationHoldings('all'),
  loadMembers,
  loadShareholdingFormConfig,
  loadPermissions,
  loadLegends(true),
  loadSecurities(),
  loadOrganization,
  loadFeatures
)

export const TransferShareholdingPage = withData(
  withPermissions([BuiltInPermission.editCapTable])((props: Props) => {
    const { httpClient,
      members,
      navigate,
      params,
      holdings,
      service,
      legends,
      securities,
      organizationFeatures,
      voidedHoldings,
    } = props
    const { organization } = params
    const holdingsList = holdings && holdings.length > 0 ? holdings : []
    const shareholdings = holdingsList.filter(h => h.securityDetails && !h.convertibleInstrument && !h.plan)
    const securitiesList = securities ? securities?.filter(s => !s.parent) : []
    const orgFeatures = organizationFeatures && organizationFeatures.length > 0 ? organizationFeatures : []

    const initialValues: Partial<InitialShareholdingFormValues> = {
      issueDate: new Date(),
    }

    const onUpload: UploadHandler = async files => {
      const response = (await postFile(httpClient)(`organization/${organization}/file`, files)) as FilesResponse
      const file = response.files[0]
      return {
        hash: file.hash,
        name: file.filename,
        id: file.id
      }
    }

    const onSubmit = async (values: any) => {
      const documents = values?.approvalDocument?.filter(d => (d.approvalDate || d.approvalDocument || d.documentTypeName || d.note))
      const approvalDocuments = documents && documents.length > 0 ? documents.map((document: any) => {
        const newDocument = { ...document }
        delete newDocument.key
        return newDocument
      }) : []
      const { organization } = params
      const capitalContributions = prepareCapitalContributionsForSubmission(values).capitalContributions
        .filter(arg => arg.amount !== 0)
      const request = {
        ...values,
        capitalContributions,
        approvalDocument: values?.approvalDocument?.id!
      }
      delete request.fromMember
      delete request.shareType
      delete request.shareClass
      const transferResponse = await transferShareholding(service)(organization, request)
      if (transferResponse.newShareholdings) {
        const holdings = transferResponse.newShareholdings
        holdings.forEach(h => {
          modifyAuthorizations({ approvalDocuments, target: h.hash, httpClient, organization })
        })
        navigate(captablePath, { organization })
      }
    }

    const parent: BaseLinkProps = { path: captablePath, args: { organization } }

    return (
      <PageContent>
        <PageContentHeader title="Transfer" parent={parent} />
        {orgFeatures.length > 0 && orgFeatures.includes(Features.transfers) ?
          <CommonForm
            mutators={{ ...arrayMutators }}
            onSubmit={onSubmit}
            initialValues={initialValues}
            submitText="Transfer"
          >
            <TransferShareholdingForm
              organization={organization}
              securities={securitiesList}
              members={members}
              holdings={holdingsList}
              voidedHoldings={voidedHoldings}
              shareholdings={shareholdings}
              legends={legends}
              onUpload={onUpload}
            />
          </CommonForm>
          :
          <CannotTransferError organization={organization} />
        }
      </PageContent>
    )
  }))
