import * as React from 'react'
import { OrganizationProps, PageProps } from '@logic'
import { withLoadingCachedMultiple } from '@components/loading'
import { GetSecuritiesResponse, GetLegendsResponse } from '@src/service'
import { loadAccount } from '@shared/helpers'
import { EditAccountSettingsShared } from '../components/edit-acc-shared'

type Props = PageProps & OrganizationProps & GetSecuritiesResponse & GetLegendsResponse

const withData = withLoadingCachedMultiple<Props>(loadAccount)

export const EditAccountSettings = withData((props: Props) => {
    return <EditAccountSettingsShared {...props} />
})
