import * as React from 'react'
import { PageProps } from '@logic'
import { CommonPortfolio } from '@shared/components/common-portfolio'
import { signUpModal } from '@shared/components/modal'

type Props = PageProps

export const GuestPortfolioPage = (props: Props) => {
    const { navigate } = props
    const signUpModalBundle = signUpModal({ navigate })

    return <>
        {signUpModalBundle.component}
        <CommonPortfolio
            {...props}
            title='My Portfolio'
            forGuest={true}
            openSignUp={() => signUpModalBundle.setVisible(true)} />
    </>
}
